<div
  (click)="openSideMenu()"
  class="fixed bottom-5 right-5 h-14 w-14 rounded-full flex items-center justify-center text-white text-lg cursor-pointer hover:scale-110 hover:-translate-y-2 transition-all duration-200 hover:shadow-xl bg-inteccDark hover:bg-inteccDark/70 {{
    hideButton ? 'hidden' : ''
  }}"
  style=""
>
  <i class="fas fa-graduation-cap"></i>
</div>

<div class="side-menu">
  <div
    class="side-menu__overlay"
    [class.side-menu__overlay--open]="sideMenuOpen"
    (click)="closeSideMenu()"
  >
    <div
      class="side-menu__box"
      [class.side-menu__box--open]="sideMenuOpen"
      (click)="$event.stopPropagation()"
    >
      <div
        style="padding: 14px"
        class="flex justify-between bg-inteccDark p-5 text-2xl"
      >
        <div class="h-8 w-8">
          <img
            class="crisp h-full w-full object-contain"
            src="../../../assets/images/intecc-with-bg-rounded.png"
            alt=""
          />
        </div>
        <p class="flex items-center text-2xl font-bold">
          <!-- <i class="fas fa-graduation-cap mr-2"></i> -->
          <span>Academia</span>
        </p>
        <div class="cursor-pointer" (click)="closeSideMenu()">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="px-10 pt-5">
        <div class="side-menu__title">Formación</div>
        <div class="mb-3"></div>
        <div *ngFor="let item of data" class="">
          <app-accordion [title]="item.title" class="">
            <div class="py-2">
              <a
                *ngFor="let link of item.links"
                href="{{ link.url }}"
                style="font-weight: 600"
                class="mb-2 flex items-center transition-all duration-200 hover:translate-x-2"
              >
                <span class="mr-2">👉</span>
                <span>{{ link.title }}</span>
              </a>
            </div>
          </app-accordion>
          <div class="mb-3"></div>
        </div>
      </div>
      <div class="px-10 pt-5">
        <div class="side-menu__title">Publicaciones</div>
        <div class="mb-3"></div>
        <div class="side-menu__link-list">
          <a
            href="https://www.valoracionfuncional.com/01-valoracion-inicial"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración Inicial</span></a
          >
          <a
            href="https://www.valoracionfuncional.com/02-valoracion-postural"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración Postural</span></a
          >
          <a
            href="https://www.valoracionfuncional.com/03-patologias-ascendentes"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración Ascendentes</span></a
          >
          <a
            href="https://www.valoracionfuncional.com/04-valoracion-artro-muscular-01"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración del ROM 01</span></a
          >
          <a
            href="https://www.valoracionfuncional.com/05-valoracion-artromuscular-02"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración del ROM 02</span></a
          >
          <a
            href="https://www.valoracionfuncional.com/06-valoracion-del-raquis"
            target="blank"
            class="side-menu__link"
            ><i class="far fa-circle"></i><span>Valoración del Raquis</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>
