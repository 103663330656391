import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-left-right-toggle',
    templateUrl: './left-right-toggle.component.html',
    styleUrls: ['./left-right-toggle.component.scss']
})
export class LeftRightToggleComponent {

    @Input()
    toggle = false;

    @Output()
    toggled = new EventEmitter<boolean>();

    constructor() { }

    emitToggle(toggle: boolean) {
        this.toggled.emit(toggle);
    }

}
