import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-client-segments-level-section',
  templateUrl: './client-segments-level-section.component.html',
})
export class ClientSegmentsLevelSectionComponent {
  @Input()
  data: { imageUrl: string; title: string };
}
