<div class="content">
  <ng-container [ngSwitch]="loadingState">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback
          [minimal]="!!errorMessage"
          [message]="
            errorMessage || 'Algo ha fallado al cargar el cuestionario'
          "
        ></app-error-feedback>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'idle'">
      <!-- Back button at top of form -->
      <div class="mb-6 mt-4" *ngIf="isClient">
        <button
          type="button"
          class="button-secondary transition-colors hover:text-intecc"
          (click)="goBack()"
        >
          <i class="fas fa-arrow-left mr-2"></i> Volver
        </button>
      </div>

      <form
        [formGroup]="questionnaireForm"
        (ngSubmit)="onSubmit()"
        class="form pb-20"
        [ngClass]="{ 'read-only-form': !isClient }"
      >
        <!-- Datos Personales -->
        <section class="mb-8">
          <h2 class="mb-2 text-xl font-bold uppercase">Datos Personales</h2>
          <p class="mb-4 text-neutral-600">Datos básicos del cliente</p>

          <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div class="form__field">
              <label class="form__label" for="name">Nombre</label>
              <input
                class="form__input"
                type="text"
                id="name"
                formControlName="name"
              />
            </div>
            <div class="form__field">
              <label class="form__label" for="surname">Apellidos</label>
              <input
                class="form__input"
                type="text"
                id="surname"
                formControlName="surname"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="birthDate"
                >Fecha de Nacimiento</label
              >
              <input
                class="form__input"
                type="date"
                id="birthDate"
                formControlName="birthDate"
              />
            </div>
            <div class="form__field">
              <label class="form__label" for="dni">DNI</label>
              <input
                class="form__input"
                type="text"
                id="dni"
                formControlName="dni"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="email">Correo Electrónico</label>
              <input
                class="form__input"
                type="email"
                id="email"
                formControlName="email"
              />
            </div>
            <div class="form__field">
              <label class="form__label" for="phone">Número de Teléfono</label>
              <input
                class="form__input"
                type="tel"
                id="phone"
                formControlName="phone"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="studiesLevel"
                >Nivel de Estudios</label
              >
              <app-new-dropdown
                [options]="studyLevelOptions"
                [multiple]="false"
                formControlName="studiesLevel"
                buttonClass="bg-white shadow-none border-solid !border-border"
                [readonly]="!isClient"
              ></app-new-dropdown>
            </div>
            <div class="form__field">
              <label class="form__label" for="currentJob">Trabajo Actual</label>
              <input
                class="form__input"
                type="text"
                id="currentJob"
                formControlName="currentJob"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="dailyHours">Horas Diarias</label>
              <input
                class="form__input"
                type="number"
                id="dailyHours"
                formControlName="dailyHours"
                min="0"
                max="24"
                step="1"
              />
            </div>
            <div class="form__field">
              <label class="form__label" for="physicalEffort"
                >Esfuerzo Físico (0-10)</label
              >
              <input
                class="form__input"
                type="number"
                min="0"
                max="10"
                step="1"
                id="physicalEffort"
                formControlName="physicalEffort"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="currentWeight"
                >Peso Actual (kg)</label
              >
              <input
                class="form__input"
                type="number"
                id="currentWeight"
                formControlName="currentWeight"
                min="0"
                step="1"
              />
            </div>
            <div class="form__field">
              <label class="form__label" for="height">Altura (cm)</label>
              <input
                class="form__input"
                type="number"
                id="height"
                formControlName="height"
                min="0"
                step="1"
              />
            </div>
          </div>
        </section>

        <!-- Autovaloración -->
        <section class="mb-8">
          <h2 class="mb-2 text-xl font-bold uppercase">Autovaloración</h2>
          <p class="mb-4 text-neutral-600">
            Valorar de 0 a 10 su estado actual
          </p>

          <div class="space-y-4">
            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="physicalCondition"
                >Estado Físico</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                step="1"
                id="physicalCondition"
                formControlName="physicalCondition"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="animicStatus"
                >Estado Anímico</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="animicStatus"
                formControlName="animicStatus"
                step="1"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="nutritionalStatus"
                >Estado Nutricional</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="nutritionalStatus"
                formControlName="nutritionalStatus"
                step="1"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="sleepHours"
                >Horas de Sueño</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="sleepHours"
                formControlName="sleepHours"
                step="1"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="sleepQuality"
                >Calidad del Sueño</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="sleepQuality"
                formControlName="sleepQuality"
                step="1"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="dailyIntakes"
                >Comidas Diarias</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="dailyIntakes"
                formControlName="dailyIntakes"
                step="1"
              />
            </div>

            <div class="flex items-center justify-between">
              <label class="form__label !mb-0" for="painLevel"
                >Nivel de Dolor o Molestia</label
              >
              <input
                class="form__input w-20"
                type="number"
                min="0"
                max="10"
                id="painLevel"
                formControlName="painLevel"
                step="1"
              />
            </div>

            <div class="form__field">
              <label class="form__label" for="whatWouldYouImprove"
                >Qué Mejoraría de su Estado Físico</label
              >
              <textarea
                class="form__input min-h-24"
                id="whatWouldYouImprove"
                formControlName="whatWouldYouImprove"
              ></textarea>
            </div>
          </div>
        </section>

        <!-- Hábitos y Rutinas -->
        <section class="mb-8">
          <h2 class="mb-2 text-xl font-bold uppercase">Hábitos y Rutinas</h2>
          <p class="mb-4 text-neutral-600">
            Explique sus hábitos según las orientaciones
          </p>

          <div class="space-y-4">
            <div class="form__field">
              <label class="form__label" for="dailyActivities"
                >AVD. Actividades de la Vida Diaria</label
              >
              <p class="mb-2 text-sm text-neutral-500">
                Rutina diaria, hobbies, horas sentado al día, descanso, etc
              </p>
              <textarea
                class="form__input min-h-24"
                id="dailyActivities"
                formControlName="dailyActivities"
              ></textarea>
            </div>

            <div class="form__field">
              <label class="form__label" for="laboralActivities"
                >AVL. Actividades de la Vida Laboral</label
              >
              <p class="mb-2 text-sm text-neutral-500">
                Exigencia física y psicológica del trabajo actual o previo
              </p>
              <textarea
                class="form__input min-h-24"
                id="laboralActivities"
                formControlName="laboralActivities"
              ></textarea>
            </div>

            <div class="form__field">
              <label class="form__label" for="sportsActivities"
                >HD. Hábitos Deportivos</label
              >
              <p class="mb-2 text-sm text-neutral-500">
                Experiencia, ejercicios o deportes realizados, objetivos, etc
              </p>
              <textarea
                class="form__input min-h-24"
                id="sportsActivities"
                formControlName="sportsActivities"
              ></textarea>
            </div>

            <div class="form__field">
              <label class="form__label" for="nutritionalHabits"
                >HN. Hábitos Nutricionales</label
              >
              <p class="mb-2 text-sm text-neutral-500">
                Dietas, suplementación, frecuencia y horarios, etc
              </p>
              <textarea
                class="form__input min-h-24"
                id="nutritionalHabits"
                formControlName="nutritionalHabits"
              ></textarea>
            </div>

            <div class="space-y-4">
              <div class="flex items-center justify-between">
                <label class="form__label !mb-0" for="hasDoneDiet"
                  >Ha Realizado Dieta</label
                >
                <app-new-dropdown
                  [options]="relapseOptions"
                  [multiple]="false"
                  formControlName="hasDoneDiet"
                  buttonClass="bg-white shadow-none border-solid !border-border"
                  wrapperClass="w-20 md:w-40"
                  [readonly]="!isClient"
                ></app-new-dropdown>
              </div>
              <div class="flex items-center justify-between">
                <label class="form__label !mb-0" for="hasHadCavities"
                  >Ha Tenido Caries</label
                >
                <app-new-dropdown
                  [options]="relapseOptions"
                  [multiple]="false"
                  formControlName="hasHadCavities"
                  buttonClass="bg-white shadow-none border-solid !border-border"
                  wrapperClass="w-20 md:w-40"
                  [readonly]="!isClient"
                ></app-new-dropdown>
              </div>
              <div class="flex items-center justify-between">
                <label class="form__label !mb-0" for="hasHadDentalCorrection"
                  >Ha Tenido Corrección Dental</label
                >
                <app-new-dropdown
                  [options]="relapseOptions"
                  [multiple]="false"
                  formControlName="hasHadDentalCorrection"
                  buttonClass="bg-white shadow-none border-solid !border-border"
                  wrapperClass="w-20 md:w-40"
                  [readonly]="!isClient"
                ></app-new-dropdown>
              </div>
              <div class="flex items-center justify-between">
                <label class="form__label !mb-0" for="hasUsedInsoles"
                  >Ha Usado Plantillas</label
                >
                <app-new-dropdown
                  [options]="relapseOptions"
                  [multiple]="false"
                  formControlName="hasUsedInsoles"
                  buttonClass="bg-white shadow-none border-solid !border-border"
                  wrapperClass="w-20 md:w-40"
                  [readonly]="!isClient"
                ></app-new-dropdown>
              </div>
            </div>
          </div>
        </section>

        <!-- Lesiones y Patologías -->
        <section class="mb-8">
          <h2 class="mb-2 text-xl font-bold uppercase">
            Lesiones y Patologías
          </h2>
          <p class="mb-4 text-neutral-600">
            Añada las lesiones o patologías sufridas
          </p>
          <div class="mb-8">
            <h3 class="mb-2 text-lg font-semibold">Lesiones</h3>
            <div formArrayName="injuries">
              <div
                *ngFor="let injury of injuriesArray.controls; let i = index"
                [formGroupName]="i"
                class="mb-8 rounded-lg border border-neutral-200 p-4"
              >
                <div class="mb-4 flex items-center justify-between">
                  <h3 class="text-lg font-semibold">Lesión #{{ i + 1 }}</h3>
                  <button
                    type="button"
                    class="size-10 rounded-full bg-red-100 p-2 text-red-600 hover:bg-red-200"
                    (click)="removeInjury(i)"
                    *ngIf="isClient"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>

                <div class="space-y-4">
                  <div class="flex items-center justify-between gap-x-4">
                    <label class="form__label !mb-0" for="name-{{ i }}"
                      >Lesión</label
                    >
                    <input
                      class="form__input w-40 md:w-[550px] lg:w-[700px]"
                      type="text"
                      id="name-{{ i }}"
                      formControlName="name"
                    />
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="startDate-{{ i }}"
                      >Fecha</label
                    >
                    <input
                      class="form__input w-40 md:w-[550px] lg:w-[700px]"
                      type="date"
                      id="startDate-{{ i }}"
                      formControlName="startDate"
                    />
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="relapse-{{ i }}"
                      >Recaída</label
                    >
                    <app-new-dropdown
                      [options]="relapseOptions"
                      [multiple]="false"
                      formControlName="relapse"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="time-{{ i }}"
                      >Tiempo</label
                    >
                    <app-new-dropdown
                      [options]="timeOptions"
                      [multiple]="false"
                      formControlName="time"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="treatment-{{ i }}"
                      >Tratamiento</label
                    >
                    <app-new-dropdown
                      [options]="treatmentOptions"
                      [multiple]="false"
                      formControlName="treatment"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="form__field">
                    <label class="form__label" for="comments-{{ i }}"
                      >Comentarios</label
                    >
                    <p class="mb-2 text-sm text-neutral-500">
                      Información adicional (medicación, baja laboral, pruebas
                      complementarias, etc)
                    </p>
                    <textarea
                      class="form__input min-h-24"
                      id="comments-{{ i }}"
                      formControlName="comments"
                      [readonly]="!isClient"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <button
                type="button"
                class="flex items-center gap-2 rounded-md bg-blue-100 px-4 py-2 text-blue-600 hover:bg-blue-200"
                (click)="addInjury()"
                *ngIf="isClient"
              >
                <i class="fas fa-plus"></i>
                Añadir lesión
              </button>
            </div>
          </div>
          <div class="mb-8">
            <h3 class="mb-2 text-lg font-semibold">Patologías</h3>
            <div formArrayName="pathologies">
              <div
                *ngFor="
                  let pathology of pathologiesArray.controls;
                  let i = index
                "
                [formGroupName]="i"
                class="mb-8 rounded-lg border border-neutral-200 p-4"
              >
                <div class="mb-4 flex items-center justify-between">
                  <h3 class="text-lg font-semibold">Patología #{{ i + 1 }}</h3>
                  <button
                    type="button"
                    class="size-10 rounded-full bg-red-100 p-2 text-red-600 hover:bg-red-200"
                    (click)="removePathology(i)"
                    *ngIf="isClient"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>

                <div class="space-y-4">
                  <div class="flex items-center justify-between gap-x-4">
                    <label class="form__label !mb-0" for="name-{{ i }}"
                      >Patología</label
                    >
                    <input
                      class="form__input w-40 md:w-[550px] lg:w-[700px]"
                      type="text"
                      id="name-{{ i }}"
                      formControlName="name"
                    />
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="startDate-{{ i }}"
                      >Fecha</label
                    >
                    <input
                      class="form__input w-40 md:w-[550px] lg:w-[700px]"
                      type="date"
                      id="startDate-{{ i }}"
                      formControlName="startDate"
                    />
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="relapse-{{ i }}"
                      >Recaída</label
                    >
                    <app-new-dropdown
                      [options]="relapseOptions"
                      [multiple]="false"
                      formControlName="relapse"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="time-{{ i }}"
                      >Tiempo</label
                    >
                    <app-new-dropdown
                      [options]="timeOptions"
                      [multiple]="false"
                      formControlName="time"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="flex items-center justify-between">
                    <label class="form__label !mb-0" for="treatment-{{ i }}"
                      >Tratamiento</label
                    >
                    <app-new-dropdown
                      [options]="treatmentOptions"
                      [multiple]="false"
                      formControlName="treatment"
                      buttonClass="bg-white shadow-none border-solid !border-border w-full"
                      wrapperClass="w-40 md:w-[550px] lg:w-[700px]"
                      [readonly]="!isClient"
                    ></app-new-dropdown>
                  </div>

                  <div class="form__field">
                    <label class="form__label" for="comments-{{ i }}"
                      >Comentarios</label
                    >
                    <p class="mb-2 text-sm text-neutral-500">
                      Información adicional (medicación, baja laboral, pruebas
                      complementarias, etc)
                    </p>
                    <textarea
                      class="form__input min-h-24"
                      id="comments-{{ i }}"
                      formControlName="comments"
                      [readonly]="!isClient"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <button
                type="button"
                class="flex items-center gap-2 rounded-md bg-blue-100 px-4 py-2 text-blue-600 hover:bg-blue-200"
                (click)="addPathology()"
                *ngIf="isClient"
              >
                <i class="fas fa-plus"></i>
                Añadir patología
              </button>
            </div>
          </div>
        </section>

        <!-- Facturación -->
        <section class="mb-8">
          <h2 class="mb-2 text-xl font-bold uppercase">Facturación</h2>
          <p class="mb-4 text-neutral-600">Añadir o modificar sus datos</p>

          <div
            class="mb-4 sm:flex sm:items-center sm:justify-between sm:gap-x-4"
          >
            <label class="form__label sm:!mb-0" for="billingPreferences"
              >Preferencia</label
            >
            <app-new-dropdown
              [options]="billingPreferencesOptions"
              [multiple]="false"
              formControlName="billingPreferences"
              buttonClass="bg-white shadow-none border-solid !border-border"
              wrapperClass="w-full sm:w-80"
              [readonly]="!isClient"
            ></app-new-dropdown>
          </div>

          <div class="form__field">
            <label class="form__label" for="clientName">Cliente</label>
            <input
              class="form__input"
              type="text"
              id="clientName"
              formControlName="clientName"
            />
          </div>

          <div class="form__field">
            <label class="form__label" for="clientDniNif">DNI/CIF</label>
            <input
              class="form__input"
              type="text"
              id="clientDniNif"
              formControlName="clientDniNif"
            />
          </div>

          <div class="form__field">
            <label class="form__label" for="clientAddress"
              >Dirección, Localidad, CP</label
            >
            <input
              class="form__input"
              type="text"
              id="clientAddress"
              formControlName="clientAddress"
            />
          </div>

          <div class="form__field">
            <label class="form__label" for="clientEmail">Email</label>
            <input
              class="form__input"
              type="email"
              id="clientEmail"
              formControlName="clientEmail"
            />
          </div>

          <div class="form__field mt-4">
            <app-checkbox
              formControlName="acceptServiceTerms"
              [readonly]="acceptServiceTermsSaved"
            >
              <span class="form__label !mb-0 ml-2"
                >Acepto las condiciones del servicio</span
              >
            </app-checkbox>

            <p class="text-sm text-neutral-500">
              Casilla obligatoria para autorizar el tratamiento de datos e
              imágenes
            </p>
            <app-checkbox
              formControlName="acceptServiceTermsAndPrivacyPolicy"
              [readonly]="
                acceptServiceTermsAndPrivacyPolicySaved || !showAcceptance
              "
            >
              <a
                [href]="showAcceptance ? acceptanceUrl : '#'"
                target="_blank"
                class="ml-2 text-sm text-blue-600 hover:underline"
                [class.cursor-default]="!showAcceptance"
                [class.text-gray-500]="!showAcceptance"
                [class.hover:no-underline]="!showAcceptance"
                >Condiciones del servicio y política de privacidad</a
              >
            </app-checkbox>
          </div>
        </section>

        <div class="form__actions" *ngIf="isClient">
          <button
            type="submit"
            class="button-cta-2 mb-12 transition-colors hover:opacity-90"
          >
            Guardar
          </button>
          <div class="w-full">
            <button
              type="button"
              class="button-secondary w-full transition-colors hover:text-intecc"
              (click)="goBack()"
            >
              Volver
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
</div>
