import { SessionExercise } from "./exercise.model";
import { TestReportLink } from "./tests.model";

export const TRAININGSESSIONPROTOCOLDEF_PATH = "training-session-protocols";

export const CLIENT_TRAINING_SESSIONS_PATH = "client-training-sessions";

export interface TrainingSessionProtocolDef {
  id?: string;
  description: string;
  name: string;
  fixedImages?: Array<TrainingSessionFixedImage>;
  owner?: string;
  sections: Array<TrainingSessionSectionsDef>;
  lastLocalId: number;
  lastLocalImageId: number;
  createdAt?: any;
  updatedAt?: Date;
  copiedFromId?: string; // if this protocol was copied from another proffesional
}

export interface TrainingSessionSectionsDef {
  localId?: number;
  name?: string; // section name
  description?: string; // section description
  /**
   * The order in the array would be [
   * IMPORTANT: Decir que esto es inamovible, no se puede cambiar el orden
   * "Series",
   * "Volumen" (repeticiones, tiempo...),
   * "Intensidad" (peso?),
   * "Descanso series",
   * "Descanso ejercicio",
   * "Extra"
   * ]
   */
  fields?: Array<TrainingSessionFieldDef>;
  /** NOTE: tableRowFields is the field used for old version */
  tableRowFields?: Array<TrainingSessionFieldDef>;
  sectionExercises?: Array<SessionExercise>; // if these exists, they'll take precedence over 'tableRowFields'
  tableFirstColTitle?: string; // always "Exercises"
  images?: Array<TrainingSessionImageDef>;
  fixedImages?: Array<TrainingSessionFixedImage>;
  lastLocalId?: number;
  lastLocalImageId?: number;
  mode?: "table"; // always 'table' for training sessions section
  tableMode?: "xy"; // always 'xy' for training sessions section ('tableRowFields' are the columns)
}

export type TrainingSessionFieldType = "select" | "text" | "number" | "time";

export interface TrainingSessionFieldDef {
  //las columnas que no son la primera
  localId: number;
  title: string; // el nombre de la columna
  type: TrainingSessionFieldType;
  leftRight: boolean; // not used so far, would be for unilateral exercises
  options: Array<string>;
  optionsString: string;
}

export interface TrainingSessionImageDef {
  localId: number;
  key: string;
  name: string;
  position: "first" | "second";
}

export interface TrainingSessionFixedImage {
  localId: number;
  key: string;
  title: string;
  downloadUrl: string;
  position: "first" | "second";
  link: string;
}

// type for client training sessions, not definition
export interface ClientTrainingSession {
  id?: string;
  type: "training-session";
  owner: string;
  client: string;
  showToClient?: boolean;
  createdAt?: any;
  updatedAt?: Date;
  trainingSessionProtocolDefId: string;
  /**
   * will be 1 or 0? if no circuit, more if circuit. The index will be the same as the section index
   */
  roundsBySection?: Array<number>;
  /**
   * duration in seconds for each section, the index will be the same as the section index
   */
  durationBySection?: Array<number>;
  links?: Array<TestReportLink>;
  tips?: string;
}

export const createEmptyClientTrainingSession = () => {
  const result: ClientTrainingSession = {
    owner: "",
    client: "",
    type: "training-session",
    trainingSessionProtocolDefId: undefined,
  };
  return result;
};

export const getTrainingSessionProtocolInitials = (
  trainingSessionProtocol: TrainingSessionProtocolDef
) => {
  const name = trainingSessionProtocol.name.toUpperCase();
  if (!name || name === "") {
    return "--";
  }
  const splittedName = name.split(" ");
  if (splittedName.length > 1) {
    return `${splittedName[0][0]}${splittedName[1][0]}`;
  } else {
    return `${name[0]}${name.length > 1 ? name[1] : name[0]}`;
  }
};

export const optionsStringToOptionsArray = (
  protocol: TrainingSessionProtocolDef
) => {
  if (!protocol.sections) {
    return;
  }
  protocol.sections.forEach((section) => {
    if (!section.fields) {
      return;
    }
    section.fields
      .filter((f) => f.type === "select")
      .forEach((f) => {
        if (!f.optionsString) {
          f.options = [];
          return;
        }
        let splitted = f.optionsString.split(",");
        splitted = splitted.filter((s) => !!s);
        f.options = splitted;
      });
  });
};

export const calculateImageKey = (
  protocolId: string,
  testLocalId: number,
  imageLocalId: number
) => {
  return `training-session-${protocolId}-${testLocalId}-${imageLocalId}`;
};

export const getCommentsClientTrainingSessionSection = (
  sectionDef: TrainingSessionSectionsDef,
  test
) => {
  const result = test[`comments-${sectionDef.localId}`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const getTipsClientTrainingSession = (test) => {
  const result = test[`tips`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const setCommentsClientTrainingSessionSection = (
  sectionDef: TrainingSessionSectionsDef,
  test,
  value
) => {
  test[`comments-${sectionDef.localId}`] = value;
};

export const setTipsClientTrainingSession = (test, value) => {
  test[`tips`] = value;
};
