export const SIZES = {
    text: 16,
    angleTextBoxW: 40,
    measureRefTextBoxW: 50,
    measureTextBoxW: 100,
    textBoxW: 20,
    textBoxH: 25,
    lineW: 2,
    tapLineW: 5,
};

export const PERCENTAGE = {
    s: .85,
    m: 1,
    l: 1.5
};

export const getSize = (key: 'text' | 'angleTextBoxW' | 'textBoxW' | 'textBoxH' | 'lineW' | string, mode: string) => {
    return SIZES[key] * PERCENTAGE[mode];
};
