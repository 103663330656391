import { Injectable } from '@angular/core';
import { Client, CLIENTS_PATH } from '../models/client.model';
import { FirestoreService } from './firestore.service';
import { SentryService } from './sentry.service';
import { AuthService } from './auth.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {
  switchMap,
  shareReplay,
  tap,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { CanIService } from './can-i.service';
import { FunctionsService } from './functions.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  currentUserClients$: Observable<Client[]>;

  currentClientId$: BehaviorSubject<string>;
  currentClient$: Observable<Client>;

  currentUserClientsCount = 0;

  constructor(
    private firestoreService: FirestoreService,
    private sentry: SentryService,
    private auth: AuthService,
    private canI: CanIService,
    private functions: FunctionsService,
  ) {
    this.initObservables();
  }

  initObservables() {
    this.currentUserClients$ = this.auth.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }
        return this.firestoreService.colWithIds$(`${CLIENTS_PATH}`, (ref) =>
          ref.where('owner', '==', user.uid).orderBy('name'),
        );
      }),
      map((data) => {
        return data.map((client) => ({
          ...client,
          isLinked: !!client.userId,
        }));
      }),
      tap((data) => {
        this.currentUserClientsCount = data.length;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('Clients', data.length);
      }),
      //   map(data => {
      //     //   data.forEach(client => {
      //     //       client['tagName$'] =
      //     //   })
      //   })
      shareReplay(1),
      tap((d) => {}),
    );

    this.currentClientId$ = new BehaviorSubject<string>(null);
    this.currentClient$ = this.currentClientId$.pipe(
      distinctUntilChanged(),
      filter((val) => val !== null),
      switchMap((id) => this.getClient$(id)),
      shareReplay(1),
    );
  }

  async addClient(clientData: Omit<Client, 'id'>) {
    try {
      if (
        !(await this.canI.requestCreateNewClient(this.currentUserClientsCount))
      ) {
        return;
      }
      const user = await this.auth.getUser();
      const client: Client = {
        ...clientData,
        id: this.firestoreService.createId(),
        owner: user.uid,
      };
      await this.firestoreService.set(`${CLIENTS_PATH}/${client.id}`, client);
      const docRef = this.firestoreService.doc(`${CLIENTS_PATH}/${client.id}`);
      return docRef;
    } catch (err) {
      this.sentry.sendException(err);
      return null;
    }
  }

  async updateClient(client: Client) {
    delete client.isLinked; // do not save this property in the database
    try {
      const result = await this.firestoreService.update(
        `${CLIENTS_PATH}/${client.id}`,
        client,
      );
      // next value for observable if is the current client
      if (this.currentClientId$.value === client.id) {
        this.currentClientId$.next(client.id);
      }
      return result;
    } catch (err) {
      this.sentry.sendException(err);
      return null;
    }
  }

  async deleteClients(clientIds: Array<string>) {
    const unlinkClientPromise = (clientId: string) =>
      new Promise(async (resolve, reject) => {
        const client = await this.firestoreService.getDocById<Client>(
          `${CLIENTS_PATH}/${clientId}`,
        );
        if (!client || !client.userId) {
          resolve(null);
        } else {
          await this.functions
            .call('unlinkClientCallable', { clientId })
            .then(resolve)
            .catch(reject);
        }
      });

    await Promise.all(
      clientIds.map(async (clientId) => {
        return unlinkClientPromise(clientId).then(async () => {
          return this.firestoreService
            .doc(`${CLIENTS_PATH}/${clientId}`)
            .delete();
        });
      }),
    );
  }

  getClient$(id: string): Observable<Client> {
    return this.firestoreService.doc$<Client>(`${CLIENTS_PATH}/${id}`).pipe(
      tap((d) => this.sentry.logFirestoreRead('Clients')),
      filter((client) => !!client),
      map((client) => ({ ...client, id, isLinked: !!client.userId })),
    );
  }

  setCurrentClient(clientId: string) {
    this.currentClientId$.next(clientId);
  }

  getThumbnailText({ name, surname }) {
    if (!name) {
      return ' ';
    }
    if (!surname) {
      return `${name.toUpperCase()[0]}`;
    }
    return `${name.toUpperCase()[0] + surname.toUpperCase()[0]}`;
  }

  async unlinkClient(clientId: string) {
    await this.functions.call('unlinkClientCallable', { clientId });
  }
}
