import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import {
  SUBSCRIPTIONS_PATH,
  Subscription,
  getSubPlan,
  getPlan,
  PlansNew,
  SubscriptionInfo,
} from '../models/subscription.model';
import { first, switchMap, map, shareReplay, tap } from 'rxjs/operators';
import { FunctionsService } from './functions.service';
import { from, Observable, of } from 'rxjs';
import { SentryService } from './sentry.service';
import {
  ACTIVE_SUBSCRIPTION_STATUSES,
  getActiveProducts,
  InteccSubscription,
  PriceIds,
  Prices,
  Products,
  StripeData,
  StripePrice,
  StripeProduct,
} from '../../shared/subscriptions.shared';
import { AppConfig } from 'src/config/app.config';
import { Timestamp } from '@angular/fire/firestore';
import { safeToPromise } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  currentUserSubscription$: Observable<{
    product: SubscriptionCardProps;
    originalProduct: StripeProduct & {
      price: StripePrice;
    };
    subscription: InteccSubscription;
  }>;
  currentUserCustomerPortalUrl$: Observable<string>;
  availableSubscriptions$: Observable<SubscriptionCardProps[]>;
  currentUserActivePrices$: Observable<Array<string>>;
  daysForTrial: number = 7;

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private sentryService: SentryService,
  ) {
    this.currentUserSubscription$ = this.authService.user$.pipe(
      map((user) => {
        const sub = user?.subscriptions?.[0];
        if (!sub) {
          return null;
        }
        return sub;
      }),
      switchMap((sub) => {
        if (!sub || !ACTIVE_SUBSCRIPTION_STATUSES.includes(sub.status)) {
          return of(null);
        }
        return from(this.getPortalAndSubscriptionInfo(sub)).pipe(
          map((info) => {
            return { info, sub };
          }),
        );
      }),
      map((value) => {
        if (!value) {
          return null;
        }
        const { info, sub } = value;
        return {
          product: info.product,
          originalProduct: info.originalProduct,
          subscription: sub,
        };
      }),
      shareReplay(1),
    );

    this.currentUserCustomerPortalUrl$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }
        return from(this.functionsService.call('stripeGetPortalUrl', {}));
      }),
    );

    this.availableSubscriptions$ = this.authService.user$.pipe(
      switchMap(() => from(this.getAvailableSubscriptions())),
      shareReplay(1),
    );
    this.currentUserActivePrices$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }
        return this.firestoreService.doc$<Subscription>(
          `${SUBSCRIPTIONS_PATH}/${user.uid}`,
        );
      }),
      tap((data) => {
        this.sentryService.logFirestoreRead(SUBSCRIPTIONS_PATH);
      }),
      map((sub) => {
        if (sub === null || sub === undefined) {
          return [PriceIds.VAFStartFree];
        }
        return this.getActivePrices(sub);
      }),
      shareReplay(1),
    );
  }

  async hasActiveSubscription(planNames: Array<string>): Promise<boolean> {
    const user = await this.authService.getUser();
    const sub = await safeToPromise(
      this.firestoreService
        .doc$<Subscription>(`${SUBSCRIPTIONS_PATH}/${user.uid}`)
        .pipe(first()),
    );
    this.sentryService.logFirestoreRead(SUBSCRIPTIONS_PATH);
    const codes: Array<string> = [];
    planNames.forEach((planName) => {
      const subPlans = PlansNew[planName].subplans.map((sp) => sp.id);
      codes.push(...subPlans);
    });
    if (!sub) {
      return false;
    } else {
      const activeSubsKeys = Object.keys(sub).filter(
        (key) => sub[key].isActive,
      );
      return activeSubsKeys.some((key) =>
        codes.some((code) => sub[key].planId === code),
      );
    }
  }

  get timestamp() {
    return Timestamp.now().toDate();
  }

  async hasTrial() {
    const user = await this.authService.getUser();
    if (!user || user.role === 'client') {
      return false;
    }
    const random = Math.random();
    if (!user.trialStartedDate) {
      console.log(random, ' null trial');
      user.trialStartedDate = this.timestamp;
      await this.authService.updateUser(user);
      return true;
    }

    const trialStartedDate = user.trialStartedDate?.toDate();

    const date1 = trialStartedDate;
    const date2 = new Date();

    // To calculate the time difference of two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    if (diffInDays > this.daysForTrial) {
      return false;
    }

    return true;
  }

  async daysLeftInTrial() {
    const user = await this.authService.getUser();
    if (!user || user.role === 'client') {
      return 0;
    }
    const random = Math.random();
    if (!user.trialStartedDate) {
      console.log(random, ' null trial');
      user.trialStartedDate = this.timestamp;
      await this.authService.updateUser(user);
      return 7;
    }
    const trialStartedDate = user.trialStartedDate?.toDate();

    const date1 = trialStartedDate;
    const date2 = new Date();

    // To calculate the time difference of two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return this.daysForTrial - Math.round(diffInDays);
  }

  async getCurrentAppSubscriptions() {
    const activePrices = await safeToPromise(
      this.currentUserActivePrices$.pipe(first()),
    );
    console.log('activePrices', activePrices);
    if (!activePrices) {
      return null;
    }
    const activeProducts = getActiveProducts(activePrices);
    // const currentAppActiveProducts = activePrices.filter(a => Products[Prices[a].productIds[0]].parentProductIds.some(pp => pp === AppConfig.app));
    const currentAppActiveProducts = activePrices.filter((a) =>
      Products[Prices[a].productIds[0]].parentProductIds.some(
        (pp) => pp === 'vaf',
      ),
    );
    return currentAppActiveProducts.map((pId) => Prices[pId]);
  }

  buildCurrentSubInfo(sub: Subscription): SubscriptionInfo {
    if (!sub) {
      return null;
    }
    const activeSub = Object.keys(sub).find(
      (key) => sub[key].isActive && !!sub[key].planId,
    );
    if (activeSub === undefined) {
      // the current user doesn't have a subscription
      return null;
    }
    const activePlan = sub[activeSub].planId;
    const subPlan = getSubPlan(activePlan);
    const plan = getPlan(activePlan);
    if (!subPlan || !plan) {
      return null;
    }
    return {
      name: plan.name,
      planId: plan.planId,
      subPlanId: subPlan.id,
      price: subPlan.description,
      offer: subPlan.offer,
    };
  }

  getActivePrices(sub: Subscription): Array<string> {
    const activeEntries = Object.keys(sub).filter(
      (key) => sub[key].isActive && !!sub[key].planId,
    );
    const result = activeEntries.map((key) => sub[key].planId);
    if (result.filter((r) => r === PriceIds.VAFStartFree).length === 0) {
      result.push(PriceIds.VAFStartFree);
    }
    return result;
  }

  async getActiveSubscription(): Promise<InteccSubscription> {
    const user = await this.authService.getUser();
    const subscription = user?.subscriptions?.[0];
    if (!subscription) {
      return null;
    }
    return subscription;
  }

  async openCheckoutSession(priceId: string) {
    const checkoutSessionResponse = await this.functionsService.call(
      'stripeCreateCheckoutForSubscription',
      {
        planId: priceId,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
      },
    );
    const checkoutSession = checkoutSessionResponse.data;
    window.location.href = checkoutSession.url;
  }

  async cancelCurrentSubscription() {
    const result = await this.functionsService.call(
      'stripeCancelActiveSubscriptions',
      { appName: AppConfig.app },
    );
    console.log('Cancel result', result);
  }

  async getAvailableSubscriptions() {
    const availableSubscriptions = (await this.functionsService.call(
      'stripeGetAvailableSubscriptions',
      {},
    )) as StripeData;

    return this.mergeStripeData(availableSubscriptions);
  }

  async getPortalAndSubscriptionInfo(currentSubscription: InteccSubscription) {
    const response = (await this.functionsService.call(
      'stripeSubscriptionInfo',
      {
        productId: currentSubscription.productId,
      },
    )) as {
      product: StripeProduct & {
        price: StripePrice;
      };
    };
    const { product } = response;
    const { price, ...restProduct } = product;
    const finalProduct = (
      await this.mergeStripeData({
        products: [restProduct],
        prices: [price],
      })
    )[0];
    return {
      originalProduct: product,
      product: finalProduct,
    };
  }

  async mergeStripeData(data: StripeData): Promise<SubscriptionCardProps[]> {
    const result: SubscriptionCardProps[] = [];
    const userSubscription = await this.getActiveSubscription();
    const userSubscriptionIsActive =
      userSubscription &&
      userSubscription.currentPeriodEnd > Date.now() &&
      ACTIVE_SUBSCRIPTION_STATUSES.includes(userSubscription.status);

    const periodTranslations = {
      day: 'día',
      week: 'semana',
      month: 'mes',
      year: 'año',
    };
    const periodPlurals = {
      day: 'días',
      week: 'semanas',
      month: 'meses',
      year: 'años',
    };

    for (const price of data.prices) {
      const product = data.products.find((p) => p.id === price.product);

      if (product) {
        const intervalCountText =
          price.recurring.interval_count > 1
            ? `cada ${price.recurring.interval_count} `
            : '';
        const intervalText =
          price.recurring.interval_count > 1
            ? periodPlurals[price.recurring.interval]
            : '/' + periodTranslations[price.recurring.interval];

        const formattedPrice = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: price.currency,
          minimumFractionDigits: price.unit_amount % 100 === 0 ? 0 : 2,
        })
          .format(price.unit_amount / 100)
          .replace(/\s/g, '');
        const subscriptionCard: SubscriptionCardProps = {
          name: product.name,
          stripePrice: price.unit_amount,
          price: formattedPrice,
          billingPeriod: `${intervalCountText}${intervalText}`,
          description: price.metadata.description || product.description,
          bulletPoints: product.marketing_features.map(
            (feature) => feature.name,
          ),
          productId: product.id,
          priceId: price.id,
          trialAvailable: price.recurring.trial_period_days > 0,
          trialAvailableDays: price.recurring.trial_period_days,
          isRecommended: price.metadata.isRecommended === 'true',
          isUserActiveSubscription:
            userSubscriptionIsActive &&
            userSubscription.productId === product.id,
          active: product.active,
          showInApp:
            product.metadata.showInApp === 'true' &&
            price.metadata.showInApp === 'true',
        };

        result.push(subscriptionCard);
      }
    }
    return result;
  }
}

export interface SubscriptionCardProps {
  name: string;
  stripePrice?: number;
  /** price formatted to locale */
  price: string;
  billingPeriod: string;
  description: string;
  bulletPoints: Array<string>;
  productId: string;
  priceId: string;
  trialAvailable: boolean;
  trialAvailableDays?: number;
  isRecommended?: boolean;
  active?: boolean;
  isUserActiveSubscription?: boolean;
  comparedAtPrice?: string;
  showInApp: boolean;
}
