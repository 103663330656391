<div class="relative h-full w-full">
  <div
    *ngIf="trainingSession"
    class="animated fadeIn mx-auto max-w-4xl rounded-xl bg-white shadow-sm ring-1 ring-black/5 transition-all duration-200"
  >
    <div
      class="flex flex-col items-center px-8 py-6 md:flex-row md:justify-between"
    >
      <h1 class="mb-3 text-center text-3xl font-bold md:mb-0">
        {{
          trainingSession.name
            ? trainingSession.name
            : 'Sesión de entrenamiento'
        }}
      </h1>
      <div class="flex flex-col items-center md:flex-row">
        <app-two-state-button
          class="mb-3 md:mb-0 md:mr-4"
          (buttonClick)="duplicate()"
          [loading]="duplicating"
          [text]="'Añadir a mis tests'"
          [type]="'normal'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-copy'"
        ></app-two-state-button>
      </div>
    </div>
  </div>
</div>
<div class="mb-10"></div>
