<div class="">
  <div *ngIf="test$ | async as test" class="test-view">
    <div *ngIf="client$ | async as client">
      <div [ngSwitch]="test.type">
        <app-sagital-test
          [isUserClientView]="isUserClientView"
          *ngSwitchCase="'sagital'"
          [test]="test"
          [client]="client"
        ></app-sagital-test>
        <app-posture
          [isUserClientView]="isUserClientView"
          *ngSwitchCase="'posture'"
          [test]="test"
          [client]="client"
        ></app-posture>
        <app-myvaf-protocol
          [isUserClientView]="isUserClientView"
          *ngSwitchCase="'my-vaf'"
          [test]="test"
          [client]="client"
        ></app-myvaf-protocol>
        <ng-container *ngIf="!isUserClientView">
          <app-sagital-img-test
            *ngSwitchCase="'sagital-img'"
            [test]="test"
            [client]="client"
          ></app-sagital-img-test>
          <app-sagital-flechas
            *ngSwitchCase="'sagital-flechas'"
            [test]="test"
            [client]="client"
          ></app-sagital-flechas>
          <app-rom-test
            *ngSwitchCase="'rom'"
            [test]="test"
            [client]="client"
          ></app-rom-test>
          <app-foot
            *ngSwitchCase="'foot'"
            [test]="test"
            [client]="client"
          ></app-foot>
          <app-rom-short
            *ngSwitchCase="'rom-short'"
            [test]="test"
            [client]="client"
          ></app-rom-short>
        </ng-container>
      </div>
    </div>
  </div>
</div>
