<div class="content">
  <div *ngIf="authService.user$ | async as user">
    <div *ngIf="appConfig.app !== 'vaf'" class="greeting">
      <p class="greeting__text">
        Hola, <b>{{ user.displayName }}</b>
      </p>
      <!-- <div class="greeting__button">

                <div class="menu-button">
                    <button class="menu-button__button button-icon-plain" (click)="menuOpen = !menuOpen"><i class="fas fa-user-cog"></i></button>
                    <div class="menu-button__overlay" [class.menu-button__overlay--open]="menuOpen" (click)="menuOpen = false"></div>
                    <div class="menu-button__menu" [class.menu-button__menu--open]="menuOpen">
                        <button class="menu-button__menu__button" (click)="openAccountModal()">Mi cuenta</button>
                        <button class="menu-button__menu__button" (click)="editReportsConfig()">Personalizar informes</button>
                        <button class="menu-button__menu__button" (click)="openExportTestsModal()">Exportar valoraciones</button>
                        <a title="aplicaciones@intecc.org" href="mailto:aplicaciones@intecc.org" class="menu-button__menu__button">Soporte</a>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <section class="clients">
    <div class="flex-wrap items-center justify-between sm:flex">
      <div class="flex items-center justify-between sm:mr-4">
        <app-back-button classes="mr-4" />
        <h2 class="section-heading">Mis clientes</h2>
        <a
          href="https://manual.intecc.org/es/article/mis-clientes"
          target="_blank"
          rel="noopener noreferrer"
          class="hidden cursor-pointer text-xl sm:ml-2 sm:block"
        >
          <i class="fas fa-graduation-cap text-intecc2"></i>
        </a>
        <app-back-button
          classes="pointer-events-none text-transparent sm:hidden"
        />
      </div>
      <div class="mb-4 text-center sm:hidden">
        <a
          href="https://manual.intecc.org/es/article/mis-clientes"
          target="_blank"
          rel="noopener noreferrer"
          class="cursor-pointer text-xl"
        >
          <i class="fas fa-graduation-cap text-intecc2"></i>
        </a>
      </div>
      <button
        (click)="openAddClientModal()"
        class="mx-auto flex items-center rounded-lg bg-primary-6 px-3 py-[1.5rem] text-white transition-all sm:mx-0"
      >
        <div class="new-button-regular-with-icon__icon">
          <i class="fas fa-user-plus"></i>
        </div>
        <div class="new-button-regular-with-icon__text">Añadir cliente</div>
      </button>
      <!-- <button routerLink="/add-client" class="button-icon-plain"><i class="fas fa-user-plus"></i></button> -->
    </div>

    <div class="clients__filter-search">
      <app-filter-search></app-filter-search>
    </div>

    <div
      class="clients__bulk-actions bulk-actions-bar"
      [class.bulk-actions-bar--active]="getSelectedClientsCount() > 0"
    >
      <div class="bulk-actions-bar__selected-info">
        <span class="bulk-actions-bar__selected-count"
          >{{ getSelectedClientsCount() }} </span
        >{{
          getSelectedClientsCount() > 1 || getSelectedClientsCount() === 0
            ? 'clientes seleccionados'
            : 'cliente seleccionado'
        }}
      </div>
      <div class="bulk-actions-bar__actions">
        <button
          (click)="removeSelection()"
          class="bulk-actions-bar__action-button"
        >
          <i class="far fa-times-circle"></i>
        </button>
        <div class="bulk-actions-bar__separator"></div>
        <button
          (click)="deleteClients()"
          class="bulk-actions-bar__action-button bulk-actions-bar__action-button--small"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="clients__content">
      <ul
        *ngIf="currentUserClientsFiltered$ | async as currentUserClients"
        class="clients-list"
      >
        <!-- <li *ngFor="let client of currentUserClients" class="clients-list__item">
          <a class="clients-list__link">
            <div class="clients-list__content">
              <div class="clients-list__img" [class.clients-list__img--selected]="isClientSelected(client)"
                (click)="selectClient(client)">{{clientsService.getThumbnailText(client)}}</div>
              <a class="clients-list__link-content" [routerLink]="['/', 'client', client.id]">
                <div class="clients-list__name">
                  <p class="clients-list__name__name">{{client.name}}</p>
                  <p class="clients-list__name__surname"> {{client.surname}}</p>
                </div>
                <div *ngIf="getTagName$(client.tagId) | async as tag" [class.clients-list__tag--no-tag]="tag.id<0"
                  class="clients-list__tag">
                  <div>
                    <p class="client-list__tag__name">{{tag.name}}</p>
                  </div>

                </div>
                <div class="clients-list__date"><span *ngIf="client.createdAt">{{client.createdAt.toDate() |
                    date}}</span></div>
                <div class="clients-list__button"><i class="fas fa-chevron-right"></i></div>
              </a>
            </div>
            <div class="clients-list__separator">
            </div>
          </a>
        </li> -->
        <li *ngFor="let client of currentUserClients" class="">
          <a class="grid grid-cols-auto-fr-auto items-center gap-x-5 px-4 py-3">
            <div
              class="clients-list__img relative cursor-pointer"
              [class.clients-list__img--selected]="isClientSelected(client)"
              (click)="selectClient(client)"
            >
              {{ clientsService.getThumbnailText(client) }}
              <button
                (click)="openLinkClientModal(client); $event.stopPropagation()"
                class="absolute bottom-0 left-1/2 flex h-4 w-4 -translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full p-0.5 text-white"
                [class.bg-green-500]="client.isLinked"
                [class.bg-red-500]="!client.isLinked"
                [matTooltip]="
                  client.isLinked
                    ? 'Cliente vinculado a usuario'
                    : 'Cliente no vinculado a usuario'
                "
              >
                <i *ngIf="client.isLinked" class="fas fa-user text-xs"></i>
                <i *ngIf="!client.isLinked" class="fas fa-user text-xs"></i>
              </button>
            </div>
            <a class="" [routerLink]="['/', 'client', client.id]">
              <div
                class="items-center justify-between md:grid md:grid-cols-1.5fr-2fr-2fr"
              >
                <p class="mb-1 font-bold">{{ client.name }}</p>
                <p class="text-neutral-600">{{ client.surname }}</p>

                <div class="flex justify-between">
                  <div class="clients-list__date">
                    <span *ngIf="client.createdAt">{{
                      client.createdAt.toDate() | date
                    }}</span>
                  </div>
                  <div
                    *ngIf="getTagName$(client.tagId) | async as tag"
                    [class.clients-list__tag--no-tag]="tag.id < 0"
                    class="clients-list__tag mb-1 ml-5 mt-1 inline-block"
                  >
                    <div>
                      <p class="client-list__tag__name">{{ tag.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a
              [routerLink]="['/', 'client', client.id]"
              class="clients-list__button"
              ><i class="fas fa-chevron-right"></i
            ></a>
          </a>
          <div class="clients-list__separator"></div>
        </li>
      </ul>
    </div>
  </section>

  <!-- <section class="section">
        <h2 class="section-heading">Contenido de la academia</h2>
        <div class="">
            
        </div>
    </section> -->
</div>

<!-- <div class="info">
    <img src="../../../assets/images/intecc-icon.png" alt="" class="intecc-logo">
    <a href="https://www.intecc.org/tecnologia/tecnologia-i-d-i" class="intecc-link">INTECC I+D+i</a>
    <p class="version-number" title="{{isProductionEnvironment ? 'Production' : 'Development'}}">
        <span *ngIf="appConfig.app === 'rom'">App Rom v2.02</span>
        <span *ngIf="appConfig.app === 'raquis'">App Raquis v2.02</span>
    </p>
</div> -->
<!-- <div class="text-center flex justify-center uppercase text-intecc font-bold text-sm hover:underline">
  <a class="" href="http://intecc.org" target="_blank" rel="noopener noreferrer">intecc.org</a>
</div> -->
<div class="final-space"></div>
