import { TestImageValue } from "./test-images.shared";

export const TESTS_PATH = "tests";
export interface Test {
  id?: string;
  type: TestType;
  owner: string;
  client: string;
  showToClient?: boolean;
  createdAt?: any;
  updatedAt?: Date;
}

export interface TestWithImages {
  images: { [id: string]: TestImageValue };
}

export interface TestResult {
  normal?: boolean;
  text: string;
  tips?: Array<string>;
}

export enum TestType {
  Sagital = "sagital",
  SagitalImg = "sagital-img",
  SagitalFlechas = "sagital-flechas",
  Rom = "rom",
  Posture = "posture",
  Foot = "foot",
  RomShort = "rom-short",
  MyVaf = "my-vaf",
  Avatar = "avatar",
  TrainingAvatar = "training-avatar",
}

export const TestNames = {
  avatar: "Protocolo VAF ROM - Avatar",
  "training-avatar": "Avatar entrenamiento",
  sagital: "Protocolo VAF Raquis",
  "sagital-img": "Protocolo VAF Raquis",
  "sagital-flechas": "Flechas Sagitales",
  rom: "Rango de Movimiento",
  posture: "Protocolo VAF Posture",
  foot: "Protocolo VAF Foot",
  "rom-short": "Protocolo VAF ROM",
};

export const TestIcons = {
  sagital: "fas fa-database",
  "sagital-img": "fas fa-file-image",
  "sagital-flechas": "fas fa-ruler-vertical",
  posture: "fas fas fa-compress",
  rom: "fas fa-drafting-compass",
  foot: "fas fa-shoe-prints",
  "rom-short": "fas fa-drafting-compass",
};

export type TestFieldType = "select" | "text" | "number";

export interface TestField {
  title: string;
  type: TestFieldType;
  leftRight?: boolean;
  values: Array<string>;
  goodValues: Array<number>;
}

export interface LeftRigthValue {
  left: number | string;
  right: number | string;
}

export const isLeftRight = (value: any) => {
  return "left" in value;
};

export interface TestFieldValue {
  value: string;
}

export interface TestReportLink {
  title: string;
  url: string;
}
