<div class="content">
    <div class="title">{{data.title}}</div>
    <div class="small-divider divider"></div>
    <div class="section-container ">
        <!-- <div class="section section--1">
            <div class="section__heading">
                <div class="section__icon">
                    <i class="fas fa-male"></i>
                </div>
                <div class="section__title">Posición del cliente</div>
            </div>
            <div class="section__content">
                <ul class="list">
                    <li *ngFor="let position of data.position">{{position}}</li>
                </ul>
            </div>
        </div> -->
        <div class="section section--max-width">
            <div class="section__heading">
                <div class="section__icon section__icon--small">
                    <i class="fas fa-drafting-compass"></i>
                </div>
                <div class="section__title">Valores normativos</div>
            </div>
            <div class="section__content">
                <div *ngFor="let normalValue of data.normalValues" class="normal-values">
                    <div class="normal-values-title">{{normalValue.sectionName}}</div>
                    <app-normal-value-viewer [names]="normalValue.names" [values]="normalValue.values" [results]="normalValue.results">
                    </app-normal-value-viewer>
                </div>
            </div>
        </div>
        <!-- <div class="section section--3">
            <div class="section__heading">
                <div class="section__icon section__icon--small">
                    <i class="fas fa-graduation-cap"></i>
                </div>
                <div class="section__title">Manual VAF</div>
            </div>
            <div class="section__content">
                <div class="video-list">
                    <button *ngFor="let video of videos" (click)="openVideo(video.vimeoId)" class="video-list__item">
                        
                        <div *ngIf="video.vimeoId!==null" class="video-container">
                            <div class='embed-container'><iframe [src]="video.vimeoUrl" width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div>
                        </div>
                    </button>
                </div>
            </div>
        </div> -->
        <div class="section section--3">
            <div class="section__heading">
                <div class="section__icon section__icon--small">
                    <i class="fas fa-graduation-cap"></i>
                </div>
                <div class="section__title">Manual VAF</div>
            </div>
            <div class="section__content">
                <div class="flex justify-center">
                    <a *ngFor="let url of data.manualURL" href="{{url}}" target="_blank" rel="noopener noreferrer" class=" rounded-lg flex justify-center items-center bg-intecc2 py-3 px-4 text-white transition-all duration-200 hover:bg-intecc2/70">
                        <span class="mr-2 text-white">  Ver manual</span>
                        <i class="fas fa-chevron-right text-white"></i>
                    </a>
                </div>
            </div>
        </div>
        <!-- <div class="section section--full-width section--4">
            <div class="section__heading">
                <div class="section__icon section__icon--small">
                    <i class="far fa-images"></i>
                </div>
                <div class="section__title">Realización del test</div>
            </div>
            <div class="section__content">
                <div class="gif-list">
                    <div *ngFor="let gif of data.gifs" class="gif-list__item">
                        <div class="gif-list__item__title">{{gif.name}}</div>
                        <div class="gif-list__item__img-container">
                            <img class="gif-list__item__img" src="{{LearnGifs[gif.gifId]}}">
                        </div>
                    </div>
    
                </div>
            </div>
        </div> -->
    </div>
</div>