export const MEDIA_PATH = "media";
export interface MediaItem {
  id?: string;
  owner?: string;
  client?: string;
  bucket?: string;
  downloadURL?: string;
  size?: string;
  processed?: boolean;
  downloadURLMap?: any;
  deleted?: boolean;
  deleteProcessed?: boolean;
  isGlobal?: boolean;
}
