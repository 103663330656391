<div class="tag-selector">
    <div *ngIf="selectedTag" (click)="toggle();" class="tag-selector__current">
        <p>{{selectedTag.name}}</p>
        <i class="fas fa-chevron-down" [class.fa-rotate-180]="editMode" ></i>
        
    </div>
    <div *ngIf="editMode" class="tag-selector__selector animated fadeIn">
        <div class="tag-selector__selector-heading">
            <input #searchInput [value]="inputValue" (keydown)="onKeydown($event)" (input)="onSearch($event.target.value)" placeholder="Nombre del grupo" class="tag-selector__selector-input" type="text">
            <div (click)="setNoTag()" class="tag-selector__selector-delete-tag">
                <i class="fas fa-times"></i>
            </div>
        </div>
        <div class="tag-selector__selector-divider"></div>
        <div class="tag-selector__selector-list">
            <div *ngFor="let result of filteredTags; let i = index;" class="tag-selector__selector-list-item" [class.tag-selector__selector__list__item--active]="i === 0">
                <div (click)="selectTag(result);" class="tag-selector__selector-list-item-container">
                    <span *ngIf="result.id === UnsavedTagId" class="tag-selector__selector-list-item-new">Nuevo grupo: </span>
                    <span class="tag-selector__selector-list-item-name">{{result.name}}</span>
                </div>
                <div (click)="deleteTag(result)" class="tag-selector__selector-list-item-action">
                    <i class="fas fa-trash"></i>
                </div>
                    
                
            </div>
        </div>
    </div>
</div>
