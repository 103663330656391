export const QUESTIONNAIRE_PATH = "questionnaires";

export const studyLevelOptions = [
  { label: "Primarios", value: "primary" },
  { label: "Secundarios", value: "secondary" },
  { label: "FP", value: "fp" },
  { label: "Universitarios", value: "university" },
  { label: "Doctorado", value: "doctorate" },
] as const;

export const yesNoOptions = [
  { label: "No", value: "no" },
  { label: "Sí", value: "yes" },
] as const;

export const timeOptions = [
  { label: "Menos de 7 días", value: "lessThan7Days" },
  { label: "De 7 a 21 días", value: "between7And21Days" },
  { label: "Más de 21 días", value: "moreThan21Days" },
  { label: "Más de 2 meses", value: "moreThan2Months" },
] as const;

export const treatmentOptions = [
  { label: "Conservador", value: "conservative" },
  { label: "Quirúrgico", value: "surgical" },
] as const;

export const billingPreferencesOptions = [
  { label: "No necesito factura", value: "noNeed" },
  { label: "Factura personal", value: "personal" },
  { label: "Factura empresarial", value: "company" },
] as const;

export interface InjuryOrPathology {
  name: string;
  startDate: Date; // fecha de inicio
  relapse: (typeof yesNoOptions)[number]["value"]; // recaída
  time: (typeof timeOptions)[number]["value"];
  treatment: (typeof treatmentOptions)[number]["value"];
  comments: string; // textarea
}

export interface Questionnaire {
  id: string;
  userId: string;
  // fields
  // Datos personales
  name: string;
  surname: string;
  birthDate: Date;
  dni: string;
  email: string;
  phone: string;
  studiesLevel: (typeof studyLevelOptions)[number]["value"];
  currentJob: string;
  dailyHours: number;
  physicalEffort: number; // 0 - 10
  currentWeight: number;
  height: number;

  // Autovaloración (0 - 10)
  physicalCondition: number;
  animicStatus: number;
  nutritionalStatus: number;
  sleepHours: number;
  sleepQuality: number;
  dailyIntakes: number;
  painLevel: number;
  whatWouldYouImprove: string; // textarea

  // Hábitos y rutinas
  dailyActivities: string; // textarea
  laboralActivities: string; // textarea
  sportsActivities: string; // textarea
  nutritionalHabits: string; // textarea
  hasDoneDiet: (typeof yesNoOptions)[number]["value"];
  hasHadCavities: (typeof yesNoOptions)[number]["value"];
  hasHadDentalCorrection: (typeof yesNoOptions)[number]["value"];
  hasUsedInsoles: (typeof yesNoOptions)[number]["value"];

  // Lesiones y patologías
  injuries: InjuryOrPathology[];
  pathologies: InjuryOrPathology[];

  // Facturación
  billingPreferences: (typeof billingPreferencesOptions)[number]["value"];
  clientName: string;
  clientDniNif: string;
  clientAddress: string;
  clientEmail: string;

  acceptServiceTerms: boolean;
  acceptServiceTermsAndPrivacyPolicy: boolean;

  // metadata
  createdAt: Date;
  updatedAt: Date;
}
