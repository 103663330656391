import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SVG, Svg, Image } from '@svgdotjs/svg.js';
import { CropEdit } from 'src/app/models/media/crop-item.class';
import { Drawer } from 'src/app/models/media/drawer.interface';
import { EditItem } from 'src/app/models/media/edit-items.model';
import { EditedMediaItem } from 'src/app/models/media/edited-media-item.model';
import { MediaEditService } from 'src/app/services/media/media-edit.service';
import { sleep } from 'src/app/utils/utils';

@Component({
    selector: 'app-edited-image-view',
    templateUrl: './edited-image-view.component.html',
    styleUrls: ['./edited-image-view.component.scss']
})
export class EditedImageViewComponent implements OnInit, Drawer {

    pItem: EditedMediaItem;

    get item(): EditedMediaItem {
        return this.pItem;
    }

    @Input() set item(v: EditedMediaItem) {
        this.pItem = v;
        this.init();
    }

    @ViewChild('divElement', { static: true })
    svgDivElement: ElementRef;

    @ViewChild('svgElement', { static: true })
    svgElement: ElementRef;

    svgObject: Svg;

    svgH = 0;
    svgW = 0;

    imageNestedSvg: Svg;

    viewBoxX = 0;
    viewBoxY = 0;
    viewBoxW = 0;
    viewBoxH = 0;

    imageAbsoluteH = 0;
    imageAbsoluteW = 0;
    imageAbsoluteX = 0;
    imageAbsoluteY = 0;

    cropEdit = CropEdit.create(0, 0, 1, 1);

    editItems: Array<EditItem> = [];

    naturalImageSize: { w: number, h: number };
    zoomLevel = 1;

    constructor(private mediaEditService: MediaEditService) { }
    getInput(title: string, callback: any) {
        throw new Error('Method not implemented.');
    }
    selectedEvent(index: number) {
        return;
    }
    relativeToAbsoluteX(value: number): number {
        const result = value * this.imageAbsoluteW + this.imageAbsoluteX;
        return result;
    }
    relativeToAbsoluteY(value: number): number {
        const result = value * this.imageAbsoluteH + this.imageAbsoluteY;
        return result;
    }

    relativeToAbsoluteW(value: number): number {
        const result = value * this.imageAbsoluteW;
        return result;
    }
    relativeToAbsoluteH(value: number): number {
        const result = value * this.imageAbsoluteH;
        return result;
    }

    absoluteToRelativeX(value: number): number {
        const result = value / this.imageAbsoluteW;
        return result;
    }
    absoluteToRelativeY(value: number): number {
        const result = value / this.imageAbsoluteH;
        return result;
    }

    absoluteToRelativeX2(value: number): number {
        const result = (value - this.imageAbsoluteX) / this.imageAbsoluteW;
        return result;
    }

    absoluteToRelativeY2(value: number): number {
        const result = (value - this.imageAbsoluteY) / this.imageAbsoluteH;
        return result;
    }

    ngOnInit(): void {
        console.log('')
        // this.init();
    }

    async init() {
        this.svgObject = SVG(this.svgElement.nativeElement) as Svg;
        this.svgObject.clear();
        await this.loadImageSize();
        await sleep(200);
        this.svgH = this.svgObject.node.getBoundingClientRect().height;
        this.svgW = this.svgObject.node.getBoundingClientRect().width;
        this.viewBoxH = this.svgH;
        this.viewBoxW = this.svgW;
        this.loadCrop();
        this.populateItems();
        this.drawImage();
        this.drawItems();
    }

    async loadImageSize() {
        let image: Image;
        const promise = new Promise<any>((resolve) => {
            image = this.svgObject.image(this.item.downloadURL, (event) => {
                resolve(event);
            });
        });
        const result = await promise;
        this.naturalImageSize = { w: result.target.naturalWidth, h: result.target.naturalHeight };
        this.svgObject.clear();
    }

    drawImage() {
        this.imageNestedSvg = this.svgObject.nested();
        this.imageNestedSvg.node.setAttribute('preserveAspectRatio', 'xMinYMin meet');
        const image = this.imageNestedSvg.image(this.item.downloadURL);
        const imageW = this.naturalImageSize.w;
        const imageH = this.naturalImageSize.h;
        const imageAR = imageW / imageH;
        const croppedImageW = this.cropEdit.data.w * imageW;
        const croppedImageH = this.cropEdit.data.h * imageH;
        const croppedImageAR = croppedImageW / croppedImageH;
        const boardW = this.svgW;
        const boardH = this.svgH;
        let newImageW;
        let newImageH;
        let newCroppedImageW;
        let newCroppedImageH;
        const targetW = boardW;
        const targetH = boardH;

        newCroppedImageW = targetW;
        newCroppedImageH = (1 / croppedImageAR) * newCroppedImageW;
        if (newCroppedImageH > targetH) {
            newCroppedImageH = targetH;
            newCroppedImageW = croppedImageAR * newCroppedImageH;
        }

        newImageW = newCroppedImageW;
        newImageH = (1 / imageAR) * newImageW;
        if (newImageH < newCroppedImageH) {
            newImageH = newCroppedImageH;
            newImageW = imageAR * newImageH;
        }

        image.size(newImageW, newImageH);
        // image.size(imageW, imageH);
        this.imageNestedSvg.size(newCroppedImageW, newCroppedImageH);
        // this.imageNestedSvg.viewbox(newImageW * this.cropEdit.x, newImageH * this.cropEdit.y, newImageW * this.cropEdit.w, newImageH * this.cropEdit.h);
        this.imageNestedSvg.viewbox(newImageW * this.cropEdit.data.x, newImageH * this.cropEdit.data.y, newCroppedImageW * this.cropEdit.data.w, newCroppedImageH * this.cropEdit.data.h);

        const imageX = boardW / 2 - newCroppedImageW / 2;
        const imageY = boardH / 2 - newCroppedImageH / 2;
        image.x(0);
        image.y(0);
        this.imageNestedSvg.x(imageX);
        this.imageNestedSvg.y(imageY);

        this.imageAbsoluteH = newCroppedImageH;
        this.imageAbsoluteW = newCroppedImageW;
        this.imageAbsoluteX = imageX;
        this.imageAbsoluteY = imageY;

    }

    loadCrop() {
        if (this.item.crop) {
            this.cropEdit = this.mediaEditService.buildEditItemFromDB(this.item.crop) as CropEdit;
        } else {
            this.cropEdit = CropEdit.create(0, 0, 1, 1);
        }
        this.cropEdit.init(this, -1);
    }

    drawItems() {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < this.editItems.length; index++) {
            const element = this.editItems[index];
            element.sizeMode = 's';
            this.drawItem(element, index);
        }
    }

    drawItem(item: EditItem, index: number) {
        item.init(this, index, false);
        item.draw();
    }

    populateItems() {
        this.editItems = [];
        const items = this.item.edits;
        if (!items) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < items.length; index++) {
            const itemDB = items[index];
            // const item = this.buildEditItemFromDB(itemDB);
            const item = this.mediaEditService.buildEditItemFromDB(itemDB);
            this.editItems.push(item);
        }
        this.initEditItems(this.editItems);
    }

    initEditItems(items: Array<EditItem>) {
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            item.init(this, index);
        }
    }

}
