import { Component, OnInit, Input } from '@angular/core';
import { TestResult } from '../../../shared/test.outside';

@Component({
  selector: 'app-test-result-tag',
  templateUrl: './test-result-tag.component.html',
  styleUrls: ['./test-result-tag.component.scss']
})
export class TestResultTagComponent {

  @Input() result: TestResult;

  constructor() { }

}
