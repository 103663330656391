export const USERS_PATH = "users";

import { InteccSubscription } from "./subscriptions.shared";

export interface User {
  uid: string;
  clientId?: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified?: boolean;
  emailVerificationSent?: boolean;
  conditionsAccepted?: boolean;
  trialStartedDate?: any;
  subscriptions?: InteccSubscription[];
  role: "admin" | "professional" | "client";
}
