<div
  class="relative mx-auto max-h-[90vh] w-[100vw] w-full max-w-[90vw] overflow-y-auto bg-white p-6 sm:max-w-xl"
>
  <div (click)="close()" class="absolute right-5 top-2 cursor-pointer">
    <i class="fas fa-times text-lg text-gray-500 hover:text-gray-700"></i>
  </div>

  <ng-container [ngSwitch]="loadingState">
    <ng-container *ngSwitchCase="'loading'">
      <div class="flex h-40 items-center justify-center">
        <i class="fas fa-spinner fa-spin text-3xl text-blue-500"></i>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="p-4 text-center text-red-500">
        <i class="fas fa-exclamation-circle mr-2"></i>
        Error al cargar.
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <ng-container *ngIf="client$ | async as client">
        <!-- title -->
        <div class="mb-4">
          <h3 class="mb-2 text-xl font-bold">Vincular cliente</h3>
        </div>
        <!-- info message -->
        <div
          *ngIf="professionalSubscriptionType !== 'InteccElite'"
          class="mb-4 rounded-lg border border-blue-200 bg-blue-50 p-4 text-blue-800"
        >
          <div class="flex">
            <i class="fas fa-info-circle mr-3 mt-1"></i>
            <p>
              ¿Sabías que con la versión Élite puedes controlar todo lo que ven
              tus clientes en su propia Aplicación? Mejora tus ingresos, tus
              servicios y tu marca personal ¡Pásate a la Élite!
            </p>
          </div>
        </div>
        <button
          (click)="editClient(client)"
          class="mb-4 text-intecc2 hover:font-semibold"
        >
          Editar cliente <i class="fas fa-pencil ml-1"></i>
        </button>

        <!-- description -->
        <div class="mb-6">
          <div class="mb-2">
            <div>
              <span class="font-semibold">Cliente:</span>
              {{ client.name }} {{ client.surname }}
            </div>
            <div>
              <span class="mr-1 font-semibold">Email:</span>
              <span *ngIf="client.email">{{ client.email }}</span>
              <span *ngIf="!client.email" class="text-red-500"
                >Debe tener un email de google antes de ser invitado</span
              >
            </div>
          </div>
          <p *ngIf="!client.isLinked">
            Este cliente no está vinculado a ningún usuario. Asegúrate de que el
            correo electrónico que tienes guardado en su ficha de cliente sea el
            mismo que tiene o va a tener el cliente en su cuenta de usuario
            <strong>(debe ser de google)</strong>.
          </p>
          <p *ngIf="client.isLinked">
            Este cliente ya está vinculado a un usuario.
            <i class="fas fa-check ml-2 text-green-500"></i>
          </p>
        </div>
        <!-- actions -->
        <div
          class="mt-4 flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0"
        >
          <button
            (click)="copyInvitationLink()"
            class="flex items-center rounded-lg bg-gray-200 px-4 py-2 text-gray-700"
          >
            Copiar enlace de invitación
            <span class="ml-2"><i class="fas fa-copy"></i></span>
          </button>
          <button
            *ngIf="!client.isLinked"
            disabled="true"
            class="flex items-center rounded-lg bg-blue-500 px-4 py-2 text-white disabled:cursor-not-allowed disabled:opacity-50"
          >
            Enviar invitación por email
            <span class="ml-2"><i class="fas fa-envelope"></i></span>
          </button>
          <button
            [disabled]="unlinkLoadingState === 'loading'"
            *ngIf="client.isLinked"
            (click)="unlinkClient()"
            class="flex items-center rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Desvincular cliente
            <i
              *ngIf="unlinkLoadingState === 'loading'"
              class="fas fa-spinner fa-spin ml-2"
            ></i>
            <i
              *ngIf="unlinkLoadingState !== 'loading'"
              class="fas fa-unlink ml-2"
            ></i>
          </button>
        </div>
        <div *ngIf="unlinkLoadingState === 'error'" class="text-red-500">
          Error al desvincular cliente.
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
