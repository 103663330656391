import { Injectable, HostListener } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OverrideBackButtonService {
  private overrideFunctions = new Array<() => void>();

  constructor(private locationStrategy: LocationStrategy) {
    this.locationStrategy.onPopState(() => {
      if (this.overrideFunctions.length) {
        this.overrideFunctions.pop()();
      }
    });
  }
  cancelOverride() {
    this.overrideFunctions.pop();
    history.back();
  }
  override(overrideFunction: () => void) {
    history.pushState(null, null, location.href);
    this.overrideFunctions.push(overrideFunction);
  }
}
