import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, lastValueFrom, take } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import {
  TrainingSessionSection,
  TrainingSessionSectionItem,
} from 'src/app/models/training-session-section.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TrainingSessionsService } from 'src/app/services/training-sessions.service';
import {
  TrainingSessionProtocolDef,
  getTrainingSessionProtocolInitials,
} from 'src/shared/training-session-definition.outside';

@Component({
  selector: 'app-training-sessions-menu',
  templateUrl: './training-sessions-menu.component.html',
  styleUrls: ['./training-sessions-menu.component.scss'],
})
export class TrainingSessionsMenuComponent implements OnInit {
  constructor(
    private trainingSessionsService: TrainingSessionsService,
    private auth: AuthService,
    private router: Router,
    private clientService: ClientsService,
    private snackbarService: SnackbarService,
    private nav: NavigationService,
  ) {}

  @Input() isOpen = false;

  @Output() createClientTrainingSessionEvent =
    new EventEmitter<TrainingSessionProtocolDef>();

  trainingSessionProtocols: Array<TrainingSessionProtocolDef> = [];
  trainingSessionProtocols$: Observable<Array<TrainingSessionProtocolDef>>;
  trainingSessionProtocolsWithoutSection: Array<TrainingSessionProtocolDef> =
    [];

  sections: TrainingSessionSection;

  getTrainingSessionProtocolInitials = getTrainingSessionProtocolInitials;

  selectedTrainingSessionProtocolToMove = '';
  moveTrainingSessionProtocolDialogOpen = false;

  editSectionNameSection: TrainingSessionSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = 'asdg';

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;

  sharedUrl: string;

  creatingSessionFromUrl = false;
  currentClient: Client = null;

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const user = await this.auth.getUser();

    this.clientService.currentClient$.subscribe((client) => {
      this.currentClient = client;
    });

    this.trainingSessionProtocols$ =
      await this.trainingSessionsService.getTrainingSessionProtocols$(user.uid);

    this.sections =
      await this.trainingSessionsService.getSectionsOrCreateSections(user.uid);

    this.trainingSessionProtocols = await lastValueFrom(
      this.trainingSessionProtocols$.pipe(take(1)),
    );

    this.trainingSessionProtocols$.subscribe((protocols) => {
      this.trainingSessionProtocols = protocols;
      this.updateTrainingSessionProtocolsWihtoutSection();
    });

    this.clearnSections();
    this.updateTrainingSessionProtocolsWihtoutSection();
  }

  clearnSections() {
    this.sections.sections.forEach(
      (s) =>
        (s.trainingSessionProtocolIds = s.trainingSessionProtocolIds.filter(
          (p) => !!this.getTrainingSessionProtocolById(p),
        )),
    );
  }

  updateTrainingSessionProtocolsWihtoutSection() {
    if (!this.sections?.sections) {
      this.trainingSessionProtocolsWithoutSection =
        this.trainingSessionProtocols;
    }
    this.trainingSessionProtocolsWithoutSection =
      this.trainingSessionProtocols.filter(
        (p) =>
          !this.sections.sections.find(
            (item) =>
              !!item.trainingSessionProtocolIds.find((id) => id === p.id),
          ),
      );
  }

  close() {
    history.back();
  }

  async createTrainingSessionProtocol() {
    const user = await this.auth.getUser();
    const trainingSessionrotocolId =
      await this.trainingSessionsService.createTrainingSessionProtocol(user);
    this.router.navigate([
      'training-session-protocol',
      trainingSessionrotocolId,
    ]);
  }

  getTrainingSessionProtocolById(id: string) {
    if (!this.trainingSessionProtocols) {
      return undefined;
    }
    const result = this.trainingSessionProtocols.find((p) => p.id === id);
    return result;
  }

  editTrainingSessionProtocol(
    trainingSessionProtocol: TrainingSessionProtocolDef,
    event,
  ) {
    event.stopPropagation();
    this.router.navigate([
      'training-session-protocol',
      trainingSessionProtocol.id,
    ]);
  }

  /** from a training session protocol, creates a training session for a client */
  createClientTrainingSession(
    trainingSessionProtocol: TrainingSessionProtocolDef,
  ) {
    this.createClientTrainingSessionEvent.next(trainingSessionProtocol);
  }

  addSection() {
    if (!this.sections) {
      return;
    }
    if (!this.sections.sections) {
      this.sections.sections = [];
    }
    this.sections.sections.push({
      name: 'Sección',
      trainingSessionProtocolIds: [],
    });
    this.updateSections();
  }

  moveSectionUp(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === 0) {
      return;
    }
    const hold = this.sections.sections[sectionIndex - 1];
    this.sections.sections[sectionIndex - 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveSectionDown(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === this.sections.sections.length - 1) {
      return;
    }
    const hold = this.sections.sections[sectionIndex + 1];
    this.sections.sections[sectionIndex + 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveTrainingSessionProtocolUp(
    sectionIndex: number,
    trainingSessionProtocolIndex: number,
    event,
  ) {
    event.stopPropagation();
    const section =
      this.sections.sections[sectionIndex].trainingSessionProtocolIds;
    console.log(section);

    if (trainingSessionProtocolIndex === 0) {
      return;
    }
    const hold = section[trainingSessionProtocolIndex - 1];
    section[trainingSessionProtocolIndex - 1] =
      section[trainingSessionProtocolIndex];
    section[trainingSessionProtocolIndex] = hold;
    this.updateSections();
  }

  moveTrainingSessionProtocolDown(
    sectionIndex: number,
    trainingSessionProtocolIndex: number,
    event,
  ) {
    console.log(
      'sectionIndex: ',
      sectionIndex,
      ' trainingSessionProtocolIndex: ',
      trainingSessionProtocolIndex,
    );
    event.stopPropagation();
    const section =
      this.sections.sections[sectionIndex].trainingSessionProtocolIds;
    if (trainingSessionProtocolIndex === section.length - 1) {
      return;
    }
    const hold = section[trainingSessionProtocolIndex + 1];
    section[trainingSessionProtocolIndex + 1] =
      section[trainingSessionProtocolIndex];
    section[trainingSessionProtocolIndex] = hold;
    this.updateSections();
  }

  openMoveTrainingSessionProtocolDialog(
    protocol: TrainingSessionProtocolDef,
    event,
  ) {
    event.stopPropagation();
    if (
      !this.sections ||
      !this.sections.sections ||
      this.sections.sections.length === 0
    ) {
      return;
    }
    this.selectedTrainingSessionProtocolToMove = protocol.id;
    this.moveTrainingSessionProtocolDialogOpen = true;
  }

  moveToSection(section: TrainingSessionSectionItem) {
    this.sections.sections.forEach((s) => {
      s.trainingSessionProtocolIds = s.trainingSessionProtocolIds.filter(
        (id) => id !== this.selectedTrainingSessionProtocolToMove,
      );
    });
    section.trainingSessionProtocolIds.push(
      this.selectedTrainingSessionProtocolToMove,
    );
    this.moveTrainingSessionProtocolDialogOpen = false;
    this.updateSections();
  }

  closeMoveToSection() {
    this.moveTrainingSessionProtocolDialogOpen = false;
  }

  updateSections() {
    this.updateTrainingSessionProtocolsWihtoutSection();
    this.trainingSessionsService.updateSections(this.sections);
  }

  openRenameSection(section: TrainingSessionSectionItem, event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  deleteSection(index: number) {
    this.sections.sections.splice(index, 1);
    this.updateSections();
  }

  async createClientTrainingSessionFromSharedUrl() {
    this.creatingSessionFromUrl = true;
    const sessionId =
      await this.trainingSessionsService.createClientTrainingSessionFromSharedUrl(
        this.sharedUrl,
        this.currentClient,
      );
    if (!sessionId) {
      this.snackbarService.error('No se pudo crear la sesión');
      return;
    }
    this.nav.goToClientTrainingSession(sessionId);
    this.creatingSessionFromUrl = false;
  }
}
