<div class="app-container" [class.app-container--open]="isOpen">
    <div class="box" [class.box--open]="isOpen">
  
      <div class="box__heading">
        <button class="box__learn-button button-icon-circular"><i
            class="fas fa-photo-video"></i></button>
        <div class="box__title-container">
          <p class="box__title">{{titles[propertyPath]}}</p>
        </div>
        <button (click)="close()" class="box__close-button button-close-dialog"><i class="fas fa-times"></i></button>
      </div>
  
      <div class="box__content">
        <p class="box__instructions">Selecciona la imagen que más se adecúe al estado de tu cliente</p>
        <div class="box__img-selector-container">
            <div class="image-selector">
              <img *ngIf="results"  [class.invisible]="!imgVisible"  src="../../../assets/test-images/{{getResult(index).image}}">
              <div class="image-selector__nav image-selector__nav--left">
                  <button (click)="prev();" class="image-selector__nav-button"><i class="fas fa-chevron-left"></i></button>
              </div>
              <div class="image-selector__nav image-selector__nav--right">
                  <button (click)="next();" class="image-selector__nav-button"><i class="fas fa-chevron-right"></i></button>
              </div>
              
              
            </div>
        </div>
        <p *ngIf="results" class="box__result">{{getResult(index).text}}</p>
        <button (click)="saveAndClose()" class="box__save-button button-cta-2">Guardar selección</button>
      </div>
  
  
    </div>
  
  </div>
