<div class="relative inline-block w-full text-left">
  <div>
    <p *ngIf="fieldName !== ''" class="mb-1 font-bold text-neutral-600">
      {{ fieldName }}
    </p>
    <button
      (click)="toggle()"
      type="button"
      class="inline-flex w-full justify-center rounded-md border border-solid border-neutral-300 bg-white px-4 py-2 font-medium text-neutral-700 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-intecc focus:ring-offset-2 focus:ring-offset-neutral-100 {{
        readonly ? 'bg-neutral-200 opacity-50 focus:ring-0' : ''
      }}"
      [class.pointer-events-none]="readonly"
      [class.cursor-not-allowed]="readonly"
      aria-expanded="true"
      aria-haspopup="true"
    >
      <div *ngIf="selectedOption" class="grow text-left">
        {{ selectedOption.title }}
      </div>
      <div *ngIf="!selectedOption" class="grow text-left">--</div>
      <!-- Heroicon name: solid/chevron-down -->
      <svg
        class="-mr-1 ml-2 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>

  <!--
      Dropdown menu, show/hide based on menu state.
  
      Entering: "transition ease-out duration-100"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
  <div
    class="z-10 origin-top-right absolute left-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black/5 focus:outline-none transition-all
    {{
      isOpen
        ? 'transition ease-out duration-100 opacity-100 scale-100'
        : 'transition ease-in duration-75 opacity-0 invisible scale-95'
    }}
    "
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="py-1" role="none">
      <!-- Active: "bg-neutral-100 text-neutral-900", Not Active: "text-neutral-700" -->
      <a
        (click)="selectOption(i)"
        class="block px-4 py-2 text-sm text-neutral-700"
        role="menuitem"
        *ngFor="let option of options; let i = index"
        >{{ option.title }}</a
      >
    </div>
  </div>
</div>
