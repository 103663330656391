import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import {
  Exercise,
  exerciseLevelsOptions,
  exerciseTypeOptions,
  materialOptions,
} from '../../models/exercise.model';
import {
  ExercisesSection,
  ExercisesSectionItem,
  GlobalExercisesSection,
} from '../../models/exercises-section.model';
import { ExerciseService } from '../../services/exercise.service';
import { AuthService } from '../../services/auth.service';
import { Modal } from '../../models/modal.model';
import { ConfirmActionDialogService } from '../../services/confirm-action-dialog.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ModalService } from 'src/app/services/modal.service';
import { CanIService } from 'src/app/services/can-i.service';
import { LoadingState } from 'src/app/utils/utils';

export interface ExercisesModalData {
  selectionMode?: boolean;
  selectionName?: string;
}

type SelectedItemsTab = 'personal' | 'library';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss'],
})
export class ExercisesComponent implements OnInit, OnDestroy, Modal {
  onClose = new Subject<any>();
  data: ExercisesModalData;

  noExercisesYet = true;
  tabState: 'items' | 'new' | 'edit' = 'items';
  selectedItemsTab: SelectedItemsTab = 'library';

  sections: ExercisesSection;
  globalSections: GlobalExercisesSection | null = null;
  globalExercises$: Observable<Exercise[]>;
  userExercises$: Observable<Exercise[]>;
  userExercises: Exercise[] = [];
  globalExercises: Exercise[] = [];

  moveExerciseDialogOpen = false;
  selectedExerciseToMove: string | null = null;
  moveGlobalDialogOpen = false;

  selectedSection: ExercisesSection | null = null;
  selectedExercise: Exercise | null = null;
  newExercise: Exercise | null = null;
  editingExercise: Exercise | null = null;
  private subscriptions: Subscription[] = [];
  private userId: string | null = null;
  userIsAdmin = false;
  returnValue: any;

  editSectionNameSection: ExercisesSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = '';

  multiSelectList: Exercise['id'][] = [];

  canUseExercises = false;

  loadingState: LoadingState = 'loading';

  // Options for dropdowns

  levelDropdownOptions = exerciseLevelsOptions;

  materialOptions = materialOptions;

  exerciseTypeOptions = exerciseTypeOptions;

  constructor(
    private exerciseService: ExerciseService,
    private authService: AuthService,
    private confirmActionDialogService: ConfirmActionDialogService,
    private snackbarService: SnackbarService,
    private firestoreService: FirestoreService,
    private mediaService: MediaService,
    private modals: ModalService,
    private canIService: CanIService,
  ) {}

  ngOnInit(): void {
    this.loadingState = 'loading';
    this.initAsync();
  }

  async initAsync() {
    const user = await this.authService.getUser();
    if (user) {
      this.canUseExercises = await this.canIService.canViewTrainingSessions();
      this.userId = user.uid;
      this.userIsAdmin = user.role === 'admin';
      this.setupExerciseSubscriptions();
      this.sections = await this.exerciseService.getSectionsOrCreateSections(
        this.userId!,
      );
      this.globalSections = await this.exerciseService.getGlobalSections();
      this.loadingState = 'idle';
    }
  }

  setupExerciseSubscriptions() {
    // Setup user exercises subscription
    this.userExercises$ = this.exerciseService.getUserExercises(this.userId!);
    this.subscriptions.push(
      this.userExercises$.subscribe(
        (exercises) => {
          this.userExercises = exercises;
        },
        (error) => {
          console.error('Error loading user exercises:', error);
        },
      ),
    );

    // Setup global exercises subscription
    this.globalExercises$ = this.exerciseService.getGlobalExercises();
    this.subscriptions.push(
      this.globalExercises$.subscribe(
        (exercises) => {
          this.globalExercises = exercises;
        },
        (error) => {
          console.error('Error loading library exercises:', error);
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  historyBackClose() {
    this.onClose.next(this.returnValue);
  }

  handleTabChange(tab: SelectedItemsTab) {
    this.selectedItemsTab = tab;
  }

  /* SECTIONS */
  async addSection() {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    if (!sectionRef.sections) {
      sectionRef.sections = [];
    }
    sectionRef.sections.push({ name: 'Sección', exerciseIds: [] });
    this.updateSections();
  }

  getExerciseById(id: string) {
    return this.userExercises.find((e) => e.id === id);
  }

  getGlobalExerciseById(id: string) {
    return this.globalExercises.find((e) => e.id === id);
  }

  updateSections() {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    this.exerciseService.updateSections(sectionRef);
  }

  async deleteSection(index: number) {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    sectionRef.sections.splice(index, 1);
    this.updateSections();
  }

  async moveSectionUp(index: number, event: Event) {
    event.stopPropagation();
    if (index === 0) return;

    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    const hold = sectionRef.sections[index - 1];
    sectionRef.sections[index - 1] = sectionRef.sections[index];
    sectionRef.sections[index] = hold;
    this.updateSections();
  }

  async moveSectionDown(index: number, event: Event) {
    event.stopPropagation();

    // Only admins can modify global sections
    if (this.selectedItemsTab === 'library' && !this.userIsAdmin) {
      this.snackbarService.error(
        'No tienes permisos para modificar secciones globales',
      );
      return;
    }

    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    if (index === sectionRef.sections.length - 1) return;

    const hold = sectionRef.sections[index + 1];
    sectionRef.sections[index + 1] = sectionRef.sections[index];
    sectionRef.sections[index] = hold;
    this.updateSections();
  }

  openRenameSection(section: ExercisesSectionItem, event: Event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  async endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  isValidExercise(exercise: Exercise | null): boolean {
    if (!exercise || !exercise.title.trim() || !exercise.id.trim()) {
      return false;
    }

    // For global exercises, only admins can create/edit
    if (this.selectedItemsTab === 'library' && !this.userIsAdmin) {
      return false;
    }

    // For personal exercises, must be owned by user
    if (
      this.selectedItemsTab === 'personal' &&
      exercise.owner !== this.userId
    ) {
      return false;
    }

    return true;
  }

  newExerciseMode() {
    this.tabState = 'new';
    this.newExercise = {
      id: this.firestoreService.createId(),
      owner: this.userId!,
      title: '',
      isGlobal: this.selectedItemsTab === 'library',
    } satisfies Exercise;
  }

  selectExerciseById(exerciseId: string, isGlobal?: boolean) {
    const exercise = isGlobal
      ? this.getGlobalExerciseById(exerciseId)
      : this.getExerciseById(exerciseId);
    if (!exercise) {
      this.snackbarService.error('Ejercicio no encontrado');
      return;
    }
    if (this.data.selectionMode) {
      // TODO: implement multiple selection?
      this.returnValue = [exercise];
      this.historyBackClose();
      return;
    }
    if (exercise.isGlobal && !this.userIsAdmin) {
      console.warn('User is not admin, cannot edit global exercise');
      return;
    }
    this.editingExercise = exercise;
    this.tabState = 'edit';
  }

  async editExercise(exercise: Exercise, event: Event) {
    event.stopPropagation();
    this.editingExercise = { ...exercise };
    this.tabState = 'edit';
  }

  async createNewExercise() {
    try {
      if (!this.isValidExercise(this.newExercise))
        throw new Error('Invalid exercise');

      // Add isGlobal flag if creating in global section
      if (this.selectedItemsTab === 'library') {
        this.newExercise.isGlobal = true;
      }

      await this.exerciseService.createExercise(this.userId!, this.newExercise);
      this.tabState = 'items';
    } catch (error) {
      console.error('Error creating exercise:', error);
      this.snackbarService.error('Error creando ejercicio');
    }
  }

  async updateExercise() {
    try {
      if (!this.isValidExercise(this.editingExercise))
        throw new Error('Invalid exercise');
      await this.exerciseService.updateExercise(
        this.editingExercise!.id,
        this.editingExercise!,
      );
      this.tabState = 'items';
    } catch (error) {
      console.error('Error updating exercise:', error);
      this.snackbarService.error('Error actualizando ejercicio');
    }
  }

  selectGlobalExercise(exercise: Exercise) {
    if (this.data.selectionMode) {
      this.returnValue = { type: 'global', exercise };
      this.historyBackClose();
      return;
    }
    this.selectedExercise = exercise;
    this.tabState = 'edit';
  }

  openMoveExerciseDialog(exercise: Exercise | null, event: Event) {
    event.stopPropagation();
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;
    if (
      !sectionRef ||
      !sectionRef.sections ||
      sectionRef.sections.length === 0
    ) {
      this.snackbarService.error('No se encontraron secciones');
      return;
    }

    // Set selectedExerciseToMove even when no exercise is passed (multi-select case)
    if (exercise) {
      this.selectedExerciseToMove = exercise.id;
    } else if (this.multiSelectList.length > 0) {
      // No need to set selectedExerciseToMove for multi-select
      // as we'll use multiSelectList directly
    } else {
      return; // Exit if no exercise and no multi-selection
    }

    if (this.selectedItemsTab === 'personal') {
      this.moveExerciseDialogOpen = true;
    } else {
      this.moveGlobalDialogOpen = true;
    }
  }

  closeMoveToSection() {
    this.moveExerciseDialogOpen = false;
  }

  closeMoveToGlobal() {
    this.moveGlobalDialogOpen = false;
  }

  async moveToSection(section: ExercisesSectionItem) {
    const exercisesToMove =
      this.multiSelectList.length > 0
        ? this.multiSelectList
        : [this.selectedExerciseToMove];
    if (this.moveExerciseDialogOpen) {
      this.sections.sections.forEach((s) => {
        s.exerciseIds = s.exerciseIds.filter(
          (id) => !exercisesToMove.includes(id),
        );
      });
      this.moveExerciseDialogOpen = false;
    } else if (this.moveGlobalDialogOpen) {
      this.globalSections.sections.forEach((s) => {
        s.exerciseIds = s.exerciseIds.filter(
          (id) => !exercisesToMove.includes(id),
        );
      });
      this.moveGlobalDialogOpen = false;
    }
    section.exerciseIds.push(...exercisesToMove);
    this.multiSelectList = [];
    this.updateSections();
  }

  // NOTE: not used, can't delete exercises individually (not implemented)
  // async deleteExercise(exercise: Exercise) {
  //   // Clean up section references before deleting
  //   const sectionRef =
  //     this.selectedItemsTab === 'personal'
  //       ? this.sections
  //       : this.globalSections;
  //   if (sectionRef && sectionRef.sections) {
  //     sectionRef.sections.forEach((section) => {
  //       const index = section.exerciseIds.indexOf(exercise.id);
  //       if (index !== -1) {
  //         section.exerciseIds.splice(index, 1);
  //       }
  //     });
  //     await this.updateSections();
  //   }

  //   await this.exerciseService.deleteExercise(exercise.id);
  // }

  async deleteSelectedExercises() {
    this.confirmActionDialogService.openDialog({
      title: `¿Estás seguro?`,
      description: `No podrás recuperar estos ejercicios`,
      confirmButton: 'Eliminar',
      confirmCallback: async (confirm) => {
        if (confirm) {
          const sectionRef =
            this.selectedItemsTab === 'personal'
              ? this.sections
              : this.globalSections;

          // Clean up section references for all selected exercises
          if (sectionRef && sectionRef.sections) {
            sectionRef.sections.forEach((section) => {
              section.exerciseIds = section.exerciseIds.filter(
                (id) => !this.multiSelectList.includes(id),
              );
            });
            await this.updateSections();
          }

          // Delete the exercises
          for (let i = 0; i < this.multiSelectList.length; i++) {
            const exerciseId = this.multiSelectList[i];
            const refToLookInto =
              this.selectedItemsTab === 'library'
                ? this.globalExercises
                : this.userExercises;
            const exercise = refToLookInto.find((e) => e.id === exerciseId);
            if (exercise) {
              await this.exerciseService.deleteExercise(exercise.id);
            }
          }

          this.multiSelectList = [];
          this.tabState = 'items';
        }
      },
    });
  }

  getExercisesWithoutSection(exercises: Exercise[]): Exercise[] {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;
    if (!sectionRef || !sectionRef.sections) return exercises;

    const result = exercises.filter(
      (e) =>
        !sectionRef.sections.find((section) =>
          section.exerciseIds.includes(e.id),
        ),
    );
    return result;
  }

  isExerciseWithoutSection(
    exercise: Exercise,
    exercisesWithoutSection: Exercise[],
  ): boolean {
    return !!exercisesWithoutSection.find((e) => e.id === exercise.id);
  }

  multiSelectItem(exercise: Exercise, event: Event) {
    event.stopPropagation();
    if (this.multiSelectList.includes(exercise.id)) {
      const index = this.multiSelectList.indexOf(exercise.id);
      this.multiSelectList.splice(index, 1);
    } else {
      this.multiSelectList.push(exercise.id);
    }
    console.log(this.multiSelectList);
  }

  isExerciseMultiSelected(exercise: Exercise): boolean {
    return this.multiSelectList.includes(exercise.id);
  }

  // Helper function to check if an exercise ID exists in the current list NOTE: don't remove it, is fine as fallback in case section exercise ref is not deleted properly (can happen)
  isExerciseIdValid(id: string): boolean {
    const exercises =
      this.selectedItemsTab === 'personal'
        ? this.userExercises
        : this.globalExercises;
    return exercises.some((e) => e.id === id);
  }

  async selectImage(mode: 'new' | 'edit') {
    const result = await this.modals.openLateralModal(
      'client-media',
      {
        onlyOriginals: true,
        selectionMode: true,
        selectionName: 'Ejercicio',
      },
      {
        slideConfig: 'right',
        extraZIndex: 1000,
      },
    );

    if (!result) return;

    const mediaItem = await this.mediaService.getMediaItem(result.id);
    if (mode === 'new') {
      this.newExercise.imageUrl = mediaItem.downloadURL;
    } else {
      this.editingExercise.imageUrl = mediaItem.downloadURL;
    }
  }

  removeImage(mode: 'new' | 'edit') {
    if (mode === 'new') {
      this.newExercise.imageUrl = null;
    } else {
      this.editingExercise.imageUrl = null;
    }
  }

  // Helper methods for type casting
  isLevelSelected(level: Exercise['level'][number]): boolean {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;

    return (exercise as Exercise).level?.includes(level) || false;
  }

  toggleLevel(level: Exercise['level'][number]) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;

    if (!exercise.level) {
      exercise.level = [];
    }

    const index = exercise.level.indexOf(level);
    if (index === -1) {
      exercise.level.push(level);
      exercise.level.sort((a, b) => {
        return a
          .toString()
          .padStart(2, '0')
          .localeCompare(b.toString().padStart(2, '0'));
      });
    } else {
      exercise.level.splice(index, 1);
    }
  }

  isMaterialSelected(material: Exercise['material'][number]): boolean {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return false;
    return (
      Array.isArray(exercise.material) && exercise.material.includes(material)
    );
  }

  toggleMaterial(material: Exercise['material'][number]) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return;

    if (!Array.isArray(exercise.material)) {
      exercise.material = [];
    }

    const index = exercise.material.indexOf(material);
    if (index === -1) {
      exercise.material.push(material);
    } else {
      exercise.material.splice(index, 1);
    }
  }

  updateMaterials(materials: Exercise['material']) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return;
    exercise.material = materials;
  }

  // Add these methods to handle tags
  getTagsString(): string {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    return exercise?.tags?.join(', ') || '';
  }

  updateTags(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return;

    // Split by comma, trim whitespace, and filter out empty strings
    exercise.tags = value
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);
  }

  moveExerciseLeft(sectionIndex: number, exerciseIndex: number, event: Event) {
    event.stopPropagation();
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    const section = sectionRef.sections[sectionIndex];
    if (exerciseIndex === 0) return;

    const hold = section.exerciseIds[exerciseIndex - 1];
    section.exerciseIds[exerciseIndex - 1] = section.exerciseIds[exerciseIndex];
    section.exerciseIds[exerciseIndex] = hold;
    this.updateSections();
  }

  moveExerciseRight(sectionIndex: number, exerciseIndex: number, event: Event) {
    event.stopPropagation();
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    const section = sectionRef.sections[sectionIndex];
    if (exerciseIndex === section.exerciseIds.length - 1) return;

    const hold = section.exerciseIds[exerciseIndex + 1];
    section.exerciseIds[exerciseIndex + 1] = section.exerciseIds[exerciseIndex];
    section.exerciseIds[exerciseIndex] = hold;
    this.updateSections();
  }
}
