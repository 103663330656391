import {
  TestImageValue,
  TestImageInfo,
  TestImagesEnum,
} from "./test-images.shared";
import {
  TestField,
  Test,
  TestType,
  TestWithImages,
  TestReportLink,
  LeftRigthValue,
} from "./tests.model";

export interface RomShortTest extends Test, TestWithImages {
  results: {
    [key in RomShortTestFieldEnum]?: number | LeftRigthValue | string;
  };
  frontalComments?: string;
  sagitalComments?: string;
  generalComments?: string;
  multimediaLink?: string;
  links?: Array<TestReportLink>;
  images: { [id: string]: TestImageValue };
}

export enum RomShortTestFieldEnum {
  TOLevantaTalon = "TOLevantaTalon",
  TOAdelantaRodillas = "TOAdelantaRodillas",
  TOCortedad = "TOCortedad",
  TODolor = "TODolor",
  CIPMorfotipoRaquideo = "CIPMorfotipoRaquideo",
  CIPTestAdams = "CIPTestAdams",
  CIPMusculaturaRaquis = "CIPMusculaturaRaquis",
  CIPTestDedosPlanta = "CIPTestDedosPlanta",
  CIPAnguloLVFX = "CIPAnguloLVFX",
  CIPTestDedosSuelo = "CIPTestDedosSuelo",
  CIPAnguloLHFX = "CIPAnguloLHFX",
  CIPLimitacion = "CIPLimitacion",
  CIPDolor = "CIPDolor",
  CIENivelHombros = "CIENivelHombros",
  CIETrianguloTalle = "CIETrianguloTalle",
  CIEDisquinesia = "CIEDisquinesia",
  CIEOlecranon = "CIEOlecranon",
  CIETestMovilidadGeneral = "CIETestMovilidadGeneral",
  CIETestRotacionExterna = "CIETestRotacionExterna",
  CIEValorAngular1 = "CIEValorAngular1",
  CIETestRotacionInterna = "CIETestRotacionInterna",
  CIEValorAngular2 = "CIEValorAngular2",
  CIEDolor = "CIEDolor",
  CAMSupinoTestDeThomas = "CAMSupinoTestDeThomas",
  CAMSupinoRotadores = "CAMSupinoRotadores",
  CAMSupinoAductoresFx = "CAMSupinoAductoresFx",
  CAMSupinoDolorCadera = "CAMSupinoDolorCadera",
  CAMSupinoAductoresSinFx = "CAMSupinoAductoresSinFx",
  CAMSupinoDismetria = "CAMSupinoDismetria",
  CAMPronoTestTorsion = "CAMPronoTestTorsion",
  CAMPronoRotacionExterna = "CAMPronoRotacionExterna",
  CAMValorAngular1 = "CAMValorAngular1",
  CAMPronoRotacionInterna = "CAMPronoRotacionInterna",
  CAMValorAngular2 = "CAMValorAngular2",
  CAMPronoTestNachlas = "CAMPronoTestNachlas",
  CAMPronoCortedadRecto = "CAMPronoCortedadRecto",
}

export const RomShortTestFields: { [key in RomShortTestFieldEnum]: TestField } =
  {
    TOLevantaTalon: {
      type: "select",
      title: "Levanta el talón",
      values: ["Sí", "No"],
      goodValues: [0],
      leftRight: false,
    },
    TOAdelantaRodillas: {
      type: "select",
      title: "Adelanta rodillas",
      values: ["Sí", "No", "Levemente"],
      goodValues: [0],
      leftRight: false,
    },
    TOCortedad: {
      type: "select",
      title: "Cortedad",
      values: ["No", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: false,
    },
    TODolor: {
      type: "select",
      title: "Dolor",
      values: ["Sí", "No"],
      goodValues: [0],
      leftRight: false,
    },

    CIPMorfotipoRaquideo: {
      type: "select",
      title: "Morfotipo raquídeo",
      values: [
        "Neutro",
        "Adelantado",
        "Retrasado",
        "Hiperlordosis",
        "Hipercifosis",
        "Rectificación",
        "Cifolordótico",
      ],
      goodValues: [0],
      leftRight: false,
    },
    CIPTestAdams: {
      type: "select",
      title: "Test de Adams",
      values: ["Bien", "Giba derecha", "Giba Izquierda"],
      goodValues: [0],
      leftRight: false,
    },
    CIPMusculaturaRaquis: {
      type: "select",
      title: "Musculatura del raquis",
      values: [
        "Bien",
        "Cruzada",
        "Homolateral Derecha",
        "Homolateral Izquierda",
        "Hipotónica",
      ],
      goodValues: [0],
      leftRight: false,
    },
    CIPTestDedosPlanta: {
      type: "select",
      title: "Test Dedos - Suelo",
      values: ["Muy bien", "Bien", "Cortedad I", "Cortedad II"],
      goodValues: [0],
      leftRight: false,
    },
    CIPAnguloLVFX: {
      type: "text",
      title: "Ángulo LV Fx",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: false,
    },
    CIPTestDedosSuelo: {
      type: "select",
      title: "Test Dedos - Planta",
      values: ["Muy bien", "Bien", "Cortedad I", "Cortedad II"],
      goodValues: [0],
      leftRight: false,
    },
    CIPAnguloLHFX: {
      type: "text",
      title: "Ángulo LH Fx",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: false,
    },
    CIPLimitacion: {
      type: "select",
      title: "Limitación",
      values: ["Sí", "No"],
      goodValues: [0],
      leftRight: false,
    },
    CIPDolor: {
      type: "select",
      title: "Dolor",
      values: ["Sí", "No"],
      goodValues: [0],
      leftRight: false,
    },

    CIENivelHombros: {
      type: "select",
      title: "Nivel de hombros",
      values: ["Neutro", "Ascendido", "Descendido"],
      goodValues: [0],
      leftRight: true,
    },
    CIETrianguloTalle: {
      type: "select",
      title: "Triángulo del talle",
      values: ["Normal", "Aumentado"],
      goodValues: [0],
      leftRight: true,
    },
    CIEDisquinesia: {
      type: "select",
      title: "Disquinesia",
      values: ["No", "Tipo I", "Tipo II", "Tipo III"],
      goodValues: [0],
      leftRight: true,
    },
    CIEOlecranon: {
      type: "select",
      title: "Olécranon",
      values: ["Normal", "Rotación Externa", "Rotación Interna"],
      goodValues: [0],
      leftRight: true,
    },
    CIETestMovilidadGeneral: {
      type: "select",
      title: "Test de Movilidad General",
      values: ["Normal", "Tipo I", "Tipo II", "Tipo III"],
      goodValues: [0],
      leftRight: true,
    },
    CIETestRotacionExterna: {
      type: "select",
      title: "Test de Rotación Externa",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CIEValorAngular1: {
      type: "text",
      title: "Valor angular",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CIETestRotacionInterna: {
      type: "select",
      title: "Test de Rotación Interna",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CIEValorAngular2: {
      type: "text",
      title: "Valor angular",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CIEDolor: {
      type: "select",
      title: "Dolor",
      values: ["Sí", "No"],
      goodValues: [0],
      leftRight: true,
    },

    CAMSupinoTestDeThomas: {
      type: "select",
      title: "Supino - Test de Thomas",
      values: [
        "Bien",
        "Cortedad de Psoas",
        "Abducción de cadera",
        "Torsión Tibial",
      ],
      goodValues: [0],
      leftRight: true,
    },
    CAMSupinoRotadores: {
      type: "select",
      title: "Supino - Rotadores (cadera)",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CAMSupinoAductoresFx: {
      type: "select",
      title: "Supino - Aductores con flexión",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CAMSupinoDolorCadera: {
      type: "select",
      title: "Supino - Dolor (cadera)",
      values: ["Si", "No"],
      goodValues: [0],
      leftRight: true,
    },
    CAMSupinoAductoresSinFx: {
      type: "select",
      title: "Supino - Aductores sin flexión",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CAMSupinoDismetria: {
      type: "text",
      title: "Supino - Dismetría",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CAMPronoTestTorsion: {
      type: "text",
      title: "Prono - Test de Torsión Tibial",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CAMPronoRotacionExterna: {
      type: "select",
      title: "Prono - Rotación Externa",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CAMValorAngular1: {
      type: "text",
      title: "Valor angular",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CAMPronoRotacionInterna: {
      type: "select",
      title: "Prono - Rotación Interna",
      values: ["Normal", "Leve", "Importante", "Excesiva"],
      goodValues: [0],
      leftRight: true,
    },
    CAMValorAngular2: {
      type: "text",
      title: "Valor angular",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CAMPronoTestNachlas: {
      type: "text",
      title: "Prono - Test de Nachlas",
      values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
      goodValues: [0],
      leftRight: true,
    },
    CAMPronoCortedadRecto: {
      type: "select",
      title: "Prono - Cortedad Recto Anterior",
      values: ["Si", "No"],
      goodValues: [0],
      leftRight: true,
    },
  };

export const RomShortArchitecture = [
  {
    title: "Tobillo",
    id: "ta",
    learnId: "VAFROMTobillo",
    learnURL: "https://manual.intecc.org/es/article/valoracion-del-tobillo",
    fields: [
      RomShortTestFieldEnum.TOLevantaTalon,
      RomShortTestFieldEnum.TOAdelantaRodillas,
      RomShortTestFieldEnum.TOCortedad,
      RomShortTestFieldEnum.TODolor,
    ],
    images: [
      TestImagesEnum.RomShortTestTripleFlexionSagital,
      TestImagesEnum.RomShortTestTripleFlexionPosterior,
    ],
    commentsPropName: "taComments",
  },
  {
    title: "Cintura Pélvica",
    id: "cp",
    learnId: "VAFROMCinturaPelvica",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-de-la-cintura-pelvica",
    fields: [
      RomShortTestFieldEnum.CIPMorfotipoRaquideo,
      RomShortTestFieldEnum.CIPTestAdams,
      RomShortTestFieldEnum.CIPMusculaturaRaquis,
      RomShortTestFieldEnum.CIPTestDedosPlanta,
      RomShortTestFieldEnum.CIPAnguloLVFX,
      RomShortTestFieldEnum.CIPTestDedosSuelo,
      RomShortTestFieldEnum.CIPAnguloLHFX,
      RomShortTestFieldEnum.CIPLimitacion,
      RomShortTestFieldEnum.CIPDolor,
    ],
    images: [
      TestImagesEnum.RomShortDedosPlantaLVFx,
      TestImagesEnum.RomShortDedosSueloLHFx,
    ],
    commentsPropName: "cpComments",
  },
  {
    title: "Cintura Escapular",
    id: "ce",
    learnId: "VAFROMCinturaEscapular",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-de-la-cintura-escapular",
    fields: [
      RomShortTestFieldEnum.CIENivelHombros,
      RomShortTestFieldEnum.CIETrianguloTalle,
      RomShortTestFieldEnum.CIEDisquinesia,
      RomShortTestFieldEnum.CIEOlecranon,
      RomShortTestFieldEnum.CIETestMovilidadGeneral,
      RomShortTestFieldEnum.CIETestRotacionExterna,
      RomShortTestFieldEnum.CIEValorAngular1,
      RomShortTestFieldEnum.CIETestRotacionInterna,
      RomShortTestFieldEnum.CIEValorAngular2,
      RomShortTestFieldEnum.CIEDolor,
    ],
    images: [
      TestImagesEnum.RomShortPlanoFrontalPosteriorTronco,
      TestImagesEnum.RomShortTestMovilidadGeneral,
    ],
    commentsPropName: "ceComments",
  },
  {
    title: "Test del Miembro Inferior",
    id: "cam",
    learnId: "VAFROMTestsMiembroInferior",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-del-miembro-inferior-01",
    fields: [
      RomShortTestFieldEnum.CAMSupinoTestDeThomas,
      RomShortTestFieldEnum.CAMSupinoRotadores,
      RomShortTestFieldEnum.CAMSupinoAductoresFx,
      RomShortTestFieldEnum.CAMSupinoDolorCadera,
      RomShortTestFieldEnum.CAMSupinoAductoresSinFx,
      RomShortTestFieldEnum.CAMSupinoDismetria,
      RomShortTestFieldEnum.CAMPronoTestTorsion,
      RomShortTestFieldEnum.CAMPronoRotacionExterna,
      RomShortTestFieldEnum.CAMValorAngular1,
      RomShortTestFieldEnum.CAMPronoRotacionInterna,
      RomShortTestFieldEnum.CAMValorAngular2,
      RomShortTestFieldEnum.CAMPronoTestNachlas,
      RomShortTestFieldEnum.CAMPronoCortedadRecto,
    ],
    images: [
      TestImagesEnum.RomShortLimitacionArtromuscular1,
      TestImagesEnum.RomShortLimitacionArtromuscular2,
    ],
    commentsPropName: "camComments",
  },
  // {
  //     title: 'Imágenes',
  //     id: 'images',
  //     images: [
  //         TestImagesEnum.PlanoFrontalAnteriorRelajado,
  //         TestImagesEnum.PlanoFrontalPosteriorRelajado,
  //     ]
  // },
  {
    title: "Recomendaciones y enlaces",
    id: "linksAndComments",
    learnId: "VAFRecomendacionesEnlaces",
    learnURL:
      "https://manual.intecc.org/es/article/comentarios-y-recomendaciones",
    commentsPropName: "generalComments",
  },
];

export const createEmptyRomShortTest = () => {
  const result: RomShortTest = {
    type: "rom-short" as TestType,
    owner: "",
    client: "",
    results: {},
    images: {},
  };
  return result;
};

// export const getValueText = (test: PostureTest, field: RomShortTestFieldEnum) => {
//     if(!test.results){
//         return null;
//     }
//     if(!test.results[field]){
//         return null;
//     }
//     const value = test.results[field];
//     if(value < 0) {
//         return null;
//     }
//     const fieldInfo = PostureTestFields[field];
//     if(value >= fieldInfo.values.length) {
//         return null;
//     }
//     const result = fieldInfo.values[value];
//     return result;
// }

export const getFootFieldInfo = (field: RomShortTestFieldEnum) => {
  return RomShortTestFields[field];
};

export const getRomShortFieldInfo = getFootFieldInfo;

export const getValueForReport = (
  field: RomShortTestFieldEnum,
  test: RomShortTest
) => {
  const info = getFootFieldInfo(field);
  const values = info.values;
  const rawValue = test.results[field];
  if (typeof rawValue !== "number") {
    return "-";
  }
  if (rawValue === undefined || rawValue < 0 || rawValue >= values.length) {
    return "-";
  }
  const value = values[rawValue];
  return value;
};
