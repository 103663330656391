import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SagitalImgTest, SagitalImgResults, calculateCombinedResults } from 'src/app/models/sagital-img-test.model';
import { Client } from 'src/app/models/client.model';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import { TestService } from 'src/app/services/test.service';
import { LearnService } from 'src/app/services/learn.service';
import { SagitalImageValorationSelectorComponent } from '../../sagital-image-valoration-selector/sagital-image-valoration-selector.component';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-sagital-img-test',
  templateUrl: './sagital-img-test.component.html',
  styleUrls: ['./sagital-img-test.component.scss']
})
export class SagitalImgTestComponent {

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  _test: SagitalImgTest;

  date: Date;

  Results = SagitalImgResults;

  get test() {
    return this._test;
  }

  @Input() set test(value: SagitalImgTest) {
    this._test = value;
    this.init();
  }

  @Input() client: Client;

  @ViewChild(SagitalImageValorationSelectorComponent, { static:true })
  imageValorationSelector: SagitalImageValorationSelectorComponent;

  diagnosticsAvailable = true;
  diagnosticsOpen = false;
  totalValorations = 0;
  completedValorations = 0;
  testProgress = 0;
  testProgressCssSanitized: SafeStyle;

  constructor(private tests: TestService, private learnService: LearnService, private overrideBackButton: OverrideBackButtonService, private sanitizer: DomSanitizer) { }

  init() {
    calculateCombinedResults(this.test);
    this.checkDiagnosticsAvailable();
    this.updateProgress();
  }

  updateTest() {
    const {results, ...test} = this.test;
    this.tests.updateTest(test);
  }

  onDateChanged(date: any) {
    this.test.createdAt = firebaseTimestampFromDate(date.value);
    this.updateTest();
  }

  openLearn(learnSectionName: string) {
    this.learnService.openPanel(learnSectionName);
  }

  openImageSelector(propertyPath: string) {
    this.imageValorationSelector.open(this.test, propertyPath, (result) => {
      this.test[propertyPath] = result;
      this.updateTest();
    })
  }

  openDiagnostics() {
    this.diagnosticsOpen = true;
    this.overrideBackButton.override(() => {this.diagnosticsOpen = false; });
  }

  back() {
    history.back();
  }

  checkDiagnosticsAvailable() {
    const results = this.test.results;
    this.diagnosticsAvailable = !!results.bipSedDorsal || !!results.bipSedLumbar || !!results.bipFlexDorsal || !!results.bipFlexLumbar;
  }

  updateProgress() {
    const valorations = Object.keys(SagitalImgResults);
    const totalValorations = valorations.length;
    this.totalValorations = totalValorations;
    const completedValorations = valorations.filter(v => !!this.test[v]).length;
    this.completedValorations = completedValorations;
    const progress = Math.floor((completedValorations / totalValorations) * 100);
    this.testProgress = progress;
    this.testProgressCssSanitized = this.sanitizer.bypassSecurityTrustStyle('width: ' + progress + '%');
  }

}
