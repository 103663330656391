import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {
  studyLevelOptions,
  yesNoOptions,
  timeOptions,
  treatmentOptions,
  billingPreferencesOptions,
  Questionnaire,
} from '../../../shared/questionnaire.outside';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import {
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { LoadingState, safeToPromise } from 'src/app/utils/utils';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/user.model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.styles.scss'],
})
export class QuestionnaireComponent implements OnInit, OnChanges {
  user$: Observable<User>;
  user: User;
  questionnaireForm: FormGroup;
  studyLevelOptions = studyLevelOptions;
  relapseOptions = yesNoOptions;
  timeOptions = timeOptions;
  treatmentOptions = treatmentOptions;
  billingPreferencesOptions = billingPreferencesOptions;
  currentQuestionnaireId: string | null = null;
  loadingState: LoadingState = 'loading';
  showAcceptance: boolean = false;
  acceptServiceTermsSaved: boolean = false;
  acceptServiceTermsAndPrivacyPolicySaved: boolean = false;
  userClient$: Observable<Client>;
  userClient: Client;
  acceptanceUrl: string;
  clientId$: Observable<string>;
  clientUserData$: Observable<{
    clientUser: User;
    questionnaire: Questionnaire | null;
  }>;
  clientUser: User;
  clientQuestionnaire: Questionnaire | null;
  isClient: boolean;
  errorMessage: string;
  constructor(
    private fb: FormBuilder,
    private questionnaireService: QuestionnaireService,
    private snackbarService: SnackbarService,
    private userClientService: UserClientService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private location: Location,
  ) {}

  async ngOnInit() {
    this.initForm();
    // primero obtener usuario para saber si es role professional o client
    this.user$ = this.auth.user$.pipe(
      tap((user) => {}),
      shareReplay(1),
    );
    this.user = await safeToPromise(this.user$);
    this.isClient = this.user?.role === 'client';
    try {
      if (!this.isClient) {
        this.clientId$ = this.route.paramMap.pipe(
          map((paramMap) => paramMap.get('id')),
        );
        this.clientUserData$ = this.clientId$.pipe(
          switchMap((clientId) => {
            return this.userClientService.getUserDataByClientId$(clientId);
          }),
          shareReplay(1),
        );
        const clientUserData = await safeToPromise(this.clientUserData$);
        this.clientUser = clientUserData.clientUser;
        this.clientQuestionnaire = clientUserData.questionnaire;
        if (!this.clientUser) {
          throw new Error('No hay usuario vinculado al cliente');
        }
        await this.loadExistingQuestionnaire(this.clientUser.uid);
        this.loadingState = 'idle';
      } else {
        this.userClient$ = this.userClientService.userClient$.pipe(
          tap((client) => {
            this.userClient = client;
          }),
        );
        [, this.userClient] = await Promise.all([
          this.loadExistingQuestionnaire(this.user.uid),
          safeToPromise(this.userClient$),
        ]);
        this.showAcceptance =
          this.userClient.acceptanceUrl && this.userClient.displayAcceptanceUrl;
        this.acceptanceUrl = this.userClient.acceptanceUrl;
        this.loadingState = 'idle';
      }
      // Set up form state based on isClient
      this.updateFormState();
    } catch (error) {
      console.error('Error loading questionnaire:', error);
      this.errorMessage = error.message;
      this.snackbarService.error(
        error.message || 'Algo ha fallado al cargar el cuestionario',
      );
      this.loadingState = 'error';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isClient']) {
      this.updateFormState();
    }
  }

  private updateFormState() {
    if (this.isClient) {
      this.questionnaireForm.enable();
    } else {
      this.questionnaireForm.disable();
    }
  }

  private async loadExistingQuestionnaire(userId: string) {
    const questionnaires =
      this.user?.role !== 'client'
        ? [this.clientQuestionnaire]
        : await firstValueFrom(
            this.questionnaireService.getUserQuestionnaires$(userId),
          );
    if (!questionnaires || questionnaires.length === 0 || !questionnaires[0]) {
      return; // don't throw error in any case, empty will be shown even for professional
      // if (this.user?.role !== 'client') {
      //   throw new Error('El cliente aún no ha completado el cuestionario');
      // }
      // return; // don't throw error if client as the form will be initialized empty
    }
    // Get the most recent questionnaire
    const questionnaire = questionnaires[0];
    this.currentQuestionnaireId = questionnaire.id;

    if (questionnaire.acceptServiceTerms) {
      this.acceptServiceTermsSaved = true;
    }

    if (questionnaire.acceptServiceTermsAndPrivacyPolicy) {
      this.acceptServiceTermsAndPrivacyPolicySaved = true;
    }

    // Update form with existing data
    this.questionnaireForm.patchValue(questionnaire);

    // Handle arrays separately
    if (questionnaire.injuries?.length) {
      this.injuriesArray.clear();
      questionnaire.injuries.forEach((injury) => {
        this.injuriesArray.push(this.fb.group(injury));
      });
    }

    if (questionnaire.pathologies?.length) {
      this.pathologiesArray.clear();
      questionnaire.pathologies.forEach((pathology) => {
        this.pathologiesArray.push(this.fb.group(pathology));
      });
    }
  }

  private initForm() {
    this.questionnaireForm = this.fb.group({
      // Datos personales
      name: [''],
      surname: [''],
      birthDate: [''],
      dni: [''],
      email: [''],
      phone: [''],
      studiesLevel: [''],
      currentJob: [''],
      dailyHours: [''],
      physicalEffort: [''],
      currentWeight: [''],
      height: [''],

      // Autovaloración (0-10)
      physicalCondition: [''],
      animicStatus: [''],
      nutritionalStatus: [''],
      sleepHours: [''],
      sleepQuality: [''],
      dailyIntakes: [''],
      painLevel: [''],
      whatWouldYouImprove: [''],

      // Hábitos y rutinas
      dailyActivities: [''],
      laboralActivities: [''],
      sportsActivities: [''],
      nutritionalHabits: [''],
      hasDoneDiet: [''],
      hasHadCavities: [''],
      hasHadDentalCorrection: [''],
      hasUsedInsoles: [''],

      // Lesiones y patologías
      injuries: this.fb.array([]),
      pathologies: this.fb.array([]),

      // Facturación
      billingPreferences: [''],
      clientName: [''],
      clientDniNif: [''],
      clientAddress: [''],
      clientEmail: [''],

      acceptServiceTerms: [false],
      acceptServiceTermsAndPrivacyPolicy: [false],
    });
  }

  get injuriesArray() {
    return this.questionnaireForm.get('injuries') as FormArray;
  }

  get pathologiesArray() {
    return this.questionnaireForm.get('pathologies') as FormArray;
  }

  createInjuryOrPathology(): FormGroup {
    return this.fb.group({
      name: [''],
      startDate: [''],
      relapse: [''],
      time: [''],
      treatment: [''],
      comments: [''],
    });
  }

  addInjury() {
    this.injuriesArray.push(this.createInjuryOrPathology());
  }

  addPathology() {
    this.pathologiesArray.push(this.createInjuryOrPathology());
  }

  removeInjury(index: number) {
    this.injuriesArray.removeAt(index);
  }

  removePathology(index: number) {
    this.pathologiesArray.removeAt(index);
  }

  async onSubmit() {
    if (this.questionnaireForm.valid) {
      try {
        const formValue = this.questionnaireForm.value;
        if (this.currentQuestionnaireId) {
          formValue.id = this.currentQuestionnaireId;
        }

        const savedId =
          await this.questionnaireService.saveQuestionnaire(formValue);
        if (savedId && !this.currentQuestionnaireId) {
          this.currentQuestionnaireId = savedId;
        }
      } catch (error) {
        console.error('Error saving questionnaire:', error);
      }
    }
  }

  goBack() {
    this.location.back();
  }
}
