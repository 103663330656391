import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LearnSection, LearnSections } from '../models/learn-section.model';
import { OverrideBackButtonService } from './override-back-button.service';

@Injectable({
  providedIn: 'root'
})
export class LearnService {

  panelOpen = new BehaviorSubject<boolean>(false);

  selectedSection: LearnSection = null;

  constructor(private overrideBackButtonService: OverrideBackButtonService) { }

  openPanel(learnSectionName: string) {
    this.overrideBackButtonService.override(() => {
      this.panelOpen.next(false);
      this.selectedSection = null;
    });
    this.selectedSection = LearnSections[learnSectionName];
    this.panelOpen.next(true);
  }
}
