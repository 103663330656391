import { Injectable } from '@angular/core';

import { isIOS, sleep } from '../utils/utils';
import { InclinometerSubscriber } from '../models/inclinometer-subscriber.interface';
import { InclinometerAvailability } from '../models/inclinometer-status.model';

@Injectable({
    providedIn: 'root'
})
export class NewInclinometerService {

    RAD_TO_DEG = 180.0 / Math.PI;

    MEAN_SIZE = 20;

    offset = 0;

    lastsInclinations: Array<number> = [];

    lastAccValues: Array<{ x, y, z }> = [];

    status: InclinometerAvailability;

    receivingData = false;

    sub: InclinometerSubscriber = null;

    mode: 'offset' | 'absolute';

    constructor() {
        // setInterval(() => {
        //     if (!!this.sub) {
        //         this.sub.onNewAngle(getRandomInt(50));
        //     }
        // }, 1000);

    }

    async getState() {
        // return InclinometerAvailability.Available;
        await sleep(500);
        // return InclinometerAvailability.Available;
        // return InclinometerAvailability.BlockedIos;
        this.checkState();
        return this.status;
    }

    start(sub: InclinometerSubscriber) {
        this.setZero();
        this.sub = sub;
        this.mode = 'offset';
    }

    startNoOffset(sub: InclinometerSubscriber) {
        this.offset = 0;
        this.sub = sub;
        this.mode = 'absolute';
    }

    stop() {
        this.sub = null;
    }

    onNewDeviceMotionEventData(eventData) {
        // console.log(eventData);
        if (eventData.accelerationIncludingGravity.x === null) {
            return;
        }
        this.receivingData = true;
        this.inputAccValues(eventData.accelerationIncludingGravity);
        if (!!this.sub) {
            if (this.mode === 'offset') {
                this.sub.onNewAngle(this.getRelativeInclinationWithOffset());
            } else {
                this.sub.onNewAngle(this.getAbsoluteInclination());
            }
        }
    }

    setZero() {
        this.offset = this.getAbsoluteInclination();
    }

    getAbsoluteInclination() {
        const inclination = this.calculateInclination();
        return Math.round(inclination);
    }

    getAbsoluteInclinationWithOffset() {
        let inclination = this.getAbsoluteInclination();
        inclination = inclination - this.offset;
        inclination = inclination < 0 ? 360 + inclination : inclination;
        return inclination;
    }

    getRelativeInclinationWithOffset() {
        let inclination = this.getAbsoluteInclinationWithOffset();
        inclination = inclination > 180 ? 360 - inclination : inclination;
        return inclination;
    }

    async requestPermissionIOS() {

        console.log('Inclinometer permission requested');
        if (typeof (DeviceMotionEvent as any).requestPermission === 'function') {
            try {
                const permissionState = await (DeviceMotionEvent as any).requestPermission();
                if (permissionState === 'granted') {
                    return true;
                }
            } catch (err) {
                console.error(err);
            }
        }
        return false;
    }

    private checkState() {
        const pIsIOS = !!isIOS();
        const requestPermissionAvailable = (typeof (DeviceMotionEvent as any).requestPermission === 'function');
        const receivingData = this.receivingData;

        if (receivingData) {
            this.status = InclinometerAvailability.Available;
        } else if (pIsIOS && requestPermissionAvailable) {
            this.status = InclinometerAvailability.BlockedIos;
        } else {
            this.status = InclinometerAvailability.NotDetected;
        }
    }

    private inputAccValues(acc) {
        this.lastAccValues.push({ x: acc.x, y: acc.y, z: acc.z });
        if (this.lastAccValues.length > this.MEAN_SIZE) {
            this.lastAccValues.shift();
        }
    }

    private getAccValues() {
        return {
            x: this.getMean(this.lastAccValues.map(acc => acc.x)),
            y: this.getMean(this.lastAccValues.map(acc => acc.y)),
            z: this.getMean(this.lastAccValues.map(acc => acc.z)),
        };
    }

    private calculateInclination() {
        const acc = this.getAccValues();
        let roll = this.computeRoll(acc.x, acc.y, acc.z);
        roll = -roll;
        if (roll < 0) {
            roll = 270 + (90 + roll);
        }
        return roll;
    }
    private round3(num) {
        return Math.round(num * 1000) / 1000;
    }
    private getMean(values: Array<number>) {
        if (values.length === 0) {
            return 0;
        }
        const sum = values.reduce((previous, current) => current += previous);
        const avg = sum / values.length;
        return avg;
    }
    private computeRoll(x, y, z) {
        const roll = (Math.atan2(y, x) * this.RAD_TO_DEG) - 90;
        return roll;
    }
}
