import { Test, TestType } from '../../shared/test.outside';

interface Result {
  name: string;
  tips: Array<string>;
}

export interface SagitalImgTest extends Test {
  bipDorsal?: string;
  bipLumbar?: string;
  results?: {
    bipSedDorsal?: Result;
    bipFlexDorsal?: Result;
    dorsal?: Result;
    bipSedLumbar?: Result;
    bipFlexLumbar?: Result;
    lumbar?: Result;
  };
}

export const SagitalImgResults = {
  bipDorsal: {
    'bip-dorsal-hipocifosis': { text: 'Hipocifosis', image: 'bip-dorsal-hipocifosis.jpg', normal: false },
    'bip-dorsal-normalidad': { text: 'Normalidad', image: 'bip-dorsal-normalidad.jpg', normal: true },
    'bip-dorsal-hipercifosis': { text: 'Hipercifosis', image: 'bip-dorsal-hipercifosis.jpg', normal: false },
  },
  bipLumbar: {
    'bip-lumbar-inversionTLX': { text: 'Inversión T-L', image: 'bip-lumbar-inversionTLX.jpg', normal: false },
    'bip-lumbar-hipolordosis': { text: 'Hipolordosis', image: 'bip-lumbar-hipolordosis.jpg', normal: false },
    'bip-lumbar-normalidad': { text: 'Normalidad', image: 'bip-lumbar-normalidad.jpg', normal: true },
    'bip-lumbar-hiperlordosis': { text: 'Hiperlordosis', image: 'bip-lumbar-hiperlordosis.jpg', normal: false },
  },
  bipPelvis: {
    'bip-pelvis-retroversion': { text: 'Retroversión', image: 'bip-pelvis-retroversion.jpg', normal: false, tips: ['Normalizar la disposición de la pelvis en bipedestación.', 'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).']},
    'bip-pelvis-normalidad': { text: 'Normalidad', image: 'bip-pelvis-normalidad.jpg', normal: true, tips: [] },
    'bip-pelvis-anteversion': { text: 'Anteversión', image: 'bip-pelvis-anteversion.jpg', normal: false, tips: ['Normalizar la disposición de la pelvis en bipedestación.', 'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).'] },
  },
  sedDorsal: {
    'sed-dorsal-inversion': { text: 'Inversion Cifosis Dorsal', image: 'sed-dorsal-inversion.jpg', normal: false },
    'sed-dorsal-hipocifosis': { text: 'Hipocifosis', image: 'sed-dorsal-hipocifosis.jpg', normal: true },
    'sed-dorsal-normalidad': { text: 'Normalidad', image: 'sed-dorsal-normalidad.jpg', normal: true },
    'sed-dorsal-hipercifosis': { text: 'Hipercifosis', image: 'sed-dorsal-hipercifosis.jpg', normal: false },
  },
  sedLumbar: {
    'sed-lumbar-hiperlordosis': { text: 'Hiperlordosis', image: 'sed-lumbar-hiperlordosis.jpg', normal: false },
    'sed-lumbar-normalidad': { text: 'Normalidad', image: 'sed-lumbar-normalidad.jpg', normal: true },
    'sed-lumbar-actitudCifotica': { text: 'Actitud Cifotica', image: 'sed-lumbar-actitudCifotica.jpg', normal: false },
  },
  sedPelvis: {
    'sed-pelvis-retroversion': { text: 'Retroversión', image: 'sed-pelvis-retroversion.jpg', normal: false, tips: ['Normalizar la disposición de la pelvis en Sedentación Asténica.', 'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).'] },
    'sed-pelvis-normalidad': { text: 'Normalidad', image: 'sed-pelvis-normalidad.jpg', normal: true, tips: [] },
    'sed-pelvis-anteversion': { text: 'Anteversión', image: 'sed-pelvis-anteversion.jpg', normal: false, tips: ['Normalizar la disposición de la pelvis en Sedentación Asténica.', 'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).'] },
  },

  flexDorsal: {
    'flex-dorsal-hipocifosis': { text: 'Hipocifosis', image: 'flex-dorsal-hipocifosis.jpg', normal: false },
    'flex-dorsal-normalidad': { text: 'Normalidad', image: 'flex-dorsal-normalidad.jpg', normal: true },
    'flex-dorsal-hipercifosis': { text: 'Hipercifosis', image: 'flex-dorsal-hipercifosis.jpg', normal: false },
  },
  flexLumbar: {
    'flex-lumbar-lordosis': { text: 'Lordosis', image: 'flex-lumbar-lordosis.jpg', normal: false },
    'flex-lumbar-normalidad': { text: 'Normalidad', image: 'flex-lumbar-normalidad.jpg', normal: true },
    'flex-lumbar-cifosis': { text: 'Cifosis', image: 'flex-lumbar-cifosis.jpg', normal: false },
  },
  flexPelvis: {
    'flex-pelvis-normalidad': { text: 'Normalidad', image: 'flex-pelvis-normalidad.jpg', normal: true, tips: [] },
    'flex-pelvis-cortedadGradoI': { text: 'Cortedad Grado I', image: 'flex-pelvis-cortedadGradoI.jpg', normal: false, tips: ['Estiramiento isquiosural sin cifosar el raquis lumbar y con una cifosis dorsal dentro de la normalidad.'] },
    'flex-pelvis-cortedadGradoII': { text: 'Cortedad Grado II', image: 'flex-pelvis-cortedadGradoII.jpg', normal: false, tips: ['Estiramiento isquiosural sin cifosar el raquis lumbar y con una cifosis dorsal dentro de la normalidad.'] },
  },
  apex: {
    'apex-no': { text: 'Sin ápex', image: 'apex-no.jpg', normal: true },
    'apex-si': { text: 'Con ápex', image: 'apex-si.jpg', normal: false },
  }
};

const Tips = {
  'valoracion-si-hay-dolor': 'Valoración de la zona si hay dolor',
  'higiene-postural': 'Higiene postural en todas las posturas',
  'higiene-postural-sedentacion': 'Higiene postural en sedentación',
  'higiene-postural-bipedestacion': 'Higiene postural en bipedestación',
  'higiene-postural-flex': 'Higiene postural de la flexión de tronco',
  'dolor-higiene-postural-sedentacion': 'Si hay dolor de espalda se recomienda higiene postural de la sedentación',
  ec: 'Ejercicios correctivos',
  'ec-cifosis-dorsal': 'Ejercicios correctivos de la cifosis dorsal',
  'ec-flex-dorsal': 'Ejercicios correctivos de flexibilización del segmento dorsal',
  'radiografia-columna-puberes-dolor': 'Radiografía lateral de la columna vertebral en púberes o con dolor de espalda'

};

export const SagitalImgTitles = {
  bipDorsal: 'Bipedestación - Dorsal',
  bipLumbar: 'Bipedestación - Lumbar',
  bipPelvis: 'Bipedestación - Pelvis',
  sedDorsal: 'Sedestación - Dorsal',
  sedLumbar: 'Sedestación - Lumbar',
  sedPelvis: 'Sedestación - Pelvis',
  flexDorsal: 'Flexión Máxima de Tronco - Dorsal',
  flexLumbar: 'Flexión Máxima de Tronco - Lumbar',
  flexPelvis: 'Flexión Máxima de Tronco - Pelvis',
  apex: 'Apex'
};

export const SagitalImgCombinedResultsConditions = {
  bipSedDorsal: [
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], sedDorsal: ['sed-dorsal-inversion', 'sed-dorsal-hipocifosis'] }, result: { name: 'Hipocifosis', tips: [Tips['valoracion-si-hay-dolor']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], sedDorsal: ['sed-dorsal-normalidad'] }, result: { name: 'Hipocifosis funcional', tips: [Tips['valoracion-si-hay-dolor']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], sedDorsal: ['sed-dorsal-hipercifosis'] }, result: { name: 'Hipercifosis funcional', tips: [Tips['higiene-postural-sedentacion']] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], sedDorsal: ['sed-dorsal-inversion', 'sed-dorsal-hipocifosis'] }, result: { name: 'Normalidad', tips: [Tips['valoracion-si-hay-dolor']] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], sedDorsal: ['sed-dorsal-normalidad'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], sedDorsal: ['sed-dorsal-hipercifosis'] }, result: { name: 'Hipercifosis funcional', tips: [Tips['higiene-postural-sedentacion']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipercifosis'], sedDorsal: ['sed-dorsal-normalidad'] }, result: { name: 'Actitud Hipercifótica dorsal con correcta SA', tips: [Tips['higiene-postural-bipedestacion'], Tips['ec-cifosis-dorsal']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipercifosis'], sedDorsal: ['sed-dorsal-hipercifosis'] }, result: { name: 'Actitud Hipercifótica dorsal', tips: [Tips['higiene-postural'], Tips['ec-cifosis-dorsal'], Tips['radiografia-columna-puberes-dolor']] } },
  ],
  bipFlexDorsal: [
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], flexDorsal: ['flex-dorsal-hipocifosis'] }, result: { name: 'Hipocifosis', tips: [Tips['ec-flex-dorsal']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], flexDorsal: ['flex-dorsal-normalidad'] }, result: { name: 'Hipocifosis funcional', tips: [Tips['valoracion-si-hay-dolor']] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipocifosis'], flexDorsal: ['flex-dorsal-hipercifosis'] }, result: { name: 'Hipocifosis + Hipercifosis funcional', tips: [Tips['higiene-postural-flex']] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], flexDorsal: ['flex-dorsal-hipocifosis'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], flexDorsal: ['flex-dorsal-normalidad'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipDorsal: ['bip-dorsal-normalidad'], flexDorsal: ['flex-dorsal-hipercifosis'] }, result: { name: 'Hipercifosis funcional', tips: [Tips['higiene-postural-flex']] } },
    // { conditions: { bipDorsal: ['bip-dorsal-hipercifosis'], flexDorsal: ['flex-dorsal-hipocifosis'] }, result: { name: 'Hipercifosis funcional', tips: [Tips["higiene-postural-sedentacion"]] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipercifosis'], flexDorsal: ['flex-dorsal-normalidad'] }, result: { name: 'Actitud hipercifótica dorsal', tips: [Tips['higiene-postural-flex'], Tips.ec] } },
    { conditions: { bipDorsal: ['bip-dorsal-hipercifosis'], flexDorsal: ['flex-dorsal-hipercifosis'] }, result: { name: 'Hipercifosis', tips: [Tips['higiene-postural'], Tips.ec] } },
  ],
  bipSedLumbar: [
    // { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], sedLumbar: ['sed-lumbar-hiperlordosis'] }, result: { name: 'Hipocifosis', tips: [Tips["ec-flex-dorsal"]] } },
    { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], sedLumbar: ['sed-lumbar-normalidad'] }, result: { name: 'Hipolordosis funcional', tips: [Tips['higiene-postural']] } },
    { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], sedLumbar: ['sed-lumbar-actitudCifotica'] }, result: { name: 'Cifosis lumbar', tips: [Tips['higiene-postural-sedentacion']] } },
    // { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], sedLumbar: ['sed-lumbar-hiperlordosis'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], sedLumbar: ['sed-lumbar-normalidad'] }, result: { name: 'Hipolordosis funcional', tips: [Tips['higiene-postural']] } },
    { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], sedLumbar: ['sed-lumbar-actitudCifotica'] }, result: { name: 'Actitud cifótica sedente', tips: [Tips['higiene-postural-sedentacion']] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], sedLumbar: ['sed-lumbar-hiperlordosis'] }, result: { name: 'Actitud lordótica sedente', tips: [Tips['dolor-higiene-postural-sedentacion']] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], sedLumbar: ['sed-lumbar-normalidad'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], sedLumbar: ['sed-lumbar-actitudCifotica'] }, result: { name: 'Actitud cifótica lumbar', tips: [Tips['higiene-postural-sedentacion'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], sedLumbar: ['sed-lumbar-hiperlordosis'] }, result: { name: 'Hiperlordosis', tips: [Tips['higiene-postural'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], sedLumbar: ['sed-lumbar-normalidad'] }, result: { name: 'Hiperlordosis postural', tips: [Tips['higiene-postural-bipedestacion'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], sedLumbar: ['sed-lumbar-actitudCifotica'] }, result: { name: 'Hipermovilidad lumbar', tips: [Tips['higiene-postural'], Tips.ec] } },
  ],
  bipFlexLumbar: [
    { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], flexLumbar: ['flex-lumbar-lordosis'] }, result: { name: 'Hipolordosis estructurada', tips: [Tips['higiene-postural'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], flexLumbar: ['flex-lumbar-normalidad'] }, result: { name: 'Hipolordosis funcional', tips: [Tips['higiene-postural-bipedestacion']] } },
    // { conditions: { bipLumbar: ['bip-lumbar-inversionTLX'], flexLumbar: ['flex-lumbar-cifosis'] }, result: { name: 'Cifosis lumbar', tips: [Tips["higiene-postural-sedentacion"]] } },
    { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], flexLumbar: ['flex-lumbar-lordosis'] }, result: { name: 'Hipolordosis estructurada', tips: [Tips['higiene-postural'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], flexLumbar: ['flex-lumbar-normalidad'] }, result: { name: 'Hipolordosis funcional', tips: [Tips['higiene-postural-bipedestacion']] } },
    // { conditions: { bipLumbar: ['bip-lumbar-hipolordosis'], flexLumbar: ['flex-lumbar-cifosis'] }, result: { name: 'Actitud cifótica sedente', tips: [Tips["higiene-postural-sedentacion"]] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], flexLumbar: ['flex-lumbar-lordosis'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], flexLumbar: ['flex-lumbar-normalidad'] }, result: { name: 'Normalidad', tips: [] } },
    { conditions: { bipLumbar: ['bip-lumbar-normalidad'], flexLumbar: ['flex-lumbar-cifosis'] }, result: { name: 'Actitud cifótica lumbar', tips: [Tips['higiene-postural-flex'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], flexLumbar: ['flex-lumbar-lordosis'] }, result: { name: 'Hiperlordosis estructurada', tips: [Tips['higiene-postural-bipedestacion'], Tips.ec] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], flexLumbar: ['flex-lumbar-normalidad'] }, result: { name: 'Hiperlordosis postural', tips: [Tips['higiene-postural-bipedestacion']] } },
    { conditions: { bipLumbar: ['bip-lumbar-hiperlordosis'], flexLumbar: ['flex-lumbar-cifosis'] }, result: { name: 'Hipermovilidad lumbar', tips: [Tips['higiene-postural'], Tips.ec] } },
  ],

};

export function createEmptySagitalImgTest(): SagitalImgTest {
  return {
    type: ('sagital-img') as TestType,
    owner: '',
    client: ''
  };
}

function meetsCondition(test: SagitalImgTest, condition: any) {
  const keys = Object.keys(condition);
  for (const key of keys) {
    if (!condition[key].includes(test[key])) {
      return false;
    }
  }
  return true;
}

function calculateCombinedResult(test: SagitalImgTest, resultId: string) {
  const conditions = SagitalImgCombinedResultsConditions[resultId];
  const keys = Object.keys(conditions[0].conditions);
  for (const key of keys) {
    if (!test[key]) {
      return null;
    }
  }
  for (const condition of conditions) {
    if (meetsCondition(test, condition.conditions)) {
      return condition.result;
    }
  }
}

export function calculateCombinedResults(test: SagitalImgTest) {
  test.results = {};
  test.results.bipSedDorsal = calculateCombinedResult(test, 'bipSedDorsal');
  test.results.bipFlexDorsal = calculateCombinedResult(test, 'bipFlexDorsal');
  test.results.bipSedLumbar = calculateCombinedResult(test, 'bipSedLumbar');
  test.results.bipFlexLumbar = calculateCombinedResult(test, 'bipFlexLumbar');
  test.results.dorsal = (test.results.bipFlexDorsal ? test.results.bipFlexDorsal : (test.results.bipSedDorsal ? test.results.bipSedDorsal : null));
  test.results.lumbar = (test.results.bipFlexLumbar ? test.results.bipFlexLumbar : (test.results.bipSedLumbar ? test.results.bipSedLumbar : null));

  // console.log(test.results.bipSedDorsal);
}
