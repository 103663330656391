<div class="modal relative" *ngIf="user">
  <div (click)="close()" class="absolute right-5 top-3 cursor-pointer">
    <i class="fas fa-times text-lg"></i>
  </div>
  <h3 class="modal__title">{{ user.displayName }}</h3>
  <div class="modal__content">
    <form autocomplete="off" class="form">
      <div class="form__field">
        <label class="form__label" for="line1">Email</label>
        <input
          disabled
          value="{{ user.email }}"
          class="form__input"
          type="text"
          id="line1"
          placeholder="Email"
        />
      </div>
    </form>
    <div class="modal__subs" *ngIf="subInfo">
      <h3 class="modal__subs-title">Tu suscripción</h3>
      <div class="modal__sub">
        <div class="modal__sub-title">{{ subInfo.name }}</div>
        <div class="modal__sub-price">
          <p class="modal__sub-price-price">{{ subInfo.pricing }}</p>
          <span class="modal__sub-price-offer" *ngIf="subInfo.offer">{{
            subInfo.offer
          }}</span>
        </div>
        <div
          *ngIf="billingPortalUrlLoadingState === 'idle' && !billingPortalUrl"
          class="modal__sub-cancel"
          (click)="confirmCancelCurrentSub()"
        >
          <i class="fas fa-power-off"></i>
        </div>
        <a
          [class.opacity-50]="billingPortalUrlLoadingState === 'loading'"
          [class.cursor-not-allowed]="
            billingPortalUrlLoadingState === 'loading'
          "
          [href]="billingPortalUrl || '#'"
          [target]="billingPortalUrl ? '_blank' : ''"
          class="row-span-2 flex items-center gap-2 text-intecc2"
          [class.hover:underline]="billingPortalUrlLoadingState === 'idle'"
          (click)="billingPortalUrl ? null : $event.preventDefault()"
        >
          Gestionar
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="modal__actions">
    <button
      *ngIf="!isPremium(subInfo)"
      class="modal__upgrade-button cool-button-with-icon"
      (click)="openUpgradeModal()"
    >
      <span class="cool-button-with-icon__icon"
        ><i class="fas fa-rocket"></i>
      </span>
      <p class="cool-button-with-icon__text">Mejorar</p>
    </button>
    <button
      class="modal__logout-button new-button-regular-with-icon"
      (click)="logout()"
    >
      <span class="new-button-regular-with-icon__icon"
        ><i class="fas fa-sign-out-alt"></i>
      </span>
      <p
        class="new-button-regular-with-icon__text full-hero__navbar__action__text"
      >
        Cerrar sesión
      </p>
    </button>
  </div>
</div>
