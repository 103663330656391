const pathToGifs = './assets/learn/gifs';
export const LearnGifs = {
  'gif-bip-dorsal-movil': `${pathToGifs}/gif_bip_dorsal_movil.gif`,
  'gif-bip-lumbar-movil': `${pathToGifs}/gif_bip_lumbar_movil.gif`,
  'gif-bip-pelvis-movil': `${pathToGifs}/gif_bip_pelvis_inclinometro.gif`,
  'gif-bip-ma-inclinometro': `${pathToGifs}/gif_bip_ma_inclinometro.gif`,
  'gif-sa-dorsal-movil': `${pathToGifs}/gif_sa_dorsal_movil.gif`,
  'gif-sa-lumbar-movil': `${pathToGifs}/gif_sa_lumbar_movil.gif`,
  'gif-sa-pelvis-movil': `${pathToGifs}/gif_sa_pelvis_movil.gif`,
  'gif-flex-dorsal-movil': `${pathToGifs}/gif_flex_dorsal_movil.gif`,
  'gif-flex-lumbar-movil': `${pathToGifs}/gif_flex_lumbar_movil.gif`,
  'gif-flex-pelvis-movil': `${pathToGifs}/gif_flex_pelvis_movil.gif`,
  'gif-flechas': `${pathToGifs}/gif_flechas.gif`,
};
