/* eslint-disable max-len */
import {
  Test,
  TestReportLink,
  TestResult,
  TestType,
} from '../../shared/test.outside';
import { isInRange } from '../utils/utils';

export interface SagitalTest extends Test {
  onlySagital?: string;
  apex: false;
  escoliosis?: string;
  bipedestacion?: {
    dorsal: number;
    dorsalMA: number;
    lumbar: number;
    pelvis: number;
  };
  bipedestacionResults?: {
    dorsal: TestResult;
    dorsalMA: TestResult;
    lumbar: TestResult;
    pelvis: TestResult;
  };
  sedentacion?: {
    dorsal: number;
    lumbar: number;
    pelvic: number;
  };
  sedentacionResults?: {
    dorsal: TestResult;
    lumbar: TestResult;
    pelvic: TestResult;
  };
  flexMaxTronco?: {
    dorsal: number;
    lumbar: number;
    pelvic: number;
  };
  flexMaxTroncoResults?: {
    dorsal: TestResult;
    lumbar: TestResult;
    pelvic: TestResult;
  };
  dorsalBipSedResult?: SagitalTestResult;
  dorsalBipFlexResult?: SagitalTestResult;
  dorsalResult?: SagitalTestResult;
  lumbarBipSedResult?: SagitalTestResult;
  lumbarBipFlexResult?: SagitalTestResult;
  lumbarResult?: SagitalTestResult;
  comments?: string;
  links?: Array<TestReportLink>;
  multimediaLink?: string;
}

export const sagitalTestFieldsDefinition = {
  escoliosis: {
    title: 'Escoliosis',
    type: 'select',
    values: [
      { label: 'Adams Negativo', key: 'Adams Negativo', status: 'good' },
      { label: 'Adams Positivo', key: 'Adams Positivo', status: 'bad' },
    ],
  },
} as const;

export function createEmptySagital(): SagitalTest {
  return {
    type: 'sagital' as TestType,
    owner: '',
    client: '',
    apex: false,
    bipedestacion: { dorsal: null, dorsalMA: null, lumbar: null, pelvis: null },
    sedentacion: { dorsal: null, lumbar: null, pelvic: null },
    flexMaxTronco: { dorsal: null, lumbar: null, pelvic: null },
  };
}

export interface SagitalTestResult {
  result: string;
  tips: Array<string>;
}

// export function createEmptySagitalTest(){
//     return {
//         type: "sagital";
//         bipedestacion?: {
//             dorsal: number,
//             lumbar: number,
//         }
//     }
// }

export const ConditionsSagitalTest = {
  'bipedestacion/dorsal': [
    { range: [-Infinity, 19.9], name: 'Hipocifosis', normal: true },
    { range: [19.9, 40], name: 'Normalidad', normal: true },
    { range: [40, Infinity], name: 'Hipercifosis', normal: true },
  ],
  'bipedestacion/lumbar': [
    { range: [-Infinity, 0], name: 'Inversión', normal: true },
    { range: [0, 19.9], name: 'Hipolordosis', normal: true },
    { range: [19.9, 40], name: 'Normalidad', normal: true },
    { range: [40, Infinity], name: 'Hiperlordosis', normal: true },
  ],
  'bipedestacion/pelvis': [
    {
      range: [-Infinity, 9.9],
      name: 'Retroversión',
      normal: false,
      tips: [
        'Normalizar la disposición de la pelvis en bipedestación.',
        'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).',
      ],
    },
    { range: [9.9, 30], name: 'Normalidad', normal: true, tips: [] },
    {
      range: [30, Infinity],
      name: 'Anteversión',
      normal: false,
      tips: [
        'Normalizar la disposición de la pelvis en bipedestación.',
        'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).',
      ],
    },
  ],
  'bipedestacion/dorsalMA': [
    { range: [-Infinity, 30], name: 'Actitud Cifótica', normal: true },
    { range: [30, 39.9], name: 'Tendencia a la estructuración', normal: true },
    { range: [40, Infinity], name: 'Cifosis estructurada', normal: true },
  ],
  'sedentacion/dorsal': [
    { range: [-Infinity, 0], name: 'Inversión', normal: true },
    { range: [0, 19.9], name: 'Hipocifosis', normal: true },
    { range: [19.9, 40], name: 'Normalidad', normal: true },
    { range: [40, Infinity], name: 'Hipercifosis', normal: true },
  ],
  'sedentacion/lumbar': [
    { range: [-Infinity, -15], name: 'Hiperlordosis', normal: true },
    { range: [-15, 15], name: 'Normalidad', normal: true },
    { range: [15, Infinity], name: 'Act. Cifótica', normal: true },
  ],
  'sedentacion/pelvic': [
    {
      range: [-Infinity, 79.9],
      name: 'Anteversión',
      normal: false,
      tips: [
        'Normalizar la disposición de la pelvis en Sedentación Asténica.',
        'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).',
      ],
    },
    { range: [79.9, 100], name: 'Normalidad', normal: true, tips: [] },
    {
      range: [100, Infinity],
      name: 'Retroversión',
      normal: false,
      tips: [
        'Normalizar la disposición de la pelvis en Sedentación Asténica.',
        'Higiene postural con espejo o vídeo para verticalizar la pelvis (80º-100º).',
      ],
    },
  ],
  'flexMaxTronco/dorsal': [
    { range: [-Infinity, 39.9], name: 'Hipocifosis', normal: true },
    { range: [39.9, 65], name: 'Normalidad', normal: true },
    { range: [65, Infinity], name: 'Hipercifosis', normal: true },
  ],
  'flexMaxTronco/lumbar': [
    { range: [-Infinity, 0], name: 'Lordótico', normal: true },
    { range: [0, 9.9], name: 'Rectificado', normal: true },
    { range: [9.9, 30], name: 'Normalidad', normal: true },
    { range: [30, Infinity], name: 'Cifótico', normal: true },
  ],
  'flexMaxTronco/pelvic': [
    { range: [-Infinity, 100], name: 'Normalidad', normal: true, tips: [] },
    {
      range: [100, 114],
      name: 'Cortedad G-I',
      normal: false,
      tips: [
        'Estiramiento isquiosural sin cifosar el raquis lumbar y con una cifosis dorsal dentro de la normalidad.',
      ],
    },
    {
      range: [114, Infinity],
      name: 'Cortedad G-II',
      normal: false,
      tips: [
        'Estiramiento isquiosural sin cifosar el raquis lumbar y con una cifosis dorsal dentro de la normalidad.',
      ],
    },
  ],
};

export const ConditionsSagitalTestGlobalResult = {
  'dorsal/bip/sed': [
    {
      range: [-Infinity, 19.9],
      conditions: [
        {
          range: [-Infinity, 19.9],
          result: 'Hipocifosis',
          tips: [
            'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
          ],
        },
        {
          range: [19.9, 40],
          result: 'Hipocifosis funcional',
          tips: [
            'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
          ],
        },
        {
          range: [40, Infinity],
          result: 'Cifosis funcional',
          tips: ['Higiene postural de la sedentación.'],
        },
      ],
    },
    {
      range: [19.9, 40],
      conditions: [
        {
          range: [-Infinity, 19.9],
          result: 'Normalidad',
          tips: [
            'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
          ],
        },
        { range: [19.9, 40], result: 'Normalidad', tips: [] },
        {
          range: [40, Infinity],
          result: 'Hipercifosis funcional',
          tips: ['Higiene postural de la sedentación.'],
        },
      ],
    },
    {
      range: [40, Infinity],
      conditions: [
        {
          range: [-Infinity, 40],
          result:
            'Actitud Hipercifótica dorsal con correcta sedentación asténica',
          tips: [
            'Higiene Postural en bipedestación.',
            'Ejercicios Correctivos de cifosis dorsal.',
          ],
        },
        {
          range: [40, Infinity],
          result: 'Actitud Hipercifótica dorsal',
          tips: [
            'Higiene postural.',
            'Ejercicios Correctivos de la cifosis dorsal',
            'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda.',
          ],
        },
      ],
    },
  ],
  'dorsal/bip/flex': [
    {
      range: [-Infinity, 19.9],
      conditions: [
        {
          range: [-Infinity, 39.9],
          result: 'Hipocifosis',
          tips: [
            'Ejercicios correctivos de flexibilización del segmento dorsal.',
          ],
        },
        {
          range: [39.9, 65],
          result: 'Hipocifosis funcional',
          tips: [
            'Sólo si hubiese dolor en bipedestación, valoración de la zona con algia.',
          ],
        },
        {
          range: [65, Infinity],
          result: 'Hipocifosis + Cifosis funcional',
          tips: ['Higiene postural de la flexión del tronco.'],
        },
      ],
    },
    {
      range: [19.9, 40],
      conditions: [
        { range: [-Infinity, 39.9], result: 'Normalidad', tips: [] },
        { range: [39.9, 65], result: 'Normalidad', tips: [] },
        {
          range: [65, Infinity],
          result: 'Hipercifosis Funcional',
          tips: ['Higiene postural de la flexión del tronco.'],
        },
      ],
    },
    {
      range: [40, Infinity],
      conditions: [
        {
          range: [-Infinity, 65],
          result: 'Actitud Hipercifótica dorsal ',
          tips: [
            'Higiene postural en bipedestación.',
            'Ejercicios Correctivos.',
          ],
        },
        {
          range: [65, Infinity],
          result: 'Hipercifosis',
          tips: ['Higiene postural.', 'Ejercicios Correctivos.'],
        },
      ],
    },
  ],
  'lumbar/bip/sed': [
    {
      range: [-Infinity, 19.9],
      conditions: [
        {
          range: [-Infinity, 15],
          result: 'Hipolordosis Funcional',
          tips: ['Higiene Postural (normalizar).'],
        },
        {
          range: [15, Infinity],
          result: 'Actitud Cifótica Sedente',
          tips: ['Higiene Postural de la sedentación (normalizar).'],
        },
      ],
    },
    {
      range: [19.9, 40],
      conditions: [
        {
          range: [-Infinity, -15],
          result: 'Actitud Lordótica Sedente',
          tips: [
            'Si hay dolor de espalda, precisará higiene postural de la sedentación.',
          ],
        },
        { range: [-15, 15], result: 'Normalidad', tips: [] },
        {
          range: [15, Infinity],
          result: 'Actitud Cifótica lumbar',
          tips: ['Higiene postural de la sedentación (normalizar).'],
        },
      ],
    },
    {
      range: [40, Infinity],
      conditions: [
        {
          range: [-Infinity, -15],
          result: 'Hiperlordosis ',
          tips: ['Higiene postural (normalizar).', 'Ejercicios correctivos.'],
        },
        {
          range: [-15, 15],
          result: 'Hiperlordosis postural',
          tips: ['Higiene postural en bipedestación (normalizar).'],
        },
        {
          range: [15, Infinity],
          result: 'Hipermovilidad Lumbar',
          tips: ['Higiene postural (normalizar).', 'Ejercicios correctivos.'],
        },
      ],
    },
  ],
  'lumbar/bip/flex': [
    {
      range: [-Infinity, 19.9],
      conditions: [
        {
          range: [-Infinity, 10],
          result: 'Hipolordosis Estructurada',
          tips: [
            'Higiene postural.',
            'Ejercicios correctivos en todas las posturas.',
          ],
        },
        {
          range: [10, Infinity],
          result: 'Hipolordosis Funcional',
          tips: ['Higiene postural en bipedestacion (normalizar).'],
        },
      ],
    },
    {
      range: [19.9, 40],
      conditions: [
        {
          range: [-Infinity, 9.9],
          result: 'Normalidad, poca flexibilidad lumbar',
          tips: [],
        },
        { range: [9.9, 30], result: 'Normalidad', tips: [] },
        {
          range: [30, Infinity],
          result: 'Actitud Cifótica Lumbar',
          tips: [
            'Higiene postural de la flexión del tronco.',
            'Ejercicios correctivos.',
          ],
        },
      ],
    },
    {
      range: [40, Infinity],
      conditions: [
        {
          range: [-Infinity, 9.9],
          result: 'Hiperlordosis Estructurada',
          tips: [
            'Higiene postural en bipedestación (normalizar).',
            'Ejercicios correctivos.',
          ],
        },
        {
          range: [9.9, 30],
          result: 'Hiperlordosis Postural',
          tips: ['Higiene postural en bipedestación (normalizar).'],
        },
        {
          range: [30, Infinity],
          result: 'Hipermovilidad Lumbar',
          tips: ['Higiene postural.', 'Ejercicios correctivos.'],
        },
      ],
    },
  ],
};

export const DorsalBipSedDiagnosisConditions = [
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (sed) => isInRange(sed, [-Infinity, 19.9]),
    ],
    result: {
      result: 'Hipocifosis',
      tips: [
        'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (sed) => isInRange(sed, [19.9, 40]),
    ],
    result: {
      result: 'Hipocifosis Funcional',
      tips: [
        'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (sed) => isInRange(sed, [40, Infinity]),
    ],
    result: {
      result: 'Cifosis Funcional',
      tips: ['Higiene postural de la sedentación'],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (sed) => isInRange(sed, [-Infinity, 19.9]),
    ],
    result: {
      result: 'Normalidad',
      tips: [
        'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (sed) => isInRange(sed, [19.9, 40]),
    ],
    result: {
      result: 'Normalidad',
      tips: [],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (sed) => isInRange(sed, [40, Infinity]),
    ],
    result: {
      result: 'Hipercifosis Funcional',
      tips: ['Higiene postural de la sedentación'],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [-Infinity, 30]),
      (sed) => isInRange(sed, [-Infinity, 40]),
    ],
    result: {
      result: 'Actitud Hipercifótica dorsal con correcta SA',
      tips: [
        'Higiene postural en bipedestación',
        'Ejercicios correctivos de cifosis dorsal',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [-Infinity, 30]),
      (sed) => isInRange(sed, [40, Infinity]),
    ],
    result: {
      result: 'Actitud Hipercifótica dorsal',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [30, 39.9]),
      (sed) => isInRange(sed, [-Infinity, 40]),
    ],
    result: {
      result:
        'Actitud Hipercifótica Dorsal con tendencia a la estructuración y correcta SA',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [30, 39.9]),
      (sed) => isInRange(sed, [40, Infinity]),
    ],
    result: {
      result: 'Actitud Hipercifótica Dorsal con tendencia a la estructuración',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [39.9, Infinity]),
      (sed) => isInRange(sed, [-Infinity, 40]),
    ],
    result: {
      result: 'Hipercifosis Estructurada con correcta SA',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [39.9, Infinity]),
      (sed) => isInRange(sed, [40, Infinity]),
    ],
    result: {
      result: 'Hipercifosis Estructurada',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
  {
    conditions: [(apex) => !!apex, (bip) => true, (ma) => true, (sed) => true],
    result: {
      result: 'Hipercifosis Estructurada',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
];

export const DorsalBipFlexDiagnosisConditions = [
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (flex) => isInRange(flex, [-Infinity, 39.9]),
    ],
    result: {
      result: 'Hipocifosis',
      tips: ['Ejercicios correctivos de flexibilización del segmento dorsal.'],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (flex) => isInRange(flex, [39.9, 65]),
    ],
    result: {
      result: 'Hipocifosis Funcional',
      tips: [
        'Si hay dolor de espalda, precisará una valoración de la zona con dolor: dorsalgia, lumbalgia o cervicalgia.',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [-Infinity, 19.9]),
      (ma) => true,
      (flex) => isInRange(flex, [65, Infinity]),
    ],
    result: {
      result: 'Hipocifosis + Cifosis Funcional',
      tips: ['Higiene postural de la flexión del tronco.'],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (flex) => isInRange(flex, [-Infinity, 39.9]),
    ],
    result: {
      result: 'Normalidad',
      tips: [],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (flex) => isInRange(flex, [39.9, 65]),
    ],
    result: {
      result: 'Normalidad',
      tips: [],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [19.9, 40]),
      (ma) => true,
      (flex) => isInRange(flex, [65, Infinity]),
    ],
    result: {
      result: 'Hipercifosis Funcional',
      tips: ['Higiene postural de la flexión de tronco'],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [-Infinity, 30]),
      (flex) => isInRange(flex, [-Infinity, 65]),
    ],
    result: {
      result: 'Actitud Hipercifótica dorsal',
      tips: [
        'Higiene postural en bipedestación',
        'Ejercicios correctivos de cifosis dorsal',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [-Infinity, 30]),
      (flex) => isInRange(flex, [65, Infinity]),
    ],
    result: {
      result: 'Actitud Hipercifótica dorsal',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquídeo dorsal',
      ],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [30, 39.9]),
      (flex) => isInRange(flex, [-Infinity, 65]),
    ],
    result: {
      result: 'Hipercifosis dorsal con tendencia a la estructuración',
      tips: [
        'Higiene postural en bipedestación',
        'Ejercicios correctivos de cifosis dorsal',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [30, 39.9]),
      (flex) => isInRange(flex, [65, Infinity]),
    ],
    result: {
      result: 'Hipercifosis con tendencia a la estructuración',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },

  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [39.9, Infinity]),
      (flex) => isInRange(flex, [-Infinity, 65]),
    ],
    result: {
      result: 'Hipercifosis Dorsal Estructurada',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
  {
    conditions: [
      (apex) => !apex,
      (bip) => isInRange(bip, [40, Infinity]),
      (ma) => isInRange(ma, [39.9, Infinity]),
      (flex) => isInRange(flex, [65, Infinity]),
    ],
    result: {
      result: 'Hipercifosis Estructurada',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
  {
    conditions: [(apex) => !!apex, (bip) => true, (ma) => true, (flex) => true],
    result: {
      result: 'Hipercifosis Estructurada',
      tips: [
        'Higiene postural en todas las posturas',
        'Ejercicios correctivos de cifosis dorsal',
        'Ejercicios de flexibilización del segmento raquideo dorsal',
        'Posible Rx Lateral de columna vertebral en púberes o con dolor de espalda',
      ],
    },
  },
];

function calculateResult(conditionIndex: string, value: number) {
  if (value === null) {
    return null;
  }
  const conditions = ConditionsSagitalTest[conditionIndex];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let index = 0; index < conditions.length; index++) {
    const element = conditions[index];
    if (value >= element.range[0] && value <= element.range[1]) {
      return { text: element.name, normal: element.normal, tips: element.tips };
    }
  }
}

function calculateGlobalResult(
  conditionIndex: string,
  value1: number,
  value2: number,
) {
  if (value1 === null || value2 === null) {
    return null;
  }
  const conditions = ConditionsSagitalTestGlobalResult[conditionIndex];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let index = 0; index < conditions.length; index++) {
    const element1 = conditions[index];
    if (isInRange(value1, element1.range)) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index2 = 0; index2 < element1.conditions.length; index2++) {
        const element2 = element1.conditions[index2];
        if (isInRange(value2, element2.range)) {
          return { result: element2.result, tips: element2.tips };
        }
      }
    }
  }
}

function calculateCombinedDiagnosis(values: any, diagnosisList: any) {
  for (const diagnosis of diagnosisList) {
    const results: Array<boolean> = [];
    for (let i = 0; i < diagnosis.conditions.length; i++) {
      const condition = diagnosis.conditions[i];
      const value = values[i];
      results.push(condition(value));
    }
    if (results.filter((r) => !r).length === 0) {
      return diagnosis.result;
    }
  }
}

function calculateDorsalBipSedResult(test: SagitalTest) {
  const apex = test.apex;
  const bip = test.bipedestacion.dorsal;
  const ma = test.bipedestacion.dorsalMA;
  const sed = test.sedentacion.dorsal;
  if (!test.apex && (bip == null || sed == null)) {
    return null;
  }
  if (!test.apex && bip > 40 && ma == null) {
    return null;
  }

  const values = [apex, bip, ma, sed];
  return calculateCombinedDiagnosis(values, DorsalBipSedDiagnosisConditions);

  // for (const diagnosis of DorsalBipSedDiagnosisConditions) {
  //   const results: Array<boolean> = [];
  //   for (let i = 0; i < diagnosis.conditions.length; i++) {
  //     const condition = diagnosis.conditions[i];
  //     const value = values[i];
  //     results.push(condition(value));
  //   }
  //   if (results.filter(r => !r).length === 0) {
  //     return diagnosis.result;
  //   }
  // }
}

function calculateDorsalBipFlexResult(test: SagitalTest) {
  const apex = test.apex;
  const bip = test.bipedestacion.dorsal;
  const ma = test.bipedestacion.dorsalMA;
  const flex = test.flexMaxTronco.dorsal;
  if (bip == null || flex == null) {
    return null;
  }
  if (bip > 40 && ma == null) {
    return null;
  }

  const values = [apex, bip, ma, flex];
  return calculateCombinedDiagnosis(values, DorsalBipFlexDiagnosisConditions);
}

export function updateResults(test: SagitalTest) {
  (test.bipedestacionResults = {
    dorsal: calculateResult('bipedestacion/dorsal', test.bipedestacion.dorsal),
    lumbar: calculateResult('bipedestacion/lumbar', test.bipedestacion.lumbar),
    pelvis: calculateResult('bipedestacion/pelvis', test.bipedestacion.pelvis),
    dorsalMA: calculateResult(
      'bipedestacion/dorsalMA',
      test.bipedestacion.dorsalMA,
    ),
  }),
    (test.sedentacionResults = {
      dorsal: calculateResult('sedentacion/dorsal', test.sedentacion.dorsal),
      lumbar: calculateResult('sedentacion/lumbar', test.sedentacion.lumbar),
      pelvic: calculateResult('sedentacion/pelvic', test.sedentacion.pelvic),
    }),
    (test.flexMaxTroncoResults = {
      dorsal: calculateResult(
        'flexMaxTronco/dorsal',
        test.flexMaxTronco.dorsal,
      ),
      lumbar: calculateResult(
        'flexMaxTronco/lumbar',
        test.flexMaxTronco.lumbar,
      ),
      pelvic: calculateResult(
        'flexMaxTronco/pelvic',
        test.flexMaxTronco.pelvic,
      ),
    });
  // test.dorsalBipSedResult = calculateGlobalResult('dorsal/bip/sed', test.bipedestacion.dorsal, test.sedentacion.dorsal);
  test.dorsalBipSedResult = calculateDorsalBipSedResult(test);
  // test.dorsalBipFlexResult = calculateGlobalResult('dorsal/bip/flex', test.bipedestacion.dorsal, test.flexMaxTronco.dorsal);
  test.dorsalBipFlexResult = calculateDorsalBipFlexResult(test);
  test.lumbarBipSedResult = calculateGlobalResult(
    'lumbar/bip/sed',
    test.bipedestacion.lumbar,
    test.sedentacion.lumbar,
  );
  test.lumbarBipFlexResult = calculateGlobalResult(
    'lumbar/bip/flex',
    test.bipedestacion.lumbar,
    test.flexMaxTronco.lumbar,
  );
  test.dorsalResult = !!test.dorsalBipFlexResult
    ? test.dorsalBipFlexResult
    : !!test.dorsalBipSedResult
      ? test.dorsalBipSedResult
      : null;
  test.lumbarResult = !!test.lumbarBipFlexResult
    ? test.lumbarBipFlexResult
    : !!test.lumbarBipSedResult
      ? test.lumbarBipSedResult
      : null;
}
