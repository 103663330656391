import { Component, Type, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalHostDirective } from 'src/app/directives/modal-host.directive';
import { Modal } from 'src/app/models/modal.model';
import { ModalKey } from 'src/app/services/modal.service';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { Modals } from 'src/app/static/modals.static';

export type SideModalConfig = {
  slideConfig: 'right' | 'bottom' | 'left-small' | 'right-small';
};
@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
})
export class SideModalComponent {
  onClose = new Subject<any>();

  @ViewChild(ModalHostDirective, { static: true })
  modalHost: ModalHostDirective;

  isOpen = false;

  config: SideModalConfig = {
    slideConfig: 'right',
  };

  constructor(private overrideBackButton: OverrideBackButtonService) {}

  openModal(
    modalId: ModalKey,
    data: any,
    config: SideModalConfig = {
      slideConfig: 'right',
    },
  ) {
    this.config = config;
    const modalComponent: Type<any> = Modals[modalId];
    const viewContainerRef = this.modalHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(modalComponent);
    const componentInstance: Modal = componentRef.instance;
    componentInstance.data = data;
    this.onClose = componentInstance.onClose;
    this.onClose.pipe(take(1)).subscribe((result) => {
      this.isOpen = false;
      viewContainerRef.clear();
    });
    this.isOpen = true;
    this.overrideBackButton.override(() =>
      componentInstance.historyBackClose(),
    );
  }

  onBackdropClick(event) {
    if (event.target !== event.currentTarget) {
      return;
    }
    history.back();
  }
}
