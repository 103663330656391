import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-in-progress',
  templateUrl: './action-in-progress.component.html',
  styleUrls: ['./action-in-progress.component.scss']
})
export class ActionInProgressComponent {

  visible = false;
  message = '';

  constructor() { }

  show(message: string) {
    this.message = message;
    this.visible = true;
  }

  hide() {
    this.message = '';
    this.visible = false;
  }

}
