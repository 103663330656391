<div class="content-wrapper flex h-screen flex-col">
  <!-- Auth loading overlay -->
  <div
    *ngIf="authLoading"
    @fadeInOut
    class="fixed inset-0 z-50 flex items-center justify-center bg-white"
  >
    <div class="text-center">
      <!-- Sign In UI -->
      <ng-container *ngIf="authAction === 'signin'">
        <i
          class="fas fa-right-to-bracket fa-bounce mb-4 text-4xl text-intecc"
        ></i>
        <p class="text-inteccDark">Iniciando sesión...</p>

        <!-- Reload button with fade-in animation -->
        <div *ngIf="showReloadButton" @fadeInOut class="mt-8">
          <p class="mb-3 text-sm text-neutral-500">
            ¿Está tardando mucho? Pulsa el botón para recargar la página.
          </p>
          <button
            (click)="reloadPage()"
            class="rounded-md border border-neutral-300 px-4 py-2 text-sm text-neutral-600 transition-colors duration-200 hover:border-neutral-400 hover:bg-neutral-50 hover:text-neutral-800"
          >
            <i class="fas fa-rotate-right mr-2"></i>
            Recargar página
          </button>
        </div>
      </ng-container>

      <!-- Sign Out UI -->
      <ng-container *ngIf="authAction === 'signout'">
        <i class="fas fa-door-open fa-bounce mb-4 text-4xl text-intecc"></i>
        <p class="text-inteccDark">Cerrando sesión...</p>
        <p class="mt-2 text-sm text-neutral-500">¡Hasta pronto!</p>
      </ng-container>
    </div>
  </div>

  <div class="flex flex-grow flex-col overflow-y-auto overflow-x-hidden">
    <app-header></app-header>
    <router-outlet></router-outlet>

    <p *ngIf="hasTrial" class="px-5 pb-5 text-center italic text-neutral-400">
      Estás disfrutando de un período de prueba gratuito. Te quedan
      {{ daysLeftInTrial }} días.
    </p>

    <app-learn></app-learn>
    <app-floating-video></app-floating-video>
    <app-modal></app-modal>
    <app-action-in-progress></app-action-in-progress>
    <app-notifications></app-notifications>
    <app-inclinometer-reader></app-inclinometer-reader>
    <app-side-modal></app-side-modal>
    <app-side-modal-left></app-side-modal-left>
    <app-bottom-modal></app-bottom-modal>
    <app-confirm-action-dialog></app-confirm-action-dialog>
  </div>

  <div class="flex-shrink-0 bg-white shadow-lg" *ngIf="isClient">
    <div class="flex">
      <a
        class="flex flex-1 flex-col items-center justify-center py-2 text-inteccDark transition-colors duration-300 hover:text-intecc"
        [routerLink]="['/c']"
        routerLinkActive="!text-intecc"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-home mb-1 text-xl"></i>
        <span class="text-xs">Inicio</span>
      </a>
      <a
        class="flex flex-1 flex-col items-center justify-center py-2 text-inteccDark transition-colors duration-300 hover:text-intecc"
        [routerLink]="['/c/tests']"
        routerLinkActive="!text-intecc"
      >
        <i class="fas fa-heart mb-1 text-xl"></i>
        <span class="text-xs">Valoración</span>
      </a>
      <a
        class="flex flex-1 flex-col items-center justify-center py-2 text-inteccDark transition-colors duration-300 hover:text-intecc"
        [routerLink]="['/c/training']"
        routerLinkActive="!text-intecc"
      >
        <i class="fas fa-dumbbell mb-1 text-xl"></i>
        <span class="text-xs">Entrenamiento</span>
      </a>
      <a
        class="flex flex-1 flex-col items-center justify-center py-2 text-inteccDark transition-colors duration-300 hover:text-intecc"
        [routerLink]="['/c/professional']"
        routerLinkActive="!text-intecc"
      >
        <i class="fas fa-user mb-1 text-xl"></i>
        <span class="text-xs">Profesional</span>
      </a>
    </div>
  </div>
</div>
