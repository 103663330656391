<div class="d-flex justify-content-center align-items-center">
  <h2
    class="my-4 text-center text-2xl font-bold text-primary-6 md:my-8 md:text-3xl"
  >
    Nivel de entrenamiento
  </h2>
  <h3
    class="my-4 text-center text-2xl font-bold text-primary-6 md:mb-8 md:text-3xl"
  >
    {{ data.title }}
  </h3>
  <div class="flex items-center justify-center px-4">
    <img
      src="{{ data.imageUrl }}"
      src="../../../assets/images/intecc-with-bg-rounded.png"
      alt="clients-segments-level"
      class="w-full"
    />
  </div>
</div>
