import { Injectable } from '@angular/core';
import { FunctionsService } from './functions.service';
import { FunctionNames } from '../static/functions.static';
import { saveAs } from 'file-saver';
import { Tag } from '../models/tags.model';
import { TestNames } from '../../shared/test.outside';

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    constructor(private functionsService: FunctionsService) {
        // this.functionsService.setTestMode();
    }

    async exportTestsToCsv(tag: Tag, testType: string) {
        const result = await this.functionsService.call(FunctionNames.exportTestsToCsvCallable, {
            tagId: tag ? tag.id : -1,
            testType
        });
        this.downloadCsv(result, `INTECC-${tag ? tag.name : 'todos'}-${TestNames[testType].split(' ').join('')}`);
    }

    downloadCsv(data: any, name: string) {
        const blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, `${name}.csv`);
    }
}
