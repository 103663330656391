import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-new-valoration-menu',
    templateUrl: './new-valoration-menu.component.html',
    styleUrls: ['./new-valoration-menu.component.scss']
})
export class NewValorationMenuComponent {

    @Input() isOpen = false;

    @Output() createTestEvent = new EventEmitter<string>();

    constructor() { }

    close() {
        history.back();
    }

    createTest(test: string) {
        this.close();
        this.createTestEvent.next(test);
    }

}
