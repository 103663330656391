import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { Client } from 'src/app/models/client.model';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ModalService } from 'src/app/services/modal.service';
import { TagsService } from 'src/app/services/tags.service';
import { Observable, combineLatest } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/config/app.config';
import { FilterSearchComponent } from 'src/app/components/filter-search/filter-search.component';
import { FilterSearchService } from 'src/app/services/filter-search.service';
import { FunctionsService } from 'src/app/services/functions.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
})
export class ClientsComponent implements OnInit, AfterViewInit {
  selectedTest = true;

  selectedClients: Map<string, Client> = new Map();

  menuOpen = false;

  appConfig = AppConfig;

  isProductionEnvironment = environment.production;

  currentUserClientsFiltered$: Observable<Client[]>;

  @ViewChild(FilterSearchComponent)
  filterSearchComponent: FilterSearchComponent;

  constructor(
    public authService: AuthService,
    public clientsService: ClientsService,
    private confirmActionDialogService: ConfirmActionDialogService,
    private modalService: ModalService,
    private tagsService: TagsService,
    private filterSearchService: FilterSearchService,
  ) {}

  ngOnInit() {
    // this.modalService.openModal('new-upgrade');
    this.clientsService.setCurrentClient(null);
  }

  ngAfterViewInit() {
    this.initFilteredClientsObservable();
  }

  initFilteredClientsObservable() {
    this.currentUserClientsFiltered$ = combineLatest([
      this.clientsService.currentUserClients$,
      this.filterSearchComponent.filterConditions$,
      this.filterSearchComponent.createdAtSortDirection$,
      this.filterSearchComponent.nameSortDirection$,
    ]).pipe(
      map(
        ([clients, filterValue, createdAtSortDirection, nameSortDirection]) => {
          const filtered = this.filterSearchService.filter(
            clients,
            filterValue,
          );
          if (createdAtSortDirection) {
            return filtered.sort((a, b) => {
              if (createdAtSortDirection === 'asc') {
                return a.createdAt - b.createdAt;
              } else {
                return b.createdAt - a.createdAt;
              }
            });
          }
          if (nameSortDirection) {
            return filtered.sort((a, b) => {
              if (a.name < b.name) {
                return nameSortDirection === 'asc' ? -1 : 1;
              }
              if (a.name > b.name) {
                return nameSortDirection === 'asc' ? 1 : -1;
              }
              return 0;
            });
          }
          return filtered;
        },
      ),
      delay(0),
    );
  }

  // editReportsConfig() {
  //   this.modalService.openModal('edit-reports-config');
  // }

  selectClient(client: any) {
    if (this.selectedClients.has(client.id)) {
      this.selectedClients.delete(client.id);
    } else {
      this.selectedClients.set(client.id, client);
    }
  }

  isClientSelected(client: any) {
    if (this.selectedClients.has(client.id)) {
      return true;
    } else {
      return false;
    }
  }

  getSelectedClientsCount() {
    return this.selectedClients.size;
  }

  removeSelection() {
    this.selectedClients.clear();
  }

  async deleteClients() {
    this.confirmActionDialogService.openDialog({
      title: `¿Quieres eliminar ${this.selectedClients.size} cliente/s?`,
      description: `Si continúas, no podrás recuperar estos clientes ni ninguna de sus valoraciones.`,
      confirmButton: 'Eliminar clientes',
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.clientsService.deleteClients([
            ...this.selectedClients.keys(),
          ]);
          this.removeSelection();
        }
      },
    });
  }

  openAddClientModal() {
    this.modalService.openModal('create-edit-client', {});
  }

  openExportTestsModal() {
    this.modalService.openModal('export-tests', {});
  }

  openAccountModal() {
    this.modalService.openModal('account', {});
  }

  openLinkClientModal(client: Client) {
    this.modalService.openModal('link-client', { client });
  }

  getTagName$(tagId: number) {
    return this.tagsService
      .getTags$()
      .pipe(map((tags) => this.tagsService.getTag(tagId, tags)));
  }
}
