import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type DropdownOption = {
  label: string;
  value: string;
};

@Component({
  selector: 'app-new-dropdown',
  templateUrl: './new-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewDropdownComponent),
      multi: true,
    },
  ],
})
export class NewDropdownComponent implements ControlValueAccessor {
  @Input() options: DropdownOption[] = [];
  @Input() readonly: boolean = false;
  @Output() optionSelected = new EventEmitter<string>();

  isOpen = false;
  selectedOption: DropdownOption | null = null;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input()
  buttonClass: string = '';
  @Input()
  wrapperClass: string = '';

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleDropdown() {
    if (this.readonly) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  selectOption(option: string) {
    this.selectedOption = this.options.find((o) => o.value === option);
    this.onChange(option);
    this.onTouched();
    this.optionSelected.emit(option);
    this.isOpen = false;
  }

  writeValue(value: string): void {
    // this.selectedOptionValue = value;
    this.selectedOption = this.options.find((o) => o.value === value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }
}
