<div *ngIf="appName === 'rom'">
  <div class="full-hero">
    <div class="full-hero__navbar">
      <a [routerLink]="['/']" class="full-hero__navbar__brand">
        <img
          src="../../../assets/images/rom-logo-v3.png"
          appResponsiveSrc
          alt="Raquis"
        />
      </a>
      <div class="full-hero__navbar__action">
        <button (click)="login()" class="button-solid button-solid--primary">
          Iniciar sesión
        </button>
      </div>
    </div>
    <div class="full-hero__content">
      <div class="full-hero__left">
        <div class="full-hero__content__heading">
          Valora cualquier rango de movimiento sólo con tu smartphone
        </div>
        <div class="full-hero__content__subheading">
          Con <b>ROM</b> podrás cuantificar la salud articular de tus clientes.
        </div>
        <div class="full-hero__content__video"></div>
        <button
          (click)="login()"
          class="full-hero__content__cta button-solid button-solid--primary"
        >
          <p>Comienza de forma gratuita</p>
        </button>
      </div>
      <div class="full-hero__right"></div>
    </div>
  </div>
  <div class="section">
    <h2 class="section__title">Analiza...</h2>
    <p class="section__subtitle">
      de forma general o analítica el rango de movimiento.
    </p>
    <div class="cards-container">
      <div class="card">
        <div class="card__icon">
          <i class="fas fa-drafting-compass"></i>
        </div>
        <div class="card__title">Modo libre o guiado</div>
        <div class="card__text">
          Cuantifica en tiempo real cualquier articulación, identificando si el
          rango es <b>normal</b>,
          <b>limitado</b>
          y <b>óptimo</b>.
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <i class="fas fa-mobile-alt"></i>
        </div>
        <div class="card__title">Multiplataforma</div>
        <div class="card__text">
          Accede desde cualquier dispositivo y emite informes profesionales en
          segundos
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <i class="fas fa-users"></i>
        </div>
        <div class="card__title">Clientes Conectados</div>
        <div class="card__text">
          Todos tus clientes en un mismo lugar con informes personalizados en
          pdf y csv
        </div>
      </div>
      <!-- <div class="card">
                <div class="card__icon">
                    <i class="fas fa-images"></i>
                </div>
                <div class="card__title">
                    Imágenes
                </div>
                <div class="card__text">
                    Aprende a identificar los distintos morfotipos raquídeos a través de la galería de imágenes con casos
                    reales.
                </div>
            </div> -->
    </div>
  </div>
  <div class="section">
    <!-- <h2 class="section__title">Analiza...</h2>
        <p class="section__subtitle">de forma general o analítica el rango de movimiento.</p>
         -->
    <div class="video-container">
      <div class="embed-container">
        <iframe
          src="https://player.vimeo.com/video/388598739"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </div>
  <div class="section section--image section--image--rom">
    <div class="overlay"></div>
    <h2 class="section__title">Aprende desde cero gracias a la Academia</h2>
    <p class="section__subtitle">
      Las Aplicaciones de INTECC I+D+i tienen una Academia exclusiva con todos
      los vídeo-tutoriales y publicaciones científicas necesarias para aprender
      a valorar desde cero
    </p>

    <div class="bullet-container">
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-images"></i></div>
        <div class="bullet__title">Guía rápida</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-video"></i></div>
        <div class="bullet__title">Tutoriales paso a paso</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-graduation-cap"></i></div>
        <div class="bullet__title">Artículos científicos</div>
      </div>
    </div>
  </div>
  <div class="section section--white">
    <h2 class="section__title">Creado por profesionales de la salud</h2>
    <p class="section__subtitle">
      Aprende a valorar con los autores originales de las publicaciones.
    </p>
    <div class="authors-container">
      <a
        href="https://www.intecc.org/formacion/docentes/antonio-cejudo-palomo"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Antonio-Cejudo-Palomo.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> Dr. Antonio <br />
          Cejudo Palomo
        </div>
      </a>
      <a
        href="https://www.intecc.org/formacion/docentes/fernando-santonja-medina"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Fernando-Santonja-UMU.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> Dr. Fernando <br />
          Santonja Medina
        </div>
      </a>
      <a
        href="https://www.intecc.org/formacion/docentes/julian-aguilera-campillos"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Julián-Aguilera-Campillos.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> D. Julián <br />
          Aguilera Campillos
        </div>
      </a>
      <a
        href="https://www.intecc.org/formacion/docentes/pilar-sainz-de-baranda"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Pilar-Sáinz-de-Baranda.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> Dra. Pilar <br />
          Sáinz De Baranda
        </div>
      </a>
    </div>
  </div>
  <div class="footer-cta">
    <h2 class="footer-cta__title section__title">Acceso gratuito</h2>
    <div class="footer-cta__subtitle section__subtitle">
      “Cuando la Investigación y la Tecnología marcan la diferencia en el
      entrenamiento”
    </div>
    <div class="footer-cta__action">
      <button
        (click)="login()"
        class="full-hero__content__cta button-solid button-solid--invert"
      >
        <span class=""><i class="fab fa-google"></i> </span>
        <p class="">Comienza de forma gratuita.</p>
      </button>
    </div>
  </div>
</div>
<div *ngIf="appName === 'raquis'">
  <div class="full-hero full-hero--raquis">
    <div class="full-hero__navbar">
      <a
        [routerLink]="['/']"
        class="full-hero__navbar__brand full-hero__navbar__brand--raquis"
      >
        <img
          src="../../../assets/images/raquis-logo-3-white-v2.png"
          appResponsiveSrc
          alt="Raquis"
        />
      </a>
      <div class="full-hero__navbar__action">
        <!-- <button (click)="login()" class="button-solid button-solid--primary">
          Iniciar sesión
        </button> -->
      </div>
    </div>
    <div class="full-hero__content">
      <div class="full-hero__left">
        <div class="full-hero__content__heading">
          Diagnostica y mejora el estado de la columna vertebral en segundos
        </div>
        <div class="full-hero__content__subheading">
          Con la App Raquis podrás realizar una valoración profesional de la
          columna vertebral usando el smartphone y obteniendo un diagnóstico
          completo con orientaciones clínicas personalizadas
        </div>
        <div class="full-hero__content__video"></div>
        <button
          (click)="login()"
          class="full-hero__content__cta button-solid button-solid--primary"
        >
          <p>Iniciar sesión</p>
        </button>
      </div>
      <div class="full-hero__right"></div>
    </div>
  </div>
  <div class="section">
    <h2 class="section__title">📲 Investigación y Tecnología</h2>
    <p class="section__subtitle">
      Aplicación web, basada en la evidencia científica, que diagnostica y
      mejora la primera causa de incapacidad en el mundo
    </p>
    <div class="cards-container">
      <div class="card">
        <div class="card__icon">
          <!-- <i class="fas fa-drafting-compass"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Científica.gif"
            alt=""
          />
        </div>
        <div class="card__title">Científica</div>
        <div class="card__text">
          Valoración completa del Morfotipo Sagital Integral
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <!-- <i class="fas fa-mobile-alt"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Multiplataforma.gif"
            alt=""
          />
        </div>
        <div class="card__title">Multiplataforma</div>
        <div class="card__text">
          Accede desde cualquier dispositivo y sistema operativo
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <!-- <i class="fas fa-users"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Tecnológica.gif"
            alt=""
          />
        </div>
        <div class="card__title">Tecnológica</div>
        <div class="card__text">
          Gestión de clientes, informes y valoraciones centralizadas
        </div>
      </div>
      <!-- <div class="card">
                <div class="card__icon">
                    <i class="fas fa-images"></i>
                </div>
                <div class="card__title">
                    Imágenes
                </div>
                <div class="card__text">
                    Aprende a identificar los distintos morfotipos raquídeos a través de la galería de imágenes con casos
                    reales.
                </div>
            </div> -->
    </div>
  </div>

  <div class="section">
    <h2 class="section__title">⌛ En segundos</h2>
    <p class="section__subtitle">
      Estado de la columna dorsal, lumbar y pelvis
    </p>
    <div class="mx-auto max-w-5xl">
      <img
        class="w-full rounded-3xl"
        src="../../../assets/images/APP-RAQUIS-GIF-BIP.gif"
        alt=""
      />
    </div>
  </div>

  <!-- <div class="section">
        <h2 class="section__title">Analiza...</h2>
        <p class="section__subtitle">de forma general o analítica el rango de movimiento.</p>
        
        <div class="video-container">
            <div class='embed-container'><iframe src='https://player.vimeo.com/video/388598739' frameborder='0'
                    webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
        </div>
    </div> -->
  <div class="section section--image section--image--rom">
    <div class="overlay"></div>
    <h2 class="section__title">Aprende con PROfesionales</h2>
    <p class="section__subtitle">
      Accede gratis a una Academia exclusiva con videotutoriales, publicaciones
      y manuales interactivos para aprender a valorar desde cero
    </p>

    <div class="bullet-container">
      <div class="bullet">
        <div class="bullet__icon">
          <!-- <i class="fas fa-images"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Guía rápida.gif"
            alt=""
          />
        </div>
        <div class="bullet__title">Guía rápida</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon">
          <!-- <i class="fas fa-video"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Videotutoriales.gif"
            alt=""
          />
        </div>

        <div class="bullet__title">Videotutoriales</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon">
          <!-- <i class="fas fa-graduation-cap"></i> -->
          <img
            src="../../../assets/new-gifs/GIF Minimalistas/Manual.gif"
            alt=""
          />
        </div>
        <div class="bullet__title">Manual interactivo</div>
      </div>
    </div>
  </div>
  <div class="section section--white">
    <h2 class="section__title">INTECC.ORG</h2>
    <p class="section__subtitle">
      Creada por referentes internacionales en columna
    </p>
    <div class="authors-container">
      <!-- <a href="https://www.intecc.org/formacion/docentes/antonio-cejudo-palomo" class="author">
                <div class="author__img"><img src="../../../assets/images/Antonio-Cejudo-Palomo.png"
                        alt="Antonio-Cejudo-Palomo"></div>
                <div class="author__name"><i class="fas fa-external-link-alt"></i> Dr. Antonio <br> Cejudo Palomo</div>
            </a> -->
      <a
        href="https://www.intecc.org/formacion/docentes/fernando-santonja-medina"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Fernando-Santonja-UMU.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> Dr. Fernando <br />
          Santonja Medina
        </div>
      </a>
      <a
        href="https://www.intecc.org/formacion/docentes/julian-aguilera-campillos"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Julián-Aguilera-Campillos.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> D. Julián <br />
          Aguilera Campillos
        </div>
      </a>
      <a
        href="http://www.intecc.org/formacion/docentes/alvaro-garzo-serrano"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/ags.jpeg"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> D. Álvaro <br />
          Garzo Serrano
        </div>
      </a>
    </div>
  </div>
  <div class="footer-cta">
    <h2 class="footer-cta__title section__title">Acceso gratuito</h2>
    <div class="footer-cta__subtitle section__subtitle max-w-2xl">
      “Comprometidos con mejorar la primera causa de incapacidad y dolor del
      mundo, gracias a los últimos avances tecnológicos y en investigación de la
      institución, emitiendo un diagnóstico médico de la columna en segundos con
      orientaciones clínicas personalizadas”
    </div>
    <div class="footer-cta__action">
      <button
        (click)="login()"
        class="full-hero__content__cta button-solid button-solid--invert"
      >
        <span class=""><i class="fab fa-google"></i> </span>
        <p class="">2 MESES GRATIS</p>
      </button>
    </div>
  </div>
</div>
<div *ngIf="appName === 'vafold'">
  <div class="full-hero full-hero--raquis">
    <div
      class="flex h-screen w-full items-center justify-items-stretch bg-blue-900 p-10"
      style="background-color: #000e33"
    >
      <div
        class="mx-auto grid max-w-7xl grid-cols-1 gap-x-24 gap-y-10 lg:grid-cols-one-two"
        style=""
      >
        <div
          class="flex flex-col items-center justify-center text-white lg:items-center"
        >
          <div class="mb-8 w-40">
            <img
              class="w-full"
              src="../../../assets/images/intecc-with-bg-rounded.png"
              alt=""
            />
          </div>
          <p class="mb-8 text-center text-2xl lg:text-center">
            La primera App del Mundo que te permite crear cualquier Valoración o
            Prescripción de Ejercicio. Diseñada por y para PROfesionales.
          </p>
          <button
            [disabled]="loginWorking"
            (click)="login()"
            class="button-solid button-solid--intecc"
          >
            <div *ngIf="!loginWorking">Iniciar sesión</div>
            <div *ngIf="loginWorking">Iniciando...</div>
          </button>
        </div>
        <div class="">
          <!-- <img class="w-full lg:scale-110 lg:translate-x-7" src="../../../assets/images/gif-app-vaf.gif"
            alt="" style=""> -->
          <video
            controls
            autoplay
            class="w-full lg:translate-x-7 lg:scale-110"
            src="../../../assets/videos/app-vaf-presentacion.mp4"
          ></video>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <h2 class="section__title">
      Todo lo que necesitas para valorar y prescribir ejercicios
    </h2>
    <p class="section__subtitle">
      Siempre a tu alcance en tu smartphone, tableta o PC
    </p>
    <div class="cards-container">
      <div class="card">
        <div class="card__icon">
          <i class="far fa-file-image"></i>
        </div>
        <div class="card__title">
          Almacenamiento, edición y análisis multimedia
        </div>
        <div class="card__text">
          Sube cualquier foto y utiliza las herramientas multimedia para medir
          ángulos y distancias, establecer referencias y mucho más.
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <i class="fas fa-mobile-alt"></i>
        </div>
        <div class="card__title">Multiplataforma</div>
        <div class="card__text">
          Accede desde cualquier dispositivo, los datos de tus clientes estarán
          siempre a tu disposición estés donde estés.
        </div>
      </div>
      <div class="card">
        <div class="card__icon">
          <i class="fas fa-drafting-compass"></i>
        </div>
        <div class="card__title">
          Protocolos de valoración y creación de tests personalizados
        </div>
        <div class="card__text">
          Analiza la postura, rango de movimiento, composición corporal,
          desalineaciones raquídeas... O crea tus propios protocolos.
        </div>
      </div>
      <!-- <div class="card">
                <div class="card__icon">
                    <i class="fas fa-images"></i>
                </div>
                <div class="card__title">
                    Imágenes
                </div>
                <div class="card__text">
                    Aprende a identificar los distintos morfotipos raquídeos a través de la galería de imágenes con casos
                    reales.
                </div>
            </div> -->
    </div>
  </div>
  <!-- <div class="section">

    <div class="video-container">
      <div class='embed-container'><iframe src='https://player.vimeo.com/video/431774894' frameborder='0'
          webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
    </div>
  </div> -->

  <div class="section">
    <div
      style="max-width: 1200px"
      class="m-auto grid w-full overflow-hidden rounded-lg text-black md:grid-cols-2"
    >
      <div class="flex flex-col justify-center p-8 md:p-20">
        <div
          class="flex flex-col items-center text-center md:items-start md:text-left"
        >
          <h2 class="text-4xl font-bold">Galería</h2>
          <div class="mb-3"></div>
          <p class="text-lg">
            Entra y accede gratis a mas de 1000 ejercicios, ordenados por
            temáticas y realizados por profesionales.
          </p>
          <div class="mb-10"></div>
          <a
            target="_blank"
            style="color: white"
            class="cursor-pointer rounded-md bg-inteccDark px-5 py-4 text-xl font-bold text-white transition-all duration-200 hover:-translate-y-2"
            (click)="login()"
            >Empieza ahora</a
          >
        </div>
      </div>
      <div class="p-5">
        <img
          class="h-full w-full rounded-lg object-cover md:translate-x-10 md:translate-y-10 md:rounded-xl"
          src="../../../assets/images/ejemplo-ejercicio.webp"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="section">
    <div
      style="max-width: 1200px"
      class="m-auto grid w-full overflow-hidden rounded-lg bg-inteccDark text-white shadow-xl md:grid-cols-2"
    >
      <div class="flex flex-col justify-center p-8 md:p-20">
        <div
          class="flex flex-col items-center text-center md:items-start md:text-left"
        >
          <h2 class="text-4xl font-bold">Curso gratuito +50h</h2>
          <div class="mb-3"></div>
          <p class="text-lg">
            Hazte un experto en valoración y consigue tu certificado.
          </p>
          <div class="mb-10"></div>
          <a
            target="_blank"
            style="color: black"
            class="rounded-md bg-white px-5 py-4 text-xl font-bold text-black transition-all duration-200 hover:-translate-y-2"
            href="https://plataforma.intecc.org/courses/INICIACION-valoracion-anatomica-y-funcional"
            >Accede gratis ahora</a
          >
        </div>
      </div>
      <div class="p-5 md:p-0">
        <img
          class="h-full w-full rounded-lg object-cover md:translate-x-10 md:translate-y-10 md:rounded-xl"
          src="../../../assets/images/curso-gratuito-2.png"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="section">
    <!-- <h2 class="section__title">Analiza...</h2>
        <p class="section__subtitle">de forma general o analítica el rango de movimiento.</p>
         -->
    <div class="video-container">
      <div class="embed-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5G1NVKQUkJE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>

  <div class="section section--image section--image--rom">
    <div class="overlay"></div>
    <h2 class="section__title">Aprende desde cero gracias a la Academia</h2>
    <p class="section__subtitle">
      Las Aplicaciones de INTECC I+D+i tienen una Academia exclusiva con todos
      los vídeo-tutoriales y publicaciones científicas necesarias para aprender
      a valorar desde cero
    </p>

    <div class="bullet-container">
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-images"></i></div>
        <div class="bullet__title">Guía rápida</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-video"></i></div>
        <div class="bullet__title">Tutoriales paso a paso</div>
      </div>
      <div class="bullet">
        <div class="bullet__icon"><i class="fas fa-graduation-cap"></i></div>
        <div class="bullet__title">Artículos científicos</div>
      </div>
    </div>
  </div>
  <div class="section section--white">
    <h2 class="section__title">Creado por profesionales de la salud</h2>
    <p class="section__subtitle">
      Aprende a valorar con los autores originales de las publicaciones.
    </p>
    <div class="authors-container">
      <!-- <a href="https://www.intecc.org/formacion/docentes/antonio-cejudo-palomo" class="author">
        <div class="author__img"><img src="../../../assets/images/Antonio-Cejudo-Palomo.png"
            alt="Antonio-Cejudo-Palomo"></div>
        <div class="author__name"><i class="fas fa-external-link-alt"></i> Dr. Antonio <br> Cejudo Palomo</div>
      </a> -->
      <a
        href="https://www.intecc.org/formacion/docentes/fernando-santonja-medina"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Fernando-Santonja-UMU.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> Dr. Fernando <br />
          Santonja Medina
        </div>
      </a>
      <div class="mr-20"></div>
      <a
        href="https://www.intecc.org/formacion/docentes/julian-aguilera-campillos"
        class="author"
      >
        <div class="author__img">
          <img
            src="../../../assets/images/Julián-Aguilera-Campillos.png"
            alt="Antonio-Cejudo-Palomo"
          />
        </div>
        <div class="author__name">
          <i class="fas fa-external-link-alt"></i> D. Julián <br />
          Aguilera Campillos
        </div>
      </a>
      <!-- <a href="https://www.intecc.org/formacion/docentes/pilar-sainz-de-baranda" class="author">
        <div class="author__img"><img src="../../../assets/images/Pilar-Sáinz-de-Baranda.png"
            alt="Antonio-Cejudo-Palomo"></div>
        <div class="author__name"><i class="fas fa-external-link-alt"></i> Dra. Pilar <br>
          Sáinz De Baranda</div>
      </a> -->
    </div>
  </div>
  <div class="footer-cta">
    <h2 class="footer-cta__title section__title">Acceso gratuito</h2>
    <div class="footer-cta__subtitle section__subtitle">
      “Cuando la Investigación y la Tecnología marcan la diferencia en el
      entrenamiento”
    </div>
    <div class="footer-cta__action">
      <button
        (click)="login()"
        class="full-hero__content__cta button-solid button-solid--invert"
      >
        <span class=""><i class="fab fa-google"></i> </span>
        <p class="">Comienza de forma gratuita.</p>
      </button>
    </div>
  </div>
</div>

<div *ngIf="appName === 'vaf'">
  <div
    class="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-2 text-center"
  >
    <div class="mb-8 flex items-center justify-center">
      <img
        src="../../../assets/images/intecc-with-bg-rounded.png"
        alt="VAF Logo"
        class="h-20 w-20 object-contain"
      />
    </div>
    <h2 class="mb-6 max-w-xl text-3xl font-bold">
      La aplicación basada en la ciencia que mejora la salud y calidad de vida
    </h2>
    <h2 class="mb-6 text-lg font-semibold text-gray-400">
      Elige cómo quieres iniciar sesión
    </h2>
    <div class="flex items-center justify-center space-x-2 sm:space-x-6">
      <a
        [routerLink]="['/professional-login']"
        class="flex w-36 flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-md transition-shadow duration-300 hover:shadow-lg"
      >
        <!-- <i class="fas fa-heartbeat mb-3 text-4xl text-intecc"></i>
          <i class="fas fa-user-nurse mb-3 text-4xl text-intecc"></i>
          <i class="fas fa-dumbbell mb-3 text-4xl text-intecc"></i> -->
        <i class="fas fa-user-tie mb-3 text-4xl text-intecc"></i>
        <span class="font-semibold sm:text-lg">Profesional</span>
      </a>
      <a
        [routerLink]="['/c/login']"
        [queryParams]="{ professionalId: professionalIdQueryParam }"
        class="flex w-36 flex-col items-center rounded-lg border border-gray-200 bg-white p-4 shadow-md transition-shadow duration-300 hover:shadow-lg"
      >
        <!-- <i class="fas fa-heartbeat mb-3 text-4xl text-intecc"></i>
          <i class="fas fa-walking mb-3 text-4xl text-intecc"></i> -->
        <i class="fas fa-user mb-3 text-4xl text-intecc"></i>
        <span class="font-semibold sm:text-lg">Cliente</span>
      </a>
    </div>
    <!-- <div class="flex h-full w-full items-center justify-center px-4 sm:-mt-10">
      <img
        class="h-full w-full max-w-xl object-contain"
        src="../../../assets/images/AVATAR 3D (940 × 900 px).png"
        alt=""
      />
    </div> -->
  </div>
</div>
