<div class="overlay" [class.overlay--open]="isOpen" (click)="onBackdropClick($event)">
    <div class="box" [class.box--open]="isOpen">
        <div class="close-button" (click)="close()"><i class="far fa-times-circle"></i></div>
        <div class="new-tut-button" (click)="showGif()"><i class="fas fa-images"></i></div>
        <!-- <div class="gif-button" (click)="showGif()"><i class="fas fa-photo-video"></i></div> -->
        <div class="movement-name" *ngIf="movementId">{{RomMovements[movementId].name}}</div>
        <div class="movement-subname" *ngIf="movementId">{{RomMovements[movementId].subname}}</div>
        <div class="graphic-result" *ngIf="movementId">
            <app-result-bar [movement]="RomMovements[movementId]" [currentResult]="currentResult" ></app-result-bar>
        </div>
        <div class="measure-error-container">
            <p class="measure-error" *ngIf="hasMeasureError">El valor es superior a lo esperado, te recomendamos volver a medir.</p>
        </div>
        <div class="live-inclinometer">
            <app-live-inclinometer-viewer [currentResult]="currentResult" (resultChanged)="onResultChanged($event)" ></app-live-inclinometer-viewer>
        </div>
        <div class="action">
            <!-- <button *ngIf="gifPath" class="tut-button button-link" (click)="showGif()">
                <i class="fas fa-photo-video"></i>
                Tutorial rápido
            </button> -->
            <button class="button-solid button-solid--small button-solid--primary" (click)="close()">
                Guardar
            </button>
        </div>
        <div class="gif-container" [class.gif-container--open]="isGifOpen" (click)="hideGif()">
            <div class="gif-gif">
                <img src="../../../assets/learn/rom-gifs/{{gifPath}}" alt="">
            </div>
        </div>
    </div> 
</div>
