<div class="result-bar">
    <div *ngIf="movement.id !== 'free'" class="">
        <div class="labels">
            <div class="label">Limitado</div>
            <div class="label">Normal</div>
            <div class="label">Óptimo</div>
            <div class="label"><i class="fas fa-exclamation-triangle"></i></div>
        </div>
        <div class="arrow">
            <div class="arrow-arrow">
                <i class="fas fa-chevron-down" style="left: {{arrowPer}}%"></i>
            </div>
        </div>
        <div class="bars">
            <div class="bar-slice bar-slice--left"  [class.bar-slice--active-bad]="limitIndex === 0">
                <div class="marker">
                    <div class="marker__number">{{movement.limits[0]}}</div>
                    <div class="marker__line"></div>
                </div>
            </div>
            <div class="bar-slice bar-slice--center" [class.bar-slice--active-good]="limitIndex === 1">
                <div class="marker">
                    <div class="marker__number">{{movement.limits[1]}}</div>
                    <div class="marker__line"></div>
                </div>
            </div>
            <div class="bar-slice bar-slice--center" [class.bar-slice--active-optimum]="limitIndex === 2" >
                <div class="marker">
                    <div class="marker__number">{{movement.limits[2]}}</div>
                    <div class="marker__line"></div>
                </div>
            </div>
            <div class="bar-slice bar-slice--right" [class.bar-slice--active-bad]="limitIndex === 3" >

            </div>
        </div>
    </div>
    <div *ngIf="movement.id === 'free'" class="bar">
        <div class="labels">
            <!-- <div class="label">Cortedad</div>
            <div class="label">Normalidad</div>
            <div class="label">Hiper mobilidad</div> -->
        </div>
        <div class="arrow">
            <div class="arrow-arrow">
                <i class="fas fa-chevron-down" style="left: {{arrowPer}}%"></i>
            </div>
        </div>
        <div class="bars bars--free">
            <div class="bar-slice bar-slice--left-right">
                <div class="marker">
                    <div class="marker__number">{{movement.limits[0]}}</div>
                    <div class="marker__line"></div>
                </div>
            </div>
        </div>
    </div>
</div>
