import { EditItem } from './edit-items.model';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';

export interface CropItemData {
    x: number;
    y: number;
    w: number;
    h: number;
}

export type CropMode = 's' | 'v' | 'h' | 'f';

export type Direction = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'center';

export class CropEdit extends EditItem {

    type = 'crop';
    data: CropItemData;
    drawing = false;
    dotSize = 30;
    sDotSize = 5;
    lineWidth = 4;
    cropMode: CropMode = 'f';

    verticalAR = 3 / 1.3;
    horizontalAR = 1.5 / 3;
    squareAR = 1;

    // top left X
    get absoluteTLX(): number {
        return this.drawer.relativeToAbsoluteX(this.data.x);
    }

    get absoluteTLY(): number {
        return this.drawer.relativeToAbsoluteY(this.data.y);
    }

    get absoluteTRX(): number {
        return this.drawer.relativeToAbsoluteW(this.data.w) + this.absoluteTLX;
    }

    get absoluteTRY(): number {
        return this.absoluteTLY;
    }

    get absoluteBLX(): number {
        return this.absoluteTLX;
    }

    get absoluteBLY(): number {
        return this.drawer.relativeToAbsoluteH(this.data.h) + this.absoluteTLY;
    }

    get absoluteBRX(): number {
        return this.drawer.relativeToAbsoluteW(this.data.w) + this.absoluteBLX;
    }

    get absoluteBRY(): number {
        return this.absoluteBLY;
    }

    static create(x: number, y: number, w: number, h: number) {
        const data = {
            x,
            y,
            w,
            h
        };
        return new CropEdit(data);
    }

    adjustCropToCropMode(direction: Direction) {
        if (this.cropMode === 'f') {
            return;
        } else if (this.cropMode === 'h') {
            this.adjustCropToRatio(direction, this.horizontalAR);
        } else if (this.cropMode === 's') {
            this.adjustCropToRatio(direction, this.squareAR);
        } else if (this.cropMode === 'v') {
            this.adjustCropToRatio(direction, this.verticalAR);
        }
        this.update();
    }

    adjustCropToRatio(direction: Direction, ratio: number) {
        const currentRatio = this.drawer.relativeToAbsoluteH(this.data.h) / this.drawer.relativeToAbsoluteW(this.data.w);
        if (currentRatio > ratio) {
            this.adjustCropToRatioWidthHeightFirst(direction, ratio, true);
        } else {
            this.adjustCropToRatioWidthHeightFirst(direction, ratio, false);
        }
    }

    adjustCropToRatioWidthHeightFirst(direction: Direction, ratio: number, widthFirst: boolean) {
        if (widthFirst && direction === 'topLeft') {
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
        } else if (!widthFirst && direction === 'topLeft') {
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
        } else if (widthFirst && direction === 'topRight') {
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
        } else if (!widthFirst && direction === 'topRight') {
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
        } else if (widthFirst && direction === 'bottomLeft') {
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
        } else if (!widthFirst && direction === 'bottomLeft') {
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, false);
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, false);
        } else if (widthFirst && direction === 'bottomRight') {
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, true);
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, true);
        } else if (!widthFirst && direction === 'bottomRight') {
            this.adjustCropToRatioGetProportionChangeWidth(direction, ratio, true);
            this.adjustCropToRatioGetProportionChangeHeigh(direction, ratio, true);
        }
    }

    adjustCropToRatioGetProportionChangeWidth(direction: Direction, ratio: number, inverse: boolean) {
        const mustBeWidthAbsolute = this.drawer.relativeToAbsoluteH(this.data.h) / ratio;
        const mustBeWidthRelative = this.drawer.absoluteToRelativeX(mustBeWidthAbsolute);
        let rdx = this.data.w - mustBeWidthRelative;
        if (inverse) {
            rdx = rdx * -1;
        }
        this.resize(direction, rdx, 0);
    }

    adjustCropToRatioGetProportionChangeHeigh(direction: Direction, ratio: number, inverse: boolean) {
        const mustBeHeightAbsolute = this.drawer.relativeToAbsoluteW(this.data.w) * ratio;
        const mustBeHeightRelative = this.drawer.absoluteToRelativeY(mustBeHeightAbsolute);
        let rdy = this.data.h - mustBeHeightRelative;
        if (inverse) {
            rdy = rdy * -1;
        }
        this.resize(direction, 0, rdy);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                // this.data.centerX = this.data.centerX + ( 1 / this.drawer.zoomLevel ) * this.drawer.absoluteToRelativeX(event.dx);
                // this.data.centerY = this.data.centerY + ( 1 / this.drawer.zoomLevel ) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    resize(direction: Direction, rdx: number, rdy: number) {
        if (direction === 'topLeft') {
            this.data.x = this.data.x + rdx;
            this.data.w = this.data.w - rdx;

            this.data.y = this.data.y + rdy;
            this.data.h = this.data.h - rdy;
        } else if (direction === 'topRight') {
            this.data.w = this.data.w + rdx;

            this.data.y = this.data.y + rdy;
            this.data.h = this.data.h - rdy;
        } else if (direction === 'bottomLeft') {
            this.data.x = this.data.x + rdx;
            this.data.w = this.data.w - rdx;

            this.data.h = this.data.h + rdy;
        } else if (direction === 'bottomRight') {
            this.data.w = this.data.w + rdx;
            this.data.h = this.data.h + rdy;
        } else if (direction === 'center') {
            this.data.x = this.data.x + rdx;
            this.data.y = this.data.y + rdy;
        }

        // if (this.data.x < 0) {
        //     this.data.x = 0;
        // }
        // if (this.data.x > 1) {
        //     this.data.x = 1;
        // }
        // if (this.data.y < 0) {
        //     this.data.y = 0;
        // }
        // if (this.data.y > 1) {
        //     this.data.y = 1;
        // }
        // if (this.data.w < 0) {
        //     this.data.w = 0;
        // }
        // if (this.data.w > 1) {
        //     this.data.w = 1;
        // }
        // if (this.data.h < 0) {
        //     this.data.h = 0;
        // }
        // if (this.data.h > 1) {
        //     this.data.h = 1;
        // }
    }

    getActionResize(direction: Direction): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                // this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'topLeft') {
                    this.data.x = this.data.x + this.drawer.absoluteToRelativeX(event.dx);
                    this.data.w = this.data.w - this.drawer.absoluteToRelativeX(event.dx);

                    this.data.y = this.data.y + this.drawer.absoluteToRelativeY(event.dy);
                    this.data.h = this.data.h - this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'topRight') {
                    this.data.w = this.data.w + this.drawer.absoluteToRelativeX(event.dx);

                    this.data.y = this.data.y + this.drawer.absoluteToRelativeY(event.dy);
                    this.data.h = this.data.h - this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'bottomLeft') {
                    this.data.x = this.data.x + this.drawer.absoluteToRelativeX(event.dx);
                    this.data.w = this.data.w - this.drawer.absoluteToRelativeX(event.dx);

                    this.data.h = this.data.h + this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'bottomRight') {
                    this.data.w = this.data.w + this.drawer.absoluteToRelativeX(event.dx);
                    this.data.h = this.data.h + this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'center') {
                    this.data.x = this.data.x + this.drawer.absoluteToRelativeX(event.dx);
                    this.data.y = this.data.y + this.drawer.absoluteToRelativeY(event.dy);
                }

                // if (this.data.x < 0) {
                //     this.data.x = 0;
                // }
                // if (this.data.x > 1) {
                //     this.data.x = 1;
                // }
                // if (this.data.y < 0) {
                //     this.data.y = 0;
                // }
                // if (this.data.y > 1) {
                //     this.data.y = 1;
                // }
                // if (this.data.w < 0) {
                //     this.data.w = 0;
                // }
                // if (this.data.w > 1) {
                //     this.data.w = 1;
                // }
                // if (this.data.h < 0) {
                //     this.data.h = 0;
                // }
                // if (this.data.h > 1) {
                //     this.data.h = 1;
                // }

                this.adjustCropToCropMode(direction);

                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    draw() {

        const boundingRect = this.drawer.svgObject.node.getBoundingClientRect();

        console.log('data', this.data);

        const rect = this.drawer.svgObject.rect(boundingRect.width, boundingRect.height).fill('black').opacity(.8);
        const mask = this.drawer.svgObject.mask();
        const maskWhite = this.drawer.svgObject.rect(boundingRect.width, boundingRect.height).fill('white').opacity(1);
        const maskBlack = this.drawer.svgObject.rect(
            this.drawer.relativeToAbsoluteW(this.data.w), this.drawer.relativeToAbsoluteH(this.data.h))
            .x(this.absoluteTLX)
            .y(this.absoluteTLY)
            .fill('black').opacity(1);
        mask.add(maskWhite);
        mask.add(maskBlack);
        rect.maskWith(mask);

        const center = this.drawer.svgObject.rect(
            this.drawer.relativeToAbsoluteW(this.data.w), this.drawer.relativeToAbsoluteH(this.data.h))
            .x(this.absoluteTLX)
            .y(this.absoluteTLY)
            .fill('black').opacity(0.01);

        const topLeft = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.absoluteTLX, this.absoluteTLY);
        const topRigth = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.absoluteTRX, this.absoluteTRY);

        const bottomLeft = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.absoluteBLX, this.absoluteBLY);
        const bottomRigth = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.absoluteBRX, this.absoluteBRY);

        interact(topLeft.node).draggable(this.getActionResize('topLeft'));
        interact(topRigth.node).draggable(this.getActionResize('topRight'));
        interact(bottomLeft.node).draggable(this.getActionResize('bottomLeft'));
        interact(bottomRigth.node).draggable(this.getActionResize('bottomRight'));
        interact(center.node).draggable(this.getActionResize('center'));

        this.svgElements.push(topLeft);
        this.svgElements.push(topRigth);
        this.svgElements.push(bottomLeft);
        this.svgElements.push(bottomRigth);
        this.svgElements.push(rect);
        this.svgElements.push(center);

        // const bottom = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height));
        // const left = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));
        // const right = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(.6).center(this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));

        // mask.add(this.drawer.svgObject.rect().node.)

        // const topBottom = this.drawer.svgObject.line(
        //     this.drawer.relativeToAbsoluteX(this.data.centerX),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height),
        //     this.drawer.relativeToAbsoluteX(this.data.centerX),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height)).fill(this.color).stroke({color: this.color, width: this.lineWidth});
        // const leftRight = this.drawer.svgObject.line(
        //     this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY),
        //     this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color).stroke({color: this.color, width: this.lineWidth});

        // const centerS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        // const topS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height));
        // const bottomS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height));
        // const leftS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));
        // const rightS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));

        // const center = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        // const top = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height));
        // const bottom = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height));
        // const left = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));
        // const right = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));

        // this.svgElements.push(center);
        // this.svgElements.push(top);
        // this.svgElements.push(bottom);
        // this.svgElements.push(left);
        // this.svgElements.push(right);
        // this.svgElements.push(topBottom);
        // this.svgElements.push(leftRight);
        // this.svgElements.push(centerS);
        // this.svgElements.push(topS);
        // this.svgElements.push(bottomS);
        // this.svgElements.push(leftS);
        // this.svgElements.push(rightS);

        // interact(center.node)
        //     .draggable(this.getActionMove())
        //     .on('tap', (event) => {
        //         this.drawer.selectedEvent(this.index);
        //     });
        // interact(top.node)
        //     .draggable(
        //         this.getActionResize('top')
        //     );
        // interact(bottom.node)
        //     .draggable(
        //         this.getActionResize('bottom')
        //     );
        // interact(left.node)
        //     .draggable(
        //         this.getActionResize('left')
        //     );
        // interact(right.node)
        //     .draggable(
        //         this.getActionResize('right')
        //     );
    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
