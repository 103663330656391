<div class="content h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <ng-container *ngIf="professional !== null; else noProfessional">
        <ng-container
          *ngIf="
            (professionalSubscriptionType$ | async) === 'InteccElite';
            else noEliteSubscription
          "
        >
          <div class="mb-8 flex justify-end" *ngIf="user">
            <ng-container *ngIf="user.clientId">
              <button
                [disabled]="unlinkLoadingState === 'loading'"
                (click)="openUnlinkDialog()"
                class="flex items-center text-intecc2 hover:font-semibold"
                [matTooltip]="
                  'Esto desvinculará tu usuario de tu profesional y tu ficha de cliente'
                "
                [matTooltipPosition]="'left'"
              >
                Desvincular profesional
                <i
                  *ngIf="unlinkLoadingState === 'loading'"
                  class="fas fa-spinner fa-spin ml-2"
                ></i>
                <i
                  *ngIf="unlinkLoadingState === 'idle'"
                  class="fas fa-times ml-2"
                ></i>
              </button>
              <p *ngIf="unlinkLoadingState === 'error'" class="text-red-500">
                Error al desvincular.
              </p>
            </ng-container>
            <ng-container *ngIf="!user.clientId">
              <p class="text-gray-500">
                No estás vinculado a ningún profesional.
              </p>
            </ng-container>
          </div>
          <div class="flex flex-col items-center">
            <!-- Professional logo -->
            <div
              class="relative mb-4 h-44 w-44"
              *ngIf="professionalConfig?.reportsConfig?.logoUrl"
            >
              <img
                class="h-full w-full object-contain"
                [src]="professionalConfig.reportsConfig.logoUrl"
                alt=""
              />
            </div>

            <!-- Professional header box -->
            <div
              class="mb-8 w-full max-w-lg rounded-2xl bg-white px-6 py-4 shadow-md shadow-neutral-200"
            >
              <div class="flex items-center gap-4">
                <i class="fas fa-user-tie text-3xl text-neutral-600"></i>
                <div>
                  <h1 class="text-2xl font-semibold text-neutral-800">
                    Profesional
                  </h1>
                  <p class="text-lg text-neutral-600">
                    {{ professional?.displayName }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Education section -->
            <div
              *ngIf="professionalConfig?.displayEducation"
              class="mb-6 w-full max-w-lg rounded-2xl bg-white px-6 py-4 shadow-md shadow-neutral-200"
            >
              <h2 class="mb-3 text-xl font-semibold text-neutral-800">
                <i class="fas fa-graduation-cap mr-3 text-neutral-600"></i>
                Información
              </h2>
              <p class="whitespace-pre-line text-neutral-600">
                {{ professionalConfig?.education }}
              </p>
            </div>

            <!-- Experience section -->
            <div
              *ngIf="professionalConfig?.displayExperience"
              class="mb-6 w-full max-w-lg rounded-2xl bg-white px-6 py-4 shadow-md shadow-neutral-200"
            >
              <h2 class="mb-3 text-xl font-semibold text-neutral-800">
                <i class="fas fa-briefcase mr-3 text-neutral-600"></i>
                Servicios
              </h2>
              <p class="whitespace-pre-line text-neutral-600">
                {{ professionalConfig?.experience }}
              </p>
            </div>

            <!-- Others section -->
            <div
              *ngIf="professionalConfig?.displayOthers"
              class="mb-6 w-full max-w-lg rounded-2xl bg-white px-6 py-4 shadow-md shadow-neutral-200"
            >
              <h2 class="mb-3 text-xl font-semibold text-neutral-800">
                <i class="fas fa-info-circle mr-3 text-neutral-600"></i>
                Otros datos
              </h2>
              <p class="whitespace-pre-line text-neutral-600">
                {{ professionalConfig?.others }}
              </p>
            </div>

            <!-- Appointments and Payments section -->
            <!-- <div
          *ngIf="
            professionalConfig?.connectLinks?.appointmentsCalendar ||
            professionalConfig?.connectLinks?.paymentsLink
          "
          class="mt-6 w-full max-w-lg rounded-2xl bg-white px-6 py-4 shadow-md shadow-neutral-200"
        >
          <h2 class="mb-4 text-xl font-semibold text-neutral-800">
            <i class="fas fa-calendar-check mr-3 text-neutral-600"></i>
            Gestiones
          </h2> -->

            <!-- Appointments button -->
            <!-- <a
            *ngIf="
              professionalConfig?.connectLinks?.appointmentsCalendar &&
              professionalConfig?.connectLinks?.displayAppointmentsCalendar
            "
            [href]="professionalConfig.connectLinks.appointmentsCalendar"
            target="_blank"
            class="mb-3 flex w-full items-center justify-between rounded-lg bg-blue-100 px-6 py-4 font-semibold text-neutral-800 transition hover:bg-blue-200"
          >
            <span class="flex items-center">
              <i class="fas fa-calendar-alt mr-3 text-xl"></i>
              Reservar cita
            </span>
            <i class="fas fa-external-link-alt text-neutral-600"></i>
          </a> -->

            <!-- Payments button -->
            <!-- <a
            *ngIf="
              professionalConfig?.connectLinks?.paymentsLink &&
              professionalConfig?.connectLinks?.displayPaymentsLink
            "
            [href]="professionalConfig.connectLinks.paymentsLink"
            target="_blank"
            class="flex w-full items-center justify-between rounded-lg bg-blue-100 px-6 py-4 font-semibold text-neutral-800 transition hover:bg-blue-200"
          >
            <span class="flex items-center">
              <i class="fas fa-credit-card mr-3 text-xl"></i>
              Realizar pago
            </span>
            <i class="fas fa-external-link-alt text-neutral-600"></i>
          </a>
        </div> -->

            <!-- Bottom spacing -->
          </div>
        </ng-container>

        <ng-template #noEliteSubscription>
          <app-no-elite-subscription-feedback></app-no-elite-subscription-feedback>
        </ng-template>
      </ng-container>
      <ng-template #noProfessional>
        <div
          class="flex h-full flex-col items-center justify-center text-center"
        >
          <i class="fa-solid fa-user-slash mb-4 text-6xl text-gray-400"></i>
          <div class="text-lg font-semibold text-gray-600">
            Debes unirte a un profesional para ver tus registros de cliente
          </div>
          <!-- <button
            (click)="joinRandomProfessional()"
            class="hover:bg-intecc1 mt-4 rounded-lg bg-intecc2 px-4 py-2 text-white"
          >
            Unirme a un profesional
          </button> -->
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
