import { Component, OnInit, Input } from '@angular/core';
import { FloatingVideoService } from 'src/app/services/floating-video.service';
import { LearnGifs } from 'src/app/static/learn-gifs.static';
import { SagitalInclinometerLearnData } from 'src/app/models/learn/sagital-inclinometer-learn-data.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sagital-inclinometer-learn-container',
  templateUrl: './sagital-inclinometer-learn-container.component.html',
  styleUrls: ['./sagital-inclinometer-learn-container.component.scss']
})
export class SagitalInclinometerLearnContainerComponent implements OnInit {

  LearnGifs = LearnGifs;

  @Input()
  data: SagitalInclinometerLearnData;

  videos: Array<any> = [];

  constructor(private floatingVideoService: FloatingVideoService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.videos = this.data.videos.map( video => {
        return {
            vimeoUrl: this.bypassSecutiry(video.vimeoId),
            vimeoId: video.vimeoId
        };
    } );
  }

  openVideo(vimeoId: string) {
    this.floatingVideoService.showVideo(vimeoId);
  }

  bypassSecutiry(vimeoId: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${vimeoId}`);
  }

}
