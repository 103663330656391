import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { Observable, firstValueFrom, lastValueFrom, timeout } from 'rxjs';

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function isInRange(value: number, range: [number, number]) {
  return value >= range[0] && value <= range[1];
}

export function firebaseTimestampFromDate(date: Date) {
  return Timestamp.fromDate(date);
}

export function isIOS() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i);
}

export const calculateResultBarPercentage = (
  limits: Array<number>,
  value: number,
) => {
  const expandedLimits = [...limits];
  expandedLimits.push(limits[limits.length - 1] + 20);
  expandedLimits.unshift(limits[0] - 20);
  const limitIndex = findLimitIndex(limits, value);
  const limitSizePer = 100 / (limits.length + 1);
  const limitStartPer = limitIndex * limitSizePer;
  const limitEndPer = (limitIndex + 1) * limitSizePer;
  let limitStart = 0;
  let limitEnd = 0;
  if (limitIndex === 0) {
    limitStart = limits[0] - 20;
    limitEnd = limits[0];
  } else if (limitIndex === limits.length) {
    limitStart = limits[limits.length - 1];
    limitEnd = limits[limits.length - 1] + 20;
  } else {
    limitStart = limits[limitIndex - 1];
    limitEnd = limits[limitIndex];
  }
  const limitSize = limitEnd - limitStart;
  const valuePerRel =
    ((value - limitStart) * limitSizePer) / (limitEnd - limitStart);
  let valuePerAbs = limitStartPer + valuePerRel;

  // console.log('limitIndex', limitIndex);
  // console.log('limitSizePer', limitSizePer);
  // console.log('limitStartPer', limitStartPer);
  // console.log('limitEndPer', limitEndPer);
  // console.log('limitStart', limitStart);
  // console.log('limitEnd', limitEnd);
  // console.log('limitSize', limitSize);

  valuePerAbs = valuePerAbs > 100 ? 100 : valuePerAbs;
  valuePerAbs = valuePerAbs < 0 ? 0 : valuePerAbs;

  return valuePerAbs;
};

export const findLimitIndex = (limits: Array<number>, value: number) => {
  for (let i = 0; i < limits.length; i++) {
    const element = limits[i];
    if (value < element) {
      return i;
    }
  }
  return limits.length;
};

export const bytesToMB = (bytes: number) => {
  return bytes / 1024 / 1024;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

export type LoadingState = 'loading' | 'idle' | 'error';

interface ToPromiseOptions {
  useFirst?: boolean; // Whether to use firstValueFrom (true) or lastValueFrom (false)
  timeoutMs?: number; // Optional timeout in milliseconds
  defaultValue?: any; // Optional default value if the Observable completes without emitting
}

export async function safeToPromise<T>(
  source: Observable<T>,
  options: ToPromiseOptions = {
    useFirst: true,
    timeoutMs: 10000,
    defaultValue: null,
  },
): Promise<T> {
  const { useFirst, timeoutMs, defaultValue } = options;

  // Add timeout operator if timeoutMs is specified
  const sourceWithTimeout = source.pipe(timeout(timeoutMs));

  // Use either firstValueFrom or lastValueFrom based on useFirst option
  return useFirst
    ? firstValueFrom(sourceWithTimeout, { defaultValue })
    : lastValueFrom(sourceWithTimeout, { defaultValue });
}
