// clipboard.service.ts
import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private snackbarService: SnackbarService) {}

  copyToClipboard(text: string, successAction?: () => void): void {
    const successFunction = successAction
      ? () => successAction()
      : () =>
          this.snackbarService.info(
            'Texto copiado al portapapeles',
            undefined,
            {
              duration: 2000,
              horizontalPosition: 'start',
            },
          );

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Text copied to clipboard');
          successFunction();
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    } else {
      // Fallback for browsers that do not support the Clipboard API
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        console.log('Text copied to clipboard');
        successFunction();
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textarea);
    }
  }
}
