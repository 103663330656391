import { Test, TestFieldType, TestType } from "./tests.model";

export const MYVAFPROTOCOLDEF_PATH = "myvaf-protocols";

export interface MyVafProtocolDef {
  id?: string;
  name: string;
  description: string;
  fixedImages?: Array<MyVafFixedImage>;
  owner?: string;
  tests: Array<MyVafTestDef>;
  lastLocalId: number;
  lastLocalImageId: number;
  createdAt?: any;
  updatedAt?: Date;
  copiedFromId?: string; // if this protocol was copied from another proffesional
}

export interface MyVafTestDef {
  localId?: number;
  name?: string;
  description?: string;
  fields?: Array<MyVafFieldDef>;
  tableRowFields?: Array<MyVafFieldDef>;
  tableFirstColTitle?: string;
  images?: Array<MyVafImageDef>;
  fixedImages?: Array<MyVafFixedImage>;
  lastLocalId?: number;
  lastLocalImageId?: number;
  mode?: "fields" | "table";
  tableMode?: "x" | "xy";
}

export interface MyVafFieldDef {
  localId: number;
  title: string;
  type: TestFieldType;
  leftRight: boolean;
  options: Array<string>;
  optionsString: string;
}

export interface MyVafImageDef {
  localId: number;
  key: string;
  name: string;
  position: "first" | "second";
}

export interface MyVafFixedImage {
  localId: number;
  key: string;
  title: string;
  downloadUrl: string;
  position: "first" | "second";
  link: string;
}

export interface MyVafProtocolTest extends Test {
  protocolDefId: string;
  links?: Array<Array<string>>;
  // testLocalId-fieldLocalId: string
  // for table mode
  // testLocalId-fieldLocalId-index: string
  // testLocalId-rows: number
}

export const createEmptyMyVafProtocolTest = () => {
  const result: MyVafProtocolTest = {
    type: "my-vaf" as TestType,
    owner: "",
    client: "",
    protocolDefId: undefined,
  };
  return result;
};

export const getProtocolInitials = (protocol: MyVafProtocolDef) => {
  const name = protocol.name.toUpperCase();
  if (!name || name === "") {
    return "--";
  }
  const splittedName = name.split(" ");
  if (splittedName.length > 1) {
    return `${splittedName[0][0]}${splittedName[1][0]}`;
  } else {
    return `${name[0]}${name.length > 1 ? name[1] : name[0]}`;
  }
};

export const optionsStringToOptionsArray = (protocol: MyVafProtocolDef) => {
  if (!protocol.tests) {
    return;
  }
  protocol.tests.forEach((test) => {
    if (!test.fields) {
      return;
    }
    test.fields
      .filter((f) => f.type === "select")
      .forEach((f) => {
        if (!f.optionsString) {
          f.options = [];
          return;
        }
        let splitted = f.optionsString.split(",");
        splitted = splitted.filter((s) => !!s);
        f.options = splitted;
      });
  });
};

export const calculateImageKey = (
  protocolId: string,
  testLocalId: number,
  imageLocalId: number
) => {
  return `myvaf-${protocolId}-${testLocalId}-${imageLocalId}`;
};

export const getIdFromImageKey = (
  what: "protocolId" | "testLocalId" | "imageLocalId",
  imageKey: string
) => {
  const splitted = imageKey.split("-");
  const protocolId = imageKey[1];
  const testLocalId = imageKey[2];
  const imageLocalId = imageKey[3];
  if (what === "protocolId") {
    return protocolId;
  }
  if (what === "testLocalId") {
    return testLocalId;
  }
  if (what === "imageLocalId") {
    return imageLocalId;
  }
  return "🤔";
};

export const getCommentsMyVafTest = (testDef: MyVafTestDef, test) => {
  const result = test[`comments-${testDef.localId}`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const setCommentsMyVafTest = (testDef: MyVafTestDef, test, value) => {
  test[`comments-${testDef.localId}`] = value;
};

export const getTipsMyVaf = (test) => {
  const result = test[`tips`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const setTipsMyVaf = (test, value) => {
  test[`tips`] = value;
};

export const MyVafGetValue = (
  test: any,
  testLocalId: number,
  fieldLocalId: number
) => {
  if (test[`${testLocalId}-${fieldLocalId}`] === undefined) {
    return -1;
  }
  const result = test[`${testLocalId}-${fieldLocalId}`];
  return result;
};

export const MyVafGetValueLF = (
  test: any,
  testLocalId: number,
  fieldLocalId: number,
  right: boolean
) => {
  if (
    test[`${testLocalId}-${fieldLocalId}--${right ? "r" : "l"}`] === undefined
  ) {
    return -1;
  }
  const result = test[`${testLocalId}-${fieldLocalId}--${right ? "r" : "l"}`];
  return result;
};

export const MyVafGetValueOption = (
  test: any,
  testLocalId: number,
  fieldLocalId: number,
  isOption: boolean,
  options: Array<string>
) => {
  const index = MyVafGetValue(test, testLocalId, fieldLocalId);
  if (!isOption) {
    if (index === -1) {
      return "";
    } else {
      return index;
    }
  }
  if (index < 0 || index >= options.length) {
    return "-";
  }
  return options[index];
};

export const MyVafGetValueOptionLR = (
  test: any,
  testLocalId: number,
  fieldLocalId: number,
  isOption: boolean,
  options: Array<string>,
  right: boolean
) => {
  const index = MyVafGetValueLF(test, testLocalId, fieldLocalId, right);
  if (!isOption) {
    if (index === -1) {
      return "";
    } else {
      return index;
    }
  }
  if (index < 0 || index >= options.length) {
    return "-";
  }
  return options[index];
};

export const MyVafGetValueTable = (
  test: any,
  testLocalId: number,
  fieldLocalId: number,
  index: number
) => {
  if (test[`${testLocalId}-${fieldLocalId}-${index}`] === undefined) {
    return "";
  }
  const result = test[`${testLocalId}-${fieldLocalId}-${index}`];
  return result;
};

export const MyVafGetValueTableOption = (
  test: any,
  testLocalId: number,
  fieldLocalId: number,
  index: number,
  options: Array<string>,
  optionMode: boolean
) => {
  // THIS CODE IS NOT TESTED

  const index2 = MyVafGetValueTable(test, testLocalId, fieldLocalId, index);

  if (!optionMode) {
    return index2;
  }

  if (index2 < 0 || index2 >= options.length) {
    return "-";
  }
  return options[index2];
};

export const MyVafGetTableNumberOfRows = (test: any, testLocalId: number) => {
  let numberOfRows: number = test[`${testLocalId}-rows`];
  // console.log('number of row', numberOfRows);

  if (!numberOfRows) {
    numberOfRows = 0;
  }

  return numberOfRows;
};

export const createMatrix = (x: number, y: number) => {
  const result: Array<Array<string>> = [];
  for (let index = 0; index < y; index++) {
    const line: Array<string> = [];
    for (let index2 = 0; index2 < x; index2++) {
      line.push("");
    }
    result.push(line);
  }
  return result;
};
