import { Component, OnInit, HostListener } from '@angular/core';
import { NewInclinometerService } from 'src/app/services/new-inclinometer.service';

@Component({
  selector: 'app-inclinometer-reader',
  templateUrl: './inclinometer-reader.component.html',
  styleUrls: ['./inclinometer-reader.component.scss'],
})
export class InclinometerReaderComponent implements OnInit {
  constructor(private inclinometer: NewInclinometerService) {}

  ngOnInit(): void {
    console.log('hello from inclinometer-reader');
  }

  @HostListener('window:devicemotion', ['$event'])
  onDeviceMotion(event: any) {
    this.inclinometer.onNewDeviceMotionEventData(event);
  }

  // this can be emulated by chrome, but devicemotion can't
  // @HostListener('window:deviceorientation', ['$event'])
  // onDeviceOrientation(event: any) {
  //     console.log('deviceorientation', event);
  //     this.inclinometer.onNewDeviceMotionEventData(event);
  // }
}
