import { Injectable } from '@angular/core';
import { Client } from '../models/client.model';
import { FilterSearchValue } from '../models/filter-search-value.model';

@Injectable({
    providedIn: 'root'
})
export class FilterSearchService {

    constructor() { }

    filter(clients: Client[], filterValue: FilterSearchValue): Client[] {
        // console.log(filterValue);
        const searchInputText = filterValue.searchInputText;
        const groupId = filterValue.groupId;
        const fromDate = filterValue.fromDate;
        const toDate = filterValue.toDate;
        let filteredClients = clients;
        if (!!searchInputText) {
            filteredClients = this.softSearch(searchInputText, clients);
        }
        if (groupId !== undefined) {
            filteredClients = filteredClients.filter(c => c.tagId === groupId);
        }
        if (fromDate) {
            filteredClients = filteredClients.filter(c => c.createdAt.toDate() > fromDate );
        }
        if (toDate) {
            filteredClients = filteredClients.filter(c => c.createdAt.toDate() < toDate );
        }
        return filteredClients;
    }

    softSearch(value: string, clients: Client[]) {
        const softSearchRegEx = this.generateSoftSearchRegEx(value);
        return clients.filter(c => {
            const searchResult = (c.name + c.surname).search(softSearchRegEx);
            return searchResult >= 0;
        });
    }

    generateSoftSearchRegEx(value: string) {
        let regExString = '(';
        const valueChars = value.split('');
        for (let index = 0; index < valueChars.length; index++) {
            const element = valueChars[index];
            regExString = regExString + element;
            if (index < valueChars.length - 1) {
                regExString = regExString + '.*';
            }
        }
        regExString = regExString + ')';
        const regExp = new RegExp(regExString, 'gi');
        return regExp;
    }

}
