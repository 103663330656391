<button
  (click)="navigationService.goToClient(client.id)"
  class="client-back-button"
>
  <div
    class="client-back-button__image hidden"
    [class.client-back-button__image--myvaf]="myVafMode"
  >
    {{ clients.getThumbnailText(client) }}
  </div>
  <p class="client-back-button__name-surname">
    <span class="client-back-button__name">{{ client.name }} </span
    ><span class="client-back-button__surname">{{ client.surname }} </span>
  </p>
</button>
