import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { LearnService } from 'src/app/services/learn.service';
import { TestService } from 'src/app/services/test.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import { FootArchitecture, FootTest, getFootFieldInfo } from 'src/shared/foot.outside';
import { isLeftRight, LeftRigthValue } from 'src/shared/test.outside';

@Component({
    selector: 'app-foot',
    templateUrl: './foot.component.html',
    styleUrls: ['./foot.component.scss']
})
export class FootComponent {

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _test: FootTest;
    get test() {
        return this._test;
    }
    @Input() set test(value: FootTest) {
        this._test = value;
        this.init();
    }
    @Input() client: Client;

    FootArchitecture = FootArchitecture;

    getFootFieldInfo = getFootFieldInfo;

    constructor(private tests: TestService, private learn: LearnService) { }

    init() {
        console.log(this.test);

    }

    onDateChanged(date: any) {
        this.test.createdAt = firebaseTimestampFromDate(date.value);
        this.updateTest();
    }

    updateTest() {
        const { ...test } = this.test;
        this.tests.updateTest(test);
    }

    onImageChanged() {
        this.updateTest();
    }

    getValue(field) {

        if (!this.test.results) {
            return -1;
        }
        const result = this.test.results[field];
        return result;
    }

    getValueLF(field, rigth: boolean) {
        if (!this.test.results) {
            return -1;
        }
        const result = this.test.results[field] as LeftRigthValue;
        try {
            if (!rigth) {
                return result.left;
            } else {
                return result.right;
            }
        } catch (error) {
            return -1;
        }

    }

    getValueText(field) {

        if (!this.test.results) {
            return '';
        }
        const result = this.test.results[field];
        if (!result) {
            return '';
        }
        return result;
    }

    getValueTextLF(field, rigth: boolean) {
        if (!this.test.results) {
            return '';
        }
        const result = this.test.results[field] as LeftRigthValue;
        try {
            if (!rigth) {
                return result.left;
            } else {
                return result.right;
            }
        } catch (error) {
            return '';
        }

    }

    setSectionStatus(sectionId: string, value: boolean) {
        this.test[sectionId] = value;
        this.updateTest();
    }

    setValue(field, value) {
        if (!this.test.results) {
            this.test.results = {};
        }
        this.test.results[field] = value;
        this.updateTest();
    }

    openLearn(id: string) {
        this.learn.openPanel(id);
    }

    setValueLF(field, value, right: boolean) {
        if (!this.test.results) {
            this.test.results = {};
        }
        if (typeof this.test.results[field] === 'number' || !this.test.results[field] ) {
            this.test.results[field] = {left: -1, right: -1} as LeftRigthValue;
        }
        if (!right) {
            this.test.results[field].left = value;
        } else {
            this.test.results[field].right = value;
        }
        this.updateTest();
    }

    setValueText(field, value) {
        if (!this.test.results) {
            this.test.results = {};
        }
        this.test.results[field] = value;
        this.updateTest();
    }

    setValueTextLF(field, value, right: boolean) {
        if (!this.test.results) {
            this.test.results = {};
        }
        if (typeof this.test.results[field] === 'number' || !this.test.results[field] ) {
            this.test.results[field] = {left: '', right: ''} as LeftRigthValue;
        }
        if (!right) {
            this.test.results[field].left = value;
        } else {
            this.test.results[field].right = value;
        }
        this.updateTest();
    }

    getComments(propName: string) {
        return this.test[propName];
    }

    setComments(propName: string, value: string) {
        this.test[propName] = value;
        this.updateTest();
    }

    setLinks(value: any) {
        this.test.links = value;
        this.updateTest();
    }
    getLinks() {
        return this.test.links;
    }

    onCommentsChanged(propName: string, event) {
        const value = event.target.value;
        this.setComments(propName, value);
        this.updateTest();
    }

}
