<div
  class="relative grid grid-cols-2 rounded-xl font-bold {{
    isModal ? 'bg-black/20' : 'bg-blue-100'
  }}"
>
  <div
    class="absolute h-full w-1/2 rounded-xl bg-inteccDark left-0 transition-all duration-500 {{
      selectedButtonCode === buttonBCode ? 'translate-x-full' : ''
    }}"
  ></div>
  <button
    (click)="selectButton(buttonACode)"
    class="relative px-4 py-3 rounded-lg text-center transition-all {{
      selectedButtonCode === buttonACode ? 'text-white' : ''
    }}"
  >
    {{ buttonATitle }}
  </button>
  <button
    (click)="selectButton(buttonBCode)"
    class="relative px-4 py-3 rounded-lg text-center transition-all {{
      selectedButtonCode === buttonBCode ? 'text-white' : ''
    }}"
  >
    {{ buttonBTitle }}
  </button>
</div>
