import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  TrainingSessionSection,
  TRAININGSESSIONSECTIONS_PATH,
} from '../models/training-session-section.model';
import { FirestoreService } from './firestore.service';
import {
  CLIENT_TRAINING_SESSIONS_PATH,
  ClientTrainingSession,
  createEmptyClientTrainingSession,
  TrainingSessionProtocolDef,
  TRAININGSESSIONPROTOCOLDEF_PATH,
} from 'src/shared/training-session-definition.outside';
import { Observable } from 'rxjs';
import { ClientsService } from './clients.service';
import { AuthService } from './auth.service';
import { SentryService } from './sentry.service';
import { safeToPromise, sleep } from '../utils/utils';
import { SnackbarService } from './snackbar.service';
import { Client } from '../models/client.model';
import { FunctionsService } from './functions.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrainingSessionsService {
  currentClientTrainingSessions$: Observable<ClientTrainingSession[]>;
  currentClientTrainingSessionsCount = 0;

  constructor(
    private fs: FirestoreService,
    private clients: ClientsService,
    private auth: AuthService,
    private sentry: SentryService,
    private snackbarService: SnackbarService,
    private functionsService: FunctionsService,
  ) {
    this.init();
  }

  init() {
    this.currentClientTrainingSessions$ = this.clients.currentClientId$.pipe(
      distinctUntilChanged(),
      filter((val) => val !== null),
      switchMap((id) => this.auth.user$.pipe(map((user) => ({ user, id })))),
      switchMap(({ user, id }) => {
        if (!user) {
          return of([]); // Return empty array if user is not defined
        }
        return this.fs.colWithIds$<ClientTrainingSession>(
          `${CLIENT_TRAINING_SESSIONS_PATH}`,
          (ref) =>
            ref
              .where('client', '==', id)
              .where('owner', '==', user.uid)
              // .where('type', 'in', ['my-vaf'])
              .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        this.currentClientTrainingSessionsCount = data.length;
      }),
      tap((data) => this.sentry.logFirestoreRead('Tests', data.length)),
      shareReplay(1),
    );
  }

  async createTrainingSessionProtocol(owner: string) {
    const trainingSessionProtocol: TrainingSessionProtocolDef = {
      name: '',
      description: '',
      lastLocalId: 0,
      lastLocalImageId: 0,
      sections: [
        {
          name: 'Sección 1',
          localId: 0,
          fields: [
            {
              localId: 0,
              title: 'Volumen',
              leftRight: false,
              options: [],
              type: 'text',
              optionsString: '',
            },
            {
              localId: 1,
              title: 'Intensidad',
              leftRight: false,
              options: [],
              type: 'text',
              optionsString: '',
            },
            {
              localId: 2,
              title: 'Densidad',
              leftRight: false,
              options: [],
              type: 'text',
              optionsString: '',
            },
          ],
          tableFirstColTitle: 'Ejercicios',
          mode: 'table',
          tableMode: 'xy',
          lastLocalId: 2,
        },
      ],
      owner,
    };
    const result = await this.fs.add(
      `${TRAININGSESSIONPROTOCOLDEF_PATH}`,
      trainingSessionProtocol,
    );
    return result.id;
  }

  async getTrainingSessionProtocol(id: string) {
    const doc = await safeToPromise(
      this.fs.doc(`${TRAININGSESSIONPROTOCOLDEF_PATH}/${id}`).get(),
    );
    if (doc.exists) {
      return { ...(doc.data() as any), id } as TrainingSessionProtocolDef;
    } else {
      return null;
    }
  }

  getTrainingSessionProtocols$(owner: string) {
    return this.fs
      .colWithIds$<TrainingSessionProtocolDef>(
        `${TRAININGSESSIONPROTOCOLDEF_PATH}`,
        (ref) => ref.where('owner', '==', owner),
      )
      .pipe(
        map((docs) => {
          docs.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          return docs;
        }),
      );
  }

  async getSections(owner: string) {
    const docs: TrainingSessionSection[] = await safeToPromise(
      this.fs
        .colWithIds$<TrainingSessionSection>(
          `${TRAININGSESSIONSECTIONS_PATH}`,
          (ref) => ref.where('owner', '==', owner),
        )
        .pipe(take(1)),
    );
    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.fs.add(`${TRAININGSESSIONSECTIONS_PATH}`, section);
      section = await this.getSections(owner);
    }
    return section;
  }

  async updateSections(section: TrainingSessionSection) {
    await this.fs.update(
      `${TRAININGSESSIONSECTIONS_PATH}/${section.id}`,
      section,
    );
    return true;
  }

  async createSections(owner: string) {}

  async updateTrainingSessionProtocol(
    trainingSessionProtocol: TrainingSessionProtocolDef,
  ) {
    await this.fs.update(
      `${TRAININGSESSIONPROTOCOLDEF_PATH}/${trainingSessionProtocol.id}`,
      trainingSessionProtocol,
    );
    return true;
  }

  getClientTrainingSessionByProtocolDefId$(
    trainingSessionProtocolDefId: string,
    ownerId: string,
  ) {
    return this.fs
      .colWithIds$<ClientTrainingSession>(
        `${CLIENT_TRAINING_SESSIONS_PATH}`,
        (ref) => ref.where('owner', '==', ownerId),
      )
      .pipe(
        map((tests: ClientTrainingSession[]) => {
          return tests.filter(
            (clientTrainingSession) =>
              clientTrainingSession.trainingSessionProtocolDefId ===
              trainingSessionProtocolDefId,
          );
        }),
      );
  }

  async deleteTrainingSessionProtocol(
    trainingSessionProtocol: TrainingSessionProtocolDef,
  ) {
    await this.fs.delete(
      `${TRAININGSESSIONPROTOCOLDEF_PATH}/${trainingSessionProtocol.id}`,
    );
    return true;
  }

  async duplicateTrainingSessionProtocol(
    trainingSessionProtocol: TrainingSessionProtocolDef,
  ) {
    const newTrainingSessionProtocol: TrainingSessionProtocolDef = {
      ...trainingSessionProtocol,
    };
    delete newTrainingSessionProtocol.id;
    newTrainingSessionProtocol.name =
      newTrainingSessionProtocol.name + ' - Copia';
    const result = await this.fs.add(
      `${TRAININGSESSIONPROTOCOLDEF_PATH}`,
      newTrainingSessionProtocol,
    );
    return result.id;
  }

  async createClientTrainingSession(
    clientId: string,
    trainingSessionProtocolDef: TrainingSessionProtocolDef,
  ) {
    const user = await this.auth.getUser();
    const clientTrainingSession = createEmptyClientTrainingSession();
    clientTrainingSession.owner = user.uid;
    clientTrainingSession.client = clientId;
    clientTrainingSession.trainingSessionProtocolDefId =
      trainingSessionProtocolDef.id;
    await sleep(500);
    const result = await this.fs.add(
      `${CLIENT_TRAINING_SESSIONS_PATH}`,
      clientTrainingSession,
    );
    return result;
  }

  async deleteClientTrainingSessions(clientTrainingSessionsIds: Array<string>) {
    for (let index = 0; index < clientTrainingSessionsIds.length; index++) {
      await this.fs
        .doc(
          `${CLIENT_TRAINING_SESSIONS_PATH}/${clientTrainingSessionsIds[index]}`,
        )
        .delete();
      // this.sentry.logFirestoreWrite('CLIENTS', 1);
    }
  }

  getClientTrainingSession$(testId: string) {
    return this.fs
      .doc$<ClientTrainingSession>(`${CLIENT_TRAINING_SESSIONS_PATH}/${testId}`)
      .pipe(
        map((clientTrainingSession) => ({
          ...clientTrainingSession,
          id: testId,
        })),
        tap((clientTrainingSession) => this.sentry.logFirestoreRead('tests')),
      );
  }

  getClientTrainingSession(testId: string) {
    return this.fs.getDocById<ClientTrainingSession>(
      `${CLIENT_TRAINING_SESSIONS_PATH}/${testId}`,
    );
  }

  async updateClientTrainingSession(
    clientTrainingSession: ClientTrainingSession,
  ) {
    await this.fs.update<ClientTrainingSession>(
      `${CLIENT_TRAINING_SESSIONS_PATH}/${clientTrainingSession.id}`,
      clientTrainingSession,
    );
  }

  getClientTrainingSessions$(clientId: string) {
    return this.fs.colWithIds$<ClientTrainingSession>(
      `${CLIENT_TRAINING_SESSIONS_PATH}`,
      (ref) => ref.where('client', '==', clientId),
    );
  }

  getClientTrainingSessionShareUrl(
    clientTrainingSession: ClientTrainingSession,
  ) {
    return `${window.location.origin}/copy-training-session/${clientTrainingSession.id}`;
  }

  async createClientTrainingSessionFromSharedUrl(
    sessionShareUrl: string,
    client: Client,
  ) {
    try {
      if (!sessionShareUrl) {
        return this.snackbarService.error('Introduce una URL');
      }
      const currentUrl = new URL(window.location.href);
      const currentOrigin = currentUrl.origin;
      let sharedUrl;
      try {
        sharedUrl = new URL(sessionShareUrl);
      } catch (error) {
        return this.snackbarService.error('La URL compartida no es válida');
      }

      const sharedUrlOrigin = sharedUrl.origin;
      if (currentOrigin !== sharedUrlOrigin) {
        return this.snackbarService.error(
          'El origen de la URL no coincide con el origen de esta página',
        );
      }

      const sharedUrlPath = sharedUrl.pathname;
      // use regex to validate the url and extract the client-training-session id
      const regex = /\/copy-training-session\/([a-zA-Z0-9]+)/;
      const match = sharedUrlPath.match(regex);
      if (!match) {
        return this.snackbarService.error('La URL compartida no es válida');
      }
      const clientTrainingSessionId = match[1];
      if (!client) {
        return this.snackbarService.error('No hay un cliente seleccionado');
      }
      const response = await this.functionsService.call(
        'copyTrainingSessionCallable',
        {
          clientTrainingSessionId,
          destinationClientId: client.id,
        },
        { timeout: 1000 * 60 * 4 },
      );
      this.snackbarService.success('Se ha creado la sesión de entrenamiento');
      return response; // id of the new client training session
    } catch (error) {
      console.error(error);
      this.snackbarService.error(
        'Ha ocurrido un error al intentar crear la sesión de entrenamiento',
      );
    }
  }
}
