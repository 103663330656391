import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  private base(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this._snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'start',
      ...config,
    });
  }

  open(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this.base(message, action, config);
  }

  success(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this.base(message, action, {
      ...config,
      panelClass: ['snackbar-success', ...(config?.panelClass || [])],
    });
  }

  error(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this.base(message, action, {
      ...config,
      panelClass: ['snackbar-error', ...(config?.panelClass || [])],
    });
  }

  alert(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this.base(message, action, {
      ...config,
      panelClass: ['snackbar-alert', ...(config?.panelClass || [])],
    });
  }

  info(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<any>,
  ): void {
    this.base(message, action, {
      ...config,
      panelClass: ['snackbar-info', ...(config?.panelClass || [])],
    });
  }
}
