export const CLIENTS_PATH = "clients";

export interface Client {
  id: string;
  userId?: string;
  owner?: string;
  name: string;
  surname: string;
  dni?: string;
  email?: string;
  phone?: string;
  birthDate?: any;
  job?: string;
  createdAt?: Date | any;
  tagId?: number;
  sharedFolderUrl?: string;

  // this field should not be saved but computed by the client component
  isLinked?: boolean;
}
