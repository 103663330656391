import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Modal } from 'src/app/models/modal.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-cancel-sub-modal',
  templateUrl: './cancel-sub-modal.component.html',
  styleUrls: ['./cancel-sub-modal.component.scss']
})
export class CancelSubModalComponent implements Modal {

    onClose = new Subject<any>();
    @Input()
    data: any;

    canceling = false;

    historyBackClose() {
        this.onClose.next('');
    }

    close() {
        history.back();
    }

    constructor(public authService: AuthService, private subscriptionService: SubscriptionsService) { }

    async cancelCurrentSub() {
        this.canceling = true;
        await this.subscriptionService.cancelCurrentSubscription();
        await this.authService.signOut();
        this.canceling = false;
        this.close();
    }

}
