<!-- <markdown [data]="data.markdown" (ready)="onReady()"></markdown> -->
<div *ngIf="data.links" class="">
    <div *ngFor="let link of data.links" class="flex">
        <i class="far fa-hand-point-right"></i>
        <a href="{{link.url}}">{{link.name}}</a>
    </div>
</div>
<div class="md-content" [innerHTML]="data.markdown| markdown"></div>
<div class="max-w-5xl mx-auto">
    <div *ngIf="data.videos" class="section section--3 mt-5">
        <!-- <div class="section__heading">
            <div class="section__icon section__icon--small">
                <i class="fas fa-graduation-cap"></i>
            </div>
            <div class="section__title">Videos</div>
        </div> -->
        <div class="section__content">
            <div class="video-list">
                <!-- <button *ngFor="let video of data.videos" (click)="openVideo(video.vimeoId)"
                    class="video-list__item video-button">
                    <div class="video-button__icon"><i class="far fa-play-circle"></i></div>
                    <div class="video-button__text">{{video.name}}</div>
                </button> -->
                <!-- <button *ngFor="let video of data.videos" (click)="openVideo(video.vimeoId)" class="video-list__item">
                    <img class="video-list__item__img" src="{{video.vimeoImgUrl}}" alt="Video">
    
                </button> -->
                <button *ngFor="let video of data.videos" (click)="openVideo(video.vimeoId)" class="video-list__item">
                    
                    <div *ngIf="video.vimeoId!==null" class="video-container">
                        <div class='embed-container'><iframe [src]="bypassSecutiry(video.vimeoId)" width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>