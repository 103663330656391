<div
  class="flex flex-col items-center justify-center space-y-4 p-6 text-center"
>
  <i class="fas fa-exclamation-triangle text-5xl text-red-500"></i>
  <p class="text-xl font-semibold text-red-600">
    {{ message }}
  </p>
  <ng-container *ngIf="!minimal">
    <p class="text-gray-600">Por favor, inténtelo de nuevo más tarde.</p>
    <!-- <p class="text-gray-600">
      Si el problema persiste, por favor, ponte en contacto con nosotros a
      través de
      <a
        target="_blank"
        href="mailto:aplicaciones@intecc.org"
        class="mt-1 text-center text-intecc2"
        ><span>aplicaciones&#64;intecc.org</span></a
      >
    </p> -->
  </ng-container>
</div>
