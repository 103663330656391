<div class="relative">
  <div class="flex items-center">
    <input
      #inputElement
      type="text"
      [value]="valueToRender"
      [disabled]="disabled"
      [readonly]="readonly"
      (keydown)="handleKeyDown($event)"
      (focus)="handleFocus()"
      (blur)="handleBlur($event)"
      (click)="$event.preventDefault(); changeCursorPosition(0)"
      class="{{ customClass }}"
      autocomplete="off"
    />
    <div
      *ngIf="showArrows && !readonly && !disabled"
      class="absolute right-1 flex flex-col -space-y-1"
    >
      <button
        (click)="adjustTime(true)"
        class="p-0.5 pb-0 text-xs text-gray-500 hover:text-gray-700"
      >
        <i class="fas fa-caret-up"></i>
      </button>
      <button
        (click)="adjustTime(false)"
        class="p-0.5 pt-0 text-xs text-gray-500 hover:text-gray-700"
      >
        <i class="fas fa-caret-down"></i>
      </button>
    </div>
  </div>
</div>
