import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import {
  MyVafProtocolDef,
  MYVAFPROTOCOLDEF_PATH,
} from 'src/shared/myvaf.outside';
import {
  MyVafSection,
  MYVAFSECTIONS_PATH,
} from '../models/my-vaf-section.model';
import { FirestoreService } from './firestore.service';
import { safeToPromise } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class MyvafService {
  constructor(private fs: FirestoreService) {}

  async createProtocol(owner: string) {
    const protocol: MyVafProtocolDef = {
      name: '',
      description: '',
      lastLocalId: 0,
      lastLocalImageId: 0,
      tests: [{ name: 'Test 1', localId: 0, mode: 'fields' }],
      owner,
    };
    const result = await this.fs.add(`${MYVAFPROTOCOLDEF_PATH}`, protocol);
    return result.id;
  }

  async getProtocol(id: string) {
    const doc = await safeToPromise(
      this.fs.doc(`${MYVAFPROTOCOLDEF_PATH}/${id}`).get().pipe(take(1)),
    );
    if (doc.exists) {
      return { ...(doc.data() as any), id } as MyVafProtocolDef;
    } else {
      return null;
    }
  }

  getProtocols$(owner: string) {
    return this.fs
      .colWithIds$<MyVafProtocolDef>(`${MYVAFPROTOCOLDEF_PATH}`, (ref) =>
        ref.where('owner', '==', owner),
      )
      .pipe(
        map((docs) => {
          docs.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          return docs;
        }),
      );
  }

  async getSections(owner: string) {
    const docs: MyVafSection[] = await safeToPromise(
      this.fs
        .colWithIds$<MyVafSection>(`${MYVAFSECTIONS_PATH}`, (ref) =>
          ref.where('owner', '==', owner),
        )
        .pipe(take(1)),
    );
    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.fs.add(`${MYVAFSECTIONS_PATH}`, section);
      section = await this.getSections(owner);
    }
    return section;
  }

  async updateSections(section: MyVafSection) {
    await this.fs.update(`${MYVAFSECTIONS_PATH}/${section.id}`, section);
    return true;
  }

  async createSections(owner: string) {}

  async updateProtocol(protocol: MyVafProtocolDef) {
    await this.fs.update(`${MYVAFPROTOCOLDEF_PATH}/${protocol.id}`, protocol);
    return true;
  }

  async deleteProtocol(protocol: MyVafProtocolDef) {
    await this.fs.delete(`${MYVAFPROTOCOLDEF_PATH}/${protocol.id}`);
    return true;
  }

  async duplicateProtocol(protocol: MyVafProtocolDef) {
    const newProtocol: MyVafProtocolDef = { ...protocol };
    delete newProtocol.id;
    newProtocol.name = newProtocol.name + ' - Copia';
    const result = await this.fs.add(`${MYVAFPROTOCOLDEF_PATH}`, newProtocol);
    console.log('dupe id', result.id);
    return result.id;
  }
}
