import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { RomTest, RomMovement, RomMovements, RomResult, setResult, RomMovementGifs, RomResultLevel } from 'src/shared/rom-test.model';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { LiveInclinometerViewerComponent } from '../live-inclinometer-viewer/live-inclinometer-viewer.component';
import { ResultBarComponent } from '../result-bar/result-bar.component';

@Component({
    selector: 'app-rom-inclinometer',
    templateUrl: './rom-inclinometer.component.html',
    styleUrls: ['./rom-inclinometer.component.scss']
})
export class RomInclinometerComponent {

    test: RomTest;
    movementId: string;
    currentResult: number;
    isOpen = false;
    resultChanged = false;
    RomMovements = RomMovements;
    gifPath: string;
    hasMeasureError: boolean;

    @Output()
    closed = new EventEmitter<boolean>();

    @ViewChild(LiveInclinometerViewerComponent)
    liveInclinometerViewerComponent: LiveInclinometerViewerComponent;

    @ViewChild(ResultBarComponent)
    resultBar: ResultBarComponent;

    isGifOpen = false;

    constructor(private overrideBackButton: OverrideBackButtonService) { }

    open(test: RomTest, movementId: string) {
        this.test = test;
        this.movementId = movementId;
        this.isOpen = true;
        this.overrideBackButton.override(() => {
            this.isOpen = false;
            this.closed.next(this.resultChanged);
        });
        this.currentResult = this.getResult();
        this.resultChanged = false;
        this.liveInclinometerViewerComponent.refresh();
        this.gifPath = RomMovementGifs[movementId];
        this.updateHasMeasureError();
        // this.resultBar?.updateVisualState();
    }

    getResult() {
        if (!!this.test.results[this.movementId]) {
            return this.test.results[this.movementId].value;
        } else {
            return null;
        }
    }

    setResult(value: number) {
        // if (!!this.test[this.movementId]) {
        //     this.test[this.movementId].value = value;
        // } else {
        //     const result: RomResult = { value };
        //     this.test[this.movementId] = result;
        // }
        setResult(this.test, this.movementId, value);
    }

    close() {
        history.back();
    }

    onBackdropClick(event) {
        if (event.target !== event.currentTarget) {
            return;
        }
        this.close();
    }

    onResultChanged(result) {
        this.currentResult = result;
        this.resultChanged = true;
        this.setResult(result);
        this.updateHasMeasureError();
    }

    showGif() {
        this.isGifOpen = true;
    }

    hideGif() {
        this.isGifOpen = false;
    }

    updateHasMeasureError() {
        this.hasMeasureError = this.calculateHasMeasureError();
    }

    calculateHasMeasureError() {
        if (!this.test) {
            return false;
        }
        if (!this.test.results[this.movementId]) {
            return false;
        }
        return this.test.results[this.movementId].resultLevel === RomResultLevel.excessive;
    }

}
