import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CustomBrandingService } from 'src/app/services/custom-branding.service';
import { AppConfig, AppName } from 'src/config/app.config';
@Component({
  selector: 'app-professional-login',
  templateUrl: './professional-login.component.html',
})
export class ProfessionalLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;

  appName: AppName = AppConfig.app;

  loginWorking = false;

  currentBrand;

  constructor(
    public authService: AuthService,
    private customBranding: CustomBrandingService,
  ) {}

  ngOnInit() {
    this.currentBrand = this.customBranding.brand;
  }
  async login() {
    this.loginWorking = true;
    await this.authService.googleSignin({ isClientLogin: false });
    this.loginWorking = false;
  }
}
