<div *ngIf="data" class="gif-modal">
    <div class="gif-modal__header">
        <div class="gif-modal__title">{{data.title}}</div>
        <div class="gif-modal__close" (click)="close()">
            <i class="fas fa-times"></i>
        </div>
    </div>
    <div class="gif-modal__content">
        <img src="{{LearnGifs[data.gifId]}}" alt="" class="gif-modal__gif">
    </div>
</div>