<div class="upgrade-container animated fadeIn">
  <div class="dark-section">
    <button (click)="close()" class="button-link cancel-button">
      <i class="fas fa-chevron-left"></i>Volver
    </button>
    <div class="title">¡Bien hecho!</div>
    <div class="sub-title">Suscríbete y sigue mejorando</div>

    <div
      class="mx-auto -mb-[100px] mt-16 flex max-w-[1500px] flex-wrap justify-center gap-12 px-4 sm:px-8"
    >
      <!-- <div *ngFor="let price of prices" class="price">
        <div class="price__top">
          <div class="price__name">
            {{ price.name }}
          </div>
          <div class="price__price">
            <div
              *ngIf="price.comparedAtPrice"
              class="price__price__price comparedAtPrice font-light text-neutral-500 line-through"
            >
              {{ price.comparedAtPrice }}
            </div>
            <div class="price__price__price">{{ price.price }}</div>
            <div class="price__price__period">{{ price.billingPeriod }}</div>
          </div>
          <div class="price__description" [innerHTML]="price.description"></div>
        </div>
        <div class="price__bottom">
          <div class="price__bottom__top">
            <div class="price__bullets">
              <div
                *ngFor="let bullet of price.bulletPoints"
                class="price__bullet"
              >
                <div class="price__bullet__bullet">
                  <i class="fas fa-check"></i>
                </div>
                <div class="price__bullet__text" [innerHTML]="bullet"></div>
              </div>
            </div>
          </div>
          <div class="price__bottom__bottom">
            <div class="price__action">
              <app-two-state-button
                *ngIf="!price.active"
                (buttonClick)="startSub(price.priceId)"
                [loadingText]="'Procesando...'"
                [text]="price.trialAvailable ? 'Suscribirse' : 'Suscribirse'"
                [faIconCode]="'fas fa-rocket'"
                [loading]="processingSubscription"
                class="modal__sub-card__action-button"
                [modClass]="
                  'cool-button-with-icon--big cool-button-with-icon--vivid cool-button-with-icon--upgrade'
                "
              ></app-two-state-button>
              <div *ngIf="price.active" class="price__action__disabled">
                Ésta es tu suscripción actual
              </div>
            </div>
            <div class="price__action-subtitle">
            </div>
          </div>
        </div>
      </div> -->
      <div
        *ngFor="let price of availableSubscriptions$ | async"
        class="price relative overflow-hidden"
      >
        <div
          *ngIf="price.isRecommended"
          class="absolute -right-4 -top-3 translate-x-16 translate-y-14 rotate-45 transform bg-yellow-400 px-16 py-1 text-sm font-bold uppercase text-black shadow-md sm:top-0 sm:text-base"
        >
          Oferta lanzamiento
        </div>
        <div class="price__top">
          <div class="price__name">
            {{ price.name }}
          </div>
          <div class="price__price">
            <div
              *ngIf="price.comparedAtPrice"
              class="price__price__price comparedAtPrice font-light text-neutral-500 line-through"
            >
              {{ price.comparedAtPrice }}
            </div>
            <div class="price__price__price">{{ price.price }}</div>
            <div class="price__price__period">{{ price.billingPeriod }}</div>
          </div>
          <div class="price__description" [innerHTML]="price.description"></div>
        </div>
        <div class="price__bottom">
          <div class="price__bottom__top">
            <div class="price__bullets">
              <div
                *ngFor="let bullet of price.bulletPoints"
                class="price__bullet"
              >
                <div class="price__bullet__bullet">
                  <i class="fas fa-check"></i>
                </div>
                <div class="price__bullet__text" [innerHTML]="bullet"></div>
              </div>
            </div>
          </div>
          <div class="price__bottom__bottom">
            <div class="price__action">
              <!-- <button (click)="startSub(price.priceId)" *ngIf="!price.active" class="price__action__button button-solid button-solid--small button-solid--primary">{{price.trialAvailable ? 'Empezar gratis' : 'Empezar'}}</button> -->
              <app-two-state-button
                (buttonClick)="startSub(price.priceId)"
                [loadingText]="'Procesando...'"
                [text]="price.trialAvailable ? 'Suscribirse' : 'Suscribirse'"
                [faIconCode]="'fas fa-rocket'"
                [loading]="processingSubscription"
                class="modal__sub-card__action-button"
                [modClass]="
                  'cool-button-with-icon--big cool-button-with-icon--vivid cool-button-with-icon--upgrade'
                "
              ></app-two-state-button>
              <!-- TODO: change this to a disabled? maybe not if finished -->
              <!-- <button
                class="flex w-full items-center justify-center rounded-md bg-intecc px-5 py-3 font-semibold text-white opacity-50"
                disabled
              >
                <i class="fas fa-rocket mr-2"></i>
                Próximamente
              </button> -->
              <div
                *ngIf="price.isUserActiveSubscription"
                class="price__action__disabled"
              >
                Ésta es tu suscripción actual
              </div>
            </div>
            <div class="price__action-subtitle">
              <!-- <span *ngIf="!price.active" ><span *ngIf="price.trialAvailable">Prueba gratis 7 días.</span> Cancela cuando quieras.</span>
                            <span *ngIf="price.active">&nbsp;</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="white-section">
    <div class="mt-20" *ngIf="(availableSubscriptions$ | async) === null">
      <div class="spinner"></div>
      Cargando suscripciones...
    </div>
    <button (click)="close()" class="button-link cancel-button-2">
      <i class="fas fa-chevron-left"></i>Volver
    </button>
  </div>
</div>
