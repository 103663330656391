import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/config/app.config';
import { ModalService } from '../services/modal.service';
import { LoadingState } from '../utils/utils';

type ListItemBase = {
  id: string;
  faIcon: string;
  url: string;
  name: string;
  createdAt: Date;
};

export type ListItem<T = any> = Omit<T, keyof ListItemBase> & ListItemBase;

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrl: './items-list.component.scss',
})
export class ItemsListComponent implements OnInit {
  @Input() primaryColorClass = 'emerald-700';

  @Input() secondaryColorClass = 'emerald-100';

  @Input() featureNotAvailableText = 'Funcionalidad no disponible';

  @Input() itemsObservable$!: Observable<any[]>;

  @Input() canUseFeature: boolean = true;

  @Input() handleGenerateReport: (itemsMap: Map<string, any>) => void = () => {
    console.log('handleGenerateReport not implemented');
  };

  @Input() handleDeleteItems: (itemsMap: Map<string, any>) => void = () => {
    console.log('handleDeleteItems not implemented');
  };

  @Input() handleShowToClient: (item: any) => void = () => {
    console.log('handleShowToClient not implemented');
  };

  @Input() isUserClientView: boolean = false;

  loadingState: LoadingState = 'idle';

  selectedItems = new Map<string, any>();

  currentApp = AppConfig.app;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.loadingState = 'loading';
    this.itemsObservable$.subscribe({
      next: () => {
        this.loadingState = 'idle';
      },
      error: () => {
        this.loadingState = 'error';
      },
    });
  }

  selectItem(item: ListItem) {
    if (this.selectedItems.has(item.id)) {
      this.selectedItems.delete(item.id);
    } else {
      this.selectedItems.set(item.id, item);
    }
  }

  isItemSelected(item: ListItem) {
    return this.selectedItems.has(item.id);
  }

  clearSelection() {
    this.selectedItems.clear();
  }

  deleteSelectedItems() {
    if (this.isUserClientView || this.selectedItems.size === 0) {
      return;
    }
    const selectedItemsClone = new Map(this.selectedItems);
    this.handleDeleteItems(selectedItemsClone);
    this.selectedItems.clear();
  }

  async generateReport() {
    if (this.selectedItems.size === 0) {
      return;
    }
    await this.handleGenerateReport(this.selectedItems);
    this.selectedItems.clear();
  }

  async openUpgradeModal() {
    this.modalService.openModal('new-upgrade', {});
  }
}
