import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-email-wall',
  templateUrl: './email-wall.component.html',
  styleUrls: ['./email-wall.component.scss'],
})
export class EmailWallComponent implements OnInit {
  emailSent = false;

  user$: Observable<User>;

  sending = false;

  marketing = false;

  constructor(
    public auth: AuthService,
    private functionsServcie: FunctionsService,
    private nav: NavigationService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.user$ = this.auth.user$;
  }

  async sendVerificationEmail() {
    this.sending = true;
    const user = await safeToPromise(this.auth.user$.pipe(take(1)));
    const result = await this.functionsServcie.call(
      'sendVerificationEmailCallable',
      {
        email: user.email,
        name: user.displayName,
      },
    );
    this.sending = false;
    this.nav.goToHome();
  }

  requestSendEmail() {
    console.log('click');
  }
}
