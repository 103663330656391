<div class="app-container">

    <app-test-header [title]="'Rango de movimiento'" [icon]="'fas fa-shoe-prints'" [learnId]="'ROM'" ></app-test-header>
    <div class="test-info">
        <app-client-back-button [client]="client"></app-client-back-button>
        <div class="date-selector mt-2 md:mt-0">
            <button (click)="picker.open()" class="date-selector__button">{{test.createdAt.toDate() | date}} <i
                    class="fas fa-edit"></i></button>

            <input style="display: none;" (dateChange)="onDateChanged($event);" matInput [matDatepicker]="picker"
                placeholder="Choose a date">
            <mat-datepicker touchUi #picker [startAt]="test.createdAt.toDate()"></mat-datepicker>
        </div>
    </div>

    <div class="rom-card" *ngIf="false">
        <div class="rom-card__heading">
            Movimientos
        </div>
        <div class="rom-card__divider"></div>
        <div class="rom-card__content">
            <div class="rom-card__item" *ngFor="let m of movementIds" (click)="openInclinometer(m)">
                <div class="rom-card__item-bar rom-card__item-bar--{{getResultLevel(m)}}"></div>
                <div class="rom-card__item-name">
                    <div class="rom-card__item-name-name">{{RomMovements[m].name}}</div>
                    <div class="rom-card__item-name-subname">{{RomMovements[m].subname}}</div>
                </div>
                <div class="rom-card__item-result">
                    <div *ngIf="hasResult(m)" class="rom-card__item-result__result">{{getResultValue(m)}}</div>
                    <div *ngIf="!hasResult(m)" class="rom-card__item-result__no-result"><i class="far fa-circle"></i></div>
                </div>
                <div class="rom-card__item-arrow">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="rom-section-divider">
        Miembro Inferior
    </div>
    <div class="rom-card-container">
        <div class="new-rom-card new-rom-card--two-col">
            <div class="new-rom-card__heading">
                
                <div class="new-rom-card__heading__title">
                    <div (click)="openLearn('rom-tobillo')" class="learn-button"><i class="fas fa-book"></i></div>
                    <span>Tobillo</span>
                </div>
                <app-left-right-toggle [toggle]="showTobilloR" (toggled)="showTobilloR = $event"></app-left-right-toggle>
            </div>
            <div class="new-rom-card__divider"></div>
            <div class="new-rom-card__content">
                <div class="new-rom-card__grid new-rom-card__grid--2-cols">
                    <a class="new-rom-card-item" *ngFor="let m of showTobilloR ? tobilloMovementRIds : tobilloMovementIds" (click)="openInclinometer(m)">
                        <div class="new-rom-card-item__info">
                            <div class="new-rom-card-item__name">{{RomMovements[m].name}}</div>
                            <div class="new-rom-card-item__sub-name">{{RomMovements[m].subname}}</div>
                        </div>
                        <div class="new-rom-card-item__result">
                            <div class="new-rom-card-item__result-number" [class.new-rom-card-item__result-number--no-result]="!hasResult(m)">{{getResultValue(m)}}</div>
                            <app-rom-result-tag [romResult]="getResult(m)"></app-rom-result-tag>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="new-rom-card">
            <div class="new-rom-card__heading">
                <div class="new-rom-card__heading__title">
                    <div (click)="openLearn('rom-tobillo')" class="learn-button"><i class="fas fa-book"></i></div>
                    <span>Rodilla</span>
                </div>
                <app-left-right-toggle [toggle]="showRodillaR" (toggled)="showRodillaR = $event"></app-left-right-toggle>
            </div>
            <div class="new-rom-card__divider"></div>
            <div class="new-rom-card__content">
                <div class="new-rom-card__grid new-rom-card__grid">
                    <a class="new-rom-card-item" *ngFor="let m of showRodillaR ? rodillaMovementRIds : rodillaMovementIds" (click)="openInclinometer(m)">
                        <div class="new-rom-card-item__info">
                            <div class="new-rom-card-item__name">{{RomMovements[m].name}}</div>
                            <div class="new-rom-card-item__sub-name">{{RomMovements[m].subname}}</div>
                        </div>
                        <div class="new-rom-card-item__result">
                            <div class="new-rom-card-item__result-number"  [class.new-rom-card-item__result-number--no-result]="!hasResult(m)">{{getResultValue(m)}}</div>
                            <app-rom-result-tag [romResult]="getResult(m)"></app-rom-result-tag>
                        </div>
                    </a>
                </div> 
            </div>
        </div>
    </div>

    <div class="new-rom-card">
        
        <div class="new-rom-card__heading">
            <div class="new-rom-card__heading__title">
                <div (click)="openLearn('rom-tobillo')" class="learn-button"><i class="fas fa-book"></i></div>
                <span>Cadera</span>
            </div>
            <app-left-right-toggle [toggle]="showCaderaR" (toggled)="showCaderaR = $event"></app-left-right-toggle>
        </div>
        <div class="new-rom-card__divider"></div>
        <div class="new-rom-card__content">
            <div class="new-rom-card__grid new-rom-card__grid--3-cols">
                <a class="new-rom-card-item" *ngFor="let m of showCaderaR ? caderaMovementRIds : caderaMovementIds" (click)="openInclinometer(m)">
                    <div class="new-rom-card-item__info">
                        <div class="new-rom-card-item__name">{{RomMovements[m].name}}</div>
                        <div class="new-rom-card-item__sub-name">{{RomMovements[m].subname}}</div>
                    </div>
                    <div class="new-rom-card-item__result">
                        <div class="new-rom-card-item__result-number"  [class.new-rom-card-item__result-number--no-result]="!hasResult(m)">{{getResultValue(m)}}</div>
                        <app-rom-result-tag [romResult]="getResult(m)"></app-rom-result-tag>
                    </div>
                </a>
            </div> 
        </div>
    </div>

    <div class="rom-section-divider rom-section-divider--margin-top">
        Miembro Superior
    </div>

    <div class="new-rom-card">
        <div class="new-rom-card__heading">
            <div class="new-rom-card__heading__title">
                <div (click)="openLearn('rom-tobillo')" class="learn-button"><i class="fas fa-book"></i></div>
                <span>Hombro</span>
            </div>
            <app-left-right-toggle [toggle]="showHombroR" (toggled)="showHombroR = $event"></app-left-right-toggle>
        </div>
        <div class="new-rom-card__divider"></div>
        <div class="new-rom-card__content">
            <div class="new-rom-card__grid new-rom-card__grid--3-cols">
                <a class="new-rom-card-item" *ngFor="let m of showHombroR ? hombroMovementRIds : hombroMovementIds" (click)="openInclinometer(m)">
                    <div class="new-rom-card-item__info">
                        <div class="new-rom-card-item__name">{{RomMovements[m].name}}</div>
                        <div class="new-rom-card-item__sub-name">{{RomMovements[m].subname}}</div>
                    </div>
                    <div class="new-rom-card-item__result">
                        <div class="new-rom-card-item__result-number"  [class.new-rom-card-item__result-number--no-result]="!hasResult(m)">{{getResultValue(m)}}</div>
                        <app-rom-result-tag [romResult]="getResult(m)"></app-rom-result-tag>
                    </div>
                </a>
            </div> 
        </div>
    </div>

    <div class="new-rom-card">
        <div class="new-rom-card__heading">
            <div class="new-rom-card__heading__title">
                <div (click)="openLearn('rom-tobillo')" class="learn-button"><i class="fas fa-book"></i></div>
                <span>Codo</span>
            </div>
            <app-left-right-toggle [toggle]="showCodoR" (toggled)="showCodoR = $event"></app-left-right-toggle>
        </div>
        <div class="new-rom-card__divider"></div>
        <div class="new-rom-card__content">
            <div class="new-rom-card__grid">
                <a class="new-rom-card-item" *ngFor="let m of showCodoR ? codoMovementRIds : codoMovementIds" (click)="openInclinometer(m)">
                    <div class="new-rom-card-item__info">
                        <div class="new-rom-card-item__name">{{RomMovements[m].name}}</div>
                        <div class="new-rom-card-item__sub-name">{{RomMovements[m].subname}}</div>
                    </div>
                    <div class="new-rom-card-item__result">
                        <div class="new-rom-card-item__result-number"  [class.new-rom-card-item__result-number--no-result]="!hasResult(m)">{{getResultValue(m)}}</div>
                        <app-rom-result-tag [romResult]="getResult(m)"></app-rom-result-tag>
                    </div>
                </a>
            </div> 
        </div>
    </div>


</div>

<app-rom-inclinometer (closed)="onInclinometerClosed($event)" ></app-rom-inclinometer>