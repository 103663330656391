import { Injectable } from '@angular/core';
import {
  Functions,
  HttpsCallableOptions,
  connectFunctionsEmulator,
  httpsCallable,
} from '@angular/fire/functions';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(private fns: Functions) {
    // NOTE: testModee needs to be run along with "npm run run:local" and "npm run build:watch" from Server/functions package.json
    // this.setTestMode(); // FIXME: comment this line
  }

  setTestMode() {
    if (environment.production) {
      return;
    }
    connectFunctionsEmulator(this.fns, 'localhost', 5001);
  }

  async call(
    functionName: string,
    data: any,
    overrideOptions?: HttpsCallableOptions,
  ) {
    const options = {
      timeout: 1000 * 60 * 2,
      ...(overrideOptions || {}),
    };
    const callableFunction = httpsCallable(this.fns, functionName, options);
    const result = await callableFunction(data);
    return result.data as any;
  }
}
