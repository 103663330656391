import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditedMediaItem } from 'src/app/models/media/edited-media-item.model';

@Component({
    selector: 'app-render-edited-image',
    templateUrl: './render-edited-image.component.html',
    styleUrls: ['./render-edited-image.component.scss']
})
export class RenderEditedImageComponent implements OnInit {

    item: EditedMediaItem;
    item$: Observable<EditedMediaItem>;

    constructor(private route: ActivatedRoute) {
        this.item$ = this.route.queryParamMap.pipe(map(params => {
            const itemJson = params.get('item');
            console.log('itemJson', itemJson);
            const result: EditedMediaItem = JSON.parse(itemJson);
            console.log(result);
            return result;
        }));
    }

    ngOnInit(): void {
        console.log('')
        // this.item = {
        //     crop: {
        //         data: '{"x":0.12725723235673495,"y":0.20991685093053244,"w":0.6542845187642038,"h":0.6665831721024505}',
        //         type: 'crop'
        //     },
        //     edits: [
        //         { data: '{"centerX":0.46614717824285523,"centerY":0.4655656900681774,"width":0.27455422249469813,"height":0.2420737746452921}', type: 'cross' }
        //     ],
        //     downloadURL: 'https://firebasestorage.googleapis.com/v0/b/raquis-prod.appspot.com/o/user-media%2Fzh3jIWp89nhhtcczh1eqEL4TF3E3%2FdI7uiH2vnFSwK8wZnC4y?alt=media&token=5836f454-e0d0-4277-9313-4a84346423c5'
        // };
        // console.log(JSON.stringify(this.item));
    }

    init() {

    }

}
