import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InclinometerService {

  RAD_TO_DEG = 180.0 / Math.PI;

  MEAN_SIZE = 20;

  offset = 0;

  lastsInclinations: Array<number> = [];

  lastAccValues: Array<{x, y, z}> = [];

  constructor() { }

  onNewDeviceMotionEventData(eventData){
    this.inputAccValues(eventData.accelerationIncludingGravity);
    // const inclination = this.calculateInclination(eventData);

    // this.lastsInclinations.push(inclination);
    // if(this.lastsInclinations.length > this.MEAN_SIZE){
    //   this.lastsInclinations.shift();
    // }
  }
  setZero(){
    this.offset = this.getAbsoluteInclination();
  }

  getAbsoluteInclination(){
    const inclination =  this.calculateInclination();
    return Math.round(inclination);
  }

  getAbsoluteInclinationWithOffset(){
    let inclination = this.getAbsoluteInclination();
    inclination = inclination - this.offset;
    inclination = inclination < 0 ? 360 + inclination : inclination;
    return inclination;
  }

  getRelativeInclinationWithOffset(){
    let inclination = this.getAbsoluteInclinationWithOffset();
    inclination = inclination > 180 ? 360 - inclination : inclination;
    return inclination;
  }

  private inputAccValues(acc){
    this.lastAccValues.push({x: acc.x, y: acc.y, z: acc.z});
    if(this.lastAccValues.length>this.MEAN_SIZE){
      this.lastAccValues.shift();
    }
  }

  private getAccValues(){
    return {
      x: this.getMean(this.lastAccValues.map(acc => acc.x)),
      y: this.getMean(this.lastAccValues.map(acc => acc.y)),
      z: this.getMean(this.lastAccValues.map(acc => acc.z)),
    }
  }

  private calculateInclination(){
    const acc = this.getAccValues();
    let roll = this.computeRoll(acc.x, acc.y, acc.z);
    roll = -roll;
    if(roll<0){
      roll = 270 + (90 + roll);
    }

    // if(roll<-180){
    //   roll = 360 + roll;
    // }
    return roll;
  }
  private round3(num){
    return Math.round(num * 1000) / 1000
  }
  private getMean(values: Array<number>){
    if(values.length===0)
      return 0;
    let sum = values.reduce((previous, current) => current += previous);
    let avg = sum / values.length;
    return avg;
  }
  private computeRoll(x, y, z){
    let roll = (Math.atan2(y, x) * this.RAD_TO_DEG) - 90;
    return roll;
  }

}
