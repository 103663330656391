import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-feedback',
  templateUrl: './loader-feedback.component.html',
})
export class LoaderFeedbackComponent {
  @Input() message: string | undefined = undefined;

  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
