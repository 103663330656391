import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { InclinometerAvailability, PreviousAngle } from 'src/app/models/inclinometer-status.model';
import { InclinometerSubscriber } from 'src/app/models/inclinometer-subscriber.interface';
import { NewInclinometerService } from 'src/app/services/new-inclinometer.service';
import { sleep } from 'src/app/utils/utils';

enum CircleState {
    Result,
    InfoManual,
    InfoInclinometer,
    Edit,
    Measuring
}

enum MeasuringState {
    No,
    Manual,
    Inclinometer
}

@Component({
    selector: 'app-live-inclinometer-viewer',
    templateUrl: './live-inclinometer-viewer.component.html',
    styleUrls: ['./live-inclinometer-viewer.component.scss']
})
export class LiveInclinometerViewerComponent implements OnInit, AfterViewInit, InclinometerSubscriber {

    circleStateEnum = CircleState;
    measuringStateEnum = MeasuringState;
    inclinometerStateEnum = InclinometerAvailability;

    @ViewChild('angleInput') angleInput: ElementRef;

    @ViewChildren('angleInput') angleInputList !: QueryList<ElementRef>;

    // input states
    @Input()
    currentResult: number = null;

    editableResult: number;
    inclinometerState: InclinometerAvailability;

    // visual states
    circleState: CircleState;
    measuringState: MeasuringState;

    @Output() resultChanged = new EventEmitter<number>();

    constructor(private service: NewInclinometerService) { }
    onNewAngle(angle: number) {
        this.currentResult = angle;
        this.resultChanged.emit(this.currentResult);
    }

    ngOnInit(): void {
        this.init();
        this.refresh();
    }

    ngAfterViewInit() {
        this.angleInputList.changes.subscribe(r => {
            if (r.first === undefined) {
                return;
            }
            r.first.nativeElement.focus();
            setTimeout(() => {
                r.first.nativeElement.select();
            }, 10);
        });
    }

    async init() {
        this.inclinometerState = await this.service.getState();
    }

    async refresh() {
        // wait for currentResult to update
        await sleep(100);
        this.measuringState = MeasuringState.No;
        this.setInitialCircleState();
    }

    setInitialCircleState() {
        if (!!this.currentResult) {
            this.circleState = CircleState.Result;
        } else {
            if (this.inclinometerState !== InclinometerAvailability.Available) {
                this.circleState = CircleState.InfoManual;
            } else {
                this.circleState = CircleState.InfoInclinometer;
            }
        }
    }

    liveBoxTap() {
        if (this.circleState === CircleState.InfoManual) {
            this.startManualMeasure();
        } else if (this.circleState === CircleState.InfoInclinometer) {
            this.startMeasure();
        } else if (this.circleState === CircleState.Edit) {
            // this.endManualMeasure();
        } else if (this.circleState === CircleState.Result) {
            if (this.inclinometerState === InclinometerAvailability.Available) {
                this.startMeasure();
            } else {
                this.startManualMeasure();
            }
        } else if (this.circleState === CircleState.Measuring) {
            this.endMeasure();
        }
    }

    startManualMeasure() {
        this.circleState = CircleState.Edit;
        this.editableResult = this.currentResult;
    }

    startMeasure() {
        this.measuringState = MeasuringState.Inclinometer;
        this.circleState = CircleState.Measuring;
        this.service.start(this);
    }

    endMeasure() {
        this.service.stop();
        this.measuringState = MeasuringState.No;
        this.circleState = CircleState.Result;
    }

    endManualMeasure() {
        if (!!this.editableResult) {
            this.currentResult = this.editableResult;
        } else {
            this.currentResult = null;
        }
        this.resultChanged.emit(this.currentResult);
        this.setInitialCircleState();

    }

    async requestIosPermission() {
        const result = await this.service.requestPermissionIOS();
        if (result) {
            this.inclinometerState = InclinometerAvailability.Available;
        }
    }
}
