import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-academy-menu',
  templateUrl: './academy-menu.component.html',
  styleUrls: ['./academy-menu.component.scss']
})
export class AcademyMenuComponent implements OnInit {

  hideButton = false;

  data = [
    {
      title: 'Presentación', links: [
        { title: 'Introducción a la App', url: '#' },
        { title: 'Suscripciones App VAF', url: '#' },
      ]
    },
    {
      title: 'Valoración Inicial', links: [
        { title: 'Presentación VAF Inicial', url: '#' },
        { title: 'Enlace a tu Anamnesis', url: '#' },
        { title: 'Enlace a tus Clientes ', url: '#' },
        { title: 'Herramienta Multimedia ', url: '#' },
        { title: 'Comentarios y Recomendaciones', url: '#' },
      ]
    },
    {
      title: 'Valoración Postural', links: [
        { title: 'Presentación VAF Posture', url: '#' },
        { title: 'Línea de la Plomada ', url: '#' },
        { title: 'Valoración Frontal ', url: '#' },
        { title: 'Valoración Sagital ', url: '#' },
        { title: 'Herramienta Multimedia ', url: '#' },
        { title: 'Comentarios y Recomendaciones', url: '#' },
      ]
    },
    {
      title: 'Valoración del Raquis', links: [
        { title: 'Presentación VAF Raquis MSI', url: '#' },
        { title: 'Valoración en Bipedestación ', url: '#' },
        { title: 'Maniobra de Autocorrección ', url: '#' },
        { title: 'Valoración en Sedentación ', url: '#' },
        { title: 'Valoración en Flexión ', url: '#' },
        { title: 'Diagnóstico y Recomendaciones ', url: '#' },
        { title: 'Presentación VAF Raquis FS', url: '#' },
        { title: 'Índice Cifótico y Lordótico', url: '#' },
      ]
    },
    {
      title: 'Valoración Ascendente', links: [
        { title: 'Presentación VAF Foot', url: '#' },
        { title: 'Descripción Anatómica ', url: '#' },
        { title: 'Valoración del Arco Interno ', url: '#' },
        { title: 'Antepié y Retropié ', url: '#' },
        { title: 'Articulación Supra-Astragalina ', url: '#' },
        { title: 'Articulación Sub-Astragalina ', url: '#' },
        { title: 'Protocolo Hernández Corvo', url: '#' },
        { title: 'Herramienta Multimedia ', url: '#' },
        { title: 'Comentarios y Recomendaciones', url: '#' },
      ]
    },
    {
      title: 'Valoración Artromuscular', links: [
        { title: 'Presentación VAF ROM', url: '#' },
        { title: 'Valoración del Tobillo ', url: '#' },
        { title: 'Valoración de la Cintura Pélvica', url: '#' },
        { title: 'Valoración de la Cintura Escapular ', url: '#' },
        { title: 'Test básicos del Miembro Inferior ', url: '#' },
        { title: 'Herramienta Multimedia ', url: '#' },
        { title: 'Comentarios y Recomendaciones', url: '#' },
      ]
    },
    {
      title: 'My VAF', links: [
        { title: 'Presentación MyVAF', url: '#' },
        { title: 'Personalización', url: '#' },
        { title: 'Modo Valoración ', url: '#' },
        { title: 'Modo Prescripción', url: '#' },
        { title: 'Enlaces y Recursos ', url: '#' },
      ]
    },
    {
      title: 'Curso Gratuito VAF', links: [
        { title: 'Para PRO-fesionales  ', url: 'https://plataforma.intecc.org/courses/INICIACION-valoracion-anatomica-y-funcional' },
      ]
    }
  ];

  sideMenuOpen = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (window.location.href.includes('/render')) {
      this.hideButton = true;
    }
  }

  async init() {

  }

  closeSideMenu() {
    this.sideMenuOpen = false;
  }

  openSideMenu() {
    this.sideMenuOpen = true;
  }

}
