import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { ClientsComponent } from './pages/clients/clients.component';
import { AddClientComponent } from './pages/add-client/add-client.component';
import { ClientComponent } from './pages/client/client.component';
import { TestComponent } from './pages/test/test.component';
import { SubscriptionsGuard } from './guards/subscriptions.guard';
import { StyleComponent } from './pages/style/style.component';
// import { EmailWallComponent } from './pages/email-wall/email-wall.component'; // disabled as no verification of email is being done
// import { EmailVerifiedGuard } from './guards/email-verified.guard'; // disabled as no verification of email is being done
import { ConditionsAcceptedGuard } from './guards/conditions-accepted.guard';
import { RenderEditedImageComponent } from './pages/render-edited-image/render-edited-image.component';
import { MyvafProtocolEditComponent } from './pages/myvaf-protocol-edit/myvaf-protocol-edit.component';
import { DuplicateMyVafProtocolComponent } from './pages/duplicate-my-vaf/duplicate-my-vaf.component';
import { UserComponent } from './user/user.component';
import { TrainingSessionProtocolEditComponent } from './pages/training-session-protocol-edit/training-session-protocol-edit.component';
import { CorrectiveSessionProtocolEditComponent } from './pages/corrective-session-protocol-edit/corrective-session-protocol-edit.component';
import { DuplicateTrainingSessionProtocolComponent } from './pages/duplicate-training-session/duplicate-training-session.component';
import { ClientTrainingSessionComponent } from './pages/client-training-session/client-training-session.component';
import { DuplicateCorrectiveSessionProtocolComponent } from './pages/duplicate-corrective-session/duplicate-corrective-session.component';
import { ClientCorrectiveSessionComponent } from './pages/client-corrective-session/client-corrective-session.component';
import { User } from './models/user.model';
import { AcceptConditionsComponent } from './accept-conditions/accept-conditions.component';
import { ClientLoginComponent } from './pages/client-login/client-login.component';
import { ClientHomeComponent } from './pages/client-home/client-home.component';
import { InvitationGuard } from './guards/invitation.guard';
import { JoinProfessionalComponent } from './pages/join-professional/join-professional.component';
import { ProfessionalLoginComponent } from './pages/professional-login/professional-login.component';
import { ClientTestsComponent } from './pages/client-tests/client-tests.component';
import { ClientTrainingComponent } from './pages/client-training/client-training.component';
import { ClientProfessionalComponent } from './pages/client-professional/client-professional.component';
import { ClientClientComponent } from './pages/client-client/client-client.component';
import { ReportViewerComponent } from './pages/report-viewer/report-viewer.component';

const authenticatedRouteGuards = [
  AuthGuard,
  ConditionsAcceptedGuard /* EmailVerifiedGuard */,
];

const authenticatedClientRouteGuards = [
  ...authenticatedRouteGuards,
  InvitationGuard,
];

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'style',
    component: StyleComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'professional-login',
    component: ProfessionalLoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'my-profile',
    component: UserComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'add-client',
    component: AddClientComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'client/:id',
    component: ClientComponent,
    canActivate: [...authenticatedRouteGuards],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'myvaf/:id',
    component: MyvafProtocolEditComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'copy-protocol/:id',
    component: DuplicateMyVafProtocolComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'training-session-protocol/:id',
    component: TrainingSessionProtocolEditComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'copy-training-session-protocol/:id',
    component: DuplicateTrainingSessionProtocolComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'corrective-session-protocol/:id',
    component: CorrectiveSessionProtocolEditComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'copy-corrective-session-protocol/:id',
    component: DuplicateCorrectiveSessionProtocolComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'test/:id',
    component: TestComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'client-training-session/:id',
    component: ClientTrainingSessionComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'client-corrective-session/:id',
    component: ClientCorrectiveSessionComponent,
    canActivate: [...authenticatedRouteGuards, SubscriptionsGuard],
    data: { roles: ['admin', 'professional'] satisfies Array<User['role']> },
  },
  {
    path: 'accept-conditions',
    component: AcceptConditionsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'render', component: RenderEditedImageComponent },
  {
    path: 'report-viewer',
    component: ReportViewerComponent,
  },
  // Client routes
  // TODO: verify that routes related to client data (like client-training-sessions/:id) are protected by the correct guards
  {
    path: 'c',
    component: ClientHomeComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
  {
    path: 'c/login',
    component: ClientLoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'c/join-professional',
    component: JoinProfessionalComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
  {
    path: 'c/client',
    component: ClientClientComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
  {
    path: 'c/tests',
    component: ClientTestsComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
  {
    path: 'c/tests/:id',
    component: TestComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: {
      roles: ['client'] satisfies Array<User['role']>,
      isUserClientView: true,
    },
  },
  {
    path: 'c/training',
    component: ClientTrainingComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
  {
    path: 'c/client-corrective-session/:id',
    component: ClientCorrectiveSessionComponent,
    canActivate: [...authenticatedRouteGuards],
    data: {
      roles: ['client'] satisfies Array<User['role']>,
      isUserClientView: true,
    },
  },
  {
    path: 'c/client-training-session/:id',
    component: ClientTrainingSessionComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: {
      roles: ['client'] satisfies Array<User['role']>,
      isUserClientView: true,
    },
  },
  {
    path: 'c/professional',
    component: ClientProfessionalComponent,
    canActivate: [...authenticatedClientRouteGuards],
    data: { roles: ['client'] satisfies Array<User['role']> },
  },
];
