import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { AppConfig } from 'src/config/app.config';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';
import { User } from 'src/app/models/user.model';
import { UserConfig } from 'src/app/models/user-config.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { CanIService } from 'src/app/services/can-i.service';
import { combineLatest, from } from 'rxjs';
import { LoadingState } from 'src/app/utils/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  userConfig: UserConfig;

  menuOpen = false;

  appConfig = AppConfig;

  user: User;

  canUseExercises = false;

  loadingState: LoadingState = 'loading';

  shouldDisplayFirstBox = false;

  connectLinks = [];

  getIconByKey(key: string) {
    switch (key) {
      case 'web':
        return 'fas fa-globe';
      case 'location':
        return 'fas fa-map-marker-alt';
      case 'connectPhone':
        return 'fa-solid fa-square-phone';
      case 'connectEmail':
        return 'fas fa-envelope';
      case 'whatsapp':
        return 'fab fa-whatsapp';
      case 'appointmentsCalendar':
        return 'far fa-calendar-alt';
      case 'paymentsLink':
        return 'fas fa-credit-card';
      case 'news':
        return 'fas fa-bullhorn';
      case 'instagram':
        return 'fab fa-instagram';
      case 'facebook':
        return 'fab fa-facebook';
      case 'twitter':
        return 'fab fa-twitter';
      case 'tiktok':
        return 'fab fa-tiktok';
      case 'other':
        return 'fas fa-link';
      default:
        return 'fas fa-link';
    }
  }

  constructor(
    public authService: AuthService,
    private modalService: ModalService,
    private userConfigService: UserConfigServiceService,
    private subscriptionService: SubscriptionsService,
    private canIService: CanIService,
  ) {}

  ngOnInit() {
    combineLatest([
      this.authService.user$,
      this.userConfigService.userConfig$,
      this.subscriptionService.currentUserSubscription$,
      this.subscriptionService.availableSubscriptions$,
      from(this.canIService.canViewTrainingSessions()),
    ]).subscribe(([user, config, _, __, canUseExercises]) => {
      this.user = user;
      this.userConfig = config;
      this.canUseExercises = canUseExercises;

      // will display the first box if any of the reports lines or connect links are set to display
      this.shouldDisplayFirstBox =
        (config?.reportsConfig &&
          Object.entries(config.reportsConfig).some(([key, value]) => {
            return key.includes('display') && value === true;
          })) ||
        (config?.connectLinks &&
          Object.entries(config.connectLinks).some(([key, value]) => {
            return key.includes('display') && value === true;
          }));

      // the connectLinks are filtered to only display the ones that have a display key set to true
      const connectLinksEntries = config?.connectLinks
        ? Object.entries(config.connectLinks)
        : [];
      this.connectLinks = connectLinksEntries
        .filter(([key, value]) => {
          return (
            value &&
            !key.includes('display') &&
            connectLinksEntries.some(([key2, value2]) => {
              return (
                key2 ===
                  `display${key.charAt(0).toUpperCase() + key.slice(1)}` &&
                value2 === true
              );
            })
          );
        })
        .map(([k3, v3]) => {
          return {
            key: k3,
            icon: this.getIconByKey(k3),
            url: v3,
          };
        })
        .sort((a, b) => {
          return a.key.localeCompare(b.key);
        })
        .slice(0, 5);
      this.loadingState = 'idle';
    });
  }

  openAccountModal() {
    this.modalService.openModal('account', {});
  }

  async openMultimedia() {
    await this.modalService.openLateralModal('client-media', {
      onlyOriginals: false,
      selectionMode: false,
      selectionName: 'My VAF',
    });
  }

  async openExercises() {
    await this.modalService.openLateralModal('exercises', {});
  }
}
