import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  CorrectiveSessionSection,
  CORRECTIVESECTIONS_PATH,
} from '../models/corrective-session-section.model';
import { FirestoreService } from './firestore.service';
import {
  CLIENT_CORRECTIVE_SESSIONS_PATH,
  ClientCorrectiveSession,
  createEmptyClientCorrectiveSession,
  CorrectiveSessionProtocolDef,
  CORRECTIVESESSIONPROTOCOLDEF_PATH,
} from 'src/shared/corrective-session-definition.outside';
import { Observable, of } from 'rxjs';
import { ClientsService } from './clients.service';
import { AuthService } from './auth.service';
import { SentryService } from './sentry.service';
import { safeToPromise, sleep } from '../utils/utils';
import { Client } from '../models/client.model';
import { SnackbarService } from './snackbar.service';
import { FunctionsService } from './functions.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class CorrectiveSessionsService {
  currentClientCorrectiveSessions$: Observable<ClientCorrectiveSession[]>;
  currentClientCorrectiveSessionsCount = 0;

  constructor(
    private fs: FirestoreService,
    private clients: ClientsService,
    private auth: AuthService,
    private sentry: SentryService,
    private snackbarService: SnackbarService,
    private functionsService: FunctionsService,
  ) {
    this.init();
  }

  init() {
    this.currentClientCorrectiveSessions$ = this.clients.currentClientId$.pipe(
      distinctUntilChanged(),
      filter((val) => val !== null),
      switchMap((id) => this.auth.user$.pipe(map((user) => ({ user, id })))),
      switchMap(({ user, id }) => {
        if (!user) {
          return of([]); // Return empty array if user is not defined
        }
        return this.fs.colWithIds$<ClientCorrectiveSession>(
          `${CLIENT_CORRECTIVE_SESSIONS_PATH}`,
          (ref) =>
            ref
              .where('client', '==', id)
              .where('owner', '==', user.uid)
              // .where('type', 'in', ['my-vaf'])
              .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        this.currentClientCorrectiveSessionsCount = data.length;
      }),
      tap((data) => this.sentry.logFirestoreRead('Tests', data.length)),
      shareReplay(1),
    );
  }

  async createCorrectiveSessionProtocol(owner: User) {
    const correctiveSessionProtocol: CorrectiveSessionProtocolDef = {
      name: '',
      description: '',
      lastLocalId: 0,
      lastLocalImageId: 0,
      sections: [
        {
          name: 'Sección 1',
          localId: 0,
          fields: [
            {
              localId: 0,
              title: 'Series',
              leftRight: false,
              options: [],
              type: 'number',
              optionsString: '',
            },
            {
              localId: 1,
              title: 'Volumen',
              leftRight: false,
              options: [],
              type: 'text',
              optionsString: '',
            },
            {
              localId: 2,
              title: 'Intensidad',
              leftRight: false,
              options: [],
              type: 'text',
              optionsString: '',
            },
            {
              localId: 3,
              title: 'Trabajo',
              leftRight: false,
              options: [],
              type: 'time',
              optionsString: '',
            },
            {
              localId: 4,
              title: 'Descanso',
              leftRight: false,
              options: [],
              type: 'time',
              optionsString: '',
            },
            {
              localId: 5,
              title: 'Siguiente',
              leftRight: false,
              options: [],
              type: 'time',
              optionsString: '',
            },
          ],
          sectionExercises: [], // this empty as default will ensure the protocol is considered the new version with exercises entity
          tableFirstColTitle: 'Ejercicios',
          mode: 'table',
          tableMode: 'xy',
          lastLocalId: 5,
        },
      ],
      owner: owner.uid,
    };
    const result = await this.fs.add(
      `${CORRECTIVESESSIONPROTOCOLDEF_PATH}`,
      correctiveSessionProtocol,
    );
    return result.id;
  }

  async getCorrectiveSessionProtocol(id: string) {
    const doc = await safeToPromise(
      this.fs.doc(`${CORRECTIVESESSIONPROTOCOLDEF_PATH}/${id}`).get(),
    );
    if (doc.exists) {
      return { ...(doc.data() as any), id } as CorrectiveSessionProtocolDef;
    } else {
      return null;
    }
  }

  getCorrectiveSessionProtocols$(owner: string) {
    return this.fs
      .colWithIds$<CorrectiveSessionProtocolDef>(
        `${CORRECTIVESESSIONPROTOCOLDEF_PATH}`,
        (ref) => ref.where('owner', '==', owner),
      )
      .pipe(
        map((docs) => {
          return docs.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }),
      );
  }

  async getSections(owner: string) {
    const docs: CorrectiveSessionSection[] = await safeToPromise(
      this.fs
        .colWithIds$<CorrectiveSessionSection>(
          `${CORRECTIVESECTIONS_PATH}`,
          (ref) => ref.where('owner', '==', owner),
        )
        .pipe(take(1)),
    );
    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.fs.add(`${CORRECTIVESECTIONS_PATH}`, section);
      section = await this.getSections(owner);
    }
    return section;
  }

  async updateSections(section: CorrectiveSessionSection) {
    await this.fs.update(`${CORRECTIVESECTIONS_PATH}/${section.id}`, section);
    return true;
  }

  async createSections(owner: string) {}

  async updateCorrectiveSessionProtocol(
    correctiveSessionProtocol: CorrectiveSessionProtocolDef,
  ) {
    await this.fs.update(
      `${CORRECTIVESESSIONPROTOCOLDEF_PATH}/${correctiveSessionProtocol.id}`,
      correctiveSessionProtocol,
    );
    return true;
  }

  async deleteCorrectiveSessionProtocol(
    correctiveSessionProtocol: CorrectiveSessionProtocolDef,
  ) {
    await this.fs.delete(
      `${CORRECTIVESESSIONPROTOCOLDEF_PATH}/${correctiveSessionProtocol.id}`,
    );
    return true;
  }

  async duplicateCorrectiveSessionProtocol(
    correctiveSessionProtocol: CorrectiveSessionProtocolDef,
  ) {
    const newCorrectiveSessionProtocol: CorrectiveSessionProtocolDef = {
      ...correctiveSessionProtocol,
    };
    delete newCorrectiveSessionProtocol.id;
    newCorrectiveSessionProtocol.name =
      newCorrectiveSessionProtocol.name + ' - Copia';
    const result = await this.fs.add(
      `${CORRECTIVESESSIONPROTOCOLDEF_PATH}`,
      newCorrectiveSessionProtocol,
    );
    return result.id;
  }

  async createClientCorrectiveSession(
    clientId: string,
    correctiveSessionProtocolDef: CorrectiveSessionProtocolDef,
  ) {
    const user = await this.auth.getUser();
    const clientCorrectiveSession = createEmptyClientCorrectiveSession();
    clientCorrectiveSession.owner = user.uid;
    clientCorrectiveSession.client = clientId;
    clientCorrectiveSession.correctiveSessionProtocolDefId =
      correctiveSessionProtocolDef.id;
    await sleep(500);
    const result = await this.fs.add(
      `${CLIENT_CORRECTIVE_SESSIONS_PATH}`,
      clientCorrectiveSession,
    );
    return result;
  }

  async deleteClientCorrectiveSessions(
    clientCorrectiveSessionsIds: Array<string>,
  ) {
    for (let index = 0; index < clientCorrectiveSessionsIds.length; index++) {
      await this.fs
        .doc(
          `${CLIENT_CORRECTIVE_SESSIONS_PATH}/${clientCorrectiveSessionsIds[index]}`,
        )
        .delete();
      // this.sentry.logFirestoreWrite('CLIENTS', 1);
    }
  }

  getClientCorrectiveSessionByProtocolDefId$(
    correctiveSessionProtocolDefId: string,
    ownerId: string,
  ) {
    return this.fs
      .colWithIds$<ClientCorrectiveSession>(
        `${CLIENT_CORRECTIVE_SESSIONS_PATH}`,
        (ref) => ref.where('owner', '==', ownerId),
      )
      .pipe(
        map((tests: ClientCorrectiveSession[]) => {
          return tests.filter(
            (clientCorrectiveSession) =>
              clientCorrectiveSession.correctiveSessionProtocolDefId ===
              correctiveSessionProtocolDefId,
          );
        }),
      );
  }

  getClientCorrectiveSession$(testId: string) {
    return this.fs
      .doc$<ClientCorrectiveSession>(
        `${CLIENT_CORRECTIVE_SESSIONS_PATH}/${testId}`,
      )
      .pipe(
        map((clientCorrectiveSession) => ({
          ...clientCorrectiveSession,
          id: testId,
        })),
        tap((clientCorrectiveSession) => this.sentry.logFirestoreRead('tests')),
      );
  }

  getClientCorrectiveSession(testId: string) {
    return this.fs.getDocById<ClientCorrectiveSession>(
      `${CLIENT_CORRECTIVE_SESSIONS_PATH}/${testId}`,
    );
  }

  async updateClientCorrectiveSession(
    clientCorrectiveSession: ClientCorrectiveSession,
  ) {
    await this.fs.update<ClientCorrectiveSession>(
      `${CLIENT_CORRECTIVE_SESSIONS_PATH}/${clientCorrectiveSession.id}`,
      clientCorrectiveSession,
    );
  }

  getClientCorrectiveSessions$(clientId: string) {
    return this.fs.colWithIds$<ClientCorrectiveSession>(
      `${CLIENT_CORRECTIVE_SESSIONS_PATH}`,
      (ref) => ref.where('client', '==', clientId),
    );
  }

  getClientCorrectiveSessionShareUrl(
    clientCorrectiveSession: ClientCorrectiveSession,
  ) {
    return `${window.location.origin}/copy-corrective-session/${clientCorrectiveSession.id}`;
  }

  async createClientCorrectiveSessionFromSharedUrl(
    sessionSharedUrl: string,
    client: Client,
  ) {
    try {
      if (!sessionSharedUrl) {
        return this.snackbarService.error('Introduce una URL');
      }
      const currentUrl = new URL(window.location.href);
      const currentOrigin = currentUrl.origin;
      let sharedUrl;
      try {
        sharedUrl = new URL(sessionSharedUrl);
      } catch (error) {
        return this.snackbarService.error('La URL compartida no es válida');
      }

      const sharedUrlOrigin = sharedUrl.origin;
      if (currentOrigin !== sharedUrlOrigin) {
        return this.snackbarService.error(
          'El origen de la URL no coincide con el origen de esta página',
        );
      }

      const sharedUrlPath = sharedUrl.pathname;
      // use regex to validate the url and extract the client-corrective-session id
      const regex = /\/copy-corrective-session\/([a-zA-Z0-9]+)/;
      const match = sharedUrlPath.match(regex);
      if (!match) {
        return this.snackbarService.error('La URL compartida no es válida');
      }
      const clientCorrectiveSessionId = match[1];
      if (!client) {
        return this.snackbarService.error('No hay un cliente seleccionado');
      }
      const response = await this.functionsService.call(
        'copyCorrectiveSessionCallable',
        {
          clientCorrectiveSessionId,
          destinationClientId: client.id,
        },
        { timeout: 1000 * 60 * 4 },
      );
      this.snackbarService.success(
        'Se ha creado la sesión de ejercicios correctivos',
      );
      return response; // id of the new client corrective session
    } catch (error) {
      console.error(error);
      this.snackbarService.error(
        'Ha ocurrido un error al crear la sesión de ejercicios correctivos',
      );
    }
  }
}
