import { Component, OnInit, ChangeDetectorRef, ApplicationRef, ChangeDetectionStrategy } from '@angular/core';
import { PwaService } from 'src/app/services/pwa.service';
import { trigger, transition, query, animateChild, animate, style } from '@angular/animations';
import { AppConfig, AppName } from 'src/config/app.config';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('fadeOut', [
            transition(':leave', [
                query(':leave', animateChild(), { optional: true }),
                animate(500, style({ opacity: 0 }))
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit {

    show = true;

    app: AppName = AppConfig.app;

    constructor(
        private pwaService: PwaService,
        private cdr: ChangeDetectorRef,
        private appRef: ApplicationRef,
    ) {
    }

    ngOnInit() {
        this.pwaService.checkForUpdate()
            .subscribe(result => {
                this.show = result;
                this.cdr.detectChanges();
            });
    }

}
