export const SUBSCRIPTIONS_PATH = 'subscriptions';
export interface Subscription {
    raquis?: boolean;
}

export interface SubscriptionInfo {
    name: string;
    planId: string;
    subPlanId: string;
    price: string;
    offer: string;
}

// export const SubscriptionCodes = {
//     RaquisStart: 'prod_Ghxa7qubmeELcA',
//     RaquisPro: 'prod_G0t2WYJT7cnqbH',
//     RaquisPremium: 'prod_GhxhqJzrpYUw9U'
// };

// export const SubscriptionPlanCodes = {
//     // TODO CAMBIAR ESTO
//     RaquisProSecret: 'plan_GYG1lpTN3KF8dW',
//     RaquisPro50OFF: 'plan_GYG0UTkXuK9RIL',
//     RaquisProDefault: 'plan_GYG0UTkXuK9RIL',
// };

// export const SubscriptionPlans = {
//     [SubscriptionCodes.RaquisPro]: [
//         'plan_GYG1lpTN3KF8dW', // secret plan
//         'plan_GYG0UTkXuK9RIL', // 50 OFF plan
//         'plan_G0t7J7DD8dUK04', // default plan
//     ]
// };

export const SubPlanCodes = {
    RaquisStart: 'plan_GhxhNMHKdLuDCL',
    RaquisPro50: 'plan_GYG0UTkXuK9RIL',
    RaquisPro: 'plan_Gi3hN1nqDbhQSH',
    RaquisPremium: 'plan_Gi3eFm3WT6uzsf',
    RaquisPremium50: 'plan_GhxjW4JbtOj0ht',
    RaquisPremiumFree: 'plan_Gi3Uy7IYRYssFI',
};

// export const Products = {
//     RaquisStart: {
//         id: 'prod_Ghxa7qubmeELcA',
//         name: 'Raquis Start',
//         level: 1,
//         plans: [
//             {name: 'Monthly', description: '1€/mes', id: PlanCodes.RaquisStart}
//         ]
//     },
//     RaquisPro:  {
//         id: 'prod_G0wgnfMGOXMuYp',
//         name: 'Raquis Pro',
//         level: 2,
//         plans: [
//             {name: 'Monthly 50% OFF', description: '9,99€/mes', id: PlanCodes.RaquisPro50, offer: 'Oferta de lanzamiento'},
//             {name: 'Monthly', description: '19,99€/mes', id: PlanCodes.RaquisPro},
//         ]
//     },
//     RaquisPremium: {
//         id: 'prod_GhxhqJzrpYUw9U',
//         name: 'Raquis Premium',
//         level: 3,
//         plans: [
//             {name: 'Free', description: '0€/mes', id: PlanCodes.RaquisPremiumFree, offer: 'ACCESO GRATUITO'},
//             {name: 'Monthly 50% OFF', description: '29€/mes', id: PlanCodes.RaquisPremium50},
//             {name: 'Monthly', description: '58€/mes', id: PlanCodes.RaquisPremium, offer: '-50%'},
//         ]
//     }
// };

export const PlanIds = {
    RaquisStart: 'RaquisStart',
    RaquisPro: 'RaquisPro',
    RaquisPremium: 'RaquisPremium',
};

export const PlansNew = {
    [PlanIds.RaquisStart]: {
        name: 'Raquis Start',
        planId: PlanIds.RaquisStart,
        subplans: [
            {name: 'Monthly', description: '1€/mes', id: SubPlanCodes.RaquisStart}
        ]
    },
    [PlanIds.RaquisPro]: {
        name: 'Raquis Pro',
        planId: PlanIds.RaquisPro,
        subplans: [
            {name: 'Monthly 50% OFF', description: '9,99€/mes', id: SubPlanCodes.RaquisPro50, offer: 'Oferta de lanzamiento'},
            {name: 'Monthly', description: '19,99€/mes', id: SubPlanCodes.RaquisPro},
        ]
    },
    [PlanIds.RaquisPremium]: {
        name: 'Raquis Premium',
        planId: PlanIds.RaquisPremium,
        subplans: [
            {name: 'Free', description: '0€/mes', id: SubPlanCodes.RaquisPremiumFree, offer: 'ACCESO GRATUITO'},
            {name: 'Monthly 50% OFF', description: '29€/mes', id: SubPlanCodes.RaquisPremium50, offer: 'Oferta de lanzamiento'},
            {name: 'Monthly', description: '58€/mes', id: SubPlanCodes.RaquisPremium, },
        ]
    }
};

export function getSubPlan(subPlanId: string) {
    const planKeys = Object.keys(PlansNew);
    for (const planKey of planKeys) {
        const plan = PlansNew[planKey];
        for (const subPlan of plan.subplans) {
            if (subPlan.id === subPlanId) {
                return subPlan;
            }
        }
    }
    return null;
}

export function getPlan(subPlanId: string) {
    const planKeys = Object.keys(PlansNew);
    for (const planKey of planKeys) {
        const plan = PlansNew[planKey];
        for (const subPlan of plan.subplans) {
            if (subPlan.id === subPlanId) {
                return plan;
            }
        }
    }
    return null;
}

