<div class="container">
    <div class="content">
        <h2 class="heading-1">
            Heading 1
        </h2>
        <h2 class="heading-2">
            Heading 2
        </h2>

        <button class="button-solid">Button solid</button>
        <div class="separator"></div>
        <button class="button-solid button-solid--small">
            Button solid small
        </button>
        <button class="button-solid button-solid--small button-solid--primary">
            Button solid small
        </button>
        <div class="separator"></div>
        <button class="button-solid button-solid--small">
            <i class="fas fa-user-plus"></i>
            Button solid with icon - Button
        </button>
        <div class="separator"></div>
        <a class="button-solid button-solid--small">
            <i class="fas fa-user-plus"></i>
            Button solid with icon - Link
        </a>
        <button class="button-link">Button link</button>
        <button class="button-link"><i class="fas fa-chevron-left"></i>Button link with icon</button>
        <div class="form-input">
            <label class="form-input__label" for="name">Nombre</label>
            <input class="form-input__input" type="text" id="name">
        </div>
    </div>
</div>