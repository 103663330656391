<div
  class="fixed left-0 top-0 h-screen w-screen overflow-hidden transition-all duration-700"
  [class.nvm-box-hidden]="!isOpen"
>
  <div
    class="h-full w-full overflow-y-auto bg-bg pb-5 pt-5 transition-all duration-700 md:pb-10 md:pt-16"
    [class.nvm-hidden]="!isOpen"
  >
    <div class="mx-auto grid h-full max-w-5xl grid-rows-header-content px-5">
      <div class="mb-5 flex justify-between">
        <div class="">
          <h1 class="text-xl font-bold">Sesiones de ejercicios correctivos</h1>
          <p>Selecciona, ordena y crea sesiones de ejercicios correctivos</p>
        </div>
        <div class="flex">
          <a
            href="https://manual.intecc.org/es/article/ejercicios-correctivos"
            target="_blank"
            rel="noopener noreferrer"
            class="mr-5 cursor-pointer text-xl"
          >
            <i class="fas fa-graduation-cap text-intecc2"></i>
          </a>
          <div (click)="close()" class="cursor-pointer text-xl">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="">
        <ng-container *ngIf="sections">
          <div class="">
            <cdk-accordion class="example-accordion bg-white">
              <cdk-accordion-item
                *ngFor="let item of sections.sections; let sectionIndex = index"
                #accordionItem="cdkAccordionItem"
                class="example-accordion-item"
                role="button"
                tabindex="0"
                [attr.id]="'accordion-header-' + sectionIndex"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + sectionIndex"
              >
                <div
                  class="example-accordion-item-header"
                  (click)="accordionItem.toggle()"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="ml-3">
                      <p class="font-bold">{{ item.name }}</p>
                    </div>
                    <div class="flex">
                      <div
                        (click)="moveSectionUp(sectionIndex, $event)"
                        class="p-2"
                      >
                        <i class="fas fa-angle-up"></i>
                      </div>
                      <div class="mr-3"></div>
                      <div
                        (click)="moveSectionDown(sectionIndex, $event)"
                        class="p-2"
                      >
                        <i class="fas fa-angle-down"></i>
                      </div>
                      <div class="mr-3"></div>
                      <div
                        (click)="openRenameSection(item, $event)"
                        class="p-2"
                      >
                        <i class="fas fa-pen-alt"></i>
                      </div>
                      <div class="mr-3"></div>
                      <div (click)="deleteSection(sectionIndex)" class="p-2">
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="example-accordion-item-body"
                  role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'"
                  [attr.id]="'accordion-body-' + sectionIndex"
                  [attr.aria-labelledby]="'accordion-header-' + sectionIndex"
                >
                  <div class="my-vaf-grid">
                    <div
                      *ngFor="
                        let id of item.correctiveSessionProtocolIds;
                        let protocolIndex = index
                      "
                      class=""
                    >
                      <ng-container
                        *ngIf="
                          getCorrectiveSessionProtocolById(id);
                          let correctiveSessionProtocol
                        "
                      >
                        <div
                          (click)="
                            createClientCorrectiveSession(
                              correctiveSessionProtocol
                            )
                          "
                          class="my-vaf-grid__item relative cursor-pointer rounded-lg bg-white transition-all duration-200 hover:shadow-xl"
                        >
                          <div
                            class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center px-5"
                          >
                            <div
                              class="mb-2 flex h-16 w-16 items-center justify-center rounded-full bg-emerald-100"
                            >
                              <p
                                class="text-2xl font-bold tracking-widest text-emerald-700"
                              >
                                {{
                                  getCorrectiveSessionProtocolInitials(
                                    correctiveSessionProtocol
                                  )
                                }}
                              </p>
                            </div>
                            <p class="text-center font-bold leading-5">
                              {{ correctiveSessionProtocol.name }}
                            </p>
                            <span class="hidden">{{
                              correctiveSessionProtocol.id
                            }}</span>
                          </div>
                          <div
                            (click)="
                              editCorrectiveSessionProtocol(
                                correctiveSessionProtocol,
                                $event
                              )
                            "
                            class="absolute right-0 top-0 cursor-pointer p-2"
                          >
                            <i class="text-1xl fas fa-pen-alt mr-1"></i>
                          </div>
                          <div
                            (click)="
                              openMoveCorrectiveSessionProtocolDialog(
                                correctiveSessionProtocol,
                                $event
                              )
                            "
                            class="absolute left-0 top-0 cursor-pointer p-2"
                          >
                            <i
                              class="text-1xl fas fa-tags text-emerald-700"
                            ></i>
                          </div>
                          <div
                            (click)="
                              moveCorrectiveSessionProtocolUp(
                                sectionIndex,
                                protocolIndex,
                                $event
                              )
                            "
                            class="absolute bottom-0 left-0 cursor-pointer p-2"
                          >
                            <i class="text-1xl fas fa-angle-left"></i>
                          </div>
                          <div
                            (click)="
                              moveCorrectiveSessionProtocolDown(
                                sectionIndex,
                                protocolIndex,
                                $event
                              )
                            "
                            class="absolute bottom-0 right-0 cursor-pointer p-2"
                          >
                            <i class="text-1xl fas fa-angle-right"></i>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </cdk-accordion-item>
            </cdk-accordion>
          </div>
        </ng-container>
        <div class="mb-5"></div>
        <div
          (click)="addSection()"
          class="relative cursor-pointer rounded-md border-2 border-dashed p-4 opacity-60 transition-all duration-200 hover:border-emerald-700 hover:text-emerald-700 hover:opacity-100"
        >
          <p class="text-center">Añadir sección</p>
        </div>
        <div class="my-5">
          <p class="mb-2">
            Crear sesión desde url
            <i
              class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
              matTooltip="Pega aquí el enlace para crear la sesión de ejercicios correctivos"
            ></i>
          </p>
          <div class="flex w-full items-center space-x-2">
            <div class="flex-grow">
              <app-input
                class="grow"
                [(value)]="sharedUrl"
                fieldName=""
              ></app-input>
            </div>
            <app-two-state-button
              (buttonClick)="createClientCorrectiveSessionFromSharedUrl()"
              [loading]="creatingSessionFromUrl"
              [text]="'Crear'"
              [loadingText]="'Creando...'"
              [faIconCode]="'far fa-save'"
            ></app-two-state-button>
          </div>
        </div>
        <div
          *ngIf="correctiveSessionProtocolsWithoutSection"
          class="animated fadeIn overflow-y-auto px-0 py-3"
        >
          <div class="my-vaf-grid">
            <div
              (click)="createCorrectiveSessionProtocol()"
              class="my-vaf-grid__item relative cursor-pointer rounded-lg border-2 border-dashed opacity-60 transition-all duration-200 hover:border-vafDark hover:text-vafDark hover:opacity-100"
            >
              <div
                class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center px-5"
              >
                <div
                  class="mb-2 flex h-20 w-20 items-center justify-center rounded-full"
                >
                  <i class="fas fa-plus-circle text-4xl"></i>
                </div>
                <!-- <p class="font-bold leading-5 text-center">Nuevo protocolo</p> -->
              </div>
            </div>
            <div
              (click)="createClientCorrectiveSession(correctiveSessionProtocol)"
              *ngFor="
                let correctiveSessionProtocol of correctiveSessionProtocolsWithoutSection
              "
              class="my-vaf-grid__item relative cursor-pointer rounded-lg bg-white transition-all duration-200 hover:shadow-xl"
            >
              <div
                class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center px-5"
              >
                <div
                  class="mb-2 flex h-16 w-16 items-center justify-center rounded-full bg-emerald-100"
                >
                  <p
                    class="text-2xl font-bold tracking-widest text-emerald-700"
                  >
                    {{
                      getCorrectiveSessionProtocolInitials(
                        correctiveSessionProtocol
                      )
                    }}
                  </p>
                </div>
                <p class="text-center font-bold leading-5">
                  {{ correctiveSessionProtocol.name }}
                </p>
                <span class="hidden">{{ correctiveSessionProtocol.id }}</span>
              </div>
              <div
                (click)="
                  editCorrectiveSessionProtocol(
                    correctiveSessionProtocol,
                    $event
                  )
                "
                class="absolute right-0 top-0 cursor-pointer p-2"
              >
                <i class="text-1xl fas fa-pen-alt mr-1"></i>
              </div>
              <div
                (click)="
                  openMoveCorrectiveSessionProtocolDialog(
                    correctiveSessionProtocol,
                    $event
                  )
                "
                class="absolute left-0 top-0 cursor-pointer p-2"
              >
                <i class="text-1xl fas fa-tags text-emerald-700"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5"></div>
      </div>
    </div>
  </div>
  <div
    class="h-full w-full absolute left-0 top-0 bg-black/70 flex flex-col justify-center {{
      moveCorrectiveSessionProtocolDialogOpen ? '' : 'hidden'
    }}"
  >
    <div class="w-full px-2">
      <div
        class="mx-auto max-h-screen max-w-md overflow-y-auto rounded-lg bg-white p-5 py-5"
      >
        <div class="mb-3 flex items-center justify-between">
          <p class="text-lg font-bold">Mover a...</p>
          <div class="cursor-pointer p-2" (click)="closeMoveToSection()">
            <i class="far fas fa-times"></i>
          </div>
        </div>
        <ng-container *ngIf="sections">
          <div
            *ngFor="let section of sections.sections"
            (click)="moveToSection(section)"
            class="mb-2 cursor-pointer rounded-lg bg-neutral-100 px-4 py-2"
          >
            <p class="">{{ section.name }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="h-full w-full absolute left-0 top-0 bg-black/70 flex flex-col justify-center {{
      editSectionNameOpen ? '' : 'hidden'
    }}"
  >
    <div class="w-full px-2">
      <div class="mx-auto max-w-md rounded-lg bg-white p-5 py-5">
        <p class="mb-3 text-lg font-bold">Renombrar sección</p>
        <input
          class="block w-full bg-neutral-100 px-5 py-2"
          type="text"
          name=""
          id=""
          [(ngModel)]="editSectionNameText"
        />
        <div class="mb-5"></div>
        <div class="flex justify-center">
          <app-button
            [type]="'primary'"
            class=""
            (clickEvent)="endRenameSection()"
            [text]="'Renombrar'"
          >
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>
