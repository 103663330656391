import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OverrideBackButtonService } from './override-back-button.service';

@Injectable({
  providedIn: 'root'
})
export class FloatingVideoService {

  vimeoId: string;
  show$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  showVideo(vimeoId: string){
  
    this.vimeoId = vimeoId;
    this.show();

  }

  show(){
    this.show$.next(true);
  }

  hide(){
    this.show$.next(false);
  }

}
