<div class="live-inclinometer-viewer">
    <div class="info-container">
        <div class="info" *ngIf="false">Inclinómetro no detectado</div>
        <div class="info" (click)="requestIosPermission()" *ngIf="inclinometerState === inclinometerStateEnum.BlockedIos">Inclinómetro bloqueado por iOS. Toca aquí para que intentemos arreglarlo.</div>
    </div>
    <div class="live-box" (click)="liveBoxTap()" [class.live-box--measuring]="circleState === circleStateEnum.Measuring || circleState === circleStateEnum.Edit" >
        <!-- <div class="display" *ngIf="showAngle">{{currentValue}}</div>
        <div class="touchToInfo" *ngIf="showTouchToStartMeasure">Toca para empezar a medir</div>
        <div class="touchToInfo" *ngIf="showTouchToStartManualMeasure">Toca para editar</div>
        <input type="text" *ngIf="showAngleInput"> -->
        <div class="display" *ngIf="circleState === circleStateEnum.Result || circleState === circleStateEnum.Measuring">{{currentResult}}</div>
        <div class="touchToInfo" *ngIf="circleState === circleStateEnum.InfoInclinometer">Toca para empezar a medir</div>
        <div class="touchToInfo" *ngIf="circleState === circleStateEnum.InfoManual">Toca para editar</div>
        <input *ngIf="circleState === circleStateEnum.Edit" #angleInput (blur)="endManualMeasure()" [(ngModel)]="editableResult" class="manual-measure-input" type="number">
    </div>
    <div class="actions">
        <button *ngIf="inclinometerState === inclinometerStateEnum.Available && circleState !== circleStateEnum.Measuring" (click)="startManualMeasure()" class="action button-icon"><i class="fas fa-pen-square"></i></button>
        <div class="info" *ngIf="false">Toca para terminar</div>
    </div>
</div>
