import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { angleFromLines } from 'src/app/utils/math';
import { getSize, SIZES } from './media-config.class';

export interface AngleItemData {
    centerX: number;
    centerY: number;
    pointAX: number;
    pointAY: number;
    pointBX: number;
    pointBY: number;
    fixedTextBox: boolean;
    textBoxCenterX?: number;
    textBoxCenterY?: number;
}

export class AngleItem extends EditItem {
    type = 'angle';
    data: AngleItemData;

    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;

    static create(centerX: number, centerY: number, pointAX: number, pointAY: number, pointBX: number, pointBY: number) {
        const data: AngleItemData = {
            centerX,
            centerY,
            pointAX,
            pointAY,
            pointBX,
            pointBY,
            fixedTextBox: true
        };
        return new AngleItem(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.centerX = this.data.centerX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.centerY = this.data.centerY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.data.pointAX = this.data.pointAX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.pointAY = this.data.pointAY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.data.pointBX = this.data.pointBX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.pointBY = this.data.pointBY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    getActionMoveTextBox(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (this.data.fixedTextBox) {
                    const textBoxCenterX = this.drawer.relativeToAbsoluteX(this.data.centerX) + 50;
                    const textBoxCenterY = this.drawer.relativeToAbsoluteY(this.data.centerY);
                    this.data.textBoxCenterX = this.drawer.absoluteToRelativeX2(textBoxCenterX);
                    this.data.textBoxCenterY = this.drawer.absoluteToRelativeY2(textBoxCenterY);
                    this.data.fixedTextBox = false;
                } else {
                    this.data.textBoxCenterX = this.data.textBoxCenterX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.textBoxCenterY = this.data.textBoxCenterY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.update();
            }
        };
    }

    getActionResize(direction: 'a' | 'b'): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'a') {
                    this.data.pointAX = this.data.pointAX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.pointAY = this.data.pointAY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'b') {
                    this.data.pointBX = this.data.pointBX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.pointBY = this.data.pointBY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    draw() {

        const pointACenter = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.pointAX),
            this.drawer.relativeToAbsoluteY(this.data.pointAY),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
        ).fill(this.color).stroke({ color: this.color, width: getSize('lineW', this.sizeMode) * 1 / this.drawer.zoomLevel });

        const pointBCenter = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.pointBX),
            this.drawer.relativeToAbsoluteY(this.data.pointBY),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
        ).fill(this.color).stroke({ color: this.color, width: getSize('lineW', this.sizeMode) * 1 / this.drawer.zoomLevel });

        const angle = angleFromLines(
            this.drawer.relativeToAbsoluteX(this.data.pointAX),
            this.drawer.relativeToAbsoluteY(this.data.pointAY),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
            this.drawer.relativeToAbsoluteX(this.data.pointBX),
            this.drawer.relativeToAbsoluteY(this.data.pointBY),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
            false);

        const angleACenterToVertical = angleFromLines(
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerX) - 1,
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
            this.drawer.relativeToAbsoluteX(this.data.pointAX),
            this.drawer.relativeToAbsoluteY(this.data.pointAY),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
            false);

        const arcCircleRadious = 25;
        const arcCircleSize = Math.PI * arcCircleRadious;
        const arcAnglePer = angle * 100 / 360;
        const arcCircle = this.drawer.svgObject.circle(arcCircleRadious).fill('transparent').opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));

        if (this.data.centerX > this.data.centerY) {
            console.log('centerX > centerY');
            arcCircle.stroke({ color: this.color, opacity: .6, width: arcCircleRadious, dasharray: `${arcAnglePer * arcCircleSize / 100} ${arcCircleSize}` }).transform({ rotate: 90 + angleACenterToVertical });

        } else {
            console.log('centerX < centerY');
            arcCircle.stroke({ color: this.color, opacity: .6, width: arcCircleRadious, dasharray: `${arcAnglePer * arcCircleSize / 100} ${arcCircleSize}` }).transform({ rotate: -90 + angleACenterToVertical });
        }

        const centerS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const pointAS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.pointAX), this.drawer.relativeToAbsoluteY(this.data.pointAY));
        const pointBS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.pointBX), this.drawer.relativeToAbsoluteY(this.data.pointBY));

        let textBoxCenterX = this.drawer.relativeToAbsoluteX(this.data.centerX) + 50;
        let textBoxCenterY = this.drawer.relativeToAbsoluteY(this.data.centerY);

        if (!this.data.fixedTextBox) {
            textBoxCenterX = this.drawer.relativeToAbsoluteX(this.data.textBoxCenterX);
            textBoxCenterY = this.drawer.relativeToAbsoluteY(this.data.textBoxCenterY);
        }

        const textBox = this.drawer.svgObject.rect(getSize('angleTextBoxW', this.sizeMode) * 1 / this.drawer.zoomLevel, getSize('textBoxH', this.sizeMode) * 1 / this.drawer.zoomLevel).center(textBoxCenterX, textBoxCenterY).fill(this.color);
        const text = this.drawer.svgObject.text(`${Math.round(angle)} °`).font({ size: getSize('text', this.sizeMode) * 1 / this.drawer.zoomLevel }).center(textBoxCenterX, textBoxCenterY).fill('#ffffff');

        const center = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(.01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const pointA = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .25 : .01).center(this.drawer.relativeToAbsoluteX(this.data.pointAX), this.drawer.relativeToAbsoluteY(this.data.pointAY));
        const pointB = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .25 : .01).center(this.drawer.relativeToAbsoluteX(this.data.pointBX), this.drawer.relativeToAbsoluteY(this.data.pointBY));

        this.svgElements.push(centerS);
        this.svgElements.push(pointAS);
        this.svgElements.push(pointBS);
        this.svgElements.push(center);
        this.svgElements.push(pointA);
        this.svgElements.push(pointB);
        this.svgElements.push(pointACenter);
        this.svgElements.push(pointBCenter);
        this.svgElements.push(arcCircle);
        this.svgElements.push(textBox);
        this.svgElements.push(text);

        if (this.editable) {
            interact(center.node)
                .draggable(this.getActionMove())
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(text.node)
                .draggable(this.getActionMoveTextBox())
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(textBox.node)
                .draggable(this.getActionMoveTextBox())
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(pointA.node)
                .draggable(this.getActionResize('a'))
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(pointB.node)
                .draggable(this.getActionResize('b'))
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
        }

    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
