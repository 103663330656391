import {
  Component,
  Input,
  forwardRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

function isValidValue(value: string) {
  return value.match(/^[\dm]{2}:[\ds]{2}$/);
}

/**
 * @param value seconds
 * @returns formatted time string
 */
const formatValue = (value: string | number) => {
  if (typeof value === 'string') {
    return value;
  }
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

/**
 * @param value formatted time string in "mm:ss" format
 * @returns seconds
 */
const getSeconds = (value: string) => {
  const [minutes, seconds] = value.split(':').map(Number);
  if (
    minutes === undefined ||
    seconds === undefined ||
    isNaN(minutes) ||
    isNaN(seconds)
  ) {
    return null;
  }
  return minutes * 60 + seconds;
};

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true,
    },
  ],
})
export class TimeInputComponent implements ControlValueAccessor {
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() validateBeforeChange = false;
  @Input() className = '';
  @Input() customClass = '';
  @Input() showArrows = true;

  cursorPosition = 0;
  internalValue: string | number = 0;
  value: string | number = 0;
  onChange: any = () => {};
  onTouch: any = () => {};

  get valueToRender(): string {
    const formattedValue = formatValue(this.value);
    const validatedValue = isValidValue(formattedValue)
      ? formattedValue
      : 'mm:ss';

    const formattedInternalValue = formatValue(this.internalValue);
    const validatedInternalValue = isValidValue(formattedInternalValue)
      ? formattedInternalValue
      : 'mm:ss';

    return this.validateBeforeChange ? validatedInternalValue : validatedValue;
  }

  changeCursorPosition(newPosition: number) {
    this.cursorPosition = newPosition;
    setTimeout(() => {
      if (newPosition === 5) {
        this.inputElement.nativeElement.setSelectionRange(
          newPosition,
          newPosition,
        );
      } else {
        this.inputElement.nativeElement.setSelectionRange(
          newPosition,
          newPosition + 1,
        );
      }
    }, 0);
  }

  changeValue(newValue: string, nextPosition?: number) {
    if (nextPosition === undefined) {
      console.warn('nextPosition NOT passed');
    }
    if (nextPosition !== undefined) {
      this.changeCursorPosition(nextPosition);
    }
    const seconds = getSeconds(newValue);
    if (this.validateBeforeChange) {
      // Store in internalValue without triggering onChange
      if (seconds !== null) {
        this.internalValue = seconds;
      } else {
        this.internalValue = newValue;
      }
    } else {
      this.internalValue = seconds ?? newValue;
      // Update value for visual feedback, but don't trigger onChange
      this.value = this.internalValue;
    }
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      return;
    }

    if (e.key === 'Escape' || e.key === 'Tab') {
      return;
    }

    if (this.disabled || this.readonly) {
      return;
    }

    if (e.key === 'Backspace') {
      e.preventDefault();
      if (this.cursorPosition === 0) {
        this.changeValue('mm:ss', 0);
        return;
      }

      let newPosition = this.cursorPosition - 1;
      if (newPosition === 2) {
        newPosition = 1;
        this.changeCursorPosition(newPosition);
      }

      const maskLetter = [0, 1].includes(newPosition)
        ? 'm'
        : [3, 4, 5].includes(newPosition)
          ? 's'
          : '';

      const newValue =
        this.valueToRender.slice(0, newPosition) +
        maskLetter +
        this.valueToRender.slice(newPosition === 1 ? 2 : this.cursorPosition);
      this.changeValue(newValue, newPosition);
      return;
    }

    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      const newPosition = Math.max(0, this.cursorPosition - 1);
      // skip colon
      if (newPosition === 2) {
        this.changeCursorPosition(1);
      } else {
        this.changeCursorPosition(newPosition);
      }
      return;
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      const newPosition = Math.min(
        this.valueToRender.length,
        this.cursorPosition + 1,
      );
      // skip colon
      if (newPosition === 2) {
        this.changeCursorPosition(3);
      } else {
        this.changeCursorPosition(newPosition);
      }
      return;
    }

    // if key is number, add it in the current position
    if (e.key.match(/^\d$/)) {
      e.preventDefault();
      if (this.cursorPosition >= 5) {
        return;
      }

      let key = e.key;
      if (
        (this.cursorPosition === 0 || this.cursorPosition === 3) &&
        Number(key) >= 6
      ) {
        key = '5';
      }

      const newValue =
        this.valueToRender.slice(0, this.cursorPosition) +
        key +
        this.valueToRender.slice(this.cursorPosition + 1);

      let newPosition = this.cursorPosition + 1;
      if (newPosition > 4) {
        newPosition = 5;
      }
      if (newPosition === 2) {
        newPosition = 3;
      }
      this.changeValue(newValue, newPosition);
    }
  }

  handleFocus() {
    this.changeCursorPosition(0);
  }

  handleBlur(e: FocusEvent) {
    const input = e.target as HTMLInputElement;
    const newCursorPosition = input.selectionStart ?? 0;
    this.changeCursorPosition(newCursorPosition);

    // Trigger changes on blur
    const seconds = getSeconds(formatValue(this.internalValue));
    if (seconds !== null) {
      this.value = seconds;
      this.onChange(seconds.toString());
    } else {
      this.value = 0;
      this.onChange('0');
    }
    this.onTouch();
  }

  adjustTime(increment: boolean) {
    if (this.disabled || this.readonly) return;

    const currentSeconds =
      typeof this.value === 'string' ? getSeconds(this.value) ?? 0 : this.value;

    const newSeconds = Math.max(0, currentSeconds + (increment ? 5 : -5));

    // Update both internal and actual value since this is a direct user action
    this.internalValue = newSeconds;
    this.value = newSeconds;
    this.onChange(newSeconds.toString());
    this.onTouch();
  }

  // ControlValueAccessor implementation
  writeValue(value: any): void {
    // Convert incoming string to seconds if needed
    if (typeof value === 'string' && value.includes(':')) {
      const seconds = getSeconds(value);
      if (seconds !== null) {
        this.value = seconds;
        this.internalValue = seconds;
        return;
      }
    }

    this.value = value;
    this.internalValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
