import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  combineLatest,
  Subject,
  lastValueFrom,
} from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { LoadingState } from 'src/app/utils/utils';

type LinkStatus = 'linked' | 'unlinked';

@Component({
  selector: 'app-join-professional',
  templateUrl: './join-professional.component.html',
})
export class JoinProfessionalComponent implements OnInit, OnDestroy {
  professionalId$: Observable<string | null>;
  professional$: Observable<User | null>;
  professional: User | null = null;
  linkStatus$: Observable<LinkStatus>;
  linkStatus: LinkStatus = 'unlinked';
  pageLoaded: LoadingState = 'loading';
  acceptInvitationState: LoadingState = 'idle';
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private userClientService: UserClientService,
    private snackBarService: SnackbarService,
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.professionalId$ = this.route.queryParamMap.pipe(
      map((params) => params.get('professionalId')),
    );

    this.professional$ = this.professionalId$.pipe(
      switchMap((professionalId) => {
        return professionalId
          ? this.userClientService.getProfessionalById$(professionalId)
          : of(null);
      }),
      tap((professional) => {
        this.professional = professional;
      }),
    );

    this.linkStatus$ = this.userClientService.userClient$.pipe(
      switchMap((userClient) => {
        if (!userClient?.owner) {
          return of<LinkStatus>('unlinked');
        }
        return this.userClientService
          .getProfessionalById$(userClient.owner)
          .pipe(map((professional) => (professional ? 'linked' : 'unlinked')));
      }),
      tap((linkStatus) => {
        this.linkStatus = linkStatus;
      }),
    );

    combineLatest([this.professionalId$, this.professional$, this.linkStatus$])
      .pipe(
        takeUntil(this.destroy$),
        map(([professionalId, professional, linkStatus]) => ({
          professionalId,
          professional,
          linkStatus,
        })),
      )
      .subscribe({
        next: (data) => {
          // All observables have emitted values
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async acceptInvitation() {
    this.acceptInvitationState = 'loading';
    try {
      const professional = await firstValueFrom(this.professional$);
      if (!professional) {
        this.snackBarService.error('No se ha podido encontrar el entrenador');
        return;
      }
      await this.userClientService.createOrJoinClient(professional);
      await firstValueFrom(this.linkStatus$); // await this to avoid show the Accept UI again
    } catch (error) {
      console.error('Error accepting invitation', error);
      this.snackBarService.error('Error al aceptar la invitación');
    } finally {
      await this.authService.refreshIdTokenWithCustomClaims();
      this.acceptInvitationState = 'idle';
    }
  }

  async rejectInvitation() {
    await this.navigationService.removeQueryParam('professionalId');
    this.snackBarService.info('Invitación rechazada');
  }
}
