import Stripe from "stripe";

type SubscriptionStatus = Stripe.Subscription.Status;
type StripeProduct = Stripe.Product;
type StripePrice = Stripe.Price;
type StripeData = {
  products: StripeProduct[];
  prices: StripePrice[];
};

export const ACTIVE_SUBSCRIPTION_STATUSES = ["active", "trialing", "past_due"];

export interface InteccSubscription {
  productId: string;
  currentPeriodEnd: number;
  status: SubscriptionStatus;
}

export const NEW_SUBSCRIPTION_SYSTEM_START_SECONDS_TIMESTAMP =
  new Date("2024-09-23").getTime() / 1000;

// tiene que coincidir con los nombres de app.config.ts
const ParentProductIds = {
  Raquis: "raquis",
  Rom: "rom",
  VAF: "vaf",
};

const ActionIds = {
  CreateClient: "create_client",

  RomViewTests: "rom_view_tests",
  RomCreateTest: "rom_create_test",
  RomExportPdf: "rom_export_pdf",
  RomExportPdfCustom: "rom_export_pdf_custom",
  RomExportCsv: "rom_export_csv",

  RaquisViewTests: "raquis_view_tests",
  RaquisCreateTest: "raquis_create_test",
  RaquisExportPdf: "raquis_export_pdf",
  RaquisExportPdfCustom: "raquis_export_pdf_custom",
  RaquisExportCsv: "raquis_export_csv",

  VAFViewTests: "vaf_view_tests",
  VAFCreateTest: "vaf_create_test",
  VAFCreateRaquis: "vaf_create_test_raquis",
  VAFExportPdf: "vaf_export_pdf",
  VAFExportPdfCustom: "vaf_export_pdf_custom",
  VAFUseMyVAF: "vaf_use_myvaf",

  InteccUseTrainingSessions: "intecc_use_training_sessions",
  InteccUseCorrectiveSessions: "intecc_use_corrective_sessions",
  InteccUseTrainingAvatar: "intecc_use_training_avatar",
} as const;

type ActionId = (typeof ActionIds)[keyof typeof ActionIds];

const ProductIds = {
  RaquisStart: "raquis_start",
  RaquisPro: "raquis_pro",
  RaquisPremium: "raquis_premium",
  RomStart: "rom_start",
  RomPremium: "rom_pro",
  RomPro: "rom_premium",
  VAFStart: "vaf_start",
  VAFPro: "vaf_pro",
  VAFPremium: "vaf_premium",
};

const PriceIds = {
  NoSub: "no_sub",

  // NOTE: the commented code didn't make sense as it was never evaluated
  RaquisStart: "plan_GhxhNMHKdLuDCL", // || 'raquis_start',
  RaquisPro: "plan_Gi3hN1nqDbhQSH", // || 'raquis_pro',
  RaquisProOffer: "plan_GYG0UTkXuK9RIL", // || 'raquis_pro_offer',
  RaquisProFree: "raquis_pro_free",
  RaquisPremium: "plan_Gi3eFm3WT6uzsf", // || 'raquis_premium',
  RaquisPremiumOffer: "plan_GhxjW4JbtOj0ht", // || 'raquis_premium_offer',
  RaquisPremiumFree: "plan_Gi3Uy7IYRYssFI", // || 'raquis_premium_free',

  RomStart: "rom_start",
  RomStartFree: "rom_start_free",
  RomPro: "rom_pro",
  RomProOffer: "rom_pro_offer",
  RomProFree: "rom_pro_free",
  RomPremium: "rom_premium",
  RomPremiumOffer: "rom_premium_offer",
  RomPremiumFree: "rom_premium_free",

  InteccPremium: "intecc_premium",

  VAFStart: "vaf_start",
  VAFStartOffer: "vaf_start_offer",
  VAFStartFree: "vaf_start_free",
  VAFPro: "vaf_pro",
  VAFProOffer: "vaf_pro_offer",
  VAFProFree: "vaf_pro_free",
  VAFPremium: "vaf_premium",
  VAFPremiumOffer: "vaf_premium_offer",
  VAFPremiumFree: "vaf_premium_free",
  VAFTrial: "vaf_trial",

  NewVAFStart: "new_vaf_start",
  NewVAFPro: "new_vaf_pro",
  NewVAFPremium: "new_vaf_premium",
};

interface Action {
  id: string;
  limit?: number;
}

interface Product {
  id: string;
  name: string;
  capabilities: Array<Action>;
  parentProductIds: Array<string>;
}

interface Price {
  id: string;
  name: string;
  productIds: Array<string>;
  pricing: string;
  offer?: string;
}

const Products: { [id: string]: Product } = {
  [ProductIds.RaquisStart]: {
    id: ProductIds.RaquisStart,
    parentProductIds: [ParentProductIds.Raquis],
    name: "Raquis Start",
    capabilities: [
      { id: ActionIds.CreateClient, limit: 5 },
      { id: ActionIds.RaquisCreateTest, limit: 5 },
      { id: ActionIds.RaquisExportPdf, limit: 5 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [ProductIds.RaquisPro]: {
    id: ProductIds.RaquisPro,
    parentProductIds: [ParentProductIds.Raquis],
    name: "Raquis Pro",
    capabilities: [
      { id: ActionIds.CreateClient, limit: 20 },
      { id: ActionIds.RaquisCreateTest, limit: 20 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisExportCsv },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [ProductIds.RaquisPremium]: {
    id: ProductIds.RaquisPremium,
    parentProductIds: [ParentProductIds.Raquis],
    name: "Raquis Premium",
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisExportCsv },
      { id: ActionIds.RaquisExportPdfCustom },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [ProductIds.RomStart]: {
    id: ProductIds.RomStart,
    parentProductIds: [ParentProductIds.Rom],
    name: "Rom Start",
    capabilities: [
      { id: ActionIds.CreateClient, limit: 5 },
      { id: ActionIds.RomCreateTest, limit: 5 },
      { id: ActionIds.RomExportPdf, limit: 5 },
      { id: ActionIds.RomViewTests },
    ],
  },
  [ProductIds.RomPro]: {
    id: ProductIds.RomPro,
    parentProductIds: [ParentProductIds.Rom],
    name: "Rom Pro",
    capabilities: [
      { id: ActionIds.CreateClient, limit: 20 },
      { id: ActionIds.RomCreateTest, limit: 20 },
      { id: ActionIds.RomExportPdf, limit: -1 },
      { id: ActionIds.RomExportCsv },
      { id: ActionIds.RomViewTests },
    ],
  },
  [ProductIds.RomPremium]: {
    id: ProductIds.RomPremium,
    parentProductIds: [ParentProductIds.Rom],
    name: "Rom Premium",
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.RomCreateTest, limit: -1 },
      { id: ActionIds.RomExportPdf, limit: -1 },
      { id: ActionIds.RomExportCsv },
      { id: ActionIds.RomExportPdfCustom },
      { id: ActionIds.RomViewTests },
    ],
  },
  [ProductIds.VAFStart]: {
    id: ProductIds.VAFStart,
    parentProductIds: [ParentProductIds.VAF],
    name: "VAF Start",
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.VAFViewTests },
      { id: ActionIds.VAFCreateRaquis, limit: -1 },
      { id: ActionIds.VAFExportPdf, limit: -1 },
      { id: ActionIds.VAFExportPdfCustom },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [ProductIds.VAFPro]: {
    id: ProductIds.VAFPro,
    parentProductIds: [ParentProductIds.VAF],
    name: "VAF Pro",
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.VAFViewTests },
      { id: ActionIds.VAFCreateRaquis, limit: -1 },
      { id: ActionIds.VAFCreateTest, limit: -1 },
      { id: ActionIds.VAFExportPdf, limit: -1 },
      { id: ActionIds.VAFExportPdfCustom },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [ProductIds.VAFPremium]: {
    id: ProductIds.VAFPremium,
    parentProductIds: [ParentProductIds.VAF],
    name: "VAF Premium",
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.VAFViewTests },
      { id: ActionIds.VAFCreateRaquis, limit: -1 },
      { id: ActionIds.VAFCreateTest, limit: -1 },
      { id: ActionIds.VAFExportPdf, limit: -1 },
      { id: ActionIds.VAFExportPdfCustom },
      { id: ActionIds.VAFUseMyVAF },
      { id: ActionIds.InteccUseCorrectiveSessions },
      { id: ActionIds.InteccUseTrainingSessions },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
};

const Prices: { [id: string]: Price } = {
  [PriceIds.RaquisStart]: {
    id: PriceIds.RaquisStart,
    name: "Raquis Start",
    productIds: [ProductIds.RaquisStart],
    pricing: "1€/mes",
  },

  [PriceIds.RaquisPro]: {
    id: PriceIds.RaquisPro,
    name: "Raquis Pro",
    productIds: [ProductIds.RaquisPro],
    pricing: "19.99€/mes",
  },
  [PriceIds.RaquisProOffer]: {
    id: PriceIds.RaquisPro,
    name: "Raquis Pro Offer",
    productIds: [ProductIds.RaquisPro],
    pricing: "9.99€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.RaquisProFree]: {
    id: PriceIds.RaquisProFree,
    name: "Raquis Pro Free",
    productIds: [ProductIds.RaquisPro],
    pricing: "0€/mes",
    offer: "Acceso Gratuito",
  },

  [PriceIds.RaquisPremium]: {
    id: PriceIds.RaquisPremium,
    name: "Raquis Premium",
    productIds: [ProductIds.RaquisPremium],
    pricing: "58€/mes",
  },
  [PriceIds.RaquisPremiumOffer]: {
    id: PriceIds.RaquisPremiumOffer,
    name: "Raquis Premium Offer",
    productIds: [ProductIds.RaquisPremium],
    pricing: "29€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.RaquisPremiumFree]: {
    id: PriceIds.RaquisPremiumFree,
    name: "Raquis Premium Free",
    productIds: [ProductIds.RaquisPremium],
    pricing: "0€/mes",
    offer: "Acceso Gratuito",
  },

  [PriceIds.RomStart]: {
    id: PriceIds.RomStart,
    name: "Rom Start",
    productIds: [ProductIds.RomStart],
    pricing: "1€/mes",
  },
  [PriceIds.RomStartFree]: {
    id: PriceIds.RomStartFree,
    name: "Rom Start Free",
    productIds: [ProductIds.RomStart],
    pricing: "0€/mes",
    offer: "Oferta de lanzamiento",
  },

  [PriceIds.RomPro]: {
    id: PriceIds.RomPro,
    name: "Rom Pro",
    productIds: [ProductIds.RomPro],
    pricing: "19.98€/mes",
  },
  [PriceIds.RomProOffer]: {
    id: PriceIds.RomProOffer,
    name: "Rom Pro Offer",
    productIds: [ProductIds.RomPro],
    pricing: "9.99€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.RomProFree]: {
    id: PriceIds.RomProFree,
    name: "Rom Pro Free",
    productIds: [ProductIds.RomPro],
    pricing: "0€/mes",
    offer: "Acceso gratuito",
  },

  [PriceIds.RomPremium]: {
    id: PriceIds.RomPremium,
    name: "Rom Premium",
    productIds: [ProductIds.RomPremium],
    pricing: "58€/mes",
  },
  [PriceIds.RomPremiumOffer]: {
    id: PriceIds.RomPremiumOffer,
    name: "Rom Premium Offer",
    productIds: [ProductIds.RomPremium],
    pricing: "29€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.RomPremiumFree]: {
    id: PriceIds.RomPremiumFree,
    name: "Rom Premium Free",
    productIds: [ProductIds.RomPremium],
    pricing: "0€/mes",
    offer: "Acceso gratuito",
  },

  [PriceIds.VAFStart]: {
    id: PriceIds.VAFStart,
    name: "VAF Start",
    productIds: [ProductIds.VAFStart],
    pricing: "1€/mes",
  },
  [PriceIds.NewVAFStart]: {
    id: PriceIds.NewVAFStart,
    name: "VAF Start",
    productIds: [ProductIds.VAFStart],
    pricing: "1€/mes",
  },
  [PriceIds.VAFStartFree]: {
    id: PriceIds.VAFStartFree,
    name: "VAF Start Free",
    productIds: [ProductIds.VAFStart],
    pricing: "0€/mes",
    offer: "Oferta de lanzamiento",
  },

  [PriceIds.VAFPro]: {
    id: PriceIds.VAFPro,
    name: "VAF Pro",
    productIds: [ProductIds.VAFPro],
    pricing: "19.98€/mes",
  },
  [PriceIds.NewVAFPro]: {
    id: PriceIds.NewVAFPro,
    name: "VAF Pro",
    productIds: [ProductIds.VAFPro],
    pricing: "19.98€/mes",
  },
  [PriceIds.VAFProOffer]: {
    id: PriceIds.VAFProOffer,
    name: "VAF Pro Offer",
    productIds: [ProductIds.VAFPro],
    pricing: "9.99€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.VAFProFree]: {
    id: PriceIds.VAFProFree,
    name: "VAF Pro Free",
    productIds: [ProductIds.VAFPro],
    pricing: "0€/mes",
    offer: "Acceso gratuito",
  },

  [PriceIds.VAFPremium]: {
    id: PriceIds.VAFPremium,
    name: "VAF Premium",
    productIds: [ProductIds.VAFPremium],
    pricing: "19.98€/mes",
  },
  [PriceIds.NewVAFPremium]: {
    id: PriceIds.NewVAFPremium,
    name: "VAF Premium",
    productIds: [ProductIds.VAFPremium],
    pricing: "19.98€/mes",
  },
  [PriceIds.VAFPremiumOffer]: {
    id: PriceIds.VAFPremiumOffer,
    name: "VAF Premium Offer",
    productIds: [ProductIds.VAFPremium],
    pricing: "9.99€/mes",
    offer: "Oferta de lanzamiento",
  },
  [PriceIds.VAFPremiumFree]: {
    id: PriceIds.VAFPremiumFree,
    name: "VAF Premium Free",
    productIds: [ProductIds.VAFPremium],
    pricing: "0€/mes",
    offer: "Acceso gratuito",
  },
  [PriceIds.VAFTrial]: {
    id: PriceIds.VAFPremiumFree,
    name: "VAF Premium Trial",
    productIds: [ProductIds.VAFPremium],
    pricing: "0€/mes",
    offer: "Periodo de prueba",
  },
};

function hasPermission(
  priceIds: Array<string>,
  actionId: string,
  limit?: number
) {
  if (!priceIds) {
    return false;
  }
  const prices = priceIds.map((priceId) => Prices[priceId]);
  const products = prices.map((price) => Products[price.productIds[0]]);
  const emptyActionArray: Array<Action> = [];
  const capabilities = products.reduce((prev, curr) => {
    return prev.concat(curr.capabilities);
  }, emptyActionArray);
  const capability = capabilities.find((c) => c.id === actionId);
  if (capability === undefined) {
    return false;
  }
  if (capability.limit !== undefined && capability.limit !== -1) {
    if (limit === undefined) {
      return false;
    }
    if (limit >= capability.limit) {
      return false;
    }
  }
  return true;
}

function getActiveProducts(priceIds: Array<string>) {
  if (!priceIds) {
    return [];
  }
  const prices = priceIds.map((priceId) => Prices[priceId]);
  const products = prices.map((price) => Products[price.productIds[0]]);
  return products;
}

function isPriceFromApp(priceId: string, appName: string) {
  const price = Prices[priceId];
  const products = price.productIds.map((pId) => Products[pId]);
  const parentProductsIds = products
    .map((p) => p.parentProductIds)
    .reduce((prev, curr) => prev.concat(curr), []);
  const result = parentProductsIds.some((p2) => p2 === appName);
  return result;
}

export {
  SubscriptionStatus,
  ParentProductIds,
  ActionIds,
  ActionId,
  StripeProduct,
  StripePrice,
  StripeData,
  ProductIds,
  PriceIds,
  Action,
  Product,
  Price,
  Products,
  Prices,
  hasPermission,
  getActiveProducts,
  isPriceFromApp,
};
