import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { centerOfSegment } from 'src/app/utils/math';
import { getSize } from './media-config.class';

export interface TextData {
    centerX: number;
    centerY: number;
    text?: string;
}

export class Text extends EditItem {
    type = 'text';
    data: TextData;

    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;
    bLineWidth = 5;
    textboxH = 30;
    textboxW = 50;
    textboxMarginBottom = 30;
    textColor = '#ffffff';
    textBoxMargin = 10;

    static create(centerX: number, centerY: number) {
        const data: TextData = {
            centerX,
            centerY
        };
        return new Text(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.centerX = this.data.centerX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.centerY = this.data.centerY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
                // this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                // this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
            }
        };
    }

    getActionResize(direction: 'a' | 'b'): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'a') {
                    // this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    // this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'b') {
                    // this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    // this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    getText() {
        return this.data.text ? `${this.data.text}` : 'Click para editar';
    }

    calculateWidth() {

    }

    updateRefValue(value: string) {
        if (value === '') {
            this.data.text = undefined;
        } else {
            this.data.text = value;
        }
        this.update();
    }

    draw() {

        let text = this.drawer.svgObject.text(this.getText()).font({size: getSize('text', this.sizeMode)  * 1 / this.drawer.zoomLevel}).center(
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.textColor);

        const textWidth = text.node.getBoundingClientRect().width;
        console.log('textWidth', textWidth);

        const textBox = this.drawer.svgObject.rect((textWidth + this.textBoxMargin * 2)  * 1 / this.drawer.zoomLevel, getSize('textBoxH', this.sizeMode)  * 1 / this.drawer.zoomLevel).center(
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color);

        text.remove();

        text = this.drawer.svgObject.text(this.getText()).font({size: getSize('text', this.sizeMode)  * 1 / this.drawer.zoomLevel}).center(
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.textColor);

        // this.svgElements.push(pointAS);
        // this.svgElements.push(pointBS);
        // this.svgElements.push(pointA);
        // this.svgElements.push(pointB);
        // this.svgElements.push(lineAB);
        // this.svgElements.push(lineABWide);
        this.svgElements.push(textBox);
        this.svgElements.push(text);
        this.svgElements.push();

        if (this.editable) {
            interact(textBox.node)
                .draggable(
                    this.getActionMove()
                );

            interact(text.node)
                .draggable(
                    this.getActionMove()
                )
                .on('tap', (event) => {
                    this.drawer.getInput('Introduce el texto', `${this.data.text ? this.data.text : ''}`, 'Texto', (value) => {
                        this.updateRefValue(value);
                    });
                });
        }

    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
