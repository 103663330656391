import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  picker: any;

  constructor() {
    // this.picker = new MaterialDateTimePicker()
    // .on('submit', (val) => console.log(`data: ${val}`))
    // .on('open', () => console.log('opened'))
    // .on('close', () => console.log('closed'));
  }

  open() {
    this.picker.open();
  }
}
