import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestFieldType } from 'src/shared/test.outside';

@Component({
    selector: 'app-test-field-dropdown',
    templateUrl: './test-field-dropdown.component.html',
    styleUrls: ['./test-field-dropdown.component.scss']
})
export class TestFieldDropdownComponent {

    isOpen = false;

    @Input() options = ['Option 1', 'Option 2', 'Option 3'];

    @Input() selectedIndex = -1;

    @Input() text = '';

    @Input() openToTop = true;

    @Input() mode: TestFieldType = 'select';

    @Output() selectedItemChanged = new EventEmitter<number>();

    @Output() textChanged = new EventEmitter<string>();

    @Input() tableMode = false;

    @Input() isEditable = true;

    @Input() addStyles = '';

    @Input() theme = 'normal';

    constructor() { }

    open() {
        this.isOpen = true;
    }

    selectOption(index: number) {
        this.selectedIndex = index;
        this.isOpen = false;
        this.selectedItemChanged.next(index);
    }

    onTextChanged(event) {
        console.log('text changed');
        const text = event.target.value;
        this.textChanged.next(text);
    }

}
