import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() fieldName: string;
  @Input() type: 'input' | 'textarea' = 'input';
  @Input() readonly = false;
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() buttonPosition: 'start' | 'end' = 'end';
  @Input() button: { action: () => void; faClass: string } = null;

  constructor() {}

  onValueChange(newValue) {
    this.valueChange.next(newValue);
  }
}
