import { Svg, Element } from '@svgdotjs/svg.js';
import { Drawer } from './drawer.interface';

export abstract class EditItem {
    index: number;
    type: string;
    editable: boolean;
    data: any;
    drawer: Drawer;
    selected = false;
    color = '#0063DC';
    svgElements: Array<Element> = [];
    sizeMode: 's' | 'm' | 'l' = 'm';

    static fromEditItemDB(item: EditItemBD) {

    }

    abstract draw(): void;

    constructor(data: any) {
        this.data = data;
    }

    init(drawer: Drawer, index: number, editable = true) {
        this.drawer = drawer;
        this.index = index;
        this.editable = editable;
    }

    setSelected(value: boolean) {
        if (value !== this.selected) {
            this.selected = value;
            this.update();
        }
    }

    update() {
        this.undraw();
        this.draw();
    }

    undraw() {
        this.svgElements.forEach(element => {
            element.remove();
        });
    }

    toEditItemBD(): EditItemBD {
        const result: EditItemBD = {type: this.type, data: JSON.stringify(this.data)};
        return result;
    }
}

export interface EditItemBD {
    type: string;
    data: string;
}
