import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  combineLatest,
  map,
  Observable,
  switchMap,
  shareReplay,
  Subject,
  of,
} from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ListItem } from 'src/app/items-list/items-list.component';
import { Client } from 'src/app/models/client.model';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportsService } from 'src/app/services/reports.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { Test } from 'src/shared/test.outside';
import { ClienLevelCategory } from '../../../../../Server/functions/src/shared/training-avatar.shared';
import { ModalService } from 'src/app/services/modal.service';
import {
  ClientTrainingSession,
  TrainingSessionProtocolDef,
} from 'src/shared/training-session-definition.outside';
import { TrainingSessionsService } from 'src/app/services/training-sessions.service';
import { MyvafService } from 'src/app/services/myvaf.service';
import { MyVafProtocolDef } from 'src/shared/myvaf.outside';
import { LoadingState } from 'src/app/utils/utils';
import { SubscriptionType } from 'src/shared/subscriptions.shared';

@Component({
  selector: 'app-client-training',
  templateUrl: './client-training.component.html',
})
export class ClientTrainingComponent implements OnInit, OnDestroy {
  userClient$: Observable<Client>;
  userClient: Client | null = null;
  professionalSubscriptionType$: Observable<SubscriptionType>;
  generatingReport = false;

  clientLevelCategory: ClienLevelCategory;
  clientLevel: string;

  trainingSessionsProtocolDefs$: Observable<TrainingSessionProtocolDef[]>;
  clientTrainingSessionsList$: Observable<ListItem<ClientTrainingSession>[]>;

  myVafProtocolDefs$: Observable<MyVafProtocolDef[]>;
  myVafTestsList$: Observable<ListItem<Test>[]>;

  pageLoaded: LoadingState = 'loading';

  private destroy$ = new Subject<void>();

  constructor(
    private reportsServices: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private snackbarService: SnackbarService,
    private trainingSessionsService: TrainingSessionsService,
    private myVafService: MyvafService,
    private userClientService: UserClientService,
    private auth: AuthService,
    private modalService: ModalService,
  ) {
    this.generateReportClientTrainingSessions =
      this.generateReportClientTrainingSessions.bind(this);

    this.generateReportMyVaf = this.generateReportMyVaf.bind(this);

    this.userClient$ = this.auth.user$.pipe(
      switchMap(() => this.userClientService.userClient$),
      tap((userClient) => {
        this.userClient = userClient;
      }),
      shareReplay(1),
    );

    this.professionalSubscriptionType$ =
      this.userClientService.professionalSubscriptionType$;

    // subscriptions for training avatar
    this.userClientService.currentClientLevelCategory$.subscribe(
      (levelCategory) => {
        this.clientLevelCategory = levelCategory;
      },
    );
    this.userClientService.currentClientLevel$.subscribe((level) => {
      this.clientLevel = level;
    });

    // subscriptions for training sessions
    this.trainingSessionsProtocolDefs$ = this.userClient$.pipe(
      switchMap((userClient) => {
        if (!userClient) {
          return of([]);
        }
        return this.trainingSessionsService.getTrainingSessionProtocols$(
          userClient.owner,
        );
      }),
    );
    this.clientTrainingSessionsList$ = combineLatest([
      this.userClientService.userClientTrainingSessions$,
      this.trainingSessionsProtocolDefs$,
    ]).pipe(
      map(([sessions, protocolDefs]) => {
        return sessions.map((session) => {
          return {
            ...session,
            faIcon: 'fas fa-dumbbell',
            id: session.id,
            name: this.getTrainingSessionProtocolName(
              session.trainingSessionProtocolDefId,
              protocolDefs,
            ),
            url: `/c/client-training-session/${session.id}`,
            createdAt: session.createdAt,
          } satisfies ListItem<ClientTrainingSession>;
        });
      }),
    );

    // subscriptions for myvaf protocols
    this.myVafProtocolDefs$ = this.userClient$.pipe(
      switchMap((userClient) => {
        if (!userClient) {
          return of([]);
        }
        return this.myVafService.getProtocols$(userClient.owner);
      }),
    );

    this.myVafTestsList$ = combineLatest([
      this.userClientService.userClientMyVafTests$,
      this.myVafProtocolDefs$,
    ]).pipe(
      map(([tests, myVafProtocolDefs]) => {
        return tests
          .map((item) => {
            const type = this.getMyVafProtocolTestType(
              item.protocolDefId,
              myVafProtocolDefs,
            );
            // item['icon-to-show'] =
            //   type === 'table' ? 'fas fa-dumbbell' : 'far fa-file-alt';
            // const faIcon =
            //   type === 'table' ? 'fas fa-dumbbell' : 'far fa-file-alt';
            item['icon-to-show'] = 'fa-solid fa-list-check';
            const faIcon = 'fa-solid fa-list-check';

            return {
              ...item,
              id: item.id,
              faIcon,
              url: `/c/tests/${item.id}`,
              name: this.getMyVafProtocolTestName(
                item.protocolDefId,
                myVafProtocolDefs,
              ),
              createdAt: item.createdAt,
            };
          })
          .sort((a, b) => {
            if (a.name === b.name) {
              return 0;
            } else if (a.name > b.name) {
              return 1;
            } else {
              return -1;
            }
          });
      }),
    );
  }

  ngOnInit() {
    combineLatest([
      this.userClient$,
      this.userClientService.currentClientLevelCategory$,
      this.userClientService.currentClientLevel$,
      this.professionalSubscriptionType$,
      this.trainingSessionsProtocolDefs$,
      this.clientTrainingSessionsList$,
      this.myVafProtocolDefs$,
      this.myVafTestsList$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([
            userClient,
            clientLevelCategory,
            clientLevel,
            professionalSubscriptionType,
            trainingSessionsProtocolDefs,
            clientTrainingSessionsList,
            myVafProtocolDefs,
            myVafTestsList,
          ]) => ({
            userClient,
            clientLevelCategory,
            clientLevel,
            professionalSubscriptionType,
            trainingSessionsProtocolDefs,
            clientTrainingSessionsList,
            myVafProtocolDefs,
            myVafTestsList,
          }),
        ),
      )
      .subscribe({
        next: (data) => {
          // All observables have emitted values
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async generateReport(items: Map<string, Test>) {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([...items.values()]);
    } catch (e) {
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }

  // functions for training avatar
  async openTrainingAvatarModal() {
    await this.modalService.openLateralModal(
      'training-avatar',
      {
        isUserClientView: true,
      },
      {
        slideConfig: 'right-small',
      },
    );
  }

  // functions for training sessions
  async generateReportClientTrainingSessions(
    clientTrainingSessions: Map<string, Test>, // actually, is a ClientTrainingSession not a Test, but is valid for this case to keep ts happy
  ) {
    await this.generateReport(clientTrainingSessions);
  }

  getTrainingSessionProtocolName(
    protocolId: string,
    trainingSessionProtocolDefs: TrainingSessionProtocolDef[],
  ) {
    const result = trainingSessionProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo de sesión de entrenamiento eliminado';
    } else {
      return result.name;
    }
  }

  // functions for myvaf protocols

  async generateReportMyVaf(myVafTests: Map<string, Test>) {
    await this.generateReport(myVafTests);
  }

  getMyVafProtocolTestName(
    protocolId: string,
    myVafProtocolDefs: MyVafProtocolDef[],
  ) {
    const result = myVafProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo eliminado';
    } else {
      return result.name;
    }
  }

  // is first test table or fileds?
  getMyVafProtocolTestType(
    protocolId: string,
    myVafProtocolDefs: MyVafProtocolDef[],
  ) {
    const result = myVafProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result || !result.tests || result.tests.length === 0) {
      return 'Protocolo eliminado';
    } else {
      return result.tests[0].mode;
    }
  }

  // joinRandomProfessional() {
  //   this.userClientService.joinRandomProfessional();
  // }
}
