import { EditItemBD } from './edit-items.model';

export const EDITED_MEDIA_ITEM_PATH = 'edited-media';
export interface EditedMediaItem {
    id?: string;
    owner?: string;
    client?: string;
    originalId?: string;
    edits?: Array<EditItemBD>;
    crop?: EditItemBD;
    downloadURL?: string;
    downloadURLMap?: any;
}
