<div class="avatar-status-item-hover-master cursor-pointer">
  <p class="mb-1 text-lg font-bold">{{ title }}</p>
  <div
    class="avatar-status-item-hover-slave flex justify-start transition-all duration-200"
  >
    <div
      *ngIf="status === 'good'"
      class="flex w-auto items-center rounded-full bg-emerald-200 px-4 py-1 text-xs font-bold text-emerald-800"
    >
      <p class="mr-2">{{ value }}</p>
      <i class="fas fa-check"></i>
    </div>
    <div
      *ngIf="status === 'bad'"
      class="flex w-auto items-center rounded-full bg-amber-100 px-4 py-1 text-xs font-bold text-amber-800"
    >
      <p class="mr-2">{{ value }}</p>
      <i class="fas fas fa-exclamation-triangle"></i>
    </div>
    <div
      *ngIf="status === 'neutral'"
      class="flex w-auto items-center rounded-full bg-inteccLight px-4 py-1 text-xs font-bold text-neutral-500 opacity-80"
    >
      <p class="mr-2">{{ value }}</p>
      <i class="far fa-circle"></i>
    </div>
  </div>
</div>
