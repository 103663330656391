import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalHostDirective } from 'src/app/directives/modal-host.directive';
import { Modal } from 'src/app/models/modal.model';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { Modals } from 'src/app/static/modals.static';

@Component({
  selector: 'app-bottom-modal',
  templateUrl: './bottom-modal.component.html',
  styleUrls: ['./bottom-modal.component.scss']
})
export class BottomModalComponent {
    onClose = new Subject<any>();

    @ViewChild(ModalHostDirective, { static: true }) modalHost: ModalHostDirective;

    isOpen = false;

    sideFrom: 'right' | 'bottom';

    constructor(private overrideBackButton: OverrideBackButtonService) { }

    openModal(modalId: string, data: any) {
        const modalComponent: Type<any> = Modals[modalId];
        const viewContainerRef = this.modalHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(modalComponent);
        const componentInstance: Modal = componentRef.instance;
        componentInstance.data = data;
        this.onClose = componentInstance.onClose;
        this.onClose.pipe(take(1)).subscribe(result => {
            this.isOpen = false;
            // viewContainerRef.clear();
        });
        this.isOpen = true;
        this.overrideBackButton.override(() => componentInstance.historyBackClose());
    }

    onBackdropClick(event) {
        if (event.target !== event.currentTarget) {
            return;
        }
        history.back();
    }
}
