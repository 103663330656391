import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { centerOfSegment } from 'src/app/utils/math';

export interface RectEmptyData {
    centerX: number;
    centerY: number;
    width: number;
    height: number;
}

export class RectEmpty extends EditItem {
    type = 'rect-empty';
    data: RectEmptyData;

    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;
    bLineWidth = 5;
    textboxH = 30;
    textboxW = 50;
    textboxMarginBottom = 30;
    textColor = '#ffffff';
    textBoxMargin = 10;

    static create(centerX: number, centerY: number, width: number, height: number) {
        const data: RectEmptyData = {
            centerX,
            centerY,
            width,
            height
        };
        return new RectEmpty(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.centerX = this.data.centerX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.centerY = this.data.centerY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
                // this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                // this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
            }
        };
    }

    getActionResize(direction: 'a' | 'b' | 'c' | 'd'): DraggableOptions {

        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                console.log('resize');
                if (direction === 'a') {
                    // this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    // this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                    this.data.width = this.data.width - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.height = this.data.height - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'b') {
                    this.data.width = this.data.width + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.height = this.data.height - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'c') {
                    this.data.width = this.data.width - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.height = this.data.height + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'd') {
                    this.data.width = this.data.width + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.height = this.data.height + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    draw() {

        const rect = this.drawer.svgObject.rect(
            this.drawer.relativeToAbsoluteW(this.data.width),
            this.drawer.relativeToAbsoluteH(this.data.height))
            .center(
                this.drawer.relativeToAbsoluteX(this.data.centerX),
                this.drawer.relativeToAbsoluteY(this.data.centerY)).stroke({color: this.color, width: this.lineWidth}).fill('transparent');

        const halfW = this.drawer.relativeToAbsoluteW(this.data.width) / 2;
        const halfH = this.drawer.relativeToAbsoluteH(this.data.height) / 2;
        const absoluteCenterX = this.drawer.relativeToAbsoluteX(this.data.centerX);
        const absoluteCenterY = this.drawer.relativeToAbsoluteY(this.data.centerY);

        const ax = absoluteCenterX - halfW;
        const ay = absoluteCenterY - halfH;
        const bx = absoluteCenterX + halfW;
        const by = absoluteCenterY - halfH;

        const cx = absoluteCenterX - halfW;
        const cy = absoluteCenterY + halfH;
        const dx = absoluteCenterX + halfW;
        const dy = absoluteCenterY + halfH;

        const handleA = this.drawer.svgObject.circle(this.dotSize).fill(this.color).center(ax, ay).opacity(.01);
        const handleB = this.drawer.svgObject.circle(this.dotSize).fill(this.color).center(bx, by).opacity(.01);
        const handleC = this.drawer.svgObject.circle(this.dotSize).fill(this.color).center(cx, cy).opacity(.01);
        const handleD = this.drawer.svgObject.circle(this.dotSize).fill(this.color).center(dx, dy).opacity(.01);

        this.svgElements.push(rect);
        this.svgElements.push(handleA);
        this.svgElements.push(handleB);
        this.svgElements.push(handleC);
        this.svgElements.push(handleD);

        if (this.editable) {
            interact(rect.node)
                .draggable(
                    this.getActionMove()
                );

            interact(handleA.node)
                .draggable(
                    this.getActionResize('a')
                );
            interact(handleB.node)
                .draggable(
                    this.getActionResize('b')
                );
            interact(handleC.node)
                .draggable(
                    this.getActionResize('c')
                );
            interact(handleD.node)
                .draggable(
                    this.getActionResize('d')
                );
            //     .on('tap', (event) => {
            //         this.drawer.getInput('Introduce el texto', `${this.data.text ? this.data.text : ''}`, 'Texto', (value) => {
            //             this.updateRefValue(value);
            //         });
            //     });
        }

    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
