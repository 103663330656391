export const CORRECTIVESECTIONS_PATH = 'corrective-session-sections';

export interface CorrectiveSessionSectionItem {
  name: string;
  correctiveSessionProtocolIds: Array<string>;
}

export interface CorrectiveSessionSection {
  id?: string;
  owner: string;
  sections: Array<CorrectiveSessionSectionItem>;
}
