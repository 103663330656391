import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { angleFromLines, calculateDistance, centerOfSegment } from 'src/app/utils/math';
import { MeasureRef } from './measure-ref.class';
import { getSize } from './media-config.class';

export interface MeasureData {
  ax: number;
  ay: number;
  bx: number;
  by: number;
  fixedTextBox: boolean;
  textBoxCenterX?: number;
  textBoxCenterY?: number;
}

export class Measure extends EditItem {
  type = 'measure';
  data: MeasureData;

  dotSize = 30;
  sDotSize = 5;
  lineWidth = 2;
  bLineWidth = 5;
  textboxH = 30;
  textboxW = 100;
  textboxMarginBottom = 30;
  textColor = '#ffffff';

  static create(ax: number, ay: number, bx: number, by: number) {
    const data: MeasureData = {
      ax,
      ay,
      bx,
      by,
      fixedTextBox: true
    };
    return new Measure(data);
  }

  getActionMove(): DraggableOptions {
    return {
      // cursorChecker: () => {
      //     return null;
      // },
      onstart: (event) => {
        this.emitSelectedEvent();
      },
      onmove: (event) => {
        this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
        this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
        this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
        this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
        this.update();
      }
    };
  }

  getActionResize(direction: 'a' | 'b'): DraggableOptions {
    return {
      // cursorChecker: () => {
      //     return null;
      // },
      onstart: (event) => {
        this.emitSelectedEvent();
      },
      onmove: (event) => {
        if (direction === 'a') {
          this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
          this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
        } else if (direction === 'b') {
          this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
          this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
        }
        this.update();
        // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
        // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
      }
    };
  }

  getActionMoveTextBox(): DraggableOptions {
    return {
      // cursorChecker: () => {
      //     return null;
      // },
      onstart: (event) => {
        this.emitSelectedEvent();
      },
      onmove: (event) => {
        if (this.data.fixedTextBox) {
          const textBoxCenter = centerOfSegment(this.data.ax, this.data.ay, this.data.bx, this.data.by);
          textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
          textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y) - this.textboxMarginBottom;
          this.data.textBoxCenterX = this.drawer.absoluteToRelativeX2(textBoxCenter.x);
          this.data.textBoxCenterY = this.drawer.absoluteToRelativeY2(textBoxCenter.y);
          this.data.fixedTextBox = false;
        } else {
          this.data.textBoxCenterX = this.data.textBoxCenterX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
          this.data.textBoxCenterY = this.data.textBoxCenterY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
        }
        this.update();
      }
    };
  }

  getDistanceText() {
    const defaultText = '- - cm';
    if (!this.drawer.editItems) {
      return defaultText;
    }
    const measureRef = this.drawer.editItems.find(item => item.type === 'measure-ref') as MeasureRef;
    if (!measureRef || !measureRef.data.ref) {
      return defaultText;
    }

    const refAx = this.drawer.relativeToAbsoluteX(measureRef.data.ax);
    const refAy = this.drawer.relativeToAbsoluteY(measureRef.data.ay);
    const refBx = this.drawer.relativeToAbsoluteX(measureRef.data.bx);
    const refBy = this.drawer.relativeToAbsoluteY(measureRef.data.by);

    const ax = this.drawer.relativeToAbsoluteX(this.data.ax);
    const ay = this.drawer.relativeToAbsoluteY(this.data.ay);
    const bx = this.drawer.relativeToAbsoluteX(this.data.bx);
    const by = this.drawer.relativeToAbsoluteY(this.data.by);

    const measureRefValue = measureRef.data.ref;
    const measureRefDistance = calculateDistance(refAx, refAy, refBx, refBy);
    const measureDistance = calculateDistance(ax, ay, bx, by);
    const result = measureDistance * measureRefValue / measureRefDistance;
    return `${Math.round(result)} cm`;
  }

  getAngleText() {
    const ax = this.drawer.relativeToAbsoluteX(this.data.ax);
    const ay = this.drawer.relativeToAbsoluteY(this.data.ay);
    const bx = this.drawer.relativeToAbsoluteX(this.data.bx);
    const by = this.drawer.relativeToAbsoluteY(this.data.by);
    const angle = angleFromLines(
      0, 0, 1, 0,
      ax, ay, bx, by,
      true
    );
    return `${Math.round(angle)} °`;
  }

  draw() {

    const lineAB = this.drawer.svgObject.line(
      this.drawer.relativeToAbsoluteX(this.data.ax),
      this.drawer.relativeToAbsoluteY(this.data.ay),
      this.drawer.relativeToAbsoluteX(this.data.bx),
      this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).stroke({ color: this.color, width: getSize('lineW', this.sizeMode) * 1 / this.drawer.zoomLevel });
    // const leftRight = this.drawer.svgObject.line(
    //     this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width),
    //     this.drawer.relativeToAbsoluteY(this.data.centerY),
    //     this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width),
    //     this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });

    const lineABWide = this.drawer.svgObject.line(
      this.drawer.relativeToAbsoluteX(this.data.ax),
      this.drawer.relativeToAbsoluteY(this.data.ay),
      this.drawer.relativeToAbsoluteX(this.data.bx),
      this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).opacity(.01).stroke({ color: this.color, width: getSize('tapLineW', this.sizeMode) });

    const pointAS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
    const pointBS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));
    const pointA = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
    const pointB = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));

    const textBoxCenter = centerOfSegment(this.data.ax, this.data.ay, this.data.bx, this.data.by);
    textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
    textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y) - this.textboxMarginBottom;

    if (!this.data.fixedTextBox) {
      textBoxCenter.x = this.drawer.relativeToAbsoluteX(this.data.textBoxCenterX);
      textBoxCenter.y = this.drawer.relativeToAbsoluteY(this.data.textBoxCenterY);
    }

    const textBox = this.drawer.svgObject.rect(getSize('measureTextBoxW', this.sizeMode) * 1 / this.drawer.zoomLevel, getSize('textBoxH', this.sizeMode) * 1 / this.drawer.zoomLevel).center(
      textBoxCenter.x,
      textBoxCenter.y).fill(this.color);

    const text = this.drawer.svgObject.text(`${this.getDistanceText()} / ${this.getAngleText()}`).font({ size: getSize('text', this.sizeMode) * 1 / this.drawer.zoomLevel }).center(
      textBoxCenter.x,
      textBoxCenter.y).fill(this.textColor);

    this.svgElements.push(pointAS);
    this.svgElements.push(pointBS);
    this.svgElements.push(pointA);
    this.svgElements.push(pointB);
    this.svgElements.push(lineAB);
    this.svgElements.push(lineABWide);
    this.svgElements.push(textBox);
    this.svgElements.push(text);

    if (this.editable) {
      interact(pointA.node)
        .draggable(
          this.getActionResize('a')
        )
        .on('tap', (event) => {
          this.drawer.selectedEvent(this.index);
        });
      interact(textBox.node)
        .draggable(
          this.getActionMoveTextBox()
        )
        .on('tap', (event) => {
          this.drawer.selectedEvent(this.index);
        });
      interact(text.node)
        .draggable(
          this.getActionMoveTextBox()
        )
        .on('tap', (event) => {
          this.drawer.selectedEvent(this.index);
        });
      interact(pointB.node)
        .draggable(
          this.getActionResize('b')
        ).on('tap', (event) => {
          this.drawer.selectedEvent(this.index);
        });
      interact(lineABWide.node)
        .draggable(
          this.getActionMove()
        ).on('tap', (event) => {
          this.drawer.selectedEvent(this.index);
        });

    }

  }

  emitSelectedEvent() {
    this.drawer.selectedEvent(this.index);
  }
}
