<div class="email-wall" *ngIf="user$ | async as user">
  <div class="email-wall__intecc-logo">
    <img
      src="../../../assets/images/intecc-with-bg-rounded.png"
      appResponsiveSrc
      alt=""
    />
  </div>
  <h2 class="email-wall__title">¡Bienvenid&#64;!</h2>
  <div *ngIf="!user.emailVerificationSent" class="email-wall__not-sent">
    <p class="email-wall__p">Estás a un paso de mejorar como PROfesional 🚀</p>
    <p class="email-wall__email">{{ user.email }}</p>
    <button
      [disabled]="sending || !marketing"
      (click)="sendVerificationEmail()"
      class="button-solid"
      [class.button-solid--disabled]="sending || !marketing"
    >
      {{ sending ? 'Un momento...' : 'Accede a la App' }}
    </button>
    <div class="email-wall__marketing-checkbox">
      <input
        type="checkbox"
        [(ngModel)]="marketing"
        name="marketing"
        id="marketing"
      />
      <label for="marketing"
        >Acepto la
        <a
          class="underline"
          target="_blank"
          href="https://www.intecc.org/blog/rgdp/politica-de-privacidad "
        >
          política de privacidad</a
        >
        y
        <a
          class="underline"
          target="_blank"
          href="https://www.intecc.org/blog/rgdp/terminos-y-condiciones-generales-de-venta "
          >términos y condiciones generales</a
        ></label
      >
    </div>
  </div>
  <div *ngIf="user.emailVerificationSent" class="email-wall__sent">
    <p class="email-wall__p">
      Te hemos enviado las llaves 🔑 <br />
      de INTECC a tu email:
    </p>
    <p class="email-wall__email">{{ user.email }}</p>
    <p class="email-wall__p">
      Abre el mensaje para desbloquear todo el contenido.
    </p>
    <p class="email-wall__p-light">
      ¿No lo encuentras? <br />
      🔎 Revisa la carpeta de spam.
    </p>
    <a
      [routerLink]="['/']"
      class="email-wall__p-light"
      style="text-decoration: underline"
      >O haz click aquí para continuar.</a
    >
  </div>

  <div class="email-wall__video">
    <!-- <img src="../../../assets/vault.gif" alt=""> -->
    <video width="100%" autoplay loop>
      <source src="../../../assets/vault.mp4" type="video/mp4" />
      Your browser does not support the video element.
    </video>
  </div>

  <a href="#" (click)="auth.signOut()" class="email-wall__link"
    >Cerrar sesión</a
  >
</div>
