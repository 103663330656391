<div class="">
  <div *ngIf="clientCorrectiveSession" class="test-view">
    <div *ngIf="client$ | async as client">
      <div class="app-container" *ngIf="correctiveSessionProtocolDef">
        <app-test-header
          [title]="correctiveSessionProtocolDef.name"
          [isUserClientView]="isUserClientView"
          [generateReportAction]="generateReport"
        ></app-test-header>

        <div class="test-info">
          <app-client-back-button
            *ngIf="!isUserClientView"
            [client]="client"
            [myVafMode]="true"
            class="mr-2"
          ></app-client-back-button>
          <div class="date-selector mt-2 md:mt-0">
            <button
              *ngIf="!isUserClientView"
              (click)="picker.open()"
              class="date-selector__button"
            >
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
              <i class="fas fa-edit"></i>
            </button>
            <span *ngIf="isUserClientView">
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
            </span>

            <input
              style="display: none"
              (dateChange)="onDateChanged($event)"
              matInput
              [matDatepicker]="picker"
              placeholder="Choose a date"
            />
            <mat-datepicker
              touchUi
              #picker
              [startAt]="clientCorrectiveSession.createdAt.toDate()"
            ></mat-datepicker>
          </div>
        </div>

        <div
          *ngFor="let sectionDef of correctiveSessionProtocolDef.sections"
          class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
        >
          <div class="flex justify-between">
            <p class="text-lg font-semibold md:text-xl">
              {{ sectionDef.name }}
            </p>
            <div class="flex items-center"></div>
          </div>
          <div *ngIf="sectionDef.description" class="mt-2">
            <p
              class="text-neutral-500"
              [innerHTML]="processLineBreak(sectionDef.description)"
            ></p>
          </div>
          <div class="mb-4"></div>
          <div
            *ngIf="sectionDef.mode === 'table'"
            class="w-full overflow-y-scroll md:overflow-y-hidden"
          >
            <div
              *ngIf="sectionDef.fields; else noFields"
              style=""
              class="grid grid-cols-3 md:grid-cols-1.2fr-1fr-1fr md:grid-flow-col md:auto-cols-fr grid-rows-100-of-parent md:grid-rows-1 gap-x-5 table-container max-h-screen-3/4 md:h-auto {{
                sectionDef.images ? 'md:min-h-96' : ''
              }}"
            >
              <div class="col-span-2 overflow-y-scroll md:overflow-y-auto">
                <div
                  *ngIf="sectionDef.tableMode === 'xy'"
                  style=""
                  class="h-auto self-start rounded-md"
                >
                  <table
                    mat-table
                    [dataSource]="preBuiltDataSources[sectionDef.localId]"
                    class="w-full table-fixed"
                  >
                    <ng-container matColumnDef="rowTitle">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="font-bold"
                        style="width: 220px"
                      >
                        {{
                          sectionDef.tableFirstColTitle
                            ? sectionDef.tableFirstColTitle
                            : ' '
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        style="
                          border-bottom: 1px solid #e5e7eb;
                          min-width: 100px;
                        "
                      >
                        <p class="w-full font-bold">
                          {{ element.rowTitle }}
                        </p>
                      </td>
                    </ng-container>
                    <ng-container
                      *ngFor="let field of sectionDef.fields"
                      matColumnDef="{{ field.localId }}"
                    >
                      <th mat-header-cell *matHeaderCellDef class="font-bold">
                        {{ field.title }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        style="
                          border-bottom: 1px solid #e5e7eb;
                          overflow: visible;
                          min-width: 100px;
                        "
                      >
                        <ng-container *ngIf="!isUserClientView">
                          <app-test-field-dropdown
                            [tableMode]="true"
                            [mode]="field.type"
                            [options]="field.options"
                            [text]="element[field.localId]"
                            [selectedIndex]="
                              getValueTable(
                                sectionDef.localId,
                                field.localId,
                                element.index
                              )
                            "
                            [openToTop]="false"
                            (selectedItemChanged)="
                              setValueTextTable(
                                sectionDef.localId,
                                field.localId,
                                element.index,
                                $event
                              )
                            "
                            (textChanged)="
                              setValueTextTable(
                                sectionDef.localId,
                                field.localId,
                                element.index,
                                $event
                              )
                            "
                          >
                          </app-test-field-dropdown>
                        </ng-container>
                        <ng-container *ngIf="isUserClientView">
                          <p *ngIf="field.type === 'select'">
                            {{
                              getValueTable(
                                sectionDef.localId,
                                field.localId,
                                element.index
                              ) !== -1
                                ? field.options[
                                    getValueTable(
                                      sectionDef.localId,
                                      field.localId,
                                      element.index
                                    )
                                  ]
                                : ''
                            }}
                          </p>
                          <p *ngIf="field.type === 'text'">
                            {{ element[field.localId] }}
                          </p>
                        </ng-container>
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="
                        preBuiltDisplayedColumns[sectionDef.localId]
                      "
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="
                        let row;
                        columns: preBuiltDisplayedColumns[sectionDef.localId]
                      "
                    ></tr>
                  </table>
                </div>
              </div>

              <div
                *ngIf="
                  (sectionDef.images && sectionDef.images.length > 0) ||
                  (sectionDef.fixedImages && sectionDef.fixedImages.length > 0)
                "
                class="custom-scroll w-full overflow-x-scroll pb-5"
              >
                <ng-container
                  *ngIf="
                    (sectionDef.images ? sectionDef.images.length : 0) +
                    (sectionDef.fixedImages
                      ? sectionDef.fixedImages.length
                      : 0) as imageCount
                  "
                >
                  <div
                    style="width: {{
                      imageCount
                    }}00%; display: grid; grid-template-columns: repeat({{
                      imageCount
                    }}, minmax(0, 1fr));"
                    class="h-full"
                  >
                    <ng-container *ngFor="let image of sectionDef.fixedImages">
                      <ng-container *ngIf="image.downloadUrl">
                        <div
                          style="width: 90%; margin: auto"
                          class="h-full bg-blue-300 rounded-md overflow-hidden {{
                            imageCount === 1 ? 'col-span-2' : ''
                          }} relative"
                        >
                          <img
                            src="{{ image.downloadUrl }}"
                            class="h-full w-full object-cover"
                            alt=""
                          />
                          <div
                            *ngIf="image.title"
                            class="absolute left-0 top-0 w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
                          >
                            <p>{{ image.title }}</p>
                          </div>
                          <div
                            class="absolute bottom-0 right-0 px-4 py-5"
                            *ngIf="!isUserClientView"
                          >
                            <a
                              *ngIf="image.link"
                              href="{{ image.link }}"
                              target="_blank"
                              class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
                            >
                              <i
                                class="fas fa-external-link-alt text-white"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>

                    <div
                      *ngFor="let image of sectionDef.images"
                      style="width: 90%; margin: auto"
                      class="h-full bg-blue-300 rounded-md overflow-hidden {{
                        imageCount === 1 ? 'col-span-2' : ''
                      }}"
                    >
                      <app-test-image-view
                        [isMyVaf]="true"
                        [client]="client"
                        [myVafImageDef]="image"
                        [imageKey]="image.key"
                        [test]="clientCorrectiveSession"
                        (imageChangedEvent)="onImageChanged()"
                        [isUserClientView]="isUserClientView"
                      >
                      </app-test-image-view>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-template #noFields> </ng-template>
          </div>

          <div class="w-full">
            <p class="mb-4 mt-7 text-lg font-semibold">Comentarios</p>
            <textarea
              class="w-full rounded-lg border-2 border-neutral-200"
              #commentsTextArea
              name="comments"
              cols="30"
              rows="5"
              [value]="getComments(sectionDef)"
              (change)="onCommentsChanged(sectionDef, $event)"
              [readonly]="isUserClientView"
            ></textarea>
          </div>
        </div>

        <div
          class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
        >
          <div class="mb-4 flex justify-between">
            <p class="text-xl font-semibold">Recomendaciones y enlaces</p>
            <div class="flex items-center"></div>
          </div>
          <div class="w-full overflow-y-scroll md:overflow-y-hidden"></div>
          <div class="w-full">
            <p class="mb-4 mt-7 text-lg font-semibold">Recomendaciones</p>
            <textarea
              class="w-full rounded-lg border-2 border-neutral-200"
              #commentsTextArea
              name="comments"
              cols="30"
              rows="5"
              [value]="getTips()"
              (change)="onTipsChanged($event)"
              [readonly]="isUserClientView"
            ></textarea>
          </div>
          <div class="w-full">
            <p class="mb-1 mt-7 text-lg font-semibold">Enlaces</p>
            <p *ngIf="!isUserClientView">Añade enlaces a los informes</p>
            <app-test-report-links
              [links]="clientCorrectiveSession.links"
              (changeEvent)="this.setLinks($event)"
              [isUserClientView]="isUserClientView"
            ></app-test-report-links>
          </div>
        </div>
        <div
          *ngIf="!isUserClientView"
          class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
        >
          <div class="mb-4">
            <p class="mb-4 text-xl font-semibold">Enlace para compartir</p>
            <app-input
              class="md:col-span-2"
              [readonly]="true"
              [button]="copySharedUrlButton"
              [value]="getShareURL()"
            ></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
