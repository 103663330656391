import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TagsService } from 'src/app/services/tags.service';
import { Tag, Tags } from 'src/app/models/tags.model';
import { take, map } from 'rxjs/operators';
import { DropdownItem } from 'src/app/models/utils/dropdown-item.model';
import { TestNames } from 'src/shared/test.outside';
import { DropdownComponent } from 'src/app/elements/dropdown/dropdown.component';
import { ExportService } from 'src/app/services/export.service';
import { Modal } from 'src/app/models/modal.model';
import { CanIService } from 'src/app/services/can-i.service';
import { AppConfig } from 'src/config/app.config';

@Component({
    selector: 'app-export-tests-modal',
    templateUrl: './export-tests-modal.component.html',
    styleUrls: ['./export-tests-modal.component.scss']
})
export class ExportTestsModalComponent implements OnInit, Modal {

    onClose = new Subject<any>();

    data;

    working = false;

    tagsDropdownItems: Observable<Array<DropdownItem>>;

    testTypesDropdownItems: Array<DropdownItem> = [];

    @ViewChild('testTypeDropdown', {static: true})
    testTypeDropdown: DropdownComponent;

    @ViewChild('tagsDropdown')
    tagsDropdown: DropdownComponent;

    constructor(private tagsService: TagsService, private exportService: ExportService, private canI: CanIService) { }

    ngOnInit() {
        this.tagsDropdownItems = this.tagsService.getTags$().pipe(take(1), map( tags => {
            const dropdownItems = tags.tags.map( tag => {
                const item: DropdownItem = {displayName: tag.name, item: tag};
                return item;
            });
            dropdownItems.unshift({displayName: 'Todos los clientes', item: null});
            return dropdownItems;
        }));

        if (AppConfig.app === 'rom') {
            this.testTypesDropdownItems = [
                {displayName: 'ROM', item: 'rom'},
            ];
        } else if (AppConfig.app === 'raquis') {
            this.testTypesDropdownItems = [
                {displayName: 'Inclinómetro', item: 'sagital'},
                {displayName: 'Flechas', item: 'sagital-flechas'},
            ];
        }

    }

    historyBackClose() {
        this.cancel();
    }

    cancel() {
        this.onClose.next('');
    }

    async export() {
        if (!(await this.canI.requestExportCsv())) {
            this.cancel();
            return;
        }
        this.working = true;
        if (!this.tagsDropdown) {
            return;
        }
        await this.exportService.exportTestsToCsv(this.tagsDropdown.selectedItem.item, this.testTypeDropdown.selectedItem.item);
        this.working = false;
    }

}
