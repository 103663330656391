import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  map,
  Observable,
  switchMap,
  tap,
  combineLatest,
  Subject,
  of,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListItem } from 'src/app/items-list/items-list.component';
import { Client } from 'src/app/models/client.model';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { AuthService } from 'src/app/services/auth.service';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import { ModalService } from 'src/app/services/modal.service';
import { ReportsService } from 'src/app/services/reports.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TestService } from 'src/app/services/test.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { LoadingState } from 'src/app/utils/utils';
import {
  ClientCorrectiveSession,
  CorrectiveSessionProtocolDef,
} from 'src/shared/corrective-session-definition.outside';
import { SubscriptionType } from 'src/shared/subscriptions.shared';
import { Test } from 'src/shared/test.outside';

@Component({
  selector: 'app-client-tests',
  templateUrl: './client-tests.component.html',
})
export class ClientTestsComponent implements OnInit, OnDestroy {
  pageLoaded: LoadingState = 'loading';

  tests$: Observable<Test[]>;

  correctiveSessionProtocolDefs$: Observable<CorrectiveSessionProtocolDef[]>;
  clientTestsList$: Observable<ListItem<Test>[]>;

  clientCorrectiveSessionsList$: Observable<
    ListItem<ClientCorrectiveSession>[]
  >;

  userClient$: Observable<Client>;
  userClient: Client;
  professionalSubscriptionType$: Observable<SubscriptionType>;
  generatingReport = false;

  private destroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private userClientService: UserClientService,
    private modalService: ModalService,
    private testsService: TestService,
    private correctiveSessionsService: CorrectiveSessionsService,
    private actionInProgressService: ActionInProgressService,
    private reportsServices: ReportsService,
    private snackbarService: SnackbarService,
  ) {
    this.generateReportForValorations =
      this.generateReportForValorations.bind(this);

    this.generateReportClientCorrectiveSessions =
      this.generateReportClientCorrectiveSessions.bind(this);

    this.userClient$ = this.userClientService.userClient$.pipe(
      tap((client) => {
        this.userClient = client;
      }),
    );

    this.professionalSubscriptionType$ =
      this.userClientService.professionalSubscriptionType$;

    this.tests$ = this.auth.user$.pipe(
      switchMap((user) => this.userClientService.userClientTests$),
      tap((tests) => {}),
    );

    // subscriptions for tests list
    this.clientTestsList$ = this.tests$.pipe(
      map((tests) => {
        return tests.map((test) => {
          const listItem = {
            ...test,
            id: test.id,
            faIcon: 'fa-solid fa-bullseye', // this.tests.TestIcons[test.type],
            url: `/c/tests/${test.id}`,
            name: this.testsService.TestNames[test.type],
            createdAt: test.createdAt,
          };
          return listItem;
        });
      }),
    );

    // subscriptions for corrective session protocols
    this.correctiveSessionProtocolDefs$ = this.userClient$.pipe(
      switchMap((userClient) => {
        if (!userClient) {
          return of([]);
        }
        return this.correctiveSessionsService.getCorrectiveSessionProtocols$(
          userClient.owner,
        );
      }),
    );
    this.clientCorrectiveSessionsList$ = combineLatest([
      this.userClientService.userClientCorrectiveSessions$,
      this.correctiveSessionProtocolDefs$,
    ]).pipe(
      map(([sessions, protocolDefs]) => {
        return sessions.map((session) => {
          return {
            ...session,
            faIcon: 'fa-regular fa-life-ring',
            id: session.id,
            name: this.getCorrectiveSessionProtocolName(
              session.correctiveSessionProtocolDefId,
              protocolDefs,
            ),
            url: `/c/client-corrective-session/${session.id}`,
            createdAt: session.createdAt,
          } satisfies ListItem<ClientCorrectiveSession>;
        });
      }),
    );
  }

  ngOnInit() {
    combineLatest([
      this.auth.user$,
      this.userClient$,
      this.professionalSubscriptionType$,
      this.tests$,
      this.clientTestsList$,
      this.correctiveSessionProtocolDefs$,
      this.clientCorrectiveSessionsList$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([
            user,
            userClient,
            professionalSubscriptionType,
            tests,
            clientTestsList,
            correctiveSessionProtocolDefs,
            clientCorrectiveSessionsList,
          ]) => ({
            user,
            userClient,
            professionalSubscriptionType,
            tests,
            clientTestsList,
            correctiveSessionProtocolDefs,
            clientCorrectiveSessionsList,
          }),
        ),
      )
      .subscribe({
        next: (data) => {
          // All observables have emitted values
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async openAvatarModal() {
    await this.modalService.openSideLeftModal('avatar', {
      isUserClientView: true,
    });
  }

  // functions for tests
  async generateReport(items: Map<string, Test>) {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([...items.values()]);
    } catch (e) {
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }

  async generateReportForValorations(valorations: Map<string, Test>) {
    await this.generateReport(valorations);
  }

  // functions for corrective sessions
  async generateReportClientCorrectiveSessions(
    correctiveSessions: Map<string, Test>, // actually, is a ClientCorrectiveSession not a Test, but is valid for this case to keep ts happy
  ) {
    await this.generateReport(correctiveSessions);
  }

  getCorrectiveSessionProtocolName(
    protocolId: string,
    correctiveSessionProtocolDefs: CorrectiveSessionProtocolDef[],
  ) {
    const result = correctiveSessionProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo de sesión de ejercicios correctivos eliminado';
    } else {
      return result.name;
    }
  }

  // joinRandomProfessional() {
  //   this.userClientService.joinRandomProfessional();
  // }
}
