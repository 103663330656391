<div class="modal" *ngIf="authService.user$ | async as user">
    <h3 class="modal__title">{{user.displayName.split(' ')[0]}}, sentimos que te vayas...</h3>
    <p class="modal__description">
        Si así lo deseas, vamos a cancelar tu suscripción. Esto es lo que ocurrirá a continuación:
    </p>

    <div class="modal__content">
        <ul class="modal__bullet-points">
        <li>No volveremos a procesar cobros a tu método de pago.</li>
        <li>Perderás el acceso a todas las valoraciones de tus clientes.</li>
        <li>No podrás generar nuevas valoraciones.</li>
        <li>Seguirás teniendo acceso a tus clientes, pero no podrás crear ninguno nuevo.</li>
        <li>Puedes volver cuando quieras desde "Mi cuenta" -> "Mejorar".</li>
    </ul>
    </div>
    <div class="modal__actions">
        <button *ngIf="!canceling" (click)="cancelCurrentSub()" class="box__confirm-button button-plain-2">Quiero cancelar mi suscripción</button>
        <button [disabled]="canceling" (click)="close()" class="box__confirm-button button-cta-2">Mantener suscripción</button>
    </div>
</div>