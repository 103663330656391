import { Component, Input, OnInit } from '@angular/core';
import { AvatarTest, AvatarTestValueKey, avatarSharedGetValueText, AvatarTestValueStatusType} from 'src/shared/avatar.outside';

@Component({
    selector: 'app-avatar-status-item',
    templateUrl: './avatar-status-item.component.html',
    styleUrls: ['./avatar-status-item.component.scss']
})
export class AvatarStatusItemComponent {

    @Input() title: string;
    @Input() value: string;
    @Input() status: AvatarTestValueStatusType;

    constructor() { }

}
