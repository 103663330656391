import { Component, OnInit } from '@angular/core';
import { FloatingVideoService } from 'src/app/services/floating-video.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';

@Component({
  selector: 'app-floating-video',
  templateUrl: './floating-video.component.html',
  styleUrls: ['./floating-video.component.scss']
})
export class FloatingVideoComponent implements OnInit {

  open = false;
  subs = new Array<Subscription>();
  vimeoId: any = null;

  constructor(private service: FloatingVideoService, private sanitizer: DomSanitizer, private overrideBackButtonService: OverrideBackButtonService) { }

  ngOnInit() {
    this.subs.push(this.service.show$.subscribe(show => {
      if(show) {
        this.openVideo(this.service.vimeoId);
      }else{
        this.vimeoId = null;
      }
      this.open = show;
    }));
  }

  openVideo(vimeoId: string){
    this.overrideBackButtonService.override(() => {
      this.service.hide();
    });
    this.vimeoId = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${vimeoId}`);
  }

  close(event){
    event.stopPropagation();
    history.back();
  }

}
