import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appLearnSectionHost]'
})
export class LearnSectionHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
   }

}
