import { Injectable } from '@angular/core';
import { ActionInProgressComponent } from '../components/action-in-progress/action-in-progress.component';

@Injectable({
  providedIn: 'root'
})
export class ActionInProgressService {

  public component: ActionInProgressComponent;

  constructor() { }

}
