import { Component, OnInit, Output, Input, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { DropdownItem } from 'src/app/models/utils/dropdown-item.model';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

    @Input()
    defaultFirst = false;

    private pItems: Array<DropdownItem> = [];
    public filteredItems: Array<DropdownItem> = [];

    @Input() set items(options: Array<DropdownItem>) {
        this.pItems = options;
        this.filteredItems = this.pItems;
        if (this.defaultFirst) {
            this.selectItem(this.pItems[0]);
        }
        if (this.pSearchInputRef) {
            this.pSearchInputRef.nativeElement.value = '';
        }
    }

    @Output() selectedItemChange = new EventEmitter<DropdownItem>();

    private pSearchInputRef: ElementRef;

    @ViewChild('searchInput') set searchInputRef(value: ElementRef) {
        this.pSearchInputRef = value;
    }

    selectedItem: DropdownItem;

    isOpen = false;

    constructor() { }

    selectItem(item: DropdownItem) {
        this.selectedItem = item;
        this.selectedItemChange.next(this.selectedItem);
        this.close();
    }

    open() {
        this.isOpen = true;
        this.onSearch('');
    }
    close() {
        this.isOpen = false;
    }
    toggle() {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    onHeaderClick() {
        console.log('onHeaderClick');
        if (!this.isOpen) {
            this.open();
        }
    }
    onIconClick(event) {
        console.log('onIconClick');
        this.toggle();
        event.stopPropagation();
    }

    onSearch(value: string) {
        this.filteredItems = this.findItems(value, this.pItems);
    }

    findItems(value: string, array: Array<DropdownItem>) {
        let regExString = '(';
        const valueChars = value.split('');
        for (let index = 0; index < valueChars.length; index++) {
            const element = valueChars[index];
            regExString = regExString + element;
            if (index < valueChars.length - 1) {
                regExString = regExString + '.*';
            }
        }
        regExString = regExString + ')';
        const regEx = new RegExp(regExString, 'gi');
        const result  = array.filter(item => {
            const searchResult = item.displayName.search(regEx);
            return searchResult >= 0;
        });
        return result;
    }

    onKeydown(event) {
        if (event.key === 'Enter') {
            if (this.filteredItems.length > 0) {
                this.selectItem(this.filteredItems[0]);
            }
        }
    }

}
