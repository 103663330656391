import { Component, Input } from '@angular/core';
import { LearnService } from 'src/app/services/learn.service';

@Component({
  selector: 'app-test-header',
  templateUrl: './test-header.component.html',
  styleUrls: ['./test-header.component.scss'],
})
export class TestHeaderComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() learnId: string;
  @Input() learnURL = '';
  @Input() isUserClientView = false;
  @Input() generateReportAction: () => void;

  constructor(private learnService: LearnService) {}

  openLearn() {
    if (!this.learnId) {
      return;
    }
    this.learnService.openPanel(this.learnId);
  }
}
