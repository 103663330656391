export const MYVAFSECTIONS_PATH = 'myvaf-sections';

export interface MyVafSectionItem {
  name: string;
  protocolIds: Array<string>;
}

export interface MyVafSection {
  id?: string;
  owner: string;
  sections: Array<MyVafSectionItem>;
}
