import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-feedback',
  templateUrl: './error-feedback.component.html',
})
export class ErrorFeedbackComponent {
  @Input() message: string = 'Ha ocurrido un error inesperado';

  @Input() minimal: boolean = false;
}
