import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-icon-toggle',
    templateUrl: './icon-toggle.component.html',
    styleUrls: ['./icon-toggle.component.scss']
})
export class IconToggleComponent {

    @Input() active: boolean;
    @Output() activeChange = new EventEmitter<boolean>();

    constructor() { }

    toggle() {
        this.active = !this.active;
        this.activeChange.next(this.active);
    }

}
