import { Component, Input, OnInit } from '@angular/core';
import { AvatarTestValueKey, AvatarTestValueStatusType } from 'src/shared/avatar.outside';

@Component({
    selector: 'app-avatar-status-indicator',
    templateUrl: './avatar-status-indicator.component.html',
    styleUrls: ['./avatar-status-indicator.component.scss']
})
export class AvatarStatusIndicatorComponent {

    @Input() xy = [7, 51];
    @Input() key: AvatarTestValueKey;
    @Input() currentHoverKey: AvatarTestValueKey;
    @Input() status: AvatarTestValueStatusType;

    constructor() { }

}
