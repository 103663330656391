import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CustomBrandingService } from 'src/app/services/custom-branding.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { AppConfig, AppName } from 'src/config/app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;

  appName: AppName = AppConfig.app;

  loginWorking = false;

  currentBrand;

  professionalIdQueryParam: string | null;

  constructor(
    public authService: AuthService,
    private customBranding: CustomBrandingService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.loginForm = this.createFormGroup();
    this.currentBrand = this.customBranding.brand;
    this.professionalIdQueryParam =
      this.navigationService.professionalIdQueryParam;
  }

  createFormGroup() {
    return new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.email),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit() {
    console.log(this.loginForm);
  }

  async login() {
    this.loginWorking = true;
    await this.authService.googleSignin();
    this.loginWorking = false;
  }
}
