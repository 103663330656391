import { Component, OnInit, Input } from '@angular/core';
import { RomMovement } from 'src/shared/rom-test.model';
import { calculateResultBarPercentage, findLimitIndex } from 'src/app/utils/utils';

@Component({
    selector: 'app-result-bar',
    templateUrl: './result-bar.component.html',
    styleUrls: ['./result-bar.component.scss']
})
export class ResultBarComponent {
    arrowPer: number;
    limitIndex: number;
    private pCurrentResult: number;

    @Input() set currentResult(value: number) {
        this.pCurrentResult = value;
        this.updateVisualState();
    }

    private pMovement: RomMovement;

    @Input() set movement(value: RomMovement) {
        this.pMovement = value;
        this.updateVisualState();
    }

    get movement() {
        return this.pMovement;
    }

    constructor() { }

    updateVisualState() {
        if (!this.movement) {
            return;
        }
        if (this.movement.id === 'free') {
            this.arrowPer = this.pCurrentResult / 180 * 100;
            this.arrowPer = this.arrowPer > 100 ? 100 : this.arrowPer;
            return;
        }
        this.arrowPer = calculateResultBarPercentage(this.movement.limits, this.pCurrentResult);
        this.limitIndex = findLimitIndex(this.movement.limits, this.pCurrentResult);
    }

}
