import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-test-angle-value',
  templateUrl: './test-angle-value.component.html',
  styleUrls: ['./test-angle-value.component.scss']
})
export class TestAngleValueComponent {

  @Output()
  click = new EventEmitter<void>();

  _value: number;
  get value() {return this._value;}
  @Input() set value(value: number) {this._value = value; this.valueChange();}

  constructor() { }

  valueChange(){

  }

}
