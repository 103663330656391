import { Injectable } from '@angular/core';
import { NotificationsComponent } from '../components/notifications/notifications.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    component: NotificationsComponent;

    constructor() { }
}
