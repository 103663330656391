<div class="box">
    <div class="title">Filtrar clientes</div>
    <div class="sub-title">Por grupo</div>
    <div class="tags" *ngIf="tagsDropdownItems | async as dropDownItems">
        <app-dropdown #tagsDropdown [defaultFirst]="true" [items]="dropDownItems"></app-dropdown>
    </div>
    <div class="sub-title">Fecha de creación</div>
    <div class="dates">
        <div (click)="pickerFrom.open()" class="date-button margin-right">
            <span class="date-button__info">Desde: </span>
            <span class="date-button__date">{{dateFrom ? (dateFrom | date) : 'Elegir fecha'}}</span>
        </div>
        <div (click)="pickerTo.open()" class="date-button">
            <span class="date-button__info">Hasta: </span>
            <span class="date-button__date">{{dateTo ? (dateTo | date) : 'Elegir fecha'}}</span>
        </div>


        <input style="display: none;" (dateChange)="dateFromSelected($event);" matInput [matDatepicker]="pickerFrom"
            placeholder="Choose a date">
        <mat-datepicker touchUi #pickerFrom [startAt]="dateFrom"></mat-datepicker>

        <input style="display: none;" (dateChange)="dateToSelected($event);" matInput [matDatepicker]="pickerTo"
        placeholder="Choose a date">
        <mat-datepicker touchUi #pickerTo [startAt]="dateTo"></mat-datepicker>
    </div>
    <button (click)="accept()" class="button-cta-2">Filtrar</button>
    <button class="cancel-button button-plain-2" (click)="cancel()">Cancelar</button>
</div>