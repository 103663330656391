<div
  class="absolute w-full h-full top-0 left-0 bg-black/70 flex flex-col justify-center {{
    isOpen ? '' : 'hidden'
  }}"
>
  <div class="w-full px-5">
    <div
      class="bg-white rounded-lg max-w-md mx-auto text-black text-center p-5"
    >
      <p class="text-lg mb-5">{{ title }}</p>
      <div class="w-full mb-5">
        <input
          class="w-full rounded p-2 border-2 border-blue-300"
          placeholder="{{ placeholder }}"
          type="text"
          [(ngModel)]="value"
        />
      </div>
      <div class="flex justify-center">
        <app-button (clickEvent)="accept()" [text]="'Aceptar'"></app-button>
      </div>
    </div>
  </div>
</div>
