import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { FunctionsService } from '../services/functions.service';
import { safeToPromise } from '../utils/utils';

@Component({
  selector: 'app-accept-conditions',
  templateUrl: './accept-conditions.component.html',
  styleUrls: ['./accept-conditions.component.scss'],
})
export class AcceptConditionsComponent implements OnInit {
  emailSent = false;

  user$: Observable<User>;

  sending = false;

  marketing = false;

  constructor(
    public auth: AuthService,
    private nav: NavigationService,
    private functionsService: FunctionsService,
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.user$.pipe(take(1)).subscribe((user) => {
      if (user.conditionsAccepted === true) {
        this.roleBasedRedirect(user);
      }
    });
  }

  roleBasedRedirect(user: User) {
    if (user.role === 'client') {
      this.nav.clientNav.goToHome();
    } else {
      this.nav.goToHome();
    }
  }

  loadData() {
    this.user$ = this.auth.user$;
  }

  async acceptConditions() {
    this.sending = true;
    const user = await safeToPromise(this.auth.user$.pipe(take(1)));
    user.conditionsAccepted = true;
    if (user.role !== 'client') {
      const result = await this.functionsService.call(
        'sendVerificationEmailCallable',
        { email: user.email, name: user.displayName },
      );
      // console.log('acceptConditions result', result);
    }
    await this.auth.updateUser(user);
    this.sending = false;
    this.roleBasedRedirect(user);
  }
}
