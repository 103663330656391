import { Component, Input, OnInit } from '@angular/core';
import { AvatarTestValueStatusType } from 'src/shared/avatar.outside';

@Component({
  selector: 'app-avatar-field-status',
  templateUrl: './avatar-field-status.component.html',
  styleUrls: ['./avatar-field-status.component.scss'],
})
export class AvatarFieldStatusComponent {
  @Input() status: AvatarTestValueStatusType = 'neutral';

  statusIconMap = {
    // eslint-disable-next-line quote-props
    neutral: 'far fa-circle',
    // eslint-disable-next-line quote-props
    good: 'fas fa-check',
    // eslint-disable-next-line quote-props
    bad: 'fas fa-exclamation-triangle',
  };

  statusColorMap = {
    // eslint-disable-next-line quote-props
    neutral: 'text-neutral-300',
    // eslint-disable-next-line quote-props
    good: 'text-emerald-200',
    // eslint-disable-next-line quote-props
    bad: 'text-amber-300',
  };

  constructor() {}
}
