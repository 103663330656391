export const NO_TAG_ID = -1;
export const UNSAVED_TAG_ID = -2;
export const TAGS_PATH = 'user-tags';

export interface Tag {
    id: number;
    name: string;
}

export interface Tags {
    tags?: Array<Tag>;
    lastId: number;
}

export const NoTag: Tag = {
    id: NO_TAG_ID,
    name: 'Sin Grupo'
};

export const EmptyTags: Tags = {
    tags: [],
    lastId: 0
};

