import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CanIService } from '../services/can-i.service';
import { AppConfig } from 'src/config/app.config';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsGuard implements CanActivate {
  constructor(private canI: CanIService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    switch (next.routeConfig?.path) {
      case 'test/:id': {
        const result = await this.canI.canViewTests(AppConfig.app);
        if (!result) {
          alert('No puedes acceder a esta página.');
        }
        return result;
      }
      case 'copy-training-session-protocol/:id':
      case 'training-session-protocol/:id':
      case 'client-training-session/:id': {
        const result = await this.canI.canViewTrainingSessions(AppConfig.app);
        if (!result) {
          alert('No puedes acceder a esta página.');
        }
        return result;
      }
      case 'copy-corrective-session-protocol/:id':
      case 'corrective-session-protocol/:id':
      case 'client-corrective-session/:id': {
        const result = await this.canI.canViewCorrectiveSessions(AppConfig.app);
        if (!result) {
          alert('No puedes acceder a esta página.');
        }
        return result;
      }
      case 'copy-protocol/:id':
      case 'myvaf/:id': {
        const result = await this.canI.canUseMyVaf(AppConfig.app);
        if (!result) {
          alert('No puedes acceder a esta página.');
        }
        return result;
      }
      default:
        return false;
    }
  }
}
