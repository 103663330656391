import { Component, OnInit } from '@angular/core';
import { Modal } from 'src/app/models/modal.model';
import { Subject } from 'rxjs';
import { LearnGifs } from 'src/app/static/learn-gifs.static';

@Component({
    selector: 'app-gif-modal',
    templateUrl: './gif-modal.component.html',
    styleUrls: ['./gif-modal.component.scss']
})
export class GifModalComponent implements Modal {
    onClose = new Subject<any>();
    data: { title: string, gifId: string };

    LearnGifs = LearnGifs;

    historyBackClose() {
        this.close();
    }

    constructor() { }

    close() {
        this.onClose.next('');
    }

}
