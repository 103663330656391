import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestReportLink } from 'src/shared/test.outside';

@Component({
  selector: 'app-test-report-links',
  templateUrl: './test-report-links.component.html',
  styleUrls: ['./test-report-links.component.scss'],
})
export class TestReportLinksComponent {
  @Input() isUserClientView = false;

  pLinks: Array<TestReportLink> = [
    { title: 'title', url: 'https://google.com' },
  ];

  get links(): Array<TestReportLink> {
    return this.pLinks;
  }
  @Input() set links(v: Array<TestReportLink>) {
    this.pLinks = v;
    if (!this.pLinks || this.pLinks.length === 0) {
      this.pLinks = [{ title: '', url: '' }];
    }
  }

  @Input() theme = 'default';

  @Output() changeEvent = new EventEmitter<Array<TestReportLink>>();

  constructor() {}

  add(): void {
    this.links.push({ title: '', url: '' });
    this.notifyChange();
  }

  remove(index: number): void {
    this.links.splice(index, 1);
    this.notifyChange();
  }

  notifyChange() {
    this.changeEvent.next(this.links);
  }

  onInputChanged(index: number, propName: string, event) {
    const value = event.target.value;
    this.links[index][propName] = value;
    this.notifyChange();
  }
}
