<div class="app-container">
  <app-test-header
    [title]="'Flechas Sagitales'"
    [icon]="'fas fa-database'"
    [learnId]="'RaquisFlechas'"
    [learnURL]="
      'https://manual.intecc.org/es/article/valoracion-de-las-flechas-sagitales'
    "
  ></app-test-header>

  <div class="test-info">
    <app-client-back-button [client]="client"></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
      <button (click)="picker.open()" class="date-selector__button">
        {{ test.createdAt.toDate() | date }} <i class="fas fa-edit"></i>
      </button>

      <input
        style="display: none"
        (dateChange)="onDateChanged($event)"
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
      />
      <mat-datepicker
        touchUi
        #picker
        [startAt]="test.createdAt.toDate()"
      ></mat-datepicker>
    </div>
  </div>

  <div class="test-card">
    <div class="test-card__heading">
      <div class="test-card__title tooltip">
        Flechas raquídeas
        <div class="top top--test-card">
          <h3>Flechas raquídeas</h3>
          <p>
            En bipedestación, manteniendo una postura relajada. Acercar el hilo
            de la plomada. Asegurar la verticalidad del hilo.
          </p>
          <i></i>
        </div>
      </div>
      <div class="test-card__help">
        <div class="test-card__help-item test-card__help-item--small">
          <i (click)="openGif()" class="fas fa-photo-video"></i>
        </div>
        <div class="test-card__help-item">
          <i
            (click)="openLearn('sagital-flechas-test')"
            class="fas fa-book"
          ></i>
        </div>
      </div>
    </div>

    <div class="test-card__divider"></div>
    <div class="test-card__content">
      <form class="form" [formGroup]="flechasForm">
        <div class="test-card__row">
          <div class="test-card__label">
            <p>FC - Flecha Cervical</p>
          </div>
          <div class="test-card__value">
            <input formControlName="fc" type="number" class="form__input" />
            <p class="unit-info">cm</p>
          </div>
        </div>
        <div class="test-card__row">
          <div class="test-card__label">
            <p>FT - Flecha Torácica</p>
          </div>
          <div class="test-card__value">
            <input formControlName="ft" type="number" class="form__input" />
            <p class="unit-info">cm</p>
          </div>
        </div>
        <div class="test-card__row">
          <div class="test-card__label">
            <p>FL - Flecha Lumbar</p>
          </div>
          <div class="test-card__value">
            <input formControlName="fl" type="number" class="form__input" />
            <p class="unit-info">cm</p>
          </div>
        </div>
        <div class="test-card__row">
          <div class="test-card__label">
            <p>FS - Flecha Sacra</p>
          </div>
          <div class="test-card__value">
            <input formControlName="fs" type="number" class="form__input" />
            <p class="unit-info">cm</p>
          </div>
        </div>
        <div
          class="test-card__ma-section"
          [class.test-card__ma-section--open]="test.ic > 65"
        >
          <div class="test-card__ma tooltip">
            Maniobra de Autocorrección
            <div class="top">
              <h3>Maniobra de Autocorrección</h3>
              <p>
                El resultado del índice cifótico es superior a 65º, por lo
                tanto, hay que volver a cuantificar en máxima corrección activa.
              </p>
              <p>
                Se invita a nuestro cliente a que se estire al máximo (como
                creciendo) pero sin crear hiperlordosis, buscando la máxima
                reducción de su cifosis dorsal
              </p>
            </div>
          </div>

          <div class="test-card__row">
            <div class="test-card__label">
              <p>FC - Flecha Cervical</p>
            </div>
            <div class="test-card__value">
              <input formControlName="fca" type="number" class="form__input" />
              <p class="unit-info">cm</p>
            </div>
          </div>
          <div class="test-card__row">
            <div class="test-card__label">
              <p>FT - Flecha Torácica</p>
            </div>
            <div class="test-card__value">
              <input formControlName="fta" type="number" class="form__input" />
              <p class="unit-info">cm</p>
            </div>
          </div>
          <div class="test-card__row">
            <div class="test-card__label">
              <p>FL - Flecha Lumbar</p>
            </div>
            <div class="test-card__value">
              <input formControlName="fla" type="number" class="form__input" />
              <p class="unit-info">cm</p>
            </div>
          </div>
          <div class="test-card__row">
            <div class="test-card__label">
              <p>FS - Flecha Sacra</p>
            </div>
            <div class="test-card__value">
              <input formControlName="fsa" type="number" class="form__input" />
              <p class="unit-info">cm</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="test-card">
    <div class="test-card__heading">
      <div class="test-card__title tooltip">
        Índice Cifótico
        <div class="top top--test-card">
          <h3>Índice Cifótico</h3>
          <p>
            Si ninguna flecha da como valor 0, se le restará la flecha menor a
            todas las medidas y se utilizarán las siguientes fórmulas:
          </p>
          <p><b>Fórmula del eje atrasado. FT = 0</b></p>
          <p>
            Índice Cifótico (IC) es igual a la Flecha Cervical (FC) más la
            Flecha Lumbar (FL) más la Flecha Sacra (FS), divididas entre 2.
            IC=(FC+FL)/2
          </p>
          <p><b>Fórmula del eje adelantado. FS = 0</b></p>
          <p>
            Índice Cifótico (IC) es igual a la Flecha Cervical (FC) más la
            Flecha Lumbar (FL) más la Flecha Sacra cuyo valor será 0 (FS),
            divididas entre 2. IC=(FC+FL)/2
          </p>
          <i></i>
        </div>
      </div>
      <div class="test-card__help">
        <!-- <i (click)="openLearn('sagital-test-bipedestacion');" class="fas fa-book"></i> -->
      </div>
    </div>

    <div class="test-card__divider"></div>
    <div class="test-card__content">
      <form class="form">
        <div class="test-card__row">
          <div class="test-card__label">
            <p>IC - Índice Cifótico</p>
          </div>
          <div class="test-card__value">
            <input
              readonly
              [value]="test.ic"
              type="number"
              class="form__input"
            />
          </div>
        </div>
        <div *ngIf="test.ica" class="test-card__row">
          <div class="test-card__label">
            <p>ICA - I.C. Autocorregido</p>
          </div>
          <div class="test-card__value">
            <input
              readonly
              [value]="test.ica"
              type="number"
              class="form__input"
            />
          </div>
        </div>
        <div class="test-card__row">
          <div class="test-card__label">
            <p><b>Resultado</b></p>
          </div>
          <div
            class="test-card__value test-card__value--result"
            [class.test-card__value--result--empty]="!test.dorsalResult"
          >
            {{ test.dorsalResult ? test.dorsalResult : '--' }}
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="test-card">
    <div class="test-card__heading">
      <div class="test-card__title tooltip">
        Índice Lordótico
        <div class="top top--test-card">
          <h3>Índice Lordótico</h3>
          <p>
            Si ninguna flecha da como valor 0, se le restará la flecha menor a
            todas las medidas y se utilizarán las siguientes fórmulas:
          </p>
          <p><b>Fórmula del eje atrasado. FT = 0</b></p>
          <p>
            Índice Lordótico (IL) es igual a la Flecha Lumbar (FL) menos la
            mitad de la Flecha Sacra (FS). IL=FL - FS/2
          </p>
          <p><b>Fórmula del eje adelantado. FS = 0</b></p>
          <p>
            Índice Lordótico (IL) es igual a la Flecha Lumbar (FL) menos la
            mitad de la Flecha Torácica (FT). IL=FL - FT/2
          </p>
          <i></i>
        </div>
      </div>
      <div class="test-card__help">
        <!-- <i (click)="openLearn('sagital-test-bipedestacion');" class="fas fa-book"></i> -->
      </div>
    </div>

    <div class="test-card__divider"></div>
    <div class="test-card__content">
      <form class="form">
        <div class="test-card__row">
          <div class="test-card__label">
            <p>IL - Índice Lordótico</p>
          </div>
          <div class="test-card__value">
            <input
              readonly
              [value]="test.il"
              type="number"
              class="form__input"
            />
          </div>
        </div>
        <!-- <div *ngIf="test.ica" class="test-card__row">
                <div class="test-card__label">
                    <p>I.L. Autocorregido</p>
                </div>
                <div class="test-card__value">
                    <input readonly [value]="test.ica" type="number" class="form__input">
                </div>
            </div> -->
        <div class="test-card__row">
          <div class="test-card__label">
            <p><b>Resultado</b></p>
          </div>
          <div
            class="test-card__value test-card__value--result"
            [class.test-card__value--result--empty]="!test.dorsalResult"
          >
            {{ test.lumbarResult ? test.lumbarResult : '--' }}
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="test-card">
    <div class="test-card__heading">
      <div class="test-card__title">Comentarios</div>
      <div class="test-card__help">
        <!-- <i (click)="openLearn('sagital-test-bipedestacion');" class="fas fa-book"></i> -->
      </div>
    </div>

    <div class="test-card__divider"></div>
    <div class="test-card__content">
      <div class="test-card__comments-box">
        <textarea
          #commentsTextArea
          name="comments"
          cols="30"
          rows="5"
          [value]="test.comments ? test.comments : ''"
          (change)="onCommentsChanged($event)"
        ></textarea>
      </div>
    </div>
  </div>
</div>
