<div class="relative grid h-full grid-rows-header-content text-white">
  <div class="flex justify-between bg-black px-5 py-3">
    <div class="flex items-center font-bold">
      <i class="fas fa-draw-polygon mr-2"></i>
      <p>Editor</p>
    </div>

    <div class="flex items-center">
      <!-- link button -->
      <a
        *ngIf="renderUrl"
        class="mr-2 flex cursor-pointer items-center rounded bg-violet-500 px-3 py-1 font-bold text-white"
        [href]="renderUrl"
      >
        render view
      </a>
      <div
        class="flex cursor-pointer items-center rounded bg-violet-500 px-3 py-1 font-bold text-white"
        (click)="saveAndClose()"
      >
        <i class="fas fas fas fa-check-circle mr-2"></i>
        <p>Terminar</p>
      </div>
      <!-- <div class="cursor-pointer flex items-center font-bold bg-violet-500 text-white px-3 py-1 rounded"
                (click)="domToImage()">
                <i class=" fas fas fas fa-check-circle mr-2"></i>
                <p>Test</p>
            </div> -->
    </div>
  </div>
  <div class="relative grid h-full grid-cols-content-bar grid-rows-min-max-1fr">
    <div
      #divElement
      class="relative h-full w-full overflow-hidden bg-neutral-700"
      style="touch-action: none"
    >
      <svg
        #svgElement
        class="h-full w-full"
        [attr.viewBox]="
          viewBoxX + ' ' + viewBoxY + ' ' + viewBoxW + ' ' + viewBoxH
        "
      >
        <!-- <image [attr.href]="editedMediaItem.downloadURL" height="90%" width="90%"/>
                <ellipse cx="50" cy="50" rx="50" ry="50"></ellipse> -->
      </svg>
      <div
        class="absolute right-5 top-5 grid auto-cols-auto grid-flow-col gap-x-5 rounded bg-black px-4 text-lg text-white"
      >
        <div
          class="grid auto-cols-auto grid-flow-col gap-x-5"
          *ngIf="!cropping"
        >
          <div
            (click)="zoomIn()"
            class="flex h-10 cursor-pointer items-center justify-center px-2"
          >
            <i class="fas fa-search-plus"></i>
          </div>
          <div
            (click)="zoomOut()"
            class="flex h-10 cursor-pointer items-center justify-center px-2"
          >
            <i class="fas fas fa-search-minus"></i>
          </div>
          <div
            (click)="resetView()"
            class="flex h-10 cursor-pointer items-center justify-center px-2"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fa-compress"></i>
          </div>
          <div
            (click)="startCrop()"
            class="flex h-10 cursor-pointer items-center justify-center px-2"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fa-crop-alt"></i>
          </div>
        </div>
        <div *ngIf="cropping" class="grid auto-cols-auto grid-flow-col gap-x-5">
          <div
            (click)="setCropMode('f')"
            class="cursor-pointer px-2 h-10 flex items-center justify-center {{
              cropMode === 'f' ? 'text-blue-600' : ''
            }}"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fa-vector-square"></i>
          </div>
          <div
            (click)="setCropMode('s')"
            class="cursor-pointer px-2 h-10 flex items-center justify-center {{
              cropMode === 's' ? 'text-blue-600' : ''
            }}"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="far fa-square"></i>
          </div>
          <div
            (click)="setCropMode('v')"
            class="cursor-pointer px-2 h-10 flex items-center justify-center {{
              cropMode === 'v' ? 'text-blue-600' : ''
            }}"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fa-arrows-alt-v"></i>
          </div>
          <div
            (click)="setCropMode('h')"
            class="cursor-pointer px-2 h-10 flex items-center justify-center {{
              cropMode === 'h' ? 'text-blue-600' : ''
            }}"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fas fa-arrows-alt-h"></i>
          </div>
          <div
            (click)="endCrop()"
            class="flex h-10 cursor-pointer items-center justify-center px-2"
          >
            <!-- <i class="material-icons" style="font-size: 21px;">center_focus_weak</i> -->
            <i class="fas fa-check-circle"></i>
          </div>
        </div>
      </div>
      <div
        class="me-hidden-desktop absolute bottom-5 left-5 transition-all duration-200"
        [class.left-32]="sideMenuOpenMobile"
      >
        <div
          (click)="toggleSideMenuMobile()"
          class="flex h-12 w-12 items-center justify-center rounded-full bg-white text-lg text-black"
        >
          <i
            class="{{
              sideMenuOpenMobile ? 'fas fa-arrow-left' : 'fas fa-pen-nib'
            }}"
          ></i>
        </div>
      </div>
      <div
        class="me-selected-item-menu absolute bottom-0 left-0 flex h-24 w-full items-center justify-center"
        [class.me-selected-item-menu--open]="selectedItemMenuOpen"
      >
        <div
          class="flex h-full w-full items-center justify-center rounded-lg bg-black px-5 md:h-auto md:w-auto"
        >
          <div
            class="mr-2 flex w-28 cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-neutral-900"
          >
            <i class="fas fa-arrows-alt mb-2"></i>
            <p>Modo preciso</p>
          </div>
          <div
            (click)="deleteSelectedItem()"
            class="mr-2 flex w-28 cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-neutral-900"
          >
            <i class="far fa-trash-alt mb-2"></i>
            <p>Eliminar</p>
          </div>
          <div
            (click)="unselect()"
            class="flex w-28 cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-neutral-900"
          >
            <i class="fas fa-times mb-2"></i>
            <p>Cerrar</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="me-lateral-menu w-36 bg-neutral-900 py-5"
      [class.me-lateral-menu--open]="sideMenuOpenMobile"
    >
      <div
        (click)="addCross()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-plus mb-2 text-2xl"></i>
        <p>Cruceta</p>
      </div>

      <div
        (click)="addAngle()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-angle-left mb-2 text-2xl"></i>
        <p>Ángulo</p>
      </div>
      <div
        (click)="addMeasureRef()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-pencil-ruler mb-2 text-2xl"></i>
        <p>Referencia</p>
      </div>
      <div
        (click)="addMeasure()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-ruler mb-2 text-2xl"></i>
        <p>Medida</p>
      </div>
      <div
        (click)="addText()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-italic mb-2 text-2xl"></i>
        <p>Texto</p>
      </div>
      <div
        (click)="addRect()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-square mb-2 text-2xl"></i>
        <p>Rectángulo</p>
      </div>
      <div
        (click)="addRectEmpty()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-vector-square mb-2 text-2xl"></i>
        <p>Rectángulo</p>
      </div>
      <!-- <div (click)="addCross()"
                class="w-full flex flex-col items-center py-3 hover:bg-black transition-all duration-200 cursor-pointer mb-1">
                <i class="mb-2 far fa-dot-circle"></i>
                <p>Punto</p>
            </div> -->
      <div
        (click)="addArrow()"
        class="mb-1 flex w-full cursor-pointer flex-col items-center py-3 transition-all duration-200 hover:bg-black"
      >
        <i class="fas fa-arrow-right mb-2 text-2xl"></i>
        <p>Flecha</p>
      </div>
    </div>
  </div>
  <app-media-edit-input-modal></app-media-edit-input-modal>
</div>
