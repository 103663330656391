import { Test, TestType } from './tests.model';


export const findLimitIndex = (limits: Array<number>, value: number) => {
    for (let i = 0; i < limits.length; i++) {
        const element = limits[i];
        if (value < element) {
            return i;
        }
    }
    return limits.length;
};

export interface RomTest extends Test {
    results: {[id: string]: RomResult};
}

export interface RomResult {
    value: number;
    resultLevel?: RomResultLevel;
    resultName?: string;
}

export enum RomResultLevel {
    empty = 'empty',
    bad = 'bad',
    good = 'good',
    optimum = 'optimum',
    excessive = 'excessive'
}

export const RomResultLevelOrder = {
    excessive: 0,
    bad: 1,
    good: 2,
    optimum: 3,
    empty: 4,
};


export const RomMovementsIds = {
    flexsionDorsalGemelo: 'flexsionDorsalGemelo',
    flexionDorsalSoleo: 'flexionDorsalSoleo',
    extensionIsqCua: 'extensionIsqCua',
    flexionCua: 'flexionCua',
    rotacionExtAductores: 'rotacionExtAductores',
    rotacionIntGlueto: 'rotacionIntGlueto',
    abduccionSinFlexionAductores: 'abduccionSinFlexionAductores',
    abduccionConFlexionAductores: 'abduccionConFlexionAductores',
    aduccionConFlexionAbductores: 'aduccionConFlexionAbductores',
    flexionGluteo: 'flexionGluteo',
    extensionPsoas: 'extensionPsoas',
    rotacionExternaPectoral: 'rotacionExternaPectoral',
    rotacionInternaInfraespinoso: 'rotacionInternaInfraespinoso',
    flexionDorsalAncho: 'flexionDorsalAncho',
    extensionDeltoides: 'extensionDeltoides',
    abduccionPectoralMayor: 'AbduccioónPectoralMayor',
    aduccionInfraespinoso: 'AduccionInfraespinoso',
    flexionTriceps: 'flexionTriceps',
    extensionBiceps: 'extensionBiceps',

    flexsionDorsalGemeloR: 'flexsionDorsalGemeloR',
    flexionDorsalSoleoR: 'flexionDorsalSoleoR',
    extensionIsqCuaR: 'extensionIsqCuaR',
    flexionCuaR: 'flexionCuaR',
    rotacionExtAductoresR: 'rotacionExtAductoresR',
    rotacionIntGluetoR: 'rotacionIntGluetoR',
    abduccionSinFlexionAductoresR: 'abduccionSinFlexionAductoresR',
    abduccionConFlexionAductoresR: 'abduccionConFlexionAductoresR',
    aduccionConFlexionAbductoresR: 'aduccionConFlexionAbductoresR',
    flexionGluteoR: 'flexionGluteoR',
    extensionPsoasR: 'extensionPsoasR',
    rotacionExternaPectoralR: 'rotacionExternaPectoralR',
    rotacionInternaInfraespinosoR: 'rotacionInternaInfraespinosoR',
    flexionDorsalAnchoR: 'flexionDorsalAnchoR',
    extensionDeltoidesR: 'extensionDeltoidesR',
    abduccionPectoralMayorR: 'AbduccioónPectoralMayorR',
    aduccionInfraespinosoR: 'AduccionInfraespinosoR',
    flexionTricepsR: 'flexionTricepsR',
    extensionBicepsR: 'extensionBicepsR',

    free: 'free',
};

export interface RomMovement {
    id: string;
    name: string;
    subname?: string;
    limits: Array<number>;
    tutGif?: string;
    right?: boolean;
}

export const RomMovements: {[id: string]: RomMovement} = {
    [RomMovementsIds.flexsionDorsalGemelo]: {id: RomMovementsIds.flexsionDorsalGemelo, name: 'Flexión Dorsal', subname: 'Gemelo', limits: [25, 30, 45] , tutGif: 'gif-1'},
    [RomMovementsIds.flexionDorsalSoleo]: {id: RomMovementsIds.flexionDorsalSoleo, name: 'Flexión Dorsal', subname: 'Sóleo', limits: [35, 40, 55] },
    [RomMovementsIds.extensionIsqCua]: {id: RomMovementsIds.extensionIsqCua, name: 'Flexión', subname: 'Isquiosurales', limits: [70, 80, 110] },
    [RomMovementsIds.flexionCua]: {id: RomMovementsIds.flexionCua, name: 'Flexión', subname: 'Cuádriceps', limits: [120, 135, 160] },
    [RomMovementsIds.rotacionExtAductores]: {id: RomMovementsIds.rotacionExtAductores, name: 'Rotación Externa', subname: 'Aductores', limits: [45, 50, 75] },
    [RomMovementsIds.rotacionIntGlueto]: {id: RomMovementsIds.rotacionIntGlueto, name: 'Rotación Interna', subname: 'Glúteo medio', limits: [40, 45, 60] },
    [RomMovementsIds.abduccionSinFlexionAductores]: {id: RomMovementsIds.abduccionSinFlexionAductores, name: 'Abducción sin Flexión', subname: 'Aductores', limits: [40, 45, 55] },
    [RomMovementsIds.abduccionConFlexionAductores]: {id: RomMovementsIds.abduccionConFlexionAductores, name: 'Abducción con Flexión', subname: 'Aductores', limits: [75, 80, 90] },
    [RomMovementsIds.aduccionConFlexionAbductores]: {id: RomMovementsIds.aduccionConFlexionAbductores, name: 'Aducción con Flexión', subname: 'Abductores', limits: [25, 30, 50] },
    [RomMovementsIds.flexionGluteo]: {id: RomMovementsIds.flexionGluteo, name: 'Flexión', subname: 'Glúteo Mayor', limits: [125, 135, 150] },
    [RomMovementsIds.extensionPsoas]: {id: RomMovementsIds.extensionPsoas, name: 'Extensión', subname: 'Psoas', limits: [0, 10, 40] },
    [RomMovementsIds.rotacionExternaPectoral]: {id: RomMovementsIds.rotacionExternaPectoral, name: 'Rotación Externa', subname: 'Pectoral Mayor y Dorsal', limits: [70, 100, 120] },
    [RomMovementsIds.rotacionInternaInfraespinoso]: {id: RomMovementsIds.rotacionInternaInfraespinoso, name: 'Rotación Interna', subname: 'Infra y Redondo Menor', limits: [65, 90, 120] },
    [RomMovementsIds.flexionDorsalAncho]: {id: RomMovementsIds.flexionDorsalAncho, name: 'Flexión', subname: 'Dorsal Ancho', limits: [150, 170, 190] },
    [RomMovementsIds.extensionDeltoides]: {id: RomMovementsIds.extensionDeltoides, name: 'Extensión', subname: 'Pectoral Mayor', limits: [50, 60, 70] },
    [RomMovementsIds.abduccionPectoralMayor]: {id: RomMovementsIds.abduccionPectoralMayor, name: 'Abducción', subname: 'Pectoral Mayor', limits: [0, 20, 45] },
    [RomMovementsIds.aduccionInfraespinoso]: {id: RomMovementsIds.aduccionInfraespinoso, name: 'Aducción', subname: 'Infra y Redondo Menor', limits: [120, 135, 145] },
    [RomMovementsIds.flexionTriceps]: {id: RomMovementsIds.flexionTriceps, name: 'Flexión', subname: 'Tríceps', limits: [140, 150, 160] },
    [RomMovementsIds.extensionBiceps]: {id: RomMovementsIds.extensionBiceps, name: 'Extensión', subname: 'Bíceps', limits: [170, 180, 185] },

    [RomMovementsIds.flexsionDorsalGemeloR]: {id: RomMovementsIds.flexsionDorsalGemeloR, name: 'Flexión Dorsal', subname: 'Gemelo', limits: [25, 30, 45] , tutGif: 'gif-1', right: true},
    [RomMovementsIds.flexionDorsalSoleoR]: {id: RomMovementsIds.flexionDorsalSoleoR, name: 'Flexión Dorsal', subname: 'Sóleo', limits: [35, 40, 55] , right: true},
    [RomMovementsIds.extensionIsqCuaR]: {id: RomMovementsIds.extensionIsqCuaR, name: 'Extensión', subname: 'Isquiosurales', limits: [70, 80, 110] , right: true},
    [RomMovementsIds.flexionCuaR]: {id: RomMovementsIds.flexionCuaR, name: 'Flexión', subname: 'Cuádriceps', limits: [120, 135, 160] , right: true},
    [RomMovementsIds.rotacionExtAductoresR]: {id: RomMovementsIds.rotacionExtAductoresR, name: 'Rotación Externa', subname: 'Aductores', limits: [45, 50, 75] , right: true},
    [RomMovementsIds.rotacionIntGluetoR]: {id: RomMovementsIds.rotacionIntGluetoR, name: 'Rotación Interna', subname: 'Glúteo medio', limits: [40, 45, 60] , right: true},
    [RomMovementsIds.abduccionSinFlexionAductoresR]: {id: RomMovementsIds.abduccionSinFlexionAductoresR, name: 'Abducción sin Flexión', subname: 'Aductores', limits: [40, 45, 55] , right: true},
    [RomMovementsIds.abduccionConFlexionAductoresR]: {id: RomMovementsIds.abduccionConFlexionAductoresR, name: 'Abducción con Flexión', subname: 'Aductores', limits: [75, 80, 90] , right: true},
    [RomMovementsIds.aduccionConFlexionAbductoresR]: {id: RomMovementsIds.aduccionConFlexionAbductoresR, name: 'Aducción con Flexión', subname: 'Abductores', limits: [25, 30, 50] , right: true},
    [RomMovementsIds.flexionGluteoR]: {id: RomMovementsIds.flexionGluteoR, name: 'Flexión', subname: 'Glúteo Mayor', limits: [125, 135, 150] , right: true},
    [RomMovementsIds.extensionPsoasR]: {id: RomMovementsIds.extensionPsoasR, name: 'Extensión', subname: 'Psoas', limits: [0, 10, 40] , right: true},
    [RomMovementsIds.rotacionExternaPectoralR]: {id: RomMovementsIds.rotacionExternaPectoralR, name: 'Rotación Externa', subname: 'Pectoral Mayor y Dorsal', limits: [70, 100, 120] , right: true},
    [RomMovementsIds.rotacionInternaInfraespinosoR]: {id: RomMovementsIds.rotacionInternaInfraespinosoR, name: 'Rotación Interna', subname: 'Infra y Redondo Menor', limits: [65, 90, 120] , right: true},
    [RomMovementsIds.flexionDorsalAnchoR]: {id: RomMovementsIds.flexionDorsalAnchoR, name: 'Flexión', subname: 'Dorsal Ancho', limits: [150, 170, 190] , right: true},
    [RomMovementsIds.extensionDeltoidesR]: {id: RomMovementsIds.extensionDeltoidesR, name: 'Extensión', subname: 'Pectoral Mayor', limits: [50, 60, 70] , right: true},
    [RomMovementsIds.abduccionPectoralMayorR]: {id: RomMovementsIds.abduccionPectoralMayorR, name: 'Abducción', subname: 'Pectoral Mayor', limits: [0, 20, 45] , right: true},
    [RomMovementsIds.aduccionInfraespinosoR]: {id: RomMovementsIds.aduccionInfraespinosoR, name: 'Aducción', subname: 'Infra y Redondo Menor', limits: [120, 135, 145] , right: true},
    [RomMovementsIds.flexionTricepsR]: {id: RomMovementsIds.flexionTricepsR, name: 'Flexión', subname: 'Tríceps', limits: [140, 150, 160] , right: true},
    [RomMovementsIds.extensionBicepsR]: {id: RomMovementsIds.extensionBicepsR, name: 'Extensión', subname: 'Bíceps', limits: [170, 180, 185] , right: true},

    [RomMovementsIds.free]: {id: RomMovementsIds.free, name: 'Libre', limits: [180]}
};

export const RomMovementGifs: {[id: string]: string} = {
    [RomMovementsIds.flexsionDorsalGemelo]: 'gif_flex_lumbar_movil.gif'
};

export function createEmptyRomTest(): RomTest {
    return {
        type: ('rom') as TestType,
        owner: '',
        client: '',
        results: {}
    };
}


export function getResultLevel(mId: string, value: number): RomResultLevel {
    const limits = RomMovements[mId].limits;
    if (!value) {
        return RomResultLevel.empty;
    }
    const limitIndex = findLimitIndex(limits, value);
    if (limitIndex === 0) {
        return RomResultLevel.bad;
    } else if (limitIndex === 1) {
        return RomResultLevel.good;
    } else if (limitIndex === 2) {
        return RomResultLevel.optimum;
    } else if (limitIndex === 3) {
        return RomResultLevel.excessive;
    }
    return RomResultLevel.empty
}

export function getResultLevelName(resultLevel: RomResultLevel): string {
    if (resultLevel === RomResultLevel.empty) {
        return '--';
    }
    if (resultLevel === RomResultLevel.bad) {
        return 'Limitado';
    }
    if (resultLevel === RomResultLevel.good) {
        return 'Normal';
    }
    if (resultLevel === RomResultLevel.optimum) {
        return 'Óptimo';
    }
    if (resultLevel === RomResultLevel.excessive) {
        return 'Error';
    }
    return '--'
}
export function getResultLevelNameLetter(resultLevel: RomResultLevel): string {
    if (resultLevel === RomResultLevel.empty) {
        return '--';
    }
    if (resultLevel === RomResultLevel.bad) {
        return 'L';
    }
    if (resultLevel === RomResultLevel.good) {
        return 'N';
    }
    if (resultLevel === RomResultLevel.optimum) {
        return 'O';
    }
    if (resultLevel === RomResultLevel.excessive) {
        return 'E';
    }
    return '--'
}

export function setResult(test: RomTest, mId: string, value: number) {
    if (!!test.results[mId]) {
        test.results[mId].value = value;
    } else {
        const result: RomResult = { value };
        test.results[mId] = result;
    }

    test.results[mId].resultLevel = getResultLevel(mId, value);
    test.results[mId].resultName = getResultLevelName(test.results[mId].resultLevel);
}

export function findWorstLevel(levels: RomResultLevel[]){
    const result = levels.reduce((prev, curr) => {
        if(RomResultLevelOrder[curr] < RomResultLevelOrder[prev]){
            return RomResultLevel[curr];
        }
        return RomResultLevel[prev];
    }, RomResultLevel.empty);
    return result;
}