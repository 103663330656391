<div class="relative h-full w-full">
  <div
    *ngIf="correctiveSessionProtocol"
    class="max-w-4xl mx-auto rounded-xl bg-white ring-1 ring-black/5 shadow-sm transition-all duration-200 {{
      sectionEditMode ? 'protocol-edit-card-hidden' : ''
    }}"
  >
    <div
      class="flex flex-col items-center px-8 py-6 md:flex-row md:justify-between"
    >
      <h1 class="mb-3 text-center text-3xl font-bold md:mb-0">
        {{
          correctiveSessionProtocol.name
            ? correctiveSessionProtocol.name
            : 'Sesión de ejercicios correctivos'
        }}
      </h1>
      <div class="flex flex-col items-center md:flex-row">
        <app-two-state-button
          class="mb-3 md:mb-0 md:mr-4"
          (buttonClick)="duplicate()"
          [loading]="deleting"
          [text]="'Duplicar'"
          [type]="'normal'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-copy'"
        ></app-two-state-button>
        <app-two-state-button
          (buttonClick)="saveAndExit()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
    </div>
    <div class="mb-2">
      <div class="border-t border-neutral-200"></div>
    </div>
    <div class="px-8 py-6">
      <div class="mb-4">
        <h2 class="mb-2 text-2xl">Título</h2>
        <app-input [(value)]="correctiveSessionProtocol.name"></app-input>
      </div>
      <div class="grid grid-cols-1 gap-y-3">
        <div>
          <h2 class="mb-2 text-2xl">Detalles</h2>
          <app-input
            [type]="'textarea'"
            class="md:col-span-2"
            [fieldName]="'Descripción'"
            [(value)]="correctiveSessionProtocol.description"
          ></app-input>
        </div>
        <div class="flex items-center justify-between">
          <p class="mb-1 font-bold text-neutral-600">Imagen y enlace</p>
          <app-button
            (clickEvent)="addDescriptionFixedImage()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
          ></app-button>
        </div>
      </div>
      <div
        cdkDropList
        class="test-list"
        (cdkDropListDropped)="dropDescriptionFixedImage($event)"
      >
        <div
          class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
          *ngFor="
            let image of correctiveSessionProtocol.fixedImages;
            let i = index
          "
          cdkDrag
        >
          <div class="flex w-full items-center justify-between">
            <div class="transition-all duration-200">
              <!-- <app-input class="mb-2 block" [fieldName]="'Título'" [(value)]="image.title"></app-input> -->
              <div
                class="grid w-full grid-cols-1 items-end gap-x-5 gap-y-3 md:grid-cols-4"
              >
                <app-input
                  [readonly]="true"
                  [fieldName]="'URL de la imagen'"
                  [(value)]="image.downloadUrl"
                  [button]="getImageUrlCopyButton(image.downloadUrl)"
                >
                </app-input>
                <app-input [fieldName]="'Título'" [(value)]="image.title">
                </app-input>
                <app-input
                  [fieldName]="'Enlace (opcional)'"
                  [(value)]="image.link"
                >
                </app-input>
                <app-button
                  (clickEvent)="uploadDescriptionFixedImage(i)"
                  [text]="'Subir imagen '"
                  [icon]="'fas fas fa-upload'"
                >
                </app-button>
              </div>
              <div
                class="grid grid-cols-1 items-end gap-x-5 gap-y-3 pt-5 md:grid-cols-2"
              >
                <!-- <app-input [placeholder]="'https://...'" [fieldName]="'Enlace (opcional)'" [(value)]="image.link">
                </app-input> -->
                <!-- <app-simple-dropdown [fieldName]="'Ubicación en el informe PDF'" [options]="imagePositionOptions"
                  [(selectedValue)]="image.position"></app-simple-dropdown> -->
              </div>
            </div>
            <div
              (click)="deleteDescriptionFixedImage(i, $event)"
              class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
            >
              <i class="far fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="my-8">
        <h2 class="mb-2 text-2xl">Enlace para compartir</h2>
        <app-input
          class="md:col-span-2"
          [readonly]="true"
          [value]="getShareURL()"
          [button]="copySharedUrlButton"
        ></app-input>
      </div>
      <div class="mb-4 flex items-center justify-between">
        <h1 class="text-2xl">Secciones</h1>
        <app-button
          (clickEvent)="addSection()"
          [text]="'Añadir'"
          [icon]="'fas fa-plus'"
        ></app-button>
      </div>
      <div
        cdkDropList
        cdkDropListLockAxis="y"
        class="test-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          (click)="openSection(i)"
          class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-3 focus:shadow-xl"
          *ngFor="
            let section of correctiveSessionProtocol.sections;
            let i = index
          "
          cdkDrag
        >
          <div class="flex w-full items-center justify-between">
            <span>{{ section.name }}</span>
            <div class="flex">
              <div
                (click)="duplicateSection(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-copy"></i>
              </div>
              <div
                (click)="deleteSection(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-10"></div>
      <div class="flex justify-end">
        <app-two-state-button
          class="mr-5"
          (buttonClick)="deleteAndExit()"
          [loading]="deleting"
          [text]="'Eliminar'"
          [type]="'danger'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-trash-alt'"
        ></app-two-state-button>
        <app-two-state-button
          (buttonClick)="saveAndExit()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
    </div>
  </div>
  <div
    class="w-full h-full absolute top-0 left-0 {{
      sectionEditMode ? '' : 'hidden'
    }}"
  >
    <div
      *ngIf="selectedSection"
      class="animated fadeIn mx-auto max-w-4xl rounded-xl bg-white shadow-sm ring-1 ring-black/5"
    >
      <div
        class="flex flex-col items-center px-8 py-6 md:flex-row md:justify-between"
      >
        <h1 class="mb-3 text-3xl font-bold md:mb-0">
          {{ selectedSection.name }}
        </h1>
        <app-two-state-button
          (buttonClick)="saveSectionAndBack()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
      <div class="mb-2">
        <div class="border-t border-neutral-200"></div>
      </div>
      <div class="px-8 py-6">
        <div class="my-4">
          <h2 class="mb-2 text-2xl">Título</h2>
          <app-input [(value)]="selectedSection.name"></app-input>
        </div>
        <div class="my-4">
          <h2 class="mb-2 text-2xl">Detalles</h2>
          <app-input
            [type]="'textarea'"
            class="mt-2 block"
            [fieldName]="'Descripción'"
            [(value)]="selectedSection.description"
          ></app-input>
        </div>
        <h2 class="mb-5 text-2xl">Ejercicios y carga de entrenamiento</h2>

        <!-- Commented because it will be "Ejercicios" always -->
        <!-- <div class="mb-5"></div>
          <app-input
            [fieldName]="'Título de la primera columna'"
            [(value)]="selectedSection.tableFirstColTitle"
            [readonly]="true"
          >
          </app-input> -->

        <ng-container *ngIf="selectedSection.sectionExercises">
          <!-- New version with exercises entity -->
          <!-- Ejercicios -->
          <div class="mb-4 flex items-center justify-between">
            <p class="mb-1 font-bold text-neutral-600">Ejercicios</p>
            <app-button
              (clickEvent)="addSectionExercises()"
              [text]="'Añadir '"
              [icon]="'fas fa-plus'"
            ></app-button>
          </div>
          <!-- cdkDropListDisabled not used here because using keys instead of indexes for new exercises entity -->
          <div
            cdkDropList
            [cdkDropListDisabled]="correctiveSessionProtocolInUse ? null : null"
            cdkDropListLockAxis="y"
            class="test-list"
            (cdkDropListDropped)="dropSectionExercise($event)"
          >
            <div
              class="mb-2 flex w-full cursor-pointer items-center justify-between rounded-md border border-neutral-300 bg-white p-5 focus:shadow-xl"
              [class.cursor-default]="correctiveSessionProtocolInUse"
              *ngFor="
                let exercise of selectedSection.sectionExercises;
                let i = index
              "
              cdkDrag
            >
              <div
                class="flex-grow"
                *ngIf="
                  getExerciseDetails(exercise.exerciseId) as exerciseDetails
                "
              >
                <div
                  class="mb-2 line-clamp-1 font-bold md:hidden"
                  matTooltip="{{ exerciseDetails.title }}"
                  [matTooltipShowDelay]="1000"
                >
                  {{ exerciseDetails.title }}
                </div>
                <div
                  class="mb-2 flex w-full items-start gap-x-2 md:mb-4 md:gap-x-4"
                >
                  <!-- iamge square -->
                  <div
                    class="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md bg-neutral-200 md:h-24 md:w-24"
                  >
                    <img
                      [src]="exerciseDetails.imageUrl"
                      alt="Exercise image"
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <!-- image details (title with clamp-1 and matTooltip, same with description but 2 lines) -->
                  <div class="flex h-full flex-col justify-between">
                    <div class="hidden flex-1 md:flex">
                      <div
                        class="line-clamp-1 font-bold"
                        matTooltip="{{ exerciseDetails.title }}"
                        [matTooltipShowDelay]="1000"
                      >
                        {{ exerciseDetails.title }}
                      </div>
                    </div>
                    <div
                      class="flex-2 line-clamp-3 text-neutral-600"
                      matTooltip="{{ exerciseDetails.description }}"
                      [matTooltipShowDelay]="1000"
                    >
                      {{ exerciseDetails.description || '-' }}
                    </div>
                  </div>
                </div>
                <!-- image position dropdown -->
                <div
                  class="flex w-full items-baseline justify-between space-x-4"
                >
                  <app-simple-dropdown
                    [fieldName]="'Ubicación del ejercicio'"
                    [options]="imagePositionOptions"
                    [(selectedValue)]="exercise.position"
                  ></app-simple-dropdown>
                  <!-- delete button -->
                  <div
                    *ngIf="!correctiveSessionProtocolInUse"
                    (click)="deleteSectionExercise(exercise.exerciseId, $event)"
                    class="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
                  >
                    <i class="far fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-10"></div>
          <!-- Fin Ejercicios -->
        </ng-container>

        <div class="mb-4 flex items-center justify-between">
          <p class="mb-1 font-bold text-neutral-600">Prescripción</p>
          <app-button
            (clickEvent)="addField()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
            [disabled]="selectedSection.fields.length > 6"
          ></app-button>
        </div>
        <div
          cdkDropList
          cdkDropListDisabled
          (cdkDropListDropped)="dropField($event)"
          class="test-list"
        >
          <div
            class="test-box mb-3 rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
            *ngFor="let field of selectedSection.fields; let i = index"
            cdkDrag
          >
            <div class="flex w-full items-center justify-between">
              <div class="transition-all duration-200">
                <div
                  class="grid grid-cols-1 items-center gap-x-5 gap-y-3 md:grid-cols-3"
                >
                  <app-input
                    [readonly]="i < 6"
                    [fieldName]="''"
                    [(value)]="field.title"
                  ></app-input>
                  <ng-container *ngIf="i > 5">
                    <app-simple-dropdown
                      [readonly]="i < 6"
                      [options]="fieldTypeOptions"
                      [(selectedValue)]="field.type"
                    >
                    </app-simple-dropdown>
                  </ng-container>
                </div>
                <div *ngIf="field.type === 'select'" class="w-full pt-5">
                  <app-input
                    class="animated fadeIn"
                    [fieldName]="''"
                    [placeholder]="
                      'Opciones separadas por comas: Opción 1, Opción 2, Opción 3'
                    "
                    [(value)]="field.optionsString"
                  ></app-input>
                </div>
              </div>
              <!-- main prescription fields (first 6) should not be deletable -->
              <ng-container *ngIf="i > 5">
                <ng-container *ngIf="!correctiveSessionProtocolInUse">
                  <div
                    (click)="deleteField(i, $event)"
                    class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
                  >
                    <i class="far fa-trash-alt"></i>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="mb-10"></div>
        <ng-container *ngIf="!selectedSection.sectionExercises">
          <!-- Old version without exercises entity -->
          <!-- Ejercicios -->
          <div class="mb-4 flex items-center justify-between">
            <p class="mb-1 font-bold text-neutral-600">Ejercicios</p>
            <app-button
              (clickEvent)="addRow()"
              [text]="'Añadir '"
              [icon]="'fas fa-plus'"
            ></app-button>
          </div>
          <div
            cdkDropList
            [cdkDropListDisabled]="correctiveSessionProtocolInUse ? true : null"
            cdkDropListLockAxis="y"
            class="test-list"
            (cdkDropListDropped)="dropRow($event)"
          >
            <div
              class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
              *ngFor="
                let field of selectedSection.tableRowFields;
                let i = index
              "
              cdkDrag
            >
              <div class="flex w-full items-center justify-between">
                <div class="transition-all duration-200">
                  <div
                    class="grid grid-cols-1 items-center gap-x-5 gap-y-3 md:grid-cols-3"
                  >
                    <app-input
                      [fieldName]="''"
                      [(value)]="field.title"
                    ></app-input>
                  </div>
                  <div *ngIf="field.type === 'select'" class="w-full pt-5">
                    <app-input
                      class="animated fadeIn"
                      [fieldName]="''"
                      [placeholder]="
                        'Opciones separadas por comas: Opción 1, Opción 2, Opción 3'
                      "
                      [(value)]="field.optionsString"
                    ></app-input>
                  </div>
                </div>
                <div
                  *ngIf="!correctiveSessionProtocolInUse"
                  (click)="deleteRow(i, $event)"
                  class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-10"></div>
          <!-- Fin Ejercicios -->
          <!-- Imágenes -->
          <div class="mb-10"></div>
          <div class="mb-4 flex items-center justify-between">
            <div class="">
              <p class="mb-1 font-bold text-neutral-600">Imágenes</p>
              <!-- <p>Si quieres puedes añadir imágenes fijas que saldrán siempre en todos los informes</p> -->
            </div>
            <app-button
              (clickEvent)="addFixedImage()"
              [text]="'Añadir '"
              [icon]="'fas fa-plus'"
            ></app-button>
          </div>
          <div
            cdkDropList
            class="test-list"
            (cdkDropListDropped)="dropFixedImage($event)"
          >
            <div
              class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
              *ngFor="let image of selectedSection.fixedImages; let i = index"
              cdkDrag
            >
              <div class="flex w-full items-center justify-between">
                <div class="transition-all duration-200">
                  <app-input
                    class="mb-2 block"
                    [fieldName]="'Título'"
                    [(value)]="image.title"
                  ></app-input>
                  <div
                    class="grid w-full grid-cols-1 items-end gap-x-5 gap-y-3 md:grid-cols-2"
                  >
                    <app-input
                      [readonly]="true"
                      [fieldName]="'URL de la imagen'"
                      [(value)]="image.downloadUrl"
                      [button]="getImageUrlCopyButton(image.downloadUrl)"
                    >
                    </app-input>
                    <app-button
                      (clickEvent)="uploadFixedImage(i)"
                      [text]="'Subir imagen '"
                      [icon]="'fas fas fa-upload'"
                    >
                    </app-button>
                  </div>
                  <div
                    class="grid grid-cols-1 items-end gap-x-5 gap-y-3 pt-5 md:grid-cols-2"
                  >
                    <app-input
                      [placeholder]="'https://...'"
                      [fieldName]="'Enlace (opcional)'"
                      [(value)]="image.link"
                    >
                    </app-input>
                    <app-simple-dropdown
                      [fieldName]="'Ubicación en el informe PDF'"
                      [options]="imagePositionOptions"
                      [(selectedValue)]="image.position"
                    ></app-simple-dropdown>
                  </div>
                </div>
                <div
                  (click)="deleteFixedImage(i, $event)"
                  class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- Fin Imágenes -->
        </ng-container>
      </div>
    </div>
  </div>
</div>
