import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VafLearnData } from 'src/app/models/learn/vaf-learn-data.model';
import { FloatingVideoService } from 'src/app/services/floating-video.service';

@Component({
    selector: 'app-vaf-learn-container',
    templateUrl: './vaf-learn-container.component.html',
    styleUrls: ['./vaf-learn-container.component.scss']
})
export class VafLearnContainerComponent implements OnInit {

    @Input()
    data: VafLearnData;

    videos: Array<any> = [];

    constructor(private floatingVideoService: FloatingVideoService, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.videos = this.data.videos.map(video => {
            return {
                vimeoUrl: this.bypassSecutiry(video.vimeoId),
                vimeoId: video.vimeoId
            };
        });
    }

    openVideo(vimeoId: string) {
        this.floatingVideoService.showVideo(vimeoId);
    }

    bypassSecutiry(vimeoId: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${vimeoId}`);
    }

    onReady() {

    }

}
