import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import { CorrectiveSessionProtocolDef } from '../../../../../Server/functions/src/shared/corrective-session-definition.model';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-duplicate-corrective-session',
  templateUrl: './duplicate-corrective-session.component.html',
})
export class DuplicateCorrectiveSessionProtocolComponent implements OnInit {
  correctiveSession: CorrectiveSessionProtocolDef;

  duplicating = false;

  constructor(
    private route: ActivatedRoute,
    private correctiveSessionsService: CorrectiveSessionsService,
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const protocolId = await safeToPromise(
      this.route.params.pipe(
        map((params) => {
          return params.id;
        }),
        take(1),
      ),
    );
    this.correctiveSession =
      await this.correctiveSessionsService.getCorrectiveSessionProtocol(
        protocolId,
      );
  }

  async duplicate() {
    const user = await this.auth.getUser();
    this.correctiveSession.owner = user.uid;
    const id =
      await this.correctiveSessionsService.duplicateCorrectiveSessionProtocol(
        this.correctiveSession,
      );
    this.router.navigate(['/']);
  }
}
