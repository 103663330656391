import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { centerOfSegment } from 'src/app/utils/math';
import { getSize } from './media-config.class';

export interface MeasureRefData {
    ax: number;
    ay: number;
    bx: number;
    by: number;
    ref?: number;
    fixedTextBox: boolean;
    textBoxCenterX?: number;
    textBoxCenterY?: number;
}

export class MeasureRef extends EditItem {
    type = 'measure-ref';
    data: MeasureRefData;

    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;
    bLineWidth = 5;
    textboxH = 30;
    textboxW = 50;
    textboxMarginBottom = 30;
    textColor = '#ffffff';

    static create(ax: number, ay: number, bx: number, by: number) {
        const data: MeasureRefData = {
            ax,
            ay,
            bx,
            by,
            fixedTextBox: true
        };
        return new MeasureRef(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.updateMeasureRef();
            }
        };
    }

    getActionMoveTextBox(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (this.data.fixedTextBox) {
                    const textBoxCenter = centerOfSegment(this.data.ax, this.data.ay, this.data.bx, this.data.by);
                    textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
                    textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y) - this.textboxMarginBottom;
                    this.data.textBoxCenterX = this.drawer.absoluteToRelativeX2(textBoxCenter.x);
                    this.data.textBoxCenterY = this.drawer.absoluteToRelativeY2(textBoxCenter.y);
                    this.data.fixedTextBox = false;
                } else {
                    this.data.textBoxCenterX = this.data.textBoxCenterX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.textBoxCenterY = this.data.textBoxCenterY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.updateMeasureRef();
            }
        };
    }

    getActionResize(direction: 'a' | 'b'): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'a') {
                    this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'b') {
                    this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.updateMeasureRef();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    getText() {
        return this.data.ref ? `${this.data.ref} cm` : '- - cm';
    }

    updateRefValue(value: number) {
        this.data.ref = value;
        this.updateMeasureRef();
    }

    updateMeasureRef() {
        const measures = this.drawer.editItems.filter(item => item.type === 'measure');
        measures.forEach(measure => {
            measure.update();
        });
        this.update();
    }

    draw() {

        const lineAB = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.ax),
            this.drawer.relativeToAbsoluteY(this.data.ay),
            this.drawer.relativeToAbsoluteX(this.data.bx),
            this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).stroke({ color: this.color, width: getSize('lineW', this.sizeMode) * 1 / this.drawer.zoomLevel, dasharray: '5 5' });

        const lineABWide = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.ax),
            this.drawer.relativeToAbsoluteY(this.data.ay),
            this.drawer.relativeToAbsoluteX(this.data.bx),
            this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).opacity(.01).stroke({ color: this.color, width: getSize('tapLineW', this.sizeMode) * 1 / this.drawer.zoomLevel });
        // const leftRight = this.drawer.svgObject.line(
        //     this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY),
        //     this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });

        const pointAS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
        const pointBS = this.drawer.svgObject.circle(this.sDotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));
        const pointA = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
        const pointB = this.drawer.svgObject.circle(this.dotSize * 1 / this.drawer.zoomLevel).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));

        const textBoxCenter = centerOfSegment(this.data.ax, this.data.ay, this.data.bx, this.data.by);
        textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
        textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y) - this.textboxMarginBottom;

        textBoxCenter.x = this.drawer.absoluteToRelativeX2(textBoxCenter.x);
        textBoxCenter.y = this.drawer.absoluteToRelativeY2(textBoxCenter.y);

        textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
        textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y);

        // console.log('Init PosX', textBoxCenter.x);
        // console.log('Init PosY', textBoxCenter.y);

        if (!this.data.fixedTextBox) {
            textBoxCenter.x = this.drawer.relativeToAbsoluteX(this.data.textBoxCenterX);
            textBoxCenter.y = this.drawer.relativeToAbsoluteY(this.data.textBoxCenterY);
        }

        const textBox = this.drawer.svgObject.rect(getSize('measureRefTextBoxW', this.sizeMode) * 1 / this.drawer.zoomLevel, getSize('textBoxH', this.sizeMode)  * 1 / this.drawer.zoomLevel).center(
            textBoxCenter.x,
            textBoxCenter.y).fill(this.color);

        const text = this.drawer.svgObject.text(this.getText()).font({size: getSize('text', this.sizeMode) * 1 / this.drawer.zoomLevel}).center(
            textBoxCenter.x,
            textBoxCenter.y).fill(this.textColor);

        this.svgElements.push(pointAS);
        this.svgElements.push(pointBS);
        this.svgElements.push(pointA);
        this.svgElements.push(pointB);
        this.svgElements.push(lineAB);
        this.svgElements.push(lineABWide);
        this.svgElements.push(textBox);
        this.svgElements.push(text);

        if (this.editable) {
            interact(pointA.node)
                .draggable(
                    this.getActionResize('a')
                ).on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(pointB.node)
                .draggable(
                    this.getActionResize('b')
                ).on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(lineABWide.node)
                .draggable(
                    this.getActionMove()
                )
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(text.node)
                .draggable(
                    this.getActionMoveTextBox()
                )
                .on('tap', (event) => {
                    this.drawer.getInput('Introduce la medida de referencia (CM)', `${this.data.ref ? this.data.ref : ''}`, 'Valor en CM', (value) => {
                        this.updateRefValue(value);
                    });
                });
        }
    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
