<div class="">
  <div style="" class="flex justify-between cursor-pointer items-center" (click)="toggle()">
    <span class="flex items-center">
      <i class="text-lg mr-2 fas fa-graduation-cap"></i>
      <p style="font-size: 18px;" class="leading-none">{{title}}</p>
    </span>
    <!-- <p>{{scrollH}}</p> -->
    <i class="fas fa-angle-down {{expanded ? 'rotate-180': ''}}"></i>
  </div>
  <div class="overflow-hidden transition-all duration-200 {{expanded ? '' : 'max-h-0'}}" style="max-height: {{expanded ? (scrollH + 'px') : '0px'}};">
    <div #container>
      <ng-content></ng-content>
    </div>

  </div>
</div>
