import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Test, TestNames } from '../../shared/test.outside';
import { ClientsService } from './clients.service';
import { CanIService } from './can-i.service';
import { FunctionsService } from './functions.service';
import { ClientTrainingSession } from '../../../../Server/functions/src/shared/training-session-definition.model';
import { ClientCorrectiveSession } from '../../../../Server/functions/src/shared/corrective-session-definition.model';
import { TrainingAvatar } from '../../../../Server/functions/src/shared/training-avatar.shared';
import { safeToPromise } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private functionsService: FunctionsService,
    private clientService: ClientsService,
    private canI: CanIService,
  ) {
    // this.functionsService.setTestMode();
  }

  async generateReport(
    tests:
      | Array<Test>
      | Array<ClientTrainingSession>
      | Array<ClientCorrectiveSession>
      | Array<TrainingAvatar>,
  ) {
    if (!(await this.canI.requestGenerateReport())) {
      return;
    }

    let testNames = '';

    for (let index = 0; index < tests.length; index++) {
      const test = tests[index];
      let newName = '';
      if (test.type === 'training-session') {
        newName = 'Sesión entrenamiento';
      } else if (test.type === 'corrective-session') {
        newName = 'Sesión correctivos';
      } else {
        if (TestNames[test.type]) {
          newName = TestNames[test.type];
        } else {
          newName = 'Protocolo VAF';
        }
      }
      testNames = testNames + newName;
      if (index < tests.length - 1) {
        testNames = testNames + '-';
      }
    }

    const clientId = tests[0].client;
    const client = await safeToPromise(
      this.clientService.getClient$(clientId).pipe(take(1)),
    );

    const preResult = await this.functionsService.call(
      'generatePdfReportCallable',
      {
        testIds: tests.map((t) => t.id),
      },
      { timeout: 1000 * 60 * 4 },
    );
    const result = preResult;

    // console.log('result:', result);
    // console.log('result64:', resultBase64);
    // let pdfWindow = window.open("")
    // pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(result)+"'></iframe>")
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + result;
    const date = new Date();
    a.download = `APP INTECC. ${testNames}. ${client.name} ${client.surname} ${formatDateToSpanishLocale(date)}.pdf`;
    a.click();
  }

  async getReportHtml(testIds: string[]) {
    const getHtml = await this.functionsService.call(
      'testReportHtmlOutputCallable',
      {
        testIds,
      },
    );
    return getHtml as string;
  }
}

// get local date in format dd-mm-yyyy hh:mm
function formatDateToSpanishLocale(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${day}-${month}-${year} ${hours}_${minutes}`;
}
