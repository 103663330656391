<div class="w-full">
  <p *ngIf="fieldName !== ''" class="mb-1 font-bold text-neutral-600">
    {{ fieldName }}
  </p>

  <div class="relative">
    <input
      *ngIf="type === 'input'"
      [readonly]="readonly"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      placeholder="{{ placeholder }}"
      class="rounded-md shadow-sm block w-full py-2 px-3 border border-solid border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-100 focus:ring-intecc {{
        readonly ? 'bg-neutral-200 opacity-50 focus:ring-0' : ''
      }}"
      type="text"
      [class.pr-10]="buttonPosition === 'end'"
      [class.pl-10]="buttonPosition === 'start'"
    />
    <textarea
      *ngIf="type === 'textarea'"
      [readonly]="readonly"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      placeholder="{{ placeholder }}"
      class="rounded-md shadow-sm block w-full py-2 px-3 border border-solid border-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-100 focus:ring-intecc {{
        readonly ? 'bg-neutral-200 opacity-50 focus:ring-0' : ''
      }}"
      type="text"
      [class.pr-10]="buttonPosition === 'end'"
      [class.pl-10]="buttonPosition === 'start'"
    ></textarea>

    <ng-container *ngIf="button">
      <button
        (click)="button.action()"
        class="absolute top-0 flex h-10 w-10 items-center justify-center"
        [class.left-0]="buttonPosition === 'start'"
        [class.right-0]="buttonPosition === 'end'"
      >
        <i *ngIf="button.faClass" [class]="button.faClass"></i>
        <span *ngIf="!button.faClass">·</span>
      </button>
    </ng-container>
  </div>
</div>
