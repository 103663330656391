import { Injectable, inject } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { User } from '../models/user.model';
import {
  Auth,
  authState,
  signInWithPopup,
  signInAnonymously,
  GoogleAuthProvider,
  signOut,
} from '@angular/fire/auth';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FirestoreService } from './firestore.service';
// import 'firebase/compat/auth';
import {
  switchMap,
  first,
  tap,
  shareReplay,
  filter,
  take,
} from 'rxjs/operators';
import { SentryService } from './sentry.service';
import { NavigationService } from './navigation.service';
import { productFruits } from 'product-fruits';
import { FunctionsService } from './functions.service';
import { SnackbarService } from './snackbar.service';
import { safeToPromise, sleep } from '../utils/utils';
import { BehaviorSubject } from 'rxjs';

// Add new type at the top of the file
type AuthLoadingState = {
  loading: boolean;
  action: 'signin' | 'signout' | null;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);
  user$: Observable<User>;

  constructor(
    // private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private nav: NavigationService,
    private sentry: SentryService,
    private functionsService: FunctionsService,
    private snackbarService: SnackbarService,
  ) {
    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.authState$.pipe(
      switchMap((user) => {
        // Logged in
        if (user) {
          return this.firestoreService
            .doc$<User>(`users/${user.uid}`)
            .pipe(tap((d) => this.sentry.logFirestoreRead('Users')));
        } else {
          // Logged out

          return of(null);
        }
      }),
      tap((user) => {
        if (user && user.role !== 'client') {
          productFruits.init('XKbvfx13yJDlaeTP', 'es', {
            username: user.email,
          });
        }
      }),
      shareReplay(1),
      tap((user) => {}),
    );
  }

  getUser(): Promise<User> {
    return safeToPromise(this.user$.pipe(first()));
  }

  async googleSignin(
    config: { isClientLogin?: boolean } = { isClientLogin: false },
  ) {
    this.authLoadingSubject.next({ loading: true, action: 'signin' });
    try {
      const { isClientLogin } = config;
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      const credential = await signInWithPopup(this.auth, provider);
      this.updateUserData(credential.user, isClientLogin);
      const user = await safeToPromise(
        this.user$.pipe(
          filter((user) => !!user),
          take(1),
        ),
      );
      console.log('logged in user', user);
      if (user.role !== 'client' && isClientLogin) {
        this.snackbarService.error(
          'No puedes acceder como cliente con este usuario',
        );
        // no need to do anything else as redirect will happen in auth.guard
      }
      if (user.role === 'client' && !isClientLogin) {
        this.snackbarService.error(
          'No puedes acceder como profesional con este usuario',
        );
        // no need to do anything else as redirect will happen in auth.guard
      }
      const isClientUser = user.role === 'client';
      if (isClientUser) {
        await credential.user.getIdToken();
        await this.refreshIdTokenWithCustomClaims();
        this.nav.clientNav.goToHome();
      } else {
        // creates stripe client for professional user
        await this.functionsService.call('getOrCreateCustomerCallable', {});
        this.nav.goToHome();
      }
    } catch (error) {
      console.log('Caught');
      console.log(error);

      // Check if the error is due to popup being closed
      if (error.code === 'auth/popup-closed-by-user') {
        this.snackbarService.error('Inicio de sesión cancelado');
      } else {
        this.snackbarService.error('Error al iniciar sesión');
      }

      // Immediately clear the loading state if popup was closed
      this.authLoadingSubject.next({ loading: false, action: null });
      return; // Exit early to avoid the sleep delay
    } finally {
      // Only add the delay if we didn't exit early due to popup closing
      if (this.authLoadingSubject.value.loading) {
        await sleep(200);
        this.authLoadingSubject.next({ loading: false, action: null });
      }
    }
  }

  async refreshIdTokenWithCustomClaims() {
    await this.functionsService.call('setCustomClaimsCallable', {});
    await this.auth.currentUser?.getIdToken(true);
  }

  async anonymousSignin() {
    const credential = await signInAnonymously(this.auth);
    this.updateUserData(credential.user);
    await safeToPromise(
      this.user$.pipe(
        filter((user) => !!user),
        take(1),
      ),
    );
    this.nav.goToHome();
  }

  async updateUser(user: User) {
    const userRef: AngularFirestoreDocument<User> = this.firestoreService.doc(
      `users/${user.uid}`,
    );
    return await this.firestoreService.upsert(userRef, user);
  }

  private async updateUserData(user, isClientLogin = false) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.firestoreService.doc(
      `users/${user.uid}`,
    );

    const userData: User = await firstValueFrom(
      this.firestoreService.doc$<User>(`users/${user.uid}`),
    );

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      role: userData?.role ?? (isClientLogin ? 'client' : 'professional'),
    };
    return this.firestoreService.upsert(userRef, data);
  }

  async signOut() {
    this.authLoadingSubject.next({ loading: true, action: 'signout' });
    try {
      await signOut(this.auth);
      await sleep(1000);
      this.nav.goToLogin();
      // Add delay before clearing the loading state
      await sleep(300);
    } finally {
      this.authLoadingSubject.next({ loading: false, action: null });
    }
  }

  // Update the BehaviorSubject to handle the new state type
  private authLoadingSubject = new BehaviorSubject<AuthLoadingState>({
    loading: false,
    action: null,
  });
  authLoading$ = this.authLoadingSubject.asObservable();
}
