import { Component, OnInit, Input } from '@angular/core';
import { Modal } from 'src/app/models/modal.model';
import { Observable, Subject } from 'rxjs';
import {
  SubscriptionCardProps,
  SubscriptionsService,
} from 'src/app/services/subscriptions.service';
import { first } from 'rxjs/operators';
import {
  getActiveProducts,
  ProductIds,
  PriceIds,
} from 'src/shared/subscriptions.shared';
import { AppConfig } from 'src/config/app.config';
import { safeToPromise } from 'src/app/utils/utils';

const RaquisStart: SubscriptionCardProps = {
  showInApp: false,
  name: 'Start',
  price: '0€',
  description:
    'Para aprender a valorar el raquis y mejorar la salud de tus clientes. ¡Sorpréndelos!',
  billingPeriod: '/mes',
  bulletPoints: [
    'Hasta <b>5</b> clientes',
    'Hasta <b>5</b> valoraciones',
    'Academia y videotutoriales para aprender a valorar el raquis en minutos',
    'Prueba la función de informes en PDF (hasta 5)',
  ],
  trialAvailable: false,
  productId: ProductIds.RaquisStart,
  priceId: PriceIds.RaquisStart,
};

const RaquisPro: SubscriptionCardProps = {
  showInApp: false,
  name: 'Pro',
  price: '9€',
  description:
    'Genera estadísticas por grupos e informes profesionales en PDF para entregar a tus clientes',
  billingPeriod: '/mes',
  bulletPoints: [
    'Hasta <b>20</b> clientes',
    'Hasta <b>20</b> valoraciones',
    'Academia y videotutoriales',
    'Exportar Informes a <b>PDF</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.RaquisPro,
  priceId: PriceIds.RaquisProOffer,
};

const RaquisPremium: SubscriptionCardProps = {
  showInApp: false,
  name: 'Premium',
  price: '29€',
  description:
    '<b>Personaliza</b> las valoraciones de tus clientes y conviértete en un profesional de referencia.',
  billingPeriod: '/mes',
  bulletPoints: [
    'Clientes <b>Ilimitados</b>',
    'Valoraciones <b>Ilimitadas</b>',
    'Academia y videotutoriales',
    'Exportar Informes en <b>PDF</b>',
    '<b>Personaliza los informes con tus datos, webs y logos.</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.RaquisPremium,
  priceId: PriceIds.RaquisPremiumOffer,
};

const RomStart: SubscriptionCardProps = {
  showInApp: false,
  name: 'Start',
  price: '0€',
  description:
    'Para aprender a valorar el Rom y mejorar la salud de tus clientes. ¡Sorpréndelos!',
  billingPeriod: '/mes',
  bulletPoints: [
    'Hasta <b>5</b> clientes',
    'Hasta <b>5</b> valoraciones',
    'Academia y videotutoriales para aprender a valorar el Rom en minutos',
    'Prueba la función de informes en PDF (hasta 5)',
  ],
  trialAvailable: false,
  productId: ProductIds.RomStart,
  priceId: PriceIds.RomStart,
};

const RomPro: SubscriptionCardProps = {
  showInApp: false,
  name: 'Pro',
  price: '9€',
  description:
    'Genera estadísticas por grupos e informes profesionales en PDF para entregar a tus clientes',
  billingPeriod: '/mes',
  bulletPoints: [
    'Hasta <b>20</b> clientes',
    'Hasta <b>20</b> valoraciones',
    'Academia y videotutoriales',
    'Exportar Informes a <b>PDF</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.RomPro,
  priceId: PriceIds.RomProOffer,
};

const RomPremium: SubscriptionCardProps = {
  showInApp: false,
  name: 'Premium',
  price: '29€',
  description:
    '<b>Personaliza</b> las valoraciones de tus clientes y conviértete en un profesional de referencia.',
  billingPeriod: '/mes',
  bulletPoints: [
    'Clientes <b>Ilimitados</b>',
    'Valoraciones <b>Ilimitadas</b>',
    'Academia y videotutoriales',
    'Exportar Informes en <b>PDF</b>',
    '<b>Personaliza los informes con tus datos, webs y logos.</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.RomPremium,
  priceId: PriceIds.RomPremiumOffer,
};

const VAFStart: SubscriptionCardProps = {
  showInApp: false,
  name: 'Raquis',
  comparedAtPrice: '9',
  price: '5€',
  description:
    'La única aplicación del mundo que valora la columna en menos de 5 minutos con un informe y diagnóstico profesional.',
  billingPeriod: '/mes',
  bulletPoints: [
    'Editor multimedia profesional',
    'Clientes ilimitados',
    'Valoraciones ilimitadas',
    'Academia y videotutoriales',
    'Exportar Informes en <b>PDF</b> (ilimitados)',
    'Almacena <b>100 MB</b> de imágenes',
    '<b>Personaliza los informes con tus datos, webs y logos.</b>',
    'Soporte técnico',
  ],
  trialAvailable: false,
  productId: ProductIds.VAFStart,
  priceId: PriceIds.NewVAFStart,
};

const VAFPro: SubscriptionCardProps = {
  showInApp: false,
  name: 'Pro',
  price: '19€',
  description:
    'Acceso completo e ilimitado a <b>todos los protocolos de valoración; postural, articular, columna, ascendentes y ROM</b>.',
  billingPeriod: '/mes',
  bulletPoints: [
    'Editor multimedia profesional',
    'Clientes ilimitados',
    'Valoraciones ilimitadas',
    'Academia y videotutoriales',
    'Exportar Informes en <b>PDF</b> (ilimitados)',
    'Almacena <b>200 MB</b> de imágenes',
    '<b>Personaliza los informes con tus datos, webs y logos.</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.VAFPro,
  priceId: PriceIds.NewVAFPro,
};

const VAFPremium: SubscriptionCardProps = {
  showInApp: false,
  name: 'Premium',
  price: '29€',
  description:
    '<b>Crea</b> cualquier test personalizado con MyVAF y <b>difunde</b> el sello de tu empresa.<br>Versión Premium <b>¡ILIMITADA!</b>',
  billingPeriod: '/mes',
  bulletPoints: [
    'Crea tus <b>tests personalizados</b>',
    'Crea tus <b>protocolos de valoración o prescripción</b>',
    'Clientes <b>Ilimitados</b>',
    'Valoraciones <b>Ilimitadas</b>',
    'Academia y videotutoriales',
    'Exportar Informes en <b>PDF</b> ilimitados',
    'Almacena <b>1 GB</b> de imágenes',
    '<b>Personaliza los informes con tus datos, webs y logos.</b>',
    'Soporte técnico',
  ],
  trialAvailable: true,
  productId: ProductIds.VAFPremium,
  priceId: PriceIds.NewVAFPremium,
};

@Component({
  selector: 'app-new-upgrade-modal',
  templateUrl: './new-upgrade-modal.component.html',
  styleUrls: ['./new-upgrade-modal.component.scss'],
})
export class NewUpgradeModalComponent implements OnInit, Modal {
  onClose = new Subject<any>();
  @Input()
  data: any;

  prices: Array<SubscriptionCardProps> = [];
  userSubscriptions: Array<SubscriptionCardProps> = [];
  availableSubscriptions$: Observable<Array<SubscriptionCardProps>>;

  processingSubscription = false;

  constructor(private subService: SubscriptionsService) {}

  ngOnInit(): void {
    this.loadPrices();
    this.availableSubscriptions$ = this.subService.availableSubscriptions$;
  }

  historyBackClose() {
    this.onClose.next('');
  }

  close() {
    history.back();
  }

  async loadPrices() {
    console.log('hello');
    const activePrices = await safeToPromise(
      this.subService.currentUserActivePrices$.pipe(first()),
    );
    const activeProducts = getActiveProducts(activePrices);
    const currentApp = AppConfig.app;

    if (currentApp === 'raquis') {
      this.prices.push(VAFStart);
    } else {
      this.prices.push(VAFPremium);
      // this.prices.push(VAFPro);
      // this.prices.push(VAFStart);
    }

    return;

    if (currentApp === 'raquis') {
      RaquisPro.active = false;
      RaquisPremium.active = false;
      RaquisStart.active = false;
      if (
        activeProducts.filter((a) => a.id === ProductIds.RaquisPremium).length >
        0
      ) {
        RaquisPremium.active = true;
        this.prices.push(RaquisPremium);
        return;
      }
      if (
        activeProducts.filter((a) => a.id === ProductIds.RaquisPro).length > 0
      ) {
        RaquisPro.active = true;
        this.prices.push(RaquisPremium);
        this.prices.push(RaquisPro);
        return;
      }
      if (
        activeProducts.filter((a) => a.id === ProductIds.RaquisStart).length > 0
      ) {
        RaquisStart.active = true;
        this.prices.push(RaquisPremium);
        this.prices.push(RaquisPro);
        this.prices.push(RaquisStart);
        return;
      }
      this.prices.push(RaquisPremium);
      this.prices.push(RaquisPro);
      this.prices.push(RaquisStart);
      return;
    }
    if (currentApp === 'rom') {
      RomPro.active = false;
      RomPremium.active = false;
      RomStart.active = false;
      if (
        activeProducts.filter((a) => a.id === ProductIds.RomPremium).length > 0
      ) {
        RomPremium.active = true;
        this.prices.push(RomPremium);
        return;
      }
      if (activeProducts.filter((a) => a.id === ProductIds.RomPro).length > 0) {
        RomPro.active = true;
        this.prices.push(RomPremium);
        this.prices.push(RomPro);
        return;
      }
      if (
        activeProducts.filter((a) => a.id === ProductIds.RomStart).length > 0
      ) {
        RomStart.active = true;
        this.prices.push(RomPremium);
        this.prices.push(RomPro);
        this.prices.push(RomStart);
        return;
      }
      this.prices.push(RomPremium);
      this.prices.push(RomPro);
      this.prices.push(RomStart);
      return;
    }
    if (currentApp === 'vaf') {
      VAFPro.active = false;
      VAFStart.active = false;
      VAFPremium.active = false;
      if (
        activeProducts.filter((a) => a.id === ProductIds.VAFPremium).length > 0
      ) {
        VAFPremium.active = true;
        this.prices.push(VAFPremium);
        return;
      }
      if (activeProducts.filter((a) => a.id === ProductIds.VAFPro).length > 0) {
        VAFPro.active = true;
        this.prices.push(VAFPremium);
        this.prices.push(VAFPro);
        return;
      }
      if (
        activeProducts.filter((a) => a.id === ProductIds.VAFStart).length > 0
      ) {
        VAFStart.active = true;
        this.prices.push(VAFPremium);
        this.prices.push(VAFStart);
        this.prices.push(VAFPro);
        return;
      }
      this.prices.push(VAFPremium);
      this.prices.push(VAFPro);
      this.prices.push(VAFStart);
      return;
    }
  }

  async startSub(priceId: string) {
    this.processingSubscription = true;
    await this.subService.openCheckoutSession(priceId);
    this.processingSubscription = false;
  }
}
