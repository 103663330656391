import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewInit {

  @Input() title = 'Title';

  @ViewChild('container') private containerRef: ElementRef<HTMLElement>;

  scrollH = 0;

  expanded = false;

  constructor() { }

  ngOnInit(): void {
    console.log('ref', this.containerRef);

  }
  ngAfterViewInit(): void {
    console.log('ref', this.containerRef);
    this.scrollH = this.containerRef.nativeElement.scrollHeight;
  }

  toggle() {
    this.expanded = !this.expanded;
  }

}
