<div class="splash-screen" *ngIf="show" @fadeOut>
  <div class="splash-screen__box">
    <div class="splash-screen__icon">
      <!-- <img src="../../../assets/images/spine.svg" alt="Raquis Logo"> -->
    </div>
    <!-- <div class="splash-screen__subtitle">Bienvenido a <span class="splash-screen__bold-raquis">Raquis</span>.</div> -->
    <div class="splash-screen__subicon">
      <img
        *ngIf="app === 'raquis'"
        src="../../../assets/images/raquis-logo-3-v2.png"
        appResponsiveSrc
        alt=""
      />
      <img
        *ngIf="app === 'rom'"
        src="../../../assets/images/rom-logo-v3.png"
        appResponsiveSrc
        alt=""
      />
    </div>
  </div>
</div>
