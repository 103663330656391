import {
  TestImageValue,
  TestImageInfo,
  TestImagesEnum,
} from "./test-images.shared";
import {
  TestField,
  Test,
  TestType,
  TestWithImages,
  TestReportLink,
} from "./tests.model";

export interface PostureTest extends Test, TestWithImages {
  results: { [key in PostureTestFieldEnum]?: number };
  frontalComments?: string;
  sagitalComments?: string;
  generalComments?: string;
  multimediaLink?: string;
  links?: Array<TestReportLink>;
  images: { [id: string]: TestImageValue };
}

export enum PostureTestFieldEnum {
  PFCabeza = "PFCabeza",
  PFHombros = "PFHombros",
  PFTroncoVentral = "PFTroncoVentral",
  PFTroncoDorsal = "PFTroncoDorsal",
  PFPelvisVentral = "PFPelvisVentral",
  PFPelvisDorsal = "PFPelvisDorsal",
  PFRodillasFrontal = "PFRodillasFrontal",
  PFRodillasTransversal = "PFRodillasTransversal",
  PFPies = "PFPies",
  PSLobulo = "PSLobulo",
  PSCuerposCervicales = "PSCuerposCervicales",
  PSArticulacionHombro = "PSArticulacionHombro",
  PSMitadTronco = "PSMitadTronco",
  PSTrocanterMayor = "PSTrocanterMayor",
  PSAnteriorRodilla = "PSAnteriorRodilla",
  PSAnteriorMaleolo = "PSAnteriorMaleolo",
  PSMorfotipoPostural = "PSMorfotipoPostural",
  AvatarMR = "AvatarMR",
  AvatarMMI = "AvatarMMI",
  AvatarMPT = "AvatarMPT",
}

export const PostureTestFields: { [key in PostureTestFieldEnum]: TestField } = {
  PFCabeza: {
    type: "select",
    title: "Cabeza",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFHombros: {
    type: "select",
    title: "Hombros",
    values: ["Neutros", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFTroncoVentral: {
    type: "select",
    title: "Tronco Ventral",
    values: ["Neutro", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFTroncoDorsal: {
    type: "select",
    title: "Tronco Dorsal",
    values: ["Neutro", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFPelvisVentral: {
    type: "select",
    title: "Pelvis Ventral",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFPelvisDorsal: {
    type: "select",
    title: "Pelvis Dorsal",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
  },
  PFRodillasFrontal: {
    type: "select",
    title: "Rodillas Frontal",
    values: ["Neutras", "Genu Varo", "Genu Valgo"],
    goodValues: [0],
  },
  PFRodillasTransversal: {
    type: "select",
    title: "Rodillas Transversal",
    values: ["Neutras", "Convergentes", "Divergentes"],
    goodValues: [0],
  },
  PFPies: {
    type: "select",
    title: "Pies",
    values: ["Neutros", "Rotación Interna", "Rotación Externa"],
    goodValues: [0],
  },

  PSLobulo: {
    type: "select",
    title: "Lóbulo de la oreja",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSCuerposCervicales: {
    type: "select",
    title: "Cuerpos Cervicales",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSArticulacionHombro: {
    type: "select",
    title: "Articulación Hombro",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSMitadTronco: {
    type: "select",
    title: "Mitad Tronco",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSTrocanterMayor: {
    type: "select",
    title: "Trocánter Mayor",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSAnteriorRodilla: {
    type: "select",
    title: "Anterior a la Rodilla",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSAnteriorMaleolo: {
    type: "select",
    title: "Anterior al Maléolo",
    values: ["Neutro", "Anterior", "Posterior"],
    goodValues: [0],
  },
  PSMorfotipoPostural: {
    type: "select",
    title: "Morfotipo Postural",
    values: ["Neutro", "Anteriorizado", "Posteriorizado", "Sway Back"],
    goodValues: [0],
  },

  AvatarMR: {
    type: "select",
    title: "Morfotipo Raquídeo",
    values: [
      "Neutro",
      "Hiperlordótico",
      "Hipercifótico",
      "Rectificado",
      "Cifolordótico",
    ],
    goodValues: [0],
  },
  AvatarMMI: {
    type: "select",
    title: "Morfotipo del Miembro Inferior",
    values: [
      "Neutro",
      "Genu Varo",
      "Genu Valgo",
      "Genu Recurvatum",
      "Genu Flexo",
    ],
    goodValues: [0],
  },
  AvatarMPT: {
    type: "select",
    title: "Morfotipo del Pie-Tobillo",
    values: [
      "Neutro",
      "Pie Cavo",
      "Pie Plano",
      "Pie Cavo Extremo",
      "Pie Plano Extremo",
    ],
    goodValues: [0],
  },
};

export const PostureArchitecture = [
  {
    title: "Plano Frontal",
    id: "frontal",
    learnId: "VAFPostureFrontal",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-del-plano-frontal",
    fields: [
      PostureTestFieldEnum.PFCabeza,
      PostureTestFieldEnum.PFHombros,
      PostureTestFieldEnum.PFTroncoVentral,
      PostureTestFieldEnum.PFTroncoDorsal,
      PostureTestFieldEnum.PFPelvisVentral,
      PostureTestFieldEnum.PFPelvisDorsal,
      PostureTestFieldEnum.PFRodillasFrontal,
      PostureTestFieldEnum.PFRodillasTransversal,
      PostureTestFieldEnum.PFPies,
    ],
    images: [
      TestImagesEnum.PlanoFrontalAnterior,
      TestImagesEnum.PlanoFrontalPosterior,
    ],
    commentsPropName: "frontalComments",
  },
  {
    title: "Plano Sagital",
    id: "sagital",
    learnId: "VAFPostureSagital",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-del-plano-sagital",
    fields: [
      PostureTestFieldEnum.PSLobulo,
      PostureTestFieldEnum.PSCuerposCervicales,
      PostureTestFieldEnum.PSArticulacionHombro,
      PostureTestFieldEnum.PSMitadTronco,
      PostureTestFieldEnum.PSTrocanterMayor,
      PostureTestFieldEnum.PSAnteriorRodilla,
      PostureTestFieldEnum.PSAnteriorMaleolo,
      PostureTestFieldEnum.PSMorfotipoPostural,
    ],
    images: [
      TestImagesEnum.PlanoSagitalDerecho,
      TestImagesEnum.PlanoSagitalIzquierdo,
    ],
    commentsPropName: "sagitalComments",
  },
  {
    title: "Imágenes",
    id: "images",
    images: [
      TestImagesEnum.PlanoFrontalAnteriorRelajado,
      TestImagesEnum.PlanoFrontalPosteriorRelajado,
    ],
  },
  {
    title: "Morfotipos - Avatar del cliente",
    id: "avatar",
    fields: [
      PostureTestFieldEnum.AvatarMR,
      PostureTestFieldEnum.AvatarMMI,
      PostureTestFieldEnum.AvatarMPT,
    ],
    // images: [
    //     TestImagesEnum.PlanoSagitalDerecho,
    //     TestImagesEnum.PlanoSagitalIzquierdo,
    // ],
    // commentsPropName: 'avatarComments'
  },
  {
    title: "Recomendaciones y enlaces",
    learnId: "VAFRecomendacionesEnlaces",
    learnURL:
      "https://manual.intecc.org/es/article/comentarios-y-recomendaciones",
    id: "linksAndComments",
    commentsPropName: "generalComments",
  },
];

export const createEmptyPostureTest = () => {
  const result: PostureTest = {
    type: "posture" as TestType,
    owner: "",
    client: "",
    results: {},
    images: {},
  };
  return result;
};

// export const getValueText = (test: PostureTest, field: PostureTestFieldEnum) => {
//     if(!test.results){
//         return null;
//     }
//     if(!test.results[field]){
//         return null;
//     }
//     const value = test.results[field];
//     if(value < 0) {
//         return null;
//     }
//     const fieldInfo = PostureTestFields[field];
//     if(value >= fieldInfo.values.length) {
//         return null;
//     }
//     const result = fieldInfo.values[value];
//     return result;
// }

export const getPostureFieldInfo = (field: PostureTestFieldEnum) => {
  return PostureTestFields[field];
};

export const getValueForReport = (
  field: PostureTestFieldEnum,
  test: PostureTest
) => {
  const info = getPostureFieldInfo(field);
  const values = info.values;
  const rawValue = test.results[field];
  if (rawValue === undefined || rawValue < 0 || rawValue >= values.length) {
    return "-";
  }
  const value = values[rawValue];
  return value;
};
