import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { LoadingState } from 'src/app/utils/utils';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { UserConfig } from 'src/app/models/user-config.model';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionType } from 'src/shared/subscriptions.shared';

@Component({
  selector: 'app-client-professional',
  templateUrl: './client-professional.component.html',
})
export class ClientProfessionalComponent implements OnInit, OnDestroy {
  professionalConfig: UserConfig;

  professional: User | null;

  professionalSubscriptionType$: Observable<SubscriptionType>;

  unlinkLoadingState: LoadingState = 'idle';

  pageLoaded: LoadingState = 'loading';

  user: User;

  private destroy$ = new Subject<void>();

  constructor(
    private userClientService: UserClientService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.unlinkLoadingState = 'idle';
    this.pageLoaded = 'loading';

    this.professionalSubscriptionType$ =
      this.userClientService.professionalSubscriptionType$;

    combineLatest([
      this.authService.user$,
      this.userClientService.professional$,
      this.professionalSubscriptionType$,
      this.userClientService.professionalConfig$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, professional, professionalSubscriptionType, config]) => ({
          user,
          professional,
          professionalSubscriptionType,
          config,
        })),
      )
      .subscribe({
        next: (data) => {
          this.user = data.user;
          this.professional = data.professional;
          this.professionalConfig = data.config;
          // All observables have emitted values
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openUnlinkDialog(): void {
    const dialogRef = this.dialog.open(UnlinkConfirmationDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.unlinkClient();
      }
    });
  }

  private async unlinkClient() {
    this.unlinkLoadingState = 'loading';
    try {
      await this.userClientService.unlinkClient();
      this.unlinkLoadingState = 'idle';
    } catch (error) {
      console.error(error);
      this.unlinkLoadingState = 'error';
    }
  }
}

@Component({
  selector: 'app-unlink-confirmation-dialog',
  template: `
    <div mat-dialog-title>Confirmar desvinculación</div>
    <div mat-dialog-content>
      ¿Estás seguro de que deseas desvincular tu usuario del profesional?
    </div>
    <div mat-dialog-actions class="space-x-2" align="end">
      <button
        matDialogClose="false"
        class="flex items-center rounded-lg bg-gray-200 px-4 py-2 text-gray-700"
      >
        Cancelar
      </button>
      <button
        matDialogClose="true"
        class="flex items-center rounded-lg bg-red-600 px-4 py-2 text-white disabled:cursor-not-allowed disabled:opacity-50"
      >
        Desvincular
      </button>
    </div>
  `,
})
export class UnlinkConfirmationDialogComponent {}
