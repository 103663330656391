export const EXERCISES_PATH = "exercises";

export const materialOptions = [
  { label: "Peso Corporal", value: "bodyweight" },
  { label: "Peso Libre", value: "freeweight" },
  { label: "Máquinas", value: "machines" },
  { label: "Elásticos", value: "bands" },
  { label: "Core", value: "core" },
  { label: "Cardio", value: "cardio" },
  { label: "Coordinación", value: "coordination" },
  { label: "Valoración", value: "evaluation" },
  { label: "Barra ", value: "bar" },
  { label: "Landmine", value: "landmine" },
  { label: "Hexagonal", value: "hexagonal" },
  { label: "Mancuernas", value: "mancuernas" },
  { label: "Discos ", value: "discs" },
  { label: "Kettlebell ", value: "kb" },
  { label: "Battle Rope", value: "battleRope" },
  { label: "Balón Medicinal", value: "ball" },
  { label: "Banco", value: "bench" },
  { label: "Polea", value: "pulley" },
  { label: "Foam Roller", value: "foam" },
  { label: "Slider", value: "slider" },
  { label: "Fitball", value: "fitball" },
  { label: "Cajón", value: "cajon" },
  { label: "Comba", value: "comba" },
  { label: "TRX", value: "trx" },
  { label: "Colchoneta", value: "mat" },
  { label: "Camilla", value: "treatmentTable" },
  { label: "Silla", value: "chair" },
  { label: "Bosu", value: "boxu" },
  { label: "Miniband", value: "miniband" },
] as const;

export const exerciseLevelsOptions = [
  { label: "Correctivo", value: "corrective" },
  { label: "Principiante", value: "beginner" },
  { label: "Intermedio", value: "intermediate" },
  { label: "Avanzado", value: "advanced" },
  { label: "Nivel 1", value: 1 },
  { label: "Nivel 2", value: 2 },
  { label: "Nivel 3", value: 3 },
  { label: "Nivel 4", value: 4 },
  { label: "Nivel 5", value: 5 },
  { label: "Nivel 6", value: 6 },
  { label: "Nivel 7", value: 7 },
  { label: "Nivel 8", value: 8 },
  { label: "Nivel 9", value: 9 },
  { label: "Nivel 10", value: 10 },
] as const;

export const exerciseTypeOptions = [
  {
    label: "Activación",
    value: "activation",
  },
  {
    label: "Movilidad",
    value: "mobility",
  },
  {
    label: "Global",
    value: "global",
  },
  {
    label: "Superior Empuje",
    value: "superiorEmpuje",
  },
  {
    label: "Superior Tracción",
    value: "superiorTraccion",
  },
  {
    label: "Inferior Empuje",
    value: "inferiorEmpuje",
  },
  {
    label: "Inferior Tracción",
    value: "inferiorTraccion",
  },
  {
    label: "Core",
    value: "core",
  },
  {
    label: "Rotacionales",
    value: "rotational",
  },
  {
    label: "Metabólico",
    value: "metabolic",
  },
  {
    label: "Estiramientos",
    value: "stretch",
  },
  {
    label: "Vuelta a la Calma",
    value: "coolDown",
  },
] as const;

export interface Exercise {
  id: string;
  title: string;
  owner?: string; // if isGlobal is true, owner is not used
  description?: string;
  isGlobal?: boolean;
  imageUrl?: string;
  reportLinkUrl?: string;
  material?: (typeof materialOptions)[number]["value"][];
  type?: (typeof exerciseTypeOptions)[number]["value"];
  level?: (typeof exerciseLevelsOptions)[number]["value"][];
  tags?: string[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface SessionExercise {
  key: string;
  exerciseId: string;
  // type: "text"; // should not be needed, but keep for clarity when dealing with this in other places if it is still used

  /** the position of the exercise image in PDF if exists */
  position: "first" | "second";
}
