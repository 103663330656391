import { Injectable } from '@angular/core';
import { UserConfig, USER_CONFIG_PATH } from '../models/user-config.model';
import { FirestoreService } from './firestore.service';
import { take, switchMap, shareReplay, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import {
  UserMetrics,
  USER_METRICS_PATH,
} from 'src/shared/user-metrics.outside';
import { SentryService } from './sentry.service';
import { safeToPromise } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class UserConfigServiceService {
  constructor(
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private sentry: SentryService,
  ) {
    this.userConfig$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }
        return this.firestoreService.doc$<UserConfig>(
          `${USER_CONFIG_PATH}/${user.uid}`,
        );
      }),
      map((config) => {
        if (config === undefined) {
          return {};
        }
        return config;
      }),
      shareReplay(1),
    );

    this.userMetrics$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }
        return this.firestoreService.doc$<UserMetrics | {}>(
          `${USER_METRICS_PATH}/${user.uid}`,
        );
      }),
      tap((metrics) => {
        this.sentry.logFirestoreRead(USER_METRICS_PATH);
      }),
      map((metrics) => {
        if (metrics === undefined) {
          return {};
        }
        return metrics;
      }),
    );
  }

  userConfig$: Observable<UserConfig>;

  userMetrics$: Observable<UserMetrics | {}>;

  async getMetrics() {
    return await safeToPromise(this.userMetrics$.pipe(take(1)));
  }

  async getUsedStorage() {
    const metrics = await this.getMetrics();
    if ('usedStorage' in metrics && metrics.usedStorage) {
      return metrics.usedStorage;
    } else {
      return 0;
    }
  }

  async getConfig() {
    return await safeToPromise(this.userConfig$.pipe(take(1)));
  }

  async updateUserConfig(config: UserConfig) {
    const uid = await safeToPromise(
      this.authService.user$.pipe(
        take(1),
        map((user) => user.uid),
      ),
    );
    this.updateUserConfigUid(uid, config);
  }

  async updateUserConfigUid(uid: string, config: UserConfig) {
    const userConfigRef = this.firestoreService.doc(
      `${USER_CONFIG_PATH}/${uid}`,
    );
    return await this.firestoreService.upsert(userConfigRef, config);
  }

  async countUpTotalReportsGenerated() {
    const config = await this.getConfig();
    if (!config) {
      return;
    }
    if (!config.counters) {
      config.counters = {};
    }
    const currentCount = config.counters.reportsGenerated
      ? config.counters.reportsGenerated
      : 0;
    config.counters.reportsGenerated = currentCount + 1;
    await this.updateUserConfig(config);
  }

  async getTotalReportsGeneratedCounter() {
    const config = await this.getConfig();
    if (!config) {
      return 0;
    }
    if (!config.counters) {
      return 0;
    }
    const currentCount = config.counters.reportsGenerated
      ? config.counters.reportsGenerated
      : 0;
    return currentCount;
  }
}
