<div
  *ngIf="currentApp === 'vaf'"
  class="h-full {{
    isModal
      ? 'pb-5 bg-intecc text-white overflow-y-auto overflow-x-hidden'
      : 'mt-4 pt-4 pb-0 bg-background text-inteccDark'
  }}"
>
  <div
    *ngIf="!editMode"
    [class]="isModal ? 'flex h-full flex-col justify-start' : ''"
  >
    <!-- START OF HEADER -->
    <div
      *ngIf="isModal"
      class="flex h-14 items-center justify-between bg-inteccDark p-4 text-2xl"
    >
      <p class="font-bold">Avatar Entrenamiento</p>
      <div class="flex items-center">
        <a
          *ngIf="!isUserClientView"
          href="https://manual.intecc.org/es/article/avatar-de-entrenamiento"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-5 cursor-pointer text-xl"
        >
          <i class="fas fa-graduation-cap text-white"></i>
        </a>
        <div class="cursor-pointer" (click)="close()">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <!-- END OF HEADER -->
    <!-- START OF CONTENT -->
    <div [class]="isModal ? 'flex-grow overflow-y-auto overflow-x-hidden' : ''">
      <div class="content">
        <div
          *ngIf="isModal"
          class="mx-auto mb-4 mt-8 flex w-full max-w-md items-center justify-center rounded-lg bg-inteccDark px-4 py-3 font-semibold"
        >
          <p class="text-lg font-bold">
            {{ clientLevelCategory }}
          </p>
          <span *ngIf="clientLevel">
            {{ ': ' + clientLevel }}
          </span>
        </div>
      </div>
      <div class="relative mx-auto max-w-md">
        <div
          class="relative mx-auto flex h-[540px] max-w-md justify-between"
          *ngIf="selectedTab === 'nivel'"
        >
          <div
            class="pointer-events-none absolute -right-6 top-1/2 -mt-8 h-[480px] -translate-y-1/2"
          >
            <img
              class="h-full object-contain"
              src="../../../assets/images/human-back.png"
              alt=""
            />
          </div>
          <div
            class="relative h-full w-full sm:pl-10"
            [class.content]="isModal"
          >
            <div class="mt-4 h-full">
              <ng-container
                *ngFor="
                  let segment of [
                    'mmssEmpuje',
                    'mmssTraccion',
                    'mmiiEmpuje',
                    'mmiiTraccion',
                    'core',
                    'metabolico',
                  ]
                "
              >
                <div class="mb-4 w-44">
                  <p
                    class="mb-1 flex items-center justify-between text-lg font-bold"
                  >
                    {{ segmentsMap[segment] }}
                    <!-- <i
                      (click)="openClientSegmentLevel(segment + 'Level')"
                      class="fas fa-book ml-1 text-primary-6 hover:opacity-70"
                    ></i> -->
                  </p>
                  <div
                    (click)="changeSelectedTab('resultados')"
                    class="inline-flex cursor-pointer items-center rounded-full px-4 py-1 text-xs font-bold"
                    [class]="
                      getLevelTextByNumber(this[segment + 'Level']())
                        ? colorByLevel[
                            getLevelTextByNumber(this[segment + 'Level']())
                          ]
                        : ''
                    "
                  >
                    <p class="mr-2">
                      {{ getDropdownLabelByLevel(this[segment + 'Level']()) }}
                    </p>
                    <i
                      [class]="
                        iconByLevel[
                          getLevelTextByNumber(this[segment + 'Level']())
                        ]
                      "
                    ></i>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="!isModal"
            (click)="openTrainingAvatarSideModal()"
            class="absolute h-full w-full cursor-pointer bg-transparent"
          ></div>
        </div>
        <div
          class="relative mx-auto w-full max-w-md overflow-y-auto overflow-x-hidden sm:pl-10"
          [class.content]="isModal"
          [class.h-[540px]]="!isModal"
          *ngIf="selectedTab === 'resultados'"
        >
          <div class="h-full pt-4">
            <ng-container
              *ngFor="
                let segment of [
                  'mmssEmpuje',
                  'mmssTraccion',
                  'mmiiEmpuje',
                  'mmiiTraccion',
                  'core',
                  'metabolico',
                ]
              "
            >
              <div class="mb-4">
                <p *ngIf="!isModal" class="text-lg font-bold">
                  {{ segmentsMap[segment] }}
                </p>
                <p
                  *ngIf="isModal"
                  class="mb-4 rounded bg-black/20 p-2 text-center text-xl font-bold"
                >
                  {{ segmentsMap[segment] }}
                </p>

                <div [class.flex]="!isModal" [class.items-center]="!isModal">
                  <span
                    class="mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-sm font-semibold"
                    [class]="
                      getLevelTextByNumber(this[segment + 'Level']())
                        ? colorByLevel[
                            getLevelTextByNumber(this[segment + 'Level']())
                          ]
                        : ''
                    "
                    *ngIf="!isModal && this[segment + 'Level']() !== undefined"
                    >N{{ this[segment + 'Level']() || '' }}</span
                  >
                  <span
                    *ngIf="!isModal && this[segment + 'Level']() !== undefined"
                    class="mr-2"
                  >
                    -
                  </span>
                  <app-new-dropdown
                    *ngIf="isModal"
                    [(ngModel)]="this[segment + 'Level']"
                    [options]="levelDropdownOptions"
                    (optionSelected)="onOptionSelected($event)"
                    [buttonClass]="
                      getLevelTextByNumber(this[segment + 'Level']())
                        ? colorByLevel[
                            getLevelTextByNumber(this[segment + 'Level']())
                          ]
                        : ''
                    "
                    wrapperClass="w-full"
                    [readonly]="isUserClientView"
                  ></app-new-dropdown>
                  <div *ngIf="isUserClientView && !isModal" class="my-2 flex-1">
                    {{ currentClientTrainingAvatar.segmentComments[segment] }}
                  </div>
                  <!-- Default/custom feedback text -->
                  <div
                    *ngIf="isModal || (!isModal && !isUserClientView)"
                    class="my-2 flex-1"
                  >
                    <ng-container
                      *ngIf="
                        this[segment + 'Level']() !== undefined;
                        else noLevel
                      "
                    >
                      <ng-container
                        *ngIf="
                          trainingAvatarConfigService.showCustomLevelFeedback$
                            | async as showCustomLevelFeedback;
                          else defaultFeedback
                        "
                      >
                        <ng-container
                          *ngIf="
                            userConfig?.segmentsLevelsFeedback?.[segment]?.[
                              this[segment + 'Level']()
                            ];
                            else defaultFeedback
                          "
                        >
                          {{
                            userConfig.segmentsLevelsFeedback[segment][
                              this[segment + 'Level']()
                            ]
                          }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-template #defaultFeedback>
                      {{
                        getLevelDropdownLabel(
                          segment,
                          this[segment + 'Level']()
                        )
                      }}
                    </ng-template>
                    <ng-template #noLevel>
                      {{ getLevelDropdownLabel(segment, undefined) }}
                    </ng-template>
                  </div>
                  <div *ngIf="isModal && currentClientTrainingAvatar">
                    <textarea
                      class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
                      *ngIf="isModal"
                      cols="30"
                      rows="1"
                      [(ngModel)]="
                        currentClientTrainingAvatar.segmentComments[segment]
                      "
                      (ngModelChange)="onSegmentCommentsChange($event, segment)"
                      [readonly]="isUserClientView"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <button
            *ngIf="isModal"
            (click)="enterEditMode()"
            class="mb-4 flex w-full cursor-pointer items-center justify-center rounded-full bg-black/20 py-1 transition-all duration-200 hover:bg-black/80"
          >
            Editar
          </button>
          <div
            *ngIf="!isModal"
            (click)="openTrainingAvatarSideModal()"
            class="absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent"
          ></div>
        </div>
        <div
          *ngIf="
            selectedTab === 'nivel' && isModal && currentClientTrainingAvatar
          "
          class="content px-8"
        >
          <p class="mb-2 font-bold">Comentarios de nivel</p>
          <textarea
            class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
            #commentsTextArea
            name="comments"
            cols="30"
            rows="5"
            [(ngModel)]="currentClientTrainingAvatar.nivelComments"
            (ngModelChange)="onNivelCommentsChange($event)"
            [readonly]="isUserClientView"
          ></textarea>
        </div>
        <div
          *ngIf="
            selectedTab === 'resultados' &&
            isModal &&
            currentClientTrainingAvatar
          "
          class="content px-8"
        >
          <p class="mb-2 font-bold">Comentarios de resultados</p>
          <textarea
            class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
            #commentsTextArea
            name="comments"
            cols="30"
            rows="5"
            [(ngModel)]="currentClientTrainingAvatar.resultsComments"
            (ngModelChange)="onResultsCommentsChange($event)"
            [readonly]="isUserClientView"
          ></textarea>
        </div>
        <div [class.content]="isModal">
          <div *ngIf="isModal && currentClientTrainingAvatar">
            <div class="w-full">
              <p class="mb-1 mt-7 text-lg font-semibold">Enlaces multimedia</p>
              <ng-container *ngIf="!isUserClientView">
                <p>
                  Accede a la carpeta “Documentos” e inserta la URL de los
                  archivos multimedia.
                </p>
                <button
                  *ngIf="!clientSharedFolderUrl"
                  (click)="this.editClient({ client: data })"
                  class="mt-2 font-semibold text-white underline"
                >
                  Añade la carpeta "Documentos"
                </button>
                <a
                  *ngIf="clientSharedFolderUrl"
                  class="mt-2 inline-block font-semibold text-white underline"
                  href="{{ clientSharedFolderUrl }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Ir a carpeta "Documentos" del cliente</a
                >
                <input
                  class="w-full rounded-lg border-2 border-white bg-intecc"
                  name="multimediaLink"
                  [ngModel]="
                    this.currentClientTrainingAvatar.multimediaLink
                      ? this.currentClientTrainingAvatar.multimediaLink
                      : ''
                  "
                  (ngModelChange)="this.setMultimediaLink($event)"
                />
              </ng-container>
              <ng-container *ngIf="isUserClientView">
                <a
                  *ngIf="this.currentClientTrainingAvatar.multimediaLink"
                  href="{{ this.currentClientTrainingAvatar.multimediaLink }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="break-all text-white hover:underline"
                >
                  {{ this.currentClientTrainingAvatar.multimediaLink }}
                </a>
                <p *ngIf="!this.currentClientTrainingAvatar.multimediaLink">
                  No hay enlace multimedia disponible
                </p>
              </ng-container>
              <p class="mb-1 mt-7 text-lg font-semibold">Enlaces opcionales</p>
              <p *ngIf="!isUserClientView">
                Añade enlaces opcionales a los informes (webs, videos, lecturas,
                etc).
              </p>
              <app-test-report-links
                [isUserClientView]="isUserClientView"
                [theme]="'avatar'"
                [links]="currentClientTrainingAvatar.links"
                (changeEvent)="this.setLinks($event)"
              ></app-test-report-links>
            </div>
            <div class="mb-8">
              <button
                (click)="generateReport()"
                target="_blank"
                href="https://plataforma.intecc.org/courses/INICIACION-valoracion-anatomica-y-funcional"
                class="mt-4 flex w-full flex-row items-center justify-center overflow-hidden rounded-xl bg-inteccDark px-6 py-3 font-bold text-white transition-all hover:bg-inteccDark/80"
              >
                <i class="fas fa-file-pdf mr-2"></i>
                <span *ngIf="!generatingReport">Generar Informe</span>
                <span *ngIf="generatingReport">Generando...</span>
              </button>
            </div>
          </div>
          <!-- <div class="mx-auto mt-12 max-w-md">
            <app-two-tab-button
              [buttonATitle]="'Resultados'"
              [buttonACode]="'resultados'"
              [buttonBTitle]="'Nivel'"
              [buttonBCode]="'nivel'"
              [selectedButtonCode]="selectedTab"
              (buttonSelected)="changeSelectedTab($event)"
              [isModal]="isModal"
            ></app-two-tab-button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- END OF CONTENT -->
    <!-- START OF FOOTER (two-tab-button) -->
    <div [class.content]="isModal" [class.w-full]="isModal">
      <div class="mx-auto mt-4 max-w-md">
        <app-two-tab-button
          [buttonATitle]="'Resultados'"
          [buttonACode]="'resultados'"
          [buttonBTitle]="'Nivel'"
          [buttonBCode]="'nivel'"
          [selectedButtonCode]="selectedTab"
          (buttonSelected)="changeSelectedTab($event)"
          [isModal]="isModal"
        ></app-two-tab-button>
      </div>
    </div>
    <!-- END OF FOOTER (two-tab-button) -->
  </div>

  <div *ngIf="editMode" class="h-full bg-intecc text-white">
    <div
      class="flex h-14 items-center justify-between bg-inteccDark p-4 text-2xl"
    >
      <p class="font-bold">Editar Avatar</p>
      <div class="cursor-pointer" (click)="exitEditMode()">
        <i class="fas fas fa-chevron-circle-left"></i>
      </div>
    </div>
    <div
      style="height: calc(100% - 56px)"
      class="custom-scroll-2 h-full overflow-y-auto px-8 py-5"
    >
      <form [formGroup]="editLevelsConfigForm">
        <div class="mb-4 flex items-center space-x-2">
          <app-checkbox
            formControlName="showCustomLevelFeedback"
            [inputId]="'showCustomLevelFeedback'"
          />
          <label for="showCustomLevelFeedback" class="text-sm font-semibold">
            Cambiar a mis niveles de entrenamiento en las casillas que rellene
          </label>
        </div>

        <div formGroupName="levels">
          <div
            *ngFor="let item of segmentsMap | keyvalue: originalOrder"
            class="mb-8"
          >
            <p class="rounded bg-black/20 p-2 text-center text-xl font-bold">
              {{ item.value }}
            </p>
            <div class="mt-4">
              <div *ngFor="let level of levelsNumbersList" class="mb-2">
                <div class="form__field">
                  <label
                    class="text-sm font-semibold uppercase text-white"
                    [for]="item.key + '_' + level"
                  >
                    Nivel {{ level }}
                  </label>
                  <input
                    class="form__input w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
                    type="text"
                    [id]="item.key + '_' + level"
                    [formControlName]="item.key + '_' + level"
                    [placeholder]="
                      'Valor para ' + item.value + ' nivel ' + level
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
