export enum InclinometerAvailability {
    Available,
    BlockedIos,
    NotDetected,
}

export enum PreviousAngle {
    Yes,
    No
}

