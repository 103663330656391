export interface Point {
    x: number;
    y: number;
}

export const angleFromLines = (
    A1x: number,
    A1y: number,
    A2x: number,
    A2y: number,
    B1x: number,
    B1y: number,
    B2x: number,
    B2y: number,
    withNegatives: boolean
) => {
    const dAx = A2x - A1x;
    const dAy = A2y - A1y;
    const dBx = B2x - B1x;
    const dBy = B2y - B1y;
    const angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
    let degreeAngle = angle * (180 / Math.PI);
    if (degreeAngle < 0 && !withNegatives) {
        degreeAngle = 360 + degreeAngle;
    }
    return degreeAngle;
};

export const centerOfSegment = (ax: number, ay: number, bx: number, by: number): { x: number, y: number } => {
    const result = { x: 0, y: 0 };
    result.x = (ax + bx) / 2;
    result.y = (ay + by) / 2;
    return result;
};

export const calculateDistance = (ax: number, ay: number, bx: number, by: number) => {
    const x = ax - bx;
    const y = ay - by;
    const distance = Math.sqrt(x * x + y * y);
    return distance;
};

export const perpendicular = (x: number, y: number, reverse: boolean): Point => {
    if (!reverse) {
        return { x: y, y: -x };
    } else {
        return { x: -y, y: x };
    }
};

export const SumPoints = (a: Point, b: Point): Point => {
    return { x: a.x + b.x, y: a.y + b.y };
};

export const MultPoint = (a: Point, factor: number): Point => {
    return { x: a.x * factor, y: a.y * factor };
};

export const NegativePoint = (a: Point): Point => {
    return { x: a.x, y: -a.y };
};

export const NormalizePoint = (a: Point): Point => {
    const mag = Math.sqrt(a.x * a.x + a.y * a.y);
    return { x: a.x / mag, y: a.y / mag };
};
