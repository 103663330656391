import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  openMyVafMenu = false;
  openTrainingSessionsMenu = false;
  openCorrectiveSessionsMenu = false;

  professionalIdQueryParam = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initializeRouteParams();
  }

  private initializeRouteParams() {
    this.route.queryParams.subscribe((params) => {
      const professionalId = params['professionalId'];
      if (professionalId) {
        this.professionalIdQueryParam = professionalId;
      } else {
        this.professionalIdQueryParam = null;
      }
    });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
  goToHome() {
    this.router.navigate(['/']);
  }
  goToClient(clientId: string) {
    this.router.navigate(['/', 'client', clientId]);
  }
  goToTest(testId: string) {
    this.router.navigate(['/test', testId]);
  }
  goToClientTrainingSession(clientTrainingSessionId: string) {
    this.router.navigate([
      '/',
      'client-training-session',
      clientTrainingSessionId,
    ]);
  }
  goToClientCorrectiveSession(clientCorrectiveSessionId: string) {
    this.router.navigate([
      '/',
      'client-corrective-session',
      clientCorrectiveSessionId,
    ]);
  }

  goToAcceptConditions() {
    this.router.navigate(['/accept-conditions']);
  }

  // client navigations
  getClientNavigationConfig(config?: NavigationExtras): NavigationExtras {
    return {
      queryParams: this.professionalIdQueryParam
        ? {
            professionalId: this.professionalIdQueryParam,
          }
        : null,
      ...(config ? config : {}),
    };
  }

  async removeQueryParam(queryParam: string) {
    await this.router.navigate([], { queryParams: { [queryParam]: null } });
  }

  clientNav = {
    goToLogin: (navExtraConfig?: NavigationExtras) => {
      this.router.navigate(
        ['c/login'],
        this.getClientNavigationConfig({
          ...navExtraConfig,
        }),
      );
    },

    goToHome: () => {
      this.router.navigate(['c'], this.getClientNavigationConfig());
    },

    goToAcceptConditions: (navExtraConfig?: NavigationExtras) => {
      this.router.navigate(
        ['/accept-conditions'],
        this.getClientNavigationConfig({
          ...navExtraConfig,
        }),
      );
    },

    goToJoinProfessional: (professionalId: string) => {
      this.router.navigate(['c', 'join-professional'], {
        queryParams: { professionalId },
      });
    },

    goToValorations: () => {
      this.router.navigate(
        ['c', 'valorations'],
        this.getClientNavigationConfig(),
      );
    },
  };
}
