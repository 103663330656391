<div *ngIf="theme === 'default'">
  <ng-container *ngIf="!isUserClientView">
    <div
      *ngFor="let link of links; index as i"
      class="mt-4 grid grid-cols-fr-fr-auto items-end gap-x-5"
    >
      <div class="">
        <p class="mb-1 mr-3 text-sm font-bold uppercase text-neutral-700">
          Título
        </p>
        <input
          [value]="link.title"
          (change)="onInputChanged(i, 'title', $event)"
          class="w-full rounded-lg border-2 border-neutral-200"
          type="text"
          name="title"
        />
      </div>
      <div class="">
        <p class="mb-1 mr-3 text-sm font-bold uppercase text-neutral-700">
          URL
        </p>
        <input
          [value]="link.url"
          (change)="onInputChanged(i, 'url', $event)"
          class="w-full rounded-lg border-2 border-neutral-200"
          type="text"
          name="title"
        />
      </div>
      <div class="w-32">
        <app-button
          class=""
          *ngIf="i === links.length - 1 || links.length === 1; else deleteBlock"
          [text]="'Añadir más'"
          (clickEvent)="add()"
        ></app-button>
        <ng-template #deleteBlock>
          <app-button
            *ngIf="i !== links.length"
            [text]="'Eliminar'"
            (clickEvent)="remove(i)"
          ></app-button>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isUserClientView">
    <div *ngFor="let link of links">
      <a
        class="hover:underline"
        [href]="link.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ link.title ? link.title : link.url }}
      </a>
    </div>
  </ng-container>
</div>

<div *ngIf="theme === 'avatar'">
  <ng-container *ngIf="!isUserClientView">
    <div
      *ngFor="let link of links; index as i"
      class="mt-2 grid grid-cols-fr-fr-auto items-end gap-x-5 text-white"
    >
      <div class="">
        <p class="mb-1 mr-3 text-xs font-bold">Título</p>
        <input
          [value]="link.title"
          (change)="onInputChanged(i, 'title', $event)"
          class="w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2"
          type="text"
          name="title"
        />
      </div>
      <div class="">
        <p class="mb-1 mr-3 font-bold">URL</p>
        <input
          [value]="link.url"
          (change)="onInputChanged(i, 'url', $event)"
          class="w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2"
          type="text"
          name="title"
        />
      </div>
      <div class="w-10">
        <div
          class="cursor-pointer"
          *ngIf="i === links.length - 1 || links.length === 1; else deleteBlock"
          (click)="add()"
        >
          <i class="far fa-plus-square"></i>
        </div>
        <ng-template #deleteBlock>
          <div
            class="cursor-pointer"
            *ngIf="i !== links.length"
            (click)="remove(i)"
          >
            <i class="far fa-trash-alt"></i>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isUserClientView">
    <div
      *ngFor="let link of links; index as i"
      class="space-y-y mt-2 text-white"
    >
      <a
        class="text-white hover:underline"
        [href]="link.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ link.title ? link.title : link.url }}
      </a>
    </div>
  </ng-container>
</div>
