export enum TestImagesEnum {
  PlanoFrontalAnterior = "PlanoFrontalAnterior",
  PlanoFrontalPosterior = "PlanoFrontalPosterior",
  PlanoSagitalDerecho = "PlanoSagitalDerecho",
  PlanoSagitalIzquierdo = "PlanoSagitalIzquierdo",
  PlanoFrontalAnteriorRelajado = "PlanoFrontalAnteriorRelajado",
  PlanoFrontalPosteriorRelajado = "PlanoFrontalPosteriorRelajado",

  FootPlanoFrontalAnteriorNeutro = "FootPlanoFrontalAnteriorNeutro",
  FootHalluxValgus = "FootHalluxValgus",
  FootPlanoSagitalIzquierdo = "FootPlanoSagitalIzquierdo",
  FootPlanoSagitalDerecho = "FootPlanoSagitalDerecho",
  FootPlanoFrontalAnteriorRelajado = "FootPlanoFrontalAnteriorRelajado",
  FootPlanoFrontalPosteriorRelajado = "FootPlanoFrontalPosteriorRelajado",
  FootHuellaPlantarIzquierda = "FootHuellaPlantarIzquierda",
  FootHuellaPlantarDerecha = "FootHuellaPlantarDerecha",
  FootTripleFlexionDeTobillo = "FootTripleFlexionDeTobillo",
  FootTripleFlexionDeTobilloPosterior = "FootTripleFlexionDeTobilloPosterior",
  FootCarreraPlanoFrontalPosterior = "FootCarreraPlanoFrontalPosterior",
  FootCarreraPlanoSagital = "FootCarreraPlanoSagital",

  RomShortTestTripleFlexionSagital = "RomShortTestTripleFlexionSagital",
  RomShortTestTripleFlexionPosterior = "RomShortTestTripleFlexionPosterior",
  RomShortDedosPlantaLVFx = "RomShortDedosPlantaLVFx",
  RomShortDedosSueloLHFx = "RomShortDedosSueloLHFx",
  RomShortPlanoFrontalPosteriorTronco = "RomShortPlanoFrontalPosteriorTronco",
  RomShortTestMovilidadGeneral = "RomShortTestMovilidadGeneral",
  RomShortLimitacionArtromuscular1 = "RomShortLimitacionArtromuscular1",
  RomShortLimitacionArtromuscular2 = "RomShortLimitacionArtromuscular2",
}

export interface TestImageInfo {
  name: string;
}

export const TestImages: { [key in TestImagesEnum]: TestImageInfo } = {
  PlanoFrontalAnterior: { name: "Plano Frontal Anterior" },
  PlanoFrontalPosterior: { name: "Plano Frontal Posterior" },
  PlanoSagitalDerecho: { name: "Plano Sagital Derecho" },
  PlanoSagitalIzquierdo: { name: "Plano Sagital Izquierdo" },
  PlanoFrontalAnteriorRelajado: { name: "Plano Frontal Anterior Relajado" },
  PlanoFrontalPosteriorRelajado: { name: "Plano Frontal Posterior Relajado" },

  FootPlanoFrontalAnteriorNeutro: { name: "Plano Frontal Anterior" },
  FootHalluxValgus: { name: "Plano Frontal Posterior" },
  FootPlanoSagitalIzquierdo: { name: "Plano Sagital Izquierdo" },
  FootPlanoSagitalDerecho: { name: "Plano Sagital Derecho" },
  FootPlanoFrontalAnteriorRelajado: { name: "Plano Frontal Anterior Relajado" },
  FootPlanoFrontalPosteriorRelajado: {
    name: "Plano Frontal Posterior Relajado",
  },
  FootHuellaPlantarIzquierda: { name: "Huella Plantar Izquierda" },
  FootHuellaPlantarDerecha: { name: "Huella Plantar Derecha" },
  FootTripleFlexionDeTobillo: { name: "Triple Flexion De Tobillo (Sagital)" },
  FootTripleFlexionDeTobilloPosterior: {
    name: "Triple Flexion De Tobillo (Posterior)",
  },
  FootCarreraPlanoFrontalPosterior: { name: "Carrera Plano Frontal Posterior" },
  FootCarreraPlanoSagital: { name: "Carrera Plano Sagital" },

  RomShortTestTripleFlexionSagital: { name: "Test Triple Flexión (Sagital)" },
  RomShortTestTripleFlexionPosterior: {
    name: "Test Triple Flexión (Posterior)",
  },
  RomShortDedosPlantaLVFx: { name: "Dedos Suelo (LV Fx)" },
  RomShortDedosSueloLHFx: { name: "Dedos Planta (LH Fx)" },
  RomShortPlanoFrontalPosteriorTronco: { name: "Limitación Escapular 01" },
  RomShortTestMovilidadGeneral: { name: "Limitación Escapular 02" },
  RomShortLimitacionArtromuscular1: { name: "Limitación Artromuscular 01" },
  RomShortLimitacionArtromuscular2: { name: "Limitación Artromuscular 02" },
};

export interface TestImageValue {
  type: "original" | "edit";
  id: string;
  link?: string;
  linkTitle?: string;
  imageTitle?: string;
}
