import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-media-edit-input-modal',
    templateUrl: './media-edit-input-modal.component.html',
    styleUrls: ['./media-edit-input-modal.component.scss']
})
export class MediaEditInputModalComponent {

    @Input() title: string;
    @Input() isOpen = false;
    @Input() placeholder: string;
    @Input() value: string;
    @Input() resultCallback: any;

    constructor() { }

    accept() {
        this.resultCallback(this.value);
        this.isOpen = false;
    }

}
