<div class="filter-search">
  <input
    (ngModelChange)="searchInputTextChange($event)"
    [ngModel]="searchInputText"
    placeholder="Buscar clientes..."
    class="input"
    type="text"
  />
  <div class="button-box mr-2">
    <div *ngIf="hasFilter()" (click)="clearFilters()" class="clear-filter">
      <span>Limpiar filtro</span> <i class="fas fa-times"></i>
    </div>
    <button
      class="flex h-full shrink-0 items-center justify-center px-2"
      [class.opacity-50]="(nameSortDirection$ | async) === null"
      (click)="toggleNameSortDirection()"
    >
      <i
        class="{{
          (nameSortDirection$ | async) === 'asc'
            ? 'fa-solid fa-arrow-down-a-z'
            : 'fa-solid fa-arrow-up-a-z'
        }}"
      ></i>
    </button>
    <button
      class="flex h-full shrink-0 items-center justify-center px-2"
      [class.opacity-50]="(createdAtSortDirection$ | async) === null"
      (click)="toggleCreatedAtSortDirection()"
    >
      <i
        class="fas {{
          (createdAtSortDirection$ | async) === 'asc'
            ? 'fa-sort-amount-down'
            : 'fa-sort-amount-up'
        }}"
      ></i>
    </button>
    <button
      class="flex h-full shrink-0 items-center justify-center px-2"
      (click)="openFilterModal()"
    >
      <i class="fas fa-filter"></i>
    </button>
  </div>
</div>
