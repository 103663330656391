import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { firebaseTimestampFromDate, safeToPromise } from 'src/app/utils/utils';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, shareReplay, tap } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import {
  ClientTrainingSession,
  TrainingSessionSectionsDef,
  TrainingSessionProtocolDef,
  getCommentsClientTrainingSessionSection,
  getTipsClientTrainingSession,
  setCommentsClientTrainingSessionSection,
  setTipsClientTrainingSession,
} from 'src/shared/training-session-definition.outside';
import { TrainingSessionsService } from 'src/app/services/training-sessions.service';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'app-client-training-session',
  templateUrl: './client-training-session.component.html',
  styleUrl: './client-training-session.component.scss',
})
export class ClientTrainingSessionComponent implements OnInit {
  clientTrainingSessionId$: Observable<string>;
  clientTrainingSession$: Observable<ClientTrainingSession>;
  clientId: string;
  client$: Observable<Client>;
  isUserClientView: boolean;

  displayedColumns: string[] = ['position', 'name'];

  clientTrainingSession: ClientTrainingSession;
  trainingSessionProtocolDef: TrainingSessionProtocolDef;

  preBuiltDataSources = {};
  preBuiltDisplayedColumns = {};

  copySharedUrlButton = {
    faClass: 'far fa-copy',
    action: () => {
      this.clipboardService.copyToClipboard(this.getShareURL());
    },
  };

  constructor(
    private trainingSessionsService: TrainingSessionsService,
    private route: ActivatedRoute,
    private clients: ClientsService,
    public nav: NavigationService,
    private clipboardService: ClipboardService,
  ) {}

  async ngOnInit() {
    this.isUserClientView = this.route.snapshot.data['isUserClientView'];
    this.clientTrainingSessionId$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
    );
    this.clientTrainingSession = await safeToPromise(
      this.clientTrainingSessionId$.pipe(
        switchMap((id) => {
          return this.trainingSessionsService.getClientTrainingSession$(id);
        }),
        take(1),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    );
    this.clientId = this.clientTrainingSession.client;
    this.clients.setCurrentClient(this.clientId);
    this.client$ = this.clients.currentClient$;
    // .subscribe((currentClient) => {
    //   console.log('this.clients.currentClient$',this.clients.currentClient$)
    //   return currentClient
    // })
    this.init();
  }

  async backToClient() {
    this.nav.goToClient(this.clientId);
  }

  async init() {
    this.trainingSessionProtocolDef =
      await this.trainingSessionsService.getTrainingSessionProtocol(
        this.clientTrainingSession.trainingSessionProtocolDefId,
      );

    for (
      let index = 0;
      index < this.trainingSessionProtocolDef.sections.length;
      index++
    ) {
      const section = this.trainingSessionProtocolDef.sections[index];
      this.preBuildTableData(section);
    }
  }

  preBuildTableData(section: TrainingSessionSectionsDef) {
    this.preBuiltDataSources[section.localId] =
      this.buildTableDataSource(section);
    this.preBuiltDisplayedColumns[section.localId] =
      this.getDisplayedColumns(section);
  }

  onDateChanged(date: any) {
    this.clientTrainingSession.createdAt = firebaseTimestampFromDate(
      date.value,
    );
    this.updateClientTrainingSession();
  }

  updateClientTrainingSession() {
    if (this.isUserClientView) {
      return;
    }
    this.trainingSessionsService.updateClientTrainingSession({
      ...this.clientTrainingSession,
    });
  }

  TESTgetFieldTitle(field) {
    console.log(field.title);
    return field.title;
  }

  buildTableDataSource(sectionDef: TrainingSessionSectionsDef) {
    if (sectionDef.tableMode !== 'xy') {
      const result = this.buildTableDataSourceX(sectionDef);
      return result;
    } else {
      const result = this.buildTableDataSourceXY(sectionDef);
      return result;
    }
  }

  buildTableDataSourceX(sectionDef: TrainingSessionSectionsDef) {
    const result = [];
    const numberOfRows: number = this.getNumberOfRows(sectionDef.localId);
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      for (let index2 = 0; index2 < sectionDef.fields.length; index2++) {
        const field = sectionDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          sectionDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    console.log(result);
    return result;
  }

  buildTableDataSourceXY(sectionDef: TrainingSessionSectionsDef) {
    console.log('buildXY');
    console.log(sectionDef);

    const result = [];
    if (!sectionDef.tableRowFields) {
      return result;
    }
    if (!sectionDef.fields) {
      return result;
    }
    const numberOfRows: number = sectionDef.tableRowFields.length;
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      item.rowTitle = sectionDef.tableRowFields[index].title;
      for (let index2 = 0; index2 < sectionDef.fields.length; index2++) {
        const field = sectionDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          sectionDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    console.log(result);
    return result;
  }

  getNumberOfRows(sectionLocalId: number) {
    let numberOfRows: number =
      this.clientTrainingSession[`${sectionLocalId}-rows`];
    // console.log('number of row', numberOfRows);

    if (!numberOfRows) {
      numberOfRows = 0;
    }

    return numberOfRows;
  }

  addRow(sectionLocalId: number) {
    this.clientTrainingSession[`${sectionLocalId}-rows`] =
      this.getNumberOfRows(sectionLocalId) + 1;
    const section = this.trainingSessionProtocolDef.sections.find(
      (e) => e.localId === sectionLocalId,
    );
    this.preBuildTableData(section);
    this.updateClientTrainingSession();
  }

  removeRow(sectionLocalId: number) {
    if (this.getNumberOfRows(sectionLocalId) === 0) {
      return;
    }
    this.clientTrainingSession[`${sectionLocalId}-rows`] =
      this.getNumberOfRows(sectionLocalId) - 1;
    const section = this.trainingSessionProtocolDef.sections.find(
      (e) => e.localId === sectionLocalId,
    );
    this.preBuildTableData(section);
    this.updateClientTrainingSession();
  }

  getDisplayedColumns(sectionDef: TrainingSessionSectionsDef) {
    const result: string[] = [];
    if (!sectionDef.fields) {
      return result;
    }
    if (sectionDef.tableMode === 'xy') {
      result.push('rowTitle');
    }
    for (let index = 0; index < sectionDef.fields.length; index++) {
      const field = sectionDef.fields[index];
      result.push(field.localId + '');
    }
    // console.log('displayed columns', result);

    return result;
  }

  getValue(sectionLocalId: number, fieldLocalId: number) {
    if (
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`] ===
      undefined
    ) {
      return -1;
    }
    const result =
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`];
    return result;
  }

  getValueTable(sectionLocalId: number, fieldLocalId: number, index: number) {
    if (
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}-${index}`
      ] === undefined
    ) {
      return -1;
    }
    const result =
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}-${index}`];
    return result;
  }

  getValueTableText(
    sectionLocalId: number,
    fieldLocalId: number,
    index: number,
  ) {
    if (
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}-${index}`
      ] === undefined
    ) {
      return '';
    }
    const result =
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}-${index}`];
    return result;
  }

  getValueText(sectionLocalId: number, fieldLocalId: number) {
    if (
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`] ===
      undefined
    ) {
      return '';
    }
    const result =
      this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`];
    return result;
  }

  setValue(sectionLocalId: number, fieldLocalId: number, value) {
    this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`] = value;
    console.log('Set Value: ', value);
    this.updateClientTrainingSession();
  }

  setValueText(sectionLocalId: number, fieldLocalId: number, value) {
    this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}`] = value;
    console.log('Set Value: ', value);
    this.updateClientTrainingSession();
  }

  setValueTextTable(
    sectionLocalId: number,
    fieldLocalId: number,
    index: number,
    value,
  ) {
    this.clientTrainingSession[`${sectionLocalId}-${fieldLocalId}-${index}`] =
      value;
    console.log('Set Value: ', value);
    this.updateClientTrainingSession();
  }

  getValueLF(sectionLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ] === undefined
    ) {
      return -1;
    }
    const result =
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ];
    return result;
  }

  getValueTextLF(sectionLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ] === undefined
    ) {
      return '';
    }
    const result =
      this.clientTrainingSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ];
    return result;
  }

  setValueLF(
    sectionLocalId: number,
    fieldLocalId: number,
    value,
    right: boolean,
  ) {
    this.clientTrainingSession[
      `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
    ] = value;
    console.log('Set Value: ', value);
    console.log('Updated Section');
    console.log(this.clientTrainingSession);

    this.updateClientTrainingSession();
  }

  setValueTextLF(
    sectionLocalId: number,
    fieldLocalId: number,
    value,
    right: boolean,
  ) {
    this.clientTrainingSession[
      `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
    ] = value;
    console.log('Set Value: ', value);
    this.updateClientTrainingSession();
  }

  onImageChanged() {
    this.updateClientTrainingSession();
  }

  getComments(sectionDef: TrainingSessionSectionsDef) {
    return getCommentsClientTrainingSessionSection(
      sectionDef,
      this.clientTrainingSession,
    );
  }

  onCommentsChanged(sectionDef: TrainingSessionSectionsDef, event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setCommentsClientTrainingSessionSection(
      sectionDef,
      this.clientTrainingSession,
      value,
    );
    this.updateClientTrainingSession();
  }

  getTips() {
    return getTipsClientTrainingSession(this.clientTrainingSession);
  }

  onTipsChanged(event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setTipsClientTrainingSession(this.clientTrainingSession, value);
    this.updateClientTrainingSession();
  }

  setLinks(value: any) {
    this.clientTrainingSession.links = value;
    this.updateClientTrainingSession();
  }

  processLineBreak(text: string) {
    text = text.replace(new RegExp('\n', 'g'), '<br />');
    return text;
  }

  getShareURL() {
    return this.trainingSessionsService.getClientTrainingSessionShareUrl(
      this.clientTrainingSession,
    );
  }
}
