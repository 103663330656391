<!-- botones de acciones en selección múltiple -->
<div
  *ngIf="currentApp === 'vaf'"
  class="clients__bulk-actions bulk-actions-bar content"
  [class.bulk-actions-bar--active]="selectedItems.size > 0"
>
  <div class="bulk-actions-bar__selected-info">
    <span class="bulk-actions-bar__selected-count"
      >{{ selectedItems.size }} </span
    >{{
      selectedItems.size > 1 || selectedItems.size === 0
        ? 'seleccionadas'
        : 'seleccionada'
    }}
  </div>
  <div class="bulk-actions-bar__actions">
    <button (click)="clearSelection()" class="bulk-actions-bar__action-button">
      <i class="far fa-times-circle"></i>
    </button>
    <ng-container *ngIf="!isUserClientView">
      <div class="bulk-actions-bar__separator"></div>
      <button
        (click)="deleteSelectedItems()"
        class="bulk-actions-bar__action-button"
      >
        <i class="far fa-trash-alt"></i>
      </button>
    </ng-container>
  </div>
  <div class="bulk-actions-bar__actions-end">
    <button
      (click)="generateReport()"
      class="bulk-actions-bar__action-button-text"
    >
      <div class="bulk-actions-bar__action-button-text__icon">
        <i class="far fa-file-pdf"></i>
      </div>
      <span class="bulk-actions-bar__action-button-text__text"
        >Generar informe</span
      >
    </button>
  </div>
</div>
<!-- banner para invitar a 'vaf' para quien no tenga 'vaf' y lista de protocolos -->

<ng-container *ngIf="currentApp === 'vaf'">
  <div
    class="mb-20 mt-4 h-[60vh] max-h-[252px] overflow-hidden rounded-2xl bg-white shadow-lg"
  >
    <ng-container [ngSwitch]="loadingState">
      <ng-container *ngSwitchCase="'loading'">
        <app-loader-feedback size="medium"></app-loader-feedback>
      </ng-container>

      <ng-container *ngSwitchCase="'error'">
        <div class="flex h-full items-center justify-center">
          <app-error-feedback></app-error-feedback>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'idle'">
        <div
          *ngIf="itemsObservable$ | async as listItems"
          class="relative h-full"
          [class.overflow-y-scroll]="canUseFeature"
        >
          <div
            class="valorations__not-subscribed-banner"
            [class.valorations__not-subscribed-banner--hidden]="canUseFeature"
          >
            <div class="valorations__not-subscribed-banner__content">
              <p class="valorations__not-subscribed-banner__title">
                {{ featureNotAvailableText }}
              </p>
              <app-two-state-button
                (buttonClick)="openUpgradeModal()"
                [loadingText]="'Procesando...'"
                [text]="'Probar gratis'"
                [faIconCode]="'fas fa-rocket'"
                [loading]="false"
                class="valorations__not-subscribed-banner__action"
              ></app-two-state-button>
            </div>
          </div>
          <div class="valorations__list">
            <div *ngFor="let listItem of listItems">
              <div class="flex items-center gap-2 px-4 py-2">
                <button
                  (click)="selectItem(listItem)"
                  [class]="
                    'flex h-10 w-10 shrink-0 items-center justify-center rounded-full' +
                    ' bg-' +
                    secondaryColorClass
                  "
                  [class.valorations__list__icon--selected]="
                    isItemSelected(listItem)
                  "
                >
                  <p
                    [class]="
                      'text-center text-lg font-bold ' +
                      'text-' +
                      primaryColorClass
                    "
                  >
                    <i class="{{ listItem.faIcon }}"></i>
                  </p>
                </button>
                <a
                  class="flex w-full items-center justify-between gap-2 p-2"
                  [routerLink]="listItem.url"
                >
                  <div>
                    <div class="font-bold">
                      {{ listItem.name }}
                    </div>
                    <div *ngIf="listItem.createdAt" class="">
                      {{ listItem.createdAt.toDate() | date }}
                    </div>
                  </div>
                </a>

                <button
                  *ngIf="!isUserClientView"
                  class="flex items-center justify-center p-1"
                  (click)="handleShowToClient(listItem)"
                  [matTooltip]="
                    listItem.showToClient
                      ? 'Visible para el cliente'
                      : 'No visible para el cliente'
                  "
                  matTooltipPosition="above"
                >
                  <i
                    *ngIf="listItem.showToClient"
                    class="fas fa-user-check text-intecc2"
                  ></i>
                  <i
                    *ngIf="!listItem.showToClient"
                    class="fas fa-user-slash text-intecc2 opacity-50"
                  ></i>
                </button>
              </div>
              <div class="valorations__list__separator"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
