import { Injectable } from '@angular/core';
import { FunctionsService } from './functions.service';
import {
  BehaviorSubject,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  shareReplay,
  finalize,
  tap,
} from 'rxjs';
import { Client, CLIENTS_PATH } from '../models/client.model';
import { UserConfig } from '../models/user-config.model';
import { User, USERS_PATH } from '../models/user.model';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { SnackbarService } from './snackbar.service';
import { Test, TESTS_PATH } from 'src/shared/test.outside';
import {
  CLIENT_CORRECTIVE_SESSIONS_PATH,
  ClientCorrectiveSession,
} from 'src/shared/corrective-session-definition.outside';
import {
  ClienLevelCategory,
  getLevelAverage,
  getLevelTextByNumber,
  TRAINING_AVATAR_PATH,
  TrainingAvatar,
} from '../../../../Server/functions/src/shared/training-avatar.shared';
import {
  CLIENT_TRAINING_SESSIONS_PATH,
  ClientTrainingSession,
} from 'src/shared/training-session-definition.outside';
import { MyVafProtocolTest } from 'src/shared/myvaf.outside';
import { LoadingState } from '../utils/utils';
import { NavigationService } from './navigation.service';
import {
  ACTIVE_SUBSCRIPTION_STATUSES,
  getSubscriptionTypeByProductId,
  SubscriptionType,
} from 'src/shared/subscriptions.shared';
import { Questionnaire } from 'src/shared/questionnaire.outside';

/** service for all related things to registered clients */
@Injectable({
  providedIn: 'root',
})
export class UserClientService {
  private loadingUserClientSubject = new BehaviorSubject<LoadingState>(
    'loading',
  );
  loadingUserClient$ = this.loadingUserClientSubject.asObservable();

  /** client data associated to the user with role 'client' */
  userClient$: Observable<Client | null>;

  /** config associated to client's professional */
  professionalConfig$: Observable<UserConfig | null>;

  /** connect links associated to client's professional */
  professionalConnectLinks$: Observable<
    { key: string; icon: string; url: string }[]
  >;

  /** professional data associated to the user with role 'client' */
  professional$: Observable<User | null>;

  professionalSubscriptionType$: Observable<SubscriptionType>;

  /** Tests associated to client */
  userClientTests$: Observable<Test[]>;

  /** Corrective sessions associated to client */
  userClientCorrectiveSessions$: Observable<ClientCorrectiveSession[]>;

  /** Training sessions associated to client */
  userClientTrainingSessions$: Observable<ClientTrainingSession[]>;

  currentClientTrainingAvatarRegistries$: Observable<TrainingAvatar[]>;
  currentClientTrainingAvatar$: Observable<TrainingAvatar>;
  currentClientLevel$: Observable<string>;
  currentClientLevelCategory$: Observable<ClienLevelCategory>;

  /** MyVaf tests associated to client */
  userClientMyVafTests$: Observable<MyVafProtocolTest[]>;

  user: User | null = null;

  availableProfessionalIds = [
    // 'rX5YmB6s2GP0yqVS7tciKuGeXyX2', // Pedro
    'xjW2ONfwOObcgwUoj1591xMkcbe2', // Julián
    // TODO: allow both when deploying?
    // IMPORTANT: different ids for production
  ];

  constructor(
    private functionsService: FunctionsService,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private snackBarService: SnackbarService,
    private navigationService: NavigationService,
  ) {
    this.authService.user$.subscribe((user) => {
      this.user = user;
    });

    const clientAndConfig$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of({
            userClient: null,
            professional: null,
            professionalConfig: null,
            professionalSubscriptionType: undefined,
          });
        }
        return from(
          this.functionsService.call(
            'getClientAndProfessionalConfigCallable',
            {},
          ),
        ).pipe(
          map((response) => ({
            userClient: response.userClient as Client | null,
            professional: response.professional as User | null,
            professionalConfig:
              response.professionalConfig as UserConfig | null,
            professionalSubscriptionType: undefined,
          })),
          map((data) => ({
            ...data,
            professionalSubscriptionType:
              data.professional?.subscriptions?.[0]?.productId &&
              ACTIVE_SUBSCRIPTION_STATUSES.includes(
                data.professional?.subscriptions?.[0]?.status,
              )
                ? getSubscriptionTypeByProductId(
                    data.professional.subscriptions[0].productId,
                  )
                : undefined,
          })),
        );
      }),
      shareReplay(1),
    );

    this.userClient$ = this.authService.user$.pipe(
      tap(() => {
        this.loadingUserClientSubject.next('loading');
      }),
      switchMap((user) => {
        if (!user?.clientId) {
          return of(null);
        }
        return this.firestoreService.doc$<Client>(
          `${CLIENTS_PATH}/${user.clientId}`,
        );
      }),
      tap((client) => {
        this.loadingUserClientSubject.next('idle');
      }),
      finalize(() => {
        this.loadingUserClientSubject.next('idle');
      }),
      shareReplay(1),
    );

    this.professionalConfig$ = clientAndConfig$.pipe(
      map((data) => data.professionalConfig),
    );
    this.professional$ = clientAndConfig$.pipe(
      map((data) => data.professional),
    );
    this.professionalSubscriptionType$ = clientAndConfig$.pipe(
      map((data) => data.professionalSubscriptionType),
    );
    this.professionalConnectLinks$ = this.professionalConfig$.pipe(
      map((config) => {
        const connectLinksEntries = config?.connectLinks
          ? Object.entries(config.connectLinks)
          : [];
        return connectLinksEntries
          .filter(([key, value]) => {
            return (
              value &&
              !key.includes('display') &&
              connectLinksEntries.some(([key2, value2]) => {
                return (
                  key2 ===
                    `display${key.charAt(0).toUpperCase() + key.slice(1)}` &&
                  value2 === true
                );
              })
            );
          })
          .map(([k3, v3]) => {
            return {
              key: k3,
              icon: this.getIconByKey(k3),
              url: v3 as string,
            };
          })
          .sort((a, b) => {
            return a.key.localeCompare(b.key);
          })
          .slice(0, 5);
      }),
    );

    // subscriptions for tests
    this.userClientTests$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user?.clientId) {
          return of([]);
        }
        return this.firestoreService.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', this.user.clientId)
            .where('showToClient', '==', true)
            // NOTE: only fetching the types that can be created in the newest version, so old types will not be available for client view
            .where('type', 'in', [
              'sagital',
              // 'sagital-img',
              // 'sagital-flechas',
              // 'rom',
              'posture',
              // 'foot',
              // 'rom-short',
            ])
            .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        // console.log('data retrieved for user client tests', data);
      }),
      shareReplay(1),
    );

    // subscriptions for corrective sessions
    this.userClientCorrectiveSessions$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user?.clientId) {
          return of([]);
        }
        return this.firestoreService.colWithIds$<ClientCorrectiveSession>(
          `${CLIENT_CORRECTIVE_SESSIONS_PATH}`,
          (ref) =>
            ref
              .where('client', '==', user.clientId)
              .where('showToClient', '==', true)
              .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        // console.log('data retrieved for user client corrective sessions', data);
      }),
      shareReplay(1),
    );

    // subscriptions for training avatar
    this.currentClientTrainingAvatarRegistries$ = this.userClient$.pipe(
      switchMap((client) => {
        if (!client) {
          return of([]);
        }
        return this.firestoreService.colWithIds$<TrainingAvatar>(
          `${TRAINING_AVATAR_PATH}`,
          (ref) => ref.where('client', '==', client.id).orderBy('date', 'desc'),
        );
      }),
      tap((data) => {
        // console.log('data retrieved for user client training avatar registries', data);
      }),
      shareReplay(1),
    );

    this.currentClientTrainingAvatar$ =
      this.currentClientTrainingAvatarRegistries$.pipe(
        switchMap((avatars) => {
          if (avatars && avatars.length > 0) {
            return of(avatars[0]);
          } else {
            return of(null);
          }
        }),
        map((avatar) => (avatar ? [avatar] : [])),
        map((data) => {
          return data?.[0];
        }),
        shareReplay(1),
      );

    this.currentClientLevel$ = this.currentClientTrainingAvatar$.pipe(
      map((data) => data?.level),
      map((clientLevel) => {
        return getLevelAverage(clientLevel);
      }),
    );

    this.currentClientLevelCategory$ = this.currentClientLevel$.pipe(
      map((level) => {
        return getLevelTextByNumber(+level);
      }),
    );

    // subscriptions for training sessions
    this.userClientTrainingSessions$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user?.clientId) {
          return of([]);
        }
        return this.firestoreService.colWithIds$<ClientTrainingSession>(
          `${CLIENT_TRAINING_SESSIONS_PATH}`,
          (ref) =>
            ref
              .where('client', '==', user.clientId)
              .where('showToClient', '==', true)
              .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        // console.log('data retrieved for user client training sessions', data);
      }),
      shareReplay(1),
    );

    // subscriptions for my vaf tests
    this.userClientMyVafTests$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user?.clientId) {
          return of([]);
        }
        return this.firestoreService
          .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
            ref
              .where('client', '==', this.user?.clientId)
              .where('type', '==', 'my-vaf')
              .where('showToClient', '==', true)
              .orderBy('createdAt', 'desc'),
          )
          .pipe(
            tap((data) => {
              // console.log('data retrieved for user client my vaf tests', data);
            }),
            shareReplay(1),
          );
      }),
    );

    // Trigger the userClient$ observable
    this.userClient$.subscribe();
  }

  getProfessionalById$(professionalId: string): Observable<User | null> {
    return from(
      this.functionsService.call('getProfessionalByIdCallable', {
        professionalId,
      }),
    ).pipe(
      map((response) => {
        return response as User | null;
      }),
    );
  }

  getUserDataByClientId$(clientId: string): Observable<{
    clientUser: User | null;
    questionnaire: Questionnaire | null;
  }> {
    return from(
      this.functionsService.call('getUserDataByClientIdCallable', {
        clientId,
      }),
    ).pipe(
      map((response) => {
        return response as {
          clientUser: User | null;
          questionnaire: Questionnaire | null;
        };
      }),
    );
  }

  // joinRandomProfessional() {
  //   const randomIndex = Math.floor(
  //     Math.random() * this.availableProfessionalIds.length,
  //   );
  //   const professionalId = this.availableProfessionalIds[randomIndex];
  //   this.navigationService.clientNav.goToJoinProfessional(professionalId);
  // }

  async createOrJoinClient(professional: User) {
    if (!this.user) {
      throw new Error('User not found');
    }
    // check if userClient$ first, if it exists, return
    const userClient = await firstValueFrom(this.userClient$);
    if (userClient?.owner) {
      this.snackBarService.error('Ya estás asignado a un profesional');
      return;
    }

    // check if existing client with same email in professional clients
    const joinClientResult = await this.functionsService.call(
      'joinClientCallable',
      {
        professionalId: professional.uid,
      },
    );

    if (joinClientResult.clientJoined) {
      console.log('Asignado a un profesional con cliente existente');
      this.snackBarService.success('Has sido asignado a un profesional');
      return;
    }

    // create client
    const newClientId = this.firestoreService.createId();
    const client: Client = {
      id: newClientId,
      owner: professional.uid,
      name: this.user.displayName,
      surname: '',
      email: this.user.email,
      userId: this.user.uid,
    };

    const result = await this.firestoreService.set(
      `${CLIENTS_PATH}/${newClientId}`,
      client,
    );

    await this.firestoreService.update(`${USERS_PATH}/${this.user.uid}`, {
      clientId: newClientId,
    });

    console.log('Asignado a un profesional creando un nuevo cliente');
    this.snackBarService.success('Has sido asignado a un profesional');

    return result;
  }

  async unlinkClient() {
    if (!this.user) {
      throw new Error('User not found');
    }
    if (!this.user.clientId) {
      throw new Error('Client not found');
    }
    await this.firestoreService.update(
      `${CLIENTS_PATH}/${this.user.clientId}`,
      {
        userId: '',
      },
    );
    await this.firestoreService.update(`${USERS_PATH}/${this.user.uid}`, {
      clientId: '',
    });
    await this.authService.refreshIdTokenWithCustomClaims();
  }

  getIconByKey(key: string) {
    switch (key) {
      case 'web':
        return 'fas fa-globe';
      case 'location':
        return 'fas fa-map-marker-alt';
      case 'connectPhone':
        return 'fa-solid fa-square-phone';
      case 'connectEmail':
        return 'fas fa-envelope';
      case 'whatsapp':
        return 'fab fa-whatsapp';
      case 'appointmentsCalendar':
        return 'far fa-calendar-alt';
      case 'paymentsLink':
        return 'fas fa-credit-card';
      case 'news':
        return 'fas fa-bullhorn';
      case 'instagram':
        return 'fab fa-instagram';
      case 'facebook':
        return 'fab fa-facebook';
      case 'twitter':
        return 'fab fa-twitter';
      case 'tiktok':
        return 'fab fa-tiktok';
      case 'other':
        return 'fas fa-link';
      default:
        return 'fas fa-link';
    }
  }
}
