import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './services/modal.service';
import { ActionInProgressComponent } from './components/action-in-progress/action-in-progress.component';
import { ActionInProgressService } from './services/action-in-progress.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { AppConfig } from 'src/config/app.config';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { BottomModalComponent } from './components/bottom-modal/bottom-modal.component';
import { SubscriptionsService } from './services/subscriptions.service';
import { SideModalLeftComponent } from './components/side-modal-left/side-modal-left.component';
import { AuthService } from './services/auth.service';
import { map, tap } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';
import { SwUpdate } from '@angular/service-worker';
import { Crisp } from 'crisp-sdk-web';
import { firstValueFrom } from 'rxjs';
import { safeToPromise } from './utils/utils';

// NOTE: before this, this code was in index.html

// <!-- crisp js script -->
// <!-- <script type="text/javascript">
//   window.$crisp = [];
//   window.CRISP_WEBSITE_ID = '016ae666-4ffd-4efe-b855-f4a54a687203';
//   (function () {
//     d = document;
//     s = d.createElement('script');
//     s.src = 'https://client.crisp.chat/l.js';
//     s.async = 1;
//     d.getElementsByTagName('head')[0].appendChild(s);
//   })();
//   window.$crisp.push(['do', 'chat:hide']);
//   $crisp.push(['safe', true]);
// </script> -->

Crisp.configure('016ae666-4ffd-4efe-b855-f4a54a687203');
Crisp.chat.hide();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'RaquisWebApp';
  test = 'Test';
  isClient = false;

  isProductionEnvironment = environment.production;

  @ViewChild(ModalComponent, { static: true })
  modalComponent: ModalComponent;

  @ViewChild(SideModalComponent, { static: true })
  sideModalComponent: SideModalComponent;

  @ViewChild(SideModalLeftComponent, { static: true })
  sideModalLeftComponent: SideModalLeftComponent;

  @ViewChild(BottomModalComponent, { static: true })
  bottomModalComponent: BottomModalComponent;

  @ViewChild(ActionInProgressComponent, { static: true })
  actionInProgressComponent: ActionInProgressComponent;

  hasTrial = false;
  daysLeftInTrial = 0;

  authLoading = false;
  authAction: 'signin' | 'signout' | null = null;

  // Add new property to track if signin is taking too long
  showReloadButton = false;
  private reloadTimeout: any;

  constructor(
    private modalService: ModalService,
    private actionInProgressService: ActionInProgressService,
    private titleService: Title,
    private subService: SubscriptionsService,
    private auth: AuthService,
    private swUpdate: SwUpdate,
  ) {
    this.checkForUpdates();
  }

  async testReports() {}

  ngOnInit(): void {
    this.modalService.modalComponent = this.modalComponent;
    this.modalService.sideModalLeftComponent = this.sideModalLeftComponent;
    this.modalService.bottomModalComponent = this.bottomModalComponent;
    this.actionInProgressService.component = this.actionInProgressComponent;
    if (AppConfig.app === 'raquis') {
      this.titleService.setTitle('Raquis');
    } else if (AppConfig.app === 'rom') {
      this.titleService.setTitle('ROM');
    }
    this.init();

    this.auth.authLoading$
      .pipe(
        tap((state) => {
          this.authLoading = state.loading;
          this.authAction = state.action;

          // Reset and handle reload button visibility
          if (state.loading && state.action === 'signin') {
            // Clear any existing timeout
            if (this.reloadTimeout) {
              clearTimeout(this.reloadTimeout);
            }
            // Show reload button after 10 seconds
            this.reloadTimeout = setTimeout(() => {
              this.showReloadButton = true;
            }, 60000);
          } else {
            this.showReloadButton = false;
            if (this.reloadTimeout) {
              clearTimeout(this.reloadTimeout);
            }
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
  }

  async init() {
    this.auth.user$
      .pipe(
        tap((user) => {
          this.isClient = user?.role === 'client';
        }),
      )
      .subscribe();
    const inteccSubscription = await safeToPromise(
      this.subService.currentUserSubscription$.pipe(
        map((c) => c?.subscription),
      ),
    );
    if (inteccSubscription) {
      // no need to check status, already checked in currentUserSubscription$ if this has any value
      return; // don't check for trial if user has intecc subscription
    }
    this.hasTrial = await this.subService.hasTrial();
    this.daysLeftInTrial = await this.subService.daysLeftInTrial();
  }

  testClick() {}

  reloadPage() {
    window.location.reload();
  }

  private checkForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      // Check for updates every 30 minutes
      setInterval(() => {
        console.log('Checking for updates...');
        this.swUpdate.checkForUpdate().then((updateFound) => {
          if (updateFound) {
            console.log('Update found!');
          }
        });
      }, 1800000);

      // When an update is available, activate it and reload
      this.swUpdate.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log('New version detected:', event.version);
            break;
          case 'VERSION_READY':
            console.log('New version ready to install:', event.latestVersion);
            // Optional: Show a prompt to the user
            if (
              confirm('Hay una nueva versión disponible. ¿Desea actualizar?')
            ) {
              this.swUpdate
                .activateUpdate()
                .then(() => window.location.reload());
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.error('Failed to install app version:', event.error);
            break;
        }
      });
    }
  }
}
