import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, interval, merge, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  multicast,
  skip,
  skipWhile,
  take,
  tap,
  throttle,
  throttleTime,
} from 'rxjs/operators';
import { EditedMediaItem } from 'src/app/models/media/edited-media-item.model';
import { Modal } from 'src/app/models/modal.model';
import { MediaEditService } from 'src/app/services/media/media-edit.service';
import { Image, Rect, Svg, SVG } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { EditItem, EditItemBD } from 'src/app/models/media/edit-items.model';
import { CrossItem } from 'src/app/models/media/cross-item.class';
import { Drawer } from 'src/app/models/media/drawer.interface';
import { CropEdit, CropMode } from 'src/app/models/media/crop-item.class';
import { AngleItem } from 'src/app/models/media/angle-item.class';
import { MeasureRef } from 'src/app/models/media/measure-ref.class';
import { MediaEditInputModalComponent } from 'src/app/components/media-edit-input-modal/media-edit-input-modal.component';
import { Measure } from 'src/app/models/media/measure.class';
import { Text } from 'src/app/models/media/text.class';
import { Rect as myRect } from 'src/app/models/media/rect.class';
import { RectEmpty } from 'src/app/models/media/rect-empty.class';
import { Arrow } from 'src/app/models/media/arrow.class';
import { environment } from 'src/environments/environment';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.scss'],
})
export class MediaEditComponent implements OnInit, OnDestroy, Modal, Drawer {
  @ViewChild(MediaEditInputModalComponent, { static: true })
  inputModal: MediaEditInputModalComponent;

  constructor(
    private mediaEditService: MediaEditService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  set data(value: MediaEditModalData) {
    this.dataValue = value;
    this.init();
  }

  get data() {
    return this.dataValue;
  }

  editedMediaItem: EditedMediaItem;

  renderUrl: string;

  @ViewChild('divElement', { static: true })
  svgDivElement: ElementRef;

  @ViewChild('svgElement', { static: true })
  svgElement: ElementRef;

  svgObject: Svg;

  imageNestedSvg: Svg;

  svgSize$ = new BehaviorSubject<{ w: number; h: number }>({ w: 0, h: 0 });

  currentSvgSize: { w: number; h: number };

  naturalImageSize: { w: number; h: number };

  viewBoxX = 0;
  viewBoxY = 0;
  viewBoxW = 0;
  viewBoxH = 0;

  imageAbsoluteH = 0;
  imageAbsoluteW = 0;
  imageAbsoluteX = 0;
  imageAbsoluteY = 0;

  zoomLevel = 1;
  moveEnabled = true;

  selectedItemMenuOpen = false;
  sideMenuOpenMobile = false;

  editItems: Array<EditItem> = [];
  selectedItemsIndex: Array<number> = [];
  cropEdit = CropEdit.create(0, 0, 1, 1);
  cropping = false;
  cropMode: CropMode = 'f';

  resizeObserver;

  subscriptions: Array<Subscription> = [];

  onClose = new Subject<any>();

  dataValue: MediaEditModalData;

  getInput(
    title: string,
    initialValue: string,
    placeholder: string,
    callback: any,
  ) {
    this.inputModal.isOpen = true;
    this.inputModal.title = title;
    this.inputModal.value = initialValue;
    this.inputModal.placeholder = placeholder;
    this.inputModal.resultCallback = callback;
  }

  relativeToAbsoluteX(value: number): number {
    const result = value * this.imageAbsoluteW + this.imageAbsoluteX;
    return result;
  }
  relativeToAbsoluteY(value: number): number {
    const result = value * this.imageAbsoluteH + this.imageAbsoluteY;
    return result;
  }

  relativeToAbsoluteW(value: number): number {
    const result = value * this.imageAbsoluteW;
    return result;
  }
  relativeToAbsoluteH(value: number): number {
    const result = value * this.imageAbsoluteH;
    return result;
  }

  absoluteToRelativeX(value: number): number {
    const result = value / this.imageAbsoluteW;
    return result;
  }

  absoluteToRelativeY(value: number): number {
    const result = value / this.imageAbsoluteH;
    return result;
  }

  absoluteToRelativeX2(value: number): number {
    const result = (value - this.imageAbsoluteX) / this.imageAbsoluteW;
    return result;
  }

  absoluteToRelativeY2(value: number): number {
    const result = (value - this.imageAbsoluteY) / this.imageAbsoluteH;
    return result;
  }

  selectedEvent(selectedIndex: number) {
    this.selectedItemMenuOpen = true;
    this.selectedItemsIndex = [];
    this.selectedItemsIndex.push(selectedIndex);
    for (let index = 0; index < this.editItems.length; index++) {
      const element = this.editItems[index];
      if (selectedIndex !== index) {
        element.setSelected(false);
      }
    }
    this.editItems[selectedIndex].setSelected(true);
  }

  unselect() {
    this.selectedItemsIndex = [];
    this.selectedItemMenuOpen = false;
    for (let index = 0; index < this.editItems.length; index++) {
      const element = this.editItems[index];
      element.setSelected(false);
    }
  }

  deleteSelectedItem() {
    this.editItems[this.selectedItemsIndex[0]].undraw();
    this.editItems.splice(this.selectedItemsIndex[0], 1);
    for (let index = 0; index < this.editItems.length; index++) {
      const element = this.editItems[index];
      element.index = index;
    }
    this.unselect();
    // this.drawBoard();
  }

  historyBackClose(): void {
    this.onClose.next(this.editedMediaItem);
  }

  ngOnInit(): void {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.svgSize$.next({ w: cr.width, h: cr.height });
      }
    });
    // Observe one or multiple elements
    this.resizeObserver.observe(this.svgDivElement.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.svgDivElement.nativeElement);
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  close() {
    history.back();
  }

  async saveAndClose() {
    const result = await this.save();
    this.close();
  }

  async domToImage() {
    // const node = this.svgDivElement.nativeElement;
    // const result = await html2canvas(node);
    // document.body.appendChild(result);
    // const dataUrl = await domtoimage.toPng(node);
    // console.log(dataUrl);
    // const img = document.createElement('img');
    // img.src = dataUrl;
  }

  async getCurrentSvgSize() {
    const result = await safeToPromise(
      this.svgSize$.pipe(
        skipWhile((val) => val.w === 0 && val.h === 0),
        take(1),
      ),
    );
    return result;
  }

  initSizeChangeSubscription() {
    const sub = this.svgSize$
      .pipe(
        debounceTime(200),
        tap((data) => this.onSvgSizeChangued(data)),
      )
      .subscribe();
    this.subscriptions.push(sub);
  }

  async init() {
    this.editedMediaItem = await this.mediaEditService.getEditedMediaItem(
      this.data.editedMediaItemId,
    );
    this.renderUrl = environment.production
      ? null
      : '/render?item=' +
        encodeURIComponent(JSON.stringify(this.editedMediaItem));
    this.svgObject = SVG(this.svgElement.nativeElement) as Svg;
    await this.loadImageSize();
    this.initInteractjs();
    this.populateItems();
    this.loadCrop();
    // this.debugPopulateEditItems();
    this.initSizeChangeSubscription();
  }

  debugPopulateEditItems() {
    const cross = new CrossItem({
      centerX: 0,
      centerY: 0,
      width: 0.5,
      height: 0.5,
    });
    cross.init(this, 0);
    this.editItems.push(cross);
  }

  loadCrop() {
    if (this.editedMediaItem.crop) {
      this.cropEdit = this.mediaEditService.buildEditItemFromDB(
        this.editedMediaItem.crop,
      ) as CropEdit;
    } else {
      this.cropEdit = CropEdit.create(0, 0, 1, 1);
    }
    this.cropEdit.init(this, -1);
  }

  populateItems() {
    this.editItems = [];
    const items = this.editedMediaItem.edits;
    if (!items) {
      return;
    }
    for (let index = 0; index < items.length; index++) {
      const itemDB = items[index];
      // const item = this.buildEditItemFromDB(itemDB);
      const item = this.mediaEditService.buildEditItemFromDB(itemDB);
      this.editItems.push(item);
    }
    this.initEditItems(this.editItems);
  }

  initEditItems(items: Array<EditItem>) {
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      item.init(this, index);
    }
  }

  saveItemsToEditedMediaItem() {
    this.editedMediaItem.edits = [];
    this.editItems.forEach((element) => {
      this.editedMediaItem.edits.push(element.toEditItemBD());
    });
    this.editedMediaItem.crop = this.cropEdit.toEditItemBD();
  }

  async save() {
    this.saveItemsToEditedMediaItem();
    await this.mediaEditService.saveEditedMediaItem(this.editedMediaItem);
    return this.editedMediaItem;
  }

  zoomIn() {
    this.zoomLevel = this.zoomLevel + 0.25;
    this.drawBoard();
  }

  zoomOut() {
    this.zoomLevel = this.zoomLevel - 0.25;
    if (this.zoomLevel < 0.25) {
      this.zoomLevel = 0.25;
    }

    this.drawBoard();
  }

  startCrop() {
    this.moveEnabled = false;
    this.cropEdit = CropEdit.create(0, 0, 1, 1);
    this.cropEdit.drawing = true;
    this.cropEdit.init(this, 0);
    this.cropping = true;
    this.drawBoard();
  }

  endCrop() {
    this.cropping = false;
    this.moveEnabled = true;
    this.cropEdit.drawing = false;
    this.drawBoard();
  }

  setCropMode(cropMode: CropMode) {
    this.cropMode = cropMode;
    this.cropEdit.cropMode = this.cropMode;
    this.cropEdit.adjustCropToCropMode('topLeft');
  }

  resetView() {
    this.zoomLevel = 1;
    this.viewBoxX = 0;
    this.viewBoxY = 0;
    this.viewBoxW = this.currentSvgSize.w;
    this.viewBoxH = this.currentSvgSize.h;
    this.setCropMode('f');
    this.drawBoard();
  }

  async initInteractjs() {
    interact(this.svgDivElement.nativeElement)
      .draggable({
        onstart: (event) => {},
        onmove: (event) => {
          if (!this.moveEnabled) {
            return true;
          }
          this.viewBoxX = this.viewBoxX - (1 / this.zoomLevel) * event.dx;
          this.viewBoxY = this.viewBoxY - (1 / this.zoomLevel) * event.dy;
        },
      })
      .gesturable({
        onmove: (event) => {
          // this.viewBoxX = this.viewBoxX - (1 / this.zoomLevel) * event.dx;
          // this.viewBoxY = this.viewBoxY - (1 / this.zoomLevel) * event.dy;
          this.zoomLevel = this.zoomLevel + event.scale;
          this.processZoom();
        },
      });
  }

  async loadImageSize() {
    let image: Image;
    const promise = new Promise<any>((resolve) => {
      image = this.svgObject.image(
        this.editedMediaItem.downloadURL,
        (event) => {
          resolve(event);
        },
      );
    });
    const result = await promise;
    this.naturalImageSize = {
      w: result.target.naturalWidth,
      h: result.target.naturalHeight,
    };
    this.svgObject.clear();
  }

  onSvgSizeChangued(size: { w: number; h: number }) {
    this.currentSvgSize = size;
    this.resetView();
  }

  async drawBoard() {
    this.processZoom();
    this.svgObject.clear();
    this.drawImage();
    if (!this.cropEdit.drawing) {
      this.drawItems();
    } else {
      this.cropEdit.draw();
    }
    this.changeDetectorRef.detectChanges();
  }

  drawItems() {
    for (let index = 0; index < this.editItems.length; index++) {
      const element = this.editItems[index];
      this.drawItem(element, index);
    }
  }

  drawItem(item: EditItem, index: number) {
    item.init(this, index, true);
    item.draw();
  }

  addItem(item: EditItem) {
    this.editItems.push(item);
    this.drawItem(item, this.editItems.length - 1);
  }

  processZoom() {
    if (this.viewBoxW === 0 || this.viewBoxH === 0) {
      this.viewBoxW = this.currentSvgSize.w;
      this.viewBoxH = this.currentSvgSize.h;
    }

    // const initialCenterX = this.currentSvgSize.w / 2 ;
    // const initialCenterY = this.currentSvgSize.h / 2 ;

    const initialCenterX = this.viewBoxW / 2 + this.viewBoxX;
    const initialCenterY = this.viewBoxH / 2 + this.viewBoxY;

    this.viewBoxW = this.currentSvgSize.w * (1 / this.zoomLevel);
    this.viewBoxH = this.currentSvgSize.h * (1 / this.zoomLevel);

    // const finalCenterX = initialCenterX / this.zoomLevel + this.viewBoxX;
    // const finalCenterY = initialCenterY / this.zoomLevel + this.viewBoxY;

    // const finalCenterX = (initialCenterX - this.viewBoxX) / this.zoomLevel + this.viewBoxX;
    // const finalCenterY = (initialCenterY - this.viewBoxY) / this.zoomLevel + this.viewBoxY;

    const finalCenterX = this.viewBoxW / 2 + this.viewBoxX;
    const finalCenterY = this.viewBoxH / 2 + this.viewBoxY;

    this.viewBoxX = this.viewBoxX + (initialCenterX - finalCenterX);
    this.viewBoxY = this.viewBoxY + (initialCenterY - finalCenterY);
  }

  drawImageD() {
    const image = this.svgObject.image(this.editedMediaItem.downloadURL);
    const imageW = this.naturalImageSize.w;
    const imageH = this.naturalImageSize.h;
    const imageAR = imageW / imageH;
    const boardW = this.currentSvgSize.w;
    const boardH = this.currentSvgSize.h;
    const boardAR = boardW / boardH;
    const marginW100 = 0.1;
    const marginH100 = 0.1;
    let newImageW;
    let newImageH;
    const targetW = boardW * (1 - marginW100);
    const targetH = boardH * (1 - marginH100);
    newImageW = targetW;
    newImageH = (imageH / imageW) * newImageW;
    if (newImageH > targetH) {
      newImageH = targetH;
      newImageW = (imageW / imageH) * newImageH;
    }
    image.size(newImageW, newImageH);
    const imageX = boardW / 2 - newImageW / 2;
    const imageY = boardH / 2 - newImageH / 2;
    image.x(imageX);
    image.y(imageY);

    this.imageAbsoluteH = newImageH;
    this.imageAbsoluteW = newImageW;
    this.imageAbsoluteX = imageX;
    this.imageAbsoluteY = imageY;
  }

  drawImage() {
    // const image = this.svgObject.image(this.editedMediaItem.downloadURL);
    this.imageNestedSvg = this.svgObject.nested();
    this.imageNestedSvg.node.setAttribute(
      'preserveAspectRatio',
      'xMinYMin meet',
    );
    const image = this.imageNestedSvg.image(this.editedMediaItem.downloadURL);
    // const absolute0NestedSvg = this.imageNestedSvg.rect(10, 10).x(0).y(0).fill('#0063DC');
    const imageW = this.naturalImageSize.w;
    const imageH = this.naturalImageSize.h;
    const imageAR = imageW / imageH;
    const croppedImageW = this.cropEdit.data.w * imageW;
    const croppedImageH = this.cropEdit.data.h * imageH;
    const croppedImageAR = croppedImageW / croppedImageH;
    const boardW = this.currentSvgSize.w;
    const boardH = this.currentSvgSize.h;
    console.log('boardW', boardW);

    const boardAR = boardW / boardH;
    const marginW100 = 0.1;
    const marginH100 = 0.1;
    let newImageW;
    let newImageH;
    let newCroppedImageW;
    let newCroppedImageH;
    const targetW = boardW * (1 - marginW100);
    const targetH = boardH * (1 - marginH100);
    // newImageW = targetW;
    // newImageH = (1 / imageAR) * newImageW;
    // if (newImageH > targetH) {
    //     newImageH = targetH;
    //     newImageW = imageAR * newImageH;
    // }

    newCroppedImageW = targetW;
    newCroppedImageH = (1 / croppedImageAR) * newCroppedImageW;
    if (newCroppedImageH > targetH) {
      newCroppedImageH = targetH;
      newCroppedImageW = croppedImageAR * newCroppedImageH;
    }

    newImageW = newCroppedImageW;
    newImageH = (1 / imageAR) * newImageW;
    if (newImageH < newCroppedImageH) {
      newImageH = newCroppedImageH;
      newImageW = imageAR * newImageH;
    }

    // newCroppedImageW = newImageW * this.cropEdit.w;
    // newCroppedImageH = newImageH * this.cropEdit.h;

    image.size(newImageW, newImageH);
    // image.size(imageW, imageH);
    this.imageNestedSvg.size(newCroppedImageW, newCroppedImageH);
    // this.imageNestedSvg.viewbox(newImageW * this.cropEdit.x, newImageH * this.cropEdit.y, newImageW * this.cropEdit.w, newImageH * this.cropEdit.h);
    this.imageNestedSvg.viewbox(
      newImageW * this.cropEdit.data.x,
      newImageH * this.cropEdit.data.y,
      newCroppedImageW * this.cropEdit.data.w,
      newCroppedImageH * this.cropEdit.data.h,
    );
    // const imageX = boardW / 2 - newImageW / 2;
    // const imageY = boardH / 2 - newImageH / 2;
    const imageX = boardW / 2 - newCroppedImageW / 2;
    const imageY = boardH / 2 - newCroppedImageH / 2;
    image.x(0);
    image.y(0);
    this.imageNestedSvg.x(imageX);
    this.imageNestedSvg.y(imageY);
    // const nestdSvgTopLeft = this.svgObject.rect(10, 10).x(imageX).y(imageY).fill('#0FF');
    // const nestdSvgTopRight = this.svgObject.rect(10, 10).x(imageX + newCroppedImageW).y(imageY).fill('#0FF');
    // const nestdSvgBottomLeft = this.svgObject.rect(10, 10).x(imageX).y(imageY + newCroppedImageH).fill('#0FF');
    // const nestdSvgBottomRight = this.svgObject.rect(10, 10).x(imageX + newCroppedImageW).y(imageY + newCroppedImageH).fill('#0FF');

    // this.imageAbsoluteH = newImageH;
    // this.imageAbsoluteW = newImageW;
    this.imageAbsoluteH = newCroppedImageH;
    this.imageAbsoluteW = newCroppedImageW;
    this.imageAbsoluteX = imageX;
    this.imageAbsoluteY = imageY;
  }

  openSideMenuMobile() {
    this.sideMenuOpenMobile = true;
  }

  closeSideMenuMobile() {
    this.sideMenuOpenMobile = false;
  }

  toggleSideMenuMobile() {
    this.sideMenuOpenMobile = !this.sideMenuOpenMobile;
  }

  addCross() {
    const cross = CrossItem.create(0.5, 0.5, 0.5, 0.5);
    this.addItem(cross);
    this.closeSideMenuMobile();
  }

  addAngle() {
    const angle = AngleItem.create(0.5, 0.5, 0.5, 0.25, 0.75, 0.5);
    this.addItem(angle);
    this.closeSideMenuMobile();
  }

  addMeasureRef() {
    if (
      this.editItems.filter((item) => item.type === 'measure-ref').length > 0
    ) {
      return;
    }
    const measureRef = MeasureRef.create(0.25, 0.5, 0.75, 0.5);
    this.addItem(measureRef);
    this.closeSideMenuMobile();
  }

  addMeasure() {
    const measure = Measure.create(0.25, 0.5, 0.75, 0.5);
    this.addItem(measure);
    this.closeSideMenuMobile();
  }

  addArrow() {
    const arrow = Arrow.create(0.25, 0.5, 0.75, 0.5);
    this.addItem(arrow);
    this.closeSideMenuMobile();
  }

  addText() {
    const text = Text.create(0.5, 0.5);
    this.addItem(text);
    this.closeSideMenuMobile();
  }

  addRect() {
    const rect = myRect.create(0.5, 0.5, 0.25, 0.25);
    this.addItem(rect);
    this.closeSideMenuMobile();
  }

  addRectEmpty() {
    const rect = RectEmpty.create(0.5, 0.5, 0.25, 0.25);
    this.addItem(rect);
    this.closeSideMenuMobile();
  }

  calculateCenterX() {
    return this.currentSvgSize.h / 2;
  }

  calculateCenterY() {
    return this.currentSvgSize.w / 2;
  }
}

export interface MediaEditModalData {
  editedMediaItemId?: string;
}
