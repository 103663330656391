<div [ngClass]="['relative inline-block text-left', wrapperClass]">
  <button
    type="button"
    (click)="toggleDropdown()"
    [ngClass]="[
      'inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-intecc focus:ring-offset-2',
      buttonClass,
    ]"
    [class.cursor-default]="readonly"
  >
    <span class="grow">
      {{ selectedOption?.label || '-' }}
    </span>
    <ng-container *ngIf="!readonly">
      <svg
        class="-mr-1 ml-2 h-5 w-5 shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </ng-container>
  </button>
  <div
    *ngIf="isOpen"
    class="absolute left-0 z-10 mt-2 min-w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div
      class="py-1"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <li
        *ngFor="let option of options"
        (click)="selectOption(option.value)"
        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        role="menuitem"
      >
        {{ option.label }}
      </li>
    </div>
  </div>
</div>
