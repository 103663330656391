import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { AuthService } from './auth.service';
import {
  TESTS_PATH,
  Test,
  TestNames,
  TestType as TestType,
  TestIcons,
} from '../../shared/test.outside';
import { AvatarTest } from 'src/shared/avatar.outside';
import { Client } from '../models/client.model';
import { SentryService } from './sentry.service';
import { safeToPromise, sleep } from '../utils/utils';
import {
  tap,
  distinctUntilChanged,
  filter,
  switchMap,
  shareReplay,
  take,
  map,
} from 'rxjs/operators';
import { ClientsService } from './clients.service';
import { Observable, Subscription, of, from } from 'rxjs';
import { createEmptySagital } from '../models/sagital-test.model';
import { createEmptySagitalImgTest } from '../models/sagital-img-test.model';
import { createEmptySagitalFlechasTest } from '../models/sagital-flechas-test.model';
import { createEmptyRomTest } from '../../shared/rom-test.model';
import { AppConfig } from 'src/config/app.config';
import { createEmptyPostureTest } from '../../shared/posture.outside';
import { createEmptyFootTest } from 'src/shared/foot.outside';
import { createEmptyRomShortTest } from 'src/shared/rom-short.outside';
import {
  createEmptyMyVafProtocolTest,
  MyVafProtocolDef,
  MyVafProtocolTest,
} from 'src/shared/myvaf.outside';
import { SnackbarService } from './snackbar.service';
import { FunctionsService } from './functions.service';
import {
  ClienLevelCategory,
  getLevelAverage,
  getLevelTextByNumber,
  TRAINING_AVATAR_PATH,
  TrainingAvatar,
} from '../../../../Server/functions/src/shared/training-avatar.shared';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  constructor(
    private fs: FirestoreService,
    private auth: AuthService,
    private sentry: SentryService,
    private clients: ClientsService,
    private snackbarService: SnackbarService,
    private functionsService: FunctionsService,
  ) {
    this.init();
  }

  TestNames = TestNames;
  TestIcons = TestIcons;

  currentClientTests$: Observable<Test[]>;
  currentClientMyVafTests$: Observable<MyVafProtocolTest[]>;
  currentClientTestsCount = 0;
  currentClientTrainingAvatarRegistries$: Observable<TrainingAvatar[]>;
  currentClientTrainingAvatar$: Observable<TrainingAvatar>;
  currentClientLevel$: Observable<string>;
  currentClientLevelCategory$: Observable<ClienLevelCategory>;

  subs: Subscription[] = [];

  async init() {
    this.currentClientTests$ = this.clients.currentClientId$.pipe(
      distinctUntilChanged(),
      filter((val) => val !== null),
      switchMap((id) => this.auth.user$.pipe(map((user) => ({ user, id })))),
      switchMap(({ user, id }) => {
        if (!user) {
          return of([]);
        }
        if (AppConfig.app === 'rom') {
          return this.fs.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
            ref
              .where('client', '==', id)
              .where('owner', '==', user.uid)
              .where('type', '==', 'rom')
              .orderBy('createdAt', 'desc'),
          );
        } else if (AppConfig.app === 'raquis') {
          return this.fs.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
            ref
              .where('client', '==', id)
              .where('owner', '==', user.uid)
              .where('type', 'in', [
                'sagital',
                'sagital-flechas',
                'sagital-img',
              ])
              .orderBy('createdAt', 'desc'),
          );
        } else {
          return this.fs.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
            ref
              .where('client', '==', id)
              .where('owner', '==', user.uid)
              .where('type', 'in', [
                'sagital',
                'sagital-img',
                'sagital-flechas',
                'rom',
                'posture',
                'foot',
                'rom-short',
              ])
              .orderBy('createdAt', 'desc'),
          );
        }
      }),
      tap((data) => {
        this.currentClientTestsCount = data.length;
      }),
      tap((data) => this.sentry.logFirestoreRead('Tests', data.length)),
      shareReplay(1),
    );
    this.currentClientMyVafTests$ = this.clients.currentClientId$.pipe(
      distinctUntilChanged(),
      filter((val) => val !== null),
      switchMap((id) => this.auth.user$.pipe(map((user) => ({ user, id })))),
      switchMap(({ user, id }) => {
        if (!user) {
          return of([]); // Return empty array if user is not defined
        }
        return this.fs.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', id)
            .where('owner', '==', user.uid)
            .where('type', 'in', ['my-vaf'])
            .orderBy('createdAt', 'desc'),
        );
      }),
      tap((data) => {
        this.currentClientTestsCount = data.length;
      }),
      tap((data) => this.sentry.logFirestoreRead('Tests', data.length)),
      shareReplay(1),
    );
    this.currentClientTrainingAvatarRegistries$ =
      this.clients.currentClientId$.pipe(
        distinctUntilChanged(),
        filter((val) => val !== null),
        switchMap((id) => this.auth.user$.pipe(map((user) => ({ user, id })))),
        switchMap(({ user, id }) => {
          if (!user) {
            return of([]); // Return empty array if user is not defined
          }
          return this.fs.colWithIds$<TrainingAvatar>(
            `${TRAINING_AVATAR_PATH}`,
            (ref) =>
              ref
                .where('client', '==', id)
                .where('owner', '==', user.uid)
                .orderBy('date', 'desc'),
          );
        }),
        shareReplay(1),
      );

    this.currentClientTrainingAvatar$ =
      this.currentClientTrainingAvatarRegistries$.pipe(
        switchMap((avatars) => {
          if (avatars && avatars.length > 0) {
            return of(avatars[0]);
          } else {
            return this.clients.currentClientId$.pipe(
              take(1),
              switchMap((clientId) => {
                if (!clientId) {
                  return of(null);
                }
                return from(this.createTrainingAvatarObject(clientId));
              }),
            );
          }
        }),
        map((avatar) => (avatar ? [avatar] : [])),
        map((data) => {
          return data?.[0];
        }),
        shareReplay(1),
      );

    this.currentClientLevel$ = this.currentClientTrainingAvatar$.pipe(
      map((data) => data?.level),
      map((clientLevel) => {
        return getLevelAverage(clientLevel);
      }),
    );

    this.currentClientLevelCategory$ = this.currentClientLevel$.pipe(
      map((level) => {
        return this.getLevelTextByNumber(+level);
      }),
    );
  }

  async createTest(clientId: string, type: TestType) {
    const user = await this.auth.getUser();
    let test;
    if (type === TestType.Sagital) {
      test = createEmptySagital();
    } else if (type === TestType.SagitalImg) {
      test = createEmptySagitalImgTest();
    } else if (type === TestType.SagitalFlechas) {
      test = createEmptySagitalFlechasTest();
    } else if (type === TestType.Rom) {
      test = createEmptyRomTest();
    } else if (type === TestType.Posture) {
      test = createEmptyPostureTest();
    } else if (type === TestType.Foot) {
      test = createEmptyFootTest();
    } else if (type === TestType.RomShort) {
      test = createEmptyRomShortTest();
    } else if (type === TestType.MyVaf) {
      test = createEmptyMyVafProtocolTest();
    } else {
      return null;
    }
    test.owner = user.uid;
    test.client = clientId;
    await sleep(500);
    const result = await this.fs.add(`${TESTS_PATH}`, test);
    return result;
  }

  async createMyVafTest(clientId: string, myVafProtocolDef: MyVafProtocolDef) {
    const user = await this.auth.getUser();
    const test = createEmptyMyVafProtocolTest();
    test.owner = user.uid;
    test.client = clientId;
    test.protocolDefId = myVafProtocolDef.id;
    await sleep(500);
    const result = await this.fs.add(`${TESTS_PATH}`, test);
    return result;
  }

  // async createSagitalTest(clientId: string) {
  //   const user = await this.auth.getUser();
  //   const test: SagitalTest = createEmptySagital();
  //   test.owner = user.uid;
  //   test.client = clientId;
  //   const result = await this.fs.add<SagitalTest>(`${TESTS_PATH}`, test);
  //   await sleep(500);
  //   return result;
  // }

  getTest$(testId: string) {
    return this.fs.doc$<Test>(`${TESTS_PATH}/${testId}`).pipe(
      map((test) => ({ ...test, id: testId })),
      tap((test) => this.sentry.logFirestoreRead('tests')),
    );
  }

  getTest(testId: string) {
    return this.fs.getDocById<Test>(`${TESTS_PATH}/${testId}`);
  }

  getTestByProtocolDefId$(protocolDefId: string, ownerId: string) {
    return this.fs
      .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
        ref.where('owner', '==', ownerId),
      )
      .pipe(
        map((tests) =>
          tests.filter((test) => test.protocolDefId === protocolDefId),
        ),
      );
  }

  async updateTest(test: Test) {
    await this.fs.update<Test>(`${TESTS_PATH}/${test.id}`, test);
  }

  getClientTests$(clientId: string) {
    return this.fs.colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
      ref.where('client', '==', clientId),
    );
  }

  async getOrCreateAvatar(clientId: string) {
    let avatar = await this.getAvatar(clientId);
    if (!avatar) {
      await this.createAvatar(clientId);
    }
    avatar = await this.getAvatar(clientId);
    return avatar;
  }

  async getAvatarForClient(clientId: string) {
    const avatars = await safeToPromise(
      this.fs
        .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', clientId)
            .where('type', '==', TestType.Avatar)
            .orderBy('createdAt', 'desc'),
        )
        .pipe(take(1)),
    );

    if (avatars.length === 0) {
      return null;
    } else {
      return avatars[0];
    }
  }

  async getAvatar(clientId: string) {
    const user = await this.auth.getUser();
    if (!user) {
      return null;
    }
    const avatars = await safeToPromise(
      this.fs
        .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', clientId)
            .where('owner', '==', user.uid)
            .where('type', 'in', ['avatar'])
            .orderBy('createdAt', 'desc'),
        )
        .pipe(take(1)),
    );

    if (avatars.length === 0) {
      return null;
    } else {
      return avatars[0];
    }
  }

  async createAvatar(clientId: string) {
    const user = await this.auth.getUser();
    if (!user) {
      return null;
    }
    const avatar: AvatarTest = {
      type: TestType.Avatar,
      owner: user.uid,
      client: clientId,
    };
    const result = await this.fs.add(`${TESTS_PATH}`, avatar);
    return result;
  }

  async findAvatarRelatedTests(clientId: string) {
    const user = await this.auth.getUser();
    const postureTests = await safeToPromise(
      this.fs
        .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', clientId)
            .where('owner', '==', user.uid)
            .where('type', 'in', ['posture'])
            .orderBy('createdAt', 'desc'),
        )
        .pipe(take(1)),
    );
    let posture = null;
    if (postureTests.length > 0) {
      posture = postureTests[0];
    }
    const sagitalTests = await safeToPromise(
      this.fs
        .colWithIds$<Test>(`${TESTS_PATH}`, (ref) =>
          ref
            .where('client', '==', clientId)
            .where('owner', '==', user.uid)
            .where('type', 'in', ['sagital'])
            .orderBy('createdAt', 'desc'),
        )
        .pipe(take(1)),
    );
    let sagital = null;
    if (sagitalTests.length > 0) {
      sagital = sagitalTests[0];
    }
    return { posture, sagital };
  }

  async deleteTests(testIds: Array<string>) {
    for (let index = 0; index < testIds.length; index++) {
      await this.fs.doc(`${TESTS_PATH}/${testIds[index]}`).delete();
      // this.sentry.logFirestoreWrite('CLIENTS', 1);
    }
  }

  getMyVafTestShareUrl(myVafTest: Test) {
    return `${window.location.origin}/copy-myvaf-test/${myVafTest.id}`;
  }

  async createMyVafTestFromSharedUrl(
    protocolSharedUrl: string,
    client: Client,
  ) {
    try {
      if (!protocolSharedUrl) {
        return this.snackbarService.error('Introduce una URL');
      }
      const currentUrl = new URL(window.location.href);
      const currentOrigin = currentUrl.origin;
      let sharedUrl;
      try {
        sharedUrl = new URL(protocolSharedUrl);
      } catch (error) {
        return this.snackbarService.error('La URL compartida no es válida');
      }

      const sharedUrlOrigin = sharedUrl.origin;
      if (currentOrigin !== sharedUrlOrigin) {
        return this.snackbarService.error(
          'El origen de la URL no coincide con el origen de esta página',
        );
      }

      const sharedUrlPath = sharedUrl.pathname;
      // use regex to validate the url and extract the client-training-session id
      const regex = /\/copy-myvaf-test\/([a-zA-Z0-9]+)/;
      const match = sharedUrlPath.match(regex);
      if (!match) {
        return this.snackbarService.error('La URL compartida no es válida');
      }
      const clientMyVAFTestId = match[1];
      if (!client) {
        return this.snackbarService.error('No hay un cliente seleccionado');
      }
      const response = await this.functionsService.call(
        'copyMyVAFTestCallable',
        {
          clientMyVAFTestId,
          destinationClientId: client.id,
        },
        { timeout: 1000 * 60 * 4 },
      );
      this.snackbarService.success('Se ha creado el protocolo correctamente');
      return response; // id of the new protocol
    } catch (error) {
      console.error(error);
      this.snackbarService.error(
        'Ha ocurrido un error al intentar crear el protocolo',
      );
    }
  }

  getLevelTextByNumber = getLevelTextByNumber;

  async createTrainingAvatarObject(clientId: string) {
    const user = await this.auth.getUser();
    if (!user) {
      return null;
    }
    const trainingAvatar: TrainingAvatar = {
      type: TestType.TrainingAvatar,
      owner: user.uid,
      client: clientId,
      date: new Date(),
    };
    return trainingAvatar;
  }

  async updateTrainingAvatar(trainingAvatar: TrainingAvatar) {
    if (!trainingAvatar.createdAt) {
      return this.fs.add(`${TRAINING_AVATAR_PATH}`, trainingAvatar);
    }
    return this.fs.update<TrainingAvatar>(
      `${TRAINING_AVATAR_PATH}/${trainingAvatar.id}`,
      trainingAvatar,
    );
  }

  async deleteTrainingAvatars(trainingAvatarIds: Array<string>) {
    for (let index = 0; index < trainingAvatarIds.length; index++) {
      await this.fs
        .doc(`${TRAINING_AVATAR_PATH}/${trainingAvatarIds[index]}`)
        .delete();
    }
  }
}
