import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-report-viewer',
  template: `
    <div class="report-container" [innerHTML]="safeHtml"></div>
    <div *ngIf="error" class="error">{{ error }}</div>
    <div *ngIf="loading" class="loading">Loading report...</div>
  `,
  styles: [
    `
      .report-container {
        padding: 20px;
      }
      .error {
        color: red;
        padding: 20px;
      }
      .loading {
        padding: 20px;
      }
    `,
  ],
})
export class ReportViewerComponent implements OnInit {
  safeHtml: SafeHtml;
  error: string;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    const testIds =
      this.route.snapshot.queryParams['testIds']?.split(',') || [];

    if (!testIds.length) {
      this.error = 'No test IDs provided';
      this.loading = false;
      return;
    }

    this.reportsService.getReportHtml(testIds).then(
      (html) => {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        this.loading = false;
      },
      (err) => {
        this.error = 'Failed to load report: ' + err.message;
        this.loading = false;
      },
    );
  }
}
