<div [class]="wrapperClass" class="relative">
  <button
    type="button"
    [ngClass]="[
      'inline-flex w-full items-center justify-between rounded-md border border-gray-300 bg-neutral-100 px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-intecc',
      buttonClass,
    ]"
    [class.cursor-default]="readonly"
    (click)="toggleDropdown()"
  >
    <span class="truncate">{{ getDisplayText() }}</span>
    <i
      class="fas fa-angle-down"
      [class.transform]="isOpen"
      [class.rotate-180]="isOpen"
    ></i>
  </button>

  <div
    class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg"
    [class.hidden]="!isOpen"
  >
    <div class="py-1">
      <div
        *ngFor="let option of options"
        class="flex cursor-pointer items-center px-4 py-2 text-gray-700 hover:bg-neutral-100"
        (click)="toggleOption(option.value)"
      >
        <div
          *ngIf="multiple"
          class="mr-2 flex h-5 w-5 items-center justify-center"
        >
          <div
            class="flex h-4 w-4 items-center justify-center rounded border border-gray-300 transition-colors duration-200"
            [class.bg-intecc]="isSelected(option.value)"
            [class.border-intecc]="isSelected(option.value)"
          >
            <i
              *ngIf="isSelected(option.value)"
              class="fas fa-check text-xs text-white"
            ></i>
          </div>
        </div>
        <span class="grow">{{ option.label }}</span>
        <i
          *ngIf="!multiple && isSelected(option.value)"
          class="fas fa-check ml-2 text-intecc"
        ></i>
      </div>
    </div>
  </div>
</div>
