import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TestWithImages } from '../../../shared/test.outside';
import {
  TestImageInfo,
  TestImages,
  TestImageValue,
} from '../../../shared/test-image.outside';
import { ModalService } from 'src/app/services/modal.service';
import { Client } from 'src/app/models/client.model';
import { ClientMediaModalData } from 'src/app/side-modals/client-media/client-media.component';
import { MediaEditService } from 'src/app/services/media/media-edit.service';
import { EditedMediaItem } from 'src/app/models/media/edited-media-item.model';
import { MediaItem } from '../../../../../Server/functions/src/shared/media-item.model';
import { MediaService } from 'src/app/services/media/media.service';
import { MyVafImageDef } from 'src/shared/myvaf.outside';

@Component({
  selector: 'app-test-image-view',
  templateUrl: './test-image-view.component.html',
  styleUrls: ['./test-image-view.component.scss'],
})
export class TestImageViewComponent {
  testImageInfo: TestImageInfo;
  editedMediaItem: EditedMediaItem;
  mediaItem: MediaItem;
  editLinkMode = false;

  imageDeleted = false;

  imageValuePrivate: TestImageValue;
  get imageValue(): TestImageValue {
    return this.imageValuePrivate;
  }
  set imageValue(v: TestImageValue) {
    this.imageValuePrivate = v;
    this.loadMediaItem();
  }

  imageKeyPrivate: string;
  get imageKey(): string {
    return this.imageKeyPrivate;
  }
  @Input() set imageKey(v: string) {
    this.imageKeyPrivate = v;
    this.loadTestImageInfo(v);
  }

  @Input() myVafImageDef: MyVafImageDef;
  @Input() isMyVaf = false;

  @Input() isUserClientView = false;

  // imageValuePrivate: TestImageValue;
  // get imageValue(): TestImageValue {
  //     return this.imageValuePrivate;
  // }
  // @Input() set imageValue(v: TestImageValue) {
  //     this.imageValuePrivate = v;
  // }

  testPrivate: TestWithImages;
  get test(): TestWithImages {
    return this.testPrivate;
  }
  @Input() set test(v: TestWithImages) {
    this.testPrivate = v;
    this.init();
  }

  @Input() client: Client;

  @Output() imageChangedEvent = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private mediaEditService: MediaEditService,
    private mediaService: MediaService,
  ) {}

  init() {
    if (this.imageKey && this.test.images) {
      this.imageValue = this.test.images[this.imageKey];
    } else {
      this.imageValue = null;
    }
  }

  async loadTestImageInfo(key: string) {
    console.log('key', key);
    if (key.startsWith('myvaf-')) {
      this.testImageInfo = {
        name: this.myVafImageDef.name,
      };
    } else {
      this.testImageInfo = TestImages[key];
    }
  }

  async loadMediaItem() {
    this.editedMediaItem = null;
    this.mediaItem = null;
    this.imageDeleted = false;
    if (!this.imageValue) {
      return;
    }
    try {
      if (this.imageValue.type === 'original') {
        const result = await this.mediaService.getMediaItem(this.imageValue.id);
        this.mediaItem = result;
      } else {
        const result = await this.mediaEditService.getEditedMediaItem(
          this.imageValue.id,
        );
        this.editedMediaItem = result;
      }
    } catch (error) {
      console.log('Image not found');
      this.imageDeleted = true;
    }
  }

  async openMediaSelector() {
    const data: ClientMediaModalData = {
      client: this.client,
      selectionMode: true,
      selectionName: this.testImageInfo.name,
      autoSelectValue: this.imageValue ? this.imageValue : null,
    };
    const result = await this.modalService.openLateralModal(
      'client-media',
      data,
    );
    const resultTestImageValue = result as TestImageValue;
    if (!result) {
      return;
    }

    this.asignValue(resultTestImageValue);
  }

  asignValue(value: TestImageValue) {
    if (!this.imageKey) {
      return;
    }
    if (!this.test.images) {
      this.test.images = {};
    }
    this.test.images[this.imageKey] = value;
    this.init();
    this.imageChangedEvent.next('');
    console.log('asignValue Result', this.test);
  }

  asignValueMyVaf(value: TestImageValue) {
    if (!this.imageKey) {
      return;
    }

    this.test[this.imageKey] = value;
    this.init();
    this.imageChangedEvent.next('');
  }

  unasign() {
    this.asignValue(null);
    this.imageChangedEvent.next('');
  }

  change() {
    if (this.isUserClientView) {
      return;
    }
    this.openMediaSelector();
  }

  editLink() {
    this.editLinkMode = true;
  }

  endEditLink() {
    this.editLinkMode = false;
    this.imageChangedEvent.next('');
  }

  open() {
    this.openMediaSelector();
  }

  openLink() {}
}
