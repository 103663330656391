<div
  class="grid h-full p-5 md:p-20"
  style="grid-template-columns: 1fr; grid-template-rows: auto minmax(0, 1fr)"
>
  <div>
    <div class="flex justify-between">
      <div>
        <div
          *ngIf="data.client"
          class="mb-2 flex cursor-pointer items-center transition-all duration-200 hover:-translate-x-2"
          (click)="close()"
        >
          <i class="fas fa-arrow-circle-left blue mr-5"></i>
          <span>{{ data.client.name + ' ' + data.client.surname }}</span>
        </div>
        <div
          *ngIf="!data.client"
          class="mb-2 flex cursor-pointer items-center transition-all duration-200 hover:-translate-x-2"
          (click)="close()"
        >
          <i class="fas fa-arrow-circle-left blue mr-5"></i>
          <span>Volver</span>
        </div>
        <div class="mb-5 flex items-center justify-between">
          <h1 class="text-xl md:text-3xl">Multimedia</h1>
          <a
            href="https://manual.intecc.org/es/article/editor-multimedia"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-2 cursor-pointer text-xl md:text-2xl"
          >
            <i class="fas fa-graduation-cap text-intecc2"></i>
          </a>
          <!-- <div (click)="openCapture()"
                        class="ml-4 text-neutral-300 hover:text-intecc transition-all duration-200 cursor-pointer">
                        <i class=" text-xl fas fa-camera-retro"></i>
                    </div> -->
        </div>
      </div>
      <div
        *ngIf="usedStorageLeftMB$ | async as usedStorageLeftMB"
        class="flex flex-col items-end"
      >
        <!-- <h1 class="text-md md:text-lg mb-4 text-right">Te quedan <b>{{usedStorageLeftMB | number : '1.2-2'}}
                        MB</b></h1> -->
        <!-- <h1 class="text-md md:text-lg mb-4 text-right">Te quedan <b>{{usedStorageLeftMB | number : '1.2-2'}}
                            MB</b></h1> -->
        <!-- <h1 class="text-md mb-4 text-right md:text-lg">
          Almacenamiento ilimitado
        </h1> -->
        <!-- <div class="w-32 md:w-80 h-3 rounded-full bg-neutral-200 overflow-hidden">
                    <div class="h-full bg-blue-600" style="width: {{usedStoragePercentage$ | async}}%;">

                    </div>
                </div> -->
      </div>
    </div>
    <!-- NOTE: hidden until how it works is more reliable (ios not working well, android pics super angular camera?) -->
    <!-- <div *ngIf="this.tabState !== 'capture'" class="mb-4 flex justify-end">
      <app-button
        class=""
        (clickEvent)="openCapture()"
        [text]="'Abrir cámara'"
        [icon]="'fas fa-camera-retro'"
        [type]="'primary'"
      ></app-button>
    </div> -->
  </div>

  <div
    *ngIf="tabState === 'items'"
    class="h-full"
    style="
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto minmax(0, 1fr);
    "
  >
    <div class="mb-2 flex w-full justify-between sm:justify-start">
      <app-tab-item
        [name]="'Originales'"
        [icon]="'fas fa-camera'"
        [selected]="selectedItemsTab === 'original'"
        (tapEvent)="handleTabChange('original')"
      ></app-tab-item>
      <app-tab-item
        *ngIf="!data.onlyOriginals"
        [name]="'Editadas'"
        [icon]="'fas fa-paint-brush'"
        [selected]="selectedItemsTab === 'edited'"
        (tapEvent)="handleTabChange('edited')"
      ></app-tab-item>
      <!-- <app-tab-item
        [name]="'Galería'"
        [icon]="'fas fa-dumbbell'"
        [selected]="selectedItemsTab === 'Galería INTECC'"
        (tapEvent)="handleTabChange('Galería INTECC')"
      ></app-tab-item> -->
    </div>
    <div>
      <div
        *ngIf="
          multiSelectList.length > 0 &&
          (selectedItemsTab !== 'Galería INTECC' ||
            (selectedItemsTab === 'Galería INTECC' && userIsAdmin))
        "
        class="flex items-center justify-between rounded-md bg-intecc2 p-2 px-4 text-white"
      >
        <div class="flex items-center">
          <span class="font-bold"
            >{{ multiSelectList.length }} seleccionada(s)</span
          >
        </div>
        <div class="flex flex-wrap gap-2">
          <div
            class="cursor-pointer rounded-md px-2 py-1 text-white hover:bg-blue-700"
            (click)="openMoveMediaDialog(null, $event)"
          >
            <i class="fas fa-tags"></i>
          </div>
          <div
            class="cursor-pointer rounded-md px-2 py-1 text-white hover:bg-blue-700"
            (click)="deleteSelectedFiles()"
          >
            <i class="far fa-trash-alt"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading; else isNotLoadingBlock">
      <div class="animated fadeIn flex h-full items-center justify-center">
        <img
          class="w-44"
          src="../../../assets/images/loading-bricks.gif"
          alt=""
        />
      </div>
    </div>
    <ng-template #isNotLoadingBlock>
      <!-- <div *ngIf="selectedItemsTab === 'Galería INTECC'" class="h-full">
        <div class="custom-scroll h-full overflow-y-scroll">
          <div class="w-full">
            <ng-container *ngIf="globalSections">
              <cdk-accordion class="example-accordion bg-white">
                <cdk-accordion-item
                  *ngFor="
                    let item of globalSections.sections;
                    let sectionIndex = index
                  "
                  #accordionItem="cdkAccordionItem"
                  class="example-accordion-item"
                  role="button"
                  tabindex="0"
                  [attr.id]="'accordion-header-' + sectionIndex"
                  [attr.aria-expanded]="accordionItem.expanded"
                  [attr.aria-controls]="'accordion-body-' + sectionIndex"
                >
                  <div
                    class="example-accordion-item-header"
                    (click)="accordionItem.toggle()"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div class="ml-3">
                        <p class="font-bold">{{ item.name }}</p>
                      </div>
                      <div class="flex" *ngIf="userIsAdmin">
                        <div
                          (click)="moveSectionUp(sectionIndex, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-angle-up"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div
                          (click)="moveSectionDown(sectionIndex, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-angle-down"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div
                          (click)="openRenameSection(item, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-pen-alt"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div (click)="deleteSection(sectionIndex)" class="p-2">
                          <i class="far fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + sectionIndex"
                    [attr.aria-labelledby]="'accordion-header-' + sectionIndex"
                  >
                    <div class="media-items-grid">
                      <div
                        *ngFor="let id of item.mediaIds; let imageIndex = index"
                        style="padding-bottom: 100%"
                        (click)="selectItemById(id, true)"
                        class="cursor-pointer bg-neutral-100 border-solid shadow-sm border-neutral-200 rounded-xl relative overflow-hidden
                                            {{
                          isMediaItemIdInMediaItems(id, globalMediaItems)
                            ? ''
                            : 'hidden'
                        }}"
                      >
                        <ng-container
                          *ngIf="
                            getSectionMediaItemById(id, globalMediaItems);
                            let sectionMediaItem
                          "
                        >
                          <img
                            loading="lazy"
                            *ngIf="sectionMediaItem.downloadURL"
                            class="absolute right-0 top-0 h-full w-full object-cover"
                            src="{{
                              accordionItem.expanded
                                ? sectionMediaItem.downloadURL
                                : ''
                            }}"
                            alt=""
                          />
                          <div
                            *ngIf="!sectionMediaItem.downloadURL"
                            class="absolute right-0 top-0 flex h-full w-full items-center justify-center"
                          >
                            <p>Cargando...</p>
                          </div>
                          <div
                            *ngIf="userIsAdmin"
                            class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <div
                              (click)="
                                openMoveMediaDialog(sectionMediaItem, $event)
                              "
                              class="flex h-8 w-8 cursor-pointer items-center justify-center"
                            >
                              <i class="fas fa-tags text-xs text-white"></i>
                            </div>
                            <div
                              (click)="
                                multiSelectItem(sectionMediaItem, $event)
                              "
                              class="flex h-8 w-8 cursor-pointer items-center justify-center"
                            >
                              <i
                                *ngIf="
                                  !isMediaItemMultiSelected(sectionMediaItem)
                                "
                                class="far fa-square text-xs text-white"
                              ></i>
                              <i
                                *ngIf="
                                  isMediaItemMultiSelected(sectionMediaItem)
                                "
                                class="fas fa-check-square text-xs text-white"
                              ></i>
                            </div>
                          </div>

                          <div
                            (click)="
                              moveProtocolUp(sectionIndex, imageIndex, $event)
                            "
                            *ngIf="userIsAdmin"
                            class="absolute bottom-0 left-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <i
                              class="fas fas fa-angle-left text-xs text-white"
                            ></i>
                          </div>
                          <div
                            (click)="
                              moveProtocolDown(sectionIndex, imageIndex, $event)
                            "
                            *ngIf="userIsAdmin"
                            class="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <i
                              class="fas fas fa-angle-right text-xs text-white"
                            ></i>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
              <div class="mb-4"></div>
              <div
                *ngIf="userIsAdmin"
                (click)="addSection()"
                class="relative cursor-pointer rounded-md border-2 border-dashed p-4 opacity-60 transition-all duration-200 hover:border-blue-700 hover:text-blue-700 hover:opacity-100"
              >
                <p class="text-center">Añadir sección</p>
              </div>

              <div class="mb-4"></div>
            </ng-container>
          </div>
          <div class="media-items-grid">
            <div
              *ngIf="userIsAdmin"
              class="border-light-blue-500 relative cursor-pointer rounded-xl border-2 border-dashed"
              style="padding-bottom: 100%"
              appDropzone
              (hovered)="toggleHover($event)"
              (dropped)="onDrop($event, true)"
              (click)="openFileDialog(true)"
              [class.isHoveringDropzone2]="isHoveringDropzone"
            >
              <div
                class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
              >
                <i class="fas fa-plus-circle mb-2 text-4xl md:mb-5"></i>
                <p class="text-center text-sm">Arrastra o haz click</p>
              </div>
            </div>
            <ng-container *ngIf="globalSections">
              <ng-container
                *ngIf="
                  getMediaWithoutSection(globalMediaItems);
                  let globalMediaItemsNoSection
                "
              >
                <div
                  *ngFor="let item of globalMediaItems; let i = index"
                  (click)="selectItem(i, true)"
                  class="cursor-pointer bg-neutral-100 border-solid shadow-sm border-neutral-200 rounded-xl relative overflow-hidden {{
                    isMediaWithoutSection(item, globalMediaItemsNoSection)
                      ? ''
                      : 'hidden'
                  }}"
                  style="padding-bottom: 100%"
                >
                  <img
                    loading="lazy"
                    *ngIf="item.downloadURL"
                    style=""
                    class="absolute right-0 top-0 h-full w-full object-cover"
                    src="{{ item.downloadURL }}"
                    alt=""
                  />
                  <div
                    *ngIf="!item.downloadURL"
                    class="absolute right-0 top-0 flex h-full w-full items-center justify-center"
                  >
                    <p>Cargando...</p>
                  </div>
                  <div
                    *ngIf="userIsAdmin"
                    class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                  >
                    <div
                      (click)="openMoveMediaDialog(item, $event)"
                      class="flex h-8 w-8 cursor-pointer items-center justify-center"
                    >
                      <i class="fas fa-tags text-xs text-white"></i>
                    </div>
                    <div
                      (click)="multiSelectItem(item, $event)"
                      class="flex h-8 w-8 cursor-pointer items-center justify-center"
                    >
                      <i
                        *ngIf="!isMediaItemMultiSelected(item)"
                        class="far fa-square text-xs text-white"
                      ></i>
                      <i
                        *ngIf="isMediaItemMultiSelected(item)"
                        class="fas fa-check-square text-xs text-white"
                      ></i>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div> -->
      <div *ngIf="selectedItemsTab === 'original'" class="h-full">
        <div
          *ngIf="!noMediaItemsYet"
          class="custom-scroll h-full overflow-y-scroll"
        >
          <div class="w-full">
            <ng-container *ngIf="sections">
              <cdk-accordion class="example-accordion bg-white">
                <cdk-accordion-item
                  *ngFor="
                    let item of sections.sections;
                    let sectionIndex = index
                  "
                  #accordionItem="cdkAccordionItem"
                  class="example-accordion-item"
                  role="button"
                  tabindex="0"
                  [attr.id]="'accordion-header-' + sectionIndex"
                  [attr.aria-expanded]="accordionItem.expanded"
                  [attr.aria-controls]="'accordion-body-' + sectionIndex"
                >
                  <div
                    class="example-accordion-item-header"
                    (click)="accordionItem.toggle()"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div class="ml-3">
                        <p class="font-bold">{{ item.name }}</p>
                      </div>
                      <div class="flex">
                        <div
                          (click)="moveSectionUp(sectionIndex, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-angle-up"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div
                          (click)="moveSectionDown(sectionIndex, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-angle-down"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div
                          (click)="openRenameSection(item, $event)"
                          class="p-2"
                        >
                          <i class="fas fa-pen-alt"></i>
                        </div>
                        <div class="mr-3"></div>
                        <div (click)="deleteSection(sectionIndex)" class="p-2">
                          <i class="far fa-trash-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + sectionIndex"
                    [attr.aria-labelledby]="'accordion-header-' + sectionIndex"
                  >
                    <div class="media-items-grid">
                      <div
                        *ngFor="let id of item.mediaIds; let imageIndex = index"
                        style="padding-bottom: 100%"
                        (click)="selectItemById(id)"
                        class="cursor-pointer bg-neutral-100 border-solid shadow-sm border-neutral-200 rounded-xl relative overflow-hidden
                                            {{
                          isMediaItemIdInMediaItems(id, currentClientMediaItems)
                            ? ''
                            : 'hidden'
                        }}"
                      >
                        <ng-container
                          *ngIf="
                            getSectionMediaItemById(
                              id,
                              currentClientMediaItems
                            );
                            let sectionMediaItem
                          "
                        >
                          <img
                            loading="lazy"
                            *ngIf="sectionMediaItem.downloadURL"
                            class="absolute right-0 top-0 h-full w-full object-cover"
                            src="{{
                              accordionItem.expanded
                                ? sectionMediaItem.downloadURL
                                : ''
                            }}"
                            alt=""
                          />
                          <div
                            *ngIf="!sectionMediaItem.downloadURL"
                            class="absolute right-0 top-0 flex h-full w-full items-center justify-center"
                          >
                            <p>Cargando...</p>
                          </div>
                          <div
                            class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <div
                              (click)="
                                openMoveMediaDialog(sectionMediaItem, $event)
                              "
                              class="flex h-8 w-8 cursor-pointer items-center justify-center"
                            >
                              <i class="fas fa-tags text-xs text-white"></i>
                            </div>
                            <div
                              (click)="
                                multiSelectItem(sectionMediaItem, $event)
                              "
                              class="flex h-8 w-8 cursor-pointer items-center justify-center"
                            >
                              <i
                                *ngIf="
                                  !isMediaItemMultiSelected(sectionMediaItem)
                                "
                                class="far fa-square text-xs text-white"
                              ></i>
                              <i
                                *ngIf="
                                  isMediaItemMultiSelected(sectionMediaItem)
                                "
                                class="fas fa-check-square text-xs text-white"
                              ></i>
                            </div>
                          </div>

                          <div
                            (click)="
                              moveProtocolUp(sectionIndex, imageIndex, $event)
                            "
                            class="absolute bottom-0 left-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <i
                              class="fas fas fa-angle-left text-xs text-white"
                            ></i>
                          </div>
                          <div
                            (click)="
                              moveProtocolDown(sectionIndex, imageIndex, $event)
                            "
                            class="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                          >
                            <i
                              class="fas fas fa-angle-right text-xs text-white"
                            ></i>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </cdk-accordion-item>
              </cdk-accordion>
              <div class="mb-4"></div>
              <div
                (click)="addSection()"
                class="relative cursor-pointer rounded-md border-2 border-dashed p-4 opacity-60 transition-all duration-200 hover:border-blue-700 hover:text-blue-700 hover:opacity-100"
              >
                <p class="text-center">Añadir sección</p>
              </div>

              <div class="mb-4"></div>
            </ng-container>
          </div>
          <div class="media-items-grid">
            <div
              class="border-light-blue-500 relative cursor-pointer rounded-xl border-2 border-dashed"
              style="padding-bottom: 100%"
              appDropzone
              (hovered)="toggleHover($event)"
              (dropped)="onDrop($event)"
              (click)="openFileDialog()"
              [class.isHoveringDropzone2]="isHoveringDropzone"
            >
              <div
                class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
              >
                <i class="fas fa-plus-circle mb-2 text-4xl md:mb-5"></i>
                <p class="text-center text-sm">Arrastra o haz click</p>
              </div>
            </div>
            <ng-container *ngIf="sections">
              <ng-container
                *ngIf="
                  getMediaWithoutSection(currentClientMediaItems);
                  let currentClientMediaItemsNoSection
                "
              >
                <div
                  *ngFor="let item of currentClientMediaItems; let i = index"
                  (click)="selectItem(i)"
                  class="cursor-pointer bg-neutral-100 border-solid shadow-sm border-neutral-200 rounded-xl relative overflow-hidden {{
                    isMediaWithoutSection(
                      item,
                      currentClientMediaItemsNoSection
                    )
                      ? ''
                      : 'hidden'
                  }}"
                  style="padding-bottom: 100%"
                >
                  <!-- <img *ngIf="item.downloadURL" class="absolute top-0 right-0 w-full h-full object-cover"
                                    src="{{item.downloadURL}}" alt=""> -->
                  <img
                    loading="lazy"
                    *ngIf="item.downloadURL"
                    style=""
                    class="absolute right-0 top-0 h-full w-full object-cover"
                    src="{{ item.downloadURL }}"
                    alt=""
                  />
                  <div
                    *ngIf="!item.downloadURL"
                    class="absolute right-0 top-0 flex h-full w-full items-center justify-center"
                  >
                    <p>Cargando...</p>
                  </div>
                  <div
                    class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                  >
                    <div
                      (click)="openMoveMediaDialog(item, $event)"
                      class="flex h-8 w-8 cursor-pointer items-center justify-center"
                    >
                      <i class="fas fa-tags text-xs text-white"></i>
                    </div>
                    <div
                      (click)="multiSelectItem(item, $event)"
                      class="flex h-8 w-8 cursor-pointer items-center justify-center"
                    >
                      <i
                        *ngIf="!isMediaItemMultiSelected(item)"
                        class="far fa-square text-xs text-white"
                      ></i>
                      <i
                        *ngIf="isMediaItemMultiSelected(item)"
                        class="fas fa-check-square text-xs text-white"
                      ></i>
                    </div>
                  </div>
                  <!-- <div (click)="openMoveMediaDialog(item, $event)"
                                    class="absolute left-0 top-0 h-8 w-8 flex items-center justify-center cursor-pointer bg-black/50 rounded-lg">
                                        <i class="text-xs fas fa-tags text-white"></i>
                                    </div>
                                    <div (click)="openMoveMediaDialog(item, $event)"
                                    class="absolute left-0 top-0 h-8 w-8 flex items-center justify-center cursor-pointer bg-black/50 rounded-lg">
                                        <i class="text-xs fas fa-tags text-white"></i>
                                    </div> -->
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="noMediaItemsYet"
          class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg bg-neutral-50 transition duration-200"
          appDropzone
          (hovered)="toggleHover($event)"
          (dropped)="onDrop($event)"
          (click)="openFileDialog()"
          [class.isHoveringDropzone]="isHoveringDropzone"
        >
          <i
            class="fas fa-file-upload mb-2 text-2xl text-neutral-300 md:mb-5 md:text-4xl"
          ></i>
          <p class="text-sm text-neutral-400 md:text-xl">
            Arrastra tus fotos y videos o haz click
          </p>
        </div>
      </div>
      <div *ngIf="selectedItemsTab === 'edited'" class="">
        <div
          *ngIf="!noEditedMediaItemsYet"
          class="custom-scroll h-full overflow-y-scroll"
        >
          <div class="media-items-grid">
            <div
              *ngFor="let item of currentClientEditedMediaItems; let i = index"
              (click)="selectEditedItem(i)"
              class="relative cursor-pointer overflow-hidden rounded-xl border-solid border-neutral-200 bg-neutral-100 shadow-sm"
              style="padding-bottom: 100%"
            >
              <app-edited-image-view
                class="absolute left-0 top-0 h-full w-full"
                [item]="item"
              >
              </app-edited-image-view>
              <div
                *ngIf="!item.downloadURL"
                class="absolute right-0 top-0 flex h-full w-full items-center justify-center"
              >
                <p>Cargando...</p>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="noEditedMediaItemsYet"
          class="flex h-full cursor-pointer flex-col items-center justify-center rounded-lg bg-neutral-800 text-violet-300 transition duration-200"
        >
          <i class="fas fa-draw-polygon mb-2 text-2xl md:mb-5 md:text-4xl"></i>
          <p class="text-center text-sm md:text-xl">
            Aquí aparecerán las fotos que edites,<br />¿Todavía no has editado
            ninguna?
          </p>
        </div>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="tabState === 'item' && selectedItemType === 'original'"
    class="selected-item-container"
  >
    <div class="relative rounded-lg bg-neutral-800 p-24">
      <div
        class="absolute right-5 top-5 grid auto-cols-auto grid-flow-col items-center gap-x-5"
      >
        <div
          *ngIf="selectedItemsTab !== 'Galería INTECC'"
          class="flex cursor-pointer items-center rounded bg-violet-500 px-3 py-1 font-bold text-white"
          (click)="editSelectedImage()"
        >
          <i class="fas fas fa-draw-polygon mr-2"></i>
          <p>Editar copia</p>
        </div>
        <div class="cursor-pointer text-white" (click)="closeSelectedItem()">
          <i class="far fa-times-circle text-2xl"></i>
        </div>
      </div>

      <div class="h-full w-full">
        <img
          src="{{ selectedItem.downloadURL }}"
          alt=""
          class="h-full w-full object-contain"
        />
      </div>
    </div>
    <div *ngIf="!data.selectionMode" class="flex justify-end">
      <app-button
        class="mr-5"
        [onlyIconMobile]="true"
        [text]="copyURLState === 'default' ? 'Copiar enlace' : '¡Copiado!'"
        [icon]="
          copyURLState === 'default' ? 'far fa-copy' : 'fas fa-check-circle'
        "
        (clickEvent)="copySelectedImageURL()"
      ></app-button>
      <app-button
        class="mr-5"
        [text]="'Descargar'"
        [icon]="'fas fa-file-download'"
        (clickEvent)="downLoadSelectedImage()"
      ></app-button>
      <app-button
        *ngIf="
          selectedItemsTab !== 'Galería INTECC' ||
          (selectedItemsTab === 'Galería INTECC' && userIsAdmin)
        "
        class="mr-0"
        [text]="'Eliminar'"
        [icon]="'far fa-trash-alt'"
        [type]="'danger'"
        (clickEvent)="deleteSelectedFile()"
      ></app-button>
    </div>
    <div *ngIf="data.selectionMode" class="flex justify-end">
      <app-button
        [text]="'Asignar a ' + this.data.selectionName"
        [icon]="'fas fa-arrow-right'"
        [type]="'primary'"
        (clickEvent)="returnSelectedImage()"
      ></app-button>
    </div>
  </div>
  <div
    *ngIf="tabState === 'item' && selectedItemType === 'edited'"
    class="selected-item-container"
  >
    <div class="relative rounded-lg bg-neutral-800 p-10 md:p-10">
      <div
        class="absolute right-5 top-5 grid auto-cols-auto grid-flow-col items-center gap-x-5"
      >
        <div
          class="flex cursor-pointer items-center rounded bg-violet-500 px-3 py-1 font-bold text-white"
          (click)="editSelectedImage()"
        >
          <i class="fas fas fa-draw-polygon mr-2"></i>
          <p>Editar</p>
        </div>
        <div class="cursor-pointer text-white" (click)="closeSelectedItem()">
          <i class="far fa-times-circle text-2xl"></i>
        </div>
      </div>
      <div class="h-full w-full">
        <app-edited-image-view
          #editedImageView
          [item]="selectedItem"
        ></app-edited-image-view>
        <!-- <img src="{{selectedItem.downloadURL}}" alt="" class=" h-full w-full object-contain"> -->
      </div>
    </div>
    <div *ngIf="!data.selectionMode" class="flex justify-end">
      <!-- <app-button class="mr-5" [text]="'Descargar'" [icon]="'fas fa-file-download'"
                (clickEvent)="downloadSelectedEditedImage()"></app-button> -->
      <app-two-state-button
        (buttonClick)="downloadSelectedEditedImage()"
        [loadingText]="'Procesando...'"
        [text]="'Descargar'"
        [faIconCode]="'fas fa-file-download'"
        [loading]="renderingEditedImage"
        class="mr-5"
      >
      </app-two-state-button>
      <app-button
        class="mr-0"
        [text]="'Eliminar'"
        [icon]="'far fa-trash-alt'"
        [type]="'danger'"
        (clickEvent)="deleteEditedMediaItem()"
      ></app-button>
    </div>
    <div *ngIf="data.selectionMode" class="flex justify-end">
      <app-button
        [text]="'Asignar a ' + this.data.selectionName"
        [icon]="'fas fa-arrow-right'"
        [type]="'primary'"
        (clickEvent)="returnSelectedImage()"
      ></app-button>
    </div>
  </div>
  <div *ngIf="tabState === 'capture'" class="capture-container">
    <div class="relative overflow-hidden rounded-2xl bg-neutral-800">
      <div class="relative h-full w-full bg-black">
        <video
          class="h-full w-full object-contain"
          [class.cm-hidden]="captureState === 'view'"
          #video
        >
          Video stream not available.
        </video>
        <div
          class="absolute left-0 top-0 h-full w-full"
          [class.cm-hidden]="captureState === 'view'"
        >
          <div class="capture-grid-vertical">
            <div class="capture-grid-vertical__bar"></div>
            <div class="capture-grid-vertical__bar"></div>
            <div class="capture-grid-vertical__bar"></div>
            <div class="capture-grid-vertical__bar"></div>
            <div class="capture-grid-vertical__bar"></div>
          </div>
          <div class="capture-grid-horizontal">
            <div class="capture-grid-horizontal__bar"></div>
            <div class="capture-grid-horizontal__bar"></div>
            <div class="capture-grid-horizontal__bar"></div>
            <div class="capture-grid-horizontal__bar"></div>
            <div class="capture-grid-horizontal__bar"></div>
          </div>
        </div>
        <div
          class="absolute left-0 top-0 flex h-full w-full items-center justify-center"
          [class.cm-hidden]="captureState === 'view'"
        >
          <div
            class="capture-circle"
            [class.capture-circle--good]="inclinationOK"
          >
            <div
              style="transform: rotate({{ currentAngle }}deg);"
              class="capture-circle__bar"
            ></div>
          </div>
        </div>
        <canvas style="display: none" #canvas> </canvas>
        <div
          class="absolute left-0 top-0 h-full w-full bg-black"
          [class.cm-hidden]="captureState !== 'view'"
        >
          <img
            class="h-full w-full object-contain"
            #photoview
            src="../../../assets/images/video_place_holder.png"
          />
        </div>
      </div>
      <div
        style="background-color: rgba(0, 0, 0, 0.5)"
        class="absolute right-5 top-5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full"
        (click)="closeCapture()"
      >
        <i class="far fa-times-circle text-2xl text-white"></i>
      </div>
    </div>
    <div class="flex justify-center">
      <div *ngIf="captureState === 'capture'" class="">
        <app-button
          (clickEvent)="takePicture()"
          class=""
          [text]="'Capturar'"
          [icon]="'fas fa-camera-retro'"
          [type]="'primary'"
        ></app-button>
      </div>
      <div *ngIf="captureState === 'view'" class="flex">
        <app-button
          (clickEvent)="savePicture()"
          class="mr-2"
          [text]="uploadingCapture ? 'Guardando...' : 'Guardar'"
          [icon]="'fas fa-save'"
          [type]="'primary'"
        >
        </app-button>
        <app-button
          (clickEvent)="discardPicture()"
          class=""
          [text]="'Descartar'"
          [icon]="'fas fa-trash-alt'"
          [type]="'damger'"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<!-- move media to user section dialog -->
<div
  class="h-full w-full absolute left-0 top-0 bg-black/70 flex flex-col justify-center {{
    moveProtocolDialogOpen ? '' : 'hidden'
  }}"
>
  <div class="w-full px-2">
    <div
      class="mx-auto max-h-screen max-w-md overflow-y-auto rounded-lg bg-white p-5 py-5"
    >
      <div class="mb-3 flex items-center justify-between">
        <p class="text-lg font-bold">Mover a...</p>
        <div class="cursor-pointer p-2" (click)="closeMoveToSection()">
          <i class="far fas fa-times"></i>
        </div>
      </div>
      <ng-container *ngIf="sections">
        <div
          *ngFor="let section of sections.sections"
          (click)="moveToSection(section)"
          class="mb-2 cursor-pointer rounded-lg bg-neutral-100 px-4 py-2"
        >
          <p class="">{{ section.name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- move media to global section dialog -->
<div
  class="h-full w-full absolute left-0 top-0 bg-black/70 flex flex-col justify-center {{
    moveGlobalDialogOpen ? '' : 'hidden'
  }}"
>
  <div class="w-full px-2">
    <div
      class="mx-auto max-h-screen max-w-md overflow-y-auto rounded-lg bg-white p-5 py-5"
    >
      <div class="mb-3 flex items-center justify-between">
        <p class="text-lg font-bold">Mover a...</p>
        <div class="cursor-pointer p-2" (click)="closeGlobalMoveToSection()">
          <i class="far fas fa-times"></i>
        </div>
      </div>
      <ng-container *ngIf="sections">
        <div
          *ngFor="let section of globalSections.sections"
          (click)="moveToSection(section)"
          class="mb-2 cursor-pointer rounded-lg bg-neutral-100 px-4 py-2"
        >
          <p class="">{{ section.name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div
  class="h-full w-full absolute left-0 top-0 bg-black/70 flex flex-col justify-center {{
    editSectionNameOpen ? '' : 'hidden'
  }}"
>
  <div class="w-full px-2">
    <div class="mx-auto max-w-md rounded-lg bg-white p-5 py-5">
      <p class="mb-3 text-lg font-bold">Renombrar sección</p>
      <input
        class="block w-full bg-neutral-100 px-5 py-2"
        type="text"
        name=""
        id=""
        [(ngModel)]="editSectionNameText"
      />
      <div class="mb-5"></div>
      <div class="flex justify-center">
        <app-button
          [type]="'primary'"
          class=""
          (clickEvent)="endRenameSection()"
          [text]="'Renombrar'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>

<input
  id="client-media-file-input"
  type="file"
  style="display: none"
  (change)="handleOpenFile($event)"
  multiple
/>

<input
  id="global-media-file-input"
  type="file"
  style="display: none"
  (change)="handleOpenFile($event, true)"
  multiple
/>
