<div class="content">
  <div class="mb-4 items-center justify-between sm:flex">
    <div class="mb-2 flex items-center justify-between sm:mb-0 sm:mr-4">
      <app-back-button classes="mr-4" />
      <h2 class="text-3xl">Mis datos</h2>
      <a
        href="https://manual.intecc.org/es/article/datos-del-profesional"
        target="_blank"
        rel="noopener noreferrer"
        class="hidden cursor-pointer text-xl sm:ml-2 sm:block"
      >
        <i class="fas fa-graduation-cap text-intecc2"></i>
      </a>
      <app-back-button
        classes="pointer-events-none text-transparent sm:hidden"
      />
    </div>
    <!-- <h2 class="mb-3 text-3xl md:mb-0">
      <app-back-button classes="mr-4" />
    </h2> -->
    <div class="mb-4 text-center sm:hidden">
      <a
        href="https://manual.intecc.org/es/article/datos-del-profesional"
        target="_blank"
        rel="noopener noreferrer"
        class="cursor-pointer text-xl"
      >
        <i class="fas fa-graduation-cap text-intecc2"></i>
      </a>
    </div>
    <button
      (click)="onSubmit()"
      class="button-cta-2 mb-4 sm:mb-0 block mx-auto sm:mx-0 {{
        !userConfig || !editConfigForm.valid || submiting || uploadingLogo
          ? 'opacity-50'
          : ''
      }}"
      [disabled]="
        !userConfig || !editConfigForm.valid || submiting || uploadingLogo
      "
    >
      {{ submiting ? 'Guardando...' : 'Guardar' }}
    </button>
  </div>
  <p class="mb-4 text-neutral-600">
    Los campos que tienen una casilla se pueden activar o desactivar para que se
    muestren en la app de cliente.
  </p>

  <form
    *ngIf="userConfig"
    autocomplete="off"
    class="pb-32"
    [formGroup]="editConfigForm"
    (ngSubmit)="onSubmit()"
  >
    <div>
      <div class="mb-2">
        <h2 class="mb-4 mt-12 text-3xl">Datos de informes</h2>
        <div
          *ngIf="!canCustomizeReports"
          class="my-4 rounded-md bg-primary-9 px-6 py-4"
        >
          <div class="mb-4 text-center">
            Puedes modificar y guardar estos datos, pero no se mostrarán en los
            informes. Para activar esta funcionalidad pulsa el botón "Mejorar".
          </div>
          <div>
            <button
              (click)="openUpgradeModal()"
              class="valorations__not-subscribed-banner__action cool-button-with-icon mx-auto"
            >
              <span class="cool-button-with-icon__icon"
                ><i class="fas fa-rocket"></i
              ></span>
              <p class="cool-button-with-icon__text">Mejorar</p>
            </button>
          </div>
        </div>
        <div class="modal__logo-form">
          <div
            class="my-4 flex flex-col items-center justify-center rounded-md border border-border bg-white p-8"
          >
            <div class="h-56 w-56 border border-gray-200 p-4">
              <img
                class="h-full w-full object-contain"
                [src]="
                  uploadedLogoUrl
                    ? uploadedLogoUrl
                    : '../../../assets/images/intecc-with-bg-rounded.png'
                "
                alt=""
              />
            </div>
            <div>
              <label
                for="logo-upload"
                class="items-cente button-plain-2 flex min-w-0"
              >
                <span><i class="fas fa-pen-square mr-2"></i> </span>
                <p class="">
                  {{ uploadingLogo ? 'Subiendo...' : 'Cambiar logo' }}
                </p>
              </label>

              <input
                (change)="uploadPhoto($event.target.files)"
                class="hidden"
                type="file"
                id="logo-upload"
                name="logo-upload"
                accept="image/png, image/jpeg"
              />
              <!-- <input (change)="uploadPhoto($event.target.files)" class="modal__logo-form__button__input" id="logo-upload" name="logo-upload" accept="image/png, image/jpeg"> -->
              <p *ngIf="fileSizeError" class="modal__logo-form__button__error">
                El tamaño máximo de archivo es de 200kb. Intenta reducir el
                tamaño de la imagen.
              </p>
              <div
                *ngIf="uploadingLogo"
                class="modal__logo-form__button__uploading"
              >
                <div
                  class="spinner modal__logo-form__button__uploading__spinner"
                ></div>
              </div>
            </div>
            <div *ngIf="uploadedLogoUrl" class="button-plain-2">
              <span><i class="fas fa-trash mr-2"></i> </span>
              <button (click)="removeLogo()">Quitar logo</button>
            </div>
          </div>
        </div>
        <div class="form__field">
          <div class="flex items-center space-x-2">
            <app-checkbox
              formControlName="displayLine1"
              [inputId]="'displayLine1'"
            />
            <label
              class="text-sm font-semibold uppercase text-primary-1"
              for="line1"
              >Linea 1</label
            >
          </div>
          <input
            class="form__input"
            type="text"
            id="line1"
            formControlName="line1"
          />
        </div>
        <div class="form__field">
          <div class="flex items-center space-x-2">
            <app-checkbox
              formControlName="displayLine2"
              [inputId]="'displayLine2'"
            />
            <label
              class="text-sm font-semibold uppercase text-primary-1"
              for="line2"
              >Linea 2</label
            >
          </div>
          <input
            class="form__input"
            type="text"
            id="line2"
            formControlName="line2"
          />
        </div>
        <div class="form__field">
          <div class="flex items-center space-x-2">
            <app-checkbox
              formControlName="displayLine3"
              [inputId]="'displayLine3'"
            />
            <label
              class="text-sm font-semibold uppercase text-primary-1"
              for="line3"
              >Linea 3</label
            >
          </div>
          <input
            class="form__input"
            type="text"
            id="line3"
            formControlName="line3"
          />
        </div>
        <h2 class="mb-4 mt-12 text-3xl">Enlaces destacados</h2>
        <p class="mb-4 text-neutral-600">
          Los enlaces que elijas de esta sección se mostrarán en la página
          principal de la app de cliente. (máximo 5)
        </p>
        <div class="grid gap-2 sm:grid-cols-2">
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayWeb"
                [inputId]="'displayWeb'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Web</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="web"
                formControlName="web"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayLocation"
                [inputId]="'displayLocation'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Ubicación</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="location"
                formControlName="location"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayConnectEmail"
                [inputId]="'displayConnectEmail'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Email</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="connectEmail"
                formControlName="connectEmail"
                placeholder="youremail@email.com."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayConnectPhone"
                [inputId]="'displayConnectPhone'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Teléfono</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="connectPhone"
                formControlName="connectPhone"
                placeholder="tel:..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayWhatsapp"
                [inputId]="'displayWhatsapp'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Whatsapp</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="whatsapp"
                formControlName="whatsapp"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayAppointmentsCalendar"
                [inputId]="'displayAppointmentsCalendar'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Calendario / citas</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="appointmentsCalendar"
                formControlName="appointmentsCalendar"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayNews"
                [inputId]="'displayNews'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Noticias</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="news"
                formControlName="news"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayPaymentsLink"
                [inputId]="'displayPaymentsLink'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Enlace de pagos</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="paymentsLink"
                formControlName="paymentsLink"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayInstagram"
                [inputId]="'displayInstagram'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Instagram Url</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="instagram"
                formControlName="instagram"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayFacebook"
                [inputId]="'displayFacebook'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Facebook Url</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="facebook"
                formControlName="facebook"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayTwitter"
                [inputId]="'displayTwitter'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Twitter</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="twitter"
                formControlName="twitter"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayTiktok"
                [inputId]="'displayTiktok'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Tiktok</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="tiktok"
                formControlName="tiktok"
                placeholder="https://..."
              />
            </div>
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <app-checkbox
                formControlName="displayOther"
                [inputId]="'displayOther'"
              />
              <p class="font-bold text-neutral-600">
                <span class="mr-1">Otro</span>
              </p>
            </div>
            <div class="form__field">
              <input
                class="form__input"
                id="other"
                formControlName="other"
                placeholder="https://..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mb-4 mt-12 text-3xl">Enlaces profesionales</h2>
    <div class="mb-2">
      <p class="font-bold text-neutral-600">
        <span class="mr-1">Enlace a mi anamnesis</span>
        <!-- <a class="text-blue-700 underline" target="blank" href="https://docs.google.com/forms/d/1LVTl9z1ADAOVfAMf8DzRAbtjLCK92-8TtiEKemq1PcM/copy">(Original)</a> -->
      </p>
      <p class="mb-2 text-sm text-neutral-500">
        👉 Aquí tienes un ejemplo
        <a
          class="text-blue-700 underline"
          target="blank"
          href="https://docs.google.com/forms/d/1LVTl9z1ADAOVfAMf8DzRAbtjLCK92-8TtiEKemq1PcM/copy"
          >(Copiar)</a
        >
      </p>
      <div class="form__field">
        <input
          class="form__input"
          type="text"
          id="customAnamnesisLink"
          formControlName="customAnamnesisLink"
          placeholder="https://..."
        />
      </div>
    </div>
    <div class="mb-2">
      <p class="mb-1 font-bold text-neutral-600">
        <span class="mr-1">Enlace a mis clientes</span>
      </p>
      <div class="form__field">
        <input
          class="form__input"
          type="text"
          id="customClientsLink"
          formControlName="customClientsLink"
          placeholder="https://..."
        />
      </div>
    </div>
    <div class="mb-2">
      <div formArrayName="customLinks">
        <p class="mb-1 font-bold text-neutral-600">
          <span class="mr-1">Enlaces personalizados</span>
        </p>
        <div
          *ngFor="let link of customLinks.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="mb-3 flex w-full min-w-0 items-center gap-3">
            <div class="grow border-r-2 border-gray-200 pr-3">
              <div class="mb-2 flex flex-col">
                <input
                  class="form__input"
                  type="text"
                  id="customLinkName-{{ i }}"
                  formControlName="name"
                  placeholder="Nombre del enlace"
                />
              </div>
              <div class="flex flex-col">
                <input
                  class="form__input"
                  type="text"
                  id="customLinkUrl-{{ i }}"
                  formControlName="url"
                  placeholder="https://..."
                />
              </div>
            </div>
            <button (click)="removeLink(i)">
              <span><i class="fas fa-trash mr-2"></i> </span>
            </button>
          </div>
        </div>
        <app-button
          (clickEvent)="addLink()"
          [disabled]="customLinks.length >= 10"
          text="Añadir"
          [icon]="'fas fa-plus'"
        >
        </app-button>
      </div>
      <h2 class="mb-4 mt-12 text-3xl">Datos profesionales</h2>
      <div class="flex items-center space-x-2">
        <app-checkbox
          formControlName="displayEducation"
          [inputId]="'displayEducation'"
        />
        <p class="font-bold text-neutral-600">
          <span class="mr-1">Información o formación</span>
        </p>
      </div>
      <div class="form__field">
        <textarea
          class="form__input min-h-24"
          id="education"
          formControlName="education"
          placeholder="Formación..."
        ></textarea>
      </div>

      <div class="flex items-center space-x-2">
        <app-checkbox
          formControlName="displayExperience"
          [inputId]="'displayExperience'"
        />
        <p class="font-bold text-neutral-600">
          <span class="mr-1">Servicios o experiencia</span>
        </p>
      </div>
      <div class="form__field">
        <textarea
          class="form__input min-h-24"
          id="experience"
          formControlName="experience"
          placeholder="Experiencia..."
        ></textarea>
      </div>

      <div class="flex items-center space-x-2">
        <app-checkbox
          formControlName="displayOthers"
          [inputId]="'displayOthers'"
        />
        <p class="font-bold text-neutral-600">
          <span class="mr-1">Otros</span>
        </p>
      </div>
      <div class="form__field">
        <textarea
          class="form__input min-h-24"
          id="others"
          formControlName="others"
          placeholder="Otros..."
        ></textarea>
      </div>
      <div class="mb-2">
        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayCompanyName"
            [inputId]="'displayCompanyName'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">Nombre de empresa</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="text"
            id="companyName"
            formControlName="companyName"
            placeholder="Nombre de empresa..."
          />
        </div>
        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayDniCif"
            [inputId]="'displayDniCif'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">DNI/CIF</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="text"
            id="dniCif"
            formControlName="dniCif"
            placeholder="DNI/CIF..."
          />
        </div>
        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayName"
            [inputId]="'displayName'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">Nombre y apellidos</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="text"
            id="name"
            formControlName="name"
            placeholder="Nombre y apellidos..."
          />
        </div>

        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayAddress"
            [inputId]="'displayAddress'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">Dirección, localidad, CP</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="text"
            id="address"
            formControlName="address"
            placeholder="Dirección, localidad, CP..."
          />
        </div>

        <!-- IMPORTANT: this was hidden before hiding the rest of fields, so keep it hidden when I show the others
          <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayAccountNumber"
            [inputId]="'displayAccountNumber'"
          />
          <p class="font-bold text-neutral-600">
          <span class="mr-1">Número de cuenta (IBAN)</span>
        </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="text"
            id="accountNumber"
            formControlName="accountNumber"
            placeholder="Número de cuenta (IBAN)..."
          />
        </div> -->

        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayPhone"
            [inputId]="'displayPhone'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">Teléfono</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="tel"
            id="phone"
            formControlName="phone"
            placeholder="Teléfono..."
          />
        </div>

        <div class="flex items-center space-x-2">
          <app-checkbox
            formControlName="displayEmail"
            [inputId]="'displayEmail'"
          />
          <p class="font-bold text-neutral-600">
            <span class="mr-1">Email</span>
          </p>
        </div>
        <div class="form__field">
          <input
            class="form__input"
            type="email"
            id="email"
            formControlName="email"
            placeholder="Email..."
          />
        </div>
      </div>
    </div>
    <div class="form__actions">
      <button
        (click)="onSubmit()"
        class="button-cta-2"
        class="button-cta-2 {{
          !userConfig || !editConfigForm.valid || submiting || uploadingLogo
            ? 'opacity-50'
            : ''
        }}"
        [disabled]="
          !userConfig || !editConfigForm.valid || submiting || uploadingLogo
        "
      >
        {{ submiting ? 'Guardando...' : 'Guardar' }}
      </button>
    </div>
  </form>
</div>
