import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Test } from '../../../shared/test.outside';
import { InclinometerService } from 'src/app/services/inclinometer.service';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { LearnService } from 'src/app/services/learn.service';
import { LearnGifs } from 'src/app/static/learn-gifs.static';
import { isIOS } from 'src/app/utils/utils';

@Component({
    selector: 'app-inclinometer',
    templateUrl: './inclinometer.component.html',
    styleUrls: ['./inclinometer.component.scss']
})
export class InclinometerComponent implements OnInit {

    showGif = false;

    LearnGifs = LearnGifs;

    _open = false;
    @Input() set open(value: boolean) {
        this._open = value;
        if (value) {
            this.onOpen();
        }
    }
    @Input() infoText: string;

    @Input() learnSectionId: string;

    @Input() learnGifId: string;

    @Input() showNext: boolean;

    @Input() minimalMode = false;

    _test: Test;
    @Input() set test(value: Test) {
        this._test = value;
        this.loadResult();
    }

    _testPropertyPath: string[];
    @Input() set testPropertyPath(value: string[]) {
        this._testPropertyPath = value;
        this.loadResult();
    }

    @Output() onclose: EventEmitter<void> = new EventEmitter<void>();
    @Output() goNextEvent: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('resultTextInput', { static: true }) resultTextInput: ElementRef;

    inclinometerActive = false;

    result: number;

    isDeviceMotionWorking = false;

    showIOSWarning = false;

    @HostListener('window:devicemotion', ['$event'])
    onDeviceMotion(event: any) {
        this.isDeviceMotionWorking = true;
        this.inclinometer.onNewDeviceMotionEventData(event);
        if (this.inclinometerActive) {
            this.result = this.inclinometer.getRelativeInclinationWithOffset();
        }
    }

    constructor(private inclinometer: InclinometerService,
                private overrideBackButton: OverrideBackButtonService, private learnService: LearnService) { }

    ngOnInit() {
        setTimeout(() => this.checkIOS(), 1000);
    }

    checkIOS() {
        // alert(`isIos: ${!!isIOS()}`);
        // alert(`requestPermissionAvailable: ${(typeof (DeviceMotionEvent as any).requestPermission === 'function')}`);
        // alert(`isDeviceMotionWorking: ${this.isDeviceMotionWorking}`);
        // if (typeof (DeviceMotionEvent as any).requestPermission === 'function' && isIOS() && this.isDeviceMotionWorking) {
        //     alert('Problemas inclinómetro');
        // }

        const pIsIOS = !!isIOS();
        const requestPermissionAvailable = (typeof (DeviceMotionEvent as any).requestPermission === 'function');
        const isDeviceMotionWorking = this.isDeviceMotionWorking;

        if ( pIsIOS && requestPermissionAvailable && !isDeviceMotionWorking ) {
            this.showIOSWarning = true;
        }
    }

    requestPermissionIOS() {
        console.log('Inclinometer permission requested');
        if (typeof (DeviceMotionEvent as any).requestPermission === 'function') {
            (DeviceMotionEvent as any).requestPermission()
              .then(permissionState => {
                if (permissionState === 'granted') {
                    this.showIOSWarning = false;
                }
              })
              .catch(console.error);
          } else {
            // handle regular non iOS 13+ devices
          }
    }

    onOpen() {
        this.resultTextInput.nativeElement.focus();
        this.overrideBackButton.override(() => this.onclose.next());
    }

    saveValue() {
        if(this.minimalMode){
            return;
        }
        this._test[this._testPropertyPath[0]][this._testPropertyPath[1]] = this.result;
    }

    saveAndClose() {
        this.saveValue();
        history.back();
    }

    saveAndNext() {
        this.saveValue();
        this.goNextEvent.next();
    }

    loadResult() {
        if (!!this._test && !!this._testPropertyPath && this._testPropertyPath.length === 2) {
            this.result = this._test[this._testPropertyPath[0]][this._testPropertyPath[1]];
        }
    }

    cancel() {
        history.back();
    }

    startInclinometer() {
        this.inclinometerActive = true;
        this.inclinometer.setZero();
    }

    stopInclinometer() {
        this.inclinometerActive = false;
    }

    openLearn() {
        if (!!this.learnSectionId) {
            this.learnService.openPanel(this.learnSectionId);
        }
    }

    openLearnGif() {
        this.showGif = true;
        this.overrideBackButton.override(() => {
            this.showGif = false;
        });
    }

    closeLearnGif() {
        history.back();
    }

}
