import { Component, OnInit, Input } from '@angular/core';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { SagitalImgResults, SagitalImgTest, SagitalImgTitles } from 'src/app/models/sagital-img-test.model';
import { sleep } from 'src/app/utils/utils';

@Component({
  selector: 'app-sagital-image-valoration-selector',
  templateUrl: './sagital-image-valoration-selector.component.html',
  styleUrls: ['./sagital-image-valoration-selector.component.scss']
})
export class SagitalImageValorationSelectorComponent {

  isOpen = false;

  test: SagitalImgTest;

  propertyPath: string;

  onSave: (result: string) => void;

  results;

  titles = SagitalImgTitles;

  index: number = 0;

  constructor(private overrideBackButton: OverrideBackButtonService) { }

  open(test: SagitalImgTest, propertyPath: string, onSave: (result: string) => void){
    this.isOpen = true;
    this.test = test;
    this.propertyPath = propertyPath;
    this.results = SagitalImgResults[propertyPath];
    this.onSave = onSave;
    this.loadCurrentResult();
    this.overrideBackButton.override(() => {
      this.isOpen = false;
    });
  }

  loadCurrentResult() {
    this.index = this.getResultIndex(this.test[this.propertyPath]);
    if(this.index<0) {
      this.index = 0;
    }
  }

  getResult(index: number) {
    return this.results[Object.keys(this.results)[index]];
  }

  getResultIndex(resutl: string) {
    const keys = Object.keys(this.results);
    return keys.findIndex(key => key === resutl);
  }

  saveAndClose() {
    this.onSave(Object.keys(this.results)[this.index]);
    history.back();
  }

  close() {
    history.back();
  }

  async next() {
    this.imgVisible = false;
    await sleep(200);
    if(this.index >= Object.keys(this.results).length -1){
      this.index = 0;
    } else {
      this.index++;
    }
    await sleep(10);
    this.imgVisible = true;
  }

  async prev() {
    if(this.index <= 0){
      this.index = Object.keys(this.results).length - 1;
    } else {
      this.index--;
    }
  }

  imgVisible = true;

}
