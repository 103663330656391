import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-client-back-button',
  templateUrl: './client-back-button.component.html',
  styleUrls: ['./client-back-button.component.scss']
})
export class ClientBackButtonComponent {

  @Input() client: Client;
  @Input() myVafMode = false;
  @Output() appOnClick = new EventEmitter();

  constructor(public clients: ClientsService, public navigationService: NavigationService) { }

}
