import { Injectable } from '@angular/core';
import { Text } from '@svgdotjs/svg.js';
import { combineLatest, Observable, of } from 'rxjs';
import {
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { MediaEditModalData } from 'src/app/bottom-modals/media-edit/media-edit.component';
import { AngleItem } from 'src/app/models/media/angle-item.class';
import { Arrow } from 'src/app/models/media/arrow.class';
import { CropEdit } from 'src/app/models/media/crop-item.class';
import { CrossItem } from 'src/app/models/media/cross-item.class';
import { EditItem, EditItemBD } from 'src/app/models/media/edit-items.model';
import {
  EditedMediaItem,
  EDITED_MEDIA_ITEM_PATH,
} from 'src/app/models/media/edited-media-item.model';
import { MeasureRef } from 'src/app/models/media/measure-ref.class';
import { Measure } from 'src/app/models/media/measure.class';
import { RectEmpty } from 'src/app/models/media/rect-empty.class';
import { Rect } from 'src/app/models/media/rect.class';
import { Text as myText } from 'src/app/models/media/text.class';
import { MediaItem } from '../../../shared/media-item.outside';
import { AuthService } from '../auth.service';
import { ClientsService } from '../clients.service';
import { FirestoreService } from '../firestore.service';
import { ModalService } from '../modal.service';
import { SentryService } from '../sentry.service';
import { safeToPromise } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class MediaEditService {
  currentClientEditedMediaItems$: Observable<Array<EditedMediaItem>>;

  constructor(
    private firestore: FirestoreService,
    private modalService: ModalService,
    private clients: ClientsService,
    private sentry: SentryService,
    private auth: AuthService,
  ) {
    this.currentClientEditedMediaItems$ = combineLatest([
      this.auth.user$,
      this.clients.currentClientId$,
    ]).pipe(
      map((value: [any, any]) => {
        return {
          user: value[0],
          clientId: value[1],
        };
      }),
      switchMap((value: any) => {
        if (!value.user) {
          return of([]);
        }
        // console.log('owner', value.user.uid);
        // console.log('client', value.clientId);
        // console.log('currentClientEditedMediaItems');

        return this.firestore
          .colWithIds$(`${EDITED_MEDIA_ITEM_PATH}`, (ref) =>
            ref
              .where('owner', '==', value.user.uid)
              .orderBy('updatedAt', 'desc'),
          )
          .pipe(
            map((items: Array<MediaItem>) => {
              if (!value.clientId) {
                return items;
              } else {
                const filtered = items.filter(
                  (item) => !item.client || item.client === value.clientId,
                );
                return filtered;
              }
            }),
          );

        return this.firestore.colWithIds$(`${EDITED_MEDIA_ITEM_PATH}`, (ref) =>
          ref
            .where('owner', '==', value.user.uid)
            .where('client', '==', value.clientId)
            .orderBy('updatedAt', 'desc'),
        );
        // return this.firestore.colWithIds$(`${EDITED_MEDIA_ITEM_PATH}`, ref => ref.where('owner', '==', value.user.uid));
        // return this.firestore.colWithIds$(`${'media'}`, ref => ref.where('owner', '==', value.user.uid).where('client', '==', value.clientId));
      }),
      map((data: Array<EditedMediaItem>) => {
        // const filteredData = data.filter(item => !item.deleted);
        // return filteredData;
        // console.log('media-edit-data', data);
        return data;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('media-edit', data.length);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {}),
    );
  }

  createEditedMediaItem(mediaItem: MediaItem) {
    const editedMediaItem: EditedMediaItem = {};
    editedMediaItem.originalId = mediaItem.id;
    editedMediaItem.owner = mediaItem.owner;
    editedMediaItem.client = mediaItem.client;
    editedMediaItem.downloadURL = mediaItem.downloadURL;
    if (mediaItem.downloadURLMap !== undefined) {
      editedMediaItem.downloadURLMap = mediaItem.downloadURLMap;
    }
    return editedMediaItem;
  }

  async addEditedMediaItem(editedMediaItem: EditedMediaItem) {
    // const result = await this.firestore.col(`${EDITED_MEDIA_ITEM_PATH}`).add(editedMediaItem);
    const result = await this.firestore.add(
      `${EDITED_MEDIA_ITEM_PATH}`,
      editedMediaItem,
    );
    editedMediaItem.id = result.id;
    return editedMediaItem;
  }

  async saveEditedMediaItem(editedMediaItem: EditedMediaItem) {
    console.log('presave');
    console.log(editedMediaItem);
    const result = await this.firestore.update(
      `${EDITED_MEDIA_ITEM_PATH}/${editedMediaItem.id}`,
      editedMediaItem,
    );
    return editedMediaItem;
  }

  async getEditedMediaItem(editedMediaItemId: string) {
    const result = await safeToPromise(
      this.firestore
        .docWithId$<EditedMediaItem>(
          `${EDITED_MEDIA_ITEM_PATH}/${editedMediaItemId}`,
        )
        .pipe(take(1)),
    );
    return result;
  }

  async openEditorFromOriginal(mediaItem: MediaItem) {
    console.log(mediaItem);

    let editedMediaItem = this.createEditedMediaItem(mediaItem);
    editedMediaItem = await this.addEditedMediaItem(editedMediaItem);
    const modalData: MediaEditModalData = {};
    modalData.editedMediaItemId = editedMediaItem.id;
    await this.modalService.openBottomModal('media-edit', modalData);
    return editedMediaItem;
  }

  async openEditor(editedMediaItem: EditedMediaItem) {
    const modalData: MediaEditModalData = {};
    modalData.editedMediaItemId = editedMediaItem.id;
    const result = await this.modalService.openBottomModal(
      'media-edit',
      modalData,
    );
    return result as EditedMediaItem;
  }

  async deleteEditedMediaItem(editedMediaItem: EditedMediaItem) {
    console.log('deleted edited media item');
    await this.firestore.delete(
      `${EDITED_MEDIA_ITEM_PATH}/${editedMediaItem.id}`,
    );
    console.log('deleted edited media item end');
  }

  buildEditItemFromDB(itemBD: EditItemBD) {
    let item: EditItem;
    if (itemBD.type === 'cross') {
      item = new CrossItem(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'crop') {
      item = new CropEdit(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'angle') {
      item = new AngleItem(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'measure-ref') {
      item = new MeasureRef(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'measure') {
      item = new Measure(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'text') {
      item = new myText(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'rect') {
      item = new Rect(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'rect-empty') {
      item = new RectEmpty(JSON.parse(itemBD.data));
    } else if (itemBD.type === 'arrow') {
      item = new Arrow(JSON.parse(itemBD.data));
    }
    return item;
  }
}
