import { Component, OnInit, ViewChild } from '@angular/core';
import { Modal } from 'src/app/models/modal.model';
import { Subject, Observable } from 'rxjs';
import { FilterSearchValue } from 'src/app/models/filter-search-value.model';
import { DropdownComponent } from 'src/app/elements/dropdown/dropdown.component';
import { DropdownItem } from 'src/app/models/utils/dropdown-item.model';
import { TagsService } from 'src/app/services/tags.service';
import { take, map } from 'rxjs/operators';

export interface FilterSearchModalResult {
    groupId?: number;
    fromDate?: Date;
    toDate?: Date;
}

@Component({
    selector: 'app-filter-search-modal',
    templateUrl: './filter-search-modal.component.html',
    styleUrls: ['./filter-search-modal.component.scss']
})
export class FilterSearchModalComponent implements OnInit, Modal {

    onClose = new Subject<FilterSearchModalResult>();

    data;

    @ViewChild('tagsDropdown')
    tagsDropdown: DropdownComponent;

    dateFrom: Date = undefined;
    dateTo: Date = undefined;

    tagsDropdownItems: Observable<Array<DropdownItem>>;

    constructor(private tagsService: TagsService) { }

    ngOnInit(): void {
        this.tagsDropdownItems = this.tagsService.getTags$().pipe(take(1), map( tags => {
            const dropdownItems = tags.tags.map( tag => {
                const item: DropdownItem = {displayName: tag.name, item: tag};
                return item;
            });
            dropdownItems.unshift({displayName: 'Todos los clientes', item: null});
            return dropdownItems;
        }));
    }

    historyBackClose() {
        this.cancel();
    }

    cancel() {
        this.onClose.next({});
    }

    accept() {
        const result: FilterSearchModalResult = {};
        if (!!this.tagsDropdown.selectedItem.item) {
            result.groupId = this.tagsDropdown.selectedItem.item.id;
        } else {
            result.groupId = undefined;
        }
        result.toDate = this.dateTo;
        result.fromDate = this.dateFrom;

        this.onClose.next(result);
    }

    dateFromSelected(event) {
        this.dateFrom = event.value;
    }

    dateToSelected(event) {
        this.dateTo = event.value;
    }

}
