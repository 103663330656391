import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthService } from '../services/auth.service'; // Adjust the import path as needed
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ConditionsAcceptedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navigation: NavigationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.user$.pipe(
      take(1),
      map((user) => {
        if (!user) {
          this.navigation.goToLogin();
          return false;
        }
        if (!user.conditionsAccepted) {
          if (user.role === 'client') {
            const professionalId = route.queryParams['professionalId'];
            this.navigation.clientNav.goToAcceptConditions({
              queryParams: {
                professionalId,
              },
            });
          } else {
            this.navigation.goToAcceptConditions();
          }
        }
        return true;
      }),
    );
  }
}
