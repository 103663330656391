<div class="">
  <div *ngIf="clientCorrectiveSession" class="test-view">
    <div *ngIf="client$ | async as client">
      <div class="app-container" *ngIf="correctiveSessionProtocolDef">
        <app-test-header
          [title]="correctiveSessionProtocolDef.name"
          [isUserClientView]="isUserClientView"
          [generateReportAction]="generateReport"
        ></app-test-header>

        <div class="test-info">
          <app-client-back-button
            *ngIf="!isUserClientView"
            [client]="client"
            [myVafMode]="true"
            class="mr-2"
          ></app-client-back-button>
          <div class="date-selector mt-2 md:mt-0">
            <button
              *ngIf="!isUserClientView"
              (click)="picker.open()"
              class="date-selector__button"
            >
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
              <i class="fas fa-edit"></i>
            </button>
            <span *ngIf="isUserClientView">
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
            </span>

            <input
              style="display: none"
              (dateChange)="onDateChanged($event)"
              matInput
              [matDatepicker]="picker"
              placeholder="Choose a date"
            />
            <mat-datepicker
              touchUi
              #picker
              [startAt]="clientCorrectiveSession.createdAt.toDate()"
            ></mat-datepicker>
          </div>
        </div>
        <ng-container *ngIf="initDone">
          <div
            *ngFor="
              let sectionDef of correctiveSessionProtocolDef.sections;
              let sectionIndex = index
            "
            class="mb-5 rounded-lg bg-white py-4 pb-8 transition-all duration-300"
          >
            <div
              class="px-3 text-center text-lg font-semibold md:flex md:items-center md:justify-between md:px-8"
            >
              <span class="py-1">
                {{ sectionDef.name }}
              </span>

              <!-- duration -->
              <div
                *ngIf="sectionDef.sectionExercises"
                class="flex items-center justify-center text-sm sm:text-base"
              >
                <div class="flex items-center justify-center gap-x-2">
                  <span class="block md:hidden">Duración </span>
                  <span class="hidden md:block"> Duración total </span>
                  <span
                    class="flex items-center justify-center rounded-md bg-gray-100 px-2 py-1"
                  >
                    {{ getDurationBySection(sectionIndex) || '-' }}
                  </span>
                </div>
                <span class="mx-2" *ngIf="getRoundsBySection(sectionIndex) > 0">
                  /
                </span>
                <!-- rounds -->
                <div
                  *ngIf="getRoundsBySection(sectionIndex) > 0"
                  class="flex items-center justify-center gap-x-2"
                >
                  <span
                    class="flex items-center justify-center rounded-md bg-gray-100 px-2 py-1"
                  >
                    {{ getRoundsBySection(sectionIndex) }}
                  </span>
                  {{
                    getRoundsBySection(sectionIndex) === 1
                      ? 'vuelta'
                      : getRoundsBySection(sectionIndex) > 1
                        ? 'vueltas'
                        : ''
                  }}
                  <!-- <span
                    matTooltip="Vueltas al circuito"
                    #tooltip="matTooltip"
                    (click)="tooltip.toggle(); $event.stopPropagation()"
                    class="ml-0.5 cursor-pointer"
                  >
                    <i class="fas fa-circle-info"></i>
                  </span> -->
                </div>
              </div>
            </div>
            <div class="mb-4"></div>
            <!-- images -->
            <!-- For old version without exercises entity -->
            <ng-container
              *ngIf="
                sectionDef.fixedImages && sectionDef.fixedImages.length > 0
              "
            >
              <div
                class="custom-scroll relative flex w-full gap-x-5 overflow-y-hidden overflow-x-scroll px-5 pb-3 md:px-8"
              >
                <ng-container *ngFor="let image of sectionDef.fixedImages">
                  <div
                    class="h-screen-3/4 max-h-[300px] w-full flex-shrink-0 sm:max-h-80 sm:w-auto"
                  >
                    <div
                      class="relative mx-auto aspect-square h-full overflow-hidden rounded-md bg-gray-100"
                    >
                      <img
                        *ngIf="image.downloadUrl"
                        src="{{ image.downloadUrl }}"
                        class="h-full w-full object-cover"
                        alt="{{ image.title }}"
                      />
                      <div
                        class="flex h-full w-full items-center justify-center"
                        *ngIf="!image.downloadUrl"
                      >
                        La imagen no tiene url
                      </div>
                      <div
                        *ngIf="image.title"
                        class="absolute left-0 top-0 w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
                      >
                        <p>{{ image.title }}</p>
                      </div>
                      <div
                        class="absolute bottom-0 right-0 px-4 py-5"
                        *ngIf="!isUserClientView"
                      >
                        <a
                          *ngIf="image.link"
                          href="{{ image.link }}"
                          target="_blank"
                          class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
                        >
                          <i class="fas fa-external-link-alt text-white"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!-- For new version with exercises entity -->
            <ng-container *ngIf="sectionDef.sectionExercises?.length">
              <div
                class="custom-scroll relative mt-4 flex w-full !cursor-default gap-x-6 overflow-y-hidden overflow-x-scroll px-1 pb-3"
              >
                <ng-container
                  *ngFor="
                    let exercise of sectionDef.sectionExercises;
                    let i = index
                  "
                >
                  <div
                    *ngIf="
                      getExerciseDetails(exercise.exerciseId) as exerciseDetails
                    "
                    class="w-full flex-shrink-0 sm:w-auto"
                  >
                    <!-- previous class -->
                    <!-- class="mx-auto max-w-[300px] overflow-hidden rounded-md bg-gray-100 md:max-w-80" -->
                    <div
                      class="relative mx-auto w-full max-w-[340px] rounded-md md:w-auto md:max-w-80"
                    >
                      <!-- Title tooltip -->
                      <div
                        class="overflow-hidden text-ellipsis whitespace-nowrap rounded-t-md bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                        [matTooltip]="exerciseDetails.title"
                        #titleTooltip="matTooltip"
                        (click)="
                          titleTooltip.toggle(); $event.stopPropagation()
                        "
                      >
                        {{ exerciseDetails.title }}
                      </div>
                      <div class="relative">
                        <div class="aspect-square max-h-[340px]">
                          <img
                            *ngIf="exerciseDetails.imageUrl"
                            src="{{ exerciseDetails.imageUrl }}"
                            class="h-full w-full object-cover"
                            alt="{{ exerciseDetails.title }}"
                          />
                        </div>

                        <div
                          class="flex h-full w-full items-center justify-center"
                          *ngIf="!exerciseDetails.imageUrl"
                        >
                          Ejercicio sin imagen
                        </div>
                        <div
                          class="absolute bottom-0 right-0 mb-4 mr-2 rounded-lg bg-black/30 px-3 py-1 text-white"
                        >
                          <!-- Exercise position (i.e, 1/10) and info tooltip with exercise description -->
                          <span class="text-xs font-semibold">
                            {{ i + 1 }}/{{ sectionDef.sectionExercises.length }}
                          </span>
                          <span
                            *ngIf="exerciseDetails.description"
                            [matTooltip]="exerciseDetails.description"
                            #tooltip="matTooltip"
                            (click)="tooltip.toggle(); $event.stopPropagation()"
                            class="ml-0.5 cursor-pointer"
                          >
                            <i class="fas fa-circle-info"></i>
                          </span>
                        </div>
                      </div>
                      <!-- First grid - Volume, Series, Intensity -->
                      <div class="mt-2 grid grid-cols-3 gap-2">
                        <!-- Each column needs flex and h-full to stretch -->
                        <!-- Series -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[0].title }}
                          </div> -->
                          <div
                            class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [class.bg-inteccDark]="true"
                            [class.text-white]="true"
                            [matTooltip]="'Número de series'"
                            #seriesToolTip="matTooltip"
                            (click)="
                              seriesToolTip.toggle(); $event.stopPropagation()
                            "
                          >
                            <span
                              *ngIf="sectionDef.fields[0] as field"
                              class="font-bold"
                            >
                              {{
                                getValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                )
                                  ? 'X' +
                                    getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                  : '-'
                              }}
                            </span>
                          </div>
                        </div>
                        <!-- Volumen -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[1].title }}
                          </div> -->
                          <div
                            class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [class.bg-inteccDark]="true"
                            [class.text-white]="true"
                            [matTooltip]="sectionDef.fields[1].title"
                            #volumeTooltip="matTooltip"
                            (click)="
                              volumeTooltip.toggle(); $event.stopPropagation()
                            "
                          >
                            <span
                              *ngIf="sectionDef.fields[1] as field"
                              class="font-bold"
                            >
                              {{
                                (field.type === 'select'
                                  ? getFieldOptionText(
                                      sectionDef,
                                      field,
                                      exercise
                                    )
                                  : getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )) || '-'
                              }}
                            </span>
                          </div>
                        </div>

                        <!-- Intensidad -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[2].title }}
                          </div> -->
                          <div
                            class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [class.bg-inteccDark]="true"
                            [class.text-white]="true"
                            [matTooltip]="sectionDef.fields[2].title"
                            #intensityTooltip="matTooltip"
                            (click)="
                              intensityTooltip.toggle();
                              $event.stopPropagation()
                            "
                          >
                            <span
                              *ngIf="sectionDef.fields[2] as field"
                              class="font-bold"
                            >
                              {{
                                getValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                ) || '-'
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- Second grid - Trabajo, Descanso, Siguiente -->
                      <div class="mt-2 grid grid-cols-3 gap-2">
                        <!-- Descanso -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[4].title }}
                          </div> -->
                          <div
                            *ngIf="sectionDef.fields[4] as field"
                            class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [matTooltip]="'Descanso entre series'"
                            #restTooltip="matTooltip"
                            (click)="
                              restTooltip.toggle(); $event.stopPropagation()
                            "
                          >
                            <span class="font-bold">
                              {{
                                getTimeValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                )
                                  ? getTimeValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                  : '-'
                              }}
                            </span>
                          </div>
                        </div>
                        <!-- Trabajo -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[3].title }}
                          </div> -->
                          <div
                            class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [matTooltip]="'Tiempo de trabajo'"
                            #seriesRestTooltip="matTooltip"
                            (click)="
                              seriesRestTooltip.toggle();
                              $event.stopPropagation()
                            "
                          >
                            <span
                              *ngIf="sectionDef.fields[3] as field"
                              class="font-bold"
                            >
                              {{
                                getTimeValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                )
                                  ? getTimeValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                  : '-'
                              }}
                            </span>
                          </div>
                        </div>
                        <!-- Siguiente -->
                        <div class="flex h-full flex-col text-center">
                          <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                            {{ sectionDef.fields[5].title }}
                          </div> -->
                          <div
                            *ngIf="sectionDef.fields[5] as field"
                            class="relative flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                            [matTooltip]="'Descanso al siguiente ejercicio'"
                            #restNextTooltip="matTooltip"
                            (click)="
                              restNextTooltip.toggle(); $event.stopPropagation()
                            "
                          >
                            <span class="font-bold">
                              {{
                                getTimeValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                )
                                  ? getTimeValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                  : '-'
                              }}
                            </span>
                            <!-- center to the right and vertically centered -->
                            <div
                              class="absolute bottom-0 right-2 top-0 flex items-center justify-center"
                            >
                              <!-- icono de forward rewind -->
                              <i class="fas fa-forward"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Campo extra -->
                      <div
                        class="mt-2 flex h-full flex-col text-center"
                        *ngIf="sectionDef.fields[6]"
                      >
                        <!-- <div class="mb-1 text-xs" *ngIf="isUserClientView">
                          {{ sectionDef.fields[6].title }}
                        </div> -->
                        <div
                          *ngIf="sectionDef.fields[6] as field"
                          class="flex flex-1 items-center justify-center rounded-lg bg-gray-100 px-4 py-1.5 text-xs"
                          [matTooltip]="'Campo extra'"
                          #restNextTooltip="matTooltip"
                          (click)="
                            restNextTooltip.toggle(); $event.stopPropagation()
                          "
                        >
                          <span class="font-bold">
                            {{
                              getValueForExercise(
                                sectionDef.localId,
                                field.localId,
                                exercise.key
                              ) || '-'
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- controles de play/pause, swipper... (si se implementa) -->
              <!-- TODO:: uncomment when adding controls -->
              <!-- <div
                class="mx-auto mt-2 flex h-9 w-full max-w-sm justify-center gap-x-2 font-bold text-white"
              >
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-play"></i>
                </button>
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div> -->
            </ng-container>
            <div class="mb-4"></div>
            <div class="px-2 md:px-4">
              <div
                class="mb-2 flex w-full justify-between border-b text-sm sm:justify-start md:text-base"
              >
                <app-tab-item
                  [name]="'Descripción'"
                  [selected]="selectedTabs[sectionIndex] === 'description'"
                  (tapEvent)="handleTabChange('description', sectionIndex)"
                ></app-tab-item>
                <app-tab-item
                  [name]="'Prescripción'"
                  [selected]="selectedTabs[sectionIndex] === 'prescription'"
                  (tapEvent)="handleTabChange('prescription', sectionIndex)"
                ></app-tab-item>
                <app-tab-item
                  [name]="'Comentarios'"
                  [selected]="selectedTabs[sectionIndex] === 'comments'"
                  (tapEvent)="handleTabChange('comments', sectionIndex)"
                ></app-tab-item>
              </div>
              <!-- Descripción -->
              <div
                *ngIf="selectedTabs[sectionIndex] === 'description'"
                class="group"
              >
                <div *ngIf="sectionDef.description" class="px-1 py-4">
                  <p
                    class="text-neutral-500"
                    [innerHTML]="processLineBreak(sectionDef.description)"
                  ></p>
                </div>
              </div>
              <!-- Prescrición -->
              <div
                *ngIf="selectedTabs[sectionIndex] === 'prescription'"
                class="group"
              >
                <div class="px-1 py-4">
                  <div *ngIf="sectionDef.mode === 'table'" class="w-full">
                    <div *ngIf="sectionDef.fields; else noFields">
                      <div class="col-span-2 overflow-y-auto overflow-x-scroll">
                        <div
                          *ngIf="sectionDef.tableMode === 'xy'"
                          class="h-auto self-start rounded-md"
                        >
                          <div
                            class="mb-2 flex items-center"
                            *ngIf="sectionDef.sectionExercises"
                          >
                            <label class="mx-4 w-16 font-semibold" for="rounds"
                              >Vueltas</label
                            >
                            <input
                              class="w-20 rounded-lg border-2 border-neutral-200 px-1 py-1"
                              type="number"
                              [min]="1"
                              [max]="50"
                              id="rounds"
                              [readonly]="isUserClientView"
                              [ngModel]="getRoundsBySection(sectionIndex)"
                              (ngModelChange)="
                                setRoundsBySection(sectionIndex, $event)
                              "
                            />
                            <i
                              class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                              matTooltip="Número de vueltas"
                              #roundsTooltip="matTooltip"
                              (click)="
                                roundsTooltip.toggle(); $event.stopPropagation()
                              "
                            ></i>
                          </div>
                          <div
                            class="mb-2 flex items-center"
                            *ngIf="sectionDef.sectionExercises"
                          >
                            <label
                              class="mx-4 w-16 font-semibold"
                              for="duration"
                              >Duración</label
                            >
                            <app-time-input
                              [customClass]="
                                'w-20 rounded-lg border-2 border-neutral-200 px-1 py-1'
                              "
                              [ngModel]="getDurationBySection(sectionIndex)"
                              [ngModelOptions]="{ standalone: true }"
                              [readonly]="isUserClientView"
                              (ngModelChange)="
                                setDurationBySection(sectionIndex, $event)
                              "
                            ></app-time-input>
                            <i
                              class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                              matTooltip="Duración total"
                              #durationTooltip="matTooltip"
                              (click)="
                                durationTooltip.toggle();
                                $event.stopPropagation()
                              "
                            ></i>
                          </div>
                          <table
                            mat-table
                            [dataSource]="
                              preBuiltDataSources[sectionDef.localId]
                            "
                            class="w-full table-fixed"
                            [class.prioritize-first-col-width]="
                              sectionDef.sectionExercises
                            "
                          >
                            <ng-container matColumnDef="rowTitle">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="font-bold"
                              >
                                {{
                                  sectionDef.tableFirstColTitle
                                    ? sectionDef.tableFirstColTitle
                                    : ' '
                                }}
                              </th>
                              <td
                                mat-cell
                                *matCellDef="let element"
                                style="
                                  border-bottom: 1px solid #e5e7eb;
                                  min-width: 100px;
                                "
                              >
                                <p class="w-full font-bold">
                                  {{ element.rowTitle }}
                                </p>
                              </td>
                            </ng-container>
                            <!-- for old version without exercises entity -->
                            <ng-container *ngIf="!sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p *ngIf="field.type === 'select'">
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p *ngIf="field.type === 'text'">
                                      {{ element[field.localId] }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <!-- for new version with exercises entity -->
                            <ng-container *ngIf="sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      *ngIf="field.type !== 'time'"
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                    <app-time-input
                                      [customClass]="'w-full py-2 px-3 text-sm'"
                                      *ngIf="field.type === 'time'"
                                      [ngModel]="
                                        formatTime(element[field.localId])
                                      "
                                      [ngModelOptions]="{ standalone: true }"
                                      (ngModelChange)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    ></app-time-input>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p
                                      *ngIf="field.type === 'select'"
                                      class="whitespace-normal break-words px-3 py-2 text-sm"
                                    >
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p
                                      *ngIf="
                                        field.type === 'text' ||
                                        field.type === 'number'
                                      "
                                      class="whitespace-normal break-words px-3 py-2 text-sm"
                                    >
                                      {{
                                        getValueForExercise(
                                          sectionDef.localId,
                                          field.localId,
                                          element.sectionExerciseKey
                                        )
                                      }}
                                    </p>
                                    <p
                                      *ngIf="field.type === 'time'"
                                      class="whitespace-normal break-words px-3 py-2 text-sm"
                                    >
                                      {{ formatTime(element[field.localId]) }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <tr
                              mat-header-row
                              *matHeaderRowDef="
                                preBuiltDisplayedColumns[sectionDef.localId]
                              "
                            ></tr>
                            <tr
                              mat-row
                              *matRowDef="
                                let row;
                                columns: preBuiltDisplayedColumns[
                                  sectionDef.localId
                                ]
                              "
                            ></tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <ng-template #noFields> </ng-template>
                  </div>
                </div>
              </div>
              <!-- Comentarios -->
              <div
                *ngIf="selectedTabs[sectionIndex] === 'comments'"
                class="group"
              >
                <div class="px-1 py-4">
                  <textarea
                    class="w-full rounded-lg border-2 border-neutral-200"
                    #commentsTextArea
                    name="comments"
                    cols="30"
                    rows="5"
                    [value]="getComments(sectionDef)"
                    (change)="onCommentsChanged(sectionDef, $event)"
                    [readonly]="isUserClientView"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div
            class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
          >
            <div class="mb-4 flex justify-between">
              <p class="text-xl font-semibold">Recomendaciones y enlaces</p>
              <div class="flex items-center"></div>
            </div>
            <div class="w-full overflow-y-scroll md:overflow-y-hidden"></div>
            <div class="w-full">
              <p class="mb-4 mt-7 text-lg font-semibold">Recomendaciones</p>
              <textarea
                class="w-full rounded-lg border-2 border-neutral-200"
                #commentsTextArea
                name="comments"
                cols="30"
                rows="5"
                [value]="getTips()"
                (change)="onTipsChanged($event)"
                [readonly]="isUserClientView"
              ></textarea>
            </div>
            <div class="w-full">
              <p class="mb-1 mt-7 text-lg font-semibold">Enlaces</p>
              <p *ngIf="!isUserClientView">Añade enlaces a los informes</p>
              <app-test-report-links
                [links]="clientCorrectiveSession.links"
                (changeEvent)="this.setLinks($event)"
                [isUserClientView]="isUserClientView"
              ></app-test-report-links>
            </div>
          </div>
          <div
            *ngIf="!isUserClientView"
            class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
          >
            <div class="mb-4">
              <p class="mb-4 text-xl font-semibold">Enlace para compartir</p>
              <app-input
                class="md:col-span-2"
                [readonly]="true"
                [button]="copySharedUrlButton"
                [value]="getShareURL()"
              ></app-input>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
