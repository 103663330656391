import { Component, Input } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ModalService } from 'src/app/services/modal.service';
import { MyvafService } from 'src/app/services/myvaf.service';
import { TestService } from 'src/app/services/test.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import {
  getCommentsMyVafTest,
  getTipsMyVaf,
  MyVafImageDef,
  MyVafProtocolDef,
  MyVafProtocolTest,
  MyVafTestDef,
  setCommentsMyVafTest,
  setTipsMyVaf,
} from 'src/shared/myvaf.outside';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-myvaf-protocol',
  templateUrl: './myvaf-protocol.component.html',
  styleUrls: ['./myvaf-protocol.component.scss'],
})
export class MyvafProtocolComponent {
  displayedColumns: string[] = ['position', 'name'];
  dataSource = ELEMENT_DATA;

  copySharedUrlButton = {
    faClass: 'far fa-copy',
    action: () => {
      this.clipboardService.copyToClipboard(this.getShareURL());
    },
  };

  _test: MyVafProtocolTest;
  get test() {
    return this._test;
  }
  @Input() set test(value: MyVafProtocolTest) {
    this._test = value;
    this.init();
  }
  @Input() client: Client;

  @Input() isUserClientView: boolean;

  protocolDef: MyVafProtocolDef;

  testDataSource;
  testDisplayedColumns;

  preBuiltDataSources = {};
  preBuiltDisplayedColumns = {};

  constructor(
    private testsService: TestService,
    private myVafService: MyvafService,
    private modals: ModalService,
    private clipboardService: ClipboardService,
  ) {}

  async init() {
    this.protocolDef = await this.myVafService.getProtocol(
      this.test.protocolDefId,
    );
    console.log('Protocol Def');
    console.log(this.protocolDef);
    console.log('Test');
    console.log(this.test);
    for (let index = 0; index < this.protocolDef.tests.length; index++) {
      const test = this.protocolDef.tests[index];
      this.preBuildTableData(test);
    }
    // this.testDataSource = this.buildTableDataSource(this.protocolDef.tests[1]);
    // this.testDisplayedColumns = this.getDisplayedColumns(this.protocolDef.tests[1]);
  }

  preBuildTableData(test: MyVafTestDef) {
    this.preBuiltDataSources[test.localId] = this.buildTableDataSource(test);
    this.preBuiltDisplayedColumns[test.localId] =
      this.getDisplayedColumns(test);
  }

  onDateChanged(date: any) {
    this.test.createdAt = firebaseTimestampFromDate(date.value);
    this.updateTest();
  }

  updateTest() {
    if (this.isUserClientView) {
      return;
    }
    const { ...test } = this.test;
    this.testsService.updateTest(test);
  }

  TESTgetFieldTitle(field) {
    console.log(field.title);
    return field.title;
  }

  buildTableDataSource(testDef: MyVafTestDef) {
    if (testDef.tableMode !== 'xy') {
      const result = this.buildTableDataSourceX(testDef);
      return result;
    } else {
      const result = this.buildTableDataSourceXY(testDef);
      return result;
    }
  }

  buildTableDataSourceX(testDef: MyVafTestDef) {
    const result = [];
    const numberOfRows: number = this.getNumberOfRows(testDef.localId);
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      for (let index2 = 0; index2 < testDef.fields.length; index2++) {
        const field = testDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          testDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    console.log(result);
    return result;
  }

  buildTableDataSourceXY(testDef: MyVafTestDef) {
    console.log('buildXY');
    console.log(testDef);

    const result = [];
    if (!testDef.tableRowFields) {
      return result;
    }
    if (!testDef.fields) {
      return result;
    }
    const numberOfRows: number = testDef.tableRowFields.length;
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      item.rowTitle = testDef.tableRowFields[index].title;
      for (let index2 = 0; index2 < testDef.fields.length; index2++) {
        const field = testDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          testDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    console.log(result);
    return result;
  }

  getNumberOfRows(testLocalId: number) {
    let numberOfRows: number = this.test[`${testLocalId}-rows`];
    // console.log('number of row', numberOfRows);

    if (!numberOfRows) {
      numberOfRows = 0;
    }

    return numberOfRows;
  }

  addRow(testLocalId: number) {
    this.test[`${testLocalId}-rows`] = this.getNumberOfRows(testLocalId) + 1;
    const test = this.protocolDef.tests.find((t) => t.localId === testLocalId);
    this.preBuildTableData(test);
    this.updateTest();
  }

  removeRow(testLocalId: number) {
    if (this.getNumberOfRows(testLocalId) === 0) {
      return;
    }
    this.test[`${testLocalId}-rows`] = this.getNumberOfRows(testLocalId) - 1;
    const test = this.protocolDef.tests.find((t) => t.localId === testLocalId);
    this.preBuildTableData(test);
    this.updateTest();
  }

  getDisplayedColumns(testDef: MyVafTestDef) {
    const result: string[] = [];
    if (!testDef.fields) {
      return result;
    }
    if (testDef.tableMode === 'xy') {
      result.push('rowTitle');
    }
    for (let index = 0; index < testDef.fields.length; index++) {
      const field = testDef.fields[index];
      result.push(field.localId + '');
    }
    // console.log('displayed columns', result);

    return result;
  }

  getValue(testLocalId: number, fieldLocalId: number) {
    if (this.test[`${testLocalId}-${fieldLocalId}`] === undefined) {
      return -1;
    }
    const result = this.test[`${testLocalId}-${fieldLocalId}`];
    return result;
  }

  getValueTable(testLocalId: number, fieldLocalId: number, index: number) {
    if (this.test[`${testLocalId}-${fieldLocalId}-${index}`] === undefined) {
      return -1;
    }
    const result = this.test[`${testLocalId}-${fieldLocalId}-${index}`];
    return result;
  }

  getValueTableText(testLocalId: number, fieldLocalId: number, index: number) {
    if (this.test[`${testLocalId}-${fieldLocalId}-${index}`] === undefined) {
      return '';
    }
    const result = this.test[`${testLocalId}-${fieldLocalId}-${index}`];
    return result;
  }

  getValueText(testLocalId: number, fieldLocalId: number) {
    if (this.test[`${testLocalId}-${fieldLocalId}`] === undefined) {
      return '';
    }
    const result = this.test[`${testLocalId}-${fieldLocalId}`];
    return result;
  }

  setValue(testLocalId: number, fieldLocalId: number, value) {
    this.test[`${testLocalId}-${fieldLocalId}`] = value;
    console.log('Set Value: ', value);
    this.updateTest();
  }

  setValueText(testLocalId: number, fieldLocalId: number, value) {
    this.test[`${testLocalId}-${fieldLocalId}`] = value;
    console.log('Set Value: ', value);
    this.updateTest();
  }

  setValueTextTable(
    testLocalId: number,
    fieldLocalId: number,
    index: number,
    value,
  ) {
    this.test[`${testLocalId}-${fieldLocalId}-${index}`] = value;
    console.log('Set Value: ', value);
    this.updateTest();
  }

  getValueLF(testLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`] ===
      undefined
    ) {
      return -1;
    }
    const result =
      this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`];
    return result;
  }

  getValueTextLF(testLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`] ===
      undefined
    ) {
      return '';
    }
    const result =
      this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`];
    return result;
  }

  setValueLF(testLocalId: number, fieldLocalId: number, value, right: boolean) {
    this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`] = value;
    console.log('Set Value: ', value);
    console.log('Updated Test');
    console.log(this.test);

    this.updateTest();
  }

  setValueTextLF(
    testLocalId: number,
    fieldLocalId: number,
    value,
    right: boolean,
  ) {
    this.test[`${testLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`] = value;
    console.log('Set Value: ', value);
    this.updateTest();
  }

  onImageChanged() {
    this.updateTest();
  }

  getComments(testDef: MyVafTestDef) {
    return getCommentsMyVafTest(testDef, this.test);
  }

  onCommentsChanged(testDef: MyVafImageDef, event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setCommentsMyVafTest(testDef, this.test, value);
    this.updateTest();
  }

  getTips() {
    return getTipsMyVaf(this.test);
  }

  onTipsChanged(event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setTipsMyVaf(this.test, value);
    this.updateTest();
  }

  setLinks(value: any) {
    this.test.links = value;
    this.updateTest();
  }

  processLineBreak(text: string) {
    text = text.replace(new RegExp('\n', 'g'), '<br />');
    return text;
  }

  getShareURL() {
    return this.testsService.getMyVafTestShareUrl(this.test);
  }
}
