import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, switchMap, timeout, catchError, filter, map } from 'rxjs/operators';
import { Observable, of, from, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

    constructor(
        private appRef: ApplicationRef,
        private swUpdate: SwUpdate,
    ) {
        if (this.swUpdate.isEnabled) {
            this.appRef.isStable.pipe(
                first(isStable => isStable === true),
                switchMap(() => this.swUpdate.versionUpdates.pipe(
                    filter(event => event.type === 'VERSION_READY')
                )),
            ).subscribe(() => {
                this.swUpdate.activateUpdate().then(() => document.location.reload());
            });
        }
    }

    checkForUpdate(): Observable<boolean> {
        const waitFor = 1500;

        if (this.swUpdate.isEnabled) {
            const available$ = this.swUpdate.versionUpdates.pipe(
                filter(event => event.type === 'VERSION_READY'),
                map(() => true),
                timeout(waitFor),
                catchError(() => of(false)),
            );

            return from(this.swUpdate.checkForUpdate()).pipe(
                switchMap(() => available$),
            );
        }

        return timer(waitFor).pipe(map(() => false));
    }
}
