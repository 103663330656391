import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { MyvafService } from 'src/app/services/myvaf.service';
import {
  calculateImageKey,
  MyVafProtocolDef,
  MyVafTestDef,
  optionsStringToOptionsArray,
} from 'src/shared/myvaf.outside';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { SimpleDropdownOption } from 'src/app/elements/simple-dropdown/simple-dropdown.component';
import { ModalService } from 'src/app/services/modal.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { TestService } from 'src/app/services/test.service';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-myvaf-protocol-edit',
  templateUrl: './myvaf-protocol-edit.component.html',
  styleUrls: ['./myvaf-protocol-edit.component.scss'],
})
export class MyvafProtocolEditComponent implements OnInit {
  selectedTest: MyVafTestDef;
  protocol: MyVafProtocolDef;
  testsUsingThisProtocolExists$?: Observable<boolean>;
  protocolInUse = false;
  saving = false;
  deleting = false;
  duplicating = false;
  testEditMode = false;

  copySharedUrlButton = {
    faClass: 'far fa-copy',
    action: () => {
      this.copySharedUrlToClipboard();
    },
  };

  getImageUrlCopyButton = (imageUrl: string) => {
    return {
      faClass: 'far fa-copy',
      action: () => {
        this.clipboardService.copyToClipboard(imageUrl);
      },
    };
  };

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  testModeOptions: Array<SimpleDropdownOption> = [
    { title: 'Normal', value: 'fields' },
    { title: 'Tabla', value: 'table' },
  ];

  tableModeOptions: Array<SimpleDropdownOption> = [
    { title: 'Normal', value: 'x' },
    { title: 'Definir filas', value: 'xy' },
  ];

  fieldTypeOptions: Array<SimpleDropdownOption> = [
    { title: 'Texto', value: 'text' },
    { title: 'Opción', value: 'select' },
  ];

  fieldLeftRightOptions: Array<SimpleDropdownOption> = [
    { title: 'Valor único', value: false },
    { title: 'Izquierda y derecha', value: true },
  ];

  imagePositionOptions: Array<SimpleDropdownOption> = [
    { title: 'Parte inferior de la primera página', value: 'first' },
    { title: 'Página siguiente', value: 'second' },
  ];

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private myVafService: MyvafService,
    private backButtonService: OverrideBackButtonService,
    private modals: ModalService,
    private mediaService: MediaService,
    private clients: ClientsService,
    private confirmActionService: ConfirmActionDialogService,
    private testService: TestService,
    private auth: AuthService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const user = await this.auth.getUser();
    const protocolId = await safeToPromise(
      this.route.params.pipe(
        map((params) => {
          return params.id;
        }),
        take(1),
      ),
    );
    this.protocol = await this.myVafService.getProtocol(protocolId);

    this.testsUsingThisProtocolExists$ = this.testService
      .getTestByProtocolDefId$(this.protocol.id, user.uid)
      .pipe(
        map((tests) => {
          return tests.length > 0;
        }),
      );

    this.testsUsingThisProtocolExists$.subscribe((exists) => {
      this.protocolInUse = exists;
    });

    this.backButtonService.override(() => {
      this.exit();
    });
    // this.protocol.tests.push({
    //     name: 'Test 1'
    // });
    // this.protocol.tests.push({
    //     name: 'Test 2'
    // });
    // this.protocol.tests.push({
    //     name: 'Test 3'
    // });
  }

  stopEvent(event: PointerEvent) {
    event.stopPropagation();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.protocol.tests,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropField(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedTest.fields,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropRow(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedTest.tableRowFields,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedTest.images,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropFixedImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedTest.fixedImages,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropDescriptionFixedImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.protocol.fixedImages,
      event.previousIndex,
      event.currentIndex,
    );
  }

  openTest(index: number) {
    console.log('open test', index);
    this.testEditMode = true;
    this.selectedTest = this.protocol.tests[index];
    this.backButtonService.override(() => {
      this.closeTest();
    });
    window.scrollTo(0, 0);
  }

  closeTest() {
    this.testEditMode = false;
    this.backButtonService.cancelOverride();
  }

  addTest() {
    if (!this.protocol.tests) {
      this.protocol.tests = [];
    }
    if (this.protocol.lastLocalId === undefined) {
      this.protocol.lastLocalId = -1;
    }
    this.protocol.lastLocalId = this.protocol.lastLocalId + 1;
    this.protocol.tests.push({
      name: `Test ${this.protocol.tests.length + 1}`,
      localId: this.protocol.lastLocalId,
      fields: [],
      mode: 'fields',
    });
  }

  addField() {
    if (!this.selectedTest.fields) {
      this.selectedTest.fields = [];
    }
    if (this.selectedTest.lastLocalId === undefined) {
      this.selectedTest.lastLocalId = -1;
    }
    this.selectedTest.lastLocalId = this.selectedTest.lastLocalId + 1;
    const nextLength = this.selectedTest.fields.length + 1;
    this.selectedTest.fields.push({
      localId: this.selectedTest.lastLocalId,
      title: `Campo ${nextLength}`,
      leftRight: false,
      options: [],
      type: 'text',
      optionsString: '',
    });
  }

  addRow() {
    if (!this.selectedTest.tableRowFields) {
      this.selectedTest.tableRowFields = [];
    }
    if (this.selectedTest.lastLocalId === undefined) {
      this.selectedTest.lastLocalId = -1;
    }
    this.selectedTest.lastLocalId = this.selectedTest.lastLocalId + 1;
    const nextLength = this.selectedTest.tableRowFields.length + 1;
    this.selectedTest.tableRowFields.push({
      localId: this.selectedTest.lastLocalId,
      title: `Fila ${nextLength}`,
      leftRight: false,
      options: [],
      type: 'text',
      optionsString: '',
    });
  }

  addImage() {
    if (!this.selectedTest.images) {
      this.selectedTest.images = [];
    }
    if (this.selectedTest.lastLocalImageId === undefined) {
      this.selectedTest.lastLocalImageId = -1;
    }
    this.selectedTest.lastLocalImageId++;
    this.selectedTest.images.push({
      localId: this.selectedTest.lastLocalImageId,
      key: calculateImageKey(
        this.protocol.id,
        this.selectedTest.localId,
        this.selectedTest.lastLocalImageId,
      ),
      name: 'Imagen 1',
      position: 'first',
    });
  }

  addFixedImage() {
    if (!this.selectedTest.fixedImages) {
      this.selectedTest.fixedImages = [];
    }
    if (this.selectedTest.lastLocalImageId === undefined) {
      this.selectedTest.lastLocalImageId = -1;
    }
    this.selectedTest.lastLocalImageId++;
    this.selectedTest.fixedImages.push({
      localId: this.selectedTest.lastLocalImageId,
      key: calculateImageKey(
        this.protocol.id,
        this.selectedTest.localId,
        this.selectedTest.lastLocalImageId,
      ),
      link: '',
      title: '',
      position: 'first',
      downloadUrl: '',
    });
  }

  addDescriptionFixedImage() {
    if (!this.protocol.fixedImages) {
      this.protocol.fixedImages = [];
    }
    if (this.protocol.lastLocalImageId === undefined) {
      this.protocol.lastLocalImageId = -1;
    }
    this.protocol.lastLocalImageId++;
    this.protocol.fixedImages.push({
      localId: this.protocol.lastLocalImageId,
      key: `fixed`,
      link: '',
      title: '',
      position: 'first',
      downloadUrl: '',
    });
  }

  async uploadFixedImage(index: number) {
    this.clients.setCurrentClient(null);
    const result = await this.modals.openLateralModal('client-media', {
      onlyOriginals: true,
      selectionMode: true,
      selectionName: 'My VAF',
    });
    if (!result) {
      return;
    }
    const mediaItem = await this.mediaService.getMediaItem(result.id);

    this.selectedTest.fixedImages[index].downloadUrl = mediaItem.downloadURL;
    console.log(mediaItem.downloadURL);
  }

  async uploadDescriptionFixedImage(index: number) {
    const currentClientId = await safeToPromise(
      this.clients.currentClientId$.pipe(take(1)),
    );
    this.clients.setCurrentClient(null);
    const result = await this.modals.openLateralModal('client-media', {
      onlyOriginals: true,
      selectionMode: true,
      selectionName: 'My VAF',
    });
    this.clients.setCurrentClient(currentClientId);
    if (!result) {
      return;
    }
    const mediaItem = await this.mediaService.getMediaItem(result.id);

    this.protocol.fixedImages[index].downloadUrl = mediaItem.downloadURL;
    console.log(mediaItem.downloadURL);
  }

  deleteTest(index: number, event) {
    event.stopPropagation();
    this.protocol.tests.splice(index, 1);
  }

  duplicateTest(index: number, event) {
    event.stopPropagation();
    if (this.protocol.lastLocalId === undefined) {
      this.protocol.lastLocalId = -1;
    }
    this.protocol.lastLocalId = this.protocol.lastLocalId + 1;
    const duplicated = JSON.parse(JSON.stringify(this.protocol.tests[index]));
    const duplicatedTest = {
      ...duplicated,
      localId: this.protocol.lastLocalId,
    };
    this.protocol.tests.push({ ...duplicatedTest });
    // this.protocol.tests.splice(index, 0, {...this.protocol.tests[index], localId: this.protocol.lastLocalId});
  }

  deleteField(index: number, event) {
    event.stopPropagation();
    this.selectedTest.fields.splice(index, 1);
  }

  deleteRow(index: number, event) {
    event.stopPropagation();
    this.selectedTest.tableRowFields.splice(index, 1);
  }

  deleteImage(index: number, event) {
    event.stopPropagation();
    this.selectedTest.images.splice(index, 1);
  }

  deleteFixedImage(index: number, event) {
    event.stopPropagation();
    this.selectedTest.fixedImages.splice(index, 1);
  }

  deleteDescriptionFixedImage(index: number, event) {
    event.stopPropagation();
    this.protocol.fixedImages.splice(index, 1);
  }

  async deleteAndExit() {
    this.confirmActionService.openDialog({
      title: `¿Estás seguro/a?`,
      description:
        'Si continúas, perderás todos los registros basados en este protocolo y no los podrás recuperar.',
      confirmButton: `Eliminar protocolo`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          this.deleting = true;
          await this.myVafService.deleteProtocol(this.protocol);
          this.deleting = false;
          history.back();
        }
      },
    });
  }

  async duplicate() {
    this.duplicating = true;
    await this.myVafService.duplicateProtocol(this.protocol);
    this.snackbarService.success('Protocolo duplicado');
    this.duplicating = false;
    history.back();
  }

  async saveAndExit() {
    this.saving = true;
    optionsStringToOptionsArray(this.protocol);
    await this.myVafService.updateProtocol(this.protocol);
    this.saving = false;
    console.log('set my vaf menu to true');

    this.navigationService.openMyVafMenu = true;
    history.back();
  }

  async exit() {
    this.navigationService.openMyVafMenu = true;
    history.back();
  }

  async saveTestAndBack() {
    this.closeTest();
  }

  getShareURL() {
    const currentUrl = new URL(window.location.href);
    const baseUrl = currentUrl.origin;
    return `${baseUrl}/copy-protocol/${this.protocol.id}`;
    // return `https://vaf.intecc.org/copy-protocol/${this.protocol.id}`;
  }

  copySharedUrlToClipboard() {
    this.clipboardService.copyToClipboard(this.getShareURL());
  }
}
