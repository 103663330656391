export const TRAININGSESSIONSECTIONS_PATH = 'training-session-sections';

export interface TrainingSessionSectionItem {
  name: string;
  trainingSessionProtocolIds: Array<string>;
}

export interface TrainingSessionSection {
  id?: string;
  owner: string;
  sections: Array<TrainingSessionSectionItem>;
}
