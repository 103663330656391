import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SagitalFlechasTest, calculateResults } from 'src/app/models/sagital-flechas-test.model';
import { Client } from 'src/app/models/client.model';
import { TestService } from 'src/app/services/test.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import { LearnService } from 'src/app/services/learn.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-sagital-flechas',
    templateUrl: './sagital-flechas.component.html',
    styleUrls: ['./sagital-flechas.component.scss']
})
export class SagitalFlechasComponent implements OnInit, OnDestroy {

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _test: SagitalFlechasTest;
    get test() {
        return this._test;
    }
    @Input() set test(value: SagitalFlechasTest) {
        this._test = value;
        this.init();
    }
    date: Date;

    @Input() client: Client;

    flechasForm: UntypedFormGroup;

    subs = new Array<Subscription>();

    // eslint-disable-next-line max-len
    constructor(private tests: TestService, private learnService: LearnService, private formBuilder: UntypedFormBuilder, private modalsService: ModalService) { }

    ngOnInit() {
        console.log('init');
        calculateResults(this.test);
        this.flechasForm = this.formBuilder.group({
            fc: this.test.fc,
            ft: this.test.ft,
            fl: this.test.fl,
            fs: this.test.fs,
            fca: this.test.fca,
            fta: this.test.fta,
            fla: this.test.fla,
            fsa: this.test.fsa,
        });
        this.subs.push(this.flechasForm.valueChanges.pipe(
            tap(value => this.processInput(value)),
            debounceTime(1000),
            tap(value => this.updateTestFields(value)),
            tap(value => this.updateTest())
        ).subscribe());
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    init() {

    }

    processInput(input) {
        console.log(input);
        this.updateTestFields(input);
        calculateResults(this.test);
        console.log('ic:', this.test.ic);
    }

    updateTestFields(data) {
        this.test.fc = data.fc;
        this.test.ft = data.ft;
        this.test.fl = data.fl;
        this.test.fs = data.fs;
        this.test.fca = data.fca;
        this.test.fta = data.fta;
        this.test.fla = data.fla;
        this.test.fsa = data.fsa;
    }

    updateTest() {
        const { ...test } = this.test;
        console.log('Udate Test');
        this.tests.updateTest(test);
    }

    onDateChanged(date: any) {
        this.test.createdAt = firebaseTimestampFromDate(date.value);
        this.updateTest();
    }

    openLearn(learnSectionName: string) {
        this.learnService.openPanel(learnSectionName);
    }

    openGif() {
        this.modalsService.openModal('gif', {title: 'Flechas Raquídeas', gifId: 'gif-flechas'});
    }

    onCommentsChanged(event) {
        this.test.comments = event.target.value;
        this.updateTest();
      }

}
