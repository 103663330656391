import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';
import { angleFromLines, calculateDistance, centerOfSegment, MultPoint, NegativePoint, NormalizePoint, perpendicular, Point, SumPoints } from 'src/app/utils/math';
import { MeasureRef } from './measure-ref.class';

export interface ArrowData {
    ax: number;
    ay: number;
    bx: number;
    by: number;
}

export class Arrow extends EditItem {
    type = 'arrow';
    data: ArrowData;

    arrowWidth = 10;
    arrowLength = 15;
    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;
    bLineWidth = 15;
    textboxH = 30;
    textboxW = 100;
    textboxMarginBottom = 30;
    textColor = '#ffffff';

    static create(ax: number, ay: number, bx: number, by: number) {
        const data: ArrowData = {
            ax,
            ay,
            bx,
            by
        };
        return new Arrow(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
            }
        };
    }

    getActionResize(direction: 'a' | 'b'): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'a') {
                    this.data.ax = this.data.ax + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.ay = this.data.ay + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'b') {
                    this.data.bx = this.data.bx + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                    this.data.by = this.data.by + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                }
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    getDistanceText() {
        const defaultText = '- - cm';
        if (!this.drawer.editItems) {
            return defaultText;
        }
        const measureRef = this.drawer.editItems.find(item => item.type === 'measure-ref') as MeasureRef;
        if (!measureRef || !measureRef.data.ref) {
            return defaultText;
        }

        const measureRefValue = measureRef.data.ref;
        const measureRefDistance = calculateDistance(measureRef.data.ax, measureRef.data.ay, measureRef.data.bx, measureRef.data.by);
        const measureDistance = calculateDistance(this.data.ax, this.data.ay, this.data.bx, this.data.by);
        const result = measureDistance * measureRefValue / measureRefDistance;
        return `${Math.round(result)} cm`;
    }

    getAngleText() {
        const angle = angleFromLines(
            0, 0, 1, 0,
            this.data.ax, this.data.ay, this.data.bx, this.data.by,
            true
        );
        return `${Math.round(angle)} °`;
    }

    draw() {

        const lineAB = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.ax),
            this.drawer.relativeToAbsoluteY(this.data.ay),
            this.drawer.relativeToAbsoluteX(this.data.bx),
            this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });
        // const leftRight = this.drawer.svgObject.line(
        //     this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY),
        //     this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width),
        //     this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });

        const lineABWide = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.ax),
            this.drawer.relativeToAbsoluteY(this.data.ay),
            this.drawer.relativeToAbsoluteX(this.data.bx),
            this.drawer.relativeToAbsoluteY(this.data.by)).fill(this.color).opacity(.01).stroke({ color: this.color, width: this.bLineWidth });

        const rax = this.drawer.relativeToAbsoluteX(this.data.ax);
        const ray = this.drawer.relativeToAbsoluteY(this.data.ay);
        const rbx = this.drawer.relativeToAbsoluteX(this.data.bx);
        const rby = this.drawer.relativeToAbsoluteY(this.data.by);

        const A: Point = { x: rax, y: ray };
        const B: Point = { x: rbx, y: rby };

        const ABVectorX = rax - rbx;
        const ABVectorY = ray - rby;

        const NormalAB = NormalizePoint({ x: ABVectorX, y: ABVectorY });
        const ABPlus = MultPoint(NormalAB, -this.arrowLength);

        let per = perpendicular(ABVectorX, ABVectorY, false);
        per = NormalizePoint(per);
        per = MultPoint(per, this.arrowWidth);

        let perR = perpendicular(ABVectorX, ABVectorY, true);
        perR = NormalizePoint(perR);
        perR = MultPoint(perR, this.arrowWidth);

        const arrowA: Point = SumPoints(B, per);
        const arrowB: Point = SumPoints(B, perR);
        const arrowC: Point = SumPoints(B, ABPlus);

        const pointAS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
        // const pointBS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));
        const pointA = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.ax), this.drawer.relativeToAbsoluteY(this.data.ay));
        // const pointB = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.bx), this.drawer.relativeToAbsoluteY(this.data.by));

        // const arrowADrawing = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(1).center(arrowA.x, arrowA.y);
        // const arrowBDrawing = this.drawer.svgObject.circle(this.dotSize).fill('#ffffff').opacity(1).center(arrowB.x, arrowB.y);
        // const arrowCDrawing = this.drawer.svgObject.circle(this.dotSize).fill('#ffffff').opacity(1).center(arrowC.x, arrowC.y);

        const arrow = this.drawer.svgObject.polygon([[arrowA.x, arrowA.y], [arrowB.x, arrowB.y], [arrowC.x, arrowC.y]]).fill(this.color);

        // const textBoxCenter = centerOfSegment(this.data.ax, this.data.ay, this.data.bx, this.data.by);
        // textBoxCenter.x = this.drawer.relativeToAbsoluteX(textBoxCenter.x);
        // textBoxCenter.y = this.drawer.relativeToAbsoluteY(textBoxCenter.y) - this.textboxMarginBottom;

        // const textBox = this.drawer.svgObject.rect(this.textboxW, this.textboxH).center(
        //     textBoxCenter.x,
        //     textBoxCenter.y).fill(this.color);

        // const text = this.drawer.svgObject.text(`${this.getDistanceText()} / ${this.getAngleText()}`).center(
        //     textBoxCenter.x,
        //     textBoxCenter.y).fill(this.textColor);

        this.svgElements.push(pointAS);
        // this.svgElements.push(pointBS);
        this.svgElements.push(pointA);
        // this.svgElements.push(pointB);
        this.svgElements.push(lineAB);
        this.svgElements.push(lineABWide);
        this.svgElements.push(arrow);
        // this.svgElements.push(textBox);
        // this.svgElements.push(text);

        if (this.editable) {
            interact(pointA.node)
                .draggable(
                    this.getActionResize('a')
                )
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            // interact(pointB.node)
            //     .draggable(
            //         this.getActionResize('b')
            //     ).on('tap', (event) => {
            //         this.drawer.selectedEvent(this.index);
            //     });
            interact(arrow.node)
                .draggable(
                    this.getActionResize('b')
                ).on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(lineABWide.node)
                .draggable(
                    this.getActionMove()
                ).on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });

        }

    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
