var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};
var esm = {};

/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/

var k,
  goog = goog || {},
  l = commonjsGlobal || self;
function aa(a) {
  var b = typeof a;
  b = "object" != b ? b : a ? Array.isArray(a) ? "array" : b : "null";
  return "array" == b || "object" == b && "number" == typeof a.length;
}
function p(a) {
  var b = typeof a;
  return "object" == b && null != a || "function" == b;
}
function ba(a) {
  return Object.prototype.hasOwnProperty.call(a, ca) && a[ca] || (a[ca] = ++da);
}
var ca = "closure_uid_" + (1E9 * Math.random() >>> 0),
  da = 0;
function ea(a, b, c) {
  return a.call.apply(a.bind, arguments);
}
function fa(a, b, c) {
  if (!a) throw Error();
  if (2 < arguments.length) {
    var d = Array.prototype.slice.call(arguments, 2);
    return function () {
      var e = Array.prototype.slice.call(arguments);
      Array.prototype.unshift.apply(e, d);
      return a.apply(b, e);
    };
  }
  return function () {
    return a.apply(b, arguments);
  };
}
function q(a, b, c) {
  Function.prototype.bind && -1 != Function.prototype.bind.toString().indexOf("native code") ? q = ea : q = fa;
  return q.apply(null, arguments);
}
function ha(a, b) {
  var c = Array.prototype.slice.call(arguments, 1);
  return function () {
    var d = c.slice();
    d.push.apply(d, arguments);
    return a.apply(this, d);
  };
}
function r(a, b) {
  function c() {}
  c.prototype = b.prototype;
  a.$ = b.prototype;
  a.prototype = new c();
  a.prototype.constructor = a;
  a.ac = function (d, e, f) {
    for (var h = Array(arguments.length - 2), n = 2; n < arguments.length; n++) h[n - 2] = arguments[n];
    return b.prototype[e].apply(d, h);
  };
}
function v() {
  this.s = this.s;
  this.o = this.o;
}
var ia = 0;
v.prototype.s = !1;
v.prototype.sa = function () {
  if (!this.s && (this.s = !0, this.N(), 0 != ia)) {
    ba(this);
  }
};
v.prototype.N = function () {
  if (this.o) for (; this.o.length;) this.o.shift()();
};
const ka = Array.prototype.indexOf ? function (a, b) {
  return Array.prototype.indexOf.call(a, b, void 0);
} : function (a, b) {
  if ("string" === typeof a) return "string" !== typeof b || 1 != b.length ? -1 : a.indexOf(b, 0);
  for (let c = 0; c < a.length; c++) if (c in a && a[c] === b) return c;
  return -1;
};
function ma(a) {
  const b = a.length;
  if (0 < b) {
    const c = Array(b);
    for (let d = 0; d < b; d++) c[d] = a[d];
    return c;
  }
  return [];
}
function na(a, b) {
  for (let c = 1; c < arguments.length; c++) {
    const d = arguments[c];
    if (aa(d)) {
      const e = a.length || 0,
        f = d.length || 0;
      a.length = e + f;
      for (let h = 0; h < f; h++) a[e + h] = d[h];
    } else a.push(d);
  }
}
function w(a, b) {
  this.type = a;
  this.g = this.target = b;
  this.defaultPrevented = !1;
}
w.prototype.h = function () {
  this.defaultPrevented = !0;
};
var oa = function () {
  if (!l.addEventListener || !Object.defineProperty) return !1;
  var a = !1,
    b = Object.defineProperty({}, "passive", {
      get: function () {
        a = !0;
      }
    });
  try {
    l.addEventListener("test", () => {}, b), l.removeEventListener("test", () => {}, b);
  } catch (c) {}
  return a;
}();
function x(a) {
  return /^[\s\xa0]*$/.test(a);
}
function pa() {
  var a = l.navigator;
  return a && (a = a.userAgent) ? a : "";
}
function y(a) {
  return -1 != pa().indexOf(a);
}
function qa(a) {
  qa[" "](a);
  return a;
}
qa[" "] = function () {};
function ra(a, b) {
  var c = sa;
  return Object.prototype.hasOwnProperty.call(c, a) ? c[a] : c[a] = b(a);
}
var ta = y("Opera"),
  z = y("Trident") || y("MSIE"),
  ua = y("Edge"),
  va = ua || z,
  wa = y("Gecko") && !(-1 != pa().toLowerCase().indexOf("webkit") && !y("Edge")) && !(y("Trident") || y("MSIE")) && !y("Edge"),
  xa = -1 != pa().toLowerCase().indexOf("webkit") && !y("Edge");
function ya() {
  var a = l.document;
  return a ? a.documentMode : void 0;
}
var za;
a: {
  var Aa = "",
    Ba = function () {
      var a = pa();
      if (wa) return /rv:([^\);]+)(\)|;)/.exec(a);
      if (ua) return /Edge\/([\d\.]+)/.exec(a);
      if (z) return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);
      if (xa) return /WebKit\/(\S+)/.exec(a);
      if (ta) return /(?:Version)[ \/]?(\S+)/.exec(a);
    }();
  Ba && (Aa = Ba ? Ba[1] : "");
  if (z) {
    var Ca = ya();
    if (null != Ca && Ca > parseFloat(Aa)) {
      za = String(Ca);
      break a;
    }
  }
  za = Aa;
}
var Da;
if (l.document && z) {
  var Ea = ya();
  Da = Ea ? Ea : parseInt(za, 10) || void 0;
} else Da = void 0;
var Fa = Da;
function A(a, b) {
  w.call(this, a ? a.type : "");
  this.relatedTarget = this.g = this.target = null;
  this.button = this.screenY = this.screenX = this.clientY = this.clientX = 0;
  this.key = "";
  this.metaKey = this.shiftKey = this.altKey = this.ctrlKey = !1;
  this.state = null;
  this.pointerId = 0;
  this.pointerType = "";
  this.i = null;
  if (a) {
    var c = this.type = a.type,
      d = a.changedTouches && a.changedTouches.length ? a.changedTouches[0] : null;
    this.target = a.target || a.srcElement;
    this.g = b;
    if (b = a.relatedTarget) {
      if (wa) {
        a: {
          try {
            qa(b.nodeName);
            var e = !0;
            break a;
          } catch (f) {}
          e = !1;
        }
        e || (b = null);
      }
    } else "mouseover" == c ? b = a.fromElement : "mouseout" == c && (b = a.toElement);
    this.relatedTarget = b;
    d ? (this.clientX = void 0 !== d.clientX ? d.clientX : d.pageX, this.clientY = void 0 !== d.clientY ? d.clientY : d.pageY, this.screenX = d.screenX || 0, this.screenY = d.screenY || 0) : (this.clientX = void 0 !== a.clientX ? a.clientX : a.pageX, this.clientY = void 0 !== a.clientY ? a.clientY : a.pageY, this.screenX = a.screenX || 0, this.screenY = a.screenY || 0);
    this.button = a.button;
    this.key = a.key || "";
    this.ctrlKey = a.ctrlKey;
    this.altKey = a.altKey;
    this.shiftKey = a.shiftKey;
    this.metaKey = a.metaKey;
    this.pointerId = a.pointerId || 0;
    this.pointerType = "string" === typeof a.pointerType ? a.pointerType : Ga[a.pointerType] || "";
    this.state = a.state;
    this.i = a;
    a.defaultPrevented && A.$.h.call(this);
  }
}
r(A, w);
var Ga = {
  2: "touch",
  3: "pen",
  4: "mouse"
};
A.prototype.h = function () {
  A.$.h.call(this);
  var a = this.i;
  a.preventDefault ? a.preventDefault() : a.returnValue = !1;
};
var Ha = "closure_listenable_" + (1E6 * Math.random() | 0);
var Ia = 0;
function Ja(a, b, c, d, e) {
  this.listener = a;
  this.proxy = null;
  this.src = b;
  this.type = c;
  this.capture = !!d;
  this.la = e;
  this.key = ++Ia;
  this.fa = this.ia = !1;
}
function Ka(a) {
  a.fa = !0;
  a.listener = null;
  a.proxy = null;
  a.src = null;
  a.la = null;
}
function Na(a, b, c) {
  for (const d in a) b.call(c, a[d], d, a);
}
function Oa(a, b) {
  for (const c in a) b.call(void 0, a[c], c, a);
}
function Pa(a) {
  const b = {};
  for (const c in a) b[c] = a[c];
  return b;
}
const Qa = "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
function Ra(a, b) {
  let c, d;
  for (let e = 1; e < arguments.length; e++) {
    d = arguments[e];
    for (c in d) a[c] = d[c];
    for (let f = 0; f < Qa.length; f++) c = Qa[f], Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c]);
  }
}
function Sa(a) {
  this.src = a;
  this.g = {};
  this.h = 0;
}
Sa.prototype.add = function (a, b, c, d, e) {
  var f = a.toString();
  a = this.g[f];
  a || (a = this.g[f] = [], this.h++);
  var h = Ta(a, b, d, e);
  -1 < h ? (b = a[h], c || (b.ia = !1)) : (b = new Ja(b, this.src, f, !!d, e), b.ia = c, a.push(b));
  return b;
};
function Ua(a, b) {
  var c = b.type;
  if (c in a.g) {
    var d = a.g[c],
      e = ka(d, b),
      f;
    (f = 0 <= e) && Array.prototype.splice.call(d, e, 1);
    f && (Ka(b), 0 == a.g[c].length && (delete a.g[c], a.h--));
  }
}
function Ta(a, b, c, d) {
  for (var e = 0; e < a.length; ++e) {
    var f = a[e];
    if (!f.fa && f.listener == b && f.capture == !!c && f.la == d) return e;
  }
  return -1;
}
var Va = "closure_lm_" + (1E6 * Math.random() | 0),
  Wa = {};
function Ya(a, b, c, d, e) {
  if (d && d.once) return Za(a, b, c, d, e);
  if (Array.isArray(b)) {
    for (var f = 0; f < b.length; f++) Ya(a, b[f], c, d, e);
    return null;
  }
  c = $a(c);
  return a && a[Ha] ? a.O(b, c, p(d) ? !!d.capture : !!d, e) : ab(a, b, c, !1, d, e);
}
function ab(a, b, c, d, e, f) {
  if (!b) throw Error("Invalid event type");
  var h = p(e) ? !!e.capture : !!e,
    n = bb(a);
  n || (a[Va] = n = new Sa(a));
  c = n.add(b, c, d, h, f);
  if (c.proxy) return c;
  d = cb();
  c.proxy = d;
  d.src = a;
  d.listener = c;
  if (a.addEventListener) oa || (e = h), void 0 === e && (e = !1), a.addEventListener(b.toString(), d, e);else if (a.attachEvent) a.attachEvent(db(b.toString()), d);else if (a.addListener && a.removeListener) a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");
  return c;
}
function cb() {
  function a(c) {
    return b.call(a.src, a.listener, c);
  }
  const b = eb;
  return a;
}
function Za(a, b, c, d, e) {
  if (Array.isArray(b)) {
    for (var f = 0; f < b.length; f++) Za(a, b[f], c, d, e);
    return null;
  }
  c = $a(c);
  return a && a[Ha] ? a.P(b, c, p(d) ? !!d.capture : !!d, e) : ab(a, b, c, !0, d, e);
}
function fb(a, b, c, d, e) {
  if (Array.isArray(b)) for (var f = 0; f < b.length; f++) fb(a, b[f], c, d, e);else (d = p(d) ? !!d.capture : !!d, c = $a(c), a && a[Ha]) ? (a = a.i, b = String(b).toString(), b in a.g && (f = a.g[b], c = Ta(f, c, d, e), -1 < c && (Ka(f[c]), Array.prototype.splice.call(f, c, 1), 0 == f.length && (delete a.g[b], a.h--)))) : a && (a = bb(a)) && (b = a.g[b.toString()], a = -1, b && (a = Ta(b, c, d, e)), (c = -1 < a ? b[a] : null) && gb(c));
}
function gb(a) {
  if ("number" !== typeof a && a && !a.fa) {
    var b = a.src;
    if (b && b[Ha]) Ua(b.i, a);else {
      var c = a.type,
        d = a.proxy;
      b.removeEventListener ? b.removeEventListener(c, d, a.capture) : b.detachEvent ? b.detachEvent(db(c), d) : b.addListener && b.removeListener && b.removeListener(d);
      (c = bb(b)) ? (Ua(c, a), 0 == c.h && (c.src = null, b[Va] = null)) : Ka(a);
    }
  }
}
function db(a) {
  return a in Wa ? Wa[a] : Wa[a] = "on" + a;
}
function eb(a, b) {
  if (a.fa) a = !0;else {
    b = new A(b, this);
    var c = a.listener,
      d = a.la || a.src;
    a.ia && gb(a);
    a = c.call(d, b);
  }
  return a;
}
function bb(a) {
  a = a[Va];
  return a instanceof Sa ? a : null;
}
var hb = "__closure_events_fn_" + (1E9 * Math.random() >>> 0);
function $a(a) {
  if ("function" === typeof a) return a;
  a[hb] || (a[hb] = function (b) {
    return a.handleEvent(b);
  });
  return a[hb];
}
function B() {
  v.call(this);
  this.i = new Sa(this);
  this.S = this;
  this.J = null;
}
r(B, v);
B.prototype[Ha] = !0;
B.prototype.removeEventListener = function (a, b, c, d) {
  fb(this, a, b, c, d);
};
function C(a, b) {
  var c,
    d = a.J;
  if (d) for (c = []; d; d = d.J) c.push(d);
  a = a.S;
  d = b.type || b;
  if ("string" === typeof b) b = new w(b, a);else if (b instanceof w) b.target = b.target || a;else {
    var e = b;
    b = new w(d, a);
    Ra(b, e);
  }
  e = !0;
  if (c) for (var f = c.length - 1; 0 <= f; f--) {
    var h = b.g = c[f];
    e = ib(h, d, !0, b) && e;
  }
  h = b.g = a;
  e = ib(h, d, !0, b) && e;
  e = ib(h, d, !1, b) && e;
  if (c) for (f = 0; f < c.length; f++) h = b.g = c[f], e = ib(h, d, !1, b) && e;
}
B.prototype.N = function () {
  B.$.N.call(this);
  if (this.i) {
    var a = this.i,
      c;
    for (c in a.g) {
      for (var d = a.g[c], e = 0; e < d.length; e++) Ka(d[e]);
      delete a.g[c];
      a.h--;
    }
  }
  this.J = null;
};
B.prototype.O = function (a, b, c, d) {
  return this.i.add(String(a), b, !1, c, d);
};
B.prototype.P = function (a, b, c, d) {
  return this.i.add(String(a), b, !0, c, d);
};
function ib(a, b, c, d) {
  b = a.i.g[String(b)];
  if (!b) return !0;
  b = b.concat();
  for (var e = !0, f = 0; f < b.length; ++f) {
    var h = b[f];
    if (h && !h.fa && h.capture == c) {
      var n = h.listener,
        t = h.la || h.src;
      h.ia && Ua(a.i, h);
      e = !1 !== n.call(t, d) && e;
    }
  }
  return e && !d.defaultPrevented;
}
var jb = l.JSON.stringify;
class kb {
  constructor(a, b) {
    this.i = a;
    this.j = b;
    this.h = 0;
    this.g = null;
  }
  get() {
    let a;
    0 < this.h ? (this.h--, a = this.g, this.g = a.next, a.next = null) : a = this.i();
    return a;
  }
}
function lb() {
  var a = mb;
  let b = null;
  a.g && (b = a.g, a.g = a.g.next, a.g || (a.h = null), b.next = null);
  return b;
}
class nb {
  constructor() {
    this.h = this.g = null;
  }
  add(a, b) {
    const c = ob.get();
    c.set(a, b);
    this.h ? this.h.next = c : this.g = c;
    this.h = c;
  }
}
var ob = new kb(() => new pb(), a => a.reset());
class pb {
  constructor() {
    this.next = this.g = this.h = null;
  }
  set(a, b) {
    this.h = a;
    this.g = b;
    this.next = null;
  }
  reset() {
    this.next = this.g = this.h = null;
  }
}
function qb(a) {
  var b = 1;
  a = a.split(":");
  const c = [];
  for (; 0 < b && a.length;) c.push(a.shift()), b--;
  a.length && c.push(a.join(":"));
  return c;
}
function rb(a) {
  l.setTimeout(() => {
    throw a;
  }, 0);
}
let sb,
  tb = !1,
  mb = new nb(),
  vb = () => {
    const a = l.Promise.resolve(void 0);
    sb = () => {
      a.then(ub);
    };
  };
var ub = () => {
  for (var a; a = lb();) {
    try {
      a.h.call(a.g);
    } catch (c) {
      rb(c);
    }
    var b = ob;
    b.j(a);
    100 > b.h && (b.h++, a.next = b.g, b.g = a);
  }
  tb = !1;
};
function wb(a, b) {
  B.call(this);
  this.h = a || 1;
  this.g = b || l;
  this.j = q(this.qb, this);
  this.l = Date.now();
}
r(wb, B);
k = wb.prototype;
k.ga = !1;
k.T = null;
k.qb = function () {
  if (this.ga) {
    var a = Date.now() - this.l;
    0 < a && a < .8 * this.h ? this.T = this.g.setTimeout(this.j, this.h - a) : (this.T && (this.g.clearTimeout(this.T), this.T = null), C(this, "tick"), this.ga && (xb(this), this.start()));
  }
};
k.start = function () {
  this.ga = !0;
  this.T || (this.T = this.g.setTimeout(this.j, this.h), this.l = Date.now());
};
function xb(a) {
  a.ga = !1;
  a.T && (a.g.clearTimeout(a.T), a.T = null);
}
k.N = function () {
  wb.$.N.call(this);
  xb(this);
  delete this.g;
};
function yb(a, b, c) {
  if ("function" === typeof a) c && (a = q(a, c));else if (a && "function" == typeof a.handleEvent) a = q(a.handleEvent, a);else throw Error("Invalid listener argument");
  return 2147483647 < Number(b) ? -1 : l.setTimeout(a, b || 0);
}
function zb(a) {
  a.g = yb(() => {
    a.g = null;
    a.i && (a.i = !1, zb(a));
  }, a.j);
  const b = a.h;
  a.h = null;
  a.m.apply(null, b);
}
class Ab extends v {
  constructor(a, b) {
    super();
    this.m = a;
    this.j = b;
    this.h = null;
    this.i = !1;
    this.g = null;
  }
  l(a) {
    this.h = arguments;
    this.g ? this.i = !0 : zb(this);
  }
  N() {
    super.N();
    this.g && (l.clearTimeout(this.g), this.g = null, this.i = !1, this.h = null);
  }
}
function Bb(a) {
  v.call(this);
  this.h = a;
  this.g = {};
}
r(Bb, v);
var Cb = [];
function Db(a, b, c, d) {
  Array.isArray(c) || (c && (Cb[0] = c.toString()), c = Cb);
  for (var e = 0; e < c.length; e++) {
    var f = Ya(b, c[e], d || a.handleEvent, !1, a.h || a);
    if (!f) break;
    a.g[f.key] = f;
  }
}
function Fb(a) {
  Na(a.g, function (b, c) {
    this.g.hasOwnProperty(c) && gb(b);
  }, a);
  a.g = {};
}
Bb.prototype.N = function () {
  Bb.$.N.call(this);
  Fb(this);
};
Bb.prototype.handleEvent = function () {
  throw Error("EventHandler.handleEvent not implemented");
};
function Gb() {
  this.g = !0;
}
Gb.prototype.Ea = function () {
  this.g = !1;
};
function Hb(a, b, c, d, e, f) {
  a.info(function () {
    if (a.g) {
      if (f) {
        var h = "";
        for (var n = f.split("&"), t = 0; t < n.length; t++) {
          var m = n[t].split("=");
          if (1 < m.length) {
            var u = m[0];
            m = m[1];
            var L = u.split("_");
            h = 2 <= L.length && "type" == L[1] ? h + (u + "=" + m + "&") : h + (u + "=redacted&");
          }
        }
      } else h = null;
    } else h = f;
    return "XMLHTTP REQ (" + d + ") [attempt " + e + "]: " + b + "\n" + c + "\n" + h;
  });
}
function Ib(a, b, c, d, e, f, h) {
  a.info(function () {
    return "XMLHTTP RESP (" + d + ") [ attempt " + e + "]: " + b + "\n" + c + "\n" + f + " " + h;
  });
}
function D(a, b, c, d) {
  a.info(function () {
    return "XMLHTTP TEXT (" + b + "): " + Jb(a, c) + (d ? " " + d : "");
  });
}
function Kb(a, b) {
  a.info(function () {
    return "TIMEOUT: " + b;
  });
}
Gb.prototype.info = function () {};
function Jb(a, b) {
  if (!a.g) return b;
  if (!b) return null;
  try {
    var c = JSON.parse(b);
    if (c) for (a = 0; a < c.length; a++) if (Array.isArray(c[a])) {
      var d = c[a];
      if (!(2 > d.length)) {
        var e = d[1];
        if (Array.isArray(e) && !(1 > e.length)) {
          var f = e[0];
          if ("noop" != f && "stop" != f && "close" != f) for (var h = 1; h < e.length; h++) e[h] = "";
        }
      }
    }
    return jb(c);
  } catch (n) {
    return b;
  }
}
var E = {},
  Lb = null;
function Mb() {
  return Lb = Lb || new B();
}
E.Ta = "serverreachability";
function Nb(a) {
  w.call(this, E.Ta, a);
}
r(Nb, w);
function Ob(a) {
  const b = Mb();
  C(b, new Nb(b));
}
E.STAT_EVENT = "statevent";
function Pb(a, b) {
  w.call(this, E.STAT_EVENT, a);
  this.stat = b;
}
r(Pb, w);
function F(a) {
  const b = Mb();
  C(b, new Pb(b, a));
}
E.Ua = "timingevent";
function Qb(a, b) {
  w.call(this, E.Ua, a);
  this.size = b;
}
r(Qb, w);
function Rb(a, b) {
  if ("function" !== typeof a) throw Error("Fn must not be null and must be a function");
  return l.setTimeout(function () {
    a();
  }, b);
}
var Sb = {
  NO_ERROR: 0,
  rb: 1,
  Eb: 2,
  Db: 3,
  yb: 4,
  Cb: 5,
  Fb: 6,
  Qa: 7,
  TIMEOUT: 8,
  Ib: 9
};
var Tb = {
  wb: "complete",
  Sb: "success",
  Ra: "error",
  Qa: "abort",
  Kb: "ready",
  Lb: "readystatechange",
  TIMEOUT: "timeout",
  Gb: "incrementaldata",
  Jb: "progress",
  zb: "downloadprogress",
  $b: "uploadprogress"
};
function Ub() {}
Ub.prototype.h = null;
function Vb(a) {
  return a.h || (a.h = a.i());
}
function Wb() {}
var Xb = {
  OPEN: "a",
  vb: "b",
  Ra: "c",
  Hb: "d"
};
function Yb() {
  w.call(this, "d");
}
r(Yb, w);
function Zb() {
  w.call(this, "c");
}
r(Zb, w);
var $b;
function ac() {}
r(ac, Ub);
ac.prototype.g = function () {
  return new XMLHttpRequest();
};
ac.prototype.i = function () {
  return {};
};
$b = new ac();
function bc(a, b, c, d) {
  this.l = a;
  this.j = b;
  this.m = c;
  this.W = d || 1;
  this.U = new Bb(this);
  this.P = cc;
  a = va ? 125 : void 0;
  this.V = new wb(a);
  this.I = null;
  this.i = !1;
  this.s = this.A = this.v = this.L = this.G = this.Y = this.B = null;
  this.F = [];
  this.g = null;
  this.C = 0;
  this.o = this.u = null;
  this.ca = -1;
  this.J = !1;
  this.O = 0;
  this.M = null;
  this.ba = this.K = this.aa = this.S = !1;
  this.h = new dc();
}
function dc() {
  this.i = null;
  this.g = "";
  this.h = !1;
}
var cc = 45E3,
  ec = {},
  fc = {};
k = bc.prototype;
k.setTimeout = function (a) {
  this.P = a;
};
function gc(a, b, c) {
  a.L = 1;
  a.v = hc(G(b));
  a.s = c;
  a.S = !0;
  ic(a, null);
}
function ic(a, b) {
  a.G = Date.now();
  jc(a);
  a.A = G(a.v);
  var c = a.A,
    d = a.W;
  Array.isArray(d) || (d = [String(d)]);
  kc(c.i, "t", d);
  a.C = 0;
  c = a.l.J;
  a.h = new dc();
  a.g = lc(a.l, c ? b : null, !a.s);
  0 < a.O && (a.M = new Ab(q(a.Pa, a, a.g), a.O));
  Db(a.U, a.g, "readystatechange", a.nb);
  b = a.I ? Pa(a.I) : {};
  a.s ? (a.u || (a.u = "POST"), b["Content-Type"] = "application/x-www-form-urlencoded", a.g.ha(a.A, a.u, a.s, b)) : (a.u = "GET", a.g.ha(a.A, a.u, null, b));
  Ob();
  Hb(a.j, a.u, a.A, a.m, a.W, a.s);
}
k.nb = function (a) {
  a = a.target;
  const b = this.M;
  b && 3 == H(a) ? b.l() : this.Pa(a);
};
k.Pa = function (a) {
  try {
    if (a == this.g) a: {
      const u = H(this.g);
      var b = this.g.Ia();
      const L = this.g.da();
      if (!(3 > u) && (3 != u || va || this.g && (this.h.h || this.g.ja() || mc(this.g)))) {
        this.J || 4 != u || 7 == b || (8 == b || 0 >= L ? Ob(3) : Ob(2));
        nc(this);
        var c = this.g.da();
        this.ca = c;
        b: if (oc(this)) {
          var d = mc(this.g);
          a = "";
          var e = d.length,
            f = 4 == H(this.g);
          if (!this.h.i) {
            if ("undefined" === typeof TextDecoder) {
              I(this);
              pc(this);
              var h = "";
              break b;
            }
            this.h.i = new l.TextDecoder();
          }
          for (b = 0; b < e; b++) this.h.h = !0, a += this.h.i.decode(d[b], {
            stream: f && b == e - 1
          });
          d.splice(0, e);
          this.h.g += a;
          this.C = 0;
          h = this.h.g;
        } else h = this.g.ja();
        this.i = 200 == c;
        Ib(this.j, this.u, this.A, this.m, this.W, u, c);
        if (this.i) {
          if (this.aa && !this.K) {
            b: {
              if (this.g) {
                var n,
                  t = this.g;
                if ((n = t.g ? t.g.getResponseHeader("X-HTTP-Initial-Response") : null) && !x(n)) {
                  var m = n;
                  break b;
                }
              }
              m = null;
            }
            if (c = m) D(this.j, this.m, c, "Initial handshake response via X-HTTP-Initial-Response"), this.K = !0, qc(this, c);else {
              this.i = !1;
              this.o = 3;
              F(12);
              I(this);
              pc(this);
              break a;
            }
          }
          this.S ? (rc(this, u, h), va && this.i && 3 == u && (Db(this.U, this.V, "tick", this.mb), this.V.start())) : (D(this.j, this.m, h, null), qc(this, h));
          4 == u && I(this);
          this.i && !this.J && (4 == u ? sc(this.l, this) : (this.i = !1, jc(this)));
        } else tc(this.g), 400 == c && 0 < h.indexOf("Unknown SID") ? (this.o = 3, F(12)) : (this.o = 0, F(13)), I(this), pc(this);
      }
    }
  } catch (u) {} finally {}
};
function oc(a) {
  return a.g ? "GET" == a.u && 2 != a.L && a.l.Ha : !1;
}
function rc(a, b, c) {
  let d = !0,
    e;
  for (; !a.J && a.C < c.length;) if (e = uc(a, c), e == fc) {
    4 == b && (a.o = 4, F(14), d = !1);
    D(a.j, a.m, null, "[Incomplete Response]");
    break;
  } else if (e == ec) {
    a.o = 4;
    F(15);
    D(a.j, a.m, c, "[Invalid Chunk]");
    d = !1;
    break;
  } else D(a.j, a.m, e, null), qc(a, e);
  oc(a) && e != fc && e != ec && (a.h.g = "", a.C = 0);
  4 != b || 0 != c.length || a.h.h || (a.o = 1, F(16), d = !1);
  a.i = a.i && d;
  d ? 0 < c.length && !a.ba && (a.ba = !0, b = a.l, b.g == a && b.ca && !b.M && (b.l.info("Great, no buffering proxy detected. Bytes received: " + c.length), vc(b), b.M = !0, F(11))) : (D(a.j, a.m, c, "[Invalid Chunked Response]"), I(a), pc(a));
}
k.mb = function () {
  if (this.g) {
    var a = H(this.g),
      b = this.g.ja();
    this.C < b.length && (nc(this), rc(this, a, b), this.i && 4 != a && jc(this));
  }
};
function uc(a, b) {
  var c = a.C,
    d = b.indexOf("\n", c);
  if (-1 == d) return fc;
  c = Number(b.substring(c, d));
  if (isNaN(c)) return ec;
  d += 1;
  if (d + c > b.length) return fc;
  b = b.slice(d, d + c);
  a.C = d + c;
  return b;
}
k.cancel = function () {
  this.J = !0;
  I(this);
};
function jc(a) {
  a.Y = Date.now() + a.P;
  wc(a, a.P);
}
function wc(a, b) {
  if (null != a.B) throw Error("WatchDog timer not null");
  a.B = Rb(q(a.lb, a), b);
}
function nc(a) {
  a.B && (l.clearTimeout(a.B), a.B = null);
}
k.lb = function () {
  this.B = null;
  const a = Date.now();
  0 <= a - this.Y ? (Kb(this.j, this.A), 2 != this.L && (Ob(), F(17)), I(this), this.o = 2, pc(this)) : wc(this, this.Y - a);
};
function pc(a) {
  0 == a.l.H || a.J || sc(a.l, a);
}
function I(a) {
  nc(a);
  var b = a.M;
  b && "function" == typeof b.sa && b.sa();
  a.M = null;
  xb(a.V);
  Fb(a.U);
  a.g && (b = a.g, a.g = null, b.abort(), b.sa());
}
function qc(a, b) {
  try {
    var c = a.l;
    if (0 != c.H && (c.g == a || xc(c.i, a))) if (!a.K && xc(c.i, a) && 3 == c.H) {
      try {
        var d = c.Ja.g.parse(b);
      } catch (m) {
        d = null;
      }
      if (Array.isArray(d) && 3 == d.length) {
        var e = d;
        if (0 == e[0]) a: {
          if (!c.u) {
            if (c.g) if (c.g.G + 3E3 < a.G) yc(c), zc(c);else break a;
            Ac(c);
            F(18);
          }
        } else c.Fa = e[1], 0 < c.Fa - c.V && 37500 > e[2] && c.G && 0 == c.A && !c.v && (c.v = Rb(q(c.ib, c), 6E3));
        if (1 >= Bc(c.i) && c.oa) {
          try {
            c.oa();
          } catch (m) {}
          c.oa = void 0;
        }
      } else J(c, 11);
    } else if ((a.K || c.g == a) && yc(c), !x(b)) for (e = c.Ja.g.parse(b), b = 0; b < e.length; b++) {
      let m = e[b];
      c.V = m[0];
      m = m[1];
      if (2 == c.H) {
        if ("c" == m[0]) {
          c.K = m[1];
          c.pa = m[2];
          const u = m[3];
          null != u && (c.ra = u, c.l.info("VER=" + c.ra));
          const L = m[4];
          null != L && (c.Ga = L, c.l.info("SVER=" + c.Ga));
          const La = m[5];
          null != La && "number" === typeof La && 0 < La && (d = 1.5 * La, c.L = d, c.l.info("backChannelRequestTimeoutMs_=" + d));
          d = c;
          const la = a.g;
          if (la) {
            const Ma = la.g ? la.g.getResponseHeader("X-Client-Wire-Protocol") : null;
            if (Ma) {
              var f = d.i;
              f.g || -1 == Ma.indexOf("spdy") && -1 == Ma.indexOf("quic") && -1 == Ma.indexOf("h2") || (f.j = f.l, f.g = new Set(), f.h && (Cc(f, f.h), f.h = null));
            }
            if (d.F) {
              const Eb = la.g ? la.g.getResponseHeader("X-HTTP-Session-Id") : null;
              Eb && (d.Da = Eb, K(d.I, d.F, Eb));
            }
          }
          c.H = 3;
          c.h && c.h.Ba();
          c.ca && (c.S = Date.now() - a.G, c.l.info("Handshake RTT: " + c.S + "ms"));
          d = c;
          var h = a;
          d.wa = Dc(d, d.J ? d.pa : null, d.Y);
          if (h.K) {
            Ec(d.i, h);
            var n = h,
              t = d.L;
            t && n.setTimeout(t);
            n.B && (nc(n), jc(n));
            d.g = h;
          } else Fc(d);
          0 < c.j.length && Gc(c);
        } else "stop" != m[0] && "close" != m[0] || J(c, 7);
      } else 3 == c.H && ("stop" == m[0] || "close" == m[0] ? "stop" == m[0] ? J(c, 7) : Hc(c) : "noop" != m[0] && c.h && c.h.Aa(m), c.A = 0);
    }
    Ob(4);
  } catch (m) {}
}
function Ic(a) {
  if (a.Z && "function" == typeof a.Z) return a.Z();
  if ("undefined" !== typeof Map && a instanceof Map || "undefined" !== typeof Set && a instanceof Set) return Array.from(a.values());
  if ("string" === typeof a) return a.split("");
  if (aa(a)) {
    for (var b = [], c = a.length, d = 0; d < c; d++) b.push(a[d]);
    return b;
  }
  b = [];
  c = 0;
  for (d in a) b[c++] = a[d];
  return b;
}
function Jc(a) {
  if (a.ta && "function" == typeof a.ta) return a.ta();
  if (!a.Z || "function" != typeof a.Z) {
    if ("undefined" !== typeof Map && a instanceof Map) return Array.from(a.keys());
    if (!("undefined" !== typeof Set && a instanceof Set)) {
      if (aa(a) || "string" === typeof a) {
        var b = [];
        a = a.length;
        for (var c = 0; c < a; c++) b.push(c);
        return b;
      }
      b = [];
      c = 0;
      for (const d in a) b[c++] = d;
      return b;
    }
  }
}
function Kc(a, b) {
  if (a.forEach && "function" == typeof a.forEach) a.forEach(b, void 0);else if (aa(a) || "string" === typeof a) Array.prototype.forEach.call(a, b, void 0);else for (var c = Jc(a), d = Ic(a), e = d.length, f = 0; f < e; f++) b.call(void 0, d[f], c && c[f], a);
}
var Lc = RegExp("^(?:([^:/?#.]+):)?(?://(?:([^\\\\/?#]*)@)?([^\\\\/?#]*?)(?::([0-9]+))?(?=[\\\\/?#]|$))?([^?#]+)?(?:\\?([^#]*))?(?:#([\\s\\S]*))?$");
function Mc(a, b) {
  if (a) {
    a = a.split("&");
    for (var c = 0; c < a.length; c++) {
      var d = a[c].indexOf("="),
        e = null;
      if (0 <= d) {
        var f = a[c].substring(0, d);
        e = a[c].substring(d + 1);
      } else f = a[c];
      b(f, e ? decodeURIComponent(e.replace(/\+/g, " ")) : "");
    }
  }
}
function M(a) {
  this.g = this.s = this.j = "";
  this.m = null;
  this.o = this.l = "";
  this.h = !1;
  if (a instanceof M) {
    this.h = a.h;
    Nc(this, a.j);
    this.s = a.s;
    this.g = a.g;
    Oc(this, a.m);
    this.l = a.l;
    var b = a.i;
    var c = new Pc();
    c.i = b.i;
    b.g && (c.g = new Map(b.g), c.h = b.h);
    Qc(this, c);
    this.o = a.o;
  } else a && (b = String(a).match(Lc)) ? (this.h = !1, Nc(this, b[1] || "", !0), this.s = Rc(b[2] || ""), this.g = Rc(b[3] || "", !0), Oc(this, b[4]), this.l = Rc(b[5] || "", !0), Qc(this, b[6] || "", !0), this.o = Rc(b[7] || "")) : (this.h = !1, this.i = new Pc(null, this.h));
}
M.prototype.toString = function () {
  var a = [],
    b = this.j;
  b && a.push(Sc(b, Tc, !0), ":");
  var c = this.g;
  if (c || "file" == b) a.push("//"), (b = this.s) && a.push(Sc(b, Tc, !0), "@"), a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g, "%$1")), c = this.m, null != c && a.push(":", String(c));
  if (c = this.l) this.g && "/" != c.charAt(0) && a.push("/"), a.push(Sc(c, "/" == c.charAt(0) ? Uc : Vc, !0));
  (c = this.i.toString()) && a.push("?", c);
  (c = this.o) && a.push("#", Sc(c, Wc));
  return a.join("");
};
function G(a) {
  return new M(a);
}
function Nc(a, b, c) {
  a.j = c ? Rc(b, !0) : b;
  a.j && (a.j = a.j.replace(/:$/, ""));
}
function Oc(a, b) {
  if (b) {
    b = Number(b);
    if (isNaN(b) || 0 > b) throw Error("Bad port number " + b);
    a.m = b;
  } else a.m = null;
}
function Qc(a, b, c) {
  b instanceof Pc ? (a.i = b, Xc(a.i, a.h)) : (c || (b = Sc(b, Yc)), a.i = new Pc(b, a.h));
}
function K(a, b, c) {
  a.i.set(b, c);
}
function hc(a) {
  K(a, "zx", Math.floor(2147483648 * Math.random()).toString(36) + Math.abs(Math.floor(2147483648 * Math.random()) ^ Date.now()).toString(36));
  return a;
}
function Rc(a, b) {
  return a ? b ? decodeURI(a.replace(/%25/g, "%2525")) : decodeURIComponent(a) : "";
}
function Sc(a, b, c) {
  return "string" === typeof a ? (a = encodeURI(a).replace(b, Zc), c && (a = a.replace(/%25([0-9a-fA-F]{2})/g, "%$1")), a) : null;
}
function Zc(a) {
  a = a.charCodeAt(0);
  return "%" + (a >> 4 & 15).toString(16) + (a & 15).toString(16);
}
var Tc = /[#\/\?@]/g,
  Vc = /[#\?:]/g,
  Uc = /[#\?]/g,
  Yc = /[#\?@]/g,
  Wc = /#/g;
function Pc(a, b) {
  this.h = this.g = null;
  this.i = a || null;
  this.j = !!b;
}
function N(a) {
  a.g || (a.g = new Map(), a.h = 0, a.i && Mc(a.i, function (b, c) {
    a.add(decodeURIComponent(b.replace(/\+/g, " ")), c);
  }));
}
k = Pc.prototype;
k.add = function (a, b) {
  N(this);
  this.i = null;
  a = O(this, a);
  var c = this.g.get(a);
  c || this.g.set(a, c = []);
  c.push(b);
  this.h += 1;
  return this;
};
function $c(a, b) {
  N(a);
  b = O(a, b);
  a.g.has(b) && (a.i = null, a.h -= a.g.get(b).length, a.g.delete(b));
}
function ad(a, b) {
  N(a);
  b = O(a, b);
  return a.g.has(b);
}
k.forEach = function (a, b) {
  N(this);
  this.g.forEach(function (c, d) {
    c.forEach(function (e) {
      a.call(b, e, d, this);
    }, this);
  }, this);
};
k.ta = function () {
  N(this);
  const a = Array.from(this.g.values()),
    b = Array.from(this.g.keys()),
    c = [];
  for (let d = 0; d < b.length; d++) {
    const e = a[d];
    for (let f = 0; f < e.length; f++) c.push(b[d]);
  }
  return c;
};
k.Z = function (a) {
  N(this);
  let b = [];
  if ("string" === typeof a) ad(this, a) && (b = b.concat(this.g.get(O(this, a))));else {
    a = Array.from(this.g.values());
    for (let c = 0; c < a.length; c++) b = b.concat(a[c]);
  }
  return b;
};
k.set = function (a, b) {
  N(this);
  this.i = null;
  a = O(this, a);
  ad(this, a) && (this.h -= this.g.get(a).length);
  this.g.set(a, [b]);
  this.h += 1;
  return this;
};
k.get = function (a, b) {
  if (!a) return b;
  a = this.Z(a);
  return 0 < a.length ? String(a[0]) : b;
};
function kc(a, b, c) {
  $c(a, b);
  0 < c.length && (a.i = null, a.g.set(O(a, b), ma(c)), a.h += c.length);
}
k.toString = function () {
  if (this.i) return this.i;
  if (!this.g) return "";
  const a = [],
    b = Array.from(this.g.keys());
  for (var c = 0; c < b.length; c++) {
    var d = b[c];
    const f = encodeURIComponent(String(d)),
      h = this.Z(d);
    for (d = 0; d < h.length; d++) {
      var e = f;
      "" !== h[d] && (e += "=" + encodeURIComponent(String(h[d])));
      a.push(e);
    }
  }
  return this.i = a.join("&");
};
function O(a, b) {
  b = String(b);
  a.j && (b = b.toLowerCase());
  return b;
}
function Xc(a, b) {
  b && !a.j && (N(a), a.i = null, a.g.forEach(function (c, d) {
    var e = d.toLowerCase();
    d != e && ($c(this, d), kc(this, e, c));
  }, a));
  a.j = b;
}
var bd = class {
  constructor(a, b) {
    this.g = a;
    this.map = b;
  }
};
function cd(a) {
  this.l = a || dd;
  l.PerformanceNavigationTiming ? (a = l.performance.getEntriesByType("navigation"), a = 0 < a.length && ("hq" == a[0].nextHopProtocol || "h2" == a[0].nextHopProtocol)) : a = !!(l.g && l.g.Ka && l.g.Ka() && l.g.Ka().ec);
  this.j = a ? this.l : 1;
  this.g = null;
  1 < this.j && (this.g = new Set());
  this.h = null;
  this.i = [];
}
var dd = 10;
function ed(a) {
  return a.h ? !0 : a.g ? a.g.size >= a.j : !1;
}
function Bc(a) {
  return a.h ? 1 : a.g ? a.g.size : 0;
}
function xc(a, b) {
  return a.h ? a.h == b : a.g ? a.g.has(b) : !1;
}
function Cc(a, b) {
  a.g ? a.g.add(b) : a.h = b;
}
function Ec(a, b) {
  a.h && a.h == b ? a.h = null : a.g && a.g.has(b) && a.g.delete(b);
}
cd.prototype.cancel = function () {
  this.i = fd(this);
  if (this.h) this.h.cancel(), this.h = null;else if (this.g && 0 !== this.g.size) {
    for (const a of this.g.values()) a.cancel();
    this.g.clear();
  }
};
function fd(a) {
  if (null != a.h) return a.i.concat(a.h.F);
  if (null != a.g && 0 !== a.g.size) {
    let b = a.i;
    for (const c of a.g.values()) b = b.concat(c.F);
    return b;
  }
  return ma(a.i);
}
var gd = class {
  stringify(a) {
    return l.JSON.stringify(a, void 0);
  }
  parse(a) {
    return l.JSON.parse(a, void 0);
  }
};
function hd() {
  this.g = new gd();
}
function id(a, b, c) {
  const d = c || "";
  try {
    Kc(a, function (e, f) {
      let h = e;
      p(e) && (h = jb(e));
      b.push(d + f + "=" + encodeURIComponent(h));
    });
  } catch (e) {
    throw b.push(d + "type=" + encodeURIComponent("_badmap")), e;
  }
}
function jd(a, b) {
  const c = new Gb();
  if (l.Image) {
    const d = new Image();
    d.onload = ha(kd, c, d, "TestLoadImage: loaded", !0, b);
    d.onerror = ha(kd, c, d, "TestLoadImage: error", !1, b);
    d.onabort = ha(kd, c, d, "TestLoadImage: abort", !1, b);
    d.ontimeout = ha(kd, c, d, "TestLoadImage: timeout", !1, b);
    l.setTimeout(function () {
      if (d.ontimeout) d.ontimeout();
    }, 1E4);
    d.src = a;
  } else b(!1);
}
function kd(a, b, c, d, e) {
  try {
    b.onload = null, b.onerror = null, b.onabort = null, b.ontimeout = null, e(d);
  } catch (f) {}
}
function ld(a) {
  this.l = a.fc || null;
  this.j = a.ob || !1;
}
r(ld, Ub);
ld.prototype.g = function () {
  return new md(this.l, this.j);
};
ld.prototype.i = function (a) {
  return function () {
    return a;
  };
}({});
function md(a, b) {
  B.call(this);
  this.F = a;
  this.u = b;
  this.m = void 0;
  this.readyState = nd;
  this.status = 0;
  this.responseType = this.responseText = this.response = this.statusText = "";
  this.onreadystatechange = null;
  this.v = new Headers();
  this.h = null;
  this.C = "GET";
  this.B = "";
  this.g = !1;
  this.A = this.j = this.l = null;
}
r(md, B);
var nd = 0;
k = md.prototype;
k.open = function (a, b) {
  if (this.readyState != nd) throw this.abort(), Error("Error reopening a connection");
  this.C = a;
  this.B = b;
  this.readyState = 1;
  od(this);
};
k.send = function (a) {
  if (1 != this.readyState) throw this.abort(), Error("need to call open() first. ");
  this.g = !0;
  const b = {
    headers: this.v,
    method: this.C,
    credentials: this.m,
    cache: void 0
  };
  a && (b.body = a);
  (this.F || l).fetch(new Request(this.B, b)).then(this.$a.bind(this), this.ka.bind(this));
};
k.abort = function () {
  this.response = this.responseText = "";
  this.v = new Headers();
  this.status = 0;
  this.j && this.j.cancel("Request was aborted.").catch(() => {});
  1 <= this.readyState && this.g && 4 != this.readyState && (this.g = !1, pd(this));
  this.readyState = nd;
};
k.$a = function (a) {
  if (this.g && (this.l = a, this.h || (this.status = this.l.status, this.statusText = this.l.statusText, this.h = a.headers, this.readyState = 2, od(this)), this.g && (this.readyState = 3, od(this), this.g))) if ("arraybuffer" === this.responseType) a.arrayBuffer().then(this.Ya.bind(this), this.ka.bind(this));else if ("undefined" !== typeof l.ReadableStream && "body" in a) {
    this.j = a.body.getReader();
    if (this.u) {
      if (this.responseType) throw Error('responseType must be empty for "streamBinaryChunks" mode responses.');
      this.response = [];
    } else this.response = this.responseText = "", this.A = new TextDecoder();
    qd(this);
  } else a.text().then(this.Za.bind(this), this.ka.bind(this));
};
function qd(a) {
  a.j.read().then(a.Xa.bind(a)).catch(a.ka.bind(a));
}
k.Xa = function (a) {
  if (this.g) {
    if (this.u && a.value) this.response.push(a.value);else if (!this.u) {
      var b = a.value ? a.value : new Uint8Array(0);
      if (b = this.A.decode(b, {
        stream: !a.done
      })) this.response = this.responseText += b;
    }
    a.done ? pd(this) : od(this);
    3 == this.readyState && qd(this);
  }
};
k.Za = function (a) {
  this.g && (this.response = this.responseText = a, pd(this));
};
k.Ya = function (a) {
  this.g && (this.response = a, pd(this));
};
k.ka = function () {
  this.g && pd(this);
};
function pd(a) {
  a.readyState = 4;
  a.l = null;
  a.j = null;
  a.A = null;
  od(a);
}
k.setRequestHeader = function (a, b) {
  this.v.append(a, b);
};
k.getResponseHeader = function (a) {
  return this.h ? this.h.get(a.toLowerCase()) || "" : "";
};
k.getAllResponseHeaders = function () {
  if (!this.h) return "";
  const a = [],
    b = this.h.entries();
  for (var c = b.next(); !c.done;) c = c.value, a.push(c[0] + ": " + c[1]), c = b.next();
  return a.join("\r\n");
};
function od(a) {
  a.onreadystatechange && a.onreadystatechange.call(a);
}
Object.defineProperty(md.prototype, "withCredentials", {
  get: function () {
    return "include" === this.m;
  },
  set: function (a) {
    this.m = a ? "include" : "same-origin";
  }
});
var rd = l.JSON.parse;
function P(a) {
  B.call(this);
  this.headers = new Map();
  this.u = a || null;
  this.h = !1;
  this.C = this.g = null;
  this.I = "";
  this.m = 0;
  this.j = "";
  this.l = this.G = this.v = this.F = !1;
  this.B = 0;
  this.A = null;
  this.K = sd;
  this.L = this.M = !1;
}
r(P, B);
var sd = "",
  td = /^https?$/i,
  ud = ["POST", "PUT"];
k = P.prototype;
k.Oa = function (a) {
  this.M = a;
};
k.ha = function (a, b, c, d) {
  if (this.g) throw Error("[goog.net.XhrIo] Object is active with another request=" + this.I + "; newUri=" + a);
  b = b ? b.toUpperCase() : "GET";
  this.I = a;
  this.j = "";
  this.m = 0;
  this.F = !1;
  this.h = !0;
  this.g = this.u ? this.u.g() : $b.g();
  this.C = this.u ? Vb(this.u) : Vb($b);
  this.g.onreadystatechange = q(this.La, this);
  try {
    this.G = !0, this.g.open(b, String(a), !0), this.G = !1;
  } catch (f) {
    vd(this, f);
    return;
  }
  a = c || "";
  c = new Map(this.headers);
  if (d) if (Object.getPrototypeOf(d) === Object.prototype) for (var e in d) c.set(e, d[e]);else if ("function" === typeof d.keys && "function" === typeof d.get) for (const f of d.keys()) c.set(f, d.get(f));else throw Error("Unknown input type for opt_headers: " + String(d));
  d = Array.from(c.keys()).find(f => "content-type" == f.toLowerCase());
  e = l.FormData && a instanceof l.FormData;
  !(0 <= ka(ud, b)) || d || e || c.set("Content-Type", "application/x-www-form-urlencoded;charset=utf-8");
  for (const [f, h] of c) this.g.setRequestHeader(f, h);
  this.K && (this.g.responseType = this.K);
  "withCredentials" in this.g && this.g.withCredentials !== this.M && (this.g.withCredentials = this.M);
  try {
    wd(this), 0 < this.B && ((this.L = xd(this.g)) ? (this.g.timeout = this.B, this.g.ontimeout = q(this.ua, this)) : this.A = yb(this.ua, this.B, this)), this.v = !0, this.g.send(a), this.v = !1;
  } catch (f) {
    vd(this, f);
  }
};
function xd(a) {
  return z && "number" === typeof a.timeout && void 0 !== a.ontimeout;
}
k.ua = function () {
  "undefined" != typeof goog && this.g && (this.j = "Timed out after " + this.B + "ms, aborting", this.m = 8, C(this, "timeout"), this.abort(8));
};
function vd(a, b) {
  a.h = !1;
  a.g && (a.l = !0, a.g.abort(), a.l = !1);
  a.j = b;
  a.m = 5;
  yd(a);
  zd(a);
}
function yd(a) {
  a.F || (a.F = !0, C(a, "complete"), C(a, "error"));
}
k.abort = function (a) {
  this.g && this.h && (this.h = !1, this.l = !0, this.g.abort(), this.l = !1, this.m = a || 7, C(this, "complete"), C(this, "abort"), zd(this));
};
k.N = function () {
  this.g && (this.h && (this.h = !1, this.l = !0, this.g.abort(), this.l = !1), zd(this, !0));
  P.$.N.call(this);
};
k.La = function () {
  this.s || (this.G || this.v || this.l ? Ad(this) : this.kb());
};
k.kb = function () {
  Ad(this);
};
function Ad(a) {
  if (a.h && "undefined" != typeof goog && (!a.C[1] || 4 != H(a) || 2 != a.da())) if (a.v && 4 == H(a)) yb(a.La, 0, a);else if (C(a, "readystatechange"), 4 == H(a)) {
    a.h = !1;
    try {
      const h = a.da();
      a: switch (h) {
        case 200:
        case 201:
        case 202:
        case 204:
        case 206:
        case 304:
        case 1223:
          var b = !0;
          break a;
        default:
          b = !1;
      }
      var c;
      if (!(c = b)) {
        var d;
        if (d = 0 === h) {
          var e = String(a.I).match(Lc)[1] || null;
          !e && l.self && l.self.location && (e = l.self.location.protocol.slice(0, -1));
          d = !td.test(e ? e.toLowerCase() : "");
        }
        c = d;
      }
      if (c) C(a, "complete"), C(a, "success");else {
        a.m = 6;
        try {
          var f = 2 < H(a) ? a.g.statusText : "";
        } catch (n) {
          f = "";
        }
        a.j = f + " [" + a.da() + "]";
        yd(a);
      }
    } finally {
      zd(a);
    }
  }
}
function zd(a, b) {
  if (a.g) {
    wd(a);
    const c = a.g,
      d = a.C[0] ? () => {} : null;
    a.g = null;
    a.C = null;
    b || C(a, "ready");
    try {
      c.onreadystatechange = d;
    } catch (e) {}
  }
}
function wd(a) {
  a.g && a.L && (a.g.ontimeout = null);
  a.A && (l.clearTimeout(a.A), a.A = null);
}
k.isActive = function () {
  return !!this.g;
};
function H(a) {
  return a.g ? a.g.readyState : 0;
}
k.da = function () {
  try {
    return 2 < H(this) ? this.g.status : -1;
  } catch (a) {
    return -1;
  }
};
k.ja = function () {
  try {
    return this.g ? this.g.responseText : "";
  } catch (a) {
    return "";
  }
};
k.Wa = function (a) {
  if (this.g) {
    var b = this.g.responseText;
    a && 0 == b.indexOf(a) && (b = b.substring(a.length));
    return rd(b);
  }
};
function mc(a) {
  try {
    if (!a.g) return null;
    if ("response" in a.g) return a.g.response;
    switch (a.K) {
      case sd:
      case "text":
        return a.g.responseText;
      case "arraybuffer":
        if ("mozResponseArrayBuffer" in a.g) return a.g.mozResponseArrayBuffer;
    }
    return null;
  } catch (b) {
    return null;
  }
}
function tc(a) {
  const b = {};
  a = (a.g && 2 <= H(a) ? a.g.getAllResponseHeaders() || "" : "").split("\r\n");
  for (let d = 0; d < a.length; d++) {
    if (x(a[d])) continue;
    var c = qb(a[d]);
    const e = c[0];
    c = c[1];
    if ("string" !== typeof c) continue;
    c = c.trim();
    const f = b[e] || [];
    b[e] = f;
    f.push(c);
  }
  Oa(b, function (d) {
    return d.join(", ");
  });
}
k.Ia = function () {
  return this.m;
};
k.Sa = function () {
  return "string" === typeof this.j ? this.j : String(this.j);
};
function Bd(a) {
  let b = "";
  Na(a, function (c, d) {
    b += d;
    b += ":";
    b += c;
    b += "\r\n";
  });
  return b;
}
function Cd(a, b, c) {
  a: {
    for (d in c) {
      var d = !1;
      break a;
    }
    d = !0;
  }
  d || (c = Bd(c), "string" === typeof a ? null != c && encodeURIComponent(String(c)) : K(a, b, c));
}
function Dd(a, b, c) {
  return c && c.internalChannelParams ? c.internalChannelParams[a] || b : b;
}
function Ed(a) {
  this.Ga = 0;
  this.j = [];
  this.l = new Gb();
  this.pa = this.wa = this.I = this.Y = this.g = this.Da = this.F = this.na = this.o = this.U = this.s = null;
  this.fb = this.W = 0;
  this.cb = Dd("failFast", !1, a);
  this.G = this.v = this.u = this.m = this.h = null;
  this.aa = !0;
  this.Fa = this.V = -1;
  this.ba = this.A = this.C = 0;
  this.ab = Dd("baseRetryDelayMs", 5E3, a);
  this.hb = Dd("retryDelaySeedMs", 1E4, a);
  this.eb = Dd("forwardChannelMaxRetries", 2, a);
  this.xa = Dd("forwardChannelRequestTimeoutMs", 2E4, a);
  this.va = a && a.xmlHttpFactory || void 0;
  this.Ha = a && a.dc || !1;
  this.L = void 0;
  this.J = a && a.supportsCrossDomainXhr || !1;
  this.K = "";
  this.i = new cd(a && a.concurrentRequestLimit);
  this.Ja = new hd();
  this.P = a && a.fastHandshake || !1;
  this.O = a && a.encodeInitMessageHeaders || !1;
  this.P && this.O && (this.O = !1);
  this.bb = a && a.bc || !1;
  a && a.Ea && this.l.Ea();
  a && a.forceLongPolling && (this.aa = !1);
  this.ca = !this.P && this.aa && a && a.detectBufferingProxy || !1;
  this.qa = void 0;
  a && a.longPollingTimeout && 0 < a.longPollingTimeout && (this.qa = a.longPollingTimeout);
  this.oa = void 0;
  this.S = 0;
  this.M = !1;
  this.ma = this.B = null;
}
k = Ed.prototype;
k.ra = 8;
k.H = 1;
function Hc(a) {
  Fd(a);
  if (3 == a.H) {
    var b = a.W++,
      c = G(a.I);
    K(c, "SID", a.K);
    K(c, "RID", b);
    K(c, "TYPE", "terminate");
    Gd(a, c);
    b = new bc(a, a.l, b);
    b.L = 2;
    b.v = hc(G(c));
    c = !1;
    if (l.navigator && l.navigator.sendBeacon) try {
      c = l.navigator.sendBeacon(b.v.toString(), "");
    } catch (d) {}
    !c && l.Image && (new Image().src = b.v, c = !0);
    c || (b.g = lc(b.l, null), b.g.ha(b.v));
    b.G = Date.now();
    jc(b);
  }
  Hd(a);
}
function zc(a) {
  a.g && (vc(a), a.g.cancel(), a.g = null);
}
function Fd(a) {
  zc(a);
  a.u && (l.clearTimeout(a.u), a.u = null);
  yc(a);
  a.i.cancel();
  a.m && ("number" === typeof a.m && l.clearTimeout(a.m), a.m = null);
}
function Gc(a) {
  if (!ed(a.i) && !a.m) {
    a.m = !0;
    var b = a.Na;
    sb || vb();
    tb || (sb(), tb = !0);
    mb.add(b, a);
    a.C = 0;
  }
}
function Id(a, b) {
  if (Bc(a.i) >= a.i.j - (a.m ? 1 : 0)) return !1;
  if (a.m) return a.j = b.F.concat(a.j), !0;
  if (1 == a.H || 2 == a.H || a.C >= (a.cb ? 0 : a.eb)) return !1;
  a.m = Rb(q(a.Na, a, b), Jd(a, a.C));
  a.C++;
  return !0;
}
k.Na = function (a) {
  if (this.m) if (this.m = null, 1 == this.H) {
    if (!a) {
      this.W = Math.floor(1E5 * Math.random());
      a = this.W++;
      const e = new bc(this, this.l, a);
      let f = this.s;
      this.U && (f ? (f = Pa(f), Ra(f, this.U)) : f = this.U);
      null !== this.o || this.O || (e.I = f, f = null);
      if (this.P) a: {
        var b = 0;
        for (var c = 0; c < this.j.length; c++) {
          b: {
            var d = this.j[c];
            if ("__data__" in d.map && (d = d.map.__data__, "string" === typeof d)) {
              d = d.length;
              break b;
            }
            d = void 0;
          }
          if (void 0 === d) break;
          b += d;
          if (4096 < b) {
            b = c;
            break a;
          }
          if (4096 === b || c === this.j.length - 1) {
            b = c + 1;
            break a;
          }
        }
        b = 1E3;
      } else b = 1E3;
      b = Kd(this, e, b);
      c = G(this.I);
      K(c, "RID", a);
      K(c, "CVER", 22);
      this.F && K(c, "X-HTTP-Session-Id", this.F);
      Gd(this, c);
      f && (this.O ? b = "headers=" + encodeURIComponent(String(Bd(f))) + "&" + b : this.o && Cd(c, this.o, f));
      Cc(this.i, e);
      this.bb && K(c, "TYPE", "init");
      this.P ? (K(c, "$req", b), K(c, "SID", "null"), e.aa = !0, gc(e, c, null)) : gc(e, c, b);
      this.H = 2;
    }
  } else 3 == this.H && (a ? Ld(this, a) : 0 == this.j.length || ed(this.i) || Ld(this));
};
function Ld(a, b) {
  var c;
  b ? c = b.m : c = a.W++;
  const d = G(a.I);
  K(d, "SID", a.K);
  K(d, "RID", c);
  K(d, "AID", a.V);
  Gd(a, d);
  a.o && a.s && Cd(d, a.o, a.s);
  c = new bc(a, a.l, c, a.C + 1);
  null === a.o && (c.I = a.s);
  b && (a.j = b.F.concat(a.j));
  b = Kd(a, c, 1E3);
  c.setTimeout(Math.round(.5 * a.xa) + Math.round(.5 * a.xa * Math.random()));
  Cc(a.i, c);
  gc(c, d, b);
}
function Gd(a, b) {
  a.na && Na(a.na, function (c, d) {
    K(b, d, c);
  });
  a.h && Kc({}, function (c, d) {
    K(b, d, c);
  });
}
function Kd(a, b, c) {
  c = Math.min(a.j.length, c);
  var d = a.h ? q(a.h.Va, a.h, a) : null;
  a: {
    var e = a.j;
    let f = -1;
    for (;;) {
      const h = ["count=" + c];
      -1 == f ? 0 < c ? (f = e[0].g, h.push("ofs=" + f)) : f = 0 : h.push("ofs=" + f);
      let n = !0;
      for (let t = 0; t < c; t++) {
        let m = e[t].g;
        const u = e[t].map;
        m -= f;
        if (0 > m) f = Math.max(0, e[t].g - 100), n = !1;else try {
          id(u, h, "req" + m + "_");
        } catch (L) {
          d && d(u);
        }
      }
      if (n) {
        d = h.join("&");
        break a;
      }
    }
  }
  a = a.j.splice(0, c);
  b.F = a;
  return d;
}
function Fc(a) {
  if (!a.g && !a.u) {
    a.ba = 1;
    var b = a.Ma;
    sb || vb();
    tb || (sb(), tb = !0);
    mb.add(b, a);
    a.A = 0;
  }
}
function Ac(a) {
  if (a.g || a.u || 3 <= a.A) return !1;
  a.ba++;
  a.u = Rb(q(a.Ma, a), Jd(a, a.A));
  a.A++;
  return !0;
}
k.Ma = function () {
  this.u = null;
  Md(this);
  if (this.ca && !(this.M || null == this.g || 0 >= this.S)) {
    var a = 2 * this.S;
    this.l.info("BP detection timer enabled: " + a);
    this.B = Rb(q(this.jb, this), a);
  }
};
k.jb = function () {
  this.B && (this.B = null, this.l.info("BP detection timeout reached."), this.l.info("Buffering proxy detected and switch to long-polling!"), this.G = !1, this.M = !0, F(10), zc(this), Md(this));
};
function vc(a) {
  null != a.B && (l.clearTimeout(a.B), a.B = null);
}
function Md(a) {
  a.g = new bc(a, a.l, "rpc", a.ba);
  null === a.o && (a.g.I = a.s);
  a.g.O = 0;
  var b = G(a.wa);
  K(b, "RID", "rpc");
  K(b, "SID", a.K);
  K(b, "AID", a.V);
  K(b, "CI", a.G ? "0" : "1");
  !a.G && a.qa && K(b, "TO", a.qa);
  K(b, "TYPE", "xmlhttp");
  Gd(a, b);
  a.o && a.s && Cd(b, a.o, a.s);
  a.L && a.g.setTimeout(a.L);
  var c = a.g;
  a = a.pa;
  c.L = 1;
  c.v = hc(G(b));
  c.s = null;
  c.S = !0;
  ic(c, a);
}
k.ib = function () {
  null != this.v && (this.v = null, zc(this), Ac(this), F(19));
};
function yc(a) {
  null != a.v && (l.clearTimeout(a.v), a.v = null);
}
function sc(a, b) {
  var c = null;
  if (a.g == b) {
    yc(a);
    vc(a);
    a.g = null;
    var d = 2;
  } else if (xc(a.i, b)) c = b.F, Ec(a.i, b), d = 1;else return;
  if (0 != a.H) if (b.i) {
    if (1 == d) {
      c = b.s ? b.s.length : 0;
      b = Date.now() - b.G;
      var e = a.C;
      d = Mb();
      C(d, new Qb(d, c));
      Gc(a);
    } else Fc(a);
  } else if (e = b.o, 3 == e || 0 == e && 0 < b.ca || !(1 == d && Id(a, b) || 2 == d && Ac(a))) switch (c && 0 < c.length && (b = a.i, b.i = b.i.concat(c)), e) {
    case 1:
      J(a, 5);
      break;
    case 4:
      J(a, 10);
      break;
    case 3:
      J(a, 6);
      break;
    default:
      J(a, 2);
  }
}
function Jd(a, b) {
  let c = a.ab + Math.floor(Math.random() * a.hb);
  a.isActive() || (c *= 2);
  return c * b;
}
function J(a, b) {
  a.l.info("Error code " + b);
  if (2 == b) {
    var c = null;
    a.h && (c = null);
    var d = q(a.pb, a);
    c || (c = new M("//www.google.com/images/cleardot.gif"), l.location && "http" == l.location.protocol || Nc(c, "https"), hc(c));
    jd(c.toString(), d);
  } else F(2);
  a.H = 0;
  a.h && a.h.za(b);
  Hd(a);
  Fd(a);
}
k.pb = function (a) {
  a ? (this.l.info("Successfully pinged google.com"), F(2)) : (this.l.info("Failed to ping google.com"), F(1));
};
function Hd(a) {
  a.H = 0;
  a.ma = [];
  if (a.h) {
    const b = fd(a.i);
    if (0 != b.length || 0 != a.j.length) na(a.ma, b), na(a.ma, a.j), a.i.i.length = 0, ma(a.j), a.j.length = 0;
    a.h.ya();
  }
}
function Dc(a, b, c) {
  var d = c instanceof M ? G(c) : new M(c);
  if ("" != d.g) b && (d.g = b + "." + d.g), Oc(d, d.m);else {
    var e = l.location;
    d = e.protocol;
    b = b ? b + "." + e.hostname : e.hostname;
    e = +e.port;
    var f = new M(null);
    d && Nc(f, d);
    b && (f.g = b);
    e && Oc(f, e);
    c && (f.l = c);
    d = f;
  }
  c = a.F;
  b = a.Da;
  c && b && K(d, c, b);
  K(d, "VER", a.ra);
  Gd(a, d);
  return d;
}
function lc(a, b, c) {
  if (b && !a.J) throw Error("Can't create secondary domain capable XhrIo object.");
  b = c && a.Ha && !a.va ? new P(new ld({
    ob: !0
  })) : new P(a.va);
  b.Oa(a.J);
  return b;
}
k.isActive = function () {
  return !!this.h && this.h.isActive(this);
};
function Nd() {}
k = Nd.prototype;
k.Ba = function () {};
k.Aa = function () {};
k.za = function () {};
k.ya = function () {};
k.isActive = function () {
  return !0;
};
k.Va = function () {};
function Od() {
  if (z && !(10 <= Number(Fa))) throw Error("Environmental error: no available transport.");
}
Od.prototype.g = function (a, b) {
  return new Q(a, b);
};
function Q(a, b) {
  B.call(this);
  this.g = new Ed(b);
  this.l = a;
  this.h = b && b.messageUrlParams || null;
  a = b && b.messageHeaders || null;
  b && b.clientProtocolHeaderRequired && (a ? a["X-Client-Protocol"] = "webchannel" : a = {
    "X-Client-Protocol": "webchannel"
  });
  this.g.s = a;
  a = b && b.initMessageHeaders || null;
  b && b.messageContentType && (a ? a["X-WebChannel-Content-Type"] = b.messageContentType : a = {
    "X-WebChannel-Content-Type": b.messageContentType
  });
  b && b.Ca && (a ? a["X-WebChannel-Client-Profile"] = b.Ca : a = {
    "X-WebChannel-Client-Profile": b.Ca
  });
  this.g.U = a;
  (a = b && b.cc) && !x(a) && (this.g.o = a);
  this.A = b && b.supportsCrossDomainXhr || !1;
  this.v = b && b.sendRawJson || !1;
  (b = b && b.httpSessionIdParam) && !x(b) && (this.g.F = b, a = this.h, null !== a && b in a && (a = this.h, b in a && delete a[b]));
  this.j = new R(this);
}
r(Q, B);
Q.prototype.m = function () {
  this.g.h = this.j;
  this.A && (this.g.J = !0);
  var a = this.g,
    b = this.l,
    c = this.h || void 0;
  F(0);
  a.Y = b;
  a.na = c || {};
  a.G = a.aa;
  a.I = Dc(a, null, a.Y);
  Gc(a);
};
Q.prototype.close = function () {
  Hc(this.g);
};
Q.prototype.u = function (a) {
  var b = this.g;
  if ("string" === typeof a) {
    var c = {};
    c.__data__ = a;
    a = c;
  } else this.v && (c = {}, c.__data__ = jb(a), a = c);
  b.j.push(new bd(b.fb++, a));
  3 == b.H && Gc(b);
};
Q.prototype.N = function () {
  this.g.h = null;
  delete this.j;
  Hc(this.g);
  delete this.g;
  Q.$.N.call(this);
};
function Pd(a) {
  Yb.call(this);
  a.__headers__ && (this.headers = a.__headers__, this.statusCode = a.__status__, delete a.__headers__, delete a.__status__);
  var b = a.__sm__;
  if (b) {
    a: {
      for (const c in b) {
        a = c;
        break a;
      }
      a = void 0;
    }
    if (this.i = a) a = this.i, b = null !== b && a in b ? b[a] : void 0;
    this.data = b;
  } else this.data = a;
}
r(Pd, Yb);
function Qd() {
  Zb.call(this);
  this.status = 1;
}
r(Qd, Zb);
function R(a) {
  this.g = a;
}
r(R, Nd);
R.prototype.Ba = function () {
  C(this.g, "a");
};
R.prototype.Aa = function (a) {
  C(this.g, new Pd(a));
};
R.prototype.za = function (a) {
  C(this.g, new Qd());
};
R.prototype.ya = function () {
  C(this.g, "b");
};
function Rd() {
  this.blockSize = -1;
}
function S() {
  this.blockSize = -1;
  this.blockSize = 64;
  this.g = Array(4);
  this.m = Array(this.blockSize);
  this.i = this.h = 0;
  this.reset();
}
r(S, Rd);
S.prototype.reset = function () {
  this.g[0] = 1732584193;
  this.g[1] = 4023233417;
  this.g[2] = 2562383102;
  this.g[3] = 271733878;
  this.i = this.h = 0;
};
function Sd(a, b, c) {
  c || (c = 0);
  var d = Array(16);
  if ("string" === typeof b) for (var e = 0; 16 > e; ++e) d[e] = b.charCodeAt(c++) | b.charCodeAt(c++) << 8 | b.charCodeAt(c++) << 16 | b.charCodeAt(c++) << 24;else for (e = 0; 16 > e; ++e) d[e] = b[c++] | b[c++] << 8 | b[c++] << 16 | b[c++] << 24;
  b = a.g[0];
  c = a.g[1];
  e = a.g[2];
  var f = a.g[3];
  var h = b + (f ^ c & (e ^ f)) + d[0] + 3614090360 & 4294967295;
  b = c + (h << 7 & 4294967295 | h >>> 25);
  h = f + (e ^ b & (c ^ e)) + d[1] + 3905402710 & 4294967295;
  f = b + (h << 12 & 4294967295 | h >>> 20);
  h = e + (c ^ f & (b ^ c)) + d[2] + 606105819 & 4294967295;
  e = f + (h << 17 & 4294967295 | h >>> 15);
  h = c + (b ^ e & (f ^ b)) + d[3] + 3250441966 & 4294967295;
  c = e + (h << 22 & 4294967295 | h >>> 10);
  h = b + (f ^ c & (e ^ f)) + d[4] + 4118548399 & 4294967295;
  b = c + (h << 7 & 4294967295 | h >>> 25);
  h = f + (e ^ b & (c ^ e)) + d[5] + 1200080426 & 4294967295;
  f = b + (h << 12 & 4294967295 | h >>> 20);
  h = e + (c ^ f & (b ^ c)) + d[6] + 2821735955 & 4294967295;
  e = f + (h << 17 & 4294967295 | h >>> 15);
  h = c + (b ^ e & (f ^ b)) + d[7] + 4249261313 & 4294967295;
  c = e + (h << 22 & 4294967295 | h >>> 10);
  h = b + (f ^ c & (e ^ f)) + d[8] + 1770035416 & 4294967295;
  b = c + (h << 7 & 4294967295 | h >>> 25);
  h = f + (e ^ b & (c ^ e)) + d[9] + 2336552879 & 4294967295;
  f = b + (h << 12 & 4294967295 | h >>> 20);
  h = e + (c ^ f & (b ^ c)) + d[10] + 4294925233 & 4294967295;
  e = f + (h << 17 & 4294967295 | h >>> 15);
  h = c + (b ^ e & (f ^ b)) + d[11] + 2304563134 & 4294967295;
  c = e + (h << 22 & 4294967295 | h >>> 10);
  h = b + (f ^ c & (e ^ f)) + d[12] + 1804603682 & 4294967295;
  b = c + (h << 7 & 4294967295 | h >>> 25);
  h = f + (e ^ b & (c ^ e)) + d[13] + 4254626195 & 4294967295;
  f = b + (h << 12 & 4294967295 | h >>> 20);
  h = e + (c ^ f & (b ^ c)) + d[14] + 2792965006 & 4294967295;
  e = f + (h << 17 & 4294967295 | h >>> 15);
  h = c + (b ^ e & (f ^ b)) + d[15] + 1236535329 & 4294967295;
  c = e + (h << 22 & 4294967295 | h >>> 10);
  h = b + (e ^ f & (c ^ e)) + d[1] + 4129170786 & 4294967295;
  b = c + (h << 5 & 4294967295 | h >>> 27);
  h = f + (c ^ e & (b ^ c)) + d[6] + 3225465664 & 4294967295;
  f = b + (h << 9 & 4294967295 | h >>> 23);
  h = e + (b ^ c & (f ^ b)) + d[11] + 643717713 & 4294967295;
  e = f + (h << 14 & 4294967295 | h >>> 18);
  h = c + (f ^ b & (e ^ f)) + d[0] + 3921069994 & 4294967295;
  c = e + (h << 20 & 4294967295 | h >>> 12);
  h = b + (e ^ f & (c ^ e)) + d[5] + 3593408605 & 4294967295;
  b = c + (h << 5 & 4294967295 | h >>> 27);
  h = f + (c ^ e & (b ^ c)) + d[10] + 38016083 & 4294967295;
  f = b + (h << 9 & 4294967295 | h >>> 23);
  h = e + (b ^ c & (f ^ b)) + d[15] + 3634488961 & 4294967295;
  e = f + (h << 14 & 4294967295 | h >>> 18);
  h = c + (f ^ b & (e ^ f)) + d[4] + 3889429448 & 4294967295;
  c = e + (h << 20 & 4294967295 | h >>> 12);
  h = b + (e ^ f & (c ^ e)) + d[9] + 568446438 & 4294967295;
  b = c + (h << 5 & 4294967295 | h >>> 27);
  h = f + (c ^ e & (b ^ c)) + d[14] + 3275163606 & 4294967295;
  f = b + (h << 9 & 4294967295 | h >>> 23);
  h = e + (b ^ c & (f ^ b)) + d[3] + 4107603335 & 4294967295;
  e = f + (h << 14 & 4294967295 | h >>> 18);
  h = c + (f ^ b & (e ^ f)) + d[8] + 1163531501 & 4294967295;
  c = e + (h << 20 & 4294967295 | h >>> 12);
  h = b + (e ^ f & (c ^ e)) + d[13] + 2850285829 & 4294967295;
  b = c + (h << 5 & 4294967295 | h >>> 27);
  h = f + (c ^ e & (b ^ c)) + d[2] + 4243563512 & 4294967295;
  f = b + (h << 9 & 4294967295 | h >>> 23);
  h = e + (b ^ c & (f ^ b)) + d[7] + 1735328473 & 4294967295;
  e = f + (h << 14 & 4294967295 | h >>> 18);
  h = c + (f ^ b & (e ^ f)) + d[12] + 2368359562 & 4294967295;
  c = e + (h << 20 & 4294967295 | h >>> 12);
  h = b + (c ^ e ^ f) + d[5] + 4294588738 & 4294967295;
  b = c + (h << 4 & 4294967295 | h >>> 28);
  h = f + (b ^ c ^ e) + d[8] + 2272392833 & 4294967295;
  f = b + (h << 11 & 4294967295 | h >>> 21);
  h = e + (f ^ b ^ c) + d[11] + 1839030562 & 4294967295;
  e = f + (h << 16 & 4294967295 | h >>> 16);
  h = c + (e ^ f ^ b) + d[14] + 4259657740 & 4294967295;
  c = e + (h << 23 & 4294967295 | h >>> 9);
  h = b + (c ^ e ^ f) + d[1] + 2763975236 & 4294967295;
  b = c + (h << 4 & 4294967295 | h >>> 28);
  h = f + (b ^ c ^ e) + d[4] + 1272893353 & 4294967295;
  f = b + (h << 11 & 4294967295 | h >>> 21);
  h = e + (f ^ b ^ c) + d[7] + 4139469664 & 4294967295;
  e = f + (h << 16 & 4294967295 | h >>> 16);
  h = c + (e ^ f ^ b) + d[10] + 3200236656 & 4294967295;
  c = e + (h << 23 & 4294967295 | h >>> 9);
  h = b + (c ^ e ^ f) + d[13] + 681279174 & 4294967295;
  b = c + (h << 4 & 4294967295 | h >>> 28);
  h = f + (b ^ c ^ e) + d[0] + 3936430074 & 4294967295;
  f = b + (h << 11 & 4294967295 | h >>> 21);
  h = e + (f ^ b ^ c) + d[3] + 3572445317 & 4294967295;
  e = f + (h << 16 & 4294967295 | h >>> 16);
  h = c + (e ^ f ^ b) + d[6] + 76029189 & 4294967295;
  c = e + (h << 23 & 4294967295 | h >>> 9);
  h = b + (c ^ e ^ f) + d[9] + 3654602809 & 4294967295;
  b = c + (h << 4 & 4294967295 | h >>> 28);
  h = f + (b ^ c ^ e) + d[12] + 3873151461 & 4294967295;
  f = b + (h << 11 & 4294967295 | h >>> 21);
  h = e + (f ^ b ^ c) + d[15] + 530742520 & 4294967295;
  e = f + (h << 16 & 4294967295 | h >>> 16);
  h = c + (e ^ f ^ b) + d[2] + 3299628645 & 4294967295;
  c = e + (h << 23 & 4294967295 | h >>> 9);
  h = b + (e ^ (c | ~f)) + d[0] + 4096336452 & 4294967295;
  b = c + (h << 6 & 4294967295 | h >>> 26);
  h = f + (c ^ (b | ~e)) + d[7] + 1126891415 & 4294967295;
  f = b + (h << 10 & 4294967295 | h >>> 22);
  h = e + (b ^ (f | ~c)) + d[14] + 2878612391 & 4294967295;
  e = f + (h << 15 & 4294967295 | h >>> 17);
  h = c + (f ^ (e | ~b)) + d[5] + 4237533241 & 4294967295;
  c = e + (h << 21 & 4294967295 | h >>> 11);
  h = b + (e ^ (c | ~f)) + d[12] + 1700485571 & 4294967295;
  b = c + (h << 6 & 4294967295 | h >>> 26);
  h = f + (c ^ (b | ~e)) + d[3] + 2399980690 & 4294967295;
  f = b + (h << 10 & 4294967295 | h >>> 22);
  h = e + (b ^ (f | ~c)) + d[10] + 4293915773 & 4294967295;
  e = f + (h << 15 & 4294967295 | h >>> 17);
  h = c + (f ^ (e | ~b)) + d[1] + 2240044497 & 4294967295;
  c = e + (h << 21 & 4294967295 | h >>> 11);
  h = b + (e ^ (c | ~f)) + d[8] + 1873313359 & 4294967295;
  b = c + (h << 6 & 4294967295 | h >>> 26);
  h = f + (c ^ (b | ~e)) + d[15] + 4264355552 & 4294967295;
  f = b + (h << 10 & 4294967295 | h >>> 22);
  h = e + (b ^ (f | ~c)) + d[6] + 2734768916 & 4294967295;
  e = f + (h << 15 & 4294967295 | h >>> 17);
  h = c + (f ^ (e | ~b)) + d[13] + 1309151649 & 4294967295;
  c = e + (h << 21 & 4294967295 | h >>> 11);
  h = b + (e ^ (c | ~f)) + d[4] + 4149444226 & 4294967295;
  b = c + (h << 6 & 4294967295 | h >>> 26);
  h = f + (c ^ (b | ~e)) + d[11] + 3174756917 & 4294967295;
  f = b + (h << 10 & 4294967295 | h >>> 22);
  h = e + (b ^ (f | ~c)) + d[2] + 718787259 & 4294967295;
  e = f + (h << 15 & 4294967295 | h >>> 17);
  h = c + (f ^ (e | ~b)) + d[9] + 3951481745 & 4294967295;
  a.g[0] = a.g[0] + b & 4294967295;
  a.g[1] = a.g[1] + (e + (h << 21 & 4294967295 | h >>> 11)) & 4294967295;
  a.g[2] = a.g[2] + e & 4294967295;
  a.g[3] = a.g[3] + f & 4294967295;
}
S.prototype.j = function (a, b) {
  void 0 === b && (b = a.length);
  for (var c = b - this.blockSize, d = this.m, e = this.h, f = 0; f < b;) {
    if (0 == e) for (; f <= c;) Sd(this, a, f), f += this.blockSize;
    if ("string" === typeof a) for (; f < b;) {
      if (d[e++] = a.charCodeAt(f++), e == this.blockSize) {
        Sd(this, d);
        e = 0;
        break;
      }
    } else for (; f < b;) if (d[e++] = a[f++], e == this.blockSize) {
      Sd(this, d);
      e = 0;
      break;
    }
  }
  this.h = e;
  this.i += b;
};
S.prototype.l = function () {
  var a = Array((56 > this.h ? this.blockSize : 2 * this.blockSize) - this.h);
  a[0] = 128;
  for (var b = 1; b < a.length - 8; ++b) a[b] = 0;
  var c = 8 * this.i;
  for (b = a.length - 8; b < a.length; ++b) a[b] = c & 255, c /= 256;
  this.j(a);
  a = Array(16);
  for (b = c = 0; 4 > b; ++b) for (var d = 0; 32 > d; d += 8) a[c++] = this.g[b] >>> d & 255;
  return a;
};
function T(a, b) {
  this.h = b;
  for (var c = [], d = !0, e = a.length - 1; 0 <= e; e--) {
    var f = a[e] | 0;
    d && f == b || (c[e] = f, d = !1);
  }
  this.g = c;
}
var sa = {};
function Td(a) {
  return -128 <= a && 128 > a ? ra(a, function (b) {
    return new T([b | 0], 0 > b ? -1 : 0);
  }) : new T([a | 0], 0 > a ? -1 : 0);
}
function U(a) {
  if (isNaN(a) || !isFinite(a)) return V;
  if (0 > a) return W(U(-a));
  for (var b = [], c = 1, d = 0; a >= c; d++) b[d] = a / c | 0, c *= Ud;
  return new T(b, 0);
}
function Vd(a, b) {
  if (0 == a.length) throw Error("number format error: empty string");
  b = b || 10;
  if (2 > b || 36 < b) throw Error("radix out of range: " + b);
  if ("-" == a.charAt(0)) return W(Vd(a.substring(1), b));
  if (0 <= a.indexOf("-")) throw Error('number format error: interior "-" character');
  for (var c = U(Math.pow(b, 8)), d = V, e = 0; e < a.length; e += 8) {
    var f = Math.min(8, a.length - e),
      h = parseInt(a.substring(e, e + f), b);
    8 > f ? (f = U(Math.pow(b, f)), d = d.R(f).add(U(h))) : (d = d.R(c), d = d.add(U(h)));
  }
  return d;
}
var Ud = 4294967296,
  V = Td(0),
  Wd = Td(1),
  Xd = Td(16777216);
k = T.prototype;
k.ea = function () {
  if (X(this)) return -W(this).ea();
  for (var a = 0, b = 1, c = 0; c < this.g.length; c++) {
    var d = this.D(c);
    a += (0 <= d ? d : Ud + d) * b;
    b *= Ud;
  }
  return a;
};
k.toString = function (a) {
  a = a || 10;
  if (2 > a || 36 < a) throw Error("radix out of range: " + a);
  if (Y(this)) return "0";
  if (X(this)) return "-" + W(this).toString(a);
  for (var b = U(Math.pow(a, 6)), c = this, d = "";;) {
    var e = Yd(c, b).g;
    c = Zd(c, e.R(b));
    var f = ((0 < c.g.length ? c.g[0] : c.h) >>> 0).toString(a);
    c = e;
    if (Y(c)) return f + d;
    for (; 6 > f.length;) f = "0" + f;
    d = f + d;
  }
};
k.D = function (a) {
  return 0 > a ? 0 : a < this.g.length ? this.g[a] : this.h;
};
function Y(a) {
  if (0 != a.h) return !1;
  for (var b = 0; b < a.g.length; b++) if (0 != a.g[b]) return !1;
  return !0;
}
function X(a) {
  return -1 == a.h;
}
k.X = function (a) {
  a = Zd(this, a);
  return X(a) ? -1 : Y(a) ? 0 : 1;
};
function W(a) {
  for (var b = a.g.length, c = [], d = 0; d < b; d++) c[d] = ~a.g[d];
  return new T(c, ~a.h).add(Wd);
}
k.abs = function () {
  return X(this) ? W(this) : this;
};
k.add = function (a) {
  for (var b = Math.max(this.g.length, a.g.length), c = [], d = 0, e = 0; e <= b; e++) {
    var f = d + (this.D(e) & 65535) + (a.D(e) & 65535),
      h = (f >>> 16) + (this.D(e) >>> 16) + (a.D(e) >>> 16);
    d = h >>> 16;
    f &= 65535;
    h &= 65535;
    c[e] = h << 16 | f;
  }
  return new T(c, c[c.length - 1] & -2147483648 ? -1 : 0);
};
function Zd(a, b) {
  return a.add(W(b));
}
k.R = function (a) {
  if (Y(this) || Y(a)) return V;
  if (X(this)) return X(a) ? W(this).R(W(a)) : W(W(this).R(a));
  if (X(a)) return W(this.R(W(a)));
  if (0 > this.X(Xd) && 0 > a.X(Xd)) return U(this.ea() * a.ea());
  for (var b = this.g.length + a.g.length, c = [], d = 0; d < 2 * b; d++) c[d] = 0;
  for (d = 0; d < this.g.length; d++) for (var e = 0; e < a.g.length; e++) {
    var f = this.D(d) >>> 16,
      h = this.D(d) & 65535,
      n = a.D(e) >>> 16,
      t = a.D(e) & 65535;
    c[2 * d + 2 * e] += h * t;
    $d(c, 2 * d + 2 * e);
    c[2 * d + 2 * e + 1] += f * t;
    $d(c, 2 * d + 2 * e + 1);
    c[2 * d + 2 * e + 1] += h * n;
    $d(c, 2 * d + 2 * e + 1);
    c[2 * d + 2 * e + 2] += f * n;
    $d(c, 2 * d + 2 * e + 2);
  }
  for (d = 0; d < b; d++) c[d] = c[2 * d + 1] << 16 | c[2 * d];
  for (d = b; d < 2 * b; d++) c[d] = 0;
  return new T(c, 0);
};
function $d(a, b) {
  for (; (a[b] & 65535) != a[b];) a[b + 1] += a[b] >>> 16, a[b] &= 65535, b++;
}
function ae(a, b) {
  this.g = a;
  this.h = b;
}
function Yd(a, b) {
  if (Y(b)) throw Error("division by zero");
  if (Y(a)) return new ae(V, V);
  if (X(a)) return b = Yd(W(a), b), new ae(W(b.g), W(b.h));
  if (X(b)) return b = Yd(a, W(b)), new ae(W(b.g), b.h);
  if (30 < a.g.length) {
    if (X(a) || X(b)) throw Error("slowDivide_ only works with positive integers.");
    for (var c = Wd, d = b; 0 >= d.X(a);) c = be(c), d = be(d);
    var e = Z(c, 1),
      f = Z(d, 1);
    d = Z(d, 2);
    for (c = Z(c, 2); !Y(d);) {
      var h = f.add(d);
      0 >= h.X(a) && (e = e.add(c), f = h);
      d = Z(d, 1);
      c = Z(c, 1);
    }
    b = Zd(a, e.R(b));
    return new ae(e, b);
  }
  for (e = V; 0 <= a.X(b);) {
    c = Math.max(1, Math.floor(a.ea() / b.ea()));
    d = Math.ceil(Math.log(c) / Math.LN2);
    d = 48 >= d ? 1 : Math.pow(2, d - 48);
    f = U(c);
    for (h = f.R(b); X(h) || 0 < h.X(a);) c -= d, f = U(c), h = f.R(b);
    Y(f) && (f = Wd);
    e = e.add(f);
    a = Zd(a, h);
  }
  return new ae(e, a);
}
k.gb = function (a) {
  return Yd(this, a).h;
};
k.and = function (a) {
  for (var b = Math.max(this.g.length, a.g.length), c = [], d = 0; d < b; d++) c[d] = this.D(d) & a.D(d);
  return new T(c, this.h & a.h);
};
k.or = function (a) {
  for (var b = Math.max(this.g.length, a.g.length), c = [], d = 0; d < b; d++) c[d] = this.D(d) | a.D(d);
  return new T(c, this.h | a.h);
};
k.xor = function (a) {
  for (var b = Math.max(this.g.length, a.g.length), c = [], d = 0; d < b; d++) c[d] = this.D(d) ^ a.D(d);
  return new T(c, this.h ^ a.h);
};
function be(a) {
  for (var b = a.g.length + 1, c = [], d = 0; d < b; d++) c[d] = a.D(d) << 1 | a.D(d - 1) >>> 31;
  return new T(c, a.h);
}
function Z(a, b) {
  var c = b >> 5;
  b %= 32;
  for (var d = a.g.length - c, e = [], f = 0; f < d; f++) e[f] = 0 < b ? a.D(f + c) >>> b | a.D(f + c + 1) << 32 - b : a.D(f + c);
  return new T(e, a.h);
}
Od.prototype.createWebChannel = Od.prototype.g;
Q.prototype.send = Q.prototype.u;
Q.prototype.open = Q.prototype.m;
Q.prototype.close = Q.prototype.close;
Sb.NO_ERROR = 0;
Sb.TIMEOUT = 8;
Sb.HTTP_ERROR = 6;
Tb.COMPLETE = "complete";
Wb.EventType = Xb;
Xb.OPEN = "a";
Xb.CLOSE = "b";
Xb.ERROR = "c";
Xb.MESSAGE = "d";
B.prototype.listen = B.prototype.O;
P.prototype.listenOnce = P.prototype.P;
P.prototype.getLastError = P.prototype.Sa;
P.prototype.getLastErrorCode = P.prototype.Ia;
P.prototype.getStatus = P.prototype.da;
P.prototype.getResponseJson = P.prototype.Wa;
P.prototype.getResponseText = P.prototype.ja;
P.prototype.send = P.prototype.ha;
P.prototype.setWithCredentials = P.prototype.Oa;
S.prototype.digest = S.prototype.l;
S.prototype.reset = S.prototype.reset;
S.prototype.update = S.prototype.j;
T.prototype.add = T.prototype.add;
T.prototype.multiply = T.prototype.R;
T.prototype.modulo = T.prototype.gb;
T.prototype.compare = T.prototype.X;
T.prototype.toNumber = T.prototype.ea;
T.prototype.toString = T.prototype.toString;
T.prototype.getBits = T.prototype.D;
T.fromNumber = U;
T.fromString = Vd;
var createWebChannelTransport = esm.createWebChannelTransport = function () {
  return new Od();
};
var getStatEventTarget = esm.getStatEventTarget = function () {
  return Mb();
};
var ErrorCode = esm.ErrorCode = Sb;
var EventType = esm.EventType = Tb;
var Event = esm.Event = E;
var Stat = esm.Stat = {
  xb: 0,
  Ab: 1,
  Bb: 2,
  Ub: 3,
  Zb: 4,
  Wb: 5,
  Xb: 6,
  Vb: 7,
  Tb: 8,
  Yb: 9,
  PROXY: 10,
  NOPROXY: 11,
  Rb: 12,
  Nb: 13,
  Ob: 14,
  Mb: 15,
  Pb: 16,
  Qb: 17,
  tb: 18,
  sb: 19,
  ub: 20
};
var FetchXmlHttpFactory = esm.FetchXmlHttpFactory = ld;
var WebChannel = esm.WebChannel = Wb;
var XhrIo = esm.XhrIo = P;
var Md5 = esm.Md5 = S;
var Integer = esm.Integer = T;
export { ErrorCode, Event, EventType, FetchXmlHttpFactory, Integer, Md5, Stat, WebChannel, XhrIo, createWebChannelTransport, esm as default, getStatEventTarget };
