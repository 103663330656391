import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const professionalId = route.queryParamMap.get('professionalId');

    if (professionalId && !state.url.includes('/c/join-professional')) {
      // Redirect to the /c/join-professional route with the query param
      return this.router.createUrlTree(['/c/join-professional'], {
        queryParams: { professionalId },
        queryParamsHandling: 'merge',
      });
    }

    // If no professionalId is present or we're already on the join-professional route, allow navigation to continue
    return true;
  }
}
