<label
  for="{{ inputId }}"
  class="inline-flex cursor-pointer flex-row-reverse items-center"
  [class.!cursor-default]="readonly"
>
  <ng-content></ng-content>
  <div class="my-2 inline-flex items-center">
    <div class="relative flex items-center">
      <input
        type="checkbox"
        [checked]="isChecked"
        (change)="toggleCheckbox()"
        (blur)="onTouched()"
        class="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-border bg-white outline-none ring-offset-1 transition-all checked:border-intecc2 checked:bg-intecc2 focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-intecc2 disabled:border-neutral-200 disabled:bg-neutral-200 disabled:checked:border-neutral-200 disabled:checked:bg-neutral-200"
        id="{{ inputId }}"
        [disabled]="readonly"
        [class.!cursor-default]="readonly"
      />
      <div
        class="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3.5 w-3.5"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</label>
