import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { TestService } from 'src/app/services/test.service';
import {
  SagitalTest,
  sagitalTestFieldsDefinition,
  updateResults,
} from 'src/app/models/sagital-test.model';
import { Client } from 'src/app/models/client.model';
import { DatePickerService } from 'src/app/services/date-picker.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { LearnService } from 'src/app/services/learn.service';
import { InclinometerComponent } from 'src/app/elements/inclinometer/inclinometer.component';
import { ModalService } from 'src/app/services/modal.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

interface InclinometerItemsType {
  [key: string]: {
    text: string;
    path: Array<string>;
    learnGif: string;
    next: string;
    nextAutoStart: boolean;
  };
}

@Component({
  selector: 'app-sagital-test',
  templateUrl: './sagital-test.component.html',
  styleUrls: ['./sagital-test.component.scss'],
})
export class SagitalTestComponent implements OnInit {
  _test: SagitalTest;

  date: Date;

  clientSharedFolderUrl: string;

  generatingReport = false;

  @Input() isUserClientView: boolean;

  getFieldDefinitionValues(field: string) {
    return sagitalTestFieldsDefinition[field].values.map(
      (value) => value.label,
    );
  }

  getSelectIndex(field: string) {
    return sagitalTestFieldsDefinition[field].values.findIndex(
      (value) => value.key === this.test[field],
    );
  }

  getSelectValue(field: string) {
    const selectedOption = sagitalTestFieldsDefinition[field].values.find(
      (value) => value.key === this.test[field],
    );
    return selectedOption?.label ?? 'sin definir';
  }

  setSelectValue(field: string, value: string) {
    const valueToBeSet = sagitalTestFieldsDefinition[field].values[value]?.key;
    this.test[field] = valueToBeSet;
    this.updateTest();
  }

  get test() {
    return this._test;
  }

  @Input() set test(value: SagitalTest) {
    this._test = value;
    this.init();
  }

  @Input() client: Client;

  @ViewChild(InclinometerComponent, { static: true })
  inclinometerComponent: InclinometerComponent;

  //   @ViewChild('commentsTextArea', {static: true}) commentsTextArea: ElementRef;

  // inclinometer binding
  currentInclinometerItemCode = '';
  inclinometerOpen = false;
  testPropertyPath: string[] = [];
  infoText = '';
  learnSectionId: string;

  diagnosticsAvailable = false;
  diagnosticsOpen = false;

  InclinometerItems: InclinometerItemsType = {
    'bip/dor': {
      text: 'Bipedestación - Dorsal',
      path: ['bipedestacion', 'dorsal'],
      learnGif: 'gif-bip-dorsal-movil',
      next: 'bip/lum',
      nextAutoStart: true,
    },
    'bip/lum': {
      text: 'Bipedestación - Lumbar',
      path: ['bipedestacion', 'lumbar'],
      learnGif: 'gif-bip-lumbar-movil',
      next: 'bip/pel',
      nextAutoStart: false,
    },
    'bip/pel': {
      text: 'Bipedestación - Pelvis',
      path: ['bipedestacion', 'pelvis'],
      learnGif: 'gif-bip-pelvis-movil',
      next: 'sed/dor',
      nextAutoStart: false,
    },
    'bip/dor/ma': {
      text: 'Maniobra de Autocorrección',
      path: ['bipedestacion', 'dorsalMA'],
      learnGif: 'gif-bip-ma-inclinometro',
      next: 'sed/dor',
      nextAutoStart: false,
    },
    'sed/dor': {
      text: 'Sedentación - Dorsal',
      path: ['sedentacion', 'dorsal'],
      learnGif: 'gif-sa-dorsal-movil',
      next: 'sed/lum',
      nextAutoStart: true,
    },
    'sed/lum': {
      text: 'Sedentación - Lumbar',
      path: ['sedentacion', 'lumbar'],
      learnGif: 'gif-sa-lumbar-movil',
      next: 'sed/pel',
      nextAutoStart: false,
    },
    'sed/pel': {
      text: 'Sedentación - Pelvis',
      path: ['sedentacion', 'pelvic'],
      learnGif: 'gif-sa-pelvis-movil',
      next: 'flex/dor',
      nextAutoStart: false,
    },
    'flex/dor': {
      text: 'Flexión Máxima del Tronco - Dorsal',
      path: ['flexMaxTronco', 'dorsal'],
      learnGif: 'gif-flex-dorsal-movil',
      next: 'flex/lum',
      nextAutoStart: true,
    },
    'flex/lum': {
      text: 'Flexión Máxima del Tronco - Lumbar',
      path: ['flexMaxTronco', 'lumbar'],
      learnGif: 'gif-flex-lumbar-movil',
      next: 'flex/pel',
      nextAutoStart: false,
    },
    'flex/pel': {
      text: 'Flexión Máxima del Tronco - Pelvis',
      path: ['flexMaxTronco', 'pelvic'],
      learnGif: 'gif-flex-pelvis-movil',
      next: null,
      nextAutoStart: false,
    },
  };

  constructor(
    private tests: TestService,
    public datePicker: DatePickerService,
    private overrideBackButton: OverrideBackButtonService,
    private learnService: LearnService,
    private modalService: ModalService,
    private clientsService: ClientsService,
    private reportsServices: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private snackbarService: SnackbarService,
  ) {
    this.generateReport = this.generateReport.bind(this);
  }

  ngOnInit() {
    this.init();
  }

  openInclinometer(
    infoText: string,
    pathToParam: string[],
    learnGifId?: string,
  ) {
    // this.infoText = infoText;
    this.inclinometerComponent.infoText = infoText;
    // this.testPropertyPath = pathToParam;
    this.inclinometerComponent.testPropertyPath = pathToParam;

    // this.inclinometerComponent.learnSectionId = learnSectionId;
    // this.inclinometerComponent
    this.inclinometerComponent.learnGifId = learnGifId;

    this.inclinometerOpen = true;
  }

  openInclinometerItem(itemCode: string, autoStart: boolean) {
    if (this.isUserClientView) {
      return;
    }
    this.currentInclinometerItemCode = itemCode;
    const item = this.InclinometerItems[this.currentInclinometerItemCode];
    this.inclinometerComponent.infoText = item.text;
    this.inclinometerComponent.testPropertyPath = item.path;
    this.inclinometerComponent.learnGifId = item.learnGif;
    this.inclinometerComponent.showNext = this.hasNext(itemCode);
    this.inclinometerOpen = true;
    if (autoStart) {
      this.inclinometerComponent.startInclinometer();
    } else {
      this.inclinometerComponent.stopInclinometer();
    }
  }

  hasNext(itemCode: string) {
    const item = this.InclinometerItems[itemCode];
    const result = !!item.next;
    return result;
  }

  async onInclinometerClose() {
    this.inclinometerOpen = false;
    updateResults(this.test);
    this.updateTest();
  }

  async onInclinometerNext() {
    this.updateTest();
    const item = this.InclinometerItems[this.currentInclinometerItemCode];
    if (item.next) {
      this.openInclinometerItem(item.next, item.nextAutoStart);
    }
  }

  init() {
    updateResults(this.test);
    this.checkDiagnosticsAvailable();

    this.clientsService.currentClient$.subscribe((client) => {
      if (client) {
        this.clientSharedFolderUrl = client.sharedFolderUrl;
      }
    });
  }

  checkDiagnosticsAvailable() {
    this.diagnosticsAvailable =
      !!this.test.dorsalResult || !!this.test.lumbarResult || !!this.test.apex;
  }

  updateTest() {
    if (this.isUserClientView) {
      return;
    }
    const {
      bipedestacionResults,
      sedentacionResults,
      flexMaxTroncoResults,
      dorsalBipSedResult,
      dorsalBipFlexResult,
      ...test
    } = this.test;
    this.tests.updateTest(test);
  }

  onDateChanged(date: any) {
    this.test.createdAt = firebaseTimestampFromDate(date.value);
    this.updateTest();
  }

  openDiagnostics() {
    this.diagnosticsOpen = true;
    this.overrideBackButton.override(() => {
      this.diagnosticsOpen = false;
    });
  }

  back() {
    history.back();
  }

  openLearn(learnSectionName: string) {
    this.learnService.openPanel(learnSectionName);
  }

  async editClient(data: any) {
    await this.modalService.openModal('create-edit-client', data);
  }

  setMultimediaLink(value: any) {
    value = value.trim();
    this.test.multimediaLink = value;
    this.updateTest();
  }

  setLinks(value: any) {
    this.test.links = value;
    this.updateTest();
  }

  onCommentsChanged(event) {
    if (this.isUserClientView) {
      return;
    }
    this.test.comments = event.target.value;
    this.updateTest();
  }

  async generateReport() {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([this.test]);
    } catch (e) {
      console.error('gen report error', e);
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }
}
