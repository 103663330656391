import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-two-state-button',
  templateUrl: './two-state-button.component.html',
  styleUrls: ['./two-state-button.component.scss']
})
export class TwoStateButtonComponent {

  @Output()
  buttonClick = new EventEmitter();

  @Input()
  text = 'Button text';

  @Input()
  loadingText = 'Loading text';

  @Input()
  faIconCode = 'fas fa-rocket';

  @Input()
  type: 'normal' | 'danger' | 'primary' = 'primary';

  @Input()
  loading = false;

  @Input()
  modClass = '';

  constructor() { }

  onClick() {
    if (!this.loading) {
      this.buttonClick.emit();
    }
  }

}
