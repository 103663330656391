import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-normal-value-viewer',
  templateUrl: './normal-value-viewer.component.html',
  styleUrls: ['./normal-value-viewer.component.scss']
})
export class NormalValueViewerComponent {

  @Input()
  names: Array<string>;

  @Input()
  values: Array<string>;

  @Input()
  results: Array<number>;

  constructor() { }

}
