<div class="notifications-container" [class.notifications-container--hidden]="visibleNotifications.length === 55">
    <div class="notification" *ngIf="visibleNotifications.includes('upgrade')">
        <div class="notification__box" *ngIf="notificationData[notifications.Upgrade] as data">
            <div class="notification__title">{{data.title}}</div>
            <div class="notification__description">{{data.description}}</div>
            <div class="notification__action">
                <button (click)="openUpgradeModal()" class="valorations__not-subscribed-banner__action cool-button-with-icon">
                    <span class="cool-button-with-icon__icon"><i class="fas fa-rocket"></i></span>
                    <p class="cool-button-with-icon__text">Mejorar</p>
                </button>
                <button (click)="remove('upgrade')" class="valorations__not-subscribed-banner__action button-plain-2">
                    <p class="cool-button-with-icon__text">No, gracias.</p>
                </button>
            </div>
        </div>
    </div>
    <div class="notification" *ngIf="visibleNotifications.includes('update')">
        <div class="notification__box">
            <div class="notification__title">¡Nueva versión disponible!</div>
            <div class="notification__description">Hemos estado trabajando en una nueva actualización. Pulsa el botón
                para disfrutar
                de las últimas mejoras.</div>
            <div class="notification__action">
                <button (click)="reload()" class="valorations__not-subscribed-banner__action cool-button-with-icon">
                    <span class="cool-button-with-icon__icon"><i class="fas fa-wrench"></i></span>
                    <p class="cool-button-with-icon__text">Actualizar</p>
                </button>
            </div>
        </div>
    </div>
    <div class="mx-5" *ngIf="visibleNotifications.includes('error')">
        <div class="bg-white max-w-4xl mx-auto flex overflow-hidden items-stretch rounded-lg relative shadow-xl" *ngIf="notificationData[notifications.Error] as data">
            <div class=" bg-red-600 w-3"></div>
            <div class="px-10 py-5">
                <div class="text-3xl font-bold mb-2">{{data.title}}</div>
                <p>{{data.message}}</p>
            </div>
            <div class="absolute right-5 top-5 cursor-pointer" (click)="remove('error')">
                <i class="text-3xl fas fa-times"></i>
            </div>
        </div>
    </div>
</div>