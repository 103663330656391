<div class="app-container">
  <app-test-header
    [title]="'Protocolo VAF Posture'"
    [icon]="'fa-compress fas'"
    [learnId]="'VAFPosture'"
    [learnURL]="'https://manual.intecc.org/es/article/protocolo-vaf-posture'"
    [isUserClientView]="isUserClientView"
    [generateReportAction]="generateReport"
  ></app-test-header>

  <div class="test-info">
    <app-client-back-button
      *ngIf="!isUserClientView"
      [client]="client"
    ></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
      <button
        *ngIf="!isUserClientView"
        (click)="picker.open()"
        class="date-selector__button"
      >
        {{ test.createdAt.toDate() | date }} <i class="fas fa-edit"></i>
      </button>
      <span *ngIf="isUserClientView">
        {{ test.createdAt.toDate() | date }}
      </span>

      <input
        style="display: none"
        (dateChange)="onDateChanged($event)"
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
      />
      <mat-datepicker
        touchUi
        #picker
        [startAt]="test.createdAt.toDate()"
      ></mat-datepicker>
    </div>
  </div>

  <div
    *ngFor="let item of PostureArchitecture"
    class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 md:px-8"
  >
    <!-- <p class="text-xl font-semibold mb-4">{{item.title}}</p> -->
    <div class="mb-4 flex justify-between">
      <p class="text-xl font-semibold">{{ item.title }}</p>
      <div class="flex items-center">
        <a
          *ngIf="item.learnURL && !isUserClientView"
          href="{{ item.learnURL }}"
          target="_blank"
          rel="noopener noreferrer"
          class="cursor-pointer text-intecc2"
        >
          <i class="fas fa-book text-intecc2"></i>
        </a>
        <!-- <app-icon-toggle [active]="!test[item.id]" (activeChange)="setSectionStatus(item.id, !$event)" ></app-icon-toggle> -->
      </div>
    </div>
    <div
      *ngIf="item.images"
      class="w-full overflow-y-scroll md:overflow-y-hidden"
    >
      <div
        *ngIf="item.fields; else noFields"
        class="posture-container grid max-h-screen-3/4 grid-cols-3 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-96 md:auto-cols-fr md:grid-flow-col md:grid-cols-1.2fr-1fr-1fr md:grid-rows-1"
      >
        <div class="overflow-y-scroll md:overflow-y-auto">
          <div
            class="grid h-auto auto-rows-auto grid-cols-1 items-center gap-x-5 gap-y-2 self-start rounded-md pr-3 md:grid-cols-2"
          >
            <ng-container *ngFor="let field of item.fields; index as i">
              <ng-container *ngIf="getPostureFieldInfo(field); let fieldInfo">
                <p>
                  {{ fieldInfo.title }}
                </p>
                <!-- <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
                <ng-container *ngIf="!isUserClientView">
                  <app-test-field-dropdown
                    [options]="fieldInfo.values"
                    [selectedIndex]="getValue(field)"
                    (selectedItemChanged)="setValue(field, $event)"
                    [openToTop]="i > 4"
                  ></app-test-field-dropdown>
                </ng-container>
                <p
                  *ngIf="isUserClientView"
                  class="relative rounded-md bg-neutral-100 px-3 py-2 text-base"
                >
                  {{ fieldInfo.values[getValue(field)] ?? '-' }}
                </p>
              </ng-container>
              <!-- <p>{{getFieldInfo(field).title}}</p>
                            <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
            </ng-container>
          </div>
        </div>
        <div
          *ngFor="let image of item.images"
          class="h-full overflow-hidden rounded-md bg-blue-300"
        >
          <app-test-image-view
            [isUserClientView]="isUserClientView"
            [client]="client"
            [imageKey]="image"
            [test]="test"
            (imageChangedEvent)="onImageChanged()"
          ></app-test-image-view>
          <!-- <img src="../../../../assets/images/run.jpg" class="h-full w-full object-cover" alt=""> -->
        </div>
        <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
      </div>

      <ng-template #noFields>
        <div
          style=""
          class="posture-container grid max-h-screen-3/4 grid-cols-2 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-96 md:grid-rows-1"
        >
          <div
            *ngFor="let image of item.images"
            style=""
            class="h-full min-h-96 overflow-hidden rounded-md bg-blue-300"
          >
            <app-test-image-view
              [isUserClientView]="isUserClientView"
              [client]="client"
              [imageKey]="image"
              [test]="test"
              (imageChangedEvent)="onImageChanged()"
            ></app-test-image-view>
            <!-- <img src="../../../../assets/images/run.jpg" class="h-full w-full object-cover" alt=""> -->
          </div>
          <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="!item.images && item.id !== 'linksAndComments'"
      class="w-full overflow-y-scroll md:overflow-y-hidden"
    >
      <div
        *ngIf="item.fields; else noFields"
        style=""
        class="posture-container grid max-h-screen-3/4 grid-cols-3 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-250 md:auto-cols-fr md:grid-flow-col md:grid-cols-1.2fr-1fr-1fr md:grid-rows-1"
      >
        <div class="overflow-y-scroll md:overflow-y-auto">
          <div
            style=""
            class="grid h-auto auto-rows-auto grid-cols-1 items-center gap-x-5 gap-y-2 self-start rounded-md pr-3 md:grid-cols-2"
          >
            <ng-container *ngFor="let field of item.fields; index as i">
              <ng-container *ngIf="getPostureFieldInfo(field); let fieldInfo">
                <p>
                  {{ fieldInfo.title }}
                </p>
                <!-- <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
                <ng-container *ngIf="!isUserClientView">
                  <app-test-field-dropdown
                    [options]="fieldInfo.values"
                    [selectedIndex]="getValue(field)"
                    (selectedItemChanged)="setValue(field, $event)"
                    [openToTop]="i > 4"
                  ></app-test-field-dropdown>
                </ng-container>
                <ng-container *ngIf="isUserClientView">
                  <p
                    class="relative rounded-md bg-neutral-100 px-3 py-2 text-base"
                  >
                    {{ fieldInfo.values[getValue(field)] ?? '-' }}
                  </p>
                </ng-container>
              </ng-container>
              <!-- <p>{{getFieldInfo(field).title}}</p>
                            <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
            </ng-container>
          </div>
        </div>
        <!-- <div *ngFor="let image of item.images" style="" class="  h-full bg-blue-300 rounded-md overflow-hidden">
                    <app-test-image-view [client]="client" [imageKey]="image" [test]="test" (imageChangedEvent)="onImageChanged()"></app-test-image-view>
                    
                </div> -->
        <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
      </div>

      <ng-template #noFields>
        <div
          style=""
          class="posture-container grid max-h-screen-3/4 grid-cols-2 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-96 md:grid-rows-1"
        >
          <div
            *ngFor="let image of item.images"
            style=""
            class="h-full min-h-96 overflow-hidden rounded-md bg-blue-300"
          >
            <app-test-image-view
              [isUserClientView]="isUserClientView"
              [client]="client"
              [imageKey]="image"
              [test]="test"
              (imageChangedEvent)="onImageChanged()"
            ></app-test-image-view>
            <!-- <img src="../../../../assets/images/run.jpg" class="h-full w-full object-cover" alt=""> -->
          </div>
          <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
        </div>
      </ng-template>
    </div>
    <!-- <div *ngIf="item.id === 'frontal' || item.id === 'sagital' || item.id === 'linksAndComments'" class="w-full">
            <p class="mt-7 text-lg font-semibold mb-4">Comentarios</p>
            <textarea class="w-full border-2 rounded-lg border-neutral-200" #commentsTextArea name="comments"  cols="30" rows="5" [value]="getComments(item.commentsPropName) ? getComments(item.commentsPropName) : ''" (change)="onCommentsChanged(item.commentsPropName, $event)"></textarea>
        </div> -->
    <div *ngIf="item.commentsPropName" class="w-full">
      <p
        *ngIf="item.id !== 'linksAndComments'"
        class="mb-4 mt-7 text-lg font-semibold"
      >
        Comentarios
      </p>
      <p
        *ngIf="item.id === 'linksAndComments'"
        class="mb-4 mt-7 text-lg font-semibold"
      >
        Recomendaciones
      </p>
      <textarea
        class="w-full rounded-lg border-2 border-neutral-200"
        #commentsTextArea
        name="comments"
        cols="30"
        rows="5"
        [value]="
          getComments(item.commentsPropName)
            ? getComments(item.commentsPropName)
            : ''
        "
        [readonly]="isUserClientView"
        (change)="onCommentsChanged(item.commentsPropName, $event)"
      ></textarea>
    </div>
    <div *ngIf="item.id === 'linksAndComments'" class="w-full">
      <p class="mb-1 mt-7 text-lg font-semibold">Enlaces multimedia</p>
      <ng-container *ngIf="!isUserClientView">
        <p>
          Accede a la carpeta “Documentos” e inserta la URL de los archivos
          multimedia.
        </p>
        <button
          *ngIf="!clientSharedFolderUrl"
          (click)="this.editClient({ client })"
          class="mt-2 font-semibold text-intecc2"
        >
          Añade una carpeta "Documentos" del cliente
        </button>
        <a
          *ngIf="clientSharedFolderUrl"
          class="mt-2 inline-block font-semibold text-intecc2"
          href="{{ clientSharedFolderUrl }}"
          target="_blank"
          rel="noopener noreferrer"
          >Ir a carpeta "Documentos" del cliente</a
        >
        <input
          class="w-full rounded-lg border-2 border-neutral-200"
          name="multimediaLink"
          [ngModel]="this.test.multimediaLink ? this.test.multimediaLink : ''"
          (ngModelChange)="this.setMultimediaLink($event)"
        />
      </ng-container>
      <ng-container *ngIf="isUserClientView">
        <a
          *ngIf="this.test.multimediaLink"
          href="{{ this.test.multimediaLink }}"
          target="_blank"
          rel="noopener noreferrer"
          class="break-all hover:underline"
        >
          {{ this.test.multimediaLink }}
        </a>
        <p *ngIf="!this.test.multimediaLink">
          No hay enlace multimedia disponible
        </p>
      </ng-container>
      <p class="mb-1 mt-7 text-lg font-semibold">Enlaces opcionales</p>
      <p *ngIf="!isUserClientView">
        Añade enlaces opcionales a los informes (webs, videos, lecturas, etc).
      </p>
      <app-test-report-links
        [isUserClientView]="isUserClientView"
        [links]="this.test.links"
        (changeEvent)="this.setLinks($event)"
      ></app-test-report-links>
    </div>
  </div>
</div>
