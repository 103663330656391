import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() inputId?: string;

  isDisabled: boolean = false;

  isChecked: boolean = false;

  // Method that writes the model value to the view
  writeValue(isChecked: boolean): void {
    this.isChecked = isChecked;
  }

  // Registers a callback function that should be called when the control's value changes in the UI
  onChange = (isChecked: boolean) => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers a callback function that should be called when the control receives a blur event
  onTouched = () => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Optional method to react to the control being disabled/enabled
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
    this.onChange(this.isChecked); // Call this when the value changes
    this.onTouched(); // Call this when the control has been touched
  }
}
