<div class="modal">
    <h3 class="modal__title">Exportar valoraciones a CSV</h3>
    <p class="modal__description">Mediante este proceso podrás exportar todas tus valoraciones a un fichero CSV</p>
    <div class="modal__config">
        <div class="modal__config-item">
            <div *ngIf="tagsDropdownItems | async as dropDownItems">
                <app-dropdown #tagsDropdown [defaultFirst]="true" [items]="dropDownItems"></app-dropdown>
            </div>
        </div>
        <div class="modal__config-item">
            <app-dropdown #testTypeDropdown [defaultFirst]="true" [items]="testTypesDropdownItems"></app-dropdown>
        </div>
    </div>
    <div class="modal__actions">
        <button class="button-cta-2" (click)="export()"
            [disabled]="working">{{working ? 'Exportando...' : 'Exportar'}}</button>
        <button class="cancel-button button-plain-2" (click)="cancel();">Cancelar</button>
    </div>
</div>