import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { firebaseTimestampFromDate, safeToPromise } from 'src/app/utils/utils';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, shareReplay } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import {
  ClientCorrectiveSession,
  CorrectiveSessionSectionsDef,
  CorrectiveSessionProtocolDef,
  getCommentsClientCorrectiveSessionSection,
  getTipsClientCorrectiveSession,
  setCommentsClientCorrectiveSessionSection,
  setTipsClientCorrectiveSession,
} from 'src/shared/corrective-session-definition.outside';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ExerciseService } from 'src/app/services/exercise.service';

type SelectedTab = 'none' | 'description' | 'prescription' | 'comments';

@Component({
  selector: 'app-client-corrective-session',
  templateUrl: './client-corrective-session.component.html',
  styleUrl: './client-corrective-session.component.scss',
})
export class ClientCorrectiveSessionComponent implements OnInit {
  clientCorrectiveSessionId$: Observable<string>;
  clientCorrectiveSession$: Observable<ClientCorrectiveSession>;
  clientId: string;
  client$: Observable<Client>;
  isUserClientView: boolean;
  initDone = false;
  displayedColumns: string[] = ['position', 'name'];
  selectedTabs: SelectedTab[] = [];
  clientCorrectiveSession: ClientCorrectiveSession;
  correctiveSessionProtocolDef: CorrectiveSessionProtocolDef;

  preBuiltDataSources = {};
  preBuiltDisplayedColumns = {};

  copySharedUrlButton = {
    faClass: 'far fa-copy',
    action: () => {
      this.clipboardService.copyToClipboard(this.getShareURL());
    },
  };

  generatingReport = false;

  constructor(
    private correctiveSessionsService: CorrectiveSessionsService,
    private route: ActivatedRoute,
    private clients: ClientsService,
    public nav: NavigationService,
    private clipboardService: ClipboardService,
    private reportsServices: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private snackbarService: SnackbarService,
    private exerciseService: ExerciseService,
  ) {
    this.generateReport = this.generateReport.bind(this);
  }

  async ngOnInit() {
    this.isUserClientView = this.route.snapshot.data['isUserClientView'];
    this.clientCorrectiveSessionId$ = this.route.paramMap.pipe(
      map((params) => params.get('id')),
    );
    this.clientCorrectiveSession = await safeToPromise(
      this.clientCorrectiveSessionId$.pipe(
        switchMap((id) => {
          return this.correctiveSessionsService.getClientCorrectiveSession$(id);
        }),
        take(1),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    );
    this.clientId = this.clientCorrectiveSession.client;
    this.clients.setCurrentClient(this.clientId);
    this.client$ = this.clients.currentClient$;
    // .subscribe((currentClient) => {
    //   console.log('this.clients.currentClient$',this.clients.currentClient$)
    //   return currentClient
    // })
    await this.init();
    this.initDone = true;
  }

  async backToClient() {
    this.nav.goToClient(this.clientId);
  }

  async init() {
    this.correctiveSessionProtocolDef =
      await this.correctiveSessionsService.getCorrectiveSessionProtocol(
        this.clientCorrectiveSession.correctiveSessionProtocolDefId,
      );

    // First, load all exercise details
    for (const section of this.correctiveSessionProtocolDef.sections) {
      if (section.sectionExercises) {
        await this.exerciseService.loadMultipleExerciseDetails(
          section.sectionExercises.map((e) => e.exerciseId),
        );
      }
    }

    // Then, build table data for all sections
    for (const section of this.correctiveSessionProtocolDef.sections) {
      this.preBuildTableData(section);
    }
  }

  preBuildTableData(section: CorrectiveSessionSectionsDef) {
    this.preBuiltDataSources[section.localId] =
      this.buildTableDataSource(section);
    this.preBuiltDisplayedColumns[section.localId] =
      this.getDisplayedColumns(section);
  }

  onDateChanged(date: any) {
    this.clientCorrectiveSession.createdAt = firebaseTimestampFromDate(
      date.value,
    );
    this.updateClientCorrectiveSession();
  }

  updateClientCorrectiveSession() {
    if (this.isUserClientView) {
      return;
    }
    this.correctiveSessionsService.updateClientCorrectiveSession({
      ...this.clientCorrectiveSession,
    });
  }

  buildTableDataSource(sectionDef: CorrectiveSessionSectionsDef) {
    if (sectionDef.tableMode !== 'xy') {
      const result = this.buildTableDataSourceX(sectionDef);
      return result;
    } else {
      const result = this.buildTableDataSourceXY(sectionDef);
      return result;
    }
  }

  buildTableDataSourceX(sectionDef: CorrectiveSessionSectionsDef) {
    const result = [];
    const numberOfRows: number = this.getNumberOfRows(sectionDef.localId);
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      for (let index2 = 0; index2 < sectionDef.fields.length; index2++) {
        const field = sectionDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          sectionDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    return result;
  }

  buildTableDataSourceXY(sectionDef: CorrectiveSessionSectionsDef) {
    // for new version with exercises entity
    if (sectionDef.sectionExercises) {
      const result = [];
      const numberOfRows: number = sectionDef.sectionExercises.length;

      for (let index = 0; index < numberOfRows; index++) {
        const exerciseDetails = this.getExerciseDetails(
          sectionDef.sectionExercises[index].exerciseId,
        );

        if (!exerciseDetails) {
          console.warn(
            'exerciseDetails not found for',
            sectionDef.sectionExercises[index].exerciseId,
          );
          continue;
        }

        const item: any = {};
        item.rowTitle = exerciseDetails.title;
        for (let index2 = 0; index2 < sectionDef.fields.length; index2++) {
          //skip index 6 if isclientview
          if (this.isUserClientView && index2 === 6) {
            continue;
          }

          const field = sectionDef.fields[index2];
          item[`${field.localId}`] = this.getValueForExerciseText(
            sectionDef.localId,
            field.localId,
            sectionDef.sectionExercises[index].key,
          );
        }
        item.index = index;
        item.sectionExerciseKey = sectionDef.sectionExercises[index].key;
        result.push(item);
      }
      return result;
    }

    // old version with tableRowFields
    const result = [];
    if (!sectionDef.tableRowFields) {
      return result;
    }
    if (!sectionDef.fields) {
      return result;
    }
    const numberOfRows: number = sectionDef.tableRowFields.length;
    for (let index = 0; index < numberOfRows; index++) {
      const item: any = {};
      item.rowTitle = sectionDef.tableRowFields[index].title;
      for (let index2 = 0; index2 < sectionDef.fields.length; index2++) {
        const field = sectionDef.fields[index2];
        item[`${field.localId}`] = this.getValueTableText(
          sectionDef.localId,
          field.localId,
          index,
        );
      }
      item.index = index;
      result.push(item);
    }
    return result;
  }

  getNumberOfRows(sectionLocalId: number) {
    let numberOfRows: number =
      this.clientCorrectiveSession[`${sectionLocalId}-rows`];
    // console.log('number of row', numberOfRows);

    if (!numberOfRows) {
      numberOfRows = 0;
    }

    return numberOfRows;
  }

  addRow(sectionLocalId: number) {
    this.clientCorrectiveSession[`${sectionLocalId}-rows`] =
      this.getNumberOfRows(sectionLocalId) + 1;
    const section = this.correctiveSessionProtocolDef.sections.find(
      (e) => e.localId === sectionLocalId,
    );
    this.preBuildTableData(section);
    this.updateClientCorrectiveSession();
  }

  removeRow(sectionLocalId: number) {
    if (this.getNumberOfRows(sectionLocalId) === 0) {
      return;
    }
    this.clientCorrectiveSession[`${sectionLocalId}-rows`] =
      this.getNumberOfRows(sectionLocalId) - 1;
    const section = this.correctiveSessionProtocolDef.sections.find(
      (e) => e.localId === sectionLocalId,
    );
    this.preBuildTableData(section);
    this.updateClientCorrectiveSession();
  }

  getDisplayedColumns(sectionDef: CorrectiveSessionSectionsDef) {
    const result: string[] = [];
    if (!sectionDef.fields) {
      return result;
    }
    if (sectionDef.tableMode === 'xy') {
      result.push('rowTitle');
    }
    for (let index = 0; index < sectionDef.fields.length; index++) {
      if (this.isUserClientView && index === 6) {
        continue;
      }
      const field = sectionDef.fields[index];
      result.push(field.localId + '');
    }
    // console.log('displayed columns', result);

    return result;
  }

  getValue(sectionLocalId: number, fieldLocalId: number) {
    if (
      this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`] ===
      undefined
    ) {
      return -1;
    }
    const result =
      this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`];
    return result;
  }

  getValueTable(sectionLocalId: number, fieldLocalId: number, index: number) {
    const key = `${sectionLocalId}-${fieldLocalId}-${index}`;
    const value = this.clientCorrectiveSession[key];
    return value === undefined ? -1 : value;
  }

  getValueTableText(
    sectionLocalId: number,
    fieldLocalId: number,
    index: number,
  ) {
    const key = `${sectionLocalId}-${fieldLocalId}-${index}`;
    const value = this.clientCorrectiveSession[key];
    return value === undefined ? '' : value;
  }

  getRoundsBySection(sectionIndex: number) {
    if (!this.clientCorrectiveSession.roundsBySection) {
      return 1;
    }
    return this.clientCorrectiveSession.roundsBySection[sectionIndex];
  }

  setRoundsBySection(sectionIndex: number, value: number) {
    if (!this.clientCorrectiveSession.roundsBySection) {
      this.clientCorrectiveSession.roundsBySection = [];
    }
    this.clientCorrectiveSession.roundsBySection[sectionIndex] = value;
    this.updateClientCorrectiveSession();
  }

  formatTime(timeInSeconds: number | undefined): string | undefined {
    if (timeInSeconds === undefined) {
      return undefined;
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  getDurationBySection(sectionIndex: number) {
    if (!this.clientCorrectiveSession.durationBySection) {
      return this.formatTime(0);
    }
    const duration =
      this.clientCorrectiveSession.durationBySection[sectionIndex];
    return this.formatTime(duration);
  }

  setDurationBySection(sectionIndex: number, value: number) {
    if (!this.clientCorrectiveSession.durationBySection) {
      this.clientCorrectiveSession.durationBySection = [];
    }
    this.clientCorrectiveSession.durationBySection[sectionIndex] = value;
    this.updateClientCorrectiveSession();
  }

  getValueForExercise(
    sectionLocalId: number,
    fieldLocalId: number,
    fieldKey: string,
  ) {
    const key = `${sectionLocalId}-${fieldLocalId}-${fieldKey}`;
    const value = this.clientCorrectiveSession[key];
    return value;
  }

  getValueForExerciseText(
    sectionLocalId: number,
    fieldLocalId: number,
    fieldKey: string,
  ) {
    const key = `${sectionLocalId}-${fieldLocalId}-${fieldKey}`;
    const value = this.clientCorrectiveSession[key];
    return value === undefined ? '' : value;
  }

  getValueText(sectionLocalId: number, fieldLocalId: number) {
    if (
      this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`] ===
      undefined
    ) {
      return '';
    }
    const result =
      this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`];
    return result;
  }

  getTimeValueForExercise(
    sectionLocalId: number,
    fieldLocalId: number,
    fieldKey: string,
  ) {
    const value = this.getValueForExercise(
      sectionLocalId,
      fieldLocalId,
      fieldKey,
    );
    // TODO: better typing for this.getValueForExercise return type
    if (!value || value === '0') {
      return '-';
    }
    return this.formatTime(value) || '-';
  }

  setValue(sectionLocalId: number, fieldLocalId: number, value) {
    this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`] = value;
    this.updateClientCorrectiveSession();
  }

  setValueText(sectionLocalId: number, fieldLocalId: number, value) {
    this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}`] = value;
    this.updateClientCorrectiveSession();
  }

  setValueForExercise(
    sectionLocalId: number,
    fieldLocalId: number,
    sectionExerciseKey: string,
    value,
  ) {
    this.clientCorrectiveSession[
      `${sectionLocalId}-${fieldLocalId}-${sectionExerciseKey}`
    ] = value;
    this.updateClientCorrectiveSession();
  }

  setValueTextTable(
    sectionLocalId: number,
    fieldLocalId: number,
    index: number,
    value,
  ) {
    this.clientCorrectiveSession[`${sectionLocalId}-${fieldLocalId}-${index}`] =
      value;
    this.updateClientCorrectiveSession();
  }

  onValueChanged(
    event: any,
    sectionExerciseKey: string,
    sectionDefLocalId: number,
    fieldLocalId: number,
    index: number,
  ) {
    if (sectionExerciseKey) {
      this.setValueForExercise(
        sectionDefLocalId,
        fieldLocalId,
        sectionExerciseKey,
        event,
      );
    } else {
      this.setValueTextTable(sectionDefLocalId, fieldLocalId, index, event);
    }
  }

  getValueLF(sectionLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.clientCorrectiveSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ] === undefined
    ) {
      return -1;
    }
    const result =
      this.clientCorrectiveSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ];
    return result;
  }

  getValueTextLF(sectionLocalId: number, fieldLocalId: number, right: boolean) {
    if (
      this.clientCorrectiveSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ] === undefined
    ) {
      return '';
    }
    const result =
      this.clientCorrectiveSession[
        `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
      ];
    return result;
  }

  setValueLF(
    sectionLocalId: number,
    fieldLocalId: number,
    value,
    right: boolean,
  ) {
    this.clientCorrectiveSession[
      `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
    ] = value;

    this.updateClientCorrectiveSession();
  }

  setValueTextLF(
    sectionLocalId: number,
    fieldLocalId: number,
    value,
    right: boolean,
  ) {
    this.clientCorrectiveSession[
      `${sectionLocalId}-${fieldLocalId}--${right ? 'r' : 'l'}`
    ] = value;
    this.updateClientCorrectiveSession();
  }

  onImageChanged() {
    this.updateClientCorrectiveSession();
  }

  getComments(sectionDef: CorrectiveSessionSectionsDef) {
    return getCommentsClientCorrectiveSessionSection(
      sectionDef,
      this.clientCorrectiveSession,
    );
  }

  onCommentsChanged(sectionDef: CorrectiveSessionSectionsDef, event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setCommentsClientCorrectiveSessionSection(
      sectionDef,
      this.clientCorrectiveSession,
      value,
    );
    this.updateClientCorrectiveSession();
  }

  getTips() {
    return getTipsClientCorrectiveSession(this.clientCorrectiveSession);
  }

  onTipsChanged(event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    setTipsClientCorrectiveSession(this.clientCorrectiveSession, value);
    this.updateClientCorrectiveSession();
  }

  setLinks(value: any) {
    this.clientCorrectiveSession.links = value;
    this.updateClientCorrectiveSession();
  }

  processLineBreak(text: string) {
    text = text.replace(new RegExp('\n', 'g'), '<br />');
    return text;
  }

  getExerciseDetails(exerciseId: string) {
    return this.exerciseService.getExerciseDetailsFromCache(exerciseId);
  }

  getShareURL() {
    return this.correctiveSessionsService.getClientCorrectiveSessionShareUrl(
      this.clientCorrectiveSession,
    );
  }

  async generateReport() {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([this.clientCorrectiveSession]);
    } catch (e) {
      console.error('gen report error', e);
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }

  getFieldOptionText(sectionDef: any, field: any, element: any): string {
    let value;
    if (element.sectionExerciseKey || element.key) {
      const exerciseKey = element.sectionExerciseKey ?? element.key; // here can come element from material table or sectionExercise on images carousel
      value = this.getValueForExercise(
        sectionDef.localId,
        field.localId,
        exerciseKey,
      );
    } else {
      value = this.getValueTable(
        sectionDef.localId,
        field.localId,
        element.index,
      );
    }

    if (value === undefined || value === -1) {
      return '';
    }

    return field.options[value] || '';
  }

  handleTabChange(tab: SelectedTab, sectionIndex: number) {
    if (this.selectedTabs[sectionIndex] === tab) {
      this.selectedTabs[sectionIndex] = 'none';
    } else {
      this.selectedTabs[sectionIndex] = tab;
      // NOTE: Rebuild table data for all sections when opening prescription tab (to solve a bug that doesn't update table value after editing and then closing and opening prescription tab)
      if (tab === 'prescription') {
        const section =
          this.correctiveSessionProtocolDef.sections[sectionIndex];
        this.preBuildTableData(section);
      }
    }
  }
}
