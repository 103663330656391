import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { Client } from 'src/app/models/client.model';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit, OnDestroy {
  newClientForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    surname: new UntypedFormControl(''),
  });

  subscriptions: Subscription[] = [];

  thumbnailText = '';

  submiting: boolean;
  submitButtonLabel: string;

  constructor(
    private clientsService: ClientsService,
    private nav: NavigationService,
  ) {}

  ngOnInit() {
    this.initSubscriptions();
    this.setSubmitButtonState(false);
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  initSubscriptions() {
    this.subscriptions.push(
      this.newClientForm.valueChanges.subscribe((val) => this.onChanges(val)),
    );
  }

  destroySubscriptions() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onChanges({ name, surname }) {
    this.thumbnailText = this.clientsService.getThumbnailText({
      name: name,
      surname: surname,
    });
  }

  async onSubmit() {
    const client: Omit<Client, 'id'> = {
      name: this.newClientForm.value.name,
      surname: this.newClientForm.value.surname,
    };
    this.setSubmitButtonState(true);
    const result = await this.clientsService.addClient(client);
    this.setSubmitButtonState(false);
    if (result) this.nav.goToHome();
  }

  setSubmitButtonState(submiting: boolean) {
    this.submiting = submiting;
    if (submiting) {
      this.submitButtonLabel = 'Añadiendo...';
    } else {
      this.submitButtonLabel = 'Guardar cliente';
    }
  }
}
