import { NgModule } from '@angular/core';
import { create, Svg } from '@svgdotjs/svg.js';
import interact from 'interactjs';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { EditItem } from './edit-items.model';

export interface CrossItemData {
    centerX: number;
    centerY: number;
    width: number;
    height: number;
}

export class CrossItem extends EditItem {
    type = 'cross';
    data: CrossItemData;

    dotSize = 30;
    sDotSize = 5;
    lineWidth = 2;

    static create(centerX: number, centerY: number, w: number, h: number) {
        const data: CrossItemData = {
            centerX,
            centerY,
            width: w,
            height: h
        };
        return new CrossItem(data);
    }

    getActionMove(): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                this.data.centerX = this.data.centerX + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                this.data.centerY = this.data.centerY + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    getActionResize(direction: 'top' | 'bottom' | 'left' | 'right'): DraggableOptions {
        return {
            // cursorChecker: () => {
            //     return null;
            // },
            onstart: (event) => {
                this.emitSelectedEvent();
            },
            onmove: (event) => {
                if (direction === 'top') {
                    this.data.height = this.data.height + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'bottom') {
                    this.data.height = this.data.height - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeY(event.dy);
                } else if (direction === 'left') {
                    this.data.width = this.data.width - (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                } else if (direction === 'right') {
                    this.data.width = this.data.width + (1 / this.drawer.zoomLevel) * this.drawer.absoluteToRelativeX(event.dx);
                }
                this.update();
                // rect.x(rect.x() + (1 / 1) * 1 / this.zoomLevel * event.dx);
                // rect.y(rect.y() + (1 / 1) * 1 / this.zoomLevel * event.dy);
            }
        };
    }

    draw() {

        const topBottom = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height),
            this.drawer.relativeToAbsoluteX(this.data.centerX),
            this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });
        const leftRight = this.drawer.svgObject.line(
            this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width),
            this.drawer.relativeToAbsoluteY(this.data.centerY),
            this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width),
            this.drawer.relativeToAbsoluteY(this.data.centerY)).fill(this.color).stroke({ color: this.color, width: this.lineWidth });

        const centerS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const topS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height));
        const bottomS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height));
        const leftS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const rightS = this.drawer.svgObject.circle(this.sDotSize).fill(this.color).opacity(1).center(this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));

        const center = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const top = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY + this.data.height));
        const bottom = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX), this.drawer.relativeToAbsoluteY(this.data.centerY - this.data.height));
        const left = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX - this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));
        const right = this.drawer.svgObject.circle(this.dotSize).fill(this.color).opacity(this.selected ? .6 : .01).center(this.drawer.relativeToAbsoluteX(this.data.centerX + this.data.width), this.drawer.relativeToAbsoluteY(this.data.centerY));

        this.svgElements.push(center);
        this.svgElements.push(top);
        this.svgElements.push(bottom);
        this.svgElements.push(left);
        this.svgElements.push(right);
        this.svgElements.push(topBottom);
        this.svgElements.push(leftRight);
        this.svgElements.push(centerS);
        this.svgElements.push(topS);
        this.svgElements.push(bottomS);
        this.svgElements.push(leftS);
        this.svgElements.push(rightS);

        if (this.editable) {
            interact(center.node)
                .draggable(this.getActionMove())
                .on('tap', (event) => {
                    this.drawer.selectedEvent(this.index);
                });
            interact(top.node)
                .draggable(
                    this.getActionResize('top')
                );
            interact(bottom.node)
                .draggable(
                    this.getActionResize('bottom')
                );
            interact(left.node)
                .draggable(
                    this.getActionResize('left')
                );
            interact(right.node)
                .draggable(
                    this.getActionResize('right')
                );
        }

    }

    emitSelectedEvent() {
        this.drawer.selectedEvent(this.index);
    }
}
