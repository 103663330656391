import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LearnService } from 'src/app/services/learn.service';
import { Subscription } from 'rxjs';
import { LearnSectionHostDirective } from 'src/app/directives/learn-section-host.directive';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss']
})
export class LearnComponent implements OnInit, OnDestroy {

  open = false;

  sub = new Array<Subscription>();

  @ViewChild(LearnSectionHostDirective, {static: true}) learnSectionHost: LearnSectionHostDirective;

  constructor(private learnService: LearnService) { }

  ngOnInit() {
    this.sub.push(this.learnService.panelOpen.subscribe(open => {
      if (open) {
        this.loadSection();
      }
      this.open = open;
    }));
  }

  ngOnDestroy() {
    this.sub.forEach(sub => sub.unsubscribe());
  }

  close() {
    history.back();
  }

  loadSection() {
    const viewContainerRef = this.learnSectionHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(
      this.learnService.selectedSection.component
    );
    if (this.learnService.selectedSection.data) {
      componentRef.instance.data = this.learnService.selectedSection.data;
    }
  }

}
