<div
  class="fixed left-0 top-0 h-screen w-screen overflow-hidden transition-all duration-700"
  [class.nvm-box-hidden]="!isOpen"
>
  <div
    class="h-full w-full overflow-y-auto bg-bg pt-16 transition-all duration-700"
    [class.nvm-hidden]="!isOpen"
  >
    <div
      class="absolute right-8 top-5 cursor-pointer text-2xl"
      (click)="close()"
    >
      <i class="fas fa-times"></i>
    </div>
    <div class="mx-auto max-w-lg px-5">
      <!-- <h1 class="text-xl mb-5 text-center">¿Qué valoración quieres realizar?</h1> -->
      <div
        class="mb-7 flex flex-col items-center rounded-lg bg-white p-5 pb-7 shadow-lg"
      >
        <p class="mb-2 text-center text-2xl font-bold">Protocolo VAF Raquis</p>
        <p class="mb-5">Valora la columna vertebral</p>
        <div class="grid w-full auto-cols-fr grid-flow-col gap-x-5">
          <div
            (click)="createTest('sagital')"
            class="flex cursor-pointer flex-col items-center rounded-lg bg-intecc py-5 text-white shadow transition-all duration-200 hover:shadow-lg"
          >
            <i class="fa-solid fa-bullseye mb-1 text-2xl"></i>
            <p class="text-center font-bold">Morfotipo</p>
          </div>
          <!-- <div (click)="createTest('sagital-flechas')"
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-ruler-vertical text-2xl mb-1"></i>
                        <p class="font-bold text-center">Flechas</p>
                    </div> -->
        </div>
      </div>

      <div
        class="mb-7 flex flex-col items-center rounded-lg bg-white p-5 pb-7 shadow-lg"
      >
        <p class="mb-2 text-center text-2xl font-bold">Protocolo VAF Posture</p>
        <p class="mb-5">Valora la higiene postural</p>
        <div class="grid w-full auto-cols-fr grid-flow-col gap-x-5">
          <div
            (click)="createTest('posture')"
            class="flex cursor-pointer flex-col items-center rounded-lg bg-intecc py-5 text-white shadow transition-all duration-200 hover:shadow-lg"
          >
            <i class="fa-solid fa-bullseye mb-1 text-2xl"></i>
            <p class="text-center font-bold">Protocolo VAF Posture</p>
          </div>
          <!-- <div (click)="createTest('posture')"
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-compress-arrows-alt text-2xl mb-1"></i>
                        <p class="font-bold text-center">Analítica</p>
                    </div> -->
        </div>
      </div>

      <!-- <div class="mb-7 flex flex-col items-center bg-white p-5 pb-7 shadow-lg rounded-lg">
                <p class="text-2xl font-bold mb-2">Ascendentes</p>
                <p class="mb-5">Analiza el tobillo y el pie</p>
                <div class="grid grid-flow-col auto-cols-fr w-full gap-x-5">
                    <div (click)="createTest('foot')"
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-shoe-prints text-2xl mb-1"></i>
                        <p class="font-bold text-center">Protocolo VAF Foot</p>
                    </div>
                </div>
            </div> -->

      <div
        class="mb-7 flex flex-col items-center rounded-lg bg-white p-5 pb-7 shadow-lg"
      >
        <p class="mb-2 text-center text-2xl font-bold">
          Protocolo VAF ROM - Avatar
        </p>
        <p class="mb-5">Valora las articulaciones</p>
        <div class="grid w-full auto-cols-fr grid-flow-col gap-x-5">
          <!-- <div (click)="createTest('rom')"
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200 opacity-70">
                        <i class="fas fa-drafting-compass text-2xl mb-1"></i>
                        <p class="font-bold text-center">Rango de movimiento (BETA)</p>
                    </div> -->
          <div
            (click)="createTest('rom-short')"
            class="flex cursor-pointer flex-col items-center rounded-lg bg-intecc py-5 text-white shadow transition-all duration-200 hover:shadow-lg"
          >
            <i class="fa-solid fa-bullseye mb-1 text-2xl"></i>
            <p class="text-center font-bold">Protocolo VAF ROM</p>
          </div>
          <!-- <div (click)="createTest('rom')"
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-street-view text-2xl mb-1"></i>
                        <p class="font-bold text-center">Protocolo</p>
                    </div> -->
        </div>
      </div>

      <!-- <div class="mb-7 flex flex-col items-center bg-white p-5 pb-7 shadow-lg rounded-lg">
                <p class="text-2xl font-bold mb-2">Test personalizados</p>
                <p class="mb-5">Diseña tus valoraciones</p>
                <div class="grid grid-flow-col auto-cols-fr w-full gap-x-5">
                    <div
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200 opacity-70">
                        <i class="fas fa-cubes text-2xl mb-1"></i>
                        <p class="font-bold text-center">My VAF</p>
                    </div>
                </div>
            </div> -->

      <!-- <div class="mb-7 flex flex-col items-center bg-white p-5 pb-7 shadow-lg rounded-lg">
                <p class="text-2xl font-bold mb-2">Composición corporal</p>
                <p class="mb-5">Cuantifica el % de músculo y grasa</p>
                <div class="grid grid-flow-col auto-cols-fr w-full gap-x-5">
                    <div
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-weight text-2xl mb-1"></i>
                        <p class="font-bold text-center">Bioimpedancia</p>
                    </div>
                    <div
                        class=" bg-intecc text-white rounded-lg  flex flex-col items-center py-5 shadow hover:shadow-lg cursor-pointer transition-all duration-200">
                        <i class="fas fa-percentage text-2xl mb-1"></i>
                        <p class="font-bold text-center">Antropometría</p>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</div>
