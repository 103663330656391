import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { TrainingSessionsService } from 'src/app/services/training-sessions.service';
import { TrainingSessionProtocolDef } from '../../../../../Server/functions/src/shared/training-session-definition.model';
import { safeToPromise } from 'src/app/utils/utils';
@Component({
  selector: 'app-duplicate-training-session',
  templateUrl: './duplicate-training-session.component.html',
})
export class DuplicateTrainingSessionProtocolComponent implements OnInit {
  trainingSession: TrainingSessionProtocolDef;

  duplicating = false;

  constructor(
    private route: ActivatedRoute,
    private trainingSessionsService: TrainingSessionsService,
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const protocolId = await safeToPromise(
      this.route.params.pipe(
        map((params) => {
          return params.id;
        }),
        take(1),
      ),
    );
    this.trainingSession =
      await this.trainingSessionsService.getTrainingSessionProtocol(protocolId);
  }

  async duplicate() {
    const user = await this.auth.getUser();
    this.trainingSession.owner = user.uid;
    const id =
      await this.trainingSessionsService.duplicateTrainingSessionProtocol(
        this.trainingSession,
      );
    this.router.navigate(['/']);
  }
}
