import { CreateEditClientModalComponent } from '../components/create-edit-client-modal/create-edit-client-modal.component';
import { ExportTestsModalComponent } from '../components/export-tests-modal/export-tests-modal.component';
import { GifModalComponent } from '../components/gif-modal/gif-modal.component';
import { AccountModalComponent } from '../components/account-modal/account-modal.component';
import { CancelSubModalComponent } from '../components/cancel-sub-modal/cancel-sub-modal.component';
import { NewUpgradeModalComponent } from '../modals/new-upgrade-modal/new-upgrade-modal.component';
import { FilterSearchModalComponent } from '../modals/filter-search-modal/filter-search-modal.component';
import { ClientMediaComponent } from '../side-modals/client-media/client-media.component';
import { MediaEditComponent } from '../bottom-modals/media-edit/media-edit.component';
import { AvatarComponent } from '../side-modals/avatar/avatar.component';
import { TrainingAvatarComponent } from '../components/training-avatar/training-avatar.component';
import { LinkClientModalComponent } from '../components/link-client-modal/link-client-modal.component';

export const Modals = {
  'create-edit-client': CreateEditClientModalComponent,
  'export-tests': ExportTestsModalComponent,
  gif: GifModalComponent,
  account: AccountModalComponent,
  'cancel-sub': CancelSubModalComponent,
  'new-upgrade': NewUpgradeModalComponent,
  'filter-search-modal': FilterSearchModalComponent,
  'client-media': ClientMediaComponent,
  avatar: AvatarComponent,
  'training-avatar': TrainingAvatarComponent,
  'media-edit': MediaEditComponent,
  'link-client': LinkClientModalComponent,
} as const;
