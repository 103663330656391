import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Modal } from 'src/app/models/modal.model';
import { Client } from 'src/app/models/client.model';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-create-edit-client-modal',
  templateUrl: './create-edit-client-modal.component.html',
  styleUrls: ['./create-edit-client-modal.component.scss'],
})
export class CreateEditClientModalComponent implements OnInit, Modal {
  onClose = new Subject<any>();
  result = false;
  @Input()
  data: { client: Client | Omit<Client, 'id'> };

  editClientForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl(''),
    dni: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    job: new FormControl(''),
    birthDate: new FormControl(''),
    sharedFolderUrl: new FormControl(''),
    displaySharedFolderUrl: new FormControl(false),
    questionnaireUrl: new FormControl(''),
    displayQuestionnaireUrl: new FormControl(false),
    acceptanceUrl: new FormControl(''),
    displayAcceptanceUrl: new FormControl(false),
  });

  submiting = false;

  constructor(public clientsService: ClientsService) {}

  ngOnInit() {
    if (!this.data.client) {
      this.data.client = { name: '', surname: '' };
    }
    this.editClientForm.setValue({
      name: this.data.client.name,
      surname: this.data.client.surname,
      dni: this.data.client.dni ? this.data.client.dni : '',
      phone: this.data.client.phone ? this.data.client.phone : '',
      job: this.data.client.job ? this.data.client.job : '',
      email: this.data.client.email ? this.data.client.email : '',
      birthDate: this.data.client.birthDate
        ? this.data.client.birthDate.toDate()
        : '',
      sharedFolderUrl: this.data.client.sharedFolderUrl
        ? this.data.client.sharedFolderUrl
        : '',
      displaySharedFolderUrl: this.data.client.displaySharedFolderUrl || false,
      questionnaireUrl: this.data.client.questionnaireUrl
        ? this.data.client.questionnaireUrl
        : '',
      displayQuestionnaireUrl:
        this.data.client.displayQuestionnaireUrl || false,
      acceptanceUrl: this.data.client.acceptanceUrl
        ? this.data.client.acceptanceUrl
        : '',
      displayAcceptanceUrl: this.data.client.displayAcceptanceUrl || false,
    });
  }

  // do not use directly. Use history.back(); (Override Back Button)
  historyBackClose() {
    this.onClose.next('');
  }

  close() {
    history.back();
  }

  async onSubmit() {
    this.submiting = true;

    this.data.client.name = this.editClientForm.value.name?.trim();
    this.data.client.surname = this.editClientForm.value.surname?.trim();
    this.data.client.dni = this.editClientForm.value.dni?.trim();
    this.data.client.job = this.editClientForm.value.job?.trim();
    this.data.client.email = this.editClientForm.value.email?.trim();
    this.data.client.phone = this.editClientForm.value.phone?.trim();
    this.data.client.birthDate = this.editClientForm.value.birthDate;
    this.data.client.sharedFolderUrl =
      this.editClientForm.value.sharedFolderUrl?.trim();
    this.data.client.displaySharedFolderUrl =
      this.editClientForm.value.displaySharedFolderUrl;
    this.data.client.questionnaireUrl =
      this.editClientForm.value.questionnaireUrl?.trim();
    this.data.client.displayQuestionnaireUrl =
      this.editClientForm.value.displayQuestionnaireUrl;
    this.data.client.acceptanceUrl =
      this.editClientForm.value.acceptanceUrl?.trim();
    this.data.client.displayAcceptanceUrl =
      this.editClientForm.value.displayAcceptanceUrl;

    if (!('id' in this.data.client)) {
      await this.clientsService.addClient(this.data.client);
    } else {
      await this.clientsService.updateClient(this.data.client);
    }

    this.submiting = false;
    history.back();
  }
}
