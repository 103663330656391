import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { switchMap, tap, take, map, shareReplay } from 'rxjs/operators';
import { TAGS_PATH, Tags, EmptyTags, Tag, NoTag } from '../models/tags.model';
import { Observable, of } from 'rxjs';
import { SentryService } from './sentry.service';
import { safeToPromise } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  currentUserTags$: Observable<Tags>;

  constructor(
    private auth: AuthService,
    private fs: FirestoreService,
    private sentry: SentryService,
  ) {
    this.init();
  }

  init() {
    // TODO ADD SHAREREPLAY
    this.currentUserTags$ = this.auth.user$.pipe(
      switchMap((user) => {
        if (!!user) {
          return this.fs.doc$<Tags>(`${TAGS_PATH}/${user.uid}`);
        }
        return of(undefined);
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('Tags', 1);
      }),
      map((tags) => {
        if (tags === undefined) {
          tags = EmptyTags;
        }
        return tags;
      }),
      shareReplay(1),
    );
  }

  getTags$() {
    return this.currentUserTags$;
  }

  async getTags() {
    return await safeToPromise(this.getTags$().pipe(take(1)));
  }

  async updateTags(tags: Tags) {
    const uid = (await this.auth.getUser()).uid;
    return await this.fs.upsert(`${TAGS_PATH}/${uid}`, tags);
  }

  async addNewTag(tag: Tag) {
    const tags = await this.getTags();
    tags.lastId++;
    tag.id = tags.lastId;
    tags.tags.push(tag);
    await this.updateTags(tags);
    return tag;
  }

  async deleteTag(tag: Tag) {
    const tags = await this.getTags();
    tags.tags = tags.tags.filter((currentTag) => currentTag.id !== tag.id);
    await this.updateTags(tags);
  }

  getTag(tagId: number, tags: Tags) {
    if (!tagId) {
      return NoTag;
    }
    const result = tags.tags.find((tag) => tag.id === tagId);
    if (!result) {
      return NoTag;
    }
    return result;
  }
}
