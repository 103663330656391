<div class="app-container" *ngIf="protocolDef">
  <app-test-header [title]="protocolDef.name"></app-test-header>

  <div class="test-info">
    <app-client-back-button
      *ngIf="!isUserClientView"
      [client]="client"
      [myVafMode]="true"
      class="mr-2"
    ></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
      <button
        *ngIf="!isUserClientView"
        (click)="picker.open()"
        class="date-selector__button"
      >
        {{ test.createdAt.toDate() | date }} <i class="fas fa-edit"></i>
      </button>
      <span *ngIf="isUserClientView">
        {{ test.createdAt.toDate() | date }}
      </span>

      <input
        style="display: none"
        (dateChange)="onDateChanged($event)"
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
      />
      <mat-datepicker
        touchUi
        #picker
        [startAt]="test.createdAt.toDate()"
      ></mat-datepicker>
    </div>
  </div>

  <div
    *ngFor="let testDef of protocolDef.tests"
    class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
  >
    <div class="flex justify-between">
      <p class="text-lg font-semibold md:text-xl">{{ testDef.name }}</p>
      <div class="flex items-center"></div>
    </div>
    <div *ngIf="testDef.description" class="mt-2">
      <p
        class="text-neutral-500"
        [innerHTML]="processLineBreak(testDef.description)"
      ></p>
    </div>
    <div class="mb-4"></div>
    <div
      *ngIf="testDef.mode !== 'table'"
      class="w-full overflow-y-scroll md:overflow-y-hidden"
    >
      <div
        *ngIf="testDef.fields; else noFields"
        style=""
        class="grid grid-cols-4 md:grid-cols-1.2fr-1fr-1fr grid-flow-col auto-cols-fr grid-rows-100-of-parent md:grid-rows-1 gap-x-5 posture-container-2 max-h-screen-3/4 md:h-auto {{
          testDef.images ? 'md:min-h-96' : ''
        }}"
      >
        <div class="col-span-2 overflow-y-scroll md:overflow-y-auto">
          <div
            *ngIf="testDef.mode !== 'table'"
            style=""
            class="grid h-auto auto-rows-auto grid-cols-1 items-center gap-x-5 gap-y-2 self-start rounded-md pr-3 md:grid-cols-2"
          >
            <ng-container *ngFor="let field of testDef.fields; index as i">
              <p>{{ field.title }}</p>

              <div
                style="
                  display: grid;
                  grid-auto-flow: column;
                  grid-auto-columns: 1fr;
                  column-gap: 10px;
                "
              >
                <ng-container *ngIf="field.leftRight" class="">
                  <ng-container *ngIf="!isUserClientView">
                    <app-test-field-dropdown
                      [mode]="field.type"
                      [options]="field.options"
                      [selectedIndex]="
                        getValueLF(testDef.localId, field.localId, false)
                      "
                      [text]="
                        getValueTextLF(testDef.localId, field.localId, false)
                      "
                      (selectedItemChanged)="
                        setValueLF(
                          testDef.localId,
                          field.localId,
                          $event,
                          false
                        )
                      "
                      [openToTop]="false"
                      (textChanged)="
                        setValueTextLF(
                          testDef.localId,
                          field.localId,
                          $event,
                          false
                        )
                      "
                    >
                    </app-test-field-dropdown>
                    <app-test-field-dropdown
                      [mode]="field.type"
                      [options]="field.options"
                      [selectedIndex]="
                        getValueLF(testDef.localId, field.localId, true)
                      "
                      [text]="
                        getValueTextLF(testDef.localId, field.localId, true)
                      "
                      (selectedItemChanged)="
                        setValueLF(testDef.localId, field.localId, $event, true)
                      "
                      [openToTop]="false"
                      (textChanged)="
                        setValueTextLF(
                          testDef.localId,
                          field.localId,
                          $event,
                          true
                        )
                      "
                    >
                    </app-test-field-dropdown>
                  </ng-container>
                  <ng-container *ngIf="isUserClientView">
                    <ng-container *ngIf="field.type === 'text'">
                      <p>
                        {{
                          getValueTextLF(testDef.localId, field.localId, false)
                        }}
                      </p>
                      <p>
                        {{
                          getValueTextLF(testDef.localId, field.localId, true)
                        }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="field.type === 'select'">
                      <p>
                        {{
                          getValueLF(testDef.localId, field.localId, false) !==
                          -1
                            ? field.options[
                                getValueLF(
                                  testDef.localId,
                                  field.localId,
                                  false
                                )
                              ]
                            : ''
                        }}
                      </p>
                      <p>
                        {{
                          getValueLF(testDef.localId, field.localId, true) !==
                          -1
                            ? field.options[
                                getValueLF(testDef.localId, field.localId, true)
                              ]
                            : ''
                        }}
                      </p>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!field.leftRight" class="">
                  <ng-container *ngIf="!isUserClientView">
                    <app-test-field-dropdown
                      [mode]="field.type"
                      [options]="field.options"
                      [selectedIndex]="getValue(testDef.localId, field.localId)"
                      [text]="getValueText(testDef.localId, field.localId)"
                      (selectedItemChanged)="
                        setValue(testDef.localId, field.localId, $event)
                      "
                      (textChanged)="
                        setValueText(testDef.localId, field.localId, $event)
                      "
                      [openToTop]="false"
                    >
                    </app-test-field-dropdown>
                  </ng-container>
                  <ng-container *ngIf="isUserClientView">
                    <p *ngIf="field.type === 'text'">
                      {{ getValueText(testDef.localId, field.localId) }}
                    </p>
                    <p *ngIf="field.type === 'select'">
                      {{
                        getValue(testDef.localId, field.localId) !== -1
                          ? field.options[
                              getValue(testDef.localId, field.localId)
                            ]
                          : ''
                      }}
                    </p>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="
            (testDef.images && testDef.images.length > 0) ||
            (testDef.fixedImages && testDef.fixedImages.length > 0)
          "
          class="custom-scroll col-span-2 w-full overflow-x-scroll pb-5 md:col-span-1"
        >
          <ng-container
            *ngIf="
              (testDef.images ? testDef.images.length : 0) +
              (testDef.fixedImages
                ? testDef.fixedImages.length
                : 0) as imageCount
            "
          >
            <div
              style="width: {{
                imageCount
              }}00%; display: grid; grid-template-columns: repeat({{
                imageCount
              }}, minmax(0, 1fr));"
              class="h-full"
            >
              <ng-container *ngFor="let image of testDef.fixedImages">
                <ng-container *ngIf="image.downloadUrl">
                  <div
                    style="width: 90%; margin: auto"
                    class="h-full bg-blue-300 rounded-md overflow-hidden {{
                      imageCount === 1 ? 'col-span-2' : ''
                    }} relative"
                  >
                    <img
                      src="{{ image.downloadUrl }}"
                      class="h-full w-full object-cover"
                      alt=""
                    />
                    <div
                      *ngIf="image.title"
                      class="absolute left-0 top-0 w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
                    >
                      <p>{{ image.title }}</p>
                    </div>
                    <div
                      class="absolute bottom-0 right-0 px-4 py-5"
                      *ngIf="!isUserClientView"
                    >
                      <a
                        *ngIf="image.link"
                        href="{{ image.link }}"
                        target="_blank"
                        class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
                      >
                        <i class="fas fa-external-link-alt text-white"></i>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div
                *ngFor="let image of testDef.images"
                style="width: 90%; margin: auto"
                class="h-full bg-blue-300 rounded-md overflow-hidden {{
                  imageCount === 1 ? 'col-span-2' : ''
                }}"
              >
                <app-test-image-view
                  [isMyVaf]="true"
                  [client]="client"
                  [myVafImageDef]="image"
                  [imageKey]="image.key"
                  [test]="test"
                  (imageChangedEvent)="onImageChanged()"
                  [isUserClientView]="isUserClientView"
                >
                </app-test-image-view>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-template #noFields> </ng-template>
    </div>

    <!-- <div *ngIf="testDef.mode === 'table'" class="w-full overflow-y-scroll md:overflow-y-hidden">
            <div *ngIf="testDef.fields; else noFields" style=""
                class="grid grid-cols-4 md:grid-cols-1.2fr-1fr-1fr md:grid-flow-col md:auto-cols-fr grid-rows-100-of-parent md:grid-rows-1 gap-x-5 table-container max-h-screen-3/4 md:h-auto {{testDef.images ? 'md:min-h-96' : ''}}">
                <div class="col-span-2 overflow-y-scroll md:overflow-y-auto">
                    <div *ngIf="testDef.mode === 'table' && testDef.tableMode !== 'xy'" style="" class="h-auto self-start rounded-md">
                        <table  mat-table [dataSource]="preBuiltDataSources[testDef.localId]" class="w-full">
                            <ng-container *ngFor="let field of testDef.fields" matColumnDef="{{field.localId}}">
                                <th mat-header-cell *matHeaderCellDef> {{ field.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <app-test-field-dropdown [tableMode]="true" [mode]="'text'"
                                        [options]="['1', '2', '3']" [text]="element[field.localId]"
                                        (textChanged)="setValueTextTable(testDef.localId, field.localId, element.index, $event)">
                                    </app-test-field-dropdown>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="preBuiltDisplayedColumns[testDef.localId]"></tr>
                            <tr mat-row *matRowDef="let row; columns: preBuiltDisplayedColumns[testDef.localId];"></tr>
                        </table>
                        <div class="flex justify-end pr-1 pt-4 pb-2">
                            <app-button class="mr-2" (clickEvent)="addRow(testDef.localId);" [text]="'Añadir'"
                                [icon]="'fas fa-plus'"></app-button>
                            <app-button (clickEvent)="removeRow(testDef.localId);" [text]="'Quitar'"
                                [icon]="'fas fas fa-minus'"></app-button>
                        </div>
                    </div>
                    <div *ngIf="testDef.tableMode === 'xy'" style="" class="h-auto self-start rounded-md">
                        <table  mat-table [dataSource]="preBuiltDataSources[testDef.localId]" class="w-full">
                            <ng-container matColumnDef="rowTitle">
                                <th mat-header-cell *matHeaderCellDef> {{testDef.tableFirstColTitle ? testDef.tableFirstColTitle : ' '}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <p class="w-full font-bold">
                                        {{element.rowTitle}} v
                                    </p>

                                </td>
                            </ng-container>
                            <ng-container *ngFor="let field of testDef.fields" matColumnDef="{{field.localId}}">
                                <th mat-header-cell *matHeaderCellDef> {{ field.title }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <app-test-field-dropdown [tableMode]="true" [mode]="'text'"
                                        [options]="['1', '2', '3']" [text]="element[field.localId]"
                                        (textChanged)="setValueTextTable(testDef.localId, field.localId, element.index, $event)">
                                    </app-test-field-dropdown>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="preBuiltDisplayedColumns[testDef.localId]"></tr>
                            <tr mat-row *matRowDef="let row; columns: preBuiltDisplayedColumns[testDef.localId];"></tr>
                        </table>

                    </div>
                </div>
                <div *ngFor="let image of testDef.images" style=""
                    class="  h-full bg-blue-300 rounded-md overflow-hidden {{testDef.images.length === 1 ? 'col-span-2' : ''}}">
                    <app-test-image-view [isMyVaf]="true" [client]="client" [myVafImageDef]="image"
                        [imageKey]="image.key" [test]="test" (imageChangedEvent)="onImageChanged()">
                    </app-test-image-view>
                </div>

            </div>

            <ng-template #noFields>

            </ng-template>
        </div> -->

    <div
      *ngIf="testDef.mode === 'table'"
      class="w-full overflow-y-scroll md:overflow-y-hidden"
    >
      <div
        *ngIf="testDef.fields; else noFields"
        class="grid grid-cols-3 md:grid-cols-1.2fr-1fr-1fr md:grid-flow-col md:auto-cols-fr grid-rows-100-of-parent md:grid-rows-1 gap-x-5 table-container max-h-screen-3/4 md:h-auto {{
          testDef.images ? 'md:min-h-96' : ''
        }}"
      >
        <div class="col-span-2 overflow-y-scroll md:overflow-y-auto">
          <div
            *ngIf="testDef.mode === 'table' && testDef.tableMode !== 'xy'"
            class="h-auto self-start rounded-md"
          >
            <table
              mat-table
              [dataSource]="preBuiltDataSources[testDef.localId]"
              class="w-full table-fixed"
            >
              <ng-container
                *ngFor="let field of testDef.fields"
                matColumnDef="{{ field.localId }}"
              >
                <th mat-header-cell *matHeaderCellDef class="font-bold">
                  {{ field.title }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="
                    border-bottom: 1px solid #e5e7eb;
                    min-width: 100px;
                    overflow: visible;
                  "
                >
                  <ng-container *ngIf="!isUserClientView">
                    <app-test-field-dropdown
                      [tableMode]="true"
                      [mode]="field.type"
                      [options]="field.options"
                      [text]="element[field.localId]"
                      [selectedIndex]="
                        getValueTable(
                          testDef.localId,
                          field.localId,
                          element.index
                        )
                      "
                      [openToTop]="false"
                      (selectedItemChanged)="
                        setValueTextTable(
                          testDef.localId,
                          field.localId,
                          element.index,
                          $event
                        )
                      "
                      (textChanged)="
                        setValueTextTable(
                          testDef.localId,
                          field.localId,
                          element.index,
                          $event
                        )
                      "
                    >
                    </app-test-field-dropdown>
                  </ng-container>
                  <ng-container *ngIf="isUserClientView">
                    <p *ngIf="field.type === 'select'">
                      {{
                        getValueTable(
                          testDef.localId,
                          field.localId,
                          element.index
                        ) !== -1
                          ? field.options[
                              getValueTable(
                                testDef.localId,
                                field.localId,
                                element.index
                              )
                            ]
                          : ''
                      }}
                    </p>
                    <p *ngIf="field.type === 'text'">
                      {{ element[field.localId] }}
                    </p>
                  </ng-container>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="preBuiltDisplayedColumns[testDef.localId]"
                class="font-bold"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: preBuiltDisplayedColumns[testDef.localId]
                "
              ></tr>
            </table>
            <div
              class="flex justify-end pb-2 pr-1 pt-4"
              *ngIf="!isUserClientView"
            >
              <app-button
                class="mr-2"
                (clickEvent)="addRow(testDef.localId)"
                [text]="'Añadir'"
                [icon]="'fas fa-plus'"
              >
              </app-button>
              <app-button
                (clickEvent)="removeRow(testDef.localId)"
                [text]="'Quitar'"
                [icon]="'fas fas fa-minus'"
              >
              </app-button>
            </div>
          </div>
          <div
            *ngIf="testDef.tableMode === 'xy'"
            style=""
            class="h-auto self-start rounded-md"
          >
            <table
              mat-table
              [dataSource]="preBuiltDataSources[testDef.localId]"
              class="w-full table-fixed"
            >
              <ng-container matColumnDef="rowTitle">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="font-bold"
                  style="width: 220px"
                >
                  {{
                    testDef.tableFirstColTitle
                      ? testDef.tableFirstColTitle
                      : ' '
                  }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="border-bottom: 1px solid #e5e7eb; min-width: 100px"
                >
                  <p class="w-full font-bold">
                    {{ element.rowTitle }}
                  </p>
                  <!-- <app-test-field-dropdown [addStyles]="'font-bold'" class="font-bold" [isEditable]="false" [tableMode]="true" [mode]="'text'"
                                    [options]="['1', '2', '3']" [text]="element.rowTitle">
                                    </app-test-field-dropdown> -->
                </td>
              </ng-container>
              <ng-container
                *ngFor="let field of testDef.fields"
                matColumnDef="{{ field.localId }}"
              >
                <th mat-header-cell *matHeaderCellDef class="font-bold">
                  {{ field.title }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="
                    border-bottom: 1px solid #e5e7eb;
                    overflow: visible;
                    min-width: 100px;
                  "
                >
                  <!-- <app-test-field-dropdown [tableMode]="true" [mode]="'text'" [options]="['1', '2', '3']"
                    [text]="element[field.localId]"
                    (textChanged)="setValueTextTable(testDef.localId, field.localId, element.index, $event)">
                  </app-test-field-dropdown> -->
                  <ng-container *ngIf="!isUserClientView">
                    <app-test-field-dropdown
                      [tableMode]="true"
                      [mode]="field.type"
                      [options]="field.options"
                      [text]="element[field.localId]"
                      [selectedIndex]="
                        getValueTable(
                          testDef.localId,
                          field.localId,
                          element.index
                        )
                      "
                      [openToTop]="false"
                      (selectedItemChanged)="
                        setValueTextTable(
                          testDef.localId,
                          field.localId,
                          element.index,
                          $event
                        )
                      "
                      (textChanged)="
                        setValueTextTable(
                          testDef.localId,
                          field.localId,
                          element.index,
                          $event
                        )
                      "
                    >
                    </app-test-field-dropdown>
                  </ng-container>
                  <ng-container *ngIf="isUserClientView">
                    <p *ngIf="field.type === 'select'">
                      {{
                        element[field.localId] !== -1
                          ? field.options[element[field.localId]]
                          : ''
                      }}
                    </p>
                    <p *ngIf="field.type === 'text'">
                      {{ element[field.localId] }}
                    </p>
                  </ng-container>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="preBuiltDisplayedColumns[testDef.localId]"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: preBuiltDisplayedColumns[testDef.localId]
                "
              ></tr>
            </table>
            <!-- <div class="flex justify-end pr-1 pt-4 pb-2">
                            <app-button class="mr-2" (clickEvent)="addRow(testDef.localId);" [text]="'Añadir'"
                                [icon]="'fas fa-plus'"></app-button>
                            <app-button (clickEvent)="removeRow(testDef.localId);" [text]="'Quitar'"
                                [icon]="'fas fas fa-minus'"></app-button>
                        </div> -->
          </div>
        </div>

        <div
          *ngIf="
            (testDef.images && testDef.images.length > 0) ||
            (testDef.fixedImages && testDef.fixedImages.length > 0)
          "
          class="custom-scroll w-full overflow-x-scroll pb-5"
        >
          <ng-container
            *ngIf="
              (testDef.images ? testDef.images.length : 0) +
              (testDef.fixedImages
                ? testDef.fixedImages.length
                : 0) as imageCount
            "
          >
            <div
              style="width: {{
                imageCount
              }}00%; display: grid; grid-template-columns: repeat({{
                imageCount
              }}, minmax(0, 1fr));"
              class="h-full"
            >
              <ng-container *ngFor="let image of testDef.fixedImages">
                <ng-container *ngIf="image.downloadUrl">
                  <div
                    style="width: 90%; margin: auto"
                    class="h-full bg-blue-300 rounded-md overflow-hidden {{
                      imageCount === 1 ? 'col-span-2' : ''
                    }} relative"
                  >
                    <img
                      src="{{ image.downloadUrl }}"
                      class="h-full w-full object-cover"
                      alt=""
                    />
                    <div
                      *ngIf="image.title"
                      class="absolute left-0 top-0 w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
                    >
                      <p>{{ image.title }}</p>
                    </div>
                    <div
                      class="absolute bottom-0 right-0 px-4 py-5"
                      *ngIf="!isUserClientView"
                    >
                      <a
                        *ngIf="image.link"
                        href="{{ image.link }}"
                        target="_blank"
                        class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
                      >
                        <i class="fas fa-external-link-alt text-white"></i>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div
                *ngFor="let image of testDef.images"
                style="width: 90%; margin: auto"
                class="h-full bg-blue-300 rounded-md overflow-hidden {{
                  imageCount === 1 ? 'col-span-2' : ''
                }}"
              >
                <app-test-image-view
                  [isMyVaf]="true"
                  [client]="client"
                  [myVafImageDef]="image"
                  [imageKey]="image.key"
                  [test]="test"
                  (imageChangedEvent)="onImageChanged()"
                  [isUserClientView]="isUserClientView"
                >
                </app-test-image-view>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <ng-template #noFields> </ng-template>
    </div>

    <div class="w-full">
      <p class="mb-4 mt-7 text-lg font-semibold">Comentarios</p>
      <textarea
        class="w-full rounded-lg border-2 border-neutral-200"
        #commentsTextArea
        name="comments"
        cols="30"
        rows="5"
        [value]="getComments(testDef)"
        (change)="onCommentsChanged(testDef, $event)"
        [readonly]="isUserClientView"
      ></textarea>
    </div>
  </div>

  <div
    class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
  >
    <div class="mb-4 flex justify-between">
      <p class="text-xl font-semibold">Recomendaciones y enlaces</p>
      <div class="flex items-center">
        <!-- <div *ngIf="item.learnId" (click)="openLearn(item.learnId)" class="mr-5  text-intecc2 cursor-pointer">
                    <i  class="fas fa-book"></i>
                </div> -->
        <!-- <app-icon-toggle [active]="!test[item.id]" (activeChange)="setSectionStatus(item.id, !$event)" ></app-icon-toggle> -->
      </div>
    </div>
    <div class="w-full overflow-y-scroll md:overflow-y-hidden"></div>
    <div class="w-full">
      <p class="mb-4 mt-7 text-lg font-semibold">Recomendaciones</p>
      <textarea
        class="w-full rounded-lg border-2 border-neutral-200"
        #commentsTextArea
        name="comments"
        cols="30"
        rows="5"
        [value]="getTips()"
        (change)="onTipsChanged($event)"
        [readonly]="isUserClientView"
      ></textarea>
    </div>
    <div class="w-full">
      <p class="mb-1 mt-7 text-lg font-semibold">Enlaces</p>
      <p *ngIf="!isUserClientView">Añade enlaces a los informes</p>
      <app-test-report-links
        [links]="test.links"
        (changeEvent)="this.setLinks($event)"
        [isUserClientView]="isUserClientView"
      ></app-test-report-links>
    </div>
  </div>
  <div
    *ngIf="!isUserClientView"
    class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
  >
    <div class="mb-4">
      <p class="mb-4 text-xl font-semibold">Enlace para compartir</p>
      <app-input
        class="md:col-span-2"
        [readonly]="true"
        [button]="copySharedUrlButton"
        [value]="getShareURL()"
      ></app-input>
    </div>
  </div>
</div>
