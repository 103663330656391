import { Injectable } from '@angular/core';
import {
  combineLatest,
  from,
  lastValueFrom,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  GlobalMediaSection,
  MediaSection,
  MEDIASECTIONS_PATH,
} from 'src/app/models/media-section.model';
import { MediaItem, MEDIA_PATH } from '../../../shared/media-item.outside';
import { AuthService } from '../auth.service';
import { ClientsService } from '../clients.service';
import { FirestoreService } from '../firestore.service';
import { NotificationsService } from '../notifications.service';
import { SentryService } from '../sentry.service';
import { StorageService } from '../storage.service';
import { safeToPromise } from 'src/app/utils/utils';

const MAX_FILE_SIZE = 50;

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  isUploading = false;
  uploadStateChangeEvent: Subject<void> = new Subject();

  uploadPercentage = 0;
  uploadPercentageChangeEvent: Subject<void> = new Subject();

  currentClientMediaItems$: Observable<Array<MediaItem>>;

  globalMediaItems$: Observable<Array<MediaItem>>;

  constructor(
    private notService: NotificationsService,
    private firestore: FirestoreService,
    private sentry: SentryService,
    private clients: ClientsService,
    private auth: AuthService,
    private storage: StorageService,
  ) {
    this.globalMediaItems$ = this.auth.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }

        return this.firestore.colWithIds$('media', (ref) =>
          ref.where('isGlobal', '==', true).orderBy('updatedAt', 'desc'),
        );
      }),
      map((data: Array<MediaItem>) => {
        const filteredData = data.filter((item) => !item.deleted);
        return filteredData;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('media', data.length);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {}),
    );
    this.currentClientMediaItems$ = combineLatest([
      this.auth.user$,
      this.clients.currentClientId$,
    ]).pipe(
      map((value: [any, any]) => {
        return {
          user: value[0],
          clientId: value[1],
        };
      }),
      switchMap((value: any) => {
        if (!value.user) {
          return of([]);
        }

        return this.firestore
          .colWithIds$(`${'media'}`, (ref) =>
            ref
              .where('owner', '==', value.user.uid)
              .orderBy('updatedAt', 'desc'),
          )
          .pipe(
            map((items: Array<MediaItem>) => {
              if (!value.clientId) {
                return items;
              } else {
                const filtered = items.filter(
                  (item) => !item.client || item.client === value.clientId,
                );
                return filtered;
              }
            }),
          );

        return this.firestore.colWithIds$(`${'media'}`, (ref) =>
          ref
            .where('owner', '==', value.user.uid)
            .where('client', '==', value.clientId)
            .orderBy('updatedAt', 'desc'),
        );
        // return this.firestore.colWithIds$(`${'media'}`, ref => ref.where('owner', '==', value.user.uid).where('client', '==', value.clientId));
      }),
      map((data: Array<MediaItem>) => {
        const filteredData = data.filter((item) => !item.deleted);
        return filteredData;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('media', data.length);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {}),
    );
  }

  async uploadFiles(files: Array<File>, isGlobal?: boolean) {
    // this.notService.component.showErrorNotification({title: 'Formato de imagen o video no reconocido', message: 'Por favor, vuelve a intentarlo con otro archivo'});
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (!this.isAcceptedFormat(file)) {
        this.notService.component.showErrorNotification({
          title: 'Formato de imagen o video no reconocido',
          message: 'Por favor, vuelve a intentarlo con otro archivo',
        });
        return;
      }
      if (!this.isAcceptedSize(file)) {
        this.notService.component.showErrorNotification({
          title: `Este archivo ocupa mas de ${MAX_FILE_SIZE} MB`,
          message: 'Por favor, vuelve a intentarlo con un archivo mas pequeño',
        });
        return;
      }
    }

    try {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        await this.uploadFile(file, isGlobal);
      }
    } catch (error) {
      // this.sentry.sendException(error);
      console.error(error);
      this.notService.component.showErrorNotification({
        title: `No hemos podido subir tus archivos`,
        message:
          'Por favor, vuelve a intentarlo mas tarde o contacta con soporte',
      });
    }
  }

  async uploadFile(file: File | any, isGlobal?: boolean) {
    const mediaItem: MediaItem = {};
    const currentUser = await safeToPromise(this.auth.user$.pipe(take(1)));
    const currentClientId = await safeToPromise(
      from(this.clients.currentClientId$).pipe(take(1)),
    );
    if (isGlobal) {
      if (currentUser.role !== 'admin') {
        this.notService.component.showErrorNotification({
          title: `No tienes permisos para subir archivos de este tipo`,
          message: 'Por favor, vuelve a intentarlo con otro archivo',
        });
        return;
      }
      mediaItem.isGlobal = true;
    } else {
      mediaItem.owner = currentUser.uid;
      mediaItem.client = currentClientId;
    }
    const folder = isGlobal ? 'global' : currentUser.uid;
    const mediaItemDocRef = await this.firestore.add('media', mediaItem);
    const mediaItemId = mediaItemDocRef.id;
    this.storage.startUpload(folder, mediaItemId, file);
    from(this.storage.percentage)
      .pipe(
        tap(
          (p) => console.log('Percentage: ', p),
          finalize(() => console.log('percentage observable finalized')),
        ),
      )
      .subscribe();
    await safeToPromise(from(this.storage.uploadTaskFinalized).pipe(take(1)));
    console.log('Upload finished');
  }

  // NOTE: apparently not used anywhere
  async uploadFileGlobal(file: File | any) {
    const mediaItem: MediaItem = {};
    const currentUser = await safeToPromise(this.auth.user$.pipe(take(1)));
    mediaItem.owner = currentUser.uid;
    const mediaItemDocRef = await this.firestore.add('media', mediaItem);
    const mediaItemId = mediaItemDocRef.id;
    this.storage.startUpload(currentUser.uid, mediaItemId, file);
    from(this.storage.percentage)
      .pipe(
        tap(
          (p) => console.log('Percentage: ', p),
          finalize(() => console.log('percentage observable finalized')),
        ),
      )
      .subscribe();
    await safeToPromise(from(this.storage.uploadTaskFinalized).pipe(take(1)));
    // return mediaIt;
  }

  isAcceptedFormat(file: File) {
    try {
      const type = file.type.split('/')[0];
      return type === 'image' || type === 'video';
    } catch (error) {
      return false;
    }
  }

  isAcceptedSize(file: File) {
    try {
      const size = file.size;
      return size <= MAX_FILE_SIZE * 1024 * 1024;
    } catch (error) {
      return false;
    }
  }

  async deleteMediaItem(mediaItem: MediaItem) {
    mediaItem.deleted = true;
    await this.firestore.update(`${MEDIA_PATH}/${mediaItem.id}`, mediaItem);
  }

  async getMediaItem(mediaItemId: string) {
    const result = await safeToPromise(
      this.firestore
        .docWithId$<MediaItem>(`${MEDIA_PATH}/${mediaItemId}`)
        .pipe(take(1)),
    );
    return result;
  }

  async getMediaItemWhenFullyUploaded(mediaItemId: string) {
    const result = await safeToPromise(
      this.firestore
        .docWithId$<MediaItem>(`${MEDIA_PATH}/${mediaItemId}`)
        .pipe(takeWhile((value) => !!value.downloadURL)),
    );
    return result;
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.firestore.add(`${MEDIASECTIONS_PATH}`, section);
      section = await this.getSections(owner);
    }
    return section;
  }

  async updateSections(section: MediaSection | GlobalMediaSection) {
    await this.firestore.update(`${MEDIASECTIONS_PATH}/${section.id}`, section);
    return true;
  }

  async getSections(owner: string) {
    const docs: MediaSection[] = await safeToPromise(
      this.firestore
        .colWithIds$<MediaSection>(`${MEDIASECTIONS_PATH}`, (ref) =>
          ref.where('owner', '==', owner),
        )
        .pipe(take(1)),
    );
    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }

  async getGlobalSections() {
    const docs: GlobalMediaSection[] = await lastValueFrom(
      this.firestore
        .colWithIds$<GlobalMediaSection>(`${MEDIASECTIONS_PATH}`, (ref) =>
          ref.where('isGlobal', '==', true),
        )
        .pipe(take(1)),
    );

    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }
}
