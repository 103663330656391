import { Component, OnInit } from '@angular/core';
import { UserConfigServiceService } from '../services/user-config-service.service';
import { UserConfig } from '../models/user-config.model';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CanIService } from '../services/can-i.service';
import { AuthService } from '../services/auth.service';
import {
  AngularFireStorage,
  AngularFireStorageReference,
} from '@angular/fire/compat/storage';
import { ModalService } from '../services/modal.service';
import { safeToPromise, sleep } from '../utils/utils';
import { MediaService } from '../services/media/media.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnInit {
  userConfig: UserConfig;

  data: any;
  submiting = false;

  editConfigForm: FormGroup<{
    // proffesional info
    education: FormControl;
    displayEducation: FormControl;
    experience: FormControl;
    displayExperience: FormControl;
    others: FormControl;
    displayOthers: FormControl;
    displayProfilePhoto: FormControl;

    // services - info buttons
    questionnaireExplanationLink: FormControl;
    displayQuestionnaireExplanationLink: FormControl;
    calendarExplanationLink: FormControl;
    displayCalendarExplanationLink: FormControl;
    metricsExplanationLink: FormControl;
    displayMetricsExplanationLink: FormControl;
    appointmentExplanationLink: FormControl;
    displayAppointmentExplanationLink: FormControl;
    hireServiceExplanationLink: FormControl;
    displayHireServiceExplanationLink: FormControl;

    // services - text and links
    customAppointmentLabel: FormControl;
    displayCustomAppointmentLabel: FormControl;
    appointmentLink: FormControl;
    customHireServiceLabel: FormControl;
    displayCustomHireServiceLabel: FormControl;
    hireServiceLink: FormControl;

    // connect links
    web: FormControl;
    displayWeb: FormControl;
    location: FormControl;
    displayLocation: FormControl;
    connectEmail: FormControl;
    displayConnectEmail: FormControl;
    connectPhone: FormControl;
    displayConnectPhone: FormControl;
    whatsapp: FormControl;
    displayWhatsapp: FormControl;
    appointmentsCalendar: FormControl;
    displayAppointmentsCalendar: FormControl;
    paymentsLink: FormControl;
    displayPaymentsLink: FormControl;
    news: FormControl;
    displayNews: FormControl;
    instagram: FormControl;
    displayInstagram: FormControl;
    facebook: FormControl;
    displayFacebook: FormControl;
    twitter: FormControl;
    displayTwitter: FormControl;
    tiktok: FormControl;
    displayTiktok: FormControl;
    other: FormControl;
    displayOther: FormControl;

    // billing info
    companyName: FormControl;
    displayCompanyName: FormControl;
    dniCif: FormControl;
    displayDniCif: FormControl;
    name: FormControl;
    displayName: FormControl;
    address: FormControl;
    displayAddress: FormControl;
    accountNumber: FormControl;
    displayAccountNumber: FormControl;
    phone: FormControl;
    displayPhone: FormControl;
    email: FormControl;
    displayEmail: FormControl;

    // reports config
    line1: FormControl;
    displayLine1: FormControl;
    line2: FormControl;
    displayLine2: FormControl;
    line3: FormControl;
    displayLine3: FormControl;

    // professional links
    customAnamnesisLink: FormControl;
    customClientsLink: FormControl;
    customLinks: FormArray<
      FormGroup<{
        name: FormControl;
        url: FormControl;
      }>
    >;

    checkboxChecked: FormControl;
  }>;

  // Report Logo Upload
  uploadingLogo = false;
  uploadingLogoPercentage: Observable<number>;
  uploadedLogoUrl = '';
  fileSizeError = false;
  canCustomizeReports = true;

  // Add these properties
  uploadingProfilePhoto = false;
  uploadingProfilePhotoPercentage: Observable<number>;
  profilePhotoUrl = '';
  profilePhotoSizeError = false;

  constructor(
    private userConfigService: UserConfigServiceService,
    private canI: CanIService,
    private auth: AuthService,
    private storage: AngularFireStorage,
    private modalsService: ModalService,
    private formBuilder: FormBuilder,
    private mediaService: MediaService,
  ) {
    this.editConfigForm = new FormGroup({
      // proffesional info
      education: new FormControl(''),
      displayEducation: new FormControl(false),
      experience: new FormControl(''),
      displayExperience: new FormControl(false),
      others: new FormControl(''),
      displayOthers: new FormControl(false),
      displayProfilePhoto: new FormControl(false),

      // services - info buttons
      questionnaireExplanationLink: new FormControl(''),
      displayQuestionnaireExplanationLink: new FormControl(false),
      calendarExplanationLink: new FormControl(''),
      displayCalendarExplanationLink: new FormControl(false),
      metricsExplanationLink: new FormControl(''),
      displayMetricsExplanationLink: new FormControl(false),
      appointmentExplanationLink: new FormControl(''),
      displayAppointmentExplanationLink: new FormControl(false),
      hireServiceExplanationLink: new FormControl(''),
      displayHireServiceExplanationLink: new FormControl(false),

      // services - text and links
      customAppointmentLabel: new FormControl(''),
      displayCustomAppointmentLabel: new FormControl(false),
      appointmentLink: new FormControl(''),
      customHireServiceLabel: new FormControl(''),
      displayCustomHireServiceLabel: new FormControl(false),
      hireServiceLink: new FormControl(''),

      // connect links
      web: new FormControl(''),
      displayWeb: new FormControl(false),
      location: new FormControl(''),
      displayLocation: new FormControl(false),
      connectEmail: new FormControl(''),
      displayConnectEmail: new FormControl(false),
      connectPhone: new FormControl(''),
      displayConnectPhone: new FormControl(false),
      whatsapp: new FormControl(''),
      displayWhatsapp: new FormControl(false),
      appointmentsCalendar: new FormControl(''),
      displayAppointmentsCalendar: new FormControl(false),
      paymentsLink: new FormControl(''),
      displayPaymentsLink: new FormControl(false),
      news: new FormControl(''),
      displayNews: new FormControl(false),
      instagram: new FormControl(''),
      displayInstagram: new FormControl(false),
      facebook: new FormControl(''),
      displayFacebook: new FormControl(false),
      twitter: new FormControl(''),
      displayTwitter: new FormControl(false),
      tiktok: new FormControl(''),
      displayTiktok: new FormControl(false),
      other: new FormControl(''),
      displayOther: new FormControl(false),

      // billing info
      companyName: new FormControl(''),
      displayCompanyName: new FormControl(false),
      dniCif: new FormControl(''),
      displayDniCif: new FormControl(false),
      name: new FormControl(''),
      displayName: new FormControl(false),
      address: new FormControl(''),
      displayAddress: new FormControl(false),
      accountNumber: new FormControl(''),
      displayAccountNumber: new FormControl(false),
      phone: new FormControl(''),
      displayPhone: new FormControl(false),
      email: new FormControl(''),
      displayEmail: new FormControl(false),

      // reports config
      line1: new FormControl(''),
      displayLine1: new FormControl(false),
      line2: new FormControl(''),
      displayLine2: new FormControl(false),
      line3: new FormControl(''),
      displayLine3: new FormControl(false),

      // proffesional links
      customAnamnesisLink: new FormControl(''),
      customClientsLink: new FormControl(''),
      customLinks: this.formBuilder.array([
        this.formBuilder.group({
          name: '',
          url: '',
        }),
      ]),

      checkboxChecked: new FormControl(true),
    });
  }

  get customLinks(): FormArray {
    return this.editConfigForm.get('customLinks') as FormArray;
  }

  addLink(): void {
    const link = this.formBuilder.group({
      name: '',
      url: '',
    });
    this.customLinks.push(link);
  }

  removeLink(index: number): void {
    this.customLinks.removeAt(index);
  }

  onFormChanges(): void {
    this.editConfigForm.valueChanges.subscribe(() => {
      // only those for connect links
      const displayFields = [
        'displayWeb',
        'displayLocation',
        'displayConnectPhone',
        'displayConnectEmail',
        'displayWhatsapp',
        'displayAppointmentsCalendar',
        'displayPaymentsLink',
        'displayNews',
        'displayInstagram',
        'displayFacebook',
        'displayTwitter',
        'displayTiktok',
        'displayOther',
      ];

      const checkedCount = displayFields.reduce(
        (acc, field) => acc + (this.editConfigForm.get(field).value ? 1 : 0),
        0,
      );

      displayFields.forEach((field) => {
        if (checkedCount >= 5) {
          if (!this.editConfigForm.get(field).value) {
            this.editConfigForm.get(field).disable({ emitEvent: false });
          }
        } else {
          this.editConfigForm.get(field).enable({ emitEvent: false });
        }
      });
    });
  }

  ngOnInit() {
    this.loadData();
    this.userConfigService.userConfig$.subscribe((config) => {
      this.userConfig = config;
      this.profilePhotoUrl = config?.profilePhotoUrl;
    });
    this.onFormChanges();
  }

  async loadData() {
    this.canCustomizeReports = await this.canI.canCustomizeReports();

    const customLinksFormArray = this.editConfigForm.get(
      'customLinks',
    ) as FormArray;

    // Clear the existing form groups in the FormArray
    while (customLinksFormArray.length !== 0) {
      customLinksFormArray.removeAt(0);
    }

    // Create a new form group for each custom link
    this.userConfig.customLinks?.forEach((link) => {
      customLinksFormArray.push(
        this.formBuilder.group({
          name: link.name || '',
          url: link.url || '',
        }),
      );
    });

    // If there are no custom links, add one form group with empty values
    if (customLinksFormArray.length === 0) {
      customLinksFormArray.push(
        this.formBuilder.group({
          name: '',
          url: '',
        }),
      );
    }

    this.editConfigForm.patchValue({
      // proffesional info
      education: this.userConfig.education ? this.userConfig.education : '',
      displayEducation: this.userConfig.displayEducation ?? false,
      experience: this.userConfig.experience ? this.userConfig.experience : '',
      displayExperience: this.userConfig.displayExperience ?? false,
      others: this.userConfig.others ? this.userConfig.others : '',
      displayOthers: this.userConfig.displayOthers ?? false,
      displayProfilePhoto: this.userConfig.displayProfilePhoto ?? false,
      // services - info buttons
      questionnaireExplanationLink: this.userConfig.questionnaireExplanationLink
        ? this.userConfig.questionnaireExplanationLink
        : '',
      displayQuestionnaireExplanationLink:
        this.userConfig.displayQuestionnaireExplanationLink ?? false,
      calendarExplanationLink: this.userConfig.calendarExplanationLink
        ? this.userConfig.calendarExplanationLink
        : '',
      displayCalendarExplanationLink:
        this.userConfig.displayCalendarExplanationLink ?? false,
      metricsExplanationLink: this.userConfig.metricsExplanationLink
        ? this.userConfig.metricsExplanationLink
        : '',
      displayMetricsExplanationLink:
        this.userConfig.displayMetricsExplanationLink ?? false,
      appointmentExplanationLink: this.userConfig.appointmentExplanationLink
        ? this.userConfig.appointmentExplanationLink
        : '',
      displayAppointmentExplanationLink:
        this.userConfig.displayAppointmentExplanationLink ?? false,
      hireServiceExplanationLink: this.userConfig.hireServiceExplanationLink
        ? this.userConfig.hireServiceExplanationLink
        : '',
      displayHireServiceExplanationLink:
        this.userConfig.displayHireServiceExplanationLink ?? false,

      // services - text and links
      customAppointmentLabel: this.userConfig.customAppointmentLabel
        ? this.userConfig.customAppointmentLabel
        : '',
      displayCustomAppointmentLabel:
        this.userConfig.displayCustomAppointmentLabel ?? false,
      appointmentLink: this.userConfig.appointmentLink
        ? this.userConfig.appointmentLink
        : '',
      customHireServiceLabel: this.userConfig.customHireServiceLabel
        ? this.userConfig.customHireServiceLabel
        : '',
      displayCustomHireServiceLabel:
        this.userConfig.displayCustomHireServiceLabel ?? false,
      hireServiceLink: this.userConfig.hireServiceLink
        ? this.userConfig.hireServiceLink
        : '',

      // connect links
      web: this.userConfig.connectLinks?.web
        ? this.userConfig.connectLinks.web
        : '',
      displayWeb: this.userConfig.connectLinks?.displayWeb ?? false,
      location: this.userConfig.connectLinks?.location
        ? this.userConfig.connectLinks.location
        : '',
      displayLocation: this.userConfig.connectLinks?.displayLocation ?? false,
      connectPhone: this.userConfig.connectLinks?.connectPhone
        ? this.userConfig.connectLinks.connectPhone
        : '',
      displayConnectPhone:
        this.userConfig.connectLinks?.displayConnectPhone ?? false,
      connectEmail: this.userConfig.connectLinks?.connectEmail
        ? this.userConfig.connectLinks.connectEmail
        : '',
      displayConnectEmail:
        this.userConfig.connectLinks?.displayConnectEmail ?? false,
      whatsapp: this.userConfig.connectLinks?.whatsapp
        ? this.userConfig.connectLinks.whatsapp
        : '',
      displayWhatsapp: this.userConfig.connectLinks?.displayWhatsapp ?? false,
      appointmentsCalendar: this.userConfig.connectLinks?.appointmentsCalendar
        ? this.userConfig.connectLinks.appointmentsCalendar
        : '',
      displayAppointmentsCalendar:
        this.userConfig.connectLinks?.displayAppointmentsCalendar ?? false,
      paymentsLink: this.userConfig.connectLinks?.paymentsLink
        ? this.userConfig.connectLinks.paymentsLink
        : '',
      displayPaymentsLink:
        this.userConfig.connectLinks?.displayPaymentsLink ?? false,
      news: this.userConfig.connectLinks?.news
        ? this.userConfig.connectLinks.news
        : '',
      displayNews: this.userConfig.connectLinks?.displayNews ?? false,
      instagram: this.userConfig.connectLinks?.instagram
        ? this.userConfig.connectLinks.instagram
        : '',
      displayInstagram: this.userConfig.connectLinks?.displayInstagram ?? false,
      facebook: this.userConfig.connectLinks?.facebook
        ? this.userConfig.connectLinks.facebook
        : '',
      displayFacebook: this.userConfig.connectLinks?.displayFacebook ?? false,
      twitter: this.userConfig.connectLinks?.twitter
        ? this.userConfig.connectLinks.twitter
        : '',
      displayTwitter: this.userConfig.connectLinks?.displayTwitter ?? false,
      tiktok: this.userConfig.connectLinks?.tiktok
        ? this.userConfig.connectLinks.tiktok
        : '',
      displayTiktok: this.userConfig.connectLinks?.displayTiktok ?? false,
      other: this.userConfig.connectLinks?.other
        ? this.userConfig.connectLinks.other
        : '',
      displayOther: this.userConfig.connectLinks?.displayOther ?? false,

      // billing info
      companyName: this.userConfig.companyName
        ? this.userConfig.companyName
        : '',
      displayCompanyName: this.userConfig.displayCompanyName ?? false,
      dniCif: this.userConfig.dniCif ? this.userConfig.dniCif : '',
      displayDniCif: this.userConfig.displayDniCif ?? false,
      name: this.userConfig.name ? this.userConfig.name : '',
      displayName: this.userConfig.displayName ?? false,
      address: this.userConfig.address ? this.userConfig.address : '',
      displayAddress: this.userConfig.displayAddress ?? false,
      accountNumber: this.userConfig.accountNumber
        ? this.userConfig.accountNumber
        : '',
      displayAccountNumber: this.userConfig.displayAccountNumber ?? false,
      phone: this.userConfig.phone ? this.userConfig.phone : '',
      displayPhone: this.userConfig.displayPhone ?? false,
      email: this.userConfig.email ? this.userConfig.email : '',
      displayEmail: this.userConfig.displayEmail ?? false,

      // reports config
      line1: this.userConfig.reportsConfig?.line1
        ? this.userConfig.reportsConfig.line1
        : '',
      displayLine1: this.userConfig.reportsConfig?.displayLine1 ?? false,
      line2: this.userConfig.reportsConfig?.line2
        ? this.userConfig.reportsConfig.line2
        : '',
      displayLine2: this.userConfig.reportsConfig?.displayLine2 ?? false,
      line3: this.userConfig.reportsConfig?.line3
        ? this.userConfig.reportsConfig.line3
        : '',
      displayLine3: this.userConfig.reportsConfig?.displayLine3 ?? false,

      // proffesional links
      customAnamnesisLink: this.userConfig.customAnamnesisLink
        ? this.userConfig.customAnamnesisLink
        : '',
      customClientsLink: this.userConfig.customClientsLink
        ? this.userConfig.customClientsLink
        : '',
      // customLinks needs to be separately patched in the loop above
    });
    this.uploadedLogoUrl = this.userConfig.reportsConfig?.logoUrl;
  }

  async onSubmit() {
    this.submiting = true;
    const config: UserConfig = {};

    // reports config
    config.reportsConfig = {
      line1: this.editConfigForm.value.line1,
      displayLine1: this.editConfigForm.value.displayLine1 ?? false,
      line2: this.editConfigForm.value.line2,
      displayLine2: this.editConfigForm.value.displayLine2 ?? false,
      line3: this.editConfigForm.value.line3,
      displayLine3: this.editConfigForm.value.displayLine3 ?? false,
      logoUrl: this.uploadedLogoUrl,
      showOnReport: this.canCustomizeReports,
    };

    // proffesional links
    config.customAnamnesisLink = this.editConfigForm.value.customAnamnesisLink;
    config.customClientsLink = this.editConfigForm.value.customClientsLink;

    // billing info
    config.companyName = this.editConfigForm.value.companyName;
    config.displayCompanyName = this.editConfigForm.value.displayCompanyName;
    config.dniCif = this.editConfigForm.value.dniCif;
    config.displayDniCif = this.editConfigForm.value.displayDniCif;
    config.name = this.editConfigForm.value.name;
    config.displayName = this.editConfigForm.value.displayName;
    config.address = this.editConfigForm.value.address;
    config.displayAddress = this.editConfigForm.value.displayAddress;
    config.accountNumber = this.editConfigForm.value.accountNumber;
    config.displayAccountNumber =
      this.editConfigForm.value.displayAccountNumber;
    config.phone = this.editConfigForm.value.phone;
    config.displayPhone = this.editConfigForm.value.displayPhone;
    config.email = this.editConfigForm.value.email;
    config.displayEmail = this.editConfigForm.value.displayEmail;

    // proffesional info
    config.education = this.editConfigForm.value.education;
    config.displayEducation = this.editConfigForm.value.displayEducation;
    config.experience = this.editConfigForm.value.experience;
    config.displayExperience = this.editConfigForm.value.displayExperience;
    config.others = this.editConfigForm.value.others;
    config.displayOthers = this.editConfigForm.value.displayOthers;
    config.profilePhotoUrl = this.profilePhotoUrl;
    config.displayProfilePhoto = this.editConfigForm.value.displayProfilePhoto;

    // services - info buttons
    config.questionnaireExplanationLink =
      this.editConfigForm.value.questionnaireExplanationLink;
    config.displayQuestionnaireExplanationLink =
      this.editConfigForm.value.displayQuestionnaireExplanationLink ?? false;
    config.calendarExplanationLink =
      this.editConfigForm.value.calendarExplanationLink;
    config.displayCalendarExplanationLink =
      this.editConfigForm.value.displayCalendarExplanationLink ?? false;
    config.metricsExplanationLink =
      this.editConfigForm.value.metricsExplanationLink;
    config.displayMetricsExplanationLink =
      this.editConfigForm.value.displayMetricsExplanationLink ?? false;
    config.appointmentExplanationLink =
      this.editConfigForm.value.appointmentExplanationLink;
    config.displayAppointmentExplanationLink =
      this.editConfigForm.value.displayAppointmentExplanationLink ?? false;
    config.hireServiceExplanationLink =
      this.editConfigForm.value.hireServiceExplanationLink;
    config.displayHireServiceExplanationLink =
      this.editConfigForm.value.displayHireServiceExplanationLink ?? false;
    // services - text and links
    config.customAppointmentLabel =
      this.editConfigForm.value.customAppointmentLabel;
    config.displayCustomAppointmentLabel =
      this.editConfigForm.value.displayCustomAppointmentLabel ?? false;
    config.appointmentLink = this.editConfigForm.value.appointmentLink;
    config.customHireServiceLabel =
      this.editConfigForm.value.customHireServiceLabel;
    config.displayCustomHireServiceLabel =
      this.editConfigForm.value.displayCustomHireServiceLabel ?? false;
    config.hireServiceLink = this.editConfigForm.value.hireServiceLink;

    // connect links
    config.connectLinks = {};
    config.connectLinks.web = this.editConfigForm.value.web;
    config.connectLinks.displayWeb =
      this.editConfigForm.value.displayWeb ?? false;
    config.connectLinks.location = this.editConfigForm.value.location;
    config.connectLinks.displayLocation =
      this.editConfigForm.value.displayLocation ?? false;
    config.connectLinks.connectEmail = this.editConfigForm.value.connectEmail;
    config.connectLinks.displayConnectEmail =
      this.editConfigForm.value.displayConnectEmail ?? false;
    config.connectLinks.connectPhone = this.editConfigForm.value.connectPhone;
    config.connectLinks.displayConnectPhone =
      this.editConfigForm.value.displayConnectPhone ?? false;
    config.connectLinks.whatsapp = this.editConfigForm.value.whatsapp;
    config.connectLinks.displayWhatsapp =
      this.editConfigForm.value.displayWhatsapp ?? false;
    config.connectLinks.appointmentsCalendar =
      this.editConfigForm.value.appointmentsCalendar;
    config.connectLinks.displayAppointmentsCalendar =
      this.editConfigForm.value.displayAppointmentsCalendar ?? false;
    config.connectLinks.paymentsLink = this.editConfigForm.value.paymentsLink;
    config.connectLinks.displayPaymentsLink =
      this.editConfigForm.value.displayPaymentsLink ?? false;
    config.connectLinks.news = this.editConfigForm.value.news;
    config.connectLinks.displayNews =
      this.editConfigForm.value.displayNews ?? false;
    config.connectLinks.instagram = this.editConfigForm.value.instagram;
    config.connectLinks.displayInstagram =
      this.editConfigForm.value.displayInstagram ?? false;
    config.connectLinks.facebook = this.editConfigForm.value.facebook;
    config.connectLinks.displayFacebook =
      this.editConfigForm.value.displayFacebook ?? false;
    config.connectLinks.twitter = this.editConfigForm.value.twitter;
    config.connectLinks.displayTwitter =
      this.editConfigForm.value.displayTwitter ?? false;
    config.connectLinks.tiktok = this.editConfigForm.value.tiktok;
    config.connectLinks.displayTiktok =
      this.editConfigForm.value.displayTiktok ?? false;
    config.connectLinks.other = this.editConfigForm.value.other;
    config.connectLinks.displayOther =
      this.editConfigForm.value.displayOther ?? false;
    config.customLinks = this.editConfigForm.value.customLinks;
    await this.userConfigService.updateUserConfig(config);
    // virtual timer for better UX (the user can see "saving" for at least 300ms)
    await sleep(300);
    this.submiting = false;
  }

  async uploadPhoto(fileList: FileList) {
    if (this.uploadingLogo) {
      return;
    }
    this.fileSizeError = false;
    const file = fileList.item(0);
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :(');
      return;
    }
    if (file.size > 200 * 1024) {
      this.fileSizeError = true;
      return;
    }
    this.uploadingLogo = true;
    // const customMetadata = { app: 'My AngularFire-powered PWA!' };
    const userUid = (await this.auth.getUser()).uid;
    const filePath = `report-logos/logo_${userUid}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadingLogoPercentage = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.onUploadFinished(fileRef);
        }),
      )
      .subscribe();
  }

  async onUploadFinished(fileRef: AngularFireStorageReference) {
    this.uploadedLogoUrl = await safeToPromise(
      fileRef.getDownloadURL().pipe(take(1)),
    );
    this.uploadingLogo = false;
  }

  removeLogo() {
    this.uploadedLogoUrl = null;
  }

  async openUpgradeModal() {
    await this.modalsService.openModal('new-upgrade');
  }

  async uploadProfilePhoto(fileList: FileList) {
    if (this.uploadingProfilePhoto) {
      return;
    }
    this.profilePhotoSizeError = false;
    const file = fileList.item(0);

    try {
      this.uploadingProfilePhoto = true;
      const mediaItemId = await this.mediaService.uploadProfilePhoto(file);
      const userUid = (await this.auth.getUser()).uid;
      const filePath = `user-media/${userUid}/${mediaItemId}`;
      const fileRef = this.storage.ref(filePath);
      if (mediaItemId) {
        // Get the URL of the uploaded photo
        const mediaItem = await this.mediaService.getMediaItem(mediaItemId);
        const profilePhotoUrl = await safeToPromise(
          fileRef.getDownloadURL().pipe(take(1)),
        );
        this.profilePhotoUrl = profilePhotoUrl;
      }
    } catch (error) {
      console.error('Error uploading profile photo:', error);
    } finally {
      this.uploadingProfilePhoto = false;
    }
  }

  removeProfilePhoto() {
    this.profilePhotoUrl = null;
    const updatedConfig = {
      ...this.userConfig,
      profilePhotoUrl: null,
    };
    this.userConfigService.updateUserConfig(updatedConfig);
  }
}
