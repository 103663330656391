import {
  type ActionId,
  ActionIds,
} from '../../../Server/functions/src/shared/subscriptions.shared';
import { environment } from '../environments/environment';
export * from '../../../Server/functions/src/shared/subscriptions.shared';

const isProd = environment.production;

export type SubscriptionType = 'InteccPro' | 'InteccElite';

// Intecc products
const NewProductIds: {
  [key in SubscriptionType]: string;
} = isProd
  ? {
      // prod values
      InteccPro: 'prod_QvWSWRd7tWg5Cp',
      InteccElite: 'prod_QvWSNueC6E4P7Z',
    }
  : {
      // dev values
      InteccPro: 'prod_QuKrQcztlntY1z',
      InteccElite: 'prod_Qv6mg10YfFWJyS',
    };

// Intecc subscriptions
const InteccProducts = {
  [NewProductIds.InteccPro]: {
    id: NewProductIds.InteccPro,
    capabilities: [
      { id: ActionIds.CreateClient, limit: 1 },
      { id: ActionIds.VAFViewTests },
      { id: ActionIds.VAFCreateRaquis, limit: -1 },
      { id: ActionIds.VAFCreateTest, limit: -1 },
      { id: ActionIds.VAFExportPdf, limit: -1 },
      { id: ActionIds.VAFExportPdfCustom },
      { id: ActionIds.VAFUseMyVAF },
      { id: ActionIds.InteccUseCorrectiveSessions },
      { id: ActionIds.InteccUseTrainingSessions },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
  [NewProductIds.InteccElite]: {
    id: NewProductIds.InteccElite,
    capabilities: [
      { id: ActionIds.CreateClient, limit: -1 },
      { id: ActionIds.VAFViewTests },
      { id: ActionIds.VAFCreateRaquis, limit: -1 },
      { id: ActionIds.VAFCreateTest, limit: -1 },
      { id: ActionIds.VAFExportPdf, limit: -1 },
      { id: ActionIds.VAFExportPdfCustom },
      { id: ActionIds.VAFUseMyVAF },
      { id: ActionIds.InteccUseCorrectiveSessions },
      { id: ActionIds.InteccUseTrainingSessions },
      { id: ActionIds.InteccUseTrainingAvatar },
      { id: ActionIds.RaquisCreateTest, limit: -1 },
      { id: ActionIds.RaquisExportPdf, limit: -1 },
      { id: ActionIds.RaquisViewTests },
    ],
  },
};

/**
 * Checks if a product (only new subscriptions) has a permission for a given action and count.
 * @param productId - The ID of the product.
 * @param actionId - The ID of the action.
 * @param count - The count of the action.
 * @returns
 */
export function productHasPermission(
  productId: string,
  actionId: ActionId,
  count?: number,
) {
  const product = InteccProducts[productId];
  if (!product) {
    return false;
  }
  const capability = product.capabilities.find((c) => c.id === actionId);
  if (!capability) return false;
  if (capability.limit && capability.limit !== -1) {
    if (count === undefined || count >= capability.limit) {
      return false;
    }
  }
  return true;
}

export function getSubscriptionTypeByProductId(
  productId: string,
): SubscriptionType {
  return Object.keys(NewProductIds).find(
    (key: keyof typeof NewProductIds) =>
      NewProductIds[key as SubscriptionType] === productId,
  ) as SubscriptionType;
}
