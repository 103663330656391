<div *ngIf="imageKey" class="relative h-full w-full bg-neutral-100">
  <div
    *ngIf="imageValue"
    class="relative grid h-full w-full grid-rows-header-content"
  >
    <div
      *ngIf="this.testImageInfo"
      class="w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
    >
      <p>{{ this.testImageInfo.name }}</p>
    </div>
    <div *ngIf="imageValue.type === 'original'" class="h-full w-full">
      <img
        *ngIf="mediaItem"
        src="{{ mediaItem.downloadURL }}"
        class="h-full w-full object-contain"
        alt=""
      />
    </div>
    <div *ngIf="imageValue.type === 'edit'" class="h-full w-full">
      <app-edited-image-view
        *ngIf="editedMediaItem"
        [item]="editedMediaItem"
      ></app-edited-image-view>
    </div>

    <div
      *ngIf="!isUserClientView"
      class="absolute bottom-0 right-0 h-full w-full cursor-pointer"
      (click)="open()"
    ></div>
    <div
      *ngIf="imageDeleted"
      class="absolute bottom-0 right-0 flex h-full w-full cursor-pointer items-center justify-center"
    >
      <p class="text-center text-sm text-neutral-500">
        Esta imagen ha sido eliminada.
      </p>
    </div>
    <div class="absolute bottom-0 right-0 px-4 py-5" *ngIf="!isUserClientView">
      <a
        *ngIf="imageValue.link"
        href="{{ imageValue.link }}"
        target="_blank"
        class="mb-3 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
      >
        <i class="fas fa-external-link-alt text-white"></i>
      </a>
      <div
        (click)="editLink()"
        class="mb-3 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
      >
        <i class="fas fa-link"></i>
      </div>
      <div
        (click)="change()"
        class="mb-3 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
      >
        <i class="fas fa-exchange-alt"></i>
      </div>
      <div
        (click)="unasign()"
        class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
      >
        <i class="fas fa-trash-alt"></i>
      </div>
    </div>
  </div>
  <div
    *ngIf="editLinkMode && imageValue"
    class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-black/70 text-white"
  >
    <p class="font-bold">Enlace</p>
    <div class="h-4"></div>
    <input
      class="bg-black p-4 py-2 text-white"
      [ngModel]="imageValue.linkTitle"
      (ngModelChange)="imageValue.linkTitle = $event"
      type="text"
      placeholder="Título del enlace"
    />
    <div class="h-4"></div>
    <input
      class="bg-black p-4 py-2 text-white"
      [ngModel]="imageValue.link"
      (ngModelChange)="imageValue.link = $event"
      type="text"
      placeholder="https://..."
    />
    <div class="h-4"></div>
    <app-button [text]="'Guardar'" (click)="endEditLink()"></app-button>
  </div>
  <div
    *ngIf="!imageValue && this.testImageInfo"
    class="flex h-full flex-col items-center justify-center text-center text-neutral-500"
    (click)="change()"
    [class.cursor-pointer]="!isUserClientView"
  >
    <ng-container *ngIf="!isUserClientView">
      <p class="text-sm font-bold uppercase">{{ this.testImageInfo.name }}</p>
      <p class="text-sm">Toca para asignar una imagen</p>
    </ng-container>
  </div>
</div>
