
<div class="overlay" [class.overlay--open]="open">
    <div class="learn" [class.learn--open]="open">
        <div class="learn__header">
            <div class="learn__header__learn-icon"><i class="fas fa-graduation-cap"></i></div>
            <div class="learn__header__title">Academia</div>
            <button (click)="close()" class="learn__header__close button-close-dialog"><i class="fas fa-times"></i></button>
        </div>
        <div class="learn__content">
            <ng-template appLearnSectionHost></ng-template>
        </div>
    </div>
</div>
