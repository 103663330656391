import { Component, OnInit, ViewChild, Type } from '@angular/core';
import { ModalHostDirective } from 'src/app/directives/modal-host.directive';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { Modal } from 'src/app/models/modal.model';
import { Modals } from 'src/app/static/modals.static';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    @ViewChild(ModalHostDirective, { static: true }) modalHost: ModalHostDirective;

    onClose = new Subject<any>();

    isOpen = false;

    constructor(private overrideBackButton: OverrideBackButtonService) { }

    loadModal(modalId: string, data: any) {
        const modalComponent: Type<any> = Modals[modalId];
        this.isOpen = true;
        const viewContainerRef = this.modalHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(modalComponent);
        const componentInstance: Modal = componentRef.instance;
        componentInstance.data = data;
        this.onClose = componentInstance.onClose;
        this.onClose.pipe(take(1)).subscribe(result => this.isOpen = false);
        this.overrideBackButton.override(() => componentInstance.historyBackClose());
    }

    close() {
        history.back();
    }

    onBackdropClick(event) {
        if (event.target !== event.currentTarget) {
            return;
        }
        this.close();
    }

}
