
<div class="add-client">
        <h2 class="heading">Vamos a añadir un cliente</h2>
        <div class="client-thumbnail">
            <p class="client-thumbnail__text">{{thumbnailText}}</p>
        </div>
        <div class="form-container">
            <form autocomplete="off" class="form" [formGroup]="newClientForm" (ngSubmit)="onSubmit()">
                <div class="form__field">
                    <label class="form__label" for="name">Nombre</label>
                    <input class="form__input" type="text" id="name" formControlName="name">
                </div>
                <div class="form__field">
                    <label class="form__label" for="name">Apellidos</label>
                    <input class="form__input" type="text" id="name" formControlName="surname">
                </div>
                <div class="form__actions">
                        <button class="button-cta-2" [disabled]="!newClientForm.valid||submiting" type="submit">{{submitButtonLabel}}</button>
                       
                </div>
            
              </form>
              <button class="cancel-button button-plain-2" [routerLink]="['/']" >Cancelar</button>
        </div>
</div>
