<div
  class="relative flex h-screen w-full flex-col justify-center"
  style="background-color: #f2f3f7"
>
  <div
    class="mx-auto grid max-w-7xl grid-cols-1 grid-rows-1 items-center justify-items-center"
  >
    <div class="flex max-w-xs flex-col items-center justify-center text-center">
      <div
        *ngIf="currentBrand === 'uem'"
        class="mb-10 flex items-center justify-center"
      >
        <div class="mr-4 h-16 w-16">
          <img
            class="h-full w-full object-contain"
            src="../../../assets/images/intecc-with-bg-rounded.png"
            alt=""
          />
        </div>
        <i class="fas fa-times mr-4 text-neutral-400"></i>
        <div class="h-16 w-16">
          <img
            class="h-full w-full object-contain"
            src="../../../assets/images/uem.png"
            alt=""
          />
        </div>
      </div>
      <div
        *ngIf="currentBrand === 'default'"
        class="mb-10 flex items-center justify-center"
      >
        <div class="h-16 w-16">
          <img
            class="h-full w-full object-contain"
            src="../../../assets/images/intecc-with-bg-rounded.png"
            alt=""
          />
        </div>
      </div>
      <a
        [routerLink]="['/login']"
        [replaceUrl]="true"
        class="mb-4 text-intecc hover:text-intecc/75 focus:outline-none"
      >
        <i class="fas fa-arrow-left mr-2"></i>
        Volver
      </a>
      <p class="mb-4 text-xl text-neutral-900">
        Acceso para <b>Profesionales</b>
      </p>
      <div class="my-4 text-2xl font-bold">La App de la Élite</div>
      <button
        (click)="login()"
        type="button"
        class="my-2 mr-2 inline-flex items-center rounded-lg bg-intecc px-5 py-2.5 text-center text-base font-medium text-white hover:bg-intecc/75 focus:outline-none focus:ring-4 focus:ring-[#4285F4]/50 dark:focus:ring-[#4285F4]/55"
      >
        <svg
          class="-ml-1 mr-2 h-4 w-4"
          aria-hidden="true"
          focusable="false"
          data-prefix="fab"
          data-icon="google"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 488 512"
        >
          <path
            fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
          ></path>
        </svg>
        Iniciar sesión con Google
      </button>
    </div>
    <!-- <div class="h-full w-full px-4">
      <img
        class="h-full w-full object-contain"
        src="../../../assets/images/AVATAR 3D (940 × 900 px).png"
        alt=""
      />
    </div> -->
  </div>
</div>
