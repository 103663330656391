import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SwUpdate } from '@angular/service-worker';
import { ModalService } from 'src/app/services/modal.service';
import { filter } from 'rxjs';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    showUpdate = false;

    notifications = {
        Upgrade: 'upgrade',
        Update: 'update',
        Error: 'error'
    };

    visibleNotifications: Array<string> = [];
    notificationData = {};

    constructor(private notificationsService: NotificationsService, private swUpdate: SwUpdate, private modalsService: ModalService) {

    }

    ngOnInit() {
        this.notificationsService.component = this;
        this.swUpdate.versionUpdates.pipe(
            filter(event => event.type === 'VERSION_READY'),
        ).subscribe(() => {
            this.showUpdate = true;
        })
    }

    showUpdateNotification() {
        this.visibleNotifications = [...this.visibleNotifications, 'update'];
    }

    showUpgradeNotification(data: any) {
        this.visibleNotifications = [...this.visibleNotifications, this.notifications.Upgrade];
        this.notificationData[this.notifications.Upgrade] = data;
    }

    showErrorNotification(data: any) {
        this.visibleNotifications = [...this.visibleNotifications, this.notifications.Error];
        this.notificationData[this.notifications.Error] = data;
    }

    reload() {
        window.location.reload();
    }

    openUpgradeModal() {
        this.remove('upgrade');
        this.modalsService.openModal('new-upgrade');
    }

    remove(notName: string) {
        this.visibleNotifications = this.visibleNotifications.filter(not => not !== notName);
    }

}
