import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { LearnService } from 'src/app/services/learn.service';
import { ModalService } from 'src/app/services/modal.service';
import { ReportsService } from 'src/app/services/reports.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TestService } from 'src/app/services/test.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import {
  getPostureFieldInfo,
  PostureArchitecture,
  PostureTest,
} from 'src/shared/posture.outside';

@Component({
  selector: 'app-posture',
  templateUrl: './posture.component.html',
  styleUrls: ['./posture.component.scss'],
})
export class PostureComponent implements OnInit, OnChanges {
  _test: PostureTest;
  get test() {
    return this._test;
  }
  @Input() set test(value: PostureTest) {
    this._test = value;
  }
  @Input() client: Client;

  @Input() isUserClientView: boolean;

  PostureArchitecture = PostureArchitecture;

  clientSharedFolderUrl: string;

  generatingReport = false;

  getPostureFieldInfo = getPostureFieldInfo;

  constructor(
    private tests: TestService,
    private learn: LearnService,
    private modalService: ModalService,
    private reportsServices: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private snackbarService: SnackbarService,
  ) {
    this.generateReport = this.generateReport.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.client && changes.client.currentValue) {
      this.clientSharedFolderUrl = this.client.sharedFolderUrl;
    }
  }

  ngOnInit(): void {
    this.clientSharedFolderUrl = this.client.sharedFolderUrl;
  }

  openLearn(id: string) {
    this.learn.openPanel(id);
  }

  onDateChanged(date: any) {
    this.test.createdAt = firebaseTimestampFromDate(date.value);
    this.updateTest();
  }

  updateTest() {
    if (this.isUserClientView) {
      return;
    }
    const { ...test } = this.test;
    this.tests.updateTest(test);
  }

  onImageChanged() {
    this.updateTest();
  }

  getValue(field) {
    if (!this.test.results) {
      return -1;
    }
    const result = this.test.results[field];
    return result;
  }

  setValue(field, value) {
    if (!this.test.results) {
      this.test.results = {};
    }
    this.test.results[field] = value;
    this.updateTest();
  }

  getComments(propName: string) {
    return this.test[propName];
  }

  setComments(propName: string, value: string) {
    this.test[propName] = value;
    this.updateTest();
  }

  async editClient(data: any) {
    await this.modalService.openModal('create-edit-client', data);
  }

  setMultimediaLink(value: any) {
    value = value.trim();
    this.test.multimediaLink = value;
    this.updateTest();
  }

  setLinks(value: any) {
    this.test.links = value;
    this.updateTest();
  }

  getLinks() {
    return this.test.links;
  }

  onCommentsChanged(propName: string, event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    this.setComments(propName, value);
    this.updateTest();
  }

  async generateReport() {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([this.test]);
    } catch (e) {
      console.error('gen report error', e);
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }
}
