import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { LearnService } from 'src/app/services/learn.service';
import { ModalService } from 'src/app/services/modal.service';
import { TestService } from 'src/app/services/test.service';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import {
  RomShortTest,
  RomShortArchitecture,
  getFootFieldInfo,
} from 'src/shared/rom-short.outside';
import { LeftRigthValue } from 'src/shared/test.outside';

@Component({
  selector: 'app-rom-short',
  templateUrl: './rom-short.component.html',
  styleUrls: ['./rom-short.component.scss'],
})
export class RomShortComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  _test: RomShortTest;
  get test() {
    return this._test;
  }
  @Input() set test(value: RomShortTest) {
    this._test = value;
  }
  @Input() client: Client;

  clientSharedFolderUrl: string;

  RomShortArchitecture = RomShortArchitecture;

  getFootFieldInfo = getFootFieldInfo;

  constructor(
    private tests: TestService,
    private learn: LearnService,
    private modalService: ModalService,
    private clientsService: ClientsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.clientsService.currentClient$.subscribe((client) => {
      if (client) {
        this.clientSharedFolderUrl = client.sharedFolderUrl;
      }
    });
  }

  onDateChanged(date: any) {
    this.test.createdAt = firebaseTimestampFromDate(date.value);
    this.updateTest();
  }

  updateTest() {
    const { ...test } = this.test;
    this.tests.updateTest(test);
  }

  onImageChanged() {
    this.updateTest();
  }

  getValue(field) {
    if (!this.test.results) {
      return -1;
    }
    const result = this.test.results[field];
    return result;
  }

  getValueLF(field, rigth: boolean) {
    if (!this.test.results) {
      return -1;
    }
    const result = this.test.results[field] as LeftRigthValue;
    try {
      if (!rigth) {
        return result.left;
      } else {
        return result.right;
      }
    } catch (error) {
      return -1;
    }
  }

  getValueText(field) {
    if (!this.test.results) {
      return '';
    }
    const result = this.test.results[field];
    if (!result) {
      return '';
    }
    return result;
  }

  getValueTextLF(field, rigth: boolean) {
    if (!this.test.results) {
      return '';
    }
    const result = this.test.results[field] as LeftRigthValue;
    try {
      if (!rigth) {
        return result.left;
      } else {
        return result.right;
      }
    } catch (error) {
      return '';
    }
  }

  setSectionStatus(sectionId: string, value: boolean) {
    this.test[sectionId] = value;
    this.updateTest();
  }

  setValue(field, value) {
    if (!this.test.results) {
      this.test.results = {};
    }
    this.test.results[field] = value;
    this.updateTest();
  }

  openLearn(id: string) {
    this.learn.openPanel(id);
  }

  setValueLF(field, value, right: boolean) {
    if (!this.test.results) {
      this.test.results = {};
    }
    if (
      typeof this.test.results[field] === 'number' ||
      !this.test.results[field]
    ) {
      this.test.results[field] = { left: -1, right: -1 } as LeftRigthValue;
    }
    if (!right) {
      this.test.results[field].left = value;
    } else {
      this.test.results[field].right = value;
    }
    this.updateTest();
  }

  setValueText(field, value) {
    if (!this.test.results) {
      this.test.results = {};
    }
    this.test.results[field] = value;
    this.updateTest();
  }

  setValueTextLF(field, value, right: boolean) {
    if (!this.test.results) {
      this.test.results = {};
    }
    if (
      typeof this.test.results[field] === 'number' ||
      !this.test.results[field]
    ) {
      this.test.results[field] = { left: '', right: '' } as LeftRigthValue;
    }
    if (!right) {
      this.test.results[field].left = value;
    } else {
      this.test.results[field].right = value;
    }
    this.updateTest();
  }

  getComments(propName: string) {
    return this.test[propName];
  }

  setComments(propName: string, value: string) {
    this.test[propName] = value;
    this.updateTest();
  }

  async editClient(data: any) {
    await this.modalService.openModal('create-edit-client', data);
  }

  setMultimediaLink(value: any) {
    value = value.trim();
    this.test.multimediaLink = value;
    this.updateTest();
  }

  setLinks(value: any) {
    this.test.links = value;
    this.updateTest();
  }
  getLinks() {
    return this.test.links;
  }

  onCommentsChanged(propName: string, event) {
    const value = event.target.value;
    this.setComments(propName, value);
    this.updateTest();
  }
}
