import { Test, TestReportLink } from "./tests.model";

export enum AvatarTestValueKey {
  CabezaCuelloMorfotipo = "CabezaCuelloMorfotipo",
  CabezaCuelloAsimetria = "CabezaCuelloAsimetria",
  InclinacionDerecha = "InclinacionDerecha",
  InclinacionIzquierda = "InclinacionIzquierda",
  RotacionDerecha = "RotacionDerecha",
  RotacionIzquierda = "RotacionIzquierda",

  CinturaEscapularMorfotipo = "CinturaEscapularMorfotipo",
  CEMorfotipo = "CEMorfotipo",
  CEInclinacion = "CEInclinacion",
  CETalle = "CETalle",
  CEHombro = "CEHombro",
  CEEscapula = "CEEscapula",

  CPMorfotipo = "CPMorfotipo",
  CPCaderaCox = "CPCaderaCox",
  CPCadera = "CPCadera",
  CPRatioCadera = "CPRatioCadera",

  RMorfotipo = "RMorfotipo",
  RDorsal = "RDorsal",
  RLumbar = "RLumbar",
  RPatronLumbar = "RPatronLumbar",
  RSacro = "RSacro",
  REscoliosis = "REscoliosis",
  RPatronFlex = "RPatronFlex",
  RPatronExt = "RPatronExt",
  RPatronRot = "RPatronRot",
  RPostureMorfotipoPostural = "RPostureMorfotipoPostural",
  RApex = "RApex",

  MEMorfotipo = "MEMorfotipo",
  MEMusloRatio = "MEMusloRatio",
  MEMusloRatioAAb = "MEMusloRatioAAb",
  MERotula = "MERotula",
  MEPierna = "MEPierna",
  MEAsimetria = "MEAsimetria",

  PTMorfotipo = "PTMorfotipo",
  PTSupraAstragalina = "PTSupraAstragalina",
  PTSubAstragalina = "PTSubAstragalina",
  PTAsimetrias = "PTAsimetrias",
  Dismetria = "Dismetria",
  HalluxValgus = "HalluxValgus",
  PrimerMeta = "PrimerMeta",
  NavicularDrop = "NavicularDrop",
  Hiperqueratosis = "Hiperqueratosis",

  TNMorfotipo = "TNMorfotipo",
  TNULTTTest = "TNULTTTest",
  TNSLUMPTest = "TNSLUMPTest",
  TNSLRTest = "TNSLRTest",
  TNCompresivos = "TNCompresivos",
}

export type AvatarTestValues = {
  [key in AvatarTestValueKey]?: any;
};

export interface AvatarTest extends Test {
  values?: AvatarTestValues;
  frontComments?: string;
  sagComments?: string;
  recomendations?: string;
  comments?: string;
  multimediaLink?: string;
  links?: Array<TestReportLink>;
}

export const AvatarArchitecture = [
  {
    title: "Cabeza y Cuello",
    values: [
      AvatarTestValueKey.CabezaCuelloMorfotipo,
      AvatarTestValueKey.CabezaCuelloAsimetria,
      AvatarTestValueKey.InclinacionDerecha,
      AvatarTestValueKey.InclinacionIzquierda,
      AvatarTestValueKey.RotacionDerecha,
      AvatarTestValueKey.RotacionIzquierda,
    ],
  },
  {
    title: "Cintura Escapular",
    values: [
      AvatarTestValueKey.CinturaEscapularMorfotipo,
      AvatarTestValueKey.CEInclinacion,
      AvatarTestValueKey.CETalle,
      AvatarTestValueKey.CEHombro,
      AvatarTestValueKey.CEEscapula,
    ],
  },
  {
    title: "Columna Vertebral",
    values: [
      AvatarTestValueKey.RMorfotipo,
      AvatarTestValueKey.REscoliosis,
      AvatarTestValueKey.RDorsal,
      AvatarTestValueKey.RLumbar,
      AvatarTestValueKey.RSacro,
      AvatarTestValueKey.RPostureMorfotipoPostural,
      AvatarTestValueKey.RApex,

      // AvatarTestValueKey.RPatronFlex,
      // AvatarTestValueKey.RPatronExt,
    ],
  },
  {
    title: "Cintura Pélvica",
    values: [
      AvatarTestValueKey.CPMorfotipo,
      AvatarTestValueKey.RPatronLumbar,
      AvatarTestValueKey.RPatronRot,
      AvatarTestValueKey.CPCaderaCox,
      AvatarTestValueKey.CPCadera,
      AvatarTestValueKey.CPRatioCadera,
    ],
  },
  {
    title: "Miembro Inferior",
    values: [
      AvatarTestValueKey.MEMorfotipo,
      AvatarTestValueKey.MEMusloRatio,
      AvatarTestValueKey.MEMusloRatioAAb,
      AvatarTestValueKey.MERotula,
      AvatarTestValueKey.MEPierna,
      AvatarTestValueKey.MEAsimetria,
    ],
  },
  {
    title: "Tobillo y Pie",
    values: [
      AvatarTestValueKey.PTMorfotipo,
      AvatarTestValueKey.PTSupraAstragalina,
      AvatarTestValueKey.PTSubAstragalina,
      AvatarTestValueKey.PTAsimetrias,
      // AvatarTestValueKey.Dismetria,
      AvatarTestValueKey.HalluxValgus,
      AvatarTestValueKey.Hiperqueratosis,
      AvatarTestValueKey.PrimerMeta,
      AvatarTestValueKey.NavicularDrop,
    ],
  },
  {
    title: "Tests Neurales",
    values: [
      AvatarTestValueKey.TNMorfotipo,
      AvatarTestValueKey.TNULTTTest,
      AvatarTestValueKey.TNSLUMPTest,
      AvatarTestValueKey.TNSLRTest,
      AvatarTestValueKey.TNCompresivos,
    ],
  },
];

export type AvatarTestValueTypeType = "select" | "binary";

export type AvatarTestValueStatusType = "good" | "bad" | "neutral";

export interface AvatarTestValueDefinitionType {
  title: string;
  preTitle?: string;
  type: AvatarTestValueTypeType;
  values?: Array<AvatarValueType>;
}

export type AvatarValueType = {
  name: string;
  status: AvatarTestValueStatusType;
};

export const AvatarTestValuesDefinitions: {
  [key in AvatarTestValueKey]: AvatarTestValueDefinitionType;
} = {
  CabezaCuelloMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Anteriorizada", status: "bad" },
      { name: "Posteriorizada", status: "bad" },
    ],
    type: "select",
  },
  CabezaCuelloAsimetria: {
    title: "Asimetría",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Inclinación", status: "bad" },
      { name: "Rotación", status: "bad" },
    ],
    type: "select",
  },
  InclinacionDerecha: {
    preTitle: "Inclinación",
    title: "Derecha",
    type: "binary",
  },
  InclinacionIzquierda: { title: "Izquierda", type: "binary" },
  RotacionDerecha: { preTitle: "Rotación", title: "Derecha", type: "binary" },
  RotacionIzquierda: { title: "Izquierda", type: "binary" },

  CinturaEscapularMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Antepulsión", status: "bad" },
      { name: "Retropulsión", status: "bad" },
    ],
    type: "select",
  },
  CEMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Antepulsión", status: "bad" },
      { name: "Retropulsión", status: "bad" },
    ],
    type: "select",
  },
  CEInclinacion: {
    title: "Hombros",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Derecha", status: "bad" },
      { name: "Izquierda", status: "bad" },
    ],
    type: "select",
  },
  CETalle: {
    title: "Talle",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Derecho", status: "bad" },
      { name: "Izquierdo", status: "bad" },
    ],
    type: "select",
  },
  CEHombro: {
    title: "Rotación",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Rotación Externa", status: "bad" },
      { name: "Rotación Interna", status: "bad" },
    ],
    type: "select",
  },
  CEEscapula: {
    title: "Escápula",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Disquinesia I", status: "bad" },
      { name: "Disquinesia II", status: "bad" },
      { name: "Disquinesia III", status: "bad" },
      { name: "Alada", status: "bad" },
    ],
    type: "select",
  },

  CPMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Retroversión", status: "bad" },
      { name: "Anteversión", status: "bad" },
    ],
    type: "select",
  },
  CPCaderaCox: {
    title: "Cadera",
    values: [
      { name: "Coxa Neutra", status: "good" },
      { name: "Coxa Vara", status: "bad" },
      { name: "Coxa Valga", status: "bad" },
    ],
    type: "select",
  },
  CPCadera: {
    title: "-Rotación",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Rotación Externa", status: "bad" },
      { name: "Rotación Interna", status: "bad" },
    ],
    type: "select",
  },
  CPRatioCadera: {
    title: "-Sagital",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Cortedad Glúteo", status: "bad" },
      { name: "Cortedad Psoas", status: "bad" },
    ],
    type: "select",
  },

  RMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Hiperlordótico", status: "bad" },
      { name: "Hipercifótico", status: "bad" },
      { name: "Rectificado", status: "bad" },
      { name: "Cifolordótico", status: "bad" },
    ],
    type: "select",
  },
  RDorsal: {
    title: "Dorsal",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Hipercifosis", status: "bad" },
      { name: "Hipocifosis", status: "bad" },
      { name: "Inversión", status: "bad" },
    ],
    type: "select",
  },
  RLumbar: {
    title: "Lumbar",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Hiperlordosis", status: "bad" },
      { name: "Rectificado", status: "bad" },
      { name: "Inversión", status: "bad" },
    ],
    type: "select",
  },
  RSacro: {
    title: "Sacro",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Anteversión", status: "bad" },
      { name: "Retroversión", status: "bad" },
    ],
    type: "select",
  },
  REscoliosis: {
    title: "Escoliosis",
    values: [
      { name: "Adams Negativo", status: "good" },
      { name: "Adams Positivo", status: "bad" },
      // { name: "Tono Cruzado", status: "bad" },
      // { name: "Tono Homolateral", status: "bad" },
    ],
    type: "select",
  },
  RPatronLumbar: {
    title: "Patrón",
    values: [
      { name: "Neutro", status: "good" },
      { name: "En Flexión", status: "bad" },
      { name: "En Extensión", status: "bad" },
    ],
    type: "select",
  },
  RPatronFlex: { title: "Patrón en Flexión", type: "binary" },
  RPatronExt: { title: "Patrón en Extensión", type: "binary" },
  RPatronRot: { title: "Patrón Rotacional", type: "binary" },
  RPostureMorfotipoPostural: {
    title: "Eje Sagital",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Anteriorizado", status: "bad" },
      { name: "Posteriorizado", status: "bad" },
      { name: "Sway Back", status: "bad" },
    ],
    type: "select",
  },
  RApex: {
    title: "Ápex",
    values: [
      { name: "No presenta", status: "good" },
      { name: "Presenta Ápex", status: "bad" },
    ],
    type: "select",
  },

  MEMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Genu Varo", status: "bad" },
      { name: "Genu Valgo", status: "bad" },
      { name: "Genu Recurvatum", status: "bad" },
      { name: "Genu Flexo", status: "bad" },
    ],
    type: "select",
  },
  MEMusloRatio: {
    title: "Muslo",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Cortedad G1 Isquios", status: "bad" },
      { name: "Cortedad G2 Isquios", status: "bad" },
      { name: "Cortedad Cuádriceps", status: "bad" },
    ],
    type: "select",
  },
  MEMusloRatioAAb: {
    title: "-Ad/Abd",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Cortedad Abducción", status: "bad" },
      { name: "Cortedad Aducción", status: "bad" },
    ],
    type: "select",
  },
  MERotula: {
    title: "Rótula",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Alta", status: "bad" },
      { name: "Convergente", status: "bad" },
      { name: "Divergente", status: "bad" },
    ],
    type: "select",
  },
  MEPierna: {
    title: "Pierna",
    values: [
      { name: "Neutra", status: "good" },
      { name: "Tibia Vara", status: "bad" },
      { name: "Torsión Tibial Ext.", status: "bad" },
      { name: "Torsión Tibial Int.", status: "bad" },
    ],
    type: "select",
  },
  MEAsimetria: { title: "Dismetría (+0,5)", type: "binary" },

  PTMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Neutro", status: "good" },
      { name: "Pie Cavo", status: "bad" },
      { name: "Pie Plano", status: "bad" },
      { name: "Pie Cavo Extremo", status: "bad" },
      { name: "Pie Plano Extremo", status: "bad" },
    ],
    type: "select",
  },
  PTSupraAstragalina: {
    title: "Supra-astragalina",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Extensión Limitada", status: "bad" },
      { name: "Flexión Limitada", status: "bad" },
    ],
    type: "select",
  },
  PTSubAstragalina: {
    title: "Sub-astragalina",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Pronación", status: "bad" },
      { name: "Supinación", status: "bad" },
    ],
    type: "select",
  },
  PTAsimetrias: {
    title: "Asimetría",
    values: [
      { name: "Normalidad", status: "good" },
      { name: "Hallux Valgus (+20º)", status: "bad" },
      { name: "Primer Meta -45º", status: "bad" },
      { name: "Navicular Drop", status: "bad" },
      { name: "Navicular Drift", status: "bad" },
      { name: "Hiperqueratosis", status: "bad" },
    ],
    type: "select",
  },

  Dismetria: { title: "Dismetría (+0,5)", type: "binary" },
  HalluxValgus: { title: "Hallux Valgus (+20º)", type: "binary" },
  Hiperqueratosis: { title: "Hiperqueratosis", type: "binary" },
  PrimerMeta: { title: "Primer Meta -45º", type: "binary" },
  NavicularDrop: { title: "Navicular Drop", type: "binary" },

  TNMorfotipo: {
    title: "Morfotipo",
    values: [
      { name: "Sin afectación", status: "good" },
      { name: "Afectación Neural", status: "bad" },
    ],
    type: "select",
  },
  TNULTTTest: { title: "ULTT Test", type: "binary" },
  TNSLUMPTest: { title: "SLUMP Test", type: "binary" },
  TNSLRTest: { title: "SLR Test", type: "binary" },
  TNCompresivos: { title: "Compresivos", type: "binary" },
};

export const getAvatarValueDefinition = (key: AvatarTestValueKey) => {
  return AvatarTestValuesDefinitions[key];
};

export const getValueNames = (values: Array<AvatarValueType>) => {
  return values.map((v) => v.name);
};

export const avatarSharedGetValueText = (
  test: AvatarTest,
  key: AvatarTestValueKey
) => {
  const def = getAvatarValueDefinition(key);
  if (!test.values) {
    return "Sin valorar";
  }
  const result = test.values[key];
  if (result !== 0 && !result) {
    return "Sin valorar";
  }
  if (def.type === "select") {
    if (result === -1) {
      return "Sin valorar";
    }
    try {
      const valueText = def.values[result].name;
      return valueText;
    } catch (error) {
      return "XX";
    }
  }
  return result;
};

export const avatarSharedGetValueStatus = (
  test: AvatarTest,
  key: AvatarTestValueKey
): AvatarTestValueStatusType => {
  const def = getAvatarValueDefinition(key);
  if (!test.values) {
    return "neutral";
  }
  const result = test.values[key];
  if (result !== 0 && !result) {
    return "neutral";
  }
  if (def.type === "select") {
    if (result === -1) {
      return "neutral";
    }
    try {
      const valueStatus = def.values[result].status;
      return valueStatus;
    } catch (error) {
      return "neutral";
    }
  }
  return "neutral";
};

export const avatarSharedMergeValueStatus = (
  status1: AvatarTestValueStatusType,
  status2: AvatarTestValueStatusType
) => {
  if (!status1 && !status2) {
    return "neutral";
  }
  if (!status1) {
    return status2;
  }
  if (!status2) {
    return status1;
  }

  if (status1 === "bad" || status2 === "bad") {
    return "bad";
  }

  if (status1 === "good" && status2 === "good") {
    return "good";
  }

  return "neutral";
};

export const avatarCalculateLeftRight = (left: any, right: any) => {
  if (left && right) {
    return "??";
  }
  if (!left && right) {
    return "Derecha";
  }
  if (left && !right) {
    return "Izquierda";
  }
  if (!left && !right) {
    return "Sin valorar";
  }
  return "--";
};
