import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface SimpleDropdownOption {
  title: string;
  value: any;
}

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
})
export class SimpleDropdownComponent {
  @Input() fieldName: string;
  @Input() readonly = false;

  @Input() options: Array<SimpleDropdownOption> = [
    { title: 'Opción 1', value: 1 },
    { title: 'Opción 2', value: 2 },
  ];

  @Input() selectedOption: SimpleDropdownOption;

  pValue: any;

  get selectedValue(): any {
    return this.pValue;
  }

  @Input() set selectedValue(v: any) {
    this.pValue = v;
    this.selectedOption = this.options.find((option) => option.value === v);
  }

  // @Input() selectedValue: any;

  @Output() selectedValueChange = new EventEmitter<any>();

  @Input() isOpen = false;

  constructor() {}

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  selectOption(index: number) {
    const option = this.options[index];
    this.selectedValue = option.value;
    this.selectedValueChange.next(this.selectedValue);
    this.close();
  }
}
