import { Test, TestType } from '../../shared/test.outside';
import { isInRange } from '../utils/utils';

export interface SagitalFlechasTest extends Test {
    fc?: number;
    ft?: number;
    fl?: number;
    fs?: number;
    fca?: number;
    fta?: number;
    fla?: number;
    fsa?: number;
    ic?: number;
    ica?: number;
    il?: number;
    ila?: number;
    dorsalResult?: string;
    lumbarResult?: string;
    comments?: string;
}

export const ResultsConditions = {
    dorsalIC: [
        {range: [-Infinity, 19.9], result: 'Hipocifosis', tips: []},
        {range: [19.9, 65], result: 'Normalidad', tips: []},
        {range: [65, Infinity], result: 'Hipercifosis ', tips: []}
    ],
    dorsalICA: [
        {range: [-Infinity, 34.9], result: 'Actitud Cifótica', tips: []},
        {range: [34.9, 50], result: 'Normalidad', tips: []},
        {range: [50, Infinity], result: 'Posible estructuración ', tips: []}
    ],
    lumbarIL: [
        {range: [-Infinity, 19.9], result: 'Hipolordosis', tips: []},
        {range: [19.9, 40], result: 'Normalidad', tips: []},
        {range: [40, Infinity], result: 'Hiperlordosis ', tips: []}
    ]
};

function findCondition(value: number, conditions: any) {
    for (const condition of conditions) {
        if (isInRange(value, condition.range)) {
            return condition;
        }
    }
    return null;
}

export function createEmptySagitalFlechasTest(): SagitalFlechasTest {
    return {
        type: ('sagital-flechas') as TestType,
        owner: '',
        client: ''
    };
}

// function calculateIC(test: SagitalFlechasTest) {
//     let fs = test.fs;
//     let ft = test.ft;
//     let fl = test.fl;
//     let fc = test.fc;
//     if (ft <= fs) {
//         fs = fs - ft;
//         fl = fl - ft;
//         fc = fc - ft;
//         return (fl + fc + fs) / 2;
//     } else {
//         ft = ft - fs;
//         fl = fl - fs;
//         fc = fc - fs;
//         return (fc + fl) / 2 - ft;
//     }
// }

function calculateIC(test: SagitalFlechasTest) {
    let fs = test.fs;
    let ft = test.ft;
    let fl = test.fl;
    let fc = test.fc;
    if (ft <= fs) {
        fs = fs - ft;
        fl = fl - ft;
        fc = fc - ft;
        return (fl + fc + fs) / 2;
    } else {
        ft = ft - fs;
        fl = fl - fs;
        fc = fc - fs;
        return (fc + fl) / 2;
    }
}

function calculateICA(test: SagitalFlechasTest) {
    if (test.ic < 65) {
        return null;
    }
    let fs = test.fsa;
    let ft = test.fta;
    let fl = test.fla;
    let fc = test.fca;
    if (ft <= fs) {
        fs = fs - ft;
        fl = fl - ft;
        fc = fc - ft;
        return (fl + fc + fs) / 2;
    } else {
        ft = ft - fs;
        fl = fl - fs;
        fc = fc - fs;
        return (fc + fl) / 2;
    }
}

// function calculateIL(test: SagitalFlechasTest) {
//     let fs = test.fs;
//     let ft = test.ft;
//     let fl = test.fl;
//     if (ft <= fs) {
//         fs = fs - ft;
//         fl = fl - ft;
//         return (fl + fs) / 2;
//     } else {
//         ft = ft - fs;
//         fl = fl - fs;
//         return (fl + ft) / 2 ;
//     }
// }

function calculateIL(test: SagitalFlechasTest) {
    let fs = test.fs;
    let ft = test.ft;
    let fl = test.fl;
    if (ft <= fs) {
        fs = fs - ft;
        fl = fl - ft;
        return fl - fs / 2;
    } else {
        ft = ft - fs;
        fl = fl - fs;
        return fl - ft / 2 ;
    }
}

// function calculateILA(test: SagitalFlechasTest) {
//     if (test.ic < 65) {
//         return null;
//     }
//     let fs = test.fsa;
//     let ft = test.fta;
//     let fl = test.fla;
//     let fc = test.fca;
//     if (ft <= fs) {
//         fs = fs - ft;
//         fl = fl - ft;
//         fc = fc - ft;
//         return (fl + fc + fs) / 2;
//     } else {
//         ft = ft - fs;
//         fl = fl - fs;
//         fc = fc - fs;
//         return (test.fc + test.fl) / 2 - test.ft;
//     }
// }

function calculateDorsalResult(test: SagitalFlechasTest) {
    let result;
    if (test.ica) {
        result = findCondition(test.ica, ResultsConditions.dorsalICA);
    } else if (test.ic) {
        result = findCondition(test.ic, ResultsConditions.dorsalIC);
    } else {
        result = null;
    }

    if (result === null) {
        return result;
    }

    return result.result;
}

function calculateLumbarResult(test: SagitalFlechasTest) {
    let result;
    if (test.il) {
        result = findCondition(test.ica, ResultsConditions.lumbarIL);
    } else {
        result = null;
    }

    if (result === null) {
        return result;
    }

    return result.result;
}

export function calculateResults(test: SagitalFlechasTest) {
    test.ic = calculateIC(test);
    test.ica = calculateICA(test);
    test.il = calculateIL(test);
    test.dorsalResult = calculateDorsalResult(test);
    test.lumbarResult = calculateLumbarResult(test);
}
