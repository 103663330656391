<button
  [disabled]="disabled"
  class="new-button-regular-with-icon {{ type }} {{
    disabled ? 'opacity-50' : ''
  }}"
  (click)="onClick()"
  style="margin: unset"
  [type]="buttonType"
>
  <div
    *ngIf="icon"
    class="new-button-regular-with-icon__icon  {{
      onlyIconMobile
        ? 'new-button-regular-with-icon__icon-no-margin-mobile'
        : ''
    }} "
  >
    <i class="{{ icon }}"></i>
  </div>
  <div
    class="new-button-regular-with-icon__text {{
      onlyIconMobile ? 'hidden md:inline-block ' : ''
    }}"
  >
    {{ text }}
  </div>
</button>
