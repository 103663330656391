import { Test, TestReportLink } from "./tests.model";

export const TRAINING_AVATAR_PATH = "training-avatar";

export type SegmentKey =
  | "mmssEmpuje"
  | "mmssTraccion"
  | "mmiiEmpuje"
  | "mmiiTraccion"
  | "core"
  | "metabolico";

export interface TrainingAvatar extends Test {
  level?: Partial<Record<SegmentKey, number>>;
  // comments for "nivel" tab
  nivelComments?: string;
  // comments for "resultados" tab
  resultsComments?: string;
  // comments for each segment
  segmentComments?: Partial<Record<SegmentKey, string>>;
  date: Date;
  multimediaLink?: string;
  links?: Array<TestReportLink>;
}

export interface TrainingAvatarTestData {
  title?: string;
  type?: string;
  client?: string;
  clientLevel?: string;
  clientLevelCategory: ClienLevelCategory;
  items?: Partial<Record<SegmentKey, TrainingAvatarItemType>>;
  resultsComments?: string;
  nivelComments?: string;
  haveAnyLinks?: boolean;
  multimediaLink?: string;
  links?: Array<Array<string>>;
}

export interface TrainingAvatarItemType {
  title: string;
  subtitle: string;
  levelFeedback: string;
  comments?: string;
  colorClasses: string;
  iconColorClasses: string;
  iconColorClassesDarker: string;
  iconClasses: string;
}

export type ClienLevelCategory =
  | "Nivel sin determinar"
  | "Amateur"
  | "Deportista"
  | "Atleta"
  | "Élite";

export const colorByLevelServer: Record<ClienLevelCategory, string> = {
  "Nivel sin determinar": "bg-gray-200 text-gray-500",
  Amateur: "bg-yellow-100 text-yellow-800",
  Deportista: "bg-green-200 text-green-800",
  Atleta: "bg-blue-100 text-gray-600",
  Élite: "bg-blue-100 text-gray-600",
};

// NOTE: the only difference is bg-yellow
export const iconColorByLevelServer: Record<ClienLevelCategory, string> = {
  "Nivel sin determinar": "bg-gray-200 text-gray-500",
  Amateur: "bg-yellow-200 text-yellow-800",
  Deportista: "bg-green-200 text-green-800",
  Atleta: "bg-blue-100 text-gray-600",
  Élite: "bg-blue-100 text-gray-600",
};

export const iconByLevel: Record<ClienLevelCategory, string> = {
  "Nivel sin determinar": "far fa-circle",
  Amateur: "fas fa-exclamation-triangle",
  Deportista: "fas fa-check",
  Atleta: "fas fa-trophy",
  Élite: "fas fa-trophy",
};

export const segmentsMap = {
  mmssEmpuje: "Superior Empuje",
  mmssTraccion: "Superior Tracción",
  mmiiEmpuje: "Inferior Empuje",
  mmiiTraccion: "Inferior Tracción",
  core: "Core",
  metabolico: "Metabólico",
} as const satisfies Record<SegmentKey, string>;

export const levelDropdownOptions = [
  { value: undefined, label: "-" },
  { value: 1, label: "Nivel 1" },
  { value: 2, label: "Nivel 2" },
  { value: 3, label: "Nivel 3" },
  { value: 4, label: "Nivel 4" },
  { value: 5, label: "Nivel 5" },
  { value: 6, label: "Nivel 6" },
  { value: 7, label: "Nivel 7" },
  { value: 8, label: "Nivel 8" },
  { value: 9, label: "Nivel 9" },
  { value: 10, label: "Nivel 10" },
] as const;

export function getDropdownLabelByLevel(level: number) {
  if (level === undefined) return "Nivel sin determinar ";
  return levelDropdownOptions.find((o) => o.value === level)?.label;
}

export const levelsMapToNumber = {
  "Nivel sin determinar": [],
  Amateur: [1, 2, 3],
  Deportista: [4, 5, 6],
  Atleta: [7, 8],
  Élite: [9, 10],
} as const satisfies Record<ClienLevelCategory, number[]>;

export function getLevelTextByNumber(level: number): ClienLevelCategory {
  if (level === undefined || isNaN(+level)) {
    return "Nivel sin determinar";
  }
  // find the level category
  const levelCategory = Object.keys(levelsMapToNumber).find((key) => {
    const latest = levelsMapToNumber[key].at(-1);
    return level <= latest;
  }) as ClienLevelCategory;
  return levelCategory;
}

export function getLevelAverage(clientLevel: TrainingAvatar["level"]) {
  const levels = [
    clientLevel?.mmssEmpuje,
    clientLevel?.mmssTraccion,
    clientLevel?.mmiiEmpuje,
    clientLevel?.mmiiTraccion,
    clientLevel?.core,
    clientLevel?.metabolico,
  ].filter((level) => level !== undefined);

  if (levels.every((level) => level === undefined)) {
    return undefined;
  }

  return (
    levels.reduce((acc, level) => acc + level, 0) / levels.length
  ).toFixed(2);
}
