<div class="overlay" [class.overlay--open]="open" (click)="close($event)">
    <div class="app-container" [class.app-container--open]="open">
            <div class="close" >
                <button (click)="close($event)" class="button-close-dialog button-close-dialog--white button-close-dialog--big button-close-dialog--rounded-background"><i class="fas fa-times"></i></button>
            </div>

            <div *ngIf="vimeoId!==null" class="video-container">
                    <div class='embed-container'><iframe [src]="vimeoId" width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div>
            </div>
    </div>
</div>
