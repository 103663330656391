import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ModalService } from 'src/app/services/modal.service';
import { LoadingState, sleep } from 'src/app/utils/utils';
import {
  getSubscriptionTypeByProductId,
  ACTIVE_SUBSCRIPTION_STATUSES,
  SubscriptionType,
} from 'src/shared/subscriptions.shared';

@Component({
  selector: 'app-link-client-modal',
  templateUrl: './link-client-modal.component.html',
})
export class LinkClientModalComponent implements OnInit {
  onClose = new Subject<any>();

  client$: Observable<Client>;

  user: User;

  professionalSubscriptionType: SubscriptionType;

  loadingState: LoadingState = 'loading';

  unlinkLoadingState: LoadingState = 'idle';

  @Input() data!: { client: Client };

  historyBackClose() {
    this.onClose.next('');
  }

  constructor(
    private clipboardService: ClipboardService,
    private authService: AuthService,
    private modalService: ModalService,
    private clientsService: ClientsService,
  ) {}

  ngOnInit() {
    this.client$ = this.clientsService.getClient$(this.data.client.id);
    this.loadingState = 'loading';
    this.authService.user$
      .pipe(
        tap((user) => {
          this.loadingState = 'idle';
          this.user = user;
          this.professionalSubscriptionType =
            user?.subscriptions?.[0]?.productId &&
            ACTIVE_SUBSCRIPTION_STATUSES.includes(
              user?.subscriptions?.[0]?.status,
            )
              ? getSubscriptionTypeByProductId(user.subscriptions[0].productId)
              : undefined;
        }),
      )
      .subscribe();
  }

  close() {
    history.back();
  }

  async editClient(client: Client) {
    await this.modalService.openModal('create-edit-client', { client });
  }

  copyInvitationLink() {
    if (!this.user) {
      console.error('no user');
      return;
    }
    const invitationLink = `${window.location.origin}/c?professionalId=${this.user.uid}`;
    this.clipboardService.copyToClipboard(invitationLink);
  }

  async unlinkClient() {
    this.unlinkLoadingState = 'loading';
    try {
      await this.clientsService.unlinkClient(this.data.client.id);
      this.unlinkLoadingState = 'idle';
    } catch (error) {
      console.error(error);
      this.unlinkLoadingState = 'error';
    }
  }
}
