import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { SimpleDropdownOption } from 'src/app/elements/simple-dropdown/simple-dropdown.component';
import { ModalService } from 'src/app/services/modal.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import {
  CorrectiveSessionSectionsDef,
  CorrectiveSessionProtocolDef,
  optionsStringToOptionsArray,
  calculateImageKey,
} from 'src/shared/corrective-session-definition.outside';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-corrective-session-protocol-edit',
  templateUrl: './corrective-session-protocol-edit.component.html',
  styleUrls: ['./corrective-session-protocol-edit.component.scss'],
})
export class CorrectiveSessionProtocolEditComponent implements OnInit {
  selectedSection: CorrectiveSessionSectionsDef;
  correctiveSessionProtocol: CorrectiveSessionProtocolDef;
  sessionUsingThisProtocolExists$?: Observable<boolean>;
  correctiveSessionProtocolInUse = false;
  saving = false;
  deleting = false;
  duplicating = false;
  sectionEditMode = false;

  copySharedUrlButton = {
    faClass: 'far fa-copy',
    action: () => {
      this.copySharedUrlToClipboard();
    },
  };

  getImageUrlCopyButton = (imageUrl: string) => {
    return {
      faClass: 'far fa-copy',
      action: () => {
        this.clipboardService.copyToClipboard(imageUrl);
      },
    };
  };

  sectionModeOptions: Array<SimpleDropdownOption> = [
    // { title: 'Normal', value: 'fields' },
    { title: 'Tabla', value: 'table' },
  ];

  tableModeOptions: Array<SimpleDropdownOption> = [
    // { title: 'Normal', value: 'x' },
    { title: 'Definir filas', value: 'xy' },
  ];

  fieldTypeOptions: Array<SimpleDropdownOption> = [
    { title: 'Texto', value: 'text' },
    { title: 'Opción', value: 'select' },
  ];

  fieldLeftRightOptions: Array<SimpleDropdownOption> = [
    { title: 'Valor único', value: false },
    { title: 'Izquierda y derecha', value: true },
  ];

  imagePositionOptions: Array<SimpleDropdownOption> = [
    { title: 'Parte inferior de la primera página', value: 'first' },
    { title: 'Página siguiente', value: 'second' },
  ];

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private correctiveSessionsService: CorrectiveSessionsService,
    private backButtonService: OverrideBackButtonService,
    private modals: ModalService,
    private mediaService: MediaService,
    private clients: ClientsService,
    private confirmActionService: ConfirmActionDialogService,
    private auth: AuthService,
    private clipboardService: ClipboardService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const user = await this.auth.getUser();
    const correctiveSessionProtocolId = await safeToPromise(
      this.route.params.pipe(
        map((params) => {
          return params.id;
        }),
        take(1),
      ),
    );
    this.correctiveSessionProtocol =
      await this.correctiveSessionsService.getCorrectiveSessionProtocol(
        correctiveSessionProtocolId,
      );

    this.sessionUsingThisProtocolExists$ = this.correctiveSessionsService
      .getClientCorrectiveSessionByProtocolDefId$(
        this.correctiveSessionProtocol.id,
        user.uid,
      )
      .pipe(
        map((tests) => {
          return tests.length > 0;
        }),
      );

    this.sessionUsingThisProtocolExists$.subscribe((exists) => {
      this.correctiveSessionProtocolInUse = exists;
    });

    this.backButtonService.override(() => {
      this.exit();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.correctiveSessionProtocol.sections,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropField(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedSection.fields,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropRow(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedSection.tableRowFields,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedSection.images,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropFixedImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedSection.fixedImages,
      event.previousIndex,
      event.currentIndex,
    );
  }

  dropDescriptionFixedImage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.correctiveSessionProtocol.fixedImages,
      event.previousIndex,
      event.currentIndex,
    );
  }

  openSection(index: number) {
    console.log('open section', index);
    this.sectionEditMode = true;
    this.selectedSection = this.correctiveSessionProtocol.sections[index];
    this.backButtonService.override(() => {
      this.closeSection();
    });
    window.scrollTo(0, 0);
  }

  closeSection() {
    this.sectionEditMode = false;
    this.backButtonService.cancelOverride();
  }

  addSection() {
    if (!this.correctiveSessionProtocol.sections) {
      this.correctiveSessionProtocol.sections = [];
    }
    if (this.correctiveSessionProtocol.lastLocalId === undefined) {
      this.correctiveSessionProtocol.lastLocalId = -1;
    }
    this.correctiveSessionProtocol.lastLocalId =
      this.correctiveSessionProtocol.lastLocalId + 1;
    this.correctiveSessionProtocol.sections.push({
      name: `Sección ${this.correctiveSessionProtocol.sections.length + 1}`,
      localId: this.correctiveSessionProtocol.lastLocalId,
      fields: [
        {
          localId: 0,
          title: 'Volumen',
          leftRight: false,
          options: [],
          type: 'text',
          optionsString: '',
        },
        {
          localId: 1,
          title: 'Intensidad',
          leftRight: false,
          options: [],
          type: 'text',
          optionsString: '',
        },
        {
          localId: 2,
          title: 'Densidad',
          leftRight: false,
          options: [],
          type: 'text',
          optionsString: '',
        },
      ],
      tableFirstColTitle: 'Ejercicios',
      mode: 'table',
      tableMode: 'xy',
      lastLocalId: 2,
    });
  }

  addField() {
    if (this.selectedSection.fields.length >= 4) {
      return;
    }
    if (!this.selectedSection.fields) {
      this.selectedSection.fields = [];
    }
    if (this.selectedSection.lastLocalId === undefined) {
      this.selectedSection.lastLocalId = -1;
    }
    this.selectedSection.lastLocalId = this.selectedSection.lastLocalId + 1;
    // only allow to add a field if lenght is less than 4
    this.selectedSection.fields.push({
      localId: this.selectedSection.lastLocalId,
      title: 'Campo extra',
      leftRight: false,
      options: [],
      type: 'text',
      optionsString: '',
    });
  }

  addRow() {
    if (!this.selectedSection.tableRowFields) {
      this.selectedSection.tableRowFields = [];
    }
    if (this.selectedSection.lastLocalId === undefined) {
      this.selectedSection.lastLocalId = -1;
    }
    const nextLength = this.selectedSection.tableRowFields.length + 1;
    this.selectedSection.tableRowFields.push({
      // NOTE: doesn't matter because row index will be used as key for values and rows will not be sortable
      localId: 0,
      title: `Ejercicio ${nextLength}`,
      leftRight: false,
      options: [],
      type: 'text',
      optionsString: '',
    });
  }

  addImage() {
    if (!this.selectedSection.images) {
      this.selectedSection.images = [];
    }
    if (this.selectedSection.lastLocalImageId === undefined) {
      this.selectedSection.lastLocalImageId = -1;
    }
    this.selectedSection.lastLocalImageId++;
    this.selectedSection.images.push({
      localId: this.selectedSection.lastLocalImageId,
      key: calculateImageKey(
        this.correctiveSessionProtocol.id,
        this.selectedSection.localId,
        this.selectedSection.lastLocalImageId,
      ),
      name: 'Imagen 1',
      position: 'first',
    });
  }

  addFixedImage() {
    if (!this.selectedSection.fixedImages) {
      this.selectedSection.fixedImages = [];
    }
    if (this.selectedSection.lastLocalImageId === undefined) {
      this.selectedSection.lastLocalImageId = -1;
    }
    this.selectedSection.lastLocalImageId++;
    this.selectedSection.fixedImages.push({
      localId: this.selectedSection.lastLocalImageId,
      key: calculateImageKey(
        this.correctiveSessionProtocol.id,
        this.selectedSection.localId,
        this.selectedSection.lastLocalImageId,
      ),
      link: '',
      title: '',
      position: 'first',
      downloadUrl: '',
    });
  }

  addDescriptionFixedImage() {
    if (!this.correctiveSessionProtocol.fixedImages) {
      this.correctiveSessionProtocol.fixedImages = [];
    }
    if (this.correctiveSessionProtocol.lastLocalImageId === undefined) {
      this.correctiveSessionProtocol.lastLocalImageId = -1;
    }
    this.correctiveSessionProtocol.lastLocalImageId++;
    this.correctiveSessionProtocol.fixedImages.push({
      localId: this.correctiveSessionProtocol.lastLocalImageId,
      key: `fixed`,
      link: '',
      title: '',
      position: 'first',
      downloadUrl: '',
    });
  }

  async uploadFixedImage(index: number) {
    this.clients.setCurrentClient(null);
    const result = await this.modals.openLateralModal('client-media', {
      onlyOriginals: true,
      selectionMode: true,
      selectionName: 'Sesión de ejercicios correctivos',
    });
    if (!result) {
      return;
    }
    const mediaItem = await this.mediaService.getMediaItem(result.id);

    this.selectedSection.fixedImages[index].downloadUrl = mediaItem.downloadURL;
    console.log(mediaItem.downloadURL);
  }

  async uploadDescriptionFixedImage(index: number) {
    const currentClientId = await safeToPromise(
      this.clients.currentClientId$.pipe(take(1)),
    );
    this.clients.setCurrentClient(null);
    const result = await this.modals.openLateralModal('client-media', {
      onlyOriginals: true,
      selectionMode: true,
      selectionName: 'Sesión de ejercicios correctivos',
    });
    this.clients.setCurrentClient(currentClientId);
    if (!result) {
      return;
    }
    const mediaItem = await this.mediaService.getMediaItem(result.id);

    this.correctiveSessionProtocol.fixedImages[index].downloadUrl =
      mediaItem.downloadURL;
    console.log(mediaItem.downloadURL);
  }

  deleteSection(index: number, event) {
    event.stopPropagation();
    this.correctiveSessionProtocol.sections.splice(index, 1);
  }

  duplicateSection(index: number, event) {
    event.stopPropagation();
    if (this.correctiveSessionProtocol.lastLocalId === undefined) {
      this.correctiveSessionProtocol.lastLocalId = -1;
    }
    this.correctiveSessionProtocol.lastLocalId =
      this.correctiveSessionProtocol.lastLocalId + 1;
    const duplicated = JSON.parse(
      JSON.stringify(this.correctiveSessionProtocol.sections[index]),
    );
    const duplicatedSection = {
      ...duplicated,
      localId: this.correctiveSessionProtocol.lastLocalId,
    };
    this.correctiveSessionProtocol.sections.push({ ...duplicatedSection });
  }

  deleteField(index: number, event) {
    event.stopPropagation();
    this.selectedSection.fields.splice(index, 1);
  }

  deleteRow(index: number, event) {
    event.stopPropagation();
    this.selectedSection.tableRowFields.splice(index, 1);
  }

  deleteImage(index: number, event) {
    event.stopPropagation();
    this.selectedSection.images.splice(index, 1);
  }

  deleteFixedImage(index: number, event) {
    event.stopPropagation();
    this.selectedSection.fixedImages.splice(index, 1);
  }

  deleteDescriptionFixedImage(index: number, event) {
    event.stopPropagation();
    this.correctiveSessionProtocol.fixedImages.splice(index, 1);
  }

  async deleteAndExit() {
    this.confirmActionService.openDialog({
      title: `¿Estás seguro/a?`,
      description:
        'Si continúas, perderás todos los registros basados en esta sesión de entrenamiento y no los podrás recuperar.',
      confirmButton: `Eliminar protocolo`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          this.deleting = true;
          await this.correctiveSessionsService.deleteCorrectiveSessionProtocol(
            this.correctiveSessionProtocol,
          );
          this.deleting = false;
          history.back();
        }
      },
    });
  }

  async duplicate() {
    this.duplicating = true;
    await this.correctiveSessionsService.duplicateCorrectiveSessionProtocol(
      this.correctiveSessionProtocol,
    );
    this.snackbarService.success('Sesión de entrenamiento duplicada');
    this.duplicating = false;
    history.back();
  }

  async saveAndExit() {
    this.saving = true;
    optionsStringToOptionsArray(this.correctiveSessionProtocol);
    await this.correctiveSessionsService.updateCorrectiveSessionProtocol(
      this.correctiveSessionProtocol,
    );
    this.saving = false;

    this.navigationService.openCorrectiveSessionsMenu = true;
    history.back();
  }

  async exit() {
    this.navigationService.openCorrectiveSessionsMenu = true;
    history.back();
  }

  async saveSectionAndBack() {
    this.closeSection();
  }

  getShareURL() {
    const currentUrl = new URL(window.location.href);
    const baseUrl = currentUrl.origin;
    return `${baseUrl}/copy-corrective-session-protocol/${this.correctiveSessionProtocol.id}`;
    // return `https://vaf.intecc.org/copy-corrective-session-protocol/${this.correctiveSessionProtocol.id}`;
  }

  copySharedUrlToClipboard() {
    this.clipboardService.copyToClipboard(this.getShareURL());
  }
}
