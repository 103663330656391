<!-- <section *ngIf="show" class="header" >
    <a [routerLink]="['/']" class="header__logo">
        <img src="../../../assets/images/raquis-logo-3-v2.png" appResponsiveSrc alt="Raquis">
    </a>
</section> -->

<div *ngIf="auth.user$ | async">
  <div *ngIf="appName === 'rom' || appName === 'raquis'" class="">
    <div class="micro-bar">
      <!-- <a  href="https://app.intecc.org" target="_blank" class="button-icon intecc-button">
                <img src="../../../assets/images/intecc-icon.png" alt="">
                <i class="fas fa-user-cog"></i>
            </a> -->
      <a [routerLink]="['/']" class="intecc-button button-icon-plain"
        ><i class="fas fa-home"></i
      ></a>

      <a *ngIf="appName === 'rom'" [routerLink]="['/']" class="logo">
        <img
          src="../../../assets/images/rom-logo-v3.png"
          appResponsiveSrc
          alt="Raquis"
        />
        <!-- <a class="simple-link" href="https://intecc.webflow.io/" target="_blank" rel="noopener noreferrer">intecc.org</a> -->
      </a>
      <a
        *ngIf="appName === 'raquis'"
        (click)="reload()"
        class="logo logo--raquis cursor-pointer"
      >
        <!-- <img src="../../../assets/images/raquis-logo-v4.png" appResponsiveSrc alt="Raquis"> -->
        <img
          src="../../../assets/images/raquis-val-logo.png"
          appResponsiveSrc
          alt="Raquis"
        />
        <!-- <a class="simple-link" href="https://intecc.webflow.io/" target="_blank" rel="noopener noreferrer">intecc.org</a> -->
      </a>
      <!-- <button (click)="auth.signOut()" class="button-icon logout-button">
                <i class="fas fa-user-cog"></i>
            </button> -->
      <div class="menu-button logout-button">
        <button
          class="menu-button__button button-icon-plain"
          (click)="openSideMenu()"
        >
          <i class="fas fa-bars"></i>
        </button>
        <div
          class="menu-button__overlay"
          [class.menu-button__overlay--open]="menuOpen"
          (click)="menuOpen = false"
        ></div>
        <div
          class="menu-button__menu"
          [class.menu-button__menu--open]="menuOpen"
        >
          <button
            class="menu-button__menu__button"
            (click)="openAccountModal()"
          >
            Mi cuenta
          </button>
          <!-- <button
            class="menu-button__menu__button"
            (click)="editReportsConfig()"
          >
            Personalizar informes
          </button> -->
          <button
            class="menu-button__menu__button"
            (click)="openExportTestsModal()"
          >
            Exportar valoraciones
          </button>
          <a
            title="aplicaciones@intecc.org"
            href="mailto:aplicaciones@intecc.org"
            class="menu-button__menu__button"
            >Soporte</a
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="appName === 'vaf'" class="">
    <!-- .vaf-bar {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      column-gap: 5rem;
      @include media('<s') {
        column-gap: 3rem;
      }
    } -->
    <div
      class="mb-10 flex items-center justify-between bg-blue-900 px-5 py-3 text-white sm:px-10"
      style="background-color: #000e33"
      *ngIf="auth.user$ | async as user"
    >
      <a class="flex cursor-pointer items-center" [routerLink]="['/']">
        <!-- <img class="h-8" src="../../../assets/images/logo-vaf-white-v2.png" appResponsiveSrc alt="Raquis"> -->
        <!-- <img
          class="crisp mr-3 h-9"
          src="../../../assets/images/intecc-with-bg-rounded.png"
          appResponsiveSrc
          alt="Raquis"
        /> -->
        <i class="fas fa-home text-2xl md:mr-5"></i>
        <p class="hidden text-lg md:block">Inicio</p>
      </a>
      <div class="flex-grow">
        <div
          (click)="reload()"
          *ngIf="!isClient"
          class="flex cursor-pointer items-center justify-center md:mx-6"
        >
          <i class="far fa-user-circle mr-5 hidden md:block"></i>
          <p class="text-center font-bold">
            {{ user.displayName }}
          </p>
        </div>
        <div
          (click)="reload()"
          *ngIf="isClient"
          class="mx-2 flex cursor-pointer items-center justify-center text-sm md:text-base"
        >
          <div class="w-fit">
            <p class="line-clamp-1 text-center font-bold">
              Profesional:
              {{ professional?.displayName ?? 'No asignado' }}
            </p>
            <p class="line-clamp-1 text-center font-bold">
              Cliente: {{ user.displayName }}
            </p>
          </div>
        </div>
      </div>
      <div (click)="openSideMenu()" class="cursor-pointer">
        <i class="fas fa-bars text-2xl"></i>
      </div>
    </div>
  </div>
</div>

<div class="side-menu" *ngIf="show && loadingState === 'idle'">
  <div
    class="side-menu__overlay"
    [class.side-menu__overlay--open]="sideMenuOpen"
    (click)="closeSideMenu()"
  >
    <div
      class="side-menu__box flex h-full flex-col"
      [class.side-menu__box--open]="sideMenuOpen"
      (click)="$event.stopPropagation()"
    >
      <div
        style="padding: 14px"
        class="flex justify-between bg-inteccDark p-5 text-2xl"
      >
        <p class="flex items-center text-2xl font-bold">
          <span>Menú</span>
        </p>
        <div class="flex items-center">
          <a
            *ngIf="!isClient"
            href="https://manual.intecc.org/es/article/menu-superior-app-vaf"
            target="_blank"
            rel="noopener noreferrer"
            class="mr-5 cursor-pointer text-xl"
          >
            <i class="fas fa-graduation-cap text-white"></i>
          </a>
          <div class="cursor-pointer" (click)="closeSideMenu()">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="side-menu flex-grow px-8" *ngIf="!isClient">
        <div class="side-menu__title">Herramientas</div>
        <div class="side-menu__link-list">
          <a class="side-menu__link" (click)="openAccountModal()"
            ><i class="far fa-user-circle"></i><span>Mi cuenta</span></a
          >
          <a
            class="side-menu__link"
            (click)="navigateToMyProfile()"
            [routerLink]="['/my-profile']"
            ><i class="fas fa-user"></i><span>Mis datos</span></a
          >
          <a
            class="side-menu__link"
            (click)="navigateToMyClients()"
            [routerLink]="['/clients']"
            ><i class="fas fa-user-friends"></i><span>Mis clientes</span></a
          >

          <a class="side-menu__link" (click)="openMultimedia()"
            ><i class="fas fa-camera"></i><span>Multimedia</span></a
          >
          <a
            *ngIf="canUseExercises"
            class="side-menu__link"
            (click)="openExercises()"
            ><i class="fas fa-dumbbell"></i><span>Ejercicios</span></a
          >
          <a class="side-menu__link" (click)="openInclinometer()"
            ><i class="fas fa-drafting-compass"></i><span>Inclinómetro</span></a
          >
          <!-- NOTE: not used anymore since global gallery is available -->
          <!-- <a
            *ngIf="appName === 'vaf'"
            class="side-menu__link"
            href="https://drive.google.com/drive/folders/10AH_jAcpLEdY_cIrHD4OcaY1cMZS8eq1?usp=sharing"
            target="_blank"
            ><i class="fab fa-google-drive"></i><span>Galería VAF</span></a
          > -->
          <details
            *ngIf="userConfig && userConfig.customLinks"
            class="hide-native-summary-arrow"
          >
            <summary class="side-menu__link side-menu__link__nested">
              <i class="fas fa-chevron-down"></i><span>Mis enlaces</span>
            </summary>
            <ul class="my-3 pl-9">
              <li
                *ngIf="userConfig && userConfig.customAnamnesisLink"
                class="my-3"
              >
                <a
                  href="{{ userConfig.customAnamnesisLink }}"
                  target="blank"
                  class="flex items-center"
                >
                  <i class="far fa-address-card mr-3"></i
                  ><span>Mi anamnesis</span>
                </a>
              </li>
              <li
                *ngIf="userConfig && userConfig.customClientsLink"
                class="my-3"
              >
                <a
                  href="{{ userConfig.customClientsLink }}"
                  target="blank"
                  class="flex items-center"
                >
                  <i class="fas fa-user-friends mr-3"></i
                  ><span>Mis clientes</span>
                </a>
              </li>
              <li *ngFor="let link of userConfig.customLinks" class="my-3">
                <a
                  *ngIf="link.url && link.name"
                  href="{{ link.url }}"
                  target="blank"
                  class="flex items-center"
                >
                  <span class="block first-letter:uppercase">{{
                    link.name
                  }}</span>
                </a>
              </li>
            </ul>
          </details>
          <!-- <a class="side-menu__link" (click)="openExportTestsModal()"><i class="fas fa-file-export"></i><span>Exportar valoraciones</span></a> -->
        </div>
        <div class="side-menu__spacing"></div>

        <div class="side-menu__title">Formación</div>
        <div class="side-menu__link-list">
          <!-- <a target="_blank" href="https://master.intecc.org/" class="side-menu__link"><i
              class="fas fa-graduation-cap"></i><span>Máster INTECC</span></a>
          <a target="_blank" href="https://plataforma.intecc.org/courses/EXPERTO-valoracion-anatomica-y-funcional"
            class="side-menu__link"><i class="fas fa-graduation-cap"></i><span>Experto en Valoración</span></a> -->
          <a
            *ngIf="currentBrand === 'default'"
            target="_blank"
            href="https://intecc.ebforms.com/6497099602722816"
            class="flex w-full flex-row items-center justify-center overflow-hidden rounded-lg bg-inteccDark px-6 py-4 font-bold uppercase text-white transition-all hover:-translate-y-1"
          >
            <i class="fas fa-graduation-cap mr-2"></i><span>FORMULARIO</span></a
          >
          <a
            *ngIf="currentBrand === 'uem'"
            target="_blank"
            href="https://plataforma.intecc.org/courses/UNIVERSIDAD-iniciacion-a-la-valoracion-anatomica-y-funcional-del-aparato-locomotor"
            class="flex w-full flex-row items-center justify-center overflow-hidden rounded-lg bg-inteccDark px-6 py-4 font-bold uppercase text-white transition-all hover:-translate-y-1"
          >
            <i class="fas fa-graduation-cap mr-2"></i
            ><span>CURSO UNIVERSITARIO</span></a
          >

          <!-- <a *ngIf="appName==='raquis'" class="side-menu__link side-menu__link--disabled"><i
              class="fas fa-graduation-cap"></i><span>Certifícate en App Raquis</span></a> -->
          <a
            *ngIf="appName === 'rom'"
            class="side-menu__link side-menu__link--disabled"
            ><i class="fas fa-graduation-cap"></i
            ><span>Certifícate en App ROM</span></a
          >
        </div>
        <div class="side-menu__title">Redes y Soporte</div>
        <div class="side-menu__spacing"></div>
        <div class="side-menu__link-list">
          <a
            class="side-menu__link"
            target="_blank"
            href="https://www.instagram.com/intecc_org/"
            ><i class="fab fa-instagram"></i><span>Instagram</span></a
          >
          <a
            class="side-menu__link"
            target="_blank"
            href="https://www.facebook.com/INTECC.ORG/"
            ><i class="fab fa-facebook-f"></i><span>Facebook</span></a
          >
          <!-- <a
            target="_blank"
            href="https://wa.me/34689001549?text=Hola%21"
            class="mb-2 mt-4 flex w-full flex-row items-center justify-center overflow-hidden rounded-lg bg-inteccDark px-6 py-4 font-bold uppercase text-white transition-all hover:-translate-y-1"
            ><i class="fab fa-whatsapp mr-2"></i><span>Whatsapp</span></a
          > -->
          <button
            class="mb-2 mt-4 flex w-full flex-row items-center justify-center overflow-hidden rounded-lg bg-inteccDark px-6 py-4 font-bold uppercase text-white transition-all hover:-translate-y-1"
            (click)="openCrispSupport()"
          >
            <i class="fas fa-comment-dots mr-2"></i>
            <span>Soporte</span>
          </button>
          <a
            target="_blank"
            href="mailto:aplicaciones@intecc.org"
            class="mt-1 flex flex-row justify-center text-center"
            ><span>aplicaciones&#64;intecc.org</span></a
          >
        </div>
        <div class="mb-10"></div>

        <!-- <div class="flex flex-col items-center opacity-80">
          <div class="w-20 h-20">
            <img class="w-full h-full object-contain" src="../../../assets/images/intecc-logo-full-white.png" appResponsiveSrc alt="">
          </div>
       
        </div> -->
        <!-- <div class="side-menu__social-container">
          <a href="mailto:aplicaciones@intecc.org" class="side-menu__social-container__link">
            <i class="far fa-envelope"></i>
          </a>
          <a target="_blank" href="https://wa.me/34659305590?text=Hola%21" class="side-menu__social-container__link">
            <i class="fab fa-whatsapp"></i>
          </a>
          <a target="_blank" href="https://www.instagram.com/intecc_org/" class="side-menu__social-container__link">
            <i class="fab fa-instagram"></i>
          </a>
          <a target="_blank" href="https://www.facebook.com/INTECC.ORG/" class="side-menu__social-container__link">
            <i class="fab fa-facebook-f"></i>
          </a>
        </div> -->
      </div>
      <div class="side-menu flex flex-grow flex-col px-8 py-8" *ngIf="isClient">
        <div class="side-menu__link-list flex-grow space-y-4">
          <a
            (click)="navigateToFromSideMenu('/c')"
            class="side-menu__link"
            routerLink="/c"
          >
            <i class="fas fa-home"></i><span>Inicio</span>
          </a>
          <div>
            <a
              (click)="navigateToFromSideMenu('/c/client')"
              class="side-menu__link"
              routerLink="/c/client"
            >
              <i class="fas fa-user"></i><span>Cliente</span>
            </a>
            <ul class="my-1 pl-9">
              <li class="my-2 flex items-center space-x-2">
                <a
                  (click)="navigateToFromSideMenu('/c/client/questionnaire')"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                  routerLink="/c/client/questionnaire"
                >
                  <a
                    *ngIf="professionalConfig"
                    [href]="
                      professionalConfig?.questionnaireExplanationLink || null
                    "
                    [target]="
                      professionalConfig?.questionnaireExplanationLink
                        ? '_blank'
                        : '_self'
                    "
                    [class.cursor-default]="
                      !professionalConfig?.questionnaireExplanationLink ||
                      !professionalConfig?.displayQuestionnaireExplanationLink
                    "
                    class="mr-1 inline px-2 py-1"
                    (click)="
                      !professionalConfig?.questionnaireExplanationLink ||
                      !professionalConfig?.displayQuestionnaireExplanationLink
                        ? $event.preventDefault()
                        : $event.stopPropagation()
                    "
                  >
                    <i
                      class="fas fa-info-circle text-lg"
                      [class.opacity-50]="
                        !professionalConfig?.questionnaireExplanationLink ||
                        !professionalConfig?.displayQuestionnaireExplanationLink
                      "
                    ></i>
                  </a>
                  <span>Cuestionario</span>
                </a>
              </li>
              <li
                *ngIf="questionnaireUrl"
                class="my-3 flex items-center space-x-2"
              >
                <a
                  [href]="questionnaireUrl"
                  target="_blank"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                >
                  <i
                    class="fas fa-clipboard-list mr-1 inline px-2 py-1 text-lg"
                  ></i>
                  <span>Cuestionario específico</span>
                </a>
              </li>
              <li class="my-3 flex items-center space-x-2">
                <!-- TODO: remove [class] statements below  when route for questionnaire is ready -->
                <a
                  href="#"
                  target="blank"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                  [class.opacity-50]="true"
                  [class.pointer-events-none]="true"
                >
                  <a
                    *ngIf="professionalConfig"
                    [href]="professionalConfig?.calendarExplanationLink || null"
                    [target]="
                      professionalConfig?.calendarExplanationLink
                        ? '_blank'
                        : '_self'
                    "
                    [class.cursor-default]="
                      !professionalConfig?.calendarExplanationLink ||
                      !professionalConfig?.displayCalendarExplanationLink
                    "
                    class="mr-1 inline px-2 py-1"
                    (click)="
                      !professionalConfig?.calendarExplanationLink ||
                      !professionalConfig?.displayCalendarExplanationLink
                        ? $event.preventDefault()
                        : $event.stopPropagation()
                    "
                  >
                    <i
                      class="fas fa-info-circle text-lg"
                      [class.opacity-50]="
                        !professionalConfig?.calendarExplanationLink ||
                        !professionalConfig?.displayCalendarExplanationLink
                      "
                    ></i>
                  </a>
                  <span>Calendario</span>
                </a>
              </li>
              <li class="my-3 flex items-center space-x-2">
                <!-- TODO: remove [class] statements below  when route for questionnaire is ready -->
                <a
                  href="#"
                  target="blank"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                  [class.opacity-50]="true"
                  [class.pointer-events-none]="true"
                >
                  <a
                    *ngIf="professionalConfig"
                    [href]="professionalConfig?.metricsExplanationLink || null"
                    [target]="
                      professionalConfig?.metricsExplanationLink
                        ? '_blank'
                        : '_self'
                    "
                    [class.cursor-default]="
                      !professionalConfig?.metricsExplanationLink ||
                      !professionalConfig?.displayMetricsExplanationLink
                    "
                    class="mr-1 inline px-2 py-1"
                    (click)="
                      !professionalConfig?.metricsExplanationLink ||
                      !professionalConfig?.displayMetricsExplanationLink
                        ? $event.preventDefault()
                        : $event.stopPropagation()
                    "
                  >
                    <i
                      class="fas fa-info-circle text-lg"
                      [class.opacity-50]="
                        !professionalConfig?.metricsExplanationLink ||
                        !professionalConfig?.displayMetricsExplanationLink
                      "
                    ></i>
                  </a>
                  <span>Métricas y evolución</span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <a
              (click)="navigateToFromSideMenu('/c/tests')"
              class="side-menu__link"
              routerLink="/c/tests"
            >
              <i class="fas fa-heart"></i><span>Valoración</span>
            </a>
            <ul class="my-1 pl-9">
              <li class="my-3 flex items-center space-x-2">
                <a
                  [href]="professionalConfig?.appointmentLink || '#'"
                  target="blank"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                  [class.opacity-50]="!professionalConfig?.appointmentLink"
                  [class.pointer-events-none]="
                    !professionalConfig?.appointmentLink
                  "
                >
                  <a
                    *ngIf="professionalConfig"
                    [href]="
                      professionalConfig?.appointmentExplanationLink || null
                    "
                    [target]="
                      professionalConfig?.appointmentExplanationLink
                        ? '_blank'
                        : '_self'
                    "
                    [class.cursor-default]="
                      !professionalConfig?.appointmentExplanationLink ||
                      !professionalConfig?.displayAppointmentExplanationLink
                    "
                    class="mr-1 inline px-2 py-1"
                    (click)="
                      !professionalConfig?.appointmentExplanationLink ||
                      !professionalConfig?.displayAppointmentExplanationLink
                        ? $event.preventDefault()
                        : $event.stopPropagation()
                    "
                  >
                    <i
                      class="fas fa-info-circle text-lg"
                      [class.opacity-50]="
                        !professionalConfig?.appointmentExplanationLink ||
                        !professionalConfig?.displayAppointmentExplanationLink
                      "
                    ></i>
                  </a>
                  <span>
                    {{
                      professionalConfig?.displayCustomAppointmentLabel
                        ? professionalConfig?.customAppointmentLabel ||
                          'Reservar cita'
                        : 'Reservar cita'
                    }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <a
              (click)="navigateToFromSideMenu('/c/training')"
              class="side-menu__link"
              routerLink="/c/training"
            >
              <i class="fas fa-dumbbell"></i><span>Entrenamiento</span>
            </a>
            <ul class="my-1 pl-9">
              <li class="my-3 flex items-center space-x-2">
                <a
                  [href]="professionalConfig?.hireServiceLink || '#'"
                  target="blank"
                  class="flex w-full items-center rounded-md bg-inteccDark px-4 py-1 transition-colors hover:bg-inteccDark/80"
                  [class.opacity-50]="!professionalConfig?.hireServiceLink"
                  [class.pointer-events-none]="
                    !professionalConfig?.hireServiceLink
                  "
                >
                  <a
                    *ngIf="professionalConfig"
                    [href]="
                      professionalConfig?.hireServiceExplanationLink || null
                    "
                    [target]="
                      professionalConfig?.hireServiceExplanationLink
                        ? '_blank'
                        : '_self'
                    "
                    [class.cursor-default]="
                      !professionalConfig?.hireServiceExplanationLink ||
                      !professionalConfig?.displayHireServiceExplanationLink
                    "
                    class="mr-1 inline px-2 py-1"
                    (click)="
                      !professionalConfig?.hireServiceExplanationLink ||
                      !professionalConfig?.displayHireServiceExplanationLink
                        ? $event.preventDefault()
                        : $event.stopPropagation()
                    "
                  >
                    <i
                      class="fas fa-info-circle text-lg"
                      [class.opacity-50]="
                        !professionalConfig?.hireServiceExplanationLink ||
                        !professionalConfig?.displayHireServiceExplanationLink
                      "
                    ></i>
                  </a>
                  <span>
                    {{
                      professionalConfig?.displayCustomHireServiceLabel
                        ? professionalConfig?.customHireServiceLabel ||
                          'Contratar servicio'
                        : 'Contratar servicio'
                    }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <a
              (click)="navigateToFromSideMenu('/c/professional')"
              class="side-menu__link"
              routerLink="/c/professional"
            >
              <i class="fas fa-user-tie"></i><span>Profesional</span>
            </a>
            <ul class="my-1 pl-9" *ngIf="!professionalConfig">
              <li class="my-3 flex items-center space-x-2">
                <a
                  href="https://plataforma.intecc.org/pages/profesionales-intecc"
                  target="_blank"
                  class="inline py-1"
                >
                  Centros certificados
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- home buttons section -->
        <ng-container
          *ngIf="professionalConnectLinks$ | async as professionalConnectLinks"
        >
          <div
            *ngIf="professionalConnectLinks.length"
            class="my-12 flex flex-wrap justify-center space-x-3 space-y-1"
          >
            @for (connectLink of professionalConnectLinks; track connectLink) {
              <a
                [href]="
                  connectLink.key === 'connectEmail' &&
                  !connectLink.url.startsWith('mailto:')
                    ? 'mailto:' + connectLink.url
                    : connectLink.url
                "
                target="_blank"
                class="flex shrink-0 items-center justify-center p-2 text-3xl text-white"
              >
                <i class="{{ connectLink.icon }}"></i>
              </a>
            }
          </div>
        </ng-container>
        <!-- end home buttons section -->
        <!-- Close session button -->
        <div class="mt-auto flex justify-center">
          <button
            class="rounded-md px-4 py-2 transition-colors hover:bg-inteccDark/50"
            (click)="logout()"
          >
            <i class="fas fa-sign-out-alt mr-2"></i><span>Cerrar sesión</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-inclinometer
  [open]="inclinometerOpen"
  [minimalMode]="true"
  [infoText]="'Inclinómetro'"
  (onclose)="onInclinometerClose()"
></app-inclinometer>
