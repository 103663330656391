import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Test } from '../../../shared/test.outside';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, shareReplay, tap } from 'rxjs/operators';
import { TestService } from 'src/app/services/test.service';
import { Client } from 'src/app/models/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { safeToPromise } from 'src/app/utils/utils';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  testId$: Observable<string>;
  test$: Observable<Test>;
  clientId$: Observable<string>;
  client$: Observable<Client>;
  isUserClientView: boolean;

  constructor(
    private route: ActivatedRoute,
    private tests: TestService,
    private clients: ClientsService,
    private userClientService: UserClientService,
    public nav: NavigationService,
  ) {}

  ngOnInit() {
    this.isUserClientView = this.route.snapshot.data['isUserClientView'];
    this.testId$ = this.route.paramMap.pipe(map((params) => params.get('id')));
    this.test$ = this.testId$.pipe(
      switchMap((testId) => this.tests.getTest$(testId)),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.clientId$ = this.test$.pipe(map((test) => test.client));
    // this.client$ = this.clientId$.pipe(switchMap(clientId => this.clients.getClient$(clientId)));
    // eslint-disable-next-line max-len

    if (this.isUserClientView) {
      this.client$ = this.userClientService.userClient$;
    } else {
      this.client$ = this.clientId$.pipe(
        tap((clientId) => this.clients.setCurrentClient(clientId)),
        switchMap((clientId) => this.clients.currentClient$),
      );
    }
  }

  async backToClient() {
    const clientId = await safeToPromise(this.clientId$.pipe(take(1)));
    this.nav.goToClient(clientId);
  }
}
