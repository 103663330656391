<div class="app-container">
  <app-test-header
    [title]="'Protocolo VAF Raquis'"
    [icon]="'fas fa-database'"
    [learnId]="'RaquisMorfotipo'"
    [learnURL]="'https://manual.intecc.org/es/article/protocolo-vaf-raquis'"
    [isUserClientView]="isUserClientView"
    [generateReportAction]="generateReport"
  ></app-test-header>

  <div class="test-info">
    <app-client-back-button
      *ngIf="!isUserClientView"
      [client]="client"
    ></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
      <button
        *ngIf="!isUserClientView"
        (click)="picker.open()"
        class="date-selector__button"
      >
        {{ test.createdAt.toDate() | date }} <i class="fas fa-edit"></i>
      </button>
      <span *ngIf="isUserClientView">
        {{ test.createdAt.toDate() | date }}
      </span>

      <input
        style="display: none"
        (dateChange)="onDateChanged($event)"
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
      />
      <mat-datepicker
        touchUi
        #picker
        [startAt]="test.createdAt.toDate()"
      ></mat-datepicker>
    </div>
  </div>

  <div *ngIf="!this.diagnosticsOpen" class="measures animated fadeIn">
    <button
      (click)="openDiagnostics()"
      *ngIf="diagnosticsAvailable"
      class="open-diagnostics animated fadeIn"
    >
      <div class="open-diagnostics__icon-1">
        <i class="far fa-check-circle"></i>
      </div>
      <div class="open-diagnostics__label">Diagnóstico</div>
      <div class="open-diagnostics__icon-2">
        <i class="fas fa-chevron-right"></i>
      </div>
    </button>

    <div class="test-card">
      <div class="test-card__heading">
        <div class="test-card__title tooltip">
          Bipedestación
          <div class="top top--test-card">
            <h3>Bipedestación</h3>
            <p>
              En bipedestación (de pie), con los brazos junto al tronco y con la
              mirada al frente, manteniendo una postura cómoda y relajada.
            </p>
            <i></i>
          </div>
        </div>
        <div class="test-card__help">
          <div>
            <!-- <i class="fas fa-book"></i> -->
            <i
              *ngIf="!isUserClientView"
              (click)="openLearn('sagital-test-bipedestacion')"
              class="fas fa-book"
            ></i>
          </div>
        </div>
      </div>

      <div class="test-card__divider"></div>
      <div class="test-card__scroll">
        <div class="test-card__content">
          <div class="test-card__item">
            <p class="test-card__label">Dorsal</p>
            <!-- <p (click)="openInclinometer('Bipedestación - Dorsal', ['bipedestacion', 'dorsal'])" class="test-card__value" >{{test.bipedestacion.dorsal}}</p> -->
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('bip/dor', true)"
              [value]="test.bipedestacion.dorsal"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.bipedestacionResults.dorsal"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Lumbar</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('bip/lum', true)"
              [value]="test.bipedestacion.lumbar"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.bipedestacionResults.lumbar"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Pelvis (S-V)</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('bip/pel', true)"
              [value]="test.bipedestacion.pelvis"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.bipedestacionResults.pelvis"
            ></app-test-result-tag>
          </div>
        </div>
      </div>
      <div
        class="test-card__extra-collapsable"
        [class.test-card__extra-collapsable--open]="
          test.bipedestacion.dorsal >= 41
        "
      >
        <div class="test-card__divider"></div>
        <div class="test-card__extra">
          <div class="test-card__extra__container">
            <div class="test-card__extra__heading">
              <div class="test-card__extra__title test-card__label tooltip">
                Maniobra de Autocorrección
                <div class="top">
                  <h3>Maniobra de Autocorrección</h3>
                  <p>
                    Cuando el resultado de la cifosis dorsal sea superior a 45º
                    (en adolescentes o en adultos), es recomendable cuantificar
                    el grado de cifosis dorsal en la máxima corrección “activa”,
                    que la obtendremos mediante esta maniobra que nos informa
                    del grado de flexibilidad de la cifosis. Se invita a nuestro
                    cliente a que se estire al máximo (cómo creciendo) pero sin
                    crear hiperlordosis, buscando la máxima reducción de su
                    cifosis dorsal.
                  </p>
                  <i></i>
                </div>
              </div>
              <div class="test-card__extra__title-help test-card__help">
                <div>
                  <!-- <i class="fas fa-book"></i> -->
                  <i
                    *ngIf="!isUserClientView"
                    (click)="openLearn('sagital-test-bipedestacion-ma')"
                    class="fas fa-book"
                  ></i>
                </div>
              </div>
            </div>
            <div class="test-card__extra__content">
              <app-test-angle-value
                class="test-card__extra__value test-card__value"
                (click)="openInclinometerItem('bip/dor/ma', true)"
                [value]="test.bipedestacion.dorsalMA"
              ></app-test-angle-value>
              <app-test-result-tag
                class="test-card__extra__result"
                [result]="test.bipedestacionResults.dorsalMA"
              ></app-test-result-tag>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="test-card">
      <div class="test-card__heading">
        <div class="test-card__title tooltip">
          Sedentación Asténica
          <div class="top top--test-card">
            <h3>Sedentación Asténica</h3>
            <p>
              En Sedentación (sentado) Asténica (relajado), con los antebrazos
              sobre los muslos y con la mirada al frente, manteniendo una
              postura relajada.
            </p>
            <p></p>

            <i></i>
          </div>
        </div>
        <div class="test-card__help">
          <div>
            <!-- <i class="fas fa-book"></i> -->
            <i
              *ngIf="!isUserClientView"
              (click)="openLearn('sagital-test-sedentacion')"
              class="fas fa-book"
            ></i>
          </div>
        </div>
      </div>
      <div class="test-card__divider"></div>
      <div class="test-card__scroll">
        <div class="test-card__content">
          <div class="test-card__item">
            <p class="test-card__label">Dorsal</p>
            <!-- <p (click)="openInclinometer('Bipedestación - Dorsal', ['bipedestacion', 'dorsal'])" class="test-card__value" >{{test.bipedestacion.dorsal}}</p> -->
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('sed/dor', true)"
              [value]="test.sedentacion.dorsal"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.sedentacionResults.dorsal"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Lumbar</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('sed/lum', true)"
              [value]="test.sedentacion.lumbar"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.sedentacionResults.lumbar"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Pelvis (LH SA)</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('sed/pel', true)"
              [value]="test.sedentacion.pelvic"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.sedentacionResults.pelvic"
            ></app-test-result-tag>
          </div>
        </div>
      </div>
    </div>

    <div class="test-card">
      <div class="test-card__heading">
        <div class="test-card__title tooltip">
          Flexión Máxima del Tronco
          <div class="top top--test-card">
            <h3>Flexión Máxima del Tronco</h3>
            <p>
              En máxima flexión del tronco sobre una camilla o en el suelo,
              invitar a que realice la máxima flexión del tronco con las
              rodillas extendidas y con una mano sobre la otra intentar alcanzar
              o sobrepasar los dedos de los pies. Mantener esta postura durante
              unos segundos.
            </p>

            <i></i>
          </div>
        </div>
        <div class="test-card__help">
          <div>
            <!-- <i class="fas fa-book"></i> -->
            <i
              *ngIf="!isUserClientView"
              (click)="openLearn('sagital-test-flex')"
              class="fas fa-book"
            ></i>
          </div>
        </div>
      </div>
      <div class="test-card__divider"></div>
      <div class="test-card__scroll">
        <div class="test-card__content">
          <div class="test-card__item">
            <p class="test-card__label">Dorsal</p>
            <!-- <p (click)="openInclinometer('Bipedestación - Dorsal', ['bipedestacion', 'dorsal'])" class="test-card__value" >{{test.bipedestacion.dorsal}}</p> -->
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('flex/dor', true)"
              [value]="test.flexMaxTronco.dorsal"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.flexMaxTroncoResults.dorsal"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Lumbar</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('flex/lum', true)"
              [value]="test.flexMaxTronco.lumbar"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.flexMaxTroncoResults.lumbar"
            ></app-test-result-tag>
          </div>
          <div class="test-card__item">
            <p class="test-card__label">Pelvis (LH Fx)</p>
            <app-test-angle-value
              class="test-card__value"
              (click)="openInclinometerItem('flex/pel', true)"
              [value]="test.flexMaxTronco.pelvic"
            ></app-test-angle-value>
            <app-test-result-tag
              [result]="test.flexMaxTroncoResults.pelvic"
            ></app-test-result-tag>
          </div>
        </div>
      </div>
    </div>

    <div class="test-card-extra">
      <div class="test-card__heading">
        <div class="test-card__title tooltip">
          Ápex
          <div class="top top--test-card">
            <h3>Ápex</h3>
            <p>
              Marca esta casilla si observas una vértebra muy prominente en la
              línea media.
            </p>
            <i></i>
          </div>
        </div>
        <div class="test-card__help"></div>
      </div>
      <div class="test-card__divider"></div>
      <div class="test-card-extra__content">
        <div class="test-card-extra__label-value">
          <div class="test-card-extra__label">Presenta ápex</div>
          <div class="test-card-extra__value">
            <div class="switch">
              <ng-container *ngIf="!isUserClientView">
                <input
                  [(ngModel)]="test.apex"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="updateTest()"
                  type="checkbox"
                  id="switch"
                /><label for="switch">Toggle</label>
              </ng-container>
              <ng-container *ngIf="isUserClientView">
                <div>{{ test.apex ? 'Sí' : 'No' }}</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="test-card-extra__info"
          [class.test-card-extra__info--open]="test.apex"
        >
          <p>
            La presencia de ápex es un claro indicador de Hipercifosis
            Estructurada. Haz click en "Diagnóstico" para saber más.
          </p>
        </div>
      </div>
    </div>
    <div class="test-card-extra">
      <div class="test-card__heading">
        <div class="test-card__title">Otros</div>
      </div>
      <div class="test-card__divider"></div>
      <div class="test-card-extra__content space-y-2">
        <div class="test-card-extra__label-value">
          <div class="test-card-extra__label">Escoliosis</div>
          <div class="test-card-extra__value w-48 shrink">
            <ng-container *ngIf="!isUserClientView">
              <app-test-field-dropdown
                [options]="getFieldDefinitionValues('escoliosis')"
                [selectedIndex]="getSelectIndex('escoliosis')"
                (selectedItemChanged)="setSelectValue('escoliosis', $event)"
              ></app-test-field-dropdown>
            </ng-container>
            <ng-container *ngIf="isUserClientView">
              <div class="text-right">{{ getSelectValue('escoliosis') }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.diagnosticsOpen" class="diagnostics animated fadeIn">
    <button (click)="back()" class="close-diagnostics">
      <div class="close-diagnostics__icon">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="close-diagnostics__label">Volver</div>
    </button>

    <div class="diagnostics-container">
      <div class="diagnostics-container__row diagnostics-container__row--1">
        <div
          class="diagnostic-card"
          [class.diagnostic-card--not-available]="!test.dorsalResult"
        >
          <div class="diagnostic-card__title">Dorsal</div>
          <div class="diagnostic-card__divider"></div>
          <div
            class="diagnostic-card__content"
            *ngIf="test.dorsalResult as result"
          >
            <div class="diagnostic-card__result">{{ result.result }}</div>
            <div class="diagnostic-card__result-tips-divider"></div>
            <div class="diagnostic-card__tips">
              <ul class="diagnostic-card__tips-list">
                <li
                  *ngFor="let tip of result.tips"
                  class="diagnostic-card__tips-list-item"
                >
                  {{ tip }}
                </li>
                <div
                  *ngIf="result.tips.length === 0"
                  class="diagnostic-card__tips-list-no-tips"
                >
                  ¡Enhorabuena!
                </div>
              </ul>
            </div>
          </div>
          <div
            *ngIf="!test.dorsalResult"
            class="diagnostic-card__content-empty"
          >
            <p>Se necesitan más medidas para obtener este diagnóstico.</p>
          </div>
        </div>
        <div
          class="diagnostic-card"
          [class.diagnostic-card--not-available]="!test.lumbarResult"
        >
          <div class="diagnostic-card__title">Lumbar</div>
          <div class="diagnostic-card__divider"></div>
          <div
            class="diagnostic-card__content"
            *ngIf="test.lumbarResult as result"
          >
            <div class="diagnostic-card__result">{{ result.result }}</div>
            <div class="diagnostic-card__result-tips-divider"></div>
            <div class="diagnostic-card__tips">
              <ul class="diagnostic-card__tips-list">
                <li
                  *ngFor="let tip of result.tips"
                  class="diagnostic-card__tips-list-item"
                >
                  {{ tip }}
                </li>
                <div
                  *ngIf="result.tips.length === 0"
                  class="diagnostic-card__tips-list-no-tips"
                >
                  ¡Enhorabuena!
                </div>
              </ul>
            </div>
          </div>
          <div
            *ngIf="!test.lumbarResult"
            class="diagnostic-card__content-empty"
          >
            <p>Se necesitan más medidas para obtener este diagnóstico.</p>
          </div>
        </div>
      </div>
      <div class="diagnostics-container__row diagnostics-container__row--2">
        <div
          class="diagnostic-card"
          [class.diagnostic-card--not-available]="
            !test.bipedestacionResults.pelvis
          "
        >
          <div class="diagnostic-card__title">Pelvis - Bipedestación</div>
          <div class="diagnostic-card__divider"></div>
          <div
            class="diagnostic-card__content"
            *ngIf="test.bipedestacionResults.pelvis as result"
          >
            <div class="diagnostic-card__result">{{ result.text }}</div>
            <div class="diagnostic-card__result-tips-divider"></div>
            <div class="diagnostic-card__tips">
              <ul class="diagnostic-card__tips-list">
                <li
                  *ngFor="let tip of result.tips"
                  class="diagnostic-card__tips-list-item"
                >
                  {{ tip }}
                </li>
                <div
                  *ngIf="result.tips.length === 0"
                  class="diagnostic-card__tips-list-no-tips"
                >
                  ¡Enhorabuena!
                </div>
              </ul>
            </div>
          </div>
          <div
            *ngIf="!test.bipedestacionResults.pelvis"
            class="diagnostic-card__content-empty"
          >
            <p>Se necesitan más medidas para obtener este diagnóstico.</p>
          </div>
        </div>

        <div
          class="diagnostic-card"
          [class.diagnostic-card--not-available]="
            !test.sedentacionResults.pelvic
          "
        >
          <div class="diagnostic-card__title">Pelvis - Sedentación</div>
          <div class="diagnostic-card__divider"></div>
          <div
            class="diagnostic-card__content"
            *ngIf="test.sedentacionResults.pelvic as result"
          >
            <div class="diagnostic-card__result">{{ result.text }}</div>
            <div class="diagnostic-card__result-tips-divider"></div>
            <div class="diagnostic-card__tips">
              <ul class="diagnostic-card__tips-list">
                <li
                  *ngFor="let tip of result.tips"
                  class="diagnostic-card__tips-list-item"
                >
                  {{ tip }}
                </li>
                <div
                  *ngIf="result.tips.length === 0"
                  class="diagnostic-card__tips-list-no-tips"
                >
                  ¡Enhorabuena!
                </div>
              </ul>
            </div>
          </div>
          <div
            *ngIf="!test.sedentacionResults.pelvic"
            class="diagnostic-card__content-empty"
          >
            <p>Se necesitan más medidas para obtener este diagnóstico.</p>
          </div>
        </div>

        <div
          class="diagnostic-card"
          [class.diagnostic-card--not-available]="
            !test.flexMaxTroncoResults.pelvic
          "
        >
          <div class="diagnostic-card__title">Pelvis - Flexión</div>
          <div class="diagnostic-card__divider"></div>
          <div
            class="diagnostic-card__content"
            *ngIf="test.flexMaxTroncoResults.pelvic as result"
          >
            <div class="diagnostic-card__result">{{ result.text }}</div>
            <div class="diagnostic-card__result-tips-divider"></div>
            <div class="diagnostic-card__tips">
              <ul class="diagnostic-card__tips-list">
                <li
                  *ngFor="let tip of result.tips"
                  class="diagnostic-card__tips-list-item"
                >
                  {{ tip }}
                </li>
                <div
                  *ngIf="result.tips.length === 0"
                  class="diagnostic-card__tips-list-no-tips"
                >
                  ¡Enhorabuena!
                </div>
              </ul>
            </div>
          </div>
          <div
            *ngIf="!test.flexMaxTroncoResults.pelvic"
            class="diagnostic-card__content-empty"
          >
            <p>Se necesitan más medidas para obtener este diagnóstico.</p>
          </div>
        </div>
      </div>
      <div class="diagnostics-container__row diagnostics-container__row--3">
        <div class="diagnostic-card">
          <div class="diagnostic-card__title">Comentarios</div>
          <div class="diagnostic-card__content">
            <div class="diagnostic-card__comments-box">
              <textarea
                [readonly]="isUserClientView"
                #commentsTextArea
                name="comments"
                cols="30"
                rows="5"
                [value]="test.comments ? test.comments : ''"
                (change)="onCommentsChanged($event)"
              ></textarea>
            </div>
          </div>
          <div class="diagnostic-card__content">
            <p class="mb-1 mt-7 text-lg font-semibold">Enlaces multimedia</p>
            <ng-container *ngIf="!isUserClientView">
              <p>
                Accede a la carpeta “Documentos” e inserta la URL de los
                archivos multimedia.
              </p>
              <button
                *ngIf="!clientSharedFolderUrl"
                (click)="this.editClient({ client })"
                class="mt-2 font-semibold text-intecc2"
              >
                Añade una carpeta "Documentos" del cliente
              </button>
              <a
                *ngIf="clientSharedFolderUrl"
                class="mt-2 inline-block font-semibold text-intecc2"
                href="{{ clientSharedFolderUrl }}"
                target="_blank"
                rel="noopener noreferrer"
                >Ir a carpeta "Documentos" del cliente</a
              >
              <input
                class="w-full rounded-lg border-2 border-neutral-200"
                name="multimediaLink"
                [ngModel]="
                  this.test.multimediaLink ? this.test.multimediaLink : ''
                "
                (ngModelChange)="this.setMultimediaLink($event)"
              />
            </ng-container>
            <ng-container *ngIf="isUserClientView">
              <a
                *ngIf="this.test.multimediaLink"
                href="{{ this.test.multimediaLink }}"
                target="_blank"
                rel="noopener noreferrer"
                class="break-all hover:underline"
              >
                {{ this.test.multimediaLink }}
              </a>
              <p *ngIf="!this.test.multimediaLink">
                No hay enlace multimedia disponible
              </p>
            </ng-container>
            <p class="mb-1 mt-7 text-lg font-semibold">Enlaces opcionales</p>
            <p *ngIf="!isUserClientView">
              Añade enlaces opcionales a los informes (webs, videos, lecturas,
              etc).
            </p>
            <app-test-report-links
              [isUserClientView]="isUserClientView"
              [links]="this.test.links"
              (changeEvent)="this.setLinks($event)"
            ></app-test-report-links>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-inclinometer
    [test]="test"
    [testPropertyPath]="testPropertyPath"
    [infoText]="infoText"
    [open]="inclinometerOpen"
    (onclose)="onInclinometerClose()"
    (goNextEvent)="onInclinometerNext()"
  ></app-inclinometer>
</div>
