import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appDragScroll]'
})
export class DragScrollDirective implements OnInit {
    pos = { top: 0, left: 0, x: 0, y: 0 };

    mouseMoveHandlerRef;
    mouseUpHandlerRef;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
        this.renderer.listen(this.el.nativeElement, 'mousedown', (e) => {
            this.mouseDownHandler(e);
        });
    }

    mouseDownHandler(e) {
        this.pos = {
            // The current scroll
            left: this.el.nativeElement.scrollLeft,
            top: this.el.nativeElement.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };

        this.mouseMoveHandlerRef = (e) => {
            this.mouseMoveHandler(e);
        };

        this.mouseUpHandlerRef = () => {
            this.mouseUpHandler();
        };

        document.addEventListener('mousemove', this.mouseMoveHandlerRef);
        document.addEventListener('mouseup', this.mouseUpHandlerRef);
    }

    mouseMoveHandler(e) {
        // How far the mouse has been moved
        const dx = e.clientX - this.pos.x;
        const dy = e.clientY - this.pos.y;

        // Scroll the element
        this.el.nativeElement.scrollTop = this.pos.top - dy;
        this.el.nativeElement.scrollLeft = this.pos.left - dx;
    }

    mouseUpHandler() {
        console.log('up=');
        document.removeEventListener('mousemove', this.mouseMoveHandlerRef);
        document.removeEventListener('mouseup', this.mouseUpHandlerRef);

        // this.renderer.setStyle(this.el, 'cursor', 'grab');
        // this.renderer.setStyle(this.el, 'cursor', 'grab');
        // this.el.nativeElement.style.cursor = 'grab';
        // this.el.nativeElement.style.removeProperty('user-select');
    }

}
