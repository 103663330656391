import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-rom-test-card',
    templateUrl: './rom-test-card.component.html',
    styleUrls: ['./rom-test-card.component.scss']
})
export class RomTestCardComponent {

    @Input()
    movementIds: string[];

    @Input()
    movementIdsR: string[];

    constructor() { }
}
