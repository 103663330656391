import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  totalReads = 0;
  totalWrites = 0;
  logOnProduction = true;
  shouldLog = false;

  constructor() {
      this.shouldLog = !environment.production || this.logOnProduction;
  }

  sendException(exception) {
    const result = Sentry.captureException(exception);
    if (this.shouldLog) {
      console.error(exception);
    }
    return result;
  }

  sendMessage(message) {
    Sentry.captureMessage(message, Sentry.Severity.Log);
  }

  logMessage(message) {
    if (this.shouldLog) {
      console.log(message);
    }
  }

  logError(message) {
    if (this.shouldLog) {
      console.error(message);
    }
  }

  logFirestoreRead(colName: string, count: number = 1) {
    this.totalReads = this.totalReads + count;
    this.logMessage(`FS READ/${colName} | Count: ${count} | Total reads: ${this.totalReads}`);
  }

  logFirestoreWrite(colName: string, count: number = 1) {
    this.totalWrites = this.totalWrites + count;
    this.logMessage(`FS WRITE/${colName} | Count: ${count} | Total writes: ${this.totalWrites}`);
  }

}
