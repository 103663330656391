import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  icon: string;

  @Input()
  text: string;

  @Input()
  disabled?: boolean;

  @Input()
  type: 'normal' | 'danger' | 'primary' = 'normal';

  @Input()
  buttonType: 'button' | 'submit' = 'button';

  @Input()
  onlyIconMobile = false;

  @Output()
  clickEvent = new EventEmitter();

  constructor() {}

  onClick(): void {
    this.clickEvent.next('');
  }
}
