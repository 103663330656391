import { Type } from '@angular/core';
// eslint-disable-next-line max-len
import { SagitalInclinometerLearnContainerComponent } from '../components/learn-containers/sagital-inclinometer-learn-container/sagital-inclinometer-learn-container.component';
import { VafLearnContainerComponent } from '../components/learn-containers/vaf-learn-container/vaf-learn-container.component';
// eslint-disable-next-line max-len
import {
  BipSagitalInclinometerLearnData,
  SedSagitalInclinometerLearnData,
  FlexSagitalInclinometerLearnData,
  MASagitalInclinometerLearnData,
  SagitalFlechasTestLearnData,
  TobilloRomLearnData as RomTobilloLearnData,
  FootDescripcionAnatomica,
  RaquisMorfotipo,
  RaquisFlechas,
  VAFPosture,
  VAFFoot,
  VAFFootAntepieRetropie,
  VAFFootArticulacionSubAstra,
  VAFFootArticulacionSupraAstra,
  VAFFootDescripcionAnatomica,
  VAFFootProtocoloHernandezCorvo,
  VAFFootValoracionArcoInterno,
  VAFROM,
  VAFROMCinturaEscapular,
  VAFROMCinturaPelvica,
  VAFROMTestsMiembroInferior,
  VAFROMTobillo,
  VAFPosturePlanoSagital,
  VAFPosturePlanoFrontal,
  VAFRecomendacionesEnlaces,
  ROM,
} from '../static/learn-data.static';
import { ClientSegmentsLevelSectionComponent } from '../client-segments-level-section/client-segments-level-section.component';

export class LearnSection {
  constructor(
    public component: Type<any>,
    public data: any,
  ) {}
}

const rootDomain = window.location.origin;

export const LearnSections = {
  'sagital-test-bipedestacion': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    BipSagitalInclinometerLearnData,
  ),
  'sagital-test-sedentacion': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    SedSagitalInclinometerLearnData,
  ),
  'sagital-test-flex': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    FlexSagitalInclinometerLearnData,
  ),
  'sagital-test-bipedestacion-ma': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    MASagitalInclinometerLearnData,
  ),
  'sagital-flechas-test': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    SagitalFlechasTestLearnData,
  ),
  // eslint-disable-next-line quote-props
  'rom-tobillo': new LearnSection(
    SagitalInclinometerLearnContainerComponent,
    RomTobilloLearnData,
  ),

  RaquisMorfotipo: new LearnSection(
    VafLearnContainerComponent,
    RaquisMorfotipo,
  ),
  RaquisFlechas: new LearnSection(VafLearnContainerComponent, RaquisFlechas),
  ROM: new LearnSection(VafLearnContainerComponent, ROM),
  VAFPosture: new LearnSection(VafLearnContainerComponent, VAFPosture),
  VAFRecomendacionesEnlaces: new LearnSection(
    VafLearnContainerComponent,
    VAFRecomendacionesEnlaces,
  ),
  VAFPostureFrontal: new LearnSection(
    VafLearnContainerComponent,
    VAFPosturePlanoFrontal,
  ),
  VAFPostureSagital: new LearnSection(
    VafLearnContainerComponent,
    VAFPosturePlanoSagital,
  ),
  VAFFoot: new LearnSection(VafLearnContainerComponent, VAFFoot),
  VAFFootDescripcionAnatomica: new LearnSection(
    VafLearnContainerComponent,
    VAFFootDescripcionAnatomica,
  ),
  VAFFootValoracionArcoInterno: new LearnSection(
    VafLearnContainerComponent,
    VAFFootValoracionArcoInterno,
  ),
  VAFFootAntepieRetropie: new LearnSection(
    VafLearnContainerComponent,
    VAFFootAntepieRetropie,
  ),
  VAFFootArticulacionSupraAstra: new LearnSection(
    VafLearnContainerComponent,
    VAFFootArticulacionSupraAstra,
  ),
  VAFFootArticulacionSubAstra: new LearnSection(
    VafLearnContainerComponent,
    VAFFootArticulacionSubAstra,
  ),
  VAFFootProtocoloHernandezCorvo: new LearnSection(
    VafLearnContainerComponent,
    VAFFootProtocoloHernandezCorvo,
  ),
  VAFROM: new LearnSection(VafLearnContainerComponent, VAFROM),
  VAFROMTobillo: new LearnSection(VafLearnContainerComponent, VAFROMTobillo),
  VAFROMCinturaPelvica: new LearnSection(
    VafLearnContainerComponent,
    VAFROMCinturaPelvica,
  ),
  VAFROMCinturaEscapular: new LearnSection(
    VafLearnContainerComponent,
    VAFROMCinturaEscapular,
  ),
  VAFROMTestsMiembroInferior: new LearnSection(
    VafLearnContainerComponent,
    VAFROMTestsMiembroInferior,
  ),

  'foot-descripcion-anatomica': new LearnSection(
    VafLearnContainerComponent,
    FootDescripcionAnatomica,
  ),

  superiorEmpujeLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Superior Empuje ',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/mmss-empuje.png',
  }),
  superiorTraccionLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Superior Tracción ',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/mmss-traccion.png',
  }),
  inferiorEmpujeLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Inferior Empuje ',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/mmii-empuje.png',
  }),
  inferiorTraccionLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Inferior Tracción ',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/mmii-traccion.png',
  }),
  coreLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Core',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/core.png',
  }),
  metabolicoLevel: new LearnSection(ClientSegmentsLevelSectionComponent, {
    title: 'Metabólico',
    imageUrl: rootDomain + '/assets/learn/resultados-nivel/metabolico.png',
  }),
};
