import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private nav: NavigationService,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const user = await this.auth.getUser();
    const loggedIn = !!user;

    if (!loggedIn) {
      const professionalId = next.queryParams['professionalId'];

      // Check if the path starts with "/c"
      if (next.url[0]?.path === 'c') {
        this.nav.clientNav.goToLogin({
          queryParams: {
            professionalId,
          },
        });
        return false;
      }

      this.nav.goToLogin();
      return false;
    }

    if (next.data?.roles && !next.data.roles.includes(user.role)) {
      // not allowed
      console.error('Acceso no permitido');
      // this.snackbarService.error('Acceso no permitido');
      if (user.role === 'client') {
        this.nav.clientNav.goToHome();
      } else {
        this.nav.goToHome();
      }
    }

    return loggedIn;
  }
}
