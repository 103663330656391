import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResponsiveSrc]'
})
export class ResponsiveSrcDirective implements OnInit {

  getExtensionRegEx = /(?:\.([^.]+))?$/;
  removeExtensionRegEx = /\.[^/.]+$/;

  src: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.src = this.el.nativeElement.getAttribute('src');
    if (this.src === null) {
      return;
    }
    const ext = this.getExtensionRegEx.exec(this.src)[1];
    const fileName = this.src.replace(this.removeExtensionRegEx, '');
    this.renderer.setAttribute(this.el.nativeElement, 'srcset', `${this.src} 1x, ${fileName}@2x.${ext} 2x`);
  }

}

