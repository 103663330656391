import { Component, OnInit, Input } from '@angular/core';
import { RomResult, RomResultLevel } from 'src/shared/rom-test.model';

@Component({
    selector: 'app-rom-result-tag',
    templateUrl: './rom-result-tag.component.html',
    styleUrls: ['./rom-result-tag.component.scss']
})
export class RomResultTagComponent {

    pRomResult: RomResult;

    @Input()
    set romResult(value: RomResult) {
        this.pRomResult = value;
    }

    get romResult() {
        return this.pRomResult;
    }

    constructor() { }

}
