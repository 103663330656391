import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytesResumable } from '@angular/fire/storage';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  percentage: BehaviorSubject<number>;
  isUploading = false;
  uploadTaskFinalized: Subject<void> = new Subject();
  constructor(private storage: Storage) {}

  async startUpload(userId: string, mediaItemId: string, file: File | any) {
    const path = `user-media/${userId}/${mediaItemId}`;
    const storageRef = ref(this.storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    this.percentage = new BehaviorSubject(0);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        this.percentage.next(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );
        this.isUploading = true;
      },
      (error) => {
        console.log('error uploading file', error);
        this.isUploading = false;
      },
      () => {
        console.log('file uploaded');
        this.isUploading = false;
        this.uploadTaskFinalized.next();
      },
    );
  }
}
