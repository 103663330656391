import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { AppName, AppConfig } from 'src/config/app.config';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { UserConfig } from 'src/app/models/user-config.model';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';
import { CustomBrandingService } from 'src/app/services/custom-branding.service';
import { LoadingState, sleep } from 'src/app/utils/utils';
import { User } from 'src/app/models/user.model';
import { UserClientService } from 'src/app/services/user-client.service';
import { CanIService } from 'src/app/services/can-i.service';
import { Crisp } from 'crisp-sdk-web';
import { Client } from 'src/app/models/client.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuOpen = false;
  show = false;
  subs = new Array<Subscription>();
  loadingState: LoadingState = 'loading';
  isClient = false;
  client$: Observable<Client>;
  questionnaireUrl: string;
  sideMenuOpen = false;
  inclinometerOpen = false;

  canUseExercises = false;

  appName: AppName = AppConfig.app;

  userConfig: UserConfig;

  currentBrand;

  professional: User;

  professionalConfig: UserConfig;

  professionalConnectLinks$: Observable<
    { key: string; icon: string; url: string }[]
  >;

  constructor(
    public auth: AuthService,
    private modalService: ModalService,
    private overrideBackButton: OverrideBackButtonService,
    private configService: UserConfigServiceService,
    private customBrandingService: CustomBrandingService,
    private router: Router,
    private userClientService: UserClientService,
    private canIService: CanIService,
  ) {}

  ngOnInit() {
    this.professionalConnectLinks$ =
      this.userClientService.professionalConnectLinks$;

    this.subs.push(
      combineLatest([
        this.auth.user$,
        this.userClientService.professional$,
        this.userClientService.professionalConfig$,
        this.configService.userConfig$,
        from(this.canIService.canViewTrainingSessions()),
      ]).subscribe(
        ([
          user,
          professional,
          professionalConfig,
          userConfig,
          canUseExercises,
        ]) => {
          this.show = !!user;
          this.isClient = user?.role === 'client';
          this.professional = professional;
          this.professionalConfig = professionalConfig;
          this.userConfig = userConfig;
          this.canUseExercises = canUseExercises;
          this.loadingState = 'idle';

          if (this.isClient) {
            this.client$ = this.userClientService.userClient$;
            this.client$.subscribe((client) => {
              if (client?.questionnaireUrl && client?.displayQuestionnaireUrl) {
                this.questionnaireUrl = client.questionnaireUrl;
              } else {
                this.questionnaireUrl = null;
              }
            });
          }
        },
      ),
    );

    this.currentBrand = this.customBrandingService.brand;

    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  openAddClientModal() {
    this.modalService.openModal('create-edit-client', {});
  }

  openExportTestsModal() {
    this.modalService.openModal('export-tests', {});
  }

  async navigateToMyProfile() {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate(['my-profile']);
  }

  async navigateToMyClients() {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate(['clients']);
  }

  async navigateToFromSideMenu(route: string) {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate([route]);
  }

  async openAccountModal() {
    this.closeSideMenu();
    await sleep(100);
    this.modalService.openModal('account', {});
  }

  // editReportsConfig() {
  //   this.modalService.openModal('edit-reports-config');
  // }

  openSideMenu() {
    this.sideMenuOpen = true;
    this.overrideBackButton.override(() => {
      this.sideMenuOpen = false;
    });
  }

  closeSideMenu() {
    history.back();
  }

  async openMultimedia() {
    this.closeSideMenu();
    await sleep(100);
    await this.modalService.openLateralModal('client-media', {
      onlyOriginals: false,
      selectionMode: false,
      selectionName: 'My VAF',
    });
  }

  async openExercises() {
    this.closeSideMenu();
    await sleep(100);
    const result = await this.modalService.openLateralModal('exercises', {});
  }

  openCrispSupport() {
    Crisp.chat.show();
    Crisp.chat.open();
  }

  openInclinometer() {
    this.inclinometerOpen = true;
  }

  onInclinometerClose() {
    this.inclinometerOpen = false;
  }

  reload() {
    location.reload();
  }

  async logout() {
    this.closeSideMenu();
    this.auth.signOut();
  }
}
