import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, lastValueFrom, take } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import {
  MyVafSection,
  MyVafSectionItem,
} from 'src/app/models/my-vaf-section.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { MyvafService } from 'src/app/services/myvaf.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TestService } from 'src/app/services/test.service';
import {
  getProtocolInitials,
  MyVafProtocolDef,
  MyVafTestDef,
} from 'src/shared/myvaf.outside';

@Component({
  selector: 'app-my-vaf-menu',
  templateUrl: './my-vaf-menu.component.html',
  styleUrls: ['./my-vaf-menu.component.scss'],
})
export class MyVafMenuComponent implements OnInit {
  constructor(
    private myVafService: MyvafService,
    private auth: AuthService,
    private router: Router,
    private testsService: TestService,
    private clientService: ClientsService,
    private snackbarService: SnackbarService,
    private nav: NavigationService,
  ) {}

  @Input() isOpen = false;

  @Output() createTestEvent = new EventEmitter<MyVafProtocolDef>();

  protocols: Array<MyVafProtocolDef> = [];
  protocols$: Observable<Array<MyVafProtocolDef>>;
  protocolsWithoutSection: Array<MyVafProtocolDef> = [];

  sections: MyVafSection;

  getProtocolInitials = getProtocolInitials;

  selectedProtocolToMove = '';
  moveProtocolDialogOpen = false;

  editSectionNameSection: MyVafSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = 'asdg';

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;

  sharedUrl: string;

  creatingMyVafTestFromUrl = false;
  currentClient: Client = null;

  filterProtocols;

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const user = await this.auth.getUser();

    this.clientService.currentClient$.subscribe((client) => {
      this.currentClient = client;
    });

    this.protocols$ = await this.myVafService.getProtocols$(user.uid);

    this.sections = await this.myVafService.getSectionsOrCreateSections(
      user.uid,
    );

    this.protocols = await lastValueFrom(this.protocols$.pipe(take(1)));

    this.protocols$.subscribe((protocols) => {
      this.protocols = protocols;
      this.updateProtocolsWihtoutSection();
    });

    this.clearnSections();
    this.updateProtocolsWihtoutSection();
  }

  clearnSections() {
    this.sections.sections.forEach(
      (s) =>
        (s.protocolIds = s.protocolIds.filter(
          (p) => !!this.getProtocolById(p),
        )),
    );
  }

  updateProtocolsWihtoutSection() {
    if (!this.sections?.sections) {
      this.protocolsWithoutSection = this.protocols;
    }
    this.protocolsWithoutSection = this.protocols.filter(
      (p) =>
        !this.sections.sections.find(
          (item) => !!item.protocolIds.find((id) => id === p.id),
        ),
    );
  }

  close() {
    history.back();
  }

  async createProtocol() {
    const user = await this.auth.getUser();
    const protocolId = await this.myVafService.createProtocol(user.uid);
    this.router.navigate(['myvaf', protocolId]);
    console.log('protocolId', protocolId);
  }

  getProtocolById(id: string) {
    if (!this.protocols) {
      return undefined;
    }
    const result = this.protocols.find((p) => p.id === id);
    return result;
  }

  editProtocol(protocol: MyVafProtocolDef, event) {
    event.stopPropagation();
    this.router.navigate(['myvaf', protocol.id]);
  }

  createProtocolTest(protocol: MyVafProtocolDef) {
    this.createTestEvent.next(protocol);
  }

  addSection() {
    if (!this.sections) {
      return;
    }
    if (!this.sections.sections) {
      this.sections.sections = [];
    }
    this.sections.sections.push({ name: 'Sección', protocolIds: [] });
    this.updateSections();
  }

  moveSectionUp(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === 0) {
      return;
    }
    const hold = this.sections.sections[sectionIndex - 1];
    this.sections.sections[sectionIndex - 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveSectionDown(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === this.sections.sections.length - 1) {
      return;
    }
    const hold = this.sections.sections[sectionIndex + 1];
    this.sections.sections[sectionIndex + 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveProtocolUp(sectionIndex: number, protocolIndex: number, event) {
    event.stopPropagation();
    const section = this.sections.sections[sectionIndex].protocolIds;
    console.log(section);

    if (protocolIndex === 0) {
      return;
    }
    const hold = section[protocolIndex - 1];
    section[protocolIndex - 1] = section[protocolIndex];
    section[protocolIndex] = hold;
    this.updateSections();
  }

  moveProtocolDown(sectionIndex: number, protocolIndex: number, event) {
    console.log(
      'sectionIndex: ',
      sectionIndex,
      ' protocolIndex: ',
      protocolIndex,
    );
    event.stopPropagation();
    const section = this.sections.sections[sectionIndex].protocolIds;
    if (protocolIndex === section.length - 1) {
      return;
    }
    const hold = section[protocolIndex + 1];
    section[protocolIndex + 1] = section[protocolIndex];
    section[protocolIndex] = hold;
    this.updateSections();
  }

  openMoveProtocolDialog(protocol: MyVafProtocolDef, event) {
    event.stopPropagation();
    if (
      !this.sections ||
      !this.sections.sections ||
      this.sections.sections.length === 0
    ) {
      return;
    }
    this.selectedProtocolToMove = protocol.id;
    this.moveProtocolDialogOpen = true;
  }

  moveToSection(section: MyVafSectionItem) {
    this.sections.sections.forEach((s) => {
      s.protocolIds = s.protocolIds.filter(
        (id) => id !== this.selectedProtocolToMove,
      );
    });
    section.protocolIds.push(this.selectedProtocolToMove);
    this.moveProtocolDialogOpen = false;
    this.updateSections();
  }

  closeMoveToSection() {
    this.moveProtocolDialogOpen = false;
  }

  updateSections() {
    this.updateProtocolsWihtoutSection();
    this.myVafService.updateSections(this.sections);
  }

  openRenameSection(section: MyVafSectionItem, event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  deleteSection(index: number) {
    this.sections.sections.splice(index, 1);
    this.updateSections();
  }

  async createMyVafTestFromSharedUrl() {
    this.creatingMyVafTestFromUrl = true;
    const testId = await this.testsService.createMyVafTestFromSharedUrl(
      this.sharedUrl,
      this.currentClient,
    );
    if (!testId) {
      this.snackbarService.error('No se pudo crear el test');
      return;
    }
    this.nav.goToTest(testId);
    this.creatingMyVafTestFromUrl = false;
  }
}
