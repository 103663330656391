import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit, OnDestroy {

  sub: Subscription;

  open: boolean;
  title: string;
  description: string;
  confirmButton: string;
  confirmCallback: (boolean) => void;

  constructor(public service: ConfirmActionDialogService) {
    
  }

  ngOnInit() {
    this.sub = this.service.onOpenCloseDialog.subscribe(event => {
      this.open = event !== undefined;
      if(this.open){
        this.title = event.title;
        this.description = event.description;
        this.confirmButton = event.confirmButton ? event.confirmButton : 'Eliminar'
        this.confirmCallback = event.confirmCallback;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeDialog(confirm: boolean){
    this.open = false;
    this.confirmCallback(confirm);
  }

}
