import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Tag, NoTag, UNSAVED_TAG_ID, Tags, NO_TAG_ID } from 'src/app/models/tags.model';
import { init } from '@sentry/browser';
import { TagsService } from 'src/app/services/tags.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';

@Component({
    selector: 'app-tag-selector',
    templateUrl: './tag-selector.component.html',
    styleUrls: ['./tag-selector.component.scss']
})
export class TagSelectorComponent implements OnInit, OnDestroy {

    @Input()
    selectedTagId: number;

    @Output() selectedTagChange = new EventEmitter<number>();

    private searchInputRef: ElementRef;

    @ViewChild('searchInput') set setSearchInputRef(content: ElementRef) {
        this.searchInputRef = content;
        if (this.shouldSelect && !!this.searchInputRef) {
            this.searchInputRef.nativeElement.focus();
            this.shouldSelect = false;
        }

    }

    constructor(private tagsService: TagsService, private confirm: ConfirmActionDialogService) { }

    editMode = false;

    UnsavedTagId = UNSAVED_TAG_ID;

    tags: Array<Tag> = [];

    selectedTag: Tag;

    filteredTags: Array<Tag> = [];

    subs: Array<Subscription> = [];

    lastSearch = '';

    inputValue = '';

    shouldSelect = false;

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    async init() {
        this.subs.push(this.tagsService.getTags$().pipe(tap(tags =>  {
            this.tags = tags.tags;
            this.selectedTag = this.getSelectedTag(this.selectedTagId, tags);
            this.onSearch(this.lastSearch);
        })).subscribe());
        // this.tags = (await this.tagsService.getTags()).tags;
        // this.filteredTags = this.tags;
    }

    open() {
        this.editMode = true;
        this.inputValue = '';
        this.shouldSelect = true;
        this.onSearch(this.inputValue);
    }

    close() {
        this.editMode = false;
    }

    toggle() {
        if (this.editMode) {
            this.close();
        } else {
            this.open();
        }
    }

    getSelectedTag(tagId: number, tags: Tags): Tag {
        return this.tagsService.getTag(tagId, tags);
    }
    onSearch(value: string) {
        this.lastSearch = value;
        const filteredTags = this.findTags(value, this.tags);
        this.filteredTags = filteredTags;
        if (value !== '' && !this.containsExact(value, this.filteredTags)) {
            this.filteredTags.push(this.getNewTag(value));
        }
    }

    getNewTag(name: string): Tag {
        return {
            id: UNSAVED_TAG_ID,
            name
        };
    }

    findTags(value: string, array: Array<{id: number, name: string}>) {
        let regExString = '(';
        const valueChars = value.split('');
        for (let index = 0; index < valueChars.length; index++) {
            const element = valueChars[index];
            regExString = regExString + element;
            if (index < valueChars.length - 1) {
                regExString = regExString + '.*';
            }
        }
        regExString = regExString + ')';
        const regEx = new RegExp(regExString, 'gi');
        const result  = array.filter(tag => {
            const searchResult = tag.name.search(regEx);
            return searchResult >= 0;
        });
        return result;
    }

    containsExact(value: string, array: Array<{id: number, name: string}>) {
        return array.some( tag => {
            const result = tag.name.search(new RegExp(`(^${value}$)`, 'gi')) >= 0;
            return result;
        });
    }

    async selectTag(tag: Tag) {
        if (tag.id === UNSAVED_TAG_ID) {
            await this.tagsService.addNewTag(tag);
        }
        this.selectedTag = tag;
        this.selectedTagChange.next(this.selectedTag.id);
        this.close();
    }

    setNoTag() {
        this.selectTag(NoTag);
    }

    async deleteTag(tag: Tag) {
        this.confirm.openDialog({
            title: `Vamos a borrar el grupo: ${tag.name}`,
            description: `Todos los clientes que pertenezcan a este grupo pasarán a estar sin grupo.`,
            confirmButton: 'Borrar grupo',
            confirmCallback: (confirm) => {
                if (confirm) {
                    this.tagsService.deleteTag(tag);
                }
            }
        });
    }

    onKeydown(event) {
        if (event.key === 'Enter') {
            if (this.filteredTags.length > 0) {
                this.selectTag(this.filteredTags[0]);
            }
        }
    }

}
