import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type NewDropdownOption = {
  label: string;
  value: string;
};

@Component({
  selector: 'app-new-dropdown',
  templateUrl: './new-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewDropdownComponent),
      multi: true,
    },
  ],
})
export class NewDropdownComponent implements ControlValueAccessor {
  @Input() options: NewDropdownOption[] = [];
  @Input() multiple: boolean = false;
  @Output() optionSelected = new EventEmitter<string | string[]>();

  isOpen = false;
  selectedOptions: string[] = [];
  selectedOption: NewDropdownOption | null = null;

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input()
  buttonClass: string = '';
  @Input()
  wrapperClass: string = '';

  private _readonly: boolean = false;
  private _disabled: boolean = false;

  @Input()
  set readonly(value: boolean | '') {
    this._readonly = value === '' || value === true;
  }
  get readonly(): boolean {
    return this._readonly || this._disabled;
  }

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleDropdown() {
    if (this.readonly) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  isSelected(value: string): boolean {
    return this.multiple
      ? this.selectedOptions.includes(value)
      : this.selectedOption?.value === value;
  }

  toggleOption(value: string) {
    if (this.multiple) {
      const index = this.selectedOptions.indexOf(value);
      if (index === -1) {
        this.selectedOptions.push(value);
      } else {
        this.selectedOptions.splice(index, 1);
      }
      this.onChange(this.selectedOptions);
      this.optionSelected.emit(this.selectedOptions);
    } else {
      this.selectedOption = this.options.find((o) => o.value === value) || null;
      this.onChange(value);
      this.optionSelected.emit(value);
      this.isOpen = false;
    }
    this.onTouched();
  }

  writeValue(value: string | string[]): void {
    if (this.multiple) {
      this.selectedOptions = Array.isArray(value) ? value : [];
    } else {
      this.selectedOption = this.options.find((o) => o.value === value) || null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  getDisplayText(): string {
    if (this.multiple) {
      if (this.selectedOptions.length === 0) return 'Seleccionar...';
      return this.selectedOptions
        .map((value) => this.options.find((o) => o.value === value)?.label)
        .filter(Boolean)
        .join(', ');
    }
    return this.selectedOption?.label || 'Seleccionar...';
  }
}
