<div class="h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <div *ngIf="client$ | async as client">
        <div class="client-data__heading">
          <!-- <h2 class="section-heading">Datos del cliente</h2> -->
          <!-- <button (click)="test()">button</button> -->
        </div>
        <div class="content">
          <div class="mb-12 rounded-2xl bg-white px-8 py-4 shadow-lg">
            <div class="flex flex-col justify-between md:flex-row">
              <div
                class="flex flex-shrink items-center justify-center md:flex-grow"
              >
                <app-back-button classes="text-xl flex-shrink-0" />
                <div
                  class="client-data__img element-client-img mr-5 hidden flex-shrink-0 md:flex"
                >
                  {{ clientsService.getThumbnailText(client) }}
                </div>
                <button
                  (click)="
                    openLinkClientModal(client); $event.stopPropagation()
                  "
                  class="mr-2 flex h-5 w-5 items-center justify-center rounded-full p-0.5 text-white"
                  [class.bg-green-500]="client.isLinked"
                  [class.bg-red-500]="!client.isLinked"
                  [matTooltip]="
                    client.isLinked
                      ? 'Cliente vinculado a usuario'
                      : 'Cliente no vinculado a usuario'
                  "
                >
                  <i *ngIf="client.isLinked" class="fas fa-user text-xs"></i>
                  <i *ngIf="!client.isLinked" class="fas fa-user text-xs"></i>
                </button>
                <button
                  (click)="isActionsExpanded = !isActionsExpanded"
                  class="client-data__name flex min-h-[44px] flex-wrap items-center justify-center text-center md:grow md:justify-start"
                >
                  <span class="client-data__name__name">{{ client.name }}</span>
                  <span class="client-data__name__surname">{{
                    client.surname
                  }}</span>
                </button>
              </div>
              <div
                class="flex cursor-pointer items-center justify-center"
                (click)="isActionsExpanded = !isActionsExpanded"
              >
                <i
                  class="fas fa-chevron-down text-lg text-intecc transition-transform"
                  [class.rotate-180]="isActionsExpanded"
                ></i>
                <!-- <span class="ml-2">Más</span> -->
              </div>
            </div>
            <div
              class="mt-4 flex-shrink-0 items-center gap-1 space-y-2 transition-all duration-300 md:grid md:grid-cols-3 md:gap-2 md:space-y-0"
              [class.hidden]="!isActionsExpanded"
              [class.flex-wrap]="!isActionsExpanded"
              [class.justify-around]="!isActionsExpanded"
              [class.md:justify-center]="!isActionsExpanded"
              [class.md:gap-3]="!isActionsExpanded"
              [class.md:hidden]="!isActionsExpanded"
            >
              <button
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                (click)="editClient({ client: client })"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <i class="fas fa-pen"></i>
                  <p class="place-self-start">Datos</p>
                </div>
              </button>

              <a
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                [routerLink]="['/client', client.id, 'questionnaire']"
                *ngIf="currentApp === 'vaf'"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <i class="fas fa-clipboard-list"></i>
                  <p class="place-self-start">Cuestionario</p>
                </div>
              </a>

              <a
                *ngIf="currentApp === 'vaf'"
                href="{{ client.sharedFolderUrl }}"
                target="_blank"
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                [class.pointer-events-none]="!client.sharedFolderUrl"
                [class.cursor-not-allowed]="!client.sharedFolderUrl"
              >
                <div
                  class="grid grid-cols-[40px_100px] place-items-center"
                  [class.opacity-50]="!client.sharedFolderUrl"
                >
                  <i class="fas fa-file"></i>
                  <p class="place-self-start">Documentos</p>
                </div>
              </a>

              <button
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                *ngIf="currentApp === 'vaf'"
                (click)="openSideModal('client-media')"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <i class="fas fa-file-image"></i>
                  <p class="place-self-start">Multimedia</p>
                </div>
              </button>

              <button
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                [class.!cursor-not-allowed]="true"
                [class.pointer-events-none]="true"
                *ngIf="currentApp === 'vaf'"
              >
                <div
                  class="grid grid-cols-[40px_100px] place-items-center"
                  [class.opacity-50]="true"
                >
                  <i class="fas fa-calendar"></i>
                  <p class="place-self-start">Calendario</p>
                </div>
              </button>

              <button
                class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
                [class.!cursor-not-allowed]="true"
                [class.pointer-events-none]="true"
                *ngIf="currentApp === 'vaf'"
              >
                <div
                  class="grid grid-cols-[40px_100px] place-items-center"
                  [class.opacity-50]="true"
                >
                  <i class="fas fa-chart-line"></i>
                  <p class="place-self-start">Métricas</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <!-- sección de "Valoraciones" -->
        <div class="content mb-4">
          <div class="flex items-center text-2xl">
            <i class="fa-solid fa-bullseye mr-3 text-xl text-intecc2"></i>
            <p class="">Valoraciones</p>
            <a
              href="https://manual.intecc.org/es/article/valoraciones"
              target="_blank"
              rel="noopener noreferrer"
              class="ml-auto cursor-pointer text-xl"
            >
              <i class="fas fa-graduation-cap text-intecc2"></i>
            </a>
          </div>
          <p *ngIf="currentApp === 'vaf'" class="text-lg text-neutral-400">
            1. Estado anatómico
          </p>
          <p *ngIf="currentApp === 'raquis'" class="text-lg text-neutral-400">
            Valoración de la columna
          </p>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="mt-3 md:hidden"></div>
          <button
            *ngIf="currentApp === 'raquis'"
            (click)="createTest('sagital')"
            class="new-button-regular-with-icon new-button-regular-with-icon--full-width-mobile shadow-sm"
          >
            <div class="new-button-regular-with-icon__icon">
              <i class="fa-solid fa-plus"></i>
            </div>
            <div class="new-button-regular-with-icon__text">
              Nueva valoración
            </div>
          </button>
        </div>
        <div class="content mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
          <button
            *ngIf="currentApp === 'vaf'"
            (click)="canViewTests ? openNewValorationMenu() : null"
            class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
            [class.cursor-not-allowed]="!canViewTests"
            [class.opacity-50]="!canViewTests"
            [disabled]="!canViewTests"
          >
            <i class="fa-solid fa-plus"></i>
            <div class="ml-4 font-bold">Nueva valoración</div>
          </button>
          <button
            *ngIf="currentApp === 'vaf'"
            (click)="openAvatarModal()"
            class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black shadow-sm"
          >
            <i class="fas fa-user"></i>
            <div class="ml-4 font-bold">Avatar de valoración</div>
          </button>
        </div>
        <div class="mb-8 h-[612px]">
          <app-avatar
            [isModal]="false"
            [openAvatarSideModal]="openAvatarModal.bind(this)"
          ></app-avatar>
        </div>
        <div class="content">
          <app-items-list
            primaryColorClass="primary-2"
            secondaryColorClass="primary-9"
            [itemsObservable$]="clientTestsList$"
            [canUseFeature]="canViewTests"
            featureNotAvailableText="Empieza tu prueba gratuita para acceder a las valoraciones"
            [handleGenerateReport]="generateReportForValorations"
            [handleDeleteItems]="deleteValorations"
            [handleShowToClient]="handleShowToClientTests"
          ></app-items-list>
        </div>
        <!-- Fin sección de "Valoraciones" -->

        <!-- Inicio sección de "Entrenamientos" -->
        <div *ngIf="currentApp === 'vaf'" class="content">
          <div class="flex items-center text-2xl">
            <i class="fa-regular fa-life-ring mr-3 text-xl text-intecc2"></i>
            <p class="">Correctivos</p>
            <a
              href="https://manual.intecc.org/es/article/ejercicios-correctivos"
              target="_blank"
              rel="noopener noreferrer"
              class="ml-auto cursor-pointer text-xl"
            >
              <i class="fas fa-graduation-cap text-intecc2"></i>
            </a>
          </div>
          <div id="corrective-sessions-section">
            <p class="mb-2 text-lg text-neutral-400">2. Ejercicios adaptados</p>
            <div *ngIf="canUseMyVaf" class="">
              <div class="mt-3 md:hidden"></div>
              <div class="mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
                <button
                  (click)="openCorrectiveSessionsMenu()"
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-plus"></i>
                  <div class="ml-4 font-bold">Nuevos Ejercicios</div>
                </button>
                <button
                  (click)="
                    newCorrectiveSessionFromUrlOpen =
                      !newCorrectiveSessionFromUrlOpen
                  "
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-upload"></i>
                  <div class="ml-4 font-bold">Nuevo Enlace</div>
                </button>
                <!-- TODO: change bellow click handler -->
                <button
                  (click)="openCorrectiveSessionsMenu()"
                  class="text mx-auto mb-8 flex w-full max-w-md cursor-not-allowed items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black opacity-50 shadow-sm"
                  disabled
                >
                  <i class="fas fa-wand-magic-sparkles"></i>
                  <div class="ml-4 font-bold">IA Correctivos</div>
                </button>
              </div>
            </div>
            <div *ngIf="newCorrectiveSessionFromUrlOpen" class="my-5">
              <p class="mb-2">
                Crear sesión desde url
                <i
                  class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                  matTooltip="Pega aquí el enlace para crear la sesión de ejercicios correctivos"
                ></i>
              </p>
              <div class="flex w-full items-center space-x-2">
                <div class="flex-grow">
                  <app-input
                    class="grow"
                    [(value)]="correctiveSessionSharedUrl"
                    fieldName=""
                  ></app-input>
                </div>
                <app-two-state-button
                  (buttonClick)="createClientCorrectiveSessionFromSharedUrl()"
                  [loading]="creatingCorrectiveSessionFromUrl"
                  [text]="'Crear'"
                  [loadingText]="'Creando...'"
                  [faIconCode]="'far fa-save'"
                ></app-two-state-button>
              </div>
            </div>

            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="clientCorrectiveSessionsList$"
              [canUseFeature]="canViewCorrectiveSessions"
              featureNotAvailableText="Empieza tu prueba gratuita para acceder a los ejercicios correctivos"
              [handleGenerateReport]="generateReportClientCorrectiveSessions"
              [handleDeleteItems]="deleteClientCorrectiveSessions"
              [handleShowToClient]="handleShowToClientCorrectiveSessions"
            ></app-items-list>
          </div>
          <!-- Inicio sección de Nivel Físico-->
          <div id="training-level-section" class="mb-20">
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-chart-simple mr-4 text-xl text-intecc2"></i>
              <p class="">Nivel Físico</p>
              <a
                href="https://manual.intecc.org/es/article/avatar-de-entrenamiento"
                target="_blank"
                rel="noopener noreferrer"
                class="ml-auto cursor-pointer text-xl"
              >
                <i class="fas fa-graduation-cap text-intecc2"></i>
              </a>
            </div>
            <p class="mb-2 text-lg text-neutral-400">
              3. Nivel de entrenamiento
            </p>
            <div class="mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
              <div
                *ngIf="currentApp === 'vaf'"
                class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
              >
                <p class="text-lg font-bold">
                  <!-- {{ clientLevelCategory }} -->
                  Nivel actual:
                </p>
                <span class="ml-2">
                  {{ clientLevel ?? '-' }}
                </span>
              </div>
              <button
                *ngIf="currentApp === 'vaf'"
                (click)="openTrainingAvatarModal()"
                class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black shadow-sm"
              >
                <i class="fas fa-user"></i>
                <div class="ml-4 font-bold">Avatar de entrenamiento</div>
              </button>
            </div>
            <div class="h-[612px]">
              <app-training-avatar
                [isModal]="false"
                [openTrainingAvatarSideModal]="
                  openTrainingAvatarModal.bind(this)
                "
              ></app-training-avatar>
            </div>
            <!-- TODO: on click function for list items -->
            <!-- <div class="mt-8">
                <app-items-list
                  primaryColorClass="primary-2"
                  secondaryColorClass="primary-9"
                  [itemsObservable$]="trainingAvatarList$"
                  [canUseFeature]="canViewTests"
                  featureNotAvailableText="Empieza tu prueba gratuita para acceder a las valoraciones"
                  [handleGenerateReport]="generateReportTrainingAvatars"
                  [handleDeleteItems]="deleteTrainingAvatars"
                  [handleShowToClient]="handleShowToClientTrainingAvatars"
                ></app-items-list>
              </div> -->
          </div>
          <!-- Inicio sección de Sesiones de entrenamiento-->
          <div id="training-sessions-section">
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-dumbbell mr-3 text-xl text-intecc2"></i>
              <p class="">Entrenamiento</p>
              <a
                href="https://manual.intecc.org/es/article/sesiones-de-entrenamiento"
                target="_blank"
                rel="noopener noreferrer"
                class="ml-auto cursor-pointer text-xl"
              >
                <i class="fas fa-graduation-cap text-intecc2"></i>
              </a>
            </div>
            <p class="mb-2 text-lg text-neutral-400">
              4. Sesiones de entrenamiento
            </p>
            <div *ngIf="canUseMyVaf" class="">
              <div class="mt-3 md:hidden"></div>
              <div class="mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
                <button
                  (click)="openTrainingSessionsMenu()"
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-plus"></i>
                  <div class="ml-4 font-bold">Nueva Sesión</div>
                </button>
                <button
                  (click)="
                    newTrainingSessionFromUrlOpen =
                      !newTrainingSessionFromUrlOpen
                  "
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-upload"></i>
                  <div class="ml-4 font-bold">Nuevo Enlace</div>
                </button>
                <!-- TODO: change bellow click handler -->
                <button
                  (click)="openTrainingSessionsMenu()"
                  class="text mx-auto mb-8 flex w-full max-w-md cursor-not-allowed items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black opacity-50 shadow-sm"
                  disabled
                >
                  <i class="fas fa-wand-magic-sparkles"></i>
                  <div class="ml-4 font-bold">IA Entrenamiento</div>
                </button>
              </div>
            </div>
            <div *ngIf="newTrainingSessionFromUrlOpen" class="my-5">
              <p class="mb-2">
                Crear sesión desde url
                <i
                  class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                  matTooltip="Pega aquí el enlace para crear la sesión de entrenamiento"
                ></i>
              </p>
              <div class="flex w-full items-center space-x-2">
                <div class="flex-grow">
                  <app-input
                    class="grow"
                    [(value)]="trainingSessionSharedUrl"
                    fieldName=""
                  ></app-input>
                </div>
                <app-two-state-button
                  (buttonClick)="createClientTrainingSessionFromSharedUrl()"
                  [loading]="creatingTrainingSessionFromUrl"
                  [text]="'Crear'"
                  [loadingText]="'Creando...'"
                  [faIconCode]="'far fa-save'"
                ></app-two-state-button>
              </div>
            </div>
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="clientTrainingSessionsList$"
              [canUseFeature]="canViewTrainingSessions"
              featureNotAvailableText="Empieza tu prueba gratuita para acceder a los entrenamientos"
              [handleGenerateReport]="generateReportClientTrainingSessions"
              [handleDeleteItems]="deleteClientTrainingSessions"
              [handleShowToClient]="handleShowToClientTrainingSessions"
            ></app-items-list>
          </div>
        </div>

        <!-- Inicio sección de "Protocolos my-vaf" -->
        <div class="content">
          <div *ngIf="currentApp === 'vaf'">
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-list-check mr-3 text-xl text-intecc2"></i>
              <p class="">Protocolos</p>
              <a
                href="https://manual.intecc.org/es/article/la-magia-de-vaf"
                target="_blank"
                rel="noopener noreferrer"
                class="ml-auto cursor-pointer text-xl"
              >
                <i class="fas fa-graduation-cap text-intecc2"></i>
              </a>
            </div>
            <p class="mb-2 text-lg text-neutral-400">5. Test Personalizados</p>
            <div *ngIf="canUseMyVaf">
              <div class="mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
                <button
                  (click)="openMyVafMenu()"
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-plus"></i>
                  <div class="ml-4 font-bold">Nuevo Protocolo</div>
                </button>
                <button
                  (click)="newProtocolFromUrlOpen = !newProtocolFromUrlOpen"
                  class="text mx-auto flex w-full max-w-md items-center justify-center rounded-lg bg-white px-4 py-3 font-semibold text-black shadow-sm"
                >
                  <i class="fa-solid fa-upload"></i>
                  <div class="ml-4 font-bold">Nuevo Enlace</div>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="newProtocolFromUrlOpen" class="my-5">
            <p class="mb-2">
              Crear protocolo desde url
              <i
                class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                matTooltip="Pega aquí el enlace para crear el protocolo"
              ></i>
            </p>
            <div class="flex w-full items-center space-x-2">
              <div class="flex-grow">
                <app-input
                  class="grow"
                  [(value)]="protocolSharedUrl"
                  fieldName=""
                ></app-input>
              </div>
              <app-two-state-button
                (buttonClick)="createClientProtocolFromSharedUrl()"
                [loading]="creatingProtocolFromUrl"
                [text]="'Crear'"
                [loadingText]="'Creando...'"
                [faIconCode]="'far fa-save'"
              ></app-two-state-button>
            </div>
          </div>
          <div class="pb-12">
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="myVafTestsList$"
              [canUseFeature]="canUseMyVaf"
              featureNotAvailableText="Crea tus tests personalizados con VAF Premium"
              [handleGenerateReport]="generateReportMyVaf"
              [handleDeleteItems]="deleteValorationsMyVaf"
              [handleShowToClient]="handleShowToClientMyVafTests"
            ></app-items-list>
          </div>
        </div>
      </div>

      <!-- menu de valoraciones (raquis, posture, rom-avatar) -->
      <div
        *ngIf="currentApp !== 'vaf'; else vaf"
        class="new-valoration-menu"
        [class.new-valoration-menu--open]="newValorationMenuOpen"
      >
        <h2 class="new-valoration-menu__heading">
          ¿Qué valoración quieres realizar?
        </h2>
        <div class="new-valoration-menu__back">
          <button (click)="closeNewValorationMenu()" class="button-plain-3">
            <i class="fas fa-times-circle"></i><span>Cerrar</span>
          </button>
        </div>
        <div class="new-valoration-menu__section">
          <div class="new-valoration-menu__intro">
            <h2 class="new-valoration-menu__title">
              Morfotipo Sagital Integral
            </h2>
            <p class="new-valoration-menu__description">
              Nuevo método diagnóstico para valorar las desalineaciones
              sagitales de la columna vertebral. Analiza la disposición de las
              curvas dorsal y lumbar en al menos dos posturas: de pie
              (bipedestación) y sentado (sedentación asténica) o en flexión
              máxima del tronco.
            </p>
          </div>

          <div
            (click)="createTest('sagital')"
            class="new-valoration-menu__card"
          >
            <div
              class="new-valoration-menu__card-overlay"
              [class.new-valoration-menu__card-overlay--visible]="addingNewTest"
            >
              <div class="spinner"></div>
            </div>
            <div class="new-valoration-menu__card-icon">
              <i class="fas fa-drafting-compass"></i>
            </div>
            <div class="new-valoration-menu__card-info">
              <h3 class="new-valoration-menu__card-title">Inclinómetro</h3>
              <p class="new-valoration-menu__card-description">
                Valoración sagital con <b>inclinómetro</b> o <b>smartphone</b>.
              </p>
            </div>
            <!-- <div class="new-valoration-menu__card-action"><i class="fas fa-chevron-circle-right"></i></div> -->
          </div>

          <div
            (click)="createTest('sagital-flechas')"
            class="new-valoration-menu__card"
          >
            <div
              class="new-valoration-menu__card-overlay"
              [class.new-valoration-menu__card-overlay--visible]="addingNewTest"
            >
              <div class="spinner"></div>
            </div>
            <div class="new-valoration-menu__card-icon">
              <i class="fas fa-compress-arrows-alt"></i>
            </div>
            <div class="new-valoration-menu__card-info">
              <h3 class="new-valoration-menu__card-title">Flechas Sagitales</h3>
              <p class="new-valoration-menu__card-description">
                Valoración sagital con <b>plomada</b> y <b>regla</b>.
              </p>
            </div>
            <!-- <div class="new-valoration-menu__card-action"><i class="fas fa-chevron-circle-right"></i></div> -->
          </div>
          <div
            (click)="createTest('sagital-img')"
            class="new-valoration-menu__card"
          >
            <div
              class="new-valoration-menu__card-overlay"
              [class.new-valoration-menu__card-overlay--visible]="addingNewTest"
            >
              <div class="spinner"></div>
            </div>
            <div class="new-valoration-menu__card-icon">
              <i class="far fa-images"></i>
            </div>
            <div class="new-valoration-menu__card-info">
              <h3 class="new-valoration-menu__card-title">Imágenes</h3>
              <p class="new-valoration-menu__card-description">
                Galería de imágenes con <b>casos reales</b>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ng-template #vaf>
        <app-new-valoration-menu
          (createTestEvent)="createTest($event)"
        ></app-new-valoration-menu>
      </ng-template>

      <!-- protocolo my vaf -->
      <app-my-vaf-menu
        (createTestEvent)="createMyVafTest($event)"
      ></app-my-vaf-menu>

      <!-- protocolo sesiones de entrenamiento -->
      <app-training-sessions-menu
        (createClientTrainingSessionEvent)="createClientTrainingSession($event)"
      ></app-training-sessions-menu>

      <!-- protocolo ejercicios correctivos -->
      <app-corrective-sessions-menu
        (createClientCorrectiveSessionEvent)="
          createClientCorrectiveSession($event)
        "
      ></app-corrective-sessions-menu>
    </ng-container>
  </ng-container>
</div>
