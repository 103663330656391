import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { FilterSearchValue } from 'src/app/models/filter-search-value.model';
import { ModalService } from 'src/app/services/modal.service';
import { FilterSearchModalResult } from 'src/app/modals/filter-search-modal/filter-search-modal.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent {
  searchInputText = '';

  filterGroupId = undefined;

  filterConditions$ = new BehaviorSubject<FilterSearchValue>({});

  createdAtSortDirection$ = new BehaviorSubject<'asc' | 'desc' | null>('desc');

  nameSortDirection$ = new BehaviorSubject<'asc' | 'desc' | null>(null);

  filterModalResult: FilterSearchModalResult = undefined;

  constructor(private modals: ModalService) {}

  searchInputTextChange(event) {
    this.searchInputText = event;
    this.updateFilterConditions();
  }

  updateFilterConditions() {
    const result: FilterSearchValue = {};
    result.searchInputText = this.searchInputText;
    if (this.filterModalResult) {
      result.groupId = this.filterModalResult.groupId;
      result.fromDate = this.filterModalResult.fromDate;
      result.toDate = this.filterModalResult.toDate;
    }
    this.filterConditions$.next(result);
  }

  async openFilterModal() {
    const filterSearchModalResult: FilterSearchModalResult =
      await this.modals.openModal('filter-search-modal');
    this.filterModalResult = filterSearchModalResult;
    this.updateFilterConditions();
  }

  hasFilter() {
    if (this.filterModalResult) {
      return (
        this.filterModalResult.groupId !== undefined ||
        this.filterModalResult.fromDate !== undefined ||
        this.filterModalResult.toDate !== undefined
      );
    } else {
      return false;
    }
  }

  clearFilters() {
    this.filterModalResult = undefined;
    this.updateFilterConditions();
  }

  toggleCreatedAtSortDirection() {
    if (!this.createdAtSortDirection$.value) {
      this.createdAtSortDirection$.next('desc');
      this.nameSortDirection$.next(null);
      return;
    }
    this.createdAtSortDirection$.next(
      this.createdAtSortDirection$.value === 'asc' ? 'desc' : 'asc',
    );
  }

  toggleNameSortDirection() {
    if (!this.nameSortDirection$.value) {
      this.nameSortDirection$.next('asc');
      this.createdAtSortDirection$.next(null);
      return;
    }
    this.nameSortDirection$.next(
      this.nameSortDirection$.value === 'asc' ? 'desc' : 'asc',
    );
  }
}
