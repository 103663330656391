<h2 class="title">Valoración mediante imágenes de casos reales</h2>



<div class="test-info">
    <app-client-back-button [client]="client"></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
        <button (click)="picker.open()" class="date-selector__button">{{test.createdAt.toDate() | date}} <i
                class="fas fa-edit"></i></button>

        <input style="display: none;" (dateChange)="onDateChanged($event);" matInput [matDatepicker]="picker"
            placeholder="Choose a date">
        <mat-datepicker touchUi #picker [startAt]="test.createdAt.toDate()"></mat-datepicker>
    </div>
</div>

<div *ngIf="!diagnosticsOpen" class="measures animated fadeIn">
    <button (click)="openDiagnostics()" *ngIf="diagnosticsAvailable" class="open-diagnostics animated fadeIn">
        <div class="open-diagnostics__icon-1"><i class="far fa-check-circle"></i></div>
        <div class="open-diagnostics__label">Diagnóstico</div>
        <div class="open-diagnostics__icon-2"><i class="fas fa-chevron-right"></i></div>
    </button>
    <div class="test-cards-container">
        <div class="test-card">
            <div class="test-card__heading">
                <div class="test-card__title tooltip">
                    Bipedestación
                    <div class="top top--test-card">
                        <h3>Bipedestación</h3>
                        <p>En bipedestación (de pie), con los brazos junto al tronco y con la mirada al frente,
                            manteniendo
                            una postura cómoda y relajada.</p>
                        <i></i>
                    </div>
                </div>
                <div class="test-card__help">
                    <i (click)="openLearn('sagital-test-bipedestacion');" class="fas fa-graduation-cap"></i>
                </div>
            </div>

            <div class="test-card__divider"></div>
            <div class="test-card__content">
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Dorsal</p>
                    </div>
                    <div *ngIf="'bipDorsal' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Lumbar</p>
                    </div>
                    <div *ngIf="'bipLumbar' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Pelvis</p>
                    </div>
                    <div *ngIf="'bipPelvis' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="test-card">
            <div class="test-card__heading">
                <div class="test-card__title tooltip">
                    Sedentación
                    <div class="top top--test-card">
                        <h3>Sedentación Asténica</h3>
                        <p>En Sedentación (sentado) Asténica (relajado), con los antebrazos sobre los muslos y con la
                            mirada
                            al frente, manteniendo una postura relajada.</p>
                    </div>
                </div>
                <div class="test-card__help">
                    <i (click)="openLearn('sagital-test-sedentacion');" class="fas fa-graduation-cap"></i>
                </div>
            </div>

            <div class="test-card__divider"></div>
            <div class="test-card__content">
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Dorsal</p>
                    </div>
                    <div *ngIf="'sedDorsal' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Lumbar</p>
                    </div>
                    <div *ngIf="'sedLumbar' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Pelvis</p>
                    </div>
                    <div *ngIf="'sedPelvis' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="test-card">
            <div class="test-card__heading">
                <div class="test-card__title tooltip">
                    Flexión Máxima del Tronco
                    <div class="top top--test-card">
                        <h3>Flexión Máxima del Tronco</h3>
                        <p>Sentado sobre una superficie dura (suelo o camilla) con las rodillas extendidas y los pies a
                            90º,
                            se le pedirá una Flexión Máxima de Tronto mantenida en la que intentará sobrepasar la punta
                            de
                            los pies sin tocarlos. Colocar una mano sobre la otra.
                            El resultado de la medición dorsal es superior a 45º, por lo tanto, es recomendable
                            cuantificar
                            otra medida realizando la Maniobra de Autocorrección de la columna para obtener un
                            diagnóstico
                        </p>
                    </div>
                </div>
                <div class="test-card__help">
                    <i (click)="openLearn('sagital-test-flex');" class="fas fa-graduation-cap"></i>
                </div>
            </div>

            <div class="test-card__divider"></div>
            <div class="test-card__content">
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Dorsal</p>
                    </div>
                    <div *ngIf="'flexDorsal' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Lumbar</p>
                    </div>
                    <div *ngIf="'flexLumbar' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Pelvis</p>
                    </div>
                    <div *ngIf="'flexPelvis' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="test-card">
            <div class="test-card__heading">
                <div class="test-card__title tooltip">
                    Ápex
                    <div class="top top--test-card">
                        <h3>Ápex</h3>
                        <p>Marca esta casilla si observas una vértebra muy prominente en la línea media.</p>
                    </div>
                </div>
                <div class="test-card__help">
                    <!-- <i (click)="openLearn('sagital-test-bipedestacion');" class="fas fa-graduation-cap"></i> -->
                </div>
            </div>

            <div class="test-card__divider"></div>
            <div class="test-card__content">
                <div class="test-card__row">
                    <div class="test-card__label">
                        <p>Presenta Ápex</p>
                    </div>
                    <div *ngIf="'apex' as testId" (click)="openImageSelector(testId)" class="test-card__value"
                        [class.test-card__value--empty]="!test[testId]">
                        <p>{{test[testId] ? Results[testId][test[testId]].text : '--'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="diagnosticsOpen" class="diagnostics animated fadeIn">
    <button (click)="back();" class="close-diagnostics">
        <div class="close-diagnostics__icon">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div class="close-diagnostics__label">Volver</div>
    </button>
    <div class="diagnostics-container">

        <div *ngIf="totalValorations !== completedValorations" class="diagnostics-container__row">
            <div class="test-progress-card">
                <div class="test-progress-card__text">
                    Completa todas las valoraciones para obtener un diagnóstico preciso. <b>({{completedValorations}}/{{totalValorations}})</b>
                </div>
                <div class="test-progress-card__progress">
                    <div class="progress-bar">
                        <span class="bar">
                            <span [style]="testProgressCssSanitized" class="progress"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="diagnostics-container__row diagnostics-container__row--1">
            <div class="diagnostic-card" [class.diagnostic-card--not-available]="!test.results.dorsal">
                <div class="diagnostic-card__title">Dorsal</div>
                <div class="diagnostic-card__divider"></div>
                <div class="diagnostic-card__content" *ngIf="test.results.dorsal as result">
                    <div class="diagnostic-card__result">{{result.name}}</div>
                    <div class="diagnostic-card__result-tips-divider"></div>
                    <div class="diagnostic-card__tips">
                        <ul class="diagnostic-card__tips-list">

                            <li *ngFor="let tip of result.tips" class="diagnostic-card__tips-list-item">
                                {{tip}}
                            </li>
                            <div *ngIf="result.tips.length===0" class="diagnostic-card__tips-list-no-tips">
                                ¡Enhorabuena!
                            </div>

                        </ul>
                    </div>
                </div>
                <div *ngIf="!test.results.dorsal" class="diagnostic-card__content-empty">
                    <p>Se necesitan más medidas para obtener este diagnóstico.</p>
                </div>
            </div>
            <div class="diagnostic-card" [class.diagnostic-card--not-available]="!test.results.lumbar">
                <div class="diagnostic-card__title">Lumbar</div>
                <div class="diagnostic-card__divider"></div>
                <div class="diagnostic-card__content" *ngIf="test.results.lumbar as result">
                    <div class="diagnostic-card__result">{{result.name}}</div>
                    <div class="diagnostic-card__result-tips-divider"></div>
                    <div class="diagnostic-card__tips">
                        <ul class="diagnostic-card__tips-list">

                            <li *ngFor="let tip of result.tips" class="diagnostic-card__tips-list-item">
                                {{tip}}
                            </li>
                            <div *ngIf="result.tips.length===0" class="diagnostic-card__tips-list-no-tips">
                                ¡Enhorabuena!
                            </div>

                        </ul>
                    </div>
                </div>
                <div *ngIf="!test.results.lumbar" class="diagnostic-card__content-empty">
                    <p>Se necesitan más medidas para obtener este diagnóstico.</p>
                </div>
            </div>
        </div>

        <div class="diagnostics-container__row diagnostics-container__row--2">
            <div *ngIf="'bipPelvis' as testId" class="diagnostic-card" [class.diagnostic-card--not-available]="!test[testId]">
                <div class="diagnostic-card__title">Pelvis - Bipedestación</div>
                <div class="diagnostic-card__divider"></div>
                <div class="diagnostic-card__content" *ngIf="test[testId] as result">
                    <div class="diagnostic-card__result">{{Results[testId][result].text}}</div>
                    <div class="diagnostic-card__result-tips-divider"></div>
                    <div class="diagnostic-card__tips">
                        <ul class="diagnostic-card__tips-list">

                            <li *ngFor="let tip of Results[testId][result].tips" class="diagnostic-card__tips-list-item">
                                {{tip}}
                            </li>
                            <div *ngIf="Results[testId][result].tips.length===0" class="diagnostic-card__tips-list-no-tips">
                                ¡Enhorabuena!
                            </div>

                        </ul>
                    </div>
                </div>
                <div *ngIf="!test[testId]" class="diagnostic-card__content-empty">
                    <p>Se necesitan más medidas para obtener este diagnóstico.</p>
                </div>
            </div>
            <div *ngIf="'sedPelvis' as testId" class="diagnostic-card" [class.diagnostic-card--not-available]="!test[testId]">
                <div class="diagnostic-card__title">Pelvis - Sedestación</div>
                <div class="diagnostic-card__divider"></div>
                <div class="diagnostic-card__content" *ngIf="test[testId] as result">
                    <div class="diagnostic-card__result">{{Results[testId][result].text}}</div>
                    <div class="diagnostic-card__result-tips-divider"></div>
                    <div class="diagnostic-card__tips">
                        <ul class="diagnostic-card__tips-list">

                            <li *ngFor="let tip of Results[testId][result].tips" class="diagnostic-card__tips-list-item">
                                {{tip}}
                            </li>
                            <div *ngIf="Results[testId][result].tips.length===0" class="diagnostic-card__tips-list-no-tips">
                                ¡Enhorabuena!
                            </div>

                        </ul>
                    </div>
                </div>
                <div *ngIf="!test[testId]" class="diagnostic-card__content-empty">
                    <p>Se necesitan más medidas para obtener este diagnóstico.</p>
                </div>
            </div>
            <div *ngIf="'flexPelvis' as testId" class="diagnostic-card" [class.diagnostic-card--not-available]="!test[testId]">
                <div class="diagnostic-card__title">Pelvis - Flexión</div>
                <div class="diagnostic-card__divider"></div>
                <div class="diagnostic-card__content" *ngIf="test[testId] as result">
                    <div class="diagnostic-card__result">{{Results[testId][result].text}}</div>
                    <div class="diagnostic-card__result-tips-divider"></div>
                    <div class="diagnostic-card__tips">
                        <ul class="diagnostic-card__tips-list">

                            <li *ngFor="let tip of Results[testId][result].tips" class="diagnostic-card__tips-list-item">
                                {{tip}}
                            </li>
                            <div *ngIf="Results[testId][result].tips.length===0" class="diagnostic-card__tips-list-no-tips">
                                ¡Enhorabuena!
                            </div>

                        </ul>
                    </div>
                </div>
                <div *ngIf="!test[testId]" class="diagnostic-card__content-empty">
                    <p>Se necesitan más medidas para obtener este diagnóstico.</p>
                </div>
            </div>
        </div>



    </div>

</div>




<app-sagital-image-valoration-selector></app-sagital-image-valoration-selector>