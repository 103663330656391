<div class="flex h-full flex-col overflow-hidden">
  <div class="px-5 pt-5 md:px-20 md:pt-20">
    <div class="flex justify-between">
      <div>
        <div
          class="mb-2 flex cursor-pointer items-center transition-all duration-200 hover:-translate-x-2"
          (click)="
            tabState === 'edit' || tabState === 'new'
              ? (tabState = 'items')
              : historyBackClose()
          "
        >
          <i class="fas fa-arrow-circle-left blue mr-5"></i>
          <span>Volver</span>
        </div>
        <div class="mb-5 flex items-center justify-between">
          <h1 class="text-xl md:text-3xl">
            {{
              tabState === 'edit'
                ? 'Editar ejercicio'
                : tabState === 'new'
                  ? 'Crear nuevo ejercicio'
                  : 'Ejercicios'
            }}
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div
    class="relative min-h-0 flex-grow overflow-x-auto px-5 pb-5 md:pb-20 md:pl-20"
  >
    <div
      *ngIf="loadingState === 'loading'; else isNotLoadingBlock"
      class="animated fadeIn flex h-full items-center justify-center"
    >
      <img
        class="w-44"
        src="../../../assets/images/loading-bricks.gif"
        alt=""
      />
    </div>

    <ng-template #isNotLoadingBlock>
      <ng-container *ngIf="canUseExercises">
        <!-- tabState to list items -->
        <div
          *ngIf="tabState === 'items'"
          class="h-full overflow-hidden"
          style="
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto minmax(0, 1fr);
          "
        >
          <div class="mb-2 flex w-full justify-between sm:justify-start">
            <app-tab-item
              [name]="'Galería INTECC'"
              [icon]="'fas fa-book'"
              [selected]="selectedItemsTab === 'library'"
              (tapEvent)="handleTabChange('library')"
            ></app-tab-item>
            <app-tab-item
              [name]="'Mis Ejercicios'"
              [icon]="'fas fa-dumbbell'"
              [selected]="selectedItemsTab === 'personal'"
              (tapEvent)="handleTabChange('personal')"
            ></app-tab-item>
          </div>
          <div>
            <div
              *ngIf="multiSelectList.length > 0"
              class="flex items-center justify-between rounded-md bg-intecc2 p-2 px-4 text-white"
            >
              <div class="flex items-center">
                <span class="font-bold"
                  >{{ multiSelectList.length }} seleccionado(s)</span
                >
              </div>
              <div class="flex flex-wrap gap-2">
                <!-- TODO: add a button to add all selected exercises to a session section? -->
                <div
                  class="cursor-pointer rounded-md px-2 py-1 text-white hover:bg-blue-700"
                  (click)="openMoveExerciseDialog(null, $event)"
                >
                  <i class="fas fa-tags"></i>
                </div>
                <div
                  class="cursor-pointer rounded-md px-2 py-1 text-white hover:bg-blue-700"
                  (click)="deleteSelectedExercises()"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="selectedItemsTab === 'personal'"
            class="h-full overflow-hidden"
          >
            <div class="custom-scroll h-full overflow-y-auto">
              <div class="w-full">
                <ng-container *ngIf="sections">
                  <cdk-accordion class="example-accordion bg-white">
                    <cdk-accordion-item
                      *ngFor="
                        let item of sections.sections;
                        let sectionIndex = index
                      "
                      #accordionItem="cdkAccordionItem"
                      class="example-accordion-item"
                      role="button"
                      tabindex="0"
                      [attr.id]="'accordion-header-' + sectionIndex"
                      [attr.aria-expanded]="accordionItem.expanded"
                      [attr.aria-controls]="'accordion-body-' + sectionIndex"
                    >
                      <div
                        class="example-accordion-item-header"
                        (click)="accordionItem.toggle()"
                      >
                        <div class="flex w-full items-center justify-between">
                          <div class="ml-3">
                            <p class="font-bold">{{ item.name }}</p>
                          </div>
                          <div class="flex">
                            <div
                              (click)="moveSectionUp(sectionIndex, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-angle-up"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="moveSectionDown(sectionIndex, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-angle-down"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="openRenameSection(item, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-pen-alt"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="deleteSection(sectionIndex)"
                              class="p-2"
                            >
                              <i class="far fa-trash-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="example-accordion-item-body"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + sectionIndex"
                        [attr.aria-labelledby]="
                          'accordion-header-' + sectionIndex
                        "
                      >
                        <div class="exercises-grid">
                          <div
                            *ngFor="
                              let exerciseId of item.exerciseIds;
                              let exerciseIndex = index
                            "
                            class="relative flex aspect-square cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
                            (click)="selectExerciseById(exerciseId)"
                            [class.hidden]="!isExerciseIdValid(exerciseId)"
                          >
                            <ng-container
                              *ngIf="getExerciseById(exerciseId) as exercise"
                            >
                              <div
                                class="absolute left-0 top-0 flex h-full w-full flex-col"
                              >
                                <div
                                  class="relative flex-1 overflow-hidden"
                                  *ngIf="exercise.imageUrl"
                                >
                                  <img
                                    [src]="exercise.imageUrl"
                                    alt="Exercise image"
                                    class="absolute left-0 top-0 h-full w-full object-cover"
                                  />
                                </div>
                                <div
                                  class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-black/20 p-2"
                                  (click)="$event.stopPropagation()"
                                >
                                  <div
                                    class="flex items-center justify-between"
                                  >
                                    <h3
                                      class="mb-1 line-clamp-2 text-sm font-semibold text-white first-letter:capitalize"
                                      [matTooltip]="exercise.title"
                                      #tooltip="matTooltip"
                                      (click)="
                                        tooltip.toggle();
                                        $event.stopPropagation()
                                      "
                                    >
                                      {{ exercise.title }}
                                    </h3>
                                    <i
                                      *ngIf="exercise.description"
                                      class="fa-solid fa-circle-info ml-2 cursor-pointer text-white"
                                      [matTooltip]="exercise.description"
                                      #tooltip="matTooltip"
                                      (click)="
                                        tooltip.toggle();
                                        $event.stopPropagation()
                                      "
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <div
                                  (click)="editExercise(exercise, $event)"
                                  class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i class="fas fa-edit"></i>
                                </div>
                                <div
                                  (click)="
                                    openMoveExerciseDialog(exercise, $event)
                                  "
                                  class="flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i class="fas fa-tags"></i>
                                </div>
                                <div
                                  (click)="multiSelectItem(exercise, $event)"
                                  class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i
                                    *ngIf="!isExerciseMultiSelected(exercise)"
                                    class="far fa-square text-xs"
                                  ></i>
                                  <i
                                    *ngIf="isExerciseMultiSelected(exercise)"
                                    class="fas fa-check-square text-xs"
                                  ></i>
                                </div>
                              </div>

                              <div
                                (click)="
                                  moveExerciseLeft(
                                    sectionIndex,
                                    exerciseIndex,
                                    $event
                                  )
                                "
                                class="absolute left-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <i
                                  class="fas fa-angle-left text-xs text-white"
                                ></i>
                              </div>
                              <div
                                (click)="
                                  moveExerciseRight(
                                    sectionIndex,
                                    exerciseIndex,
                                    $event
                                  )
                                "
                                class="absolute right-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <i
                                  class="fas fa-angle-right text-xs text-white"
                                ></i>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </cdk-accordion-item>
                  </cdk-accordion>
                  <div class="mb-4"></div>
                  <div
                    (click)="addSection()"
                    class="relative cursor-pointer rounded-md border-2 border-dashed p-4 opacity-60 transition-all duration-200 hover:border-blue-700 hover:text-blue-700 hover:opacity-100"
                  >
                    <p class="text-center">Añadir sección</p>
                  </div>
                  <div class="mb-4"></div>
                </ng-container>
              </div>
              <div class="exercises-grid">
                <div
                  class="border-light-blue-500 relative aspect-square cursor-pointer rounded-xl border-2 border-dashed"
                  (click)="newExerciseMode()"
                >
                  <div
                    class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center p-4 text-neutral-500 hover:text-intecc"
                  >
                    <i
                      class="fas fa-plus-circle mb-2 text-4xl transition-colors duration-200 md:mb-5"
                    ></i>
                    <p
                      class="text-center text-sm transition-colors duration-200"
                    >
                      Crear nuevo ejercicio
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="sections">
                  <ng-container *ngIf="userExercises$ | async as exercises">
                    <ng-container
                      *ngIf="
                        getExercisesWithoutSection(
                          exercises
                        ) as exercisesWithoutSection
                      "
                    >
                      <div
                        *ngFor="let exercise of exercisesWithoutSection"
                        class="relative flex aspect-square cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
                        (click)="selectExerciseById(exercise.id)"
                      >
                        <div
                          class="absolute left-0 top-0 flex h-full w-full flex-col"
                        >
                          <div
                            class="relative flex-1 overflow-hidden"
                            *ngIf="exercise.imageUrl"
                          >
                            <img
                              [src]="exercise.imageUrl"
                              alt="Exercise image"
                              class="absolute left-0 top-0 h-full w-full object-cover"
                            />
                          </div>
                          <div
                            class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-black/20 p-2"
                            (click)="$event.stopPropagation()"
                          >
                            <div class="flex items-center justify-between">
                              <h3
                                class="mb-1 line-clamp-2 text-sm font-semibold text-white first-letter:capitalize"
                                [matTooltip]="exercise.title"
                                #tooltip="matTooltip"
                                (click)="
                                  tooltip.toggle(); $event.stopPropagation()
                                "
                              >
                                {{ exercise.title }}
                              </h3>
                              <i
                                *ngIf="exercise.description"
                                class="fa-solid fa-circle-info ml-2 cursor-pointer text-white"
                                [matTooltip]="exercise.description"
                                #tooltip="matTooltip"
                                (click)="
                                  tooltip.toggle(); $event.stopPropagation()
                                "
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                        >
                          <div
                            (click)="editExercise(exercise, $event)"
                            class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i class="fas fa-edit"></i>
                          </div>
                          <div
                            (click)="openMoveExerciseDialog(exercise, $event)"
                            class="flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i class="fas fa-tags"></i>
                          </div>
                          <div
                            (click)="multiSelectItem(exercise, $event)"
                            class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i
                              *ngIf="!isExerciseMultiSelected(exercise)"
                              class="far fa-square text-xs"
                            ></i>
                            <i
                              *ngIf="isExerciseMultiSelected(exercise)"
                              class="fas fa-check-square text-xs"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            *ngIf="selectedItemsTab === 'library'"
            class="h-full overflow-hidden"
          >
            <div class="custom-scroll h-full overflow-y-auto">
              <div class="w-full">
                <ng-container *ngIf="globalSections">
                  <cdk-accordion class="example-accordion bg-white">
                    <cdk-accordion-item
                      *ngFor="
                        let item of globalSections.sections;
                        let sectionIndex = index
                      "
                      #accordionItem="cdkAccordionItem"
                      class="example-accordion-item"
                      role="button"
                      tabindex="0"
                      [attr.id]="'accordion-header-' + sectionIndex"
                      [attr.aria-expanded]="accordionItem.expanded"
                      [attr.aria-controls]="'accordion-body-' + sectionIndex"
                    >
                      <div
                        class="example-accordion-item-header"
                        (click)="accordionItem.toggle()"
                      >
                        <div class="flex w-full items-center justify-between">
                          <div class="ml-3">
                            <p class="font-bold">{{ item.name }}</p>
                          </div>
                          <div class="flex" *ngIf="userIsAdmin">
                            <div
                              (click)="moveSectionUp(sectionIndex, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-angle-up"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="moveSectionDown(sectionIndex, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-angle-down"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="openRenameSection(item, $event)"
                              class="p-2"
                            >
                              <i class="fas fa-pen-alt"></i>
                            </div>
                            <div class="mr-3"></div>
                            <div
                              (click)="deleteSection(sectionIndex)"
                              class="p-2"
                            >
                              <i class="far fa-trash-alt"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="example-accordion-item-body"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + sectionIndex"
                        [attr.aria-labelledby]="
                          'accordion-header-' + sectionIndex
                        "
                      >
                        <div class="exercises-grid">
                          <div
                            *ngFor="
                              let exerciseId of item.exerciseIds;
                              let exerciseIndex = index
                            "
                            class="relative flex aspect-square cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
                            (click)="selectExerciseById(exerciseId, true)"
                            [class.hidden]="!isExerciseIdValid(exerciseId)"
                          >
                            <ng-container
                              *ngIf="
                                getGlobalExerciseById(exerciseId) as exercise
                              "
                            >
                              <div
                                class="absolute left-0 top-0 flex h-full w-full flex-col"
                              >
                                <div
                                  class="relative flex-1 overflow-hidden"
                                  *ngIf="exercise.imageUrl"
                                >
                                  <img
                                    [src]="exercise.imageUrl"
                                    alt="Exercise image"
                                    class="absolute left-0 top-0 h-full w-full object-cover"
                                  />
                                </div>
                                <div
                                  class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-black/20 p-2"
                                  (click)="$event.stopPropagation()"
                                >
                                  <div
                                    class="flex items-center justify-between"
                                  >
                                    <h3
                                      class="mb-1 line-clamp-2 text-sm font-semibold text-white first-letter:capitalize"
                                      [matTooltip]="exercise.title"
                                      #tooltip="matTooltip"
                                      (click)="
                                        tooltip.toggle();
                                        $event.stopPropagation()
                                      "
                                    >
                                      {{ exercise.title }}
                                    </h3>
                                    <i
                                      *ngIf="exercise.description"
                                      class="fa-solid fa-circle-info ml-2 cursor-pointer text-white"
                                      [matTooltip]="exercise.description"
                                      #tooltip="matTooltip"
                                      (click)="
                                        tooltip.toggle();
                                        $event.stopPropagation()
                                      "
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div
                                *ngIf="userIsAdmin"
                                class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <div
                                  (click)="editExercise(exercise, $event)"
                                  class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i class="fas fa-edit"></i>
                                </div>
                                <div
                                  (click)="
                                    openMoveExerciseDialog(exercise, $event)
                                  "
                                  class="flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i class="fas fa-tags"></i>
                                </div>
                                <div
                                  (click)="multiSelectItem(exercise, $event)"
                                  class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                                >
                                  <i
                                    *ngIf="!isExerciseMultiSelected(exercise)"
                                    class="far fa-square text-xs"
                                  ></i>
                                  <i
                                    *ngIf="isExerciseMultiSelected(exercise)"
                                    class="fas fa-check-square text-xs"
                                  ></i>
                                </div>
                              </div>

                              <div
                                *ngIf="userIsAdmin"
                                (click)="
                                  moveExerciseLeft(
                                    sectionIndex,
                                    exerciseIndex,
                                    $event
                                  )
                                "
                                class="absolute left-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <i
                                  class="fas fa-angle-left text-xs text-white"
                                ></i>
                              </div>
                              <div
                                *ngIf="userIsAdmin"
                                (click)="
                                  moveExerciseRight(
                                    sectionIndex,
                                    exerciseIndex,
                                    $event
                                  )
                                "
                                class="absolute right-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-lg bg-black/50"
                              >
                                <i
                                  class="fas fa-angle-right text-xs text-white"
                                ></i>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </cdk-accordion-item>
                  </cdk-accordion>
                  <div class="mb-4"></div>
                  <div
                    *ngIf="userIsAdmin"
                    (click)="addSection()"
                    class="relative cursor-pointer rounded-md border-2 border-dashed p-4 opacity-60 transition-all duration-200 hover:border-blue-700 hover:text-blue-700 hover:opacity-100"
                  >
                    <p class="text-center">Añadir sección</p>
                  </div>
                  <div class="mb-4"></div>
                </ng-container>
              </div>
              <div class="exercises-grid">
                <div
                  *ngIf="userIsAdmin"
                  class="border-light-blue-500 relative aspect-square cursor-pointer rounded-xl border-2 border-dashed"
                  (click)="newExerciseMode()"
                >
                  <div
                    class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center p-4 text-neutral-500 hover:text-intecc"
                  >
                    <i
                      class="fas fa-plus-circle mb-2 text-4xl transition-colors duration-200 md:mb-5"
                    ></i>
                    <p
                      class="text-center text-sm transition-colors duration-200"
                    >
                      Crear nuevo ejercicio
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="globalSections">
                  <ng-container *ngIf="globalExercises$ | async as exercises">
                    <ng-container
                      *ngIf="
                        getExercisesWithoutSection(
                          exercises
                        ) as exercisesWithoutSection
                      "
                    >
                      <div
                        *ngFor="let exercise of exercisesWithoutSection"
                        class="relative flex aspect-square cursor-pointer flex-col overflow-hidden rounded-lg bg-white shadow-sm"
                        (click)="selectExerciseById(exercise.id, true)"
                      >
                        <div
                          class="absolute left-0 top-0 flex h-full w-full flex-col"
                        >
                          <div
                            class="relative flex-1 overflow-hidden"
                            *ngIf="exercise.imageUrl"
                          >
                            <img
                              [src]="exercise.imageUrl"
                              alt="Exercise image"
                              class="absolute left-0 top-0 h-full w-full object-cover"
                            />
                          </div>
                          <div
                            class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-black/20 p-2"
                            (click)="$event.stopPropagation()"
                          >
                            <div class="flex items-center justify-between">
                              <h3
                                class="mb-1 line-clamp-2 text-sm font-semibold text-white first-letter:capitalize"
                                [matTooltip]="exercise.title"
                                #tooltip="matTooltip"
                                (click)="
                                  tooltip.toggle(); $event.stopPropagation()
                                "
                              >
                                {{ exercise.title }}
                              </h3>
                              <i
                                *ngIf="exercise.description"
                                class="fa-solid fa-circle-info ml-2 cursor-pointer text-white"
                                [matTooltip]="exercise.description"
                                #tooltip="matTooltip"
                                (click)="
                                  tooltip.toggle(); $event.stopPropagation()
                                "
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="userIsAdmin"
                          class="absolute left-0 top-0 flex cursor-pointer items-center justify-center rounded-lg bg-black/50"
                        >
                          <div
                            (click)="editExercise(exercise, $event)"
                            class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i class="fas fa-edit"></i>
                          </div>
                          <div
                            (click)="openMoveExerciseDialog(exercise, $event)"
                            class="flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i class="fas fa-tags"></i>
                          </div>
                          <div
                            (click)="multiSelectItem(exercise, $event)"
                            class="mr-1 flex h-8 w-8 items-center justify-center rounded text-white transition-colors duration-200 hover:bg-white/20"
                          >
                            <i
                              *ngIf="!isExerciseMultiSelected(exercise)"
                              class="far fa-square text-xs"
                            ></i>
                            <i
                              *ngIf="isExerciseMultiSelected(exercise)"
                              class="fas fa-check-square text-xs"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- tabState to create new exercise -->
        <div *ngIf="tabState === 'new'" class="h-full">
          <form
            #exerciseForm="ngForm"
            class="flex h-full min-h-0 flex-col gap-4 overflow-y-auto px-1 pb-10"
          >
            <div>
              <label class="mb-2 block font-bold" for="name">Nombre*</label>
              <input
                id="name"
                name="name"
                [(ngModel)]="newExercise.title"
                required
                class="w-full rounded-md bg-neutral-100 px-4 py-2"
                type="text"
              />
            </div>

            <div>
              <label class="mb-2 block font-bold" for="description"
                >Descripción*</label
              >
              <textarea
                id="description"
                name="description"
                [(ngModel)]="newExercise.description"
                class="w-full rounded-md bg-neutral-100 px-4 py-2"
                rows="4"
              ></textarea>
            </div>

            <div>
              <label class="mb-2 block font-bold">Imagen</label>
              <div class="flex flex-col gap-4">
                <div class="flex items-center gap-4">
                  <div
                    *ngIf="newExercise.imageUrl"
                    class="relative h-64 w-64 overflow-hidden rounded-lg border border-neutral-200"
                  >
                    <img
                      [src]="newExercise.imageUrl"
                      class="h-full w-full cursor-pointer object-cover"
                      (click)="selectImage('new')"
                      alt="Exercise image"
                    />
                    <div
                      (click)="removeImage('new')"
                      class="absolute right-1 top-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-black/50 text-white hover:bg-black/70"
                    >
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                  <app-button
                    *ngIf="!newExercise.imageUrl"
                    (clickEvent)="selectImage('new')"
                    [text]="'Seleccionar imagen'"
                    [icon]="'fas fa-image'"
                  ></app-button>
                </div>
                <div class="flex flex-col gap-2">
                  <label class="text-sm">URL de la imagen</label>
                  <input
                    type="text"
                    [(ngModel)]="newExercise.imageUrl"
                    name="imageUrl"
                    class="w-full rounded-md bg-neutral-100 px-4 py-2"
                    placeholder="https://..."
                  />
                </div>
                <div class="flex flex-col gap-2">
                  <label class="text-sm">URL a mostrar en informes</label>
                  <input
                    type="text"
                    [(ngModel)]="newExercise.reportLinkUrl"
                    name="reportLinkUrl"
                    class="w-full rounded-md bg-neutral-100 px-4 py-2"
                    placeholder="https://..."
                  />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label class="mb-2 block font-bold" for="type">Tipo</label>
                <app-new-dropdown
                  [options]="exerciseTypeOptions"
                  [multiple]="true"
                  [ngModel]="newExercise.type"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="newExercise.type = $event"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
              <div>
                <label class="mb-2 block font-bold" for="level">Nivel</label>
                <app-new-dropdown
                  [options]="levelDropdownOptions"
                  [multiple]="true"
                  [ngModel]="newExercise.level"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="newExercise.level = $event"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label class="mb-2 block font-bold" for="tags">Etiquetas</label>
                <input
                  id="tags"
                  name="tags"
                  [value]="getTagsString()"
                  (input)="updateTags($event)"
                  type="text"
                  class="w-full rounded-md bg-neutral-100 px-4 py-2"
                  placeholder="Palabra/s clave separadas por coma"
                />
              </div>
              <div>
                <label class="mb-2 block font-bold" for="material"
                  >Material</label
                >
                <app-new-dropdown
                  [options]="materialOptions"
                  [multiple]="true"
                  [ngModel]="newExercise.material"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="updateMaterials($event)"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
            </div>

            <div class="mt-auto flex justify-end gap-2">
              <app-button
                [type]="'secondary'"
                [text]="'Cancelar'"
                (clickEvent)="tabState = 'items'"
              ></app-button>
              <app-button
                [type]="'primary'"
                [text]="'Crear ejercicio'"
                [disabled]="!exerciseForm.form.valid"
                (clickEvent)="createNewExercise()"
              ></app-button>
            </div>
          </form>
        </div>

        <!-- tabState to edit exercise -->
        <div *ngIf="tabState === 'edit'" class="h-full">
          <form
            #editForm="ngForm"
            class="flex h-full min-h-0 flex-col gap-4 overflow-y-auto px-1 pb-10"
          >
            <div>
              <label class="mb-2 block font-bold" for="editName">Nombre*</label>
              <input
                id="editName"
                name="editName"
                [(ngModel)]="editingExercise.title"
                required
                class="w-full rounded-md bg-neutral-100 px-4 py-2"
                type="text"
              />
            </div>

            <div>
              <label class="mb-2 block font-bold" for="editDescription"
                >Descripción*</label
              >
              <textarea
                id="editDescription"
                name="editDescription"
                [(ngModel)]="editingExercise.description"
                class="w-full rounded-md bg-neutral-100 px-4 py-2"
                rows="4"
              ></textarea>
            </div>

            <div>
              <label class="mb-2 block font-bold">Imagen</label>
              <div class="flex flex-col gap-4">
                <div class="flex items-center gap-4">
                  <div
                    *ngIf="editingExercise.imageUrl"
                    class="relative h-64 w-64 overflow-hidden rounded-lg border border-neutral-200"
                  >
                    <img
                      [src]="editingExercise.imageUrl"
                      class="h-full w-full cursor-pointer object-cover"
                      (click)="selectImage('edit')"
                      alt="Exercise image"
                    />
                    <div
                      (click)="removeImage('edit')"
                      class="absolute right-1 top-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-black/50 text-white hover:bg-black/70"
                    >
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                  <app-button
                    *ngIf="!editingExercise.imageUrl"
                    (clickEvent)="selectImage('edit')"
                    [text]="'Seleccionar imagen'"
                    [icon]="'fas fa-image'"
                  ></app-button>
                </div>
                <div class="flex flex-col gap-2">
                  <label class="text-sm">URL de la imagen</label>
                  <input
                    type="text"
                    [(ngModel)]="editingExercise.imageUrl"
                    name="editImageUrl"
                    class="w-full rounded-md bg-neutral-100 px-4 py-2"
                    placeholder="https://..."
                  />
                </div>
                <div class="flex flex-col gap-2">
                  <label class="text-sm">URL a mostrar en informes</label>
                  <input
                    type="text"
                    [(ngModel)]="editingExercise.reportLinkUrl"
                    name="editReportLinkUrl"
                    class="w-full rounded-md bg-neutral-100 px-4 py-2"
                    placeholder="https://..."
                  />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label class="mb-2 block font-bold" for="type">Tipo</label>
                <app-new-dropdown
                  [options]="exerciseTypeOptions"
                  [multiple]="true"
                  [ngModel]="editingExercise.type"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="editingExercise.type = $event"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
              <div>
                <label class="mb-2 block font-bold" for="editLevel"
                  >Nivel</label
                >
                <app-new-dropdown
                  [options]="levelDropdownOptions"
                  [multiple]="true"
                  [ngModel]="editingExercise.level"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="editingExercise.level = $event"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label class="mb-2 block font-bold" for="tags">Etiquetas</label>
                <input
                  id="tags"
                  name="tags"
                  [value]="getTagsString()"
                  (input)="updateTags($event)"
                  type="text"
                  class="w-full rounded-md bg-neutral-100 px-4 py-2"
                  placeholder="Palabra/s clave separadas por coma"
                />
              </div>
              <div>
                <label class="mb-2 block font-bold" for="editMaterial"
                  >Material</label
                >
                <app-new-dropdown
                  [options]="materialOptions"
                  [multiple]="true"
                  [ngModel]="editingExercise.material"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="updateMaterials($event)"
                  buttonClass="bg-neutral-100 border-none shadow-none"
                >
                </app-new-dropdown>
              </div>
            </div>

            <div class="mt-auto flex justify-end gap-2">
              <app-button
                [type]="'secondary'"
                [text]="'Cancelar'"
                (clickEvent)="tabState = 'items'"
              ></app-button>
              <app-button
                [type]="'primary'"
                [text]="'Guardar cambios'"
                [disabled]="!editForm.form.valid"
                (clickEvent)="updateExercise()"
              ></app-button>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="!canUseExercises">
        <div
          class="flex h-full flex-col items-center justify-center text-center"
        >
          <i class="fa-solid fa-lock mb-4 text-6xl text-gray-400"></i>
          <div class="text-lg font-semibold text-gray-600">
            No tienes permisos para usar la funcionalidad de Ejercicios.
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<!-- Move exercise to section dialog -->
<div
  class="absolute left-0 top-0 flex h-full w-full flex-col justify-center bg-black/70"
  [class.hidden]="!moveExerciseDialogOpen"
>
  <div class="w-full px-2">
    <div
      class="mx-auto max-h-screen max-w-md overflow-y-auto rounded-lg bg-white p-5 py-5"
    >
      <div class="mb-3 flex items-center justify-between">
        <p class="text-lg font-bold">Mover a...</p>
        <div class="cursor-pointer p-2" (click)="closeMoveToSection()">
          <i class="far fas fa-times"></i>
        </div>
      </div>
      <ng-container *ngIf="sections">
        <div
          *ngFor="let section of sections.sections"
          (click)="moveToSection(section)"
          class="mb-2 cursor-pointer rounded-lg bg-neutral-100 px-4 py-2"
        >
          <p class="">{{ section.name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Rename section dialog -->
<div
  class="absolute left-0 top-0 flex h-full w-full flex-col justify-center bg-black/70"
  [class.hidden]="!editSectionNameOpen"
>
  <div class="w-full px-2">
    <div class="mx-auto max-w-md rounded-lg bg-white p-5 py-5">
      <p class="mb-3 text-lg font-bold">Renombrar sección</p>
      <input
        class="block w-full bg-neutral-100 px-5 py-2"
        type="text"
        [(ngModel)]="editSectionNameText"
      />
      <div class="mb-5"></div>
      <div class="flex justify-center">
        <app-button
          [type]="'primary'"
          (clickEvent)="endRenameSection()"
          [text]="'Renombrar'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>

<!-- Move exercise to global section dialog -->
<div
  class="absolute left-0 top-0 flex h-full w-full flex-col justify-center bg-black/70"
  [class.hidden]="!moveGlobalDialogOpen"
>
  <div class="w-full px-2">
    <div
      class="mx-auto max-h-screen max-w-md overflow-y-auto rounded-lg bg-white p-5 py-5"
    >
      <div class="mb-3 flex items-center justify-between">
        <p class="text-lg font-bold">Mover a...</p>
        <div class="cursor-pointer p-2" (click)="closeMoveToGlobal()">
          <i class="far fas fa-times"></i>
        </div>
      </div>
      <ng-container *ngIf="globalSections">
        <div
          *ngFor="let section of globalSections.sections"
          (click)="moveToSection(section)"
          class="mb-2 cursor-pointer rounded-lg bg-neutral-100 px-4 py-2"
        >
          <p class="">{{ section.name }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
