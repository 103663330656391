<div class="dropdown">
    <div class="dropdown__header" >
        <div class="dropdown__header-display" (click)="onHeaderClick()">
            <div *ngIf="!isOpen" class="dropdown__header-text">
                {{selectedItem ? selectedItem.displayName : 'Selecciona una opción'}}</div>
            <div *ngIf="isOpen" class="dropdown__header-input">
                <input #searchInput (input)="onSearch($event.target.value)" (keydown)="onKeydown($event)" type="text" placeholder="Buscar...">
            </div>
        </div>
        <div class="dropdown__header-icon" (click)="onIconClick($event)">
            <i class="fas fa-chevron-down" [class.fa-rotate-180]="isOpen"></i>
        </div>
    </div>
    <div class="dropdown__content" [class.is-open]="isOpen">
        <div class="dropdown__divider"></div>
        <div class="dropdown__item-container">
            <div *ngFor="let item of filteredItems;" class="dropdown__item" (click)="this.selectItem(item)">
                <p>{{item.displayName}}</p>
            </div>
        </div>
    </div>
</div>