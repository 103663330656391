import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { AuthService } from './auth.service';
import {
  Questionnaire,
  QUESTIONNAIRE_PATH,
} from 'src/shared/questionnaire.outside';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(
    private fs: FirestoreService,
    private auth: AuthService,
    private snackbarService: SnackbarService,
  ) {}

  async saveQuestionnaire(
    questionnaire: Partial<Questionnaire>,
  ): Promise<string> {
    const user = await this.auth.getUser();

    if (user.role !== 'client') {
      console.error('User is not a client');
      this.snackbarService.error('Error al guardar el cuestionario');
      return;
    }

    const questionnaireId = questionnaire.id || this.fs.createId();
    const newQuestionnaire: Partial<Questionnaire> = {
      ...questionnaire,
      id: questionnaireId,
      userId: user.uid,
    };

    try {
      if (questionnaire.id) {
        // Update existing questionnaire
        await this.fs.update(
          `${QUESTIONNAIRE_PATH}/${questionnaireId}`,
          newQuestionnaire,
        );
        this.snackbarService.success('Cuestionario actualizado correctamente');
      } else {
        // Create new questionnaire
        await this.fs.set<Questionnaire>(
          `${QUESTIONNAIRE_PATH}/${questionnaireId}`,
          newQuestionnaire,
        );
        this.snackbarService.success('Cuestionario creado correctamente');
      }
      return questionnaireId;
    } catch (error) {
      console.error('Error saving questionnaire:', error);
      this.snackbarService.error('Error guardando el cuestionario');
      throw error;
    }
  }

  getQuestionnaire$(id: string): Observable<Questionnaire> {
    return this.fs
      .doc$<Questionnaire>(`${QUESTIONNAIRE_PATH}/${id}`)
      .pipe(map((questionnaire) => ({ ...questionnaire, id })));
  }

  getUserQuestionnaires$(userId: string): Observable<Questionnaire[]> {
    return this.fs.colWithIds$<Questionnaire>(QUESTIONNAIRE_PATH, (ref) =>
      ref.where('userId', '==', userId),
    );
  }

  async updateQuestionnaire(
    id: string,
    data: Partial<Questionnaire>,
  ): Promise<void> {
    return this.fs.update(`${QUESTIONNAIRE_PATH}/${id}`, data);
  }

  async deleteQuestionnaire(id: string): Promise<void> {
    return this.fs.delete(`${QUESTIONNAIRE_PATH}/${id}`);
  }
}
