import {
  TestImageValue,
  TestImageInfo,
  TestImagesEnum,
} from "./test-images.shared";
import {
  TestField,
  Test,
  TestType,
  TestWithImages,
  TestReportLink,
  LeftRigthValue,
} from "./tests.model";

export interface FootTest extends Test, TestWithImages {
  results: { [key in FootTestFieldEnum]?: number | LeftRigthValue | string };
  frontalComments?: string;
  sagitalComments?: string;
  generalComments?: string;
  links?: Array<TestReportLink>;
  images: { [id: string]: TestImageValue };
}

export enum FootTestFieldEnum {
  // PFCabeza = 'PFCabeza',
  // PFHombros = 'PFHombros',
  // PFTroncoVentral = 'PFTroncoVentral',
  // PFTroncoDorsal = 'PFTroncoDorsal',
  // PFPelvisVentral = 'PFPelvisVentral',
  // PFPelvisDorsal = 'PFPelvisDorsal',
  // PFRodillasFrontal = 'PFRodillasFrontal',
  // PFRodillasTransversal = 'PFRodillasTransversal',
  // PFPies = 'PFPies',
  // PSLobulo = 'PSLobulo',
  // PSCuerposCervicales = 'PSCuerposCervicales',
  // PSArticulacionHombro = 'PSArticulacionHombro',
  // PSMitadTronco = 'PSMitadTronco',
  // PSTrocanterMayor = 'PSTrocanterMayor',
  // PSAnteriorRodilla = 'PSAnteriorRodilla',
  // PSAnteriorMaleolo = 'PSAnteriorMaleolo',
  // PSMorfotipoPostural = 'PSMorfotipoPostural',

  DATipoPiesFalanges = "DATipoPiesFalanges",
  DATipoFalanges = "DATipoFalanges",
  DAHalluxValgus = "DAHalluxValgus",
  VAIAnguloArcoLongitudinal = "VAIAnguloArcoLongitudinal",
  VAINavicularDrop = "VAINavicularDrop",
  VAIAlturaDorsoPie = "VAIAlturaDorsoPie",
  VAIArticulacionMetatarsoFalangica = "VAIArticulacionMetatarsoFalangica",
  ARAnguloAntepie = "ARAnguloAntepie",
  ARAnguloRetropie = "ARAnguloRetropie",
  PHCContinuidadHuella = "PHCContinuidadHuella",
  PHCFaltaImpresionFalanges = "PHCFaltaImpresionFalanges",
  PHCResultadoX = "PHCResultadoX",
  PHCResultadoY = "PHCResultadoY",
  PHCResultadoPer = "PHCResultadoPer",
  PHCTipoPie = "PHCTipoPie",
  ASAAnguloTibia = "ASAAnguloTibia",
  ASAAnguloTronco = "ASAAnguloTronco",
  ASADiferenciaAngulos = "ASADiferenciaAngulos",
  ASADistanciaRodillaPuntaPie = "ASADistanciaRodillaPuntaPie",

  ASBAEstaticoAnguloRetropie = "ASBAEstaticoAnguloRetropie",
  ASBAMarchaAnguloRetropie = "ASBAMarchaAnguloRetropie",
  ASBAVelocidadMarcha = "ASBAVelocidadMarcha",
  ASBACarreraAnguloRetropie = "ASBACarreraAnguloRetropie",
  ASBAVelocidadCarrera = "ASBAVelocidadCarrera",
  ASBADiferenciaAngulos = "ASBADiferenciaAngulos",
  ASBARotacionPies = "ASBARotacionPies",
  ASBABasculacionPelvis = "ASBABasculacionPelvis",
  ASBATiempoContacto = "ASBATiempoContacto",
}

export const FootTestFields: { [key in FootTestFieldEnum]: TestField } = {
  DATipoPiesFalanges: {
    type: "select",
    title: "Pies según las falanges",
    values: ["Celta", "Griego", "Romano", "Egipcio", "Germano"],
    goodValues: [0],
    leftRight: true,
  },
  DATipoFalanges: {
    type: "select",
    title: "Tipo de falanges",
    values: ["Garra", "Martillo", "Maza"],
    goodValues: [0],
    leftRight: true,
  },
  DAHalluxValgus: {
    type: "text",
    title: "Hallux Valgus",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  VAIAnguloArcoLongitudinal: {
    type: "text",
    title: "AAL - Ángulo Arco Longitudinal",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  VAINavicularDrop: {
    type: "text",
    title: "ND - Navicular Drop (cm)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  VAIAlturaDorsoPie: {
    type: "text",
    title: "Altura del dorso del pie (cm)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  VAIArticulacionMetatarsoFalangica: {
    type: "text",
    title: "1ª Metatarso-falángica (grados)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ARAnguloAntepie: {
    type: "text",
    title: "Ángulo del Antepié",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ARAnguloRetropie: {
    type: "text",
    title: "Ángulo del Retropié",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  PHCContinuidadHuella: {
    type: "select",
    title: "Continuidad de la huella",
    values: ["Sí", "No"],
    goodValues: [0],
    leftRight: true,
  },
  PHCFaltaImpresionFalanges: {
    type: "select",
    title: "Falta impresión de falanges",
    values: ["Sí", "No"],
    goodValues: [0],
    leftRight: true,
  },
  PHCResultadoX: {
    type: "text",
    title: "Resultado X",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  PHCResultadoY: {
    type: "text",
    title: "Resultado Y",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  PHCResultadoPer: {
    type: "text",
    title: "Resultado %",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  PHCTipoPie: {
    type: "text",
    title: "Tipo de pie (resultado)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASAAnguloTibia: {
    type: "text",
    title: "Ángulo de la tibia",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASAAnguloTronco: {
    type: "text",
    title: "Ángulo del tronco",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASADiferenciaAngulos: {
    type: "text",
    title: "Diferencia entre ángulos",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASADistanciaRodillaPuntaPie: {
    type: "text",
    title: "Distancia Rodilla - Punta del Pie",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },

  ASBAEstaticoAnguloRetropie: {
    type: "text",
    title: "Estático - Ángulo del Retropié",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBAMarchaAnguloRetropie: {
    type: "text",
    title: "Marcha - Ángulo del Retropié",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBAVelocidadMarcha: {
    type: "text",
    title: "Velocidad de la marcha",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBACarreraAnguloRetropie: {
    type: "text",
    title: "Carrera - Ángulo del Retropié",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBAVelocidadCarrera: {
    type: "text",
    title: "Velocidad de la carrera",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBADiferenciaAngulos: {
    type: "text",
    title: "Diferencia entre ángulos",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBARotacionPies: {
    type: "select",
    title: "Rotación de pies",
    values: ["Neutro", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBABasculacionPelvis: {
    type: "text",
    title: "Basculación de la pelvis (cm)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
  ASBATiempoContacto: {
    type: "text",
    title: "Tiempo de contacto (ms)",
    values: ["Neutra", "Izquierda", "Derecha"],
    goodValues: [0],
    leftRight: true,
  },
};

export const FootArchitecture = [
  {
    title: "Descripción Anatómica",
    learnId: "VAFFootDescripcionAnatomica",
    learnURL:
      "https://manual.intecc.org/es/article/descripcion-anatomica-del-pie-tobillo",
    id: "da",
    fields: [
      FootTestFieldEnum.DATipoPiesFalanges,
      FootTestFieldEnum.DATipoFalanges,
      FootTestFieldEnum.DAHalluxValgus,
    ],
    images: [
      TestImagesEnum.FootPlanoFrontalAnteriorNeutro,
      TestImagesEnum.FootHalluxValgus,
    ],
    commentsPropName: "daComments",
  },
  {
    title: "Valoración del arco interno",
    id: "vai",
    learnId: "VAFFootValoracionArcoInterno",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-del-arco-interno-del-pie",
    fields: [
      FootTestFieldEnum.VAIAnguloArcoLongitudinal,
      FootTestFieldEnum.VAINavicularDrop,
      FootTestFieldEnum.VAIAlturaDorsoPie,
      FootTestFieldEnum.VAIArticulacionMetatarsoFalangica,
    ],
    images: [
      TestImagesEnum.FootPlanoSagitalIzquierdo,
      TestImagesEnum.FootPlanoSagitalDerecho,
    ],
    commentsPropName: "vaiComments",
  },
  {
    title: "Antepié - Retropié",
    id: "ar",
    learnId: "VAFFootAntepieRetropie",
    learnURL:
      "https://manual.intecc.org/es/article/angulos-del-antepie-y-retropie",
    fields: [
      FootTestFieldEnum.ARAnguloAntepie,
      FootTestFieldEnum.ARAnguloRetropie,
    ],
    images: [
      TestImagesEnum.FootPlanoFrontalAnteriorRelajado,
      TestImagesEnum.FootPlanoFrontalPosteriorRelajado,
    ],
    commentsPropName: "arComments",
  },
  {
    title: "Articulación supra-astragalina",
    id: "asa",
    learnId: "VAFFootArticulacionSupraAstra",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-de-la-articulacion-supra-astragalina",
    fields: [
      FootTestFieldEnum.ASAAnguloTibia,
      FootTestFieldEnum.ASAAnguloTronco,
      FootTestFieldEnum.ASADiferenciaAngulos,
      FootTestFieldEnum.ASADistanciaRodillaPuntaPie,
    ],
    images: [
      TestImagesEnum.FootTripleFlexionDeTobillo,
      TestImagesEnum.FootTripleFlexionDeTobilloPosterior,
    ],
    commentsPropName: "asaComments",
  },
  {
    title: "Articulación sub-astragalina",
    id: "asba",
    learnId: "VAFFootArticulacionSubAstra",
    learnURL:
      "https://manual.intecc.org/es/article/valoracion-de-la-articulacion-sub-astragalina",
    fields: [
      FootTestFieldEnum.ASBAEstaticoAnguloRetropie,
      FootTestFieldEnum.ASBAMarchaAnguloRetropie,
      FootTestFieldEnum.ASBAVelocidadMarcha,
      FootTestFieldEnum.ASBACarreraAnguloRetropie,
      FootTestFieldEnum.ASBAVelocidadCarrera,
      FootTestFieldEnum.ASBADiferenciaAngulos,
      FootTestFieldEnum.ASBARotacionPies,
      FootTestFieldEnum.ASBABasculacionPelvis,
      FootTestFieldEnum.ASBATiempoContacto,
    ],
    images: [
      TestImagesEnum.FootCarreraPlanoFrontalPosterior,
      TestImagesEnum.FootCarreraPlanoSagital,
    ],
    commentsPropName: "asbaComments",
  },
  {
    title: "Protocolo Hernández Corvo",
    id: "phc",
    learnId: "VAFFootProtocoloHernandezCorvo",
    learnURL:
      "https://manual.intecc.org/es/article/protocolo-de-hernandez-corvo",
    fields: [
      FootTestFieldEnum.PHCContinuidadHuella,
      FootTestFieldEnum.PHCFaltaImpresionFalanges,
      FootTestFieldEnum.PHCResultadoX,
      FootTestFieldEnum.PHCResultadoY,
      FootTestFieldEnum.PHCResultadoPer,
      FootTestFieldEnum.PHCTipoPie,
    ],
    images: [
      TestImagesEnum.FootHuellaPlantarIzquierda,
      TestImagesEnum.FootHuellaPlantarDerecha,
    ],
    commentsPropName: "phcComments",
  },
  // {
  //     title: 'Imágenes',
  //     id: 'images',
  //     images: [
  //         TestImagesEnum.PlanoFrontalAnteriorRelajado,
  //         TestImagesEnum.PlanoFrontalPosteriorRelajado,
  //     ]
  // },
  {
    title: "Recomendaciones y enlaces",
    id: "linksAndComments",
    learnId: "VAFRecomendacionesEnlaces",
    learnURL:
      "https://manual.intecc.org/es/article/comentarios-y-recomendaciones",
    commentsPropName: "generalComments",
  },
];

export const createEmptyFootTest = () => {
  const result: FootTest | any = {
    type: "foot" as TestType,
    owner: "",
    client: "",
    results: {},
    images: {},
    phc: true,
    asba: true,
  };
  return result;
};

// export const getValueText = (test: PostureTest, field: FootTestFieldEnum) => {
//     if(!test.results){
//         return null;
//     }
//     if(!test.results[field]){
//         return null;
//     }
//     const value = test.results[field];
//     if(value < 0) {
//         return null;
//     }
//     const fieldInfo = PostureTestFields[field];
//     if(value >= fieldInfo.values.length) {
//         return null;
//     }
//     const result = fieldInfo.values[value];
//     return result;
// }

export const getFootFieldInfo = (field: FootTestFieldEnum) => {
  return FootTestFields[field];
};

export const getValueForReport = (field: FootTestFieldEnum, test: FootTest) => {
  const info = getFootFieldInfo(field);
  const values = info.values;
  const rawValue = test.results[field];
  if (typeof rawValue !== "number") {
    return "-";
  }
  if (rawValue === undefined || rawValue < 0 || rawValue >= values.length) {
    return "-";
  }
  const value = values[rawValue];
  return value;
};
