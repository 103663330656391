import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Modal } from 'src/app/models/modal.model';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { first, map, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { Price, ProductIds } from 'src/shared/subscriptions.shared';
import { LoadingState } from 'src/app/utils/utils';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss'],
})
export class AccountModalComponent implements OnInit, Modal, OnDestroy {
  onClose = new Subject<any>();
  @Input()
  data: any;

  user: User;

  subInfo: Price;

  subs: Array<Subscription> = [];

  billingPortalUrlLoadingState: LoadingState = 'idle';
  billingPortalUrl: string;

  historyBackClose() {
    this.billingPortalUrl = null;
    this.billingPortalUrlLoadingState = 'idle';
    this.onClose.next('');
  }

  async close() {
    this.billingPortalUrl = null;
    this.billingPortalUrlLoadingState = 'idle';
    history.back();
  }

  constructor(
    public authService: AuthService,
    private modalsService: ModalService,
    private confirm: ConfirmActionDialogService,
    public subService: SubscriptionsService,
  ) {}

  ngOnInit() {
    this.billingPortalUrlLoadingState = 'loading';
    this.subs.push(
      this.subService.currentUserCustomerPortalUrl$.subscribe((url) => {
        this.billingPortalUrl = url;
        this.billingPortalUrlLoadingState = 'idle';
      }),
    );

    this.subService.currentUserSubscription$.subscribe((c) => {
      if (!c) {
        return;
      }
      const { product, originalProduct } = c;
      this.subInfo = {
        id: originalProduct.id,
        name: product.name,
        pricing: product.price + product.billingPeriod,
        productIds: [originalProduct.id],
      };
    });

    this.authService.user$
      .pipe(
        first(),
        tap((user) => {
          this.loadInfo(user);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.billingPortalUrl = null;
    this.billingPortalUrlLoadingState = 'idle';
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  async loadInfo(user: User) {
    this.user = user;
    const inteccSubscription = await firstValueFrom(
      this.subService.currentUserSubscription$.pipe(
        map((c) => !!c?.subscription),
      ),
    );
    if (inteccSubscription) {
      return; // is a modern subscription, don't load the old one
    }
    if (this.subInfo) {
      return; // if the subInfo comes from the portalAndSubInfo call, we don't need to make another call
    }

    const result = await this.subService.getCurrentAppSubscriptions();
    if (!result) {
      this.subInfo = null;
    } else {
      this.subInfo = result[0];
    }
  }

  async cancelCurrentSub() {
    await this.modalsService.openModal('cancel-sub');
    this.close();
  }

  confirmCancelCurrentSub() {
    this.confirm.openDialog({
      title: '¿Cancelar suscripción?',
      description:
        'Si cancelas tu suscripción perderás el acceso a gran parte de las funcionalidades de Raquis.',
      confirmButton: 'Cancelar suscripción',
      confirmCallback: (result) => {
        if (result) {
          this.cancelCurrentSub();
        }
      },
    });
  }

  async logout() {
    this.close();
    this.authService.signOut();
  }

  isPremium(subInfo: Price) {
    if (!subInfo) {
      return false;
    }
    return subInfo.productIds.some(
      (pId) =>
        pId === ProductIds.RomPremium || pId === ProductIds.RaquisPremium,
    );
  }

  async openUpgradeModal() {
    await this.modalsService.openModal('new-upgrade');
    this.close();
  }
}
