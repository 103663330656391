<div class="modal">
  <div class="modal__client-img">
    <p class="modal__client-img__text">
      {{ data.client ? clientsService.getThumbnailText(data.client) : '--' }}
    </p>
  </div>
  <div class="modal__client-tag">
    <app-tag-selector
      [selectedTagId]="data.client.tagId"
      (selectedTagChange)="data.client.tagId = $event"
    ></app-tag-selector>
  </div>
  <div class="modal__form-container">
    <form
      autocomplete="off"
      class="form form--two-columns px-4"
      [formGroup]="editClientForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="mb-4">
        <h2 class="text-xl font-bold uppercase">DATOS PERSONALES</h2>
        <p class="text-sm text-neutral-600">
          Datos básicos y enlaces compartidos con el cliente
        </p>
      </div>

      <div class="grid-cols-2 gap-x-4 py-2 sm:grid">
        <div class="form__field">
          <label class="form__label" for="name">Nombre</label>
          <input
            class="form__input"
            type="text"
            id="name"
            formControlName="name"
          />
        </div>
        <div class="form__field">
          <label class="form__label" for="surname">Apellidos</label>
          <input
            class="form__input"
            type="text"
            id="surname"
            formControlName="surname"
          />
        </div>
        <div class="form__field">
          <label class="form__label" for="birthDate">Fecha de nacimiento</label>
          <input
            (click)="picker.open()"
            class="form__input"
            type="text"
            id="birthDate"
            formControlName="birthDate"
            matInput
            [matDatepicker]="picker"
          />
          <!-- <input style="display: none;" (dateChange)="onDateChanged($event);" matInput [matDatepicker]="picker"
                    placeholder="Choose a date"> -->
          <mat-datepicker touchUi #picker></mat-datepicker>
        </div>
        <div class="form__field">
          <label class="form__label" for="dni">DNI</label>
          <input
            class="form__input"
            type="text"
            id="dni"
            formControlName="dni"
          />
        </div>

        <div class="form__field">
          <label class="form__label" for="email">Correo electrónico</label>
          <input
            class="form__input"
            type="text"
            id="email"
            formControlName="email"
          />
        </div>
        <div class="form__field">
          <label class="form__label" for="phone">Número de teléfono</label>
          <input
            class="form__input"
            type="text"
            id="phone"
            formControlName="phone"
          />
        </div>
        <!-- <div class="form__field">
                    <label class="form__label" for="birthDate">Fecha de nacimiento</label>
                    <input class="form__input" type="text" id="birthDate" formControlName="birthDate">
                </div> -->
        <div class="form__field">
          <label class="form__label" for="job">Profesión</label>
          <input
            class="form__input"
            type="text"
            id="job"
            formControlName="job"
          />
        </div>
        <div class="form__field">
          <label
            [matTooltip]="
              'Marca la casilla para que se muestre el enlace de los documentos en la app del cliente'
            "
            #sharedFolderToolTip="matTooltip"
            (click)="sharedFolderToolTip.toggle(); $event.stopPropagation()"
            class="form__label flex items-center gap-x-1"
            for="sharedFolderUrl"
          >
            <div class="-my-5">
              <app-checkbox
                formControlName="displaySharedFolderUrl"
                [inputId]="'displaySharedFolderUrl'"
              />
            </div>
            Url documentos</label
          >
          <input
            class="form__input"
            type="text"
            id="sharedFolderUrl"
            formControlName="sharedFolderUrl"
          />
        </div>

        <div class="form__field">
          <label
            [matTooltip]="
              'Marca la casilla para que se muestre el enlace del cuestionario en la app del cliente'
            "
            #questionnaireToolTip="matTooltip"
            (click)="questionnaireToolTip.toggle(); $event.stopPropagation()"
            class="form__label flex items-center gap-x-1"
            for="questionnaireUrl"
          >
            <div class="-my-5">
              <app-checkbox
                formControlName="displayQuestionnaireUrl"
                [inputId]="'displayQuestionnaireUrl'"
              />
            </div>
            Url cuestionario
          </label>
          <input
            class="form__input"
            type="text"
            id="questionnaireUrl"
            formControlName="questionnaireUrl"
          />
        </div>

        <div class="form__field">
          <label
            [matTooltip]="
              'Marca la casilla para que se muestre el enlace de la aceptación en la app del cliente'
            "
            #acceptanceToolTip="matTooltip"
            (click)="acceptanceToolTip.toggle(); $event.stopPropagation()"
            class="form__label flex items-center gap-x-1"
            for="acceptanceUrl"
          >
            <div class="-my-5">
              <app-checkbox
                formControlName="displayAcceptanceUrl"
                [inputId]="'displayAcceptanceUrl'"
              />
            </div>
            Url aceptación
          </label>
          <input
            class="form__input"
            type="text"
            id="acceptanceUrl"
            formControlName="acceptanceUrl"
          />
        </div>
      </div>

      <div class="form__actions">
        <button
          class="button-cta-2"
          [disabled]="!editClientForm.valid || submiting"
          type="submit"
        >
          {{ submiting ? 'Guardando...' : 'Guardar' }}
        </button>
      </div>
    </form>
    <button class="cancel-button button-plain-2" (click)="close()">
      Cancelar
    </button>
  </div>
</div>
