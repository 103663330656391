import { TestReportLink } from "./tests.model";

export const CORRECTIVESESSIONPROTOCOLDEF_PATH = "corrective-session-protocols";

export const CLIENT_CORRECTIVE_SESSIONS_PATH = "client-corrective-sessions";

export interface CorrectiveSessionProtocolDef {
  id?: string;
  description: string;
  name: string;
  fixedImages?: Array<CorrectiveSessionFixedImage>;
  owner?: string;
  sections: Array<CorrectiveSessionSectionsDef>;
  lastLocalId: number;
  lastLocalImageId: number;
  createdAt?: any;
  updatedAt?: Date;
  copiedFromId?: string; // if this protocol was copied from another proffesional
}

export interface CorrectiveSessionSectionsDef {
  localId?: number;
  name?: string; // section name
  description?: string; // section description
  /**
   * always for "Series" "Volumen" "Intensidad" "Densidad" and 1 extra for anything
   * The order in the array would be [
   * IMPORTANT: Decir que esto es inamovible, no se puede cambiar el orden
   * "Series",
   * "Volumen" (repeticiones, tiempo...),
   * "Intensidad" (peso?),
   * "Descanso series",
   * "Descanso ejercicio",
   * "Extra"
   * ]
   */
  fields?: Array<CorrectiveSessionFieldDef>;
  /** NOTE: tableRowFields is the field used for old version */
  tableRowFields?: Array<CorrectiveSessionFieldDef>;
  sectionExercises?: Array<SessionExercise>; // if these exists, they'll take precedence over 'tableRowFields'
  tableFirstColTitle?: string; // always "Exercises"
  images?: Array<CorrectiveSessionImageDef>;
  fixedImages?: Array<CorrectiveSessionFixedImage>;
  lastLocalId?: number;
  lastLocalImageId?: number;
  mode?: "table"; // always 'table' for corrective sessions section
  tableMode?: "xy"; // always 'xy' for corrective sessions section ('tableRowFields' are the columns)
}

export type CorrectiveSessionFieldType = "select" | "text" | "number" | "time";

export interface CorrectiveSessionFieldDef {
  //las columnas que no son la primera
  localId: number;
  title: string; // el nombre de la columna
  type: CorrectiveSessionFieldType;
  leftRight: boolean; // not used so far, would be for unilateral exercises
  options: Array<string>;
  optionsString: string;
}

export interface SessionExercise {
  key: string;
  exerciseId: string;
  // type: "text"; // should not be needed, but keep for clarity when dealing with this in other places if it is still used

  /** the position of the exercise image in PDF if exists */
  position: "first" | "second";
}

export interface CorrectiveSessionImageDef {
  localId: number;
  key: string;
  name: string;
  position: "first" | "second";
}

export interface CorrectiveSessionFixedImage {
  localId: number;
  key: string;
  title: string;
  downloadUrl: string;
  position: "first" | "second";
  link: string;
}

// type for client corrective sessions, not definition
export interface ClientCorrectiveSession {
  id?: string;
  type: "corrective-session";
  owner: string;
  client: string;
  showToClient?: boolean;
  createdAt?: any;
  updatedAt?: Date;
  correctiveSessionProtocolDefId: string;
  /**
   * will be 1 or 0? if no circuit, more if circuit. The index will be the same as the section index
   */
  roundsBySection?: Array<number>;
  /**
   * duration in seconds for each section, the index will be the same as the section index
   */
  durationBySection?: Array<number>;
  links?: Array<TestReportLink>;
  tips?: string;
}

export const createEmptyClientCorrectiveSession = () => {
  const result: ClientCorrectiveSession = {
    owner: "",
    client: "",
    type: "corrective-session",
    correctiveSessionProtocolDefId: undefined,
  };
  return result;
};

export const getCorrectiveSessionProtocolInitials = (
  correctiveSessionProtocol: CorrectiveSessionProtocolDef
) => {
  const name = correctiveSessionProtocol.name.toUpperCase();
  if (!name || name === "") {
    return "--";
  }
  const splittedName = name.split(" ");
  if (splittedName.length > 1) {
    return `${splittedName[0][0]}${splittedName[1][0]}`;
  } else {
    return `${name[0]}${name.length > 1 ? name[1] : name[0]}`;
  }
};

export const optionsStringToOptionsArray = (
  protocol: CorrectiveSessionProtocolDef
) => {
  if (!protocol.sections) {
    return;
  }
  protocol.sections.forEach((section) => {
    if (!section.fields) {
      return;
    }
    section.fields
      .filter((f) => f.type === "select")
      .forEach((f) => {
        if (!f.optionsString) {
          f.options = [];
          return;
        }
        let splitted = f.optionsString.split(",");
        splitted = splitted.filter((s) => !!s);
        f.options = splitted;
      });
  });
};

export const calculateImageKey = (
  protocolId: string,
  testLocalId: number,
  imageLocalId: number
) => {
  return `corrective-session-${protocolId}-${testLocalId}-${imageLocalId}`;
};

export const getCommentsClientCorrectiveSessionSection = (
  sectionDef: CorrectiveSessionSectionsDef,
  test
) => {
  const result = test[`comments-${sectionDef.localId}`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const getTipsClientCorrectiveSession = (test) => {
  const result = test[`tips`];
  if (result) {
    return result;
  } else {
    return "";
  }
};

export const setCommentsClientCorrectiveSessionSection = (
  sectionDef: CorrectiveSessionSectionsDef,
  test,
  value
) => {
  test[`comments-${sectionDef.localId}`] = value;
};

export const setTipsClientCorrectiveSession = (test, value) => {
  test[`tips`] = value;
};

// NOTE:IMPORTANT: all of this functions bellow could be reusable for training-sessions, copy the function for its file. In reports.ts I'll have to refactor to extract logic so there is different functions for each type of session, even if they are equal now
export const getValueForExercisesReportTable = (
  clientSession: ClientCorrectiveSession,
  sectionLocalId: number,
  fieldLocalId: number,
  exerciseKey: string
) => {
  const key = `${sectionLocalId}-${fieldLocalId}-${exerciseKey}`;
  const value = clientSession[key];
  return value;
};

export const formatTime = (timeInSeconds: number) => {
  if (timeInSeconds === undefined) {
    return undefined;
  }
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const getTimeValueForExerciseReportTable = (
  clientSession: ClientCorrectiveSession,
  sectionLocalId: number,
  fieldLocalId: number,
  exerciseKey: string
) => {
  const value = getValueForExercisesReportTable(
    clientSession,
    sectionLocalId,
    fieldLocalId,
    exerciseKey
  );
  return value ? formatTime(value) : "-";
};

export const getRoundsBySection = (
  clientSession: ClientCorrectiveSession,
  sectionIndex: number
) => {
  if (!clientSession.roundsBySection) {
    return 1;
  }
  return clientSession.roundsBySection[sectionIndex];
};

export const getDurationBySection = (
  clientSession: ClientCorrectiveSession,
  sectionIndex: number
) => {
  if (!clientSession.durationBySection) {
    return formatTime(0);
  }
  const duration = clientSession.durationBySection[sectionIndex];
  return formatTime(duration);
};
