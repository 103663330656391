<div class="app-container" [class.app-container--open]="_open">
  <div class="box">
    <div class="box__heading">
      <button
        *ngIf="!minimalMode"
        (click)="openLearnGif()"
        class="box__learn-button button-icon-circular"
      >
        <i class="fas fa-photo-video"></i>
      </button>
      <div *ngIf="minimalMode" class=""></div>
      <div class="box__title-container">
        <h3 class="box__title">{{ infoText }}</h3>
      </div>
      <button (click)="cancel()" class="box__close-button button-close-dialog">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="box__content">
      <div class="box__result-container">
        <input
          autocomplete="off"
          #resultTextInput
          type="text"
          name="result"
          [(ngModel)]="result"
        />
      </div>
      <div class="box__actions-container">
        <button
          *ngIf="!inclinometerActive"
          class="inclinometer-button"
          (click)="startInclinometer()"
        >
          <i class="fas fa-play"></i> Medir
        </button>
        <button
          *ngIf="inclinometerActive"
          class="inclinometer-button"
          (click)="stopInclinometer()"
        >
          <i class="fas fa-stop"></i> Parar
        </button>
      </div>
      <!-- <button (click)="requestPermissionIOS()" class="test-button">
        <i class="fas fa-exclamation-triangle"></i>
      </button> -->
      <div
        (click)="requestPermissionIOS()"
        *ngIf="showIOSWarning"
        class="iosWarning animated fadeIn"
      >
        <div class="iosWarning__icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <div class="iosWarning__info">
          <div class="iosWarning__title">
            Tu iPhone/iPad está bloqueando el acceso a los sensores de
            movimiento.
          </div>
          <div class="iosWarning__cta">Haz click aquí para solucionarlo</div>
        </div>
      </div>
      <button
        *ngIf="showNext"
        (click)="saveAndNext()"
        class="box__save-button button-cta-2"
      >
        Guardar y seguir
      </button>
      <button
        *ngIf="minimalMode"
        (click)="saveAndClose()"
        class="box__save-button button-cta-2"
      >
        Cerrar
      </button>
      <button
        *ngIf="!minimalMode"
        (click)="saveAndClose()"
        class="box__save-button button-cta-2 button-cta-2--variant-1"
      >
        Guardar y cerrar
      </button>
    </div>

    <div
      (click)="closeLearnGif()"
      class="gif-box"
      [class.gif-box--open]="showGif"
    >
      <div class="gif-box__gif-container">
        <img class="gif-box__gif" src="{{ LearnGifs[learnGifId] }}" alt="" />
      </div>
      <!-- <button (click)="closeLearnGif()" class="gif-box__close-button button-icon-circular button-icon-circular--white button-icon-circular--big"><i class="fas fa-times"></i></button> -->
      <div class="gif-box__banner">
        <p class="gif-box__banner__text">Pulsa para cerrar</p>
      </div>
    </div>
  </div>
</div>
