import { SagitalInclinometerLearnData } from '../models/learn/sagital-inclinometer-learn-data.model';
import { VafLearnData } from '../models/learn/vaf-learn-data.model';

export const BipSagitalInclinometerLearnData: SagitalInclinometerLearnData = {
  title: 'Bipedestación',
  position: [
    'En bipedestación (de pie)',
    'Brazos junto al tronco',
    'Mirada al frente',
    'Postura cómoda y relajada',
    'Pies a la anchura de las caderas',
    'Similar apoyo sobre ambos pies',
    'Rodillas extendidas',
  ],
  normalValues: [
    {
      sectionName: 'Dorsal',
      names: ['Inversión', 'Hipocifosis', 'Normalidad', 'Hipercifosis'],
      values: ['0º', '20º', '40º'],
      results: [2, 1, 0, 1],
    },
    {
      sectionName: 'Lumbar',
      names: ['Inversión', 'Hipolordosis', 'Normalidad', 'Hiperlordosis'],
      values: ['0º', '20º', '40º'],
      results: [2, 1, 0, 1],
    },
    {
      sectionName: 'Pelvis',
      names: ['Retroversión', 'Normalidad', 'Anteversión'],
      values: ['10º', '30º'],
      results: [1, 0, 1],
    },
  ],
  videos: [
    {
      name: 'Valoración de la cifosis dorsal y lordosis lumbar',
      vimeoId: '368245349',
      vimeoImgUrl: 'https://i.vimeocdn.com/video/824869253.webp',
    },
    {
      name: 'Valoración de la versión de la pelvis (S-V)',
      vimeoId: '368508120',
      vimeoImgUrl: 'https://i.vimeocdn.com/video/825200636.webp',
    },
  ],
  gifs: [
    { name: 'Valoración de la cifosis dorsal', gifId: 'gif-bip-dorsal-movil' },
    { name: 'Valoración de la lordosis lumbar', gifId: 'gif-bip-lumbar-movil' },
    {
      name: 'Valoración de la versión pélvica (S-V)',
      gifId: 'gif-bip-pelvis-movil',
    },
  ],
  manualURL: [
    'https://manual.intecc.org/es/article/valoracion-en-bipedestacion',
  ],
};

export const MASagitalInclinometerLearnData: SagitalInclinometerLearnData = {
  title: 'Maniobra de Autocorrección',
  position: [
    'En bipedestación (de pie), con los brazos junto al tronco y con la mirada al frente',
    // eslint-disable-next-line max-len
    'Se invita a que realice el máximo “auto-elongamiento” de su columna vertebral (estirarse hacia arriba al máximo sin incrementar la lordosis lumbar)',
    'Hay que mantener el raquis lo más estirado (rectificado) posible.',
  ],
  normalValues: [
    // eslint-disable-next-line max-len
    {
      sectionName: 'Dorsal',
      names: [
        'Act. Cifótica',
        'Tendencia a la estructuración',
        'Cifosis estructurada',
      ],
      values: ['30º', '40º'],
      results: [1, 0, 1],
    },
  ],
  videos: [{ name: 'Maniobra de Autocorrección', vimeoId: '368504308' }],
  gifs: [
    { name: 'Maniobra de Autocorrección', gifId: 'gif-bip-ma-inclinometro' },
  ],
  manualURL: [
    'https://manual.intecc.org/es/article/maniobra-de-autocorreccion',
  ],
};

export const SedSagitalInclinometerLearnData: SagitalInclinometerLearnData = {
  title: 'Sedentación',
  position: [
    'En Sedentación (sentado) Asténica (relajado)',
    'Antebrazos sobre los muslos',
    'Mirada al frente',
    'Postura relajada',
  ],
  normalValues: [
    {
      sectionName: 'Cifosis Dorsal',
      names: ['Inversión', 'Hipocifosis', 'Normalidad', 'Hipercifosis'],
      values: ['0º', '20º', '40º'],
      results: [2, 1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Curva Lumbar',
      names: ['Hiperlordosis', 'Normalidad', 'Actitud Cifótica'],
      values: ['-15º', '15º'],
      results: [1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Versión de la pelvis',
      names: ['Anteversión', 'Normalidad', 'Retroversión'],
      values: ['80º', '100º'],
      results: [1, 0, 1],
    },
  ],
  videos: [
    {
      name: 'Valoración de la cifosis dorsal y curva lumbar',
      vimeoId: '368514668',
    },
    { name: 'Valoración de la versión pélvica (L-H SA)', vimeoId: '368520389' },
  ],
  gifs: [
    { name: 'Valoración de la cifosis dorsal', gifId: 'gif-sa-dorsal-movil' },
    { name: 'Valoración de la curva lumbar', gifId: 'gif-sa-lumbar-movil' },
    {
      name: 'Valoración de la versión pélvica (L-H SA)',
      gifId: 'gif-sa-pelvis-movil',
    },
  ],
  manualURL: ['https://manual.intecc.org/es/article/valoracion-en-sedentacion'],
};

export const FlexSagitalInclinometerLearnData: SagitalInclinometerLearnData = {
  title: 'Flexión Máxima de Tronco',
  position: [
    'Sentado sobre una superficie dura (suelo o camilla)',
    'Rodillas extendidas',
    'Tobillos con una flexión a 90º',
    'Se le pedirá que realice una Flexión Máxima del Tronco que pueda mantener mientras se realizan las mediciones de la espalda',
  ],
  normalValues: [
    {
      sectionName: 'Dorsal',
      names: ['Lordótico', 'Hipocifosis', 'Normalidad', 'Hipercifosis'],
      values: ['0º', '40º', '65º'],
      results: [2, 1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Lumbar',
      names: ['Lordótico', 'Rectificado', 'Normalidad', 'Cifótico'],
      values: ['0º', '10º', '30º'],
      results: [2, 1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Pelvis',
      names: ['Normalidad', 'Cortedad G-I', 'Cortedad G-II'],
      values: ['100º', '115º'],
      results: [0, 1, 2],
    },
  ],
  videos: [
    {
      name: 'Valoración de la cifosis dorsal y curva lumbar',
      vimeoId: '368636460',
    },
    {
      name: 'Valoración de la flexión máxima de la pelvis (L-H Fx)',
      vimeoId: '368644091',
    },
  ],
  gifs: [
    { name: 'Valoración de la cifosis dorsal', gifId: 'gif-flex-dorsal-movil' },
    { name: 'Valoración de la curva lumbar', gifId: 'gif-flex-lumbar-movil' },
    {
      name: 'Valoración de la flexión máxima de la pelvis (L-H Fx)',
      gifId: 'gif-flex-pelvis-movil',
    },
  ],
  manualURL: [
    'https://manual.intecc.org/es/article/valoracion-en-flexion-maxima',
  ],
};

export const SagitalFlechasTestLearnData: SagitalInclinometerLearnData = {
  title: 'Flechas Raquídeas',
  position: [],
  normalValues: [
    // eslint-disable-next-line max-len
    {
      sectionName: 'Índice Cifótico',
      names: ['Hipocifosis', 'Normalidad', 'Hipercifosis'],
      values: ['20', '65'],
      results: [1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Índice Cifótico Autocorregido',
      names: ['Actitud Cifótica', 'Normalidad', 'Posible estructuración'],
      values: ['35', '50'],
      results: [1, 0, 1],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Índice Lordótico',
      names: ['Hipolordosis', 'Normalidad', 'Hiperlordosis'],
      values: ['20', '40'],
      results: [1, 0, 1],
    },
  ],
  videos: [
    { name: 'Flechas Sagitales', vimeoId: '387665223' },
    { name: 'Flechas Sagitales - Autocorrección', vimeoId: '387667887' },
  ],
  gifs: [],
};

export const TobilloRomLearnData: SagitalInclinometerLearnData = {
  title: 'Tobillo',
  position: [],
  normalValues: [
    // eslint-disable-next-line max-len
    {
      sectionName: 'Flexión Dorsal - Gemelo',
      names: ['Limitado', 'Normal', 'Óptimo'],
      values: ['25', '30'],
      results: [1, 0, 3],
    },
    // eslint-disable-next-line max-len
    {
      sectionName: 'Flexión Dorsal - Sóleo',
      names: ['Limitado', 'Normal', 'Óptimo'],
      values: ['35', '40'],
      results: [1, 0, 3],
    },
  ],
  videos: [
    { name: 'Flexión Dorsal - Gemelo', vimeoId: '387665223' },
    { name: 'Flexión Dorsal - Sóleo', vimeoId: '387667887' },
  ],
  gifs: [],
};

export const RaquisMorfotipo: VafLearnData = {
  markdown: `
# VAF RAQUIS - MORFOTIPO (MSI)
---
## Enlaces
[Publicación en PDF](https://drive.google.com/file/d/1tc9StFpa9AdYP9pHKizS86D74KKDSU8k/view)

[Publiación en la WEB](https://www.valoracionfuncional.com/06-valoracion-del-raquis)

## Introducción
Una evaluación exclusiva del raquis debería incluir y valorar tanto la articulación de la cadera
(predominio y ratio de flexores-extensores, rotadores y aductores-abductores) como la
basculación pélvica (anteversión y retroversión), especialmente mediante los test Dedos-Suelo
y Dedos-Planta ya que tendrán una relación directa sobre la estructura raquídea y su movilidad.
Además, dentro de dicha valoración raquídea debemos incluir tanto la valoración en el plano
frontal (posibles escoliosis) como las valoraciones en el plano sagital.

Debemos ser conscientes de la importancia que tienen estas valoraciones especialmente en
etapas o categorías de crecimiento ya que sería el momento óptimo para poder detectar los
morfotipos raquídeos y **evitar problemas estructurales en el futuro adulto**.

El morfotipo raquídeo, tanto en estático como en dinámico, nos indicará la normalidad o
anormalidad de dicha estructura. En caso de encontrar una desalineación raquídea (por
ejemplo, un morfotipo escoliótico) debemos diferenciar si se trata de una desalineación
actitudinal (el sujeto puede reequilibrar su raquis conscientemente) o estructural (será incapaz
de mantener una posición de neutralidad de forma consciente ya que habrá una estructuración
ósea que se lo impide).

**El inclinómetro** es el instrumento básico para la medición del morfotipo raquídeo sagital clásico,
así como para el integral. El inclinómetro se ha validado para las mediciones de las curvaturas
tanto torácicas como lumbares, frente al patrón oro como es la radiografía lateral de la columna
vertebral, midiendo las curvas sagitales mediante el método de Cobb. Los estudios de validación
más recientes, los podemos dividir en función del segmento que estudian. Para la cifosis dorsal,
varios estudios muestran un coeficiente de correlación intraclase (ICC) intraobservador de 0.94-
0.98 con una validez de r=0.62-0.85 frente a la radiografía lateral. Para la curvatura lumbar,
encontramos resultados más variados, con fiabilidad medida con el ICC= 0.96 intraobservador y
0.88-0.94 interobservador, y una validez que oscila desde r=0.32-0.80.

**La exploración del morfotipo raquídeo sagital integral** descrito por Santonja en 1996, consiste
en una evaluación más completa de la disposición sagital de las curvas torácica y lumbar, al
entender, que la clásica valoración exclusiva en bipedestación no traduce la mayor parte de las
posturas que solemos adoptar a lo largo del día y que pueden influir en el morfotipo dorsal y
lumbar. Por ello, las curvaturas se evalúan en tres posiciones distintas: bipedestación,
sedentación asténica y flexión máxima del tronco. Se cuantifican con un inclinómetro, al
proporcionar una buena reproducibilidad, fiabilidad y correlación con la medición radiográfica.
Cuando el profesional haya realizado dos valoraciones (bipedestación con sedentación asténica o bipedestación
con flexión máxima de tronco) aparecerá el diagnóstico y las recomendaciones para el cliente.
`,
  videos: [{ vimeoId: '388598739' }],
};

export const RaquisFlechas: VafLearnData = {
  markdown: `
# VAF RAQUIS - FLECHAS (FS)
---
## Enlaces
[Publicación en PDF](https://drive.google.com/file/d/1tc9StFpa9AdYP9pHKizS86D74KKDSU8k/view)

[Publiación en la WEB](https://www.valoracionfuncional.com/06-valoracion-del-raquis)

## Objetivo
Valoración angular de las curvas raquídeas dorsal y lumbar mediante el cálculo
de los denominados Índices Cifóticos (IC) y Lordóticos (IL) propuestos por Santonja y
Ferrer (2010), así como del Índice Cifótico Autocorregido (ICA) para distinguir entre
morfotipos actitudinales o estructurales.

## Introducción
Actualmente es la única forma de clasificar el eje del raquis mediante las Flechas Sagitales, es
decir, la distancia que existe desde el centro de las distintas curvaturas raquídeas (ápex) con el
hilo de la plomada. Como veremos a continuación, existen la Fecha Cervical (FC), la Flecha
Torácica (FT), la Fecha Lumbar (FL) y la Fecha Sacra (FS).

- **Eje raquídeo atrasado**: cuando la FT = 0, el hilo contacta con la zona dorsal. La FS > 0.
- **Eje raquídeo adelantado**: cuando la FT ≠ 0, el hilo suele contactar con la zona sacra
(inicio del pliegue interglúteo). La FS = 0.
- **Eje raquídeo neutro**: el hilo contacta con la zona dorsal y con la zona sacra.

Para realizar dicho test debemos observar si el hilo de la plomada toca la zona dorsal o no (FT
igual o distinto de 0) ya que las fórmulas para calcular el IC o IL serán distintas (ver fórmulas).

- **Excepción**: si el hilo contacta con el slip, pantalón o glúteos se deberá restar a todas las
flechas el valor de la flecha más pequeña, ya que una de ellas debe ser igual a cero para
poder utilizar las fórmulas.
- **Desarrollo**: medir la distancia que hay entre el hilo y la zona de mayor concavidad
cervical (FC), la zona de mayor convexidad dorsal (FT), la zona de mayor concavidad
lumbar (FL) y el inicio del pliegue interglúteo, en caso de ser distinto de cero (FS).

Los valores normativos de este test atenderán al tipo de eje raquídeo:
1) **Eje atrasado (más habitual) o adelantado**:
-  IC: entre 20 y 65.
- IL: entre 20 y 40.

2) **ICA (Índice Cifótico Autocorregido)** para valorar si la cifosis es flexible o estructurada.
Se le pedirá al sujeto que realice la maniobra de autocorrección y se volverá a medir (es
muy común que la FT sea mayor de cero):
- Cifosis Flexible: ≤35.
- Cifosis con tendencia a la estructuración: >50.
`,
  videos: [{ vimeoId: '388598739' }],
};

export const ROM: VafLearnData = {
  markdown: `
# Rango de movimiento
---
## Enlaces

[Publicación en PDF](https://www.google.com)

[Publicación en la web](https://www.google.com)
`,
  videos: [
    // {vimeoId: '335566945'},
    // {vimeoId: '335840356'},
    // {vimeoId: '336761457'},
  ],
};

export const VAFPosture: VafLearnData = {
  markdown: `
# Protocolo VAF POSTURE
---
## Enlaces

[Publicación en PDF](https://drive.google.com/file/d/1QzvvmtPREe5Rr0MpTtaL8lZ4Nc7JZpSt/view)

[Publicación en la web](https://www.valoracionfuncional.com/02-valoracion-postural)

## Introducción

Insertaremos las 6 imágenes necesarias para elaborar el protocolo, a partir de las cuales cuantificaremos y rellenaremos los desplegables
para generar el informe de la valoración postural.

**Los planos a valorar y filmar son los siguientes:**
- Foto 1: Plano Frontal Anterior Relajado.
- Foto 2: Plano Frontal Anterior.
- Foto 3: Plano Frontal Posterior Relajado.
- Foto 4: Plano Frontal Posterior.
- Foto 5: Plano Sagital Izquierdo.
- Foto 6: Plano Sagital Derecho.

El objetivo de la prueba es detectar las asimetrías del cliente en estático con sólo 6 imágenes para posteriormente seguir realizando
valoraciones dinámicas que nos confirmen dichas anomalías encontradas.

El protocolo VAF Posture es el único que incluye dos fotografías más de lo habitual, como son; en el **Plano Frontal Anterior y Posterior Relajado**
ya que en ellas podemos observar la postura habitual mantenida del cliente a lo largo del día.
- Nos fijaremos principalmente en el reparto del peso corporal. Es común encontrar una mayor rotación externa de la cadera derecha debido a que
deja caer más el peso corporal sobre esa pierna.
- Los brazos permanecerán relajados a lo largo del cuerpo y la mirada al frente.

Las fotografías en el **Plano Frontal Anterior y Posterior** deben partir de una posición simétrica de pies. Se le pedirá al cliente que
realice una rotación de 30 grados (15 grados con cada pie) y mantenga los talones juntos.
- La línea de la plomada se fijará en el centro de unión de los talones.
- Es un error común no partir con dicha rotación de 30 grados en los pies, lo cual no garantiza una posición de partida estándar y simétrica.

Las fotografías del **Plano Sagital Izquierdo y Derecho** también deben partir de una posición simétrica de pies, pero en este caso se le indicará
al cliente que ponga sus pies paralelos a la anchura de sus hombros mirando al frente y con el eje del antepié neutro.
- El eje del antepié es la línea imaginaria que pasa por el segundo metatarsiano, la cual debe estar neutra mirando al frente.
- Es un error muy común realizar la fotografía y observar que un pie está más adelantado que otro en el plano sagital (observa que ambos pies
están a la misma altura y perpendiculares a la cámara antes de capturar las imágenes).
- La línea de la plomada debe pasar por delante del maléolo tibial (observa el tutorial en caso de dudas).

## Consejos:
- Utiliza siempre un marco de calibración o de referencia conocido sobre el que poder tomar una referencia posteriormente en la edición de las
fotografías (por ejemplo, puedes colocar un objeto junto al cliente cuya altura sea aproximadamente de un metro, o bien, colocar un marco de referencia
postural… observa los tutoriales en caso de dudas).
- Te recomendamos realizar las imágenes utilizando un trípode con nivel, con la cámara o móvil colocado a la altura de la cintura pélvica del cliente
y perpendicular, desde una distancia que te permita ver con claridad el cuerpo completo de la persona (evita realizar las imágenes o vídeos desde muy lejos).
- **Puedes realizar las fotografías directamente desde la App** y se guardarán automáticamente en la carpeta multimedia del cliente para editarlas
posteriormente.
- Recuerda que la App lleva un nivel para garantizar que la fotografía está centrada tanto horizontal como verticalmente.
- El cliente debe llevar una indumentaria cómoda que permita la observación de las estructuras anatómicas de referencia explicadas en el capítulo
(preferiblemente ropa de baño o deportiva).

## Videotutoriales
`,
  videos: [
    { vimeoId: '335566945' },
    { vimeoId: '335840356' },
    { vimeoId: '336761457' },
  ],
};

export const VAFPosturePlanoSagital: VafLearnData = {
  markdown: `
# Plano Sagital
---
## Introducción

Las fotografías del **Plano Sagital Izquierdo y Derecho** también deben partir de una posición simétrica de pies, pero en este caso se le indicará
al cliente que ponga sus pies paralelos a la anchura de sus hombros mirando al frente y con el eje del antepié neutro.
- El eje del antepié es la línea imaginaria que pasa por el segundo metatarsiano, la cual debe estar neutra mirando al frente.
- Es un error muy común realizar la fotografía y observar que un pie está más adelantado que otro en el plano sagital (observa que ambos pies
están a la misma altura y perpendiculares a la cámara antes de capturar las imágenes).
- La línea de la plomada debe pasar por delante del maléolo tibial (observa el tutorial en caso de dudas).

## Consejos:
- Utiliza siempre un marco de calibración o de referencia conocido sobre el que poder tomar una referencia posteriormente en la edición de las
fotografías (por ejemplo, puedes colocar un objeto junto al cliente cuya altura sea aproximadamente de un metro, o bien, colocar un marco de referencia
postural… observa los tutoriales en caso de dudas).
- Te recomendamos realizar las imágenes utilizando un trípode con nivel, con la cámara o móvil colocado a la altura de la cintura pélvica del cliente
y perpendicular, desde una distancia que te permita ver con claridad el cuerpo completo de la persona (evita realizar las imágenes o vídeos desde muy lejos).
- **Puedes realizar las fotografías directamente desde la App** y se guardarán automáticamente en la carpeta multimedia del cliente para editarlas
posteriormente.
- Recuerda que la App lleva un nivel para garantizar que la fotografía está centrada tanto horizontal como verticalmente.
- El cliente debe llevar una indumentaria cómoda que permita la observación de las estructuras anatómicas de referencia explicadas en el capítulo
(preferiblemente ropa de baño o deportiva).

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!

`,
  videos: [
    // {vimeoId: '335566945'},
    // {vimeoId: '335840356'},
    // {vimeoId: '336761457'},
  ],
};

export const VAFPosturePlanoFrontal: VafLearnData = {
  markdown: `
# Plano Frontal
---
## Introducción

Las fotografías en el **Plano Frontal Anterior y Posterior** deben partir de una posición simétrica de pies. Se le pedirá al cliente que
realice una rotación de 30 grados (15 grados con cada pie) y mantenga los talones juntos.
- La línea de la plomada se fijará en el centro de unión de los talones.
- Es un error común no partir con dicha rotación de 30 grados en los pies, lo cual no garantiza una posición de partida estándar y simétrica.

## Consejos:
- Utiliza siempre un marco de calibración o de referencia conocido sobre el que poder tomar una referencia posteriormente en la edición de las
fotografías (por ejemplo, puedes colocar un objeto junto al cliente cuya altura sea aproximadamente de un metro, o bien, colocar un marco de referencia
postural… observa los tutoriales en caso de dudas).
- Te recomendamos realizar las imágenes utilizando un trípode con nivel, con la cámara o móvil colocado a la altura de la cintura pélvica del cliente
y perpendicular, desde una distancia que te permita ver con claridad el cuerpo completo de la persona (evita realizar las imágenes o vídeos desde muy lejos).
- **Puedes realizar las fotografías directamente desde la App** y se guardarán automáticamente en la carpeta multimedia del cliente para editarlas
posteriormente.
- Recuerda que la App lleva un nivel para garantizar que la fotografía está centrada tanto horizontal como verticalmente.
- El cliente debe llevar una indumentaria cómoda que permita la observación de las estructuras anatómicas de referencia explicadas en el capítulo
(preferiblemente ropa de baño o deportiva).

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '335566945'},
    // {vimeoId: '335840356'},
    // {vimeoId: '336761457'},
  ],
};

export const VAFRecomendacionesEnlaces: VafLearnData = {
  markdown: `
# Recomendaciones y Enlaces
---


&nbsp;

**Comentarios:** utiliza el recuadro “comentarios” para indicar qué destaca en ese test específico del protocolo (ejemplos; “Buena simetría”, “Excesiva rotación externa”, etc). Intenta cuantificar todos los valores anormales para establecer el punto inicial y comprobar las mejoras del cliente.

**Recomendaciones:** utiliza el recuadro de recomendaciones para indicar al cliente qué otras valoraciones necesitaría o qué prescripción de ejercicios o consejos te gustaría que saliesen en el informe tras los datos obtenidos en su valoración.

**Enlaces:** añade cualquier enlace a tus informes. Elige el texto que quieres que aparezca y la url o link al que llevará dicho texto. Es una herramienta fundamental con la que puedes ampliar la información y calidad de tus informes. Ejemplos de uso:
-	Para ampliar la información que das a tus clientes (lecturas recomendadas, consejos, etc).
-	Para insertar imagen/es o video/s de tus valoraciones o de tus ejercicios recomendados (por ejemplo, puedes enlazar a una carpeta “Multimedia” de tu cliente en la que incluirás todo lo que quieras que vea).

`,
  videos: [
    // {vimeoId: '335566945'},
    // {vimeoId: '335840356'},
    // {vimeoId: '336761457'},
  ],
};

export const VAFFoot: VafLearnData = {
  markdown: `
# Protocolo VAF FOOT
---
## Enlaces

[Publicación en PDF](https://drive.google.com/file/d/1xedppObQ2-wUKI9ycWF-qx_d6o8iILzd/view)

[Publicación en la web](https://www.valoracionfuncional.com/03-patologias-ascendentes)

## Introducción
El término **“patologías ascendentes”** hace referencia a las alteraciones del
binomio pie-tobillo que suelen tener una repercusión sobre las zonas adyacentes
como la rodilla, la cadera o el raquis. Es muy habitual encontrar un problema en
la cadera cuyo origen está en la estructura anatómica del pie y en su mal
funcionamiento tanto en la marcha como en la carrera, lejos de la zona de dolor.

Una vez establecido el punto de partida del cliente, tras la valoración inicial y la
valoración postural, es el momento idóneo para analizar con más detalle los
segmentos corporales que pueden manifestar algún problema funcional o
estructural.

La valoración de las patologías ascendentes a lo largo del capítulo está dividida
en valoraciones estáticas para entender cómo es el pie a nivel óseo,
principalmente, y en valoraciones dinámicas para comprobar su funcionamiento
durante la marcha o la carrera.

Dentro de las **valoraciones estáticas**, destacan los métodos para la obtención
de la huella plantar y la identificación o registro de la estructura anatómica del
binomio pie-tobillo, así como la realización de test mediante fotografía o vídeo.
Para realizar un informe completo de la valoración estática del pie realizaremos 6 fotografías:
- Fotografría del Plano Frontal Anterior Relajado.
- Fotografía del Plano Frontal Anterior Neutro.
- Fotografría del Plano Frontal Posterior Relajado.
- Fotografría del Plano Frontal Posterior Neutro.
- Fotografía del Plano Sagital Derecho (monopodal en carga).
- Fotografía del Plano Sagital Izquierdo (monopodal en carga).
Opcionalmente, se podrán incluir las dos imágenes de la huella plantar y las dos
imágenes del test de triple flexión de tobillo.

En las **valoraciones dinámicas** analizaremos el comportamiento de la
articulación supra-astragalina (flexo-extensión) y la articulación sub-astragalina
(pronación y supinación) como principales responsables, junto con su estructura
ósea, del buen funcionamiento del tobillo.
Para realizar un informe completo de la valoración dinámica del pie realizaremos 4 videos:
- Video del test de triple flexión.
- Video de la carrera en el Plano Frontal Posterior.
- Video de la carrera en el Plano Sagital.
- Video del test Drop Jump.

El pie y el tobillo son las estructuras anatómicas que soportan y transmiten las
fuerzas de reacción del suelo al resto del cuerpo, suponiendo el ejercicio físico
un estrés mecánico sobre el que responderá adaptativamente, atendiendo a los
parámetros intrínsecos de su contexto (modalidad deportiva, simetría del gesto
técnico, composición corporal del sujeto, etc).

Revisando la literatura actual se pone de manifiesto que **existe una correlación
directa entre el estado de la anatomía del binomio pie-tobillo con las
articulaciones adyacentes de rodilla y cadera**, así como una clara
predisposición a ciertas patologías, especialmente por sobrecarga (metaanálisis
de Neal, 2014; Tong, 2013; Chuter, 2012).

Los métodos cuantitativos de evaluación de la biomecánica del pie y de la huella
plantar suponen una importante ayuda en la identificación de las características
morfológicas del pie, aportando información notable sobre posibles riesgos
lesionales (metaanálisis Hollander, 2019). Por ello, se abordarán los aspectos
anatómicos más relevantes de dicha estructura junto con una revisión
sistemática a la metodología actual, seleccionando aquellos test o valoraciones
con mayor consenso y reproducibilidad.
## Videotutoriales
`,
  videos: [
    { vimeoId: '344295656' },
    { vimeoId: '345428334' },
    { vimeoId: '344985802' },
  ],
};

export const VAFFootDescripcionAnatomica: VafLearnData = {
  markdown: `
# Descripción Anatómica
---
## Indicaciones
Se le pedirá al evaluado que esté en una posición neutra de pies, con sus pies perpendiculares a la cámara. Necesitaremos
una imagen en el Plano Frontal Anterior y otra para observar el Plano Frontal Posterior.
- La posición neutra de pies en el Plano Frontal Anterior se consigue manteniendo el eje anterior del antepié neutro
(es decir, la línea del segundo meta mirando al frente).
- Podemos ver en la misma imagen la presencia o no de Hallux Valgus, siempre que la calidad sea
adecuada y nos permita observarlo en la imagen.

## Justificación

**Tipología de los pies según la longitud de las falanges**
- Egipcio: primer meta sobresale más.
- Griego: segundo meta sobresale más.
- Romano: primer y segundo meta sobresalen más (misma distancia).
- Germano (o pie cuadrado): todas las falanges sobresalen por igual.
- Celta: el segundo meta sobresale más y las falanges cuarta y quinta son simétricas.

**Tipología de los pies según el tipo de falanges**
- Garra: la articulación interfalángica proximal está flexionada (más común).
- Martillo: la articulación interfalángica medial está flexionada.
- Maza: la articulación interfalángica distal está flexionada.

**Hallux Valgus (juanete)**
- Normalidad: menor a 20 grados.
- Grado 1: de 20 a 40 grados.
- Grado 2 (quirúrgico): más de 40 grados.

El Hallux Valgus se cuantifica con goniometría y es el ángulo que forman la línea media del primer metatarso con
respecto a la línea media de la primera falange.

## Objetivos
La descripción anatómica nos indicará aspectos estructurales u óseos del pie, poniendo de manifiesto cómo
será el apoyo tanto en estático como en dinámico.
## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const VAFFootValoracionArcoInterno: VafLearnData = {
  markdown: `
# Valoración del Arco Interno
---
## Indicaciones
Se le pedirá al evaluado que coloque su pie cerca del borde de la colchoneta en la que tendremos una cinta métrica
para realizar las imágenes con una referencia. Necesitaremos una imagen del Plano Sagital Izquierdo y otra del Plano Sagital Derecho.
Previamente habremos marcado en cada pie los tres puntos de referencia: el centro del maléolo tibial o interno, el centro
de la cabeza del primer meta y la protuberancia (zona con más relieve) del tubérculo del escafoides o navicular.

El miembro inferior a evaluar debe cumplir las siguientes indicaciones:
- No deberá estar flexionado ya que indicaría que no está dejando caer todo su peso corporal sobre su arco plantar.
- La tibia debe permanecer vertical en la imagen (evitar la anteriorización o posteriorización).
- El miembro inferior que no se está evaluando debe realizar un apoyo que asegure un buen equilibrio del evaluado,
colocándose siempre posterior al pie que vamos a evaluar. No debería salir en la imagen ni colocarse excesivamente
posterior ya que indicaría que no carga todo el peso en la pierna a evaluar.

## Justificación
**Ángulo del Arco Longitudinal o “Longitudinal Arch Angle”** (Dahle 1991; Nilsson, 2012). AAL.
Es el ángulo formado entre el maléolo medial (o tibial), la cabeza del primer metatarsiano y el navicular (o tubérculo
del escafoides), el cual representa el arquetipo medial del pie.

Originalmente Dahle estableció la normalidad entre 120º y 150º (y los extremos en 90º y 180º), pero posteriormente Nilsson
utilizó una muestra mayor, estableciendo los siguientes rangos angulares, los cuales recomendamos ya que es una forma muy
eficiente de valorar su estructura:
- Plano extremo: menos de 121º
- Plano: 121º-130º
- Normal: 131º-152º
- Cavo: 153º-162º
- Cavo extremo: más de 162º

**Caída del escafoides o “Navicular Drop”** (Brody, 1982; Sachitanandam, 1995; Nielsen, 2008). ND.
Esta prueba se establece como un buen predictor de lesiones por sobreuso del miembro inferior (Neal, 2014; Razegui, 2012; Jarvis,
2012). Además, se utiliza para valorar el grado de pronación del pie, mediante la cuantificación en milímetros de “la caída” o
descenso del escafoides, tomando dicha medida entre dos posiciones:
- Medir la tuberosidad más prominente del escafoides en descarga (según “The user guide and manual of the navicular drop test”
de Charlesworth y Johansen es recomendable realizar la medición en descarga sentado con las rodillas flexionadas a 90º
y ambos pies neutros completamente apoyados y centrados con respecto al suelo).
- Medir la tuberosidad más prominente del escafoides en bipedestación, con el 50% del peso corporal sobre cada pie.
- La diferencia entre ambas medidas será el valor del test.

Según Brody, valores inferiores a 10-15mm reflejan la normalidad y valores superiores a 15mm indican anormalidad. Posteriormente,
Loudon (1996), estableció la clasificación más utilizada para dicho test:
- Normalidad: de 6 a 9mm.
- Anormalidad: 10mm o más.
Esta prueba tiene una **gran correlación con la reactividad** (stiffness o complianza muscular) del sujeto. Valores inferiores
a la normalidad se asocian a un gran stiffness muscular pero se asocian con una movilidad reducida y un alto riesgo de lesión
por sobreuso, al igual que valores superiores se asocian a una mayor complianza (Aguilera, 2017).

**Grado de extensión de la 1ª articulación metatarso-falángica**
El valor de normalidad son 45 grados de extensión. Un ángulo inferior indica que la musculatura del arco plantar limita dicho
movimiento, lo cual está asociado con un elevado stiffness muscular y pies más cavos.
Se realiza la extensión forzada del primer meta y se cuantifica con goniómetro.

## Objetivos
Estas valoraciones identifican cómo es la estructura ósea del pie, especialmente del arco plantar, además de poner de manifiesto
su stiffness muscular el cual tiene una correlación científica directa con posibles lesiones y el estado de las articulaciones o
musculatura adyacentes.
Es muy común encontrar en personas que practican ejercicio físico asiduamente un arco interno plantar más elevado (cavo) y
valores inferiores a la normalidad en la prueba del Navicular Drop y en la extensión del primer meta.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [],
};

export const VAFFootAntepieRetropie: VafLearnData = {
  markdown: `
# Antepié y Retropié
---
## Indicaciones
Se le pedirá al evaluado que esté en una posición habitual de relajación, con sus pies perpendiculares a la cámara. Necesitaremos
una imagen en el Plano Frontal Anterior y otra en el Plano Frontal Posterior, ambas en una posición relajada.
Se recomienda que en las imágenes aparezcan las rodillas para poder observar la línea del poplíteo en el Plano Frontal Posterior
y la Tuberosidad Tibial Anterior (TTA) en el Plano Frontal Anterior.

## Justificación
**Plano Frontal Anterior: ángulo del antepié**
- Valor de normalidad: **menor a 15 grados**.
- Un ángulo mayor indicará una **excesiva rotación**, la cual suele estar provocada a nivel funcional por un exceso de rotación
externa de la musculatura de la cadera o a nivel estructural por una excesiva torsión tibial externa.

Dicho ángulo se calcula con el eje anterior del pie, el cual se forma entre la línea del segundo meta y al eje perpendicular
(eje que mira perpendicularmente a la cámara).

Como norma general, las personas dejamos caer un mayor peso sobre la pierna derecha ya que existe una asimetría en el peso real de
cada hemicuerpo provocado por el hígado el cual suele tener un peso aproximado en una persona adulta de 1,5kg localizado en el
hemicuerpo derecho.

Cuando existan grandes diferencias entre los ángulos de antepié o retropié derecho e izquierdo debemos seguir valorando dinámicamente
y cuantificar las posibles asimetrías.

**Plano Frontal Posterior: ángulo del retropié**
- Valor de normalidad: **menor o igual a 5 grados** de pronación en bipedestación.

Dicho ángulo es conocido también como la **línea de Helbing**, el cual se forma tomando en la imagen tres puntos: el centro de la
línea del poplíteo, el centro del tendón de Aquiles (levemente inferior a la altura de los maléolos) y el centro del talón en la
zona más próxima al suelo.
Se recomienda indicar en los comentarios el estado tanto de las rótulas (neutras-convergentes o divergentes) como de las tibias o
genu (varo-valgo).

## Objetivos
Identificar mecanismos de rotación en el plano horizontal y el reparto del peso corporal del evaluado en estático, así como la
excesiva pronación del retropié en el plano frontal.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [],
};

export const VAFFootArticulacionSupraAstra: VafLearnData = {
  markdown: `
# Articulación Supra-Astragalina
---
## Indicaciones
Se le pedirá al evaluado que realice el **test de triple flexion de tobillo**, es decir, una flexión profunda de tobillos
(flexión dorsal), rodillas y tronco, manteniendo los pies y las rodillas juntas en todo momento. En condiciones de normalidad,
no deberá levantar los talones del suelo y los brazos quedarán apoyados sobre las rodillas.
- La prueba del tríceps sural óptima implicará el mantenimiento de la lordosis lumbar.
- Realizar el test descalzo y cuantificar la angulación de la línea media de la pierna y del tronco con respecto a la horizontal
(ambos deberían ser paralelos y nos indicarán que segmento tiene un menor desplazamiento).
- Se grabará la prueba desde el plano sagital y frontal posterior, pidiéndole al evaluado 3 repeticiones por cada plano.

**Test FMS Ankle**
Se colocará la punta del pie a 10 centímetros de la pared y el evaluado mantendrá la “posición de caballero” (una rodilla
apollada en el suelo, espalda ergida y las dos manos sobre la rodilla que se va a desplazar buscando la pared).
- No debe levantar el talón de la pierna que avanza.
- Se anotará la distancia que hay desde la rodilla hasta la pared (debería avanzar hasta 10 centímetros y tocar la pared
sin levantar el talón y manteniendo la posición inicial).

## Justificación
Las patologías o deficiencias asociadas al tobillo guardan una especial relación causa-efecto con la flexión dorsal del mismo. Por
ello, existe en la literatura científica gran cantidad de investigaciones que relacionan una flexión dorsal limitada con patologías
como el síndrome femoro-patelar, tendinopatías rotulianas, desplazamientos mediales de la rodilla en bipedestación (con el
consiguiente riesgo de lesión de las estructuras influenciadas; ligamento cruzado anterior, menisco externo y Ligamento Lateral
Interno), lesiones del tendón de Aquiles, fascitis plantar, etc (Cook y Malliaras, 2006; Bell, 2008; Boyle, 2010; Fong, 2011;
Whitting, 2011).

En resumen, una limitación del tobillo en el plano sagital afectará al movimiento equilibrado de las articulaciones adyacentes.

## Objetivos
Valorar el estado de la articulación supra-astragalina y la musculatura de la pierna, especialmente del tríceps sural el cual
puede limitar la flexión dorsal (limitación funcional), o bien, detectar una posible limitación estructural, siendo la
anteriorización del astrágalo la más común.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [],
};

export const VAFFootArticulacionSubAstra: VafLearnData = {
  markdown: `
# Articulación Sub-Astragalina
---
## Indicaciones
Se le pedirá al evaluado que se coloque en **bipedestación relajada** sobre una cinta o tapiz rodante. Se cuantificarán los ángulos
de pronación del retropié tanto en estático como en la marcha y en la carrera, anotando ambas velocidades.
- El ángulo de pronación en la marcha y la carrera se obtiene cuando el peso del cuerpo cae completamente sobre el pie de apoyo
(coincide con el momento en el que la rodilla contraria pasa junto a la pierna evaluada).
- El ángulo se cuantifica con 3 puntos de referencia: centro de la línea media del poplíteo, centro del tendón de Aquiles (levemente
por debajo de los maléolos) y el centro del calcáneo en la zona más próxima al suelo.
- Se recomienda anotar la velocidad a la que se cuantifican los ángulos del retropié tanto en la marcha (normalmente a unos 5km/h)
como en la carrera (a la máxima posible para el cliente).

## Justificación
La pronación es un mecanismo utilizado para adaptar el pie al terreno y para disminuir las fuerzas de impacto absorbidas, mientras
que la supinación es un mecanismo utilizado para estabilizar el antepié sobre el retropié de forma que el pie actúe como una palanca
rígida durante la propulsión, protegiendo el tobillo de inestabilidades y disminuyendo la dependencia de la musculatura peronea
(Jiménez, 2004).

**Valores de normalidad en la pronación del retropié** (Peroni, 2002; Aguado, 1997):
- Marcha: de 4 a 6 grados.
- Carrera: de 10 a 15 grados.

## Objetivos
Valorar las articulaciones del tobillo en dinámico, incluyendo aspectos complementarios de la técnica de carrera como la rotación
de pies, la basculación de la pelvis o los tiempos de contacto de cada apoyo.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [],
};

export const VAFFootProtocoloHernandezCorvo: VafLearnData = {
  markdown: `
# Protocolo Hernández Corvo
---
## Indicaciones
Este protocolo lo podemos emplear con el método del fotopodograma (papel fotográfico), con el método del Pedígrafo (instrumento
con forma de libro exclusivo para esta medición) o bien, mediante el método propuesto por Aguado, Izquierdo y González (1997) en el
que se realiza sobre un folio, utilizando tinta de estampación o pintura de dedos, con la ventaja de ser el más económico.

## Justificación
Consiste en **tipificar el pie según las medidas obtenidas con la imagen de la huella plantar**, dando como resultado seis
posibilidades que abarcan desde el pie plano hasta el pie cavo extremo (Hernández, 1989):
- Pie plano: 0-34%
- Pie plano/normal: 35-39%
- Pie normal: 40-54%.
- Pie normal/cavo: 55-59%
- Pie cavo: 60-74%
- Pie cavo fuerte: 75-84%
- Pie cavo extremo: 85-100%

## Objetivos
Las investigaciones indican que los sujetos con **pies más planos** presentan una tendencia a producir ángulos de pronación
mayores (Williams, 2001), siendo un factor de riesgo ya que puede acarrear lesiones del miembro inferior (Coplan, 1989; Hinterman
y Nigg, 1998). Por otro lado, el mecanismo de transferencia del movimiento de eversión del tobillo a rotación interna de tibia
se ve incrementado en los sujetos con **pies cavos**, aumentando la propensión a padecer síndrome femoropatelar (Williams, 2001;
Boozer, 2002).

Las **patologías asociadas** a la tipología de los pies son fundamentadas principalmente por tres aspectos, como son: el índice de
masa corporal, el sexo y los patrones mecánicos alterados.

Sin embargo, podemos encontrar **referencias a otras alteraciones** como el síndrome del estrés tibial medial (Sharma, 2011),
afectaciones en el tendón del tibial posterior (Xu, 2015), lesiones por sobrecarga en la musculatura de la cadera y el muslo
(Gross, 2007; Scattone, 2014), en la rodilla (Lun, 2004; Noehren, 2007) e incluso, afectaciones en la zona lumbar (Rothbart,
1995; Menz, 2013).

Por otro lado, **las presiones plantares** se reparten de forma distinta en los pies cavos (mayor presión en la parte externa
del retropié) y en los pies planos (mayor presión en la parte interna del mediopie), variando de esta forma los patrones normales
de la carrera (Elvira, 2006).

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [],
};

export const VAFROM: VafLearnData = {
  markdown: `
# Protocolo VAF ROM
---
## Enlaces

[Publicación en PDF](https://drive.google.com/file/d/1caZWKYRzor30LOfhwEXO4C6n6ThOXr5j/view)

[Publicación en la web](https://www.valoracionfuncional.com/05-valoracion-de-la-flexibilidad)

## Introducción
**En los apartados anteriores** hemos detallado la importancia que tienen la valoración inicial o anamnesis (VAF Inicial)
como puntos de partida o control a nuestra posterior prescripción de ejercicio físico, así como la identificación de posibles
desajustes tanto estructurales como funcionales gracias a la valoración postural (VAF Posture) o la valoración de las
patologías ascendentes (VAF Foot) o el raquis (VAF Raquis). Todos ellos, sin requerir un elevado tiempo ni un gran coste económico.

En este apartado, y siguiendo el orden lógico de una valoración integral, nos centraremos en las valoraciones dinámicas las cuales
nos permitirán comprobar el funcionamiento artro-cinemático, los patrones de movimiento, y las posibles disfunciones motrices,
atendiendo siempre a los patrones normativos y valores de referencia que nos delimita la literatura científica actual.

Una vez llegados a este punto, es hora de comprobar y cuantificar las desalineaciones o desequilibrios artro-musculares que hemos
observado con anterioridad para cerciorarnos de que, efectivamente, existe una limitación en el cliente o, en caso contrario,
debemos seguir valorando.

**Para ello se propone el protocolo VAF ROM en el que se analizan los principales eslabones cinéticos, atendiendo a los test con
mayor consenso científico:**
- Valoración del Tobillo.
- Valoración de la Cintura Pélvica.
- Valoración de la Cintura Escapular.
- Test específicos para Miembro Inferior.

## Grabaciones necesarias
El protocolo VAF ROM se lleva desarrollando más de una década en equipos deportivos profesionales y en valoraciones individualizadas
ya que es eminentemente rápido (no más de 10 minutos) y no necesita más de un valorador. No obstante, es recomendable realizar las
siguientes cinco grabaciones de video para incluir en cada informe:

1. Video del test de triple flexión de tobillo (desde el plano sagital y frontal posterior).
1. Video de la cintura pélvica (desde el plano sagital).
1. Video de la cintura escapular (desde el plano frontal).
1. Video de los test específicos del miembro inferior en supino (vista craneo-caudal).
1. Video de los test específicos del miembro inferior en prono (vista craneo caudal).

Una vez concluida esta valoración sería recomendable utilizar MyVAF para ampliar la información con cualquier protocolo o test
personalizados que el profesional considere necesarios.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const VAFROMTobillo: VafLearnData = {
  markdown: `
# Valoración del Tobillo
---
## Indicaciones
Se le pedirá al evaluado que realice el **test de triple flexion de tobillo**, es decir, una flexión profunda de tobillos
(flexión dorsal), rodillas y tronco, manteniendo los pies y las rodillas juntas en todo momento. En condiciones de normalidad,
no deberá levantar los talones del suelo y los brazos quedarán apoyados sobre las rodillas.
- La prueba del tríceps sural óptima implicará el mantenimiento de la lordosis lumbar.
- Realizar el test descalzo y cuantificar la angulación de la línea media de la pierna y del tronco con respecto a la horizontal
(ambos deberían ser paralelos y nos indicarán que segmento tiene un menor desplazamiento).
- Se grabará la prueba desde el plano sagital y frontal posterior, pidiéndole al evaluado 3 repeticiones por cada plano.

**Test FMS Ankle**
Se colocará la punta del pie a 10 centímetros de la pared y el evaluado mantendrá la “posición de caballero” (una rodilla
apollada en el suelo, espalda ergida y las dos manos sobre la rodilla que se va a desplazar buscando la pared).
- No debe levantar el talón de la pierna que avanza.
- Se anotará la distancia que hay desde la rodilla hasta la pared (debería avanzar hasta 10 centímetros y tocar la pared
sin levantar el talón y manteniendo la posición inicial).

## Justificación
Las patologías o deficiencias asociadas al tobillo guardan una especial relación causa-efecto con la flexión dorsal del mismo. Por
ello, existe en la literatura científica gran cantidad de investigaciones que relacionan una flexión dorsal limitada con patologías
como el síndrome femoro-patelar, tendinopatías rotulianas, desplazamientos mediales de la rodilla en bipedestación (con el
consiguiente riesgo de lesión de las estructuras influenciadas; ligamento cruzado anterior, menisco externo y Ligamento Lateral
Interno), lesiones del tendón de Aquiles, fascitis plantar, etc (Cook y Malliaras, 2006; Bell, 2008; Boyle, 2010; Fong, 2011;
Whitting, 2011).

En resumen, una limitación del tobillo en el plano sagital afectará al movimiento equilibrado de las articulaciones adyacentes.

## Objetivos
Valorar el estado de la articulación supra-astragalina y la musculatura de la pierna, especialmente del tríceps sural el cual
puede limitar la flexión dorsal (limitación funcional), o bien, detectar una posible limitación estructural, siendo la
anteriorización del astrágalo la más común.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const VAFROMCinturaPelvica: VafLearnData = {
  markdown: `
# Valoración Cintura Pélvica
---
# TEST DE ADAMS

## Indicaciones:
En bipedestación, el evaluado va flexionado lentamente el raquis cervical, dorsal y lumbar hasta llegar a una flexión máxima de tronco (el miembro
superior acompañará el movimiento, relajado, intentando llegar a la punta de los pies). El evaluador se colocará detrás del sujeto e irá adaptando
su mirada a la zona de flexión más craneal (valorando el nivel horizontal de ambos hemitórax).

## Justificación:
El procedimiento es similar al Test Dedos-Suelo y por ello aprovecharemos la posición inicial de ambos test para valorar:
- **Rotación de cinturas:** en bipedestación debemos observar si existe una rotación horaria o antihoraria en la cintura pélvica y en la cintura escapular
(normalmente con una marcada protusión). Para ello, podemos tomar como referencia el plano horizontal de los pies.
- **Basculación pélvica:** el ángulo Lumbo-Vertical (LV), debe ser superior a 65º (Santonja, 1996).
- **Comportamiento del MMII:** una cortedad de isquiosurales o del tríceps sural provocará un desplazamiento posterior de la pelvis durante la realización
del test o una flexión de rodillas.
- **Comportamiento del MMSS:** según Ferrer (1998) la punta de los dedos debería llegar a 4cm o menos con respecto a la horizontalidad (la basculación
pélvica nos indicará si el problema está en el miembro inferior o en el raquis, siendo relativamente común en deportistas una excesiva compensación dorsal).
- **Escoliosis:** la presencia de una giba conlleva la existencia de una rotación vertebral la cual puede ser estructural o funcional (aunque se puede valorar
con un inclinómetro, un escoliometer o un gibómetro, es recomendable derivar al profesional sanitario ya que un test de Adams positivo por patologías
estructurales requerirá la valoración médica y posiblemente, la utilización de un corsé en edades de crecimiento).
- **Hipertonía:** suele ser habitual encontrar un hemitórax lumbar o dorsal ligeramente más prominente que el contralateral (a la palpación se identifica
una mayor tensión muscular).

## Objetivo
Identificar una posible escoliosis y valorar el estado de la musculatura del raquis (hiper o hipotónica).


# TEST DEDOS-PLANTA

## Indicaciones
El evaluado se colocará en sedestación con las piernas extendidas y paralelas a la altura de las caderas e intentará, colocando una mano sobre la otra,
llegar lo más lejos posible realizando una flexión máxima de tronco de forma lenta y progresiva. El evaluador registrará la distancia que falta hasta
llegar a la vertical que forma la región plantar (los pies deben estar a 90 grados, apoyándonos en un cajón o similar), así como el ángulo
Lumbo-Horizontal (LH) tomando como referencia la zona el eje de la zona lumbar y el eje de la horizontal.

## Justificación
**Los valores de referencia según Vicente Ferrer (1998), son:**
- Normalidad: menor o igual a 2cm.
- Cortedad Grado I: desde los menos 3 hasta los menos 9cm.
- Cortedad Grado II: Menor o igual a 10cm.

**Atendiendo a Fernando Santonja (1996), los valores de referencia para el ángulo Lumbo-Horizontal (LH) son:**
- Normalidad: menor o igual a 100 grados (medido = 80 grados).
- Cortedad Grado I: entre 100 y 115 grados (medido = entre 79 y 65 grados).
- Cortedad Grado II: Mayor de 115 grados (medido = menor de 65 grados).

Es importante recalcar que en la medición del ángulo Lumbo-Horizontal siempre tomaremos el ángulo complementario, por ejemplo, si el ángulo LH es
de 70 grados quiere decir que la medición real sería de 110 grados (cortedad grado I).

## Objetivo
Comprobar la extensibilidad isquiosural y la basculación de la pelvis.


# TEST DEDOS-SUELO

## Indicaciones
El evaluado se colocará en bipedestación con las piernas extendidas y paralelas a la altura de las caderas e intentará, colocando una mano sobre
la otra, llegar lo más lejos posible realizando una flexión máxima de tronco de forma lenta y progresiva. El evaluador registrará la distancia que
falta hasta llegar al suelo, así como el ángulo Lumbo-Vertical (LV) tomando como referencia la zona el eje de la zona lumbar y el eje de la vertical.

Se recomienda subir al evaluado sobre un pequeño escalón o step para no limitar el avance en la flexión de tronco.

## Justificación
*Atendiendo a Vicente Ferrer (1998), los valores de referencia para el test Dedos-Suelo son:*
- Normalidad: mayor o igual a menos 4cm.
- Cortedad Grado I: desde los menos 5 hasta los menos 11cm.
- Cortedad Grado II: Menor o igual a menos 12cm.

**Según Fernando Santonja (2013), los valores de referencia para el ángulo Lumbo-Vertical (LV) son:**
- Normalidad: mayor o igual a 65 grados.
- Cortedad: menor a 65 grados.

## Objetivo
Comprobar la extensibilidad isquiosural y la basculación de la pelvis.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const VAFROMCinturaEscapular: VafLearnData = {
  markdown: `
# Valoración Cintura Escapular
---
# DISQUINESIA ESCAPULAR

## Indicaciones
El evaluado estará en bipedestación y relajado, el evaluador observará y registrará la anatomía de la escápula tanto en estático como en dinámico.

## Justificación
La disquinesia escapular es una alteración de la escápula tanto en estático como en dinámico en relación con la caja torácica en la que se observan
ciertas prominencias según su tipología.

**1.- Valoración estática de la disquinesia: Existen tres tipos:**
Tipo I: despegue del borde inferior escapular (cortedad de la musculatura originada en la apófisis coracoides).
Tipo II: despegue del borde medial de la escápula (hipotonía de la musculatura interescapular).
Tipo III: despegue del borde supero-medial de la escápula (hipotonía del trapecio superior y angular).

**2.- Valoración dinámica:** Actualmente se acepta que la relación o ratio de movimientos entre la báscula axilar y la abducción de hombro se establece
en 2:1, es decir, que al realizar 90 grados de abducción de hombro corresponderían 30º a la báscula axilar escapular y 60º al desplazamiento del
húmero. Existen trabajos más precisos en dicha cuantificación (Baggs, 1988) atendiendo a las distintas angulaciones de movimiento pese a ser 2:1 la
ratio más utilizada actualmente en la valoración del ritmo escapulo-humeral, el cual nos puede orientar sobre su funcionamiento en dinámico.

## Objetivo
Identificar la anatomía escapular tanto en estático (disquinesia) como en dinámico (ritmo escápulo-humeral 2:1) y la posible musculatura implicada.


# TEST DE MOVILIDAD GENERAL

## Indicaciones
El evaluado estará en bipedestación, intentando aproximar ambos puños sobre la espalda pasando una mano por detrás de la cabeza y la otra por la
axila (intentando descender y ascender, respectivamente, lo máximo posible sin compensar el movimiento con otros segmentos corporales).

## Justificación
Dicho ejercicio se engloba dentro del protocolo Functional Movement Screen (FMS) y nos da una visión de la movilidad general del hombro y de la escapula.
**El test establece cuatro puntuaciones según la distancia obtenida entre los puños**, no obstante, lo realmente relevante es cuantificar en centímetros
la distancia obtenida e identificar las limitaciones musculares del sujeto.
- 3 puntos: si ente un puño y otro hay menos de una mano del evaluador colocada en horizontal entre ambos puntos.
- 2 puntos: si ente un puño y otro hay menos de una mano y media del evaluador colocada en horizontal entre ambos puntos.
- 1 punto: si hay más de una mano y media de distancia entre ambos puntos.
- 0 puntos: si además siente dolor.

## Objetivo
Identificar los movimientos limitados y la musculatura implicada. Se recomienda valorar las angulaciones en caso de limitación.


# TEST DE LOS ROTADORES

## Indicaciones
Evaluado en decúbito supino sobre una camilla o en bipedestación manteniendo tres puntos de contacto con la pared (cabeza, zona dorsal y sacro). Colocará
el hombro en 90 grados de abducción y con flexión de codo de 90º. La posición de inicio debe partir de una rotación neutra e impedir en todo momento una
compensación humeral (normalmente, ante una limitación, se tiende a la anteriorización de la cabeza del humero):

**Rotación interna:** 70º. Las falanges deben tocar la camilla (Awan, 2002) o la pared.
**Rotación externa:** 90º. El dorso de la mano debe tocar la camilla o la pared completamente.

## Justificación:
Lo más importante es comprobar la amplitud de movimiento total del hombro (la suma de ambas rotaciones) ya que puede existir una limitación a la
rotación interna (denominada GIRD) pero la amplitud total es óptima debido a una mayor rotación externa. Este aspecto es muy relevante en deportes
“overhead” en los que se ponen de manifiesto limitaciones sobre la rotación interna por la repetición de ciertos gestos deportivos típicos como los
lanzamientos.

## Objetivo
Identificar limitaciones en las rotaciones de hombro y comprobar la amplitud de movimiento general.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const VAFROMTestsMiembroInferior: VafLearnData = {
  markdown: `
# Test Específicos del Miembro Inferior
---
# TEST DE THOMAS

## Indicaciones
El evaluado se colocará en decúbito supino apoyando las EIPS al borde de la camilla, dejará caer una pierna y sujetará con ambas manos la otra,
llevándosela hacia el pecho con flexión de rodilla. El evaluador se encargará de que la posición sea correcta, colocando la pelvis al filo de la
camilla y ayudará en la sujeción de la pierna que el evaluado tiene sujeta.

Mantener la lordosis lumbar fisiológica y una flexión de cadera de unos 120 grados (Lesmes, 1996). Se recomiendo la utilización de un Lumbosand
para garantizar dicha lordosis lumbar fisiológica en cada repetición.

**Posibles resultados e implicaciones:**
- Sobre la cadera: Flexores de cadera acortados (psoas): si el muslo del evaluado se mantiene por encima de la horizontal. Abducción del miembro inferior: posible cortedad del Tensor de la Fascia Lata y Fascia Lata. Rotación externa del muslo: posible cortedad del sartorio o la musculatura glútea.
- Sobre la rodilla: Rotación Tibial: el muslo está neutro pero la tibia se visualiza rotada (normalmente en rotación externa). Extensión de rodilla por encima de 45º con respecto a la horizontal: cortedad del Recto Anterior del Cuádriceps (se origina en la EIAI y sería el responsable de no permitir un descenso tibial en dicha posición, Peeler 2008).
- Sobre el pie: Valorar la articulación subastragalina (pronación o supinación del pie sin carga).

## Objetivo
Comprobar la extensibilidad de los flexores de cadera (Psoas-Iliaco principalmente), Recto Anterior del Cuádriceps (Test de Thomas modificado),
Tensor de la Fascia Lata y Fascia Lata, así como el comportamiento óseo tanto del fémur, la tibia y el tobillo.


# TEST DE LOS ROTADORES

## Indicaciones
El evaluado se colocará en decúbito prono con los muslos paralelos, relajados y con flexión de rodilla a 90 grados. El examinador valorará manualmente
la rotación interna y externa colocando su mano en el maléolo interno o externo respectivamente. Se anotará si la cortedad es leve , importante o excesiva
de forma cualitativa, tomando como referencia el valor normativo de 45 grados tanto en la rotación interna como externa.

En la misma posición, con ambas rodillas en 90 grados, sujetando el evaluador ambas piernas con una mano podremos observar y cuantificar la torsión tibial
y la disposición de la planta del pie del evaluado (ver material de estudio).

**La valoración de los rotadores de cadera se puede realizar de dos formas:**

**1.- En decúbito supino con flexión de cadera y rodilla a 90º:**
Rotación Interna
- Normalidad: mayor de 30º.
- Cortedad Grado I: 20º a 29º.
- Cortedad Grado II: 10º a 19º.
- Cortedad Grado III: menor de 10º.
Rotación Externa
- Normalidad: entre 40º y 50º.
- Cortedad Grado I: 30º a 39º.
- Cortedad Grado II: 20º a 29º.
- Cortedad Grado III: menor de 20º.

**2.- En decúbito prono con flexión de rodilla a 90º:**
- Rotación interna: de 40º a 45º.
- Rotación externa: de 40º a 50º.

## Justificación
La importancia de valorar correctamente el ROM de la cadera radica en que una limitación o exceso en dichas rotaciones provocará compensaciones
de las articulaciones adyacentes (principalmente en las rodillas y en la zona lumbar), siendo articulaciones que presentan una menor movilidad.

Una excesiva rotación interna de cadera está asociada a una pelvis en anteversión, genu valgo y retropié en pronación. Una limitación en la rotación
externa de cadera está asociada a una pelvis en retroversión, genu varo y retropié en supinación.

Existe una clara evidencia (Verrall, 2005; Ibrahim, 2007; Tak, 2017) de que una rotación total menor a 85 grados en ambas caderas aumenta la
probabilidad de dolor inguinal.

## Objetivo
Comprobar la extensibilidad de los rotadores de cadera y las posibles repercusiones sobre las estructuras adyacentes.


# TEST DE LOS ADUCTORES

## Indicaciones
**1.- Aductores cortos (Bent Knee Fall Out Test):** El evaluado se colocará en decúbito supino con flexión de cadera y rodillas, abducción de cadera,
juntando la planta de los pies del evaluado. Se aproximarán los talones a la pelvis y se le pedirá que esté lo más relajado posible.
Según Tyler (2001) y Malliaras (2010) debería existir una angulación aproximada de 53 grados.

**2.- Aductores largos:** El evaluado se colocará en decúbito supino, con los miembros inferiores extendidos. El evaluador colocará la extremidad no
evaluada fuera de la camilla y realizará una abducción máxima pasiva a la extremidad evaluada (evitando la rotación externa de la cadera). Se medirá
el ángulo formado entre las EIAS y el eje del muslo de la extremidad que se quiere medir. La normalidad para la aducción es de 45 grados (se debe
cuantificar con un goniómetro de rama larga).

## Justificación
Pese a que no existe una clara evidencia sobre la limitación del ROM de aductores e incidencia lesional (Tyler, 2001; Witvrouw, 2003; Arnason, 2004),
recomendamos su realización de forma cualitativa mediante el test de aductores cortos para diferenciar posibles problemas estructurales o funcionales.

## Objetivo
Comprobar la extensibilidad de los aductores cortos y/o largos en decúbito supino e identificar una posible descentralización de la cabeza del
fémur en la articulación de la cadera.


# TEST DE NACHLAS

## Indicaciones
El evaluado se colocará en decúbito prono. El evaluador sujetará la pierna a evaluar por la articulación supra-astragalina e irá llevando
progresivamente dicha pierna hacia el glúteo mayor homolateral.

Se considera falta de extensibilidad si el calcáneo no llega a la zona glútea hasta una **distancia igual o menor a 5cm.**
- Evitar la anteversión de la pelvis o compensaciones rotacionales.
- Para diferenciar ante una posible cortedad del Recto Anterior del Cuádriceps (RAC) se realizará posteriormente el Test de Thomas.

## Justificación
**Junto a la realización del Test de Nachlas debemos valorar el ángulo Q** el cual se registra en el centro de la rótula por la bisectriz que forman
la línea del fémur (desde la Espina Ilíaca Antero Superior-EIAS hasta el centro de la rótula) con la línea del tendón rotuliano (desde el centro
de la rótula hasta la Tuberosidad Tibial Anterior-TTA). Dicho ángulo nos indicará los valores de normalidad o anormalidad, estableciendo el genotipo
o el “genu de rodilla” del evaluado, debiendo evaluar tanto en estático como en dinámico, mediante los test Drop Jumps.

Cuando la distancia del Test de Nachlas es superior se suele asociar a condromalacia o patela alta (rótula elevada), así como con un retropié supinador,
y cuando las angulaciones del ángulo Q son mayores a la normalidad se suele asociar con retropié en pronación, torsión tibial externa, disfunción
femoro-rotuliana, anteversión femoral y anteversión de pelvis.

El aumento de dicho ángulo hasta los 15 grados en hombres se considera normal, estando la normalidad aumentada en las mujeres hasta los 20 grados
debido a un mayor valgo fisiológico (Santonja, 1996).

Hombres
- Normalidad: de 10 a 15 grados.
- Valgo: mayor de 15 grados.
- Varo: menor de 10 grados.
Mujeres
- Normalidad: de 10 a 19 grados.
- Valgo: mayor de 19 grados.
- Varo: menor de 10 grados.

## Objetivo
Comprobar la extensibilidad de la musculatura del cuádriceps, el ángulo Q y la disposición de las rótulas.


# TEST DEL POPLÍTEO

## Indicaciones:
El evaluado se colocará en decúbito supino con flexión de cadera y rodilla de 90 grados. La mano del examinador más próxima a la cadera del evaluado
sujetará la rodilla, cuyo muslo debe estar vertical manteniendo la flexión de cadera de 90 grados en todo momento y la mano del examinador más próxima
al tobillo del evaluado sujetará su retropié e irá subiendo progresivamente hasta el final del movimiento.

**Dicho test puede realizarse mediante:**
1.- Un goniómetro de rama larga, coincidiendo el centro del goniómetro con el centro de la articulación de la rodilla, una rama del goniómetro con
la línea media del muslo y la otra rama con la línea media de la pierna.
2.- Un inclinómetro con varilla extensible (ver el vídeo del protocolo ROM-Sport).
3.- Grabación del movimiento y posterior cuantificación goniométrica de la máxima amplitud.

**Atendiendo a los valores de referencia según Santonja (1996), encontramos:**
- Normalidad: mayor de 75 grados.
- Cortedad Grado I:desde los 61 grados hasta los 75 grados.
- Cortedad Grado II: Menor o igual a 60 grados.

Si tomamos como referencia los valores normativos propuestos por Byrd (2007) o Shiman (2010), encontramos un valor óptimo de 80º, la normalidad al
superar los 70º y la cortedad por debajo de 70º.

## Justificación
Debido a que la musculatura isquiosural se origina en el isquión (pelvis), una cortedad en dicha musculatura está asociada con una pelvis en
retroversión y, normalmente, con una compensación del raquis, siendo habitual encontrar una falta de movilidad en el raquis lumbar.

## Objetivo
Comprobar la extensibilidad de la musculatura isquiosural.

## Videotutoriales
¡En la próxima versión tendrás un vídeo explicativo de estas valoraciones!
`,
  videos: [
    // {vimeoId: '388598739'},
  ],
};

export const FootDescripcionAnatomica: VafLearnData = {
  markdown: `
# Titulo 1
Parrafo normal
## Lista
- 1
- 2
`,
  videos: [],
};
