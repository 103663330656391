<div
  class="-mt-5 mb-5 flex flex-col items-center md:mb-10 md:flex-row md:justify-between"
>
  <app-back-button classes="hidden md:block" />
  <p class="mb-3 cursor-pointer text-center text-3xl md:mb-0 md:cursor-default">
    <app-back-button classes="md:hidden" />
    <span (click)="openLearn()">
      {{ title }}
    </span>
  </p>
  <!-- Show graduation cap only when not in client view -->
  <div
    *ngIf="!isUserClientView && !learnURL"
    (click)="openLearn()"
    class="cursor-pointer text-intecc2 text-xl justify-center items-center flex {{
      learnId ? '' : 'opacity-0'
    }}"
  >
    <i class="fas fa-graduation-cap"></i>
  </div>

  <a
    *ngIf="!isUserClientView && !!learnURL"
    href="{{ learnURL }}"
    target="_blank"
    rel="noopener noreferrer"
    class="cursor-pointer text-intecc2 text-xl justify-center items-center flex {{
      learnId ? '' : 'opacity-0'
    }}"
  >
    <i class="fas fa-graduation-cap text-intecc2"></i>
  </a>

  <!-- Show generate report button only in client view -->
  <button
    *ngIf="isUserClientView"
    (click)="generateReportAction()"
    class="flex items-center justify-center rounded-md bg-intecc2 px-4 py-2 text-base font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-intecc2 focus:ring-offset-2"
  >
    <i class="far fa-file-pdf mr-2"></i>
    Generar informe
  </button>
</div>
