import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard  {
  constructor(private auth: AuthService, private nav: NavigationService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const user = await this.auth.getUser();
      const loggedIn = !!user;

      if (loggedIn) {
          this.nav.goToHome();
      }

      return !loggedIn;
  }
  
}
