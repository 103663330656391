import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-two-tab-button',
  templateUrl: './two-tab-button.component.html',
  styleUrls: ['./two-tab-button.component.scss'],
})
export class TwoTabButtonComponent {
  @Input() buttonATitle = 'Button A';
  @Input() buttonACode = 'button-a';
  @Input() buttonBTitle = 'Button B';
  @Input() buttonBCode = 'button-b';
  @Input() selectedButtonCode = 'button-a';
  @Input() isModal = false;
  @Output() buttonSelected = new EventEmitter<string>();

  constructor() {}

  selectButton(buttonCode: string) {
    this.selectedButtonCode = buttonCode;
    this.buttonSelected.emit(this.selectedButtonCode);
  }
}
