<div class="app-container">
  <app-test-header
    [title]="'Protocolo VAF ROM Artromuscular'"
    [icon]="'fas fa-drafting-compass'"
    [learnId]="'VAFROM'"
    [learnURL]="'https://manual.intecc.org/es/article/protocolo-vaf-rom'"
  ></app-test-header>

  <div class="test-info">
    <app-client-back-button [client]="client"></app-client-back-button>
    <div class="date-selector mt-2 md:mt-0">
      <button (click)="picker.open()" class="date-selector__button">
        {{ test.createdAt.toDate() | date }} <i class="fas fa-edit"></i>
      </button>

      <input
        style="display: none"
        (dateChange)="onDateChanged($event)"
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
      />
      <mat-datepicker
        touchUi
        #picker
        [startAt]="test.createdAt.toDate()"
      ></mat-datepicker>
    </div>
  </div>

  <div
    *ngFor="let item of RomShortArchitecture"
    class="bg-white rounded-lg px-5 md:px-8 py-4 pb-8 mb-5 transition-all duration-300 {{
      !test[item.id] ? '' : 'opacity-50'
    }}"
  >
    <div class="mb-4 flex justify-between">
      <p class="text-xl font-semibold">{{ item.title }}</p>
      <div class="flex items-center">
        <!-- <div *ngIf="item.learnId" (click)="openLearn(item.learnId)" class="mr-5  text-intecc2 cursor-pointer">
                    <i  class="fas fa-book"></i>
                </div> -->
        <a
          *ngIf="item.learnURL"
          href="{{ item.learnURL }}"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-5 cursor-pointer text-intecc2"
        >
          <i class="fas fa-book text-intecc2"></i>
        </a>
        <app-icon-toggle
          [active]="!test[item.id]"
          (activeChange)="setSectionStatus(item.id, !$event)"
        ></app-icon-toggle>
      </div>
    </div>
    <div
      *ngIf="item.images"
      class="w-full overflow-y-scroll md:overflow-y-hidden"
    >
      <div
        *ngIf="item.fields; else noFields"
        style=""
        class="posture-container grid max-h-screen-3/4 grid-cols-3 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-96 md:auto-cols-fr md:grid-flow-col md:grid-cols-1.2fr-1fr-1fr md:grid-rows-1"
      >
        <div class="overflow-y-scroll md:overflow-y-auto">
          <div
            style=""
            class="grid h-auto auto-rows-auto grid-cols-1 items-center gap-x-5 gap-y-2 self-start rounded-md pr-3 md:grid-cols-2"
          >
            <ng-container *ngFor="let field of item.fields; index as i">
              <ng-container *ngIf="getFootFieldInfo(field); let fieldInfo">
                <p>{{ fieldInfo.title }}</p>

                <!-- <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
                <div
                  style="
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: 1fr;
                    column-gap: 10px;
                  "
                >
                  <ng-container *ngIf="fieldInfo.leftRight" class="">
                    <app-test-field-dropdown
                      [mode]="fieldInfo.type"
                      [options]="fieldInfo.values"
                      [selectedIndex]="getValueLF(field, false)"
                      [text]="getValueTextLF(field, false)"
                      (selectedItemChanged)="setValueLF(field, $event, false)"
                      [openToTop]="i > 4"
                      (textChanged)="setValueTextLF(field, $event, false)"
                    ></app-test-field-dropdown>
                    <app-test-field-dropdown
                      [mode]="fieldInfo.type"
                      [options]="fieldInfo.values"
                      [selectedIndex]="getValueLF(field, true)"
                      [text]="getValueTextLF(field, true)"
                      (selectedItemChanged)="setValueLF(field, $event, true)"
                      [openToTop]="i > 4"
                      (textChanged)="setValueTextLF(field, $event, true)"
                    ></app-test-field-dropdown>
                  </ng-container>
                  <ng-container *ngIf="!fieldInfo.leftRight" class="">
                    <app-test-field-dropdown
                      [mode]="fieldInfo.type"
                      [options]="fieldInfo.values"
                      [selectedIndex]="getValue(field)"
                      [text]="getValueText(field)"
                      (selectedItemChanged)="setValue(field, $event)"
                      (textChanged)="setValueText(field, $event)"
                      [openToTop]="i > 4"
                    ></app-test-field-dropdown>
                  </ng-container>
                </div>
              </ng-container>
              <!-- <p>{{getFieldInfo(field).title}}</p>
                            <div class="w-full bg-neutral-100 h-9 rounded-md"></div> -->
            </ng-container>
          </div>
        </div>
        <div
          *ngFor="let image of item.images"
          style=""
          class="  h-full bg-blue-300 rounded-md overflow-hidden {{
            item.images.length === 1 ? 'col-span-2' : ''
          }}"
        >
          <app-test-image-view
            [client]="client"
            [imageKey]="image"
            [test]="test"
            (imageChangedEvent)="onImageChanged()"
          ></app-test-image-view>
          <!-- <img src="../../../../assets/images/run.jpg" class="h-full w-full object-cover" alt=""> -->
        </div>
        <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
      </div>

      <ng-template #noFields>
        <div
          style=""
          class="posture-container grid max-h-screen-3/4 grid-cols-2 grid-rows-100-of-parent gap-x-5 md:h-auto md:min-h-96 md:grid-rows-1"
        >
          <div
            *ngFor="let image of item.images"
            style=""
            class="h-full min-h-96 overflow-hidden rounded-md bg-blue-300"
          >
            <app-test-image-view
              [client]="client"
              [imageKey]="image"
              [test]="test"
              (imageChangedEvent)="onImageChanged()"
            ></app-test-image-view>
            <!-- <img src="../../../../assets/images/run.jpg" class="h-full w-full object-cover" alt=""> -->
          </div>
          <!-- <div style="" class="  h-full bg-blue-300 rounded-md"></div> -->
        </div>
      </ng-template>
    </div>
    <div *ngIf="item.commentsPropName" class="w-full">
      <p
        *ngIf="item.id !== 'linksAndComments'"
        class="mb-4 mt-7 text-lg font-semibold"
      >
        Comentarios
      </p>
      <p
        *ngIf="item.id === 'linksAndComments'"
        class="mb-4 mt-7 text-lg font-semibold"
      >
        Recomendaciones
      </p>
      <textarea
        class="w-full rounded-lg border-2 border-neutral-200"
        #commentsTextArea
        name="comments"
        cols="30"
        rows="5"
        [value]="
          getComments(item.commentsPropName)
            ? getComments(item.commentsPropName)
            : ''
        "
        (change)="onCommentsChanged(item.commentsPropName, $event)"
      ></textarea>
    </div>
    <div *ngIf="item.id === 'linksAndComments'" class="w-full">
      <p class="mb-1 mt-7 text-lg font-semibold">Enlaces multimedia</p>
      <p>
        Añade enlaces multimedia a los informes. Puedes usar la carpeta
        compartida de "Documentos" del cliente para buscar el link de la carpeta
        o archivo concreto que quieras incluir aquí
      </p>
      <button
        *ngIf="!clientSharedFolderUrl"
        (click)="this.editClient({ client })"
        class="mt-2 font-semibold text-intecc2"
      >
        Añade una carpeta "Documentos" del cliente
      </button>
      <a
        *ngIf="clientSharedFolderUrl"
        class="mt-2 inline-block font-semibold text-intecc2"
        href="{{ clientSharedFolderUrl }}"
        target="_blank"
        rel="noopener noreferrer"
        >Ir a carpeta "Documentos" del cliente</a
      >
      <input
        class="w-full rounded-lg border-2 border-neutral-200"
        name="multimediaLink"
        [ngModel]="this.test.multimediaLink ? this.test.multimediaLink : ''"
        (ngModelChange)="this.setMultimediaLink($event)"
      />
      <p class="mb-1 mt-7 text-lg font-semibold">Enlaces opcionales</p>
      <p>Añade enlaces a los informes</p>
      <app-test-report-links
        [links]="this.test.links"
        (changeEvent)="this.setLinks($event)"
      ></app-test-report-links>
    </div>
  </div>
</div>
