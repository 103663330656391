import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RomTest, RomMovementsIds, RomMovements, getResultLevel, getResultLevelName, RomResult } from 'src/shared/rom-test.model';
import { Client } from 'src/app/models/client.model';
import { firebaseTimestampFromDate } from 'src/app/utils/utils';
import { TestService } from 'src/app/services/test.service';
import { RomInclinometerComponent } from '../../rom-inclinometer/rom-inclinometer.component';
import { LearnService } from 'src/app/services/learn.service';

@Component({
    selector: 'app-rom-test',
    templateUrl: './rom-test.component.html',
    styleUrls: ['./rom-test.component.scss']
})
export class RomTestComponent implements OnInit {

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    _test: RomTest;
    get test() {
        return this._test;
    }
    @Input() set test(value: RomTest) {
        this._test = value;
        this.init();
    }
    @Input() client: Client;

    @ViewChild(RomInclinometerComponent)
    inclinometer: RomInclinometerComponent;

    date: Date;

    movementIds: Array<string> = [];

    RomMovements = RomMovements;

    tobilloMovementIds = [
        RomMovementsIds.flexsionDorsalGemelo,
        RomMovementsIds.flexionDorsalSoleo
    ];

    tobilloMovementRIds = [
        RomMovementsIds.flexsionDorsalGemeloR,
        RomMovementsIds.flexionDorsalSoleoR
    ];

    showTobilloR = false;

    rodillaMovementIds = [
        RomMovementsIds.flexionCua
    ];

    rodillaMovementRIds = [
        RomMovementsIds.flexionCuaR
    ];

    showRodillaR = false;

    caderaMovementIds = [
        RomMovementsIds.extensionIsqCua,
        RomMovementsIds.extensionPsoas,
        RomMovementsIds.flexionGluteo,
        RomMovementsIds.rotacionIntGlueto,
        RomMovementsIds.rotacionExtAductores,
        RomMovementsIds.abduccionSinFlexionAductores,
        RomMovementsIds.abduccionConFlexionAductores,
        RomMovementsIds.aduccionConFlexionAbductores,
    ];

    caderaMovementRIds = [
        RomMovementsIds.extensionIsqCuaR,
        RomMovementsIds.extensionPsoasR,
        RomMovementsIds.flexionGluteoR,
        RomMovementsIds.rotacionIntGluetoR,
        RomMovementsIds.rotacionExtAductoresR,
        RomMovementsIds.abduccionSinFlexionAductoresR,
        RomMovementsIds.abduccionConFlexionAductoresR,
        RomMovementsIds.aduccionConFlexionAbductoresR,
    ];

    showCaderaR = false;

    hombroMovementIds = [
        RomMovementsIds.extensionDeltoides,
        RomMovementsIds.flexionDorsalAncho,
        RomMovementsIds.abduccionPectoralMayor,
        RomMovementsIds.rotacionInternaInfraespinoso,
        RomMovementsIds.rotacionExternaPectoral,
        RomMovementsIds.aduccionInfraespinoso,
    ];

    hombroMovementRIds = [
        RomMovementsIds.extensionDeltoidesR,
        RomMovementsIds.flexionDorsalAnchoR,
        RomMovementsIds.abduccionPectoralMayorR,
        RomMovementsIds.rotacionInternaInfraespinosoR,
        RomMovementsIds.rotacionExternaPectoralR,
        RomMovementsIds.aduccionInfraespinosoR,
    ];

    showHombroR = false;

    codoMovementIds = [
        RomMovementsIds.extensionBiceps,
        RomMovementsIds.flexionTriceps,
    ];

    codoMovementRIds = [
        RomMovementsIds.extensionBicepsR,
        RomMovementsIds.flexionTricepsR,
    ];

    showCodoR = false;

    constructor(private tests: TestService, private learn: LearnService) { }

    ngOnInit(): void {
        this.movementIds = Object.values(RomMovementsIds);
    }

    init() {

    }

    onDateChanged(date: any) {
        this.test.createdAt = firebaseTimestampFromDate(date.value);
        this.updateTest();
    }

    updateTest() {
        const { ...test } = this.test;
        this.tests.updateTest(test);
    }

    onInclinometerClosed(changed: boolean) {
        if (changed) {
            this.updateTest();
        }
    }

    hasResult(movementId: string) {
        const result = this.test.results[movementId];
        return !!result && !!result.value;
    }

    getResultValue(movementId: string) {
        const result = this.test.results[movementId];
        if (this.hasResult(movementId)) {
            return result.value;
        } else {
            return '00';
        }
    }

    getResult(movementId: string): RomResult {
        return this.test.results[movementId];
    }

    getResultLevel(movementId: string) {
        const result = this.test.results[movementId];
        const value = !!result ? result.value : null;
        return getResultLevel(movementId, value);
    }

    openInclinometer(movementId: string) {
        this.inclinometer.open(this.test, movementId);
    }

    openLearn(learnSectionName: string) {
        return;
        this.learn.openPanel(learnSectionName);
    }

}
