
<div class="w-full h-full relative">
    <div *ngIf="protocol"
      class="max-w-4xl mx-auto animated fadeIn  rounded-xl bg-white ring-1 ring-black/5 shadow-sm transition-all duration-200">
      <div class="flex flex-col md:flex-row md:justify-between items-center px-8 py-6">
        <h1 class="text-3xl font-bold mb-3 md:mb-0 text-center">{{protocol.name ? protocol.name : 'Protocolo My VAF'}}
        </h1>
        <div class="flex flex-col md:flex-row items-center">
            <app-two-state-button class="mb-3 md:mb-0 md:mr-4" (buttonClick)="duplicate()" [loading]="duplicating" [text]="'Añadir a mis tests'"
            [type]="'normal'" [loadingText]="'Guardando...'" [faIconCode]="'far fa-copy'"></app-two-state-button>
        </div>
      </div>

    </div>

  </div>
  <div class="mb-10"></div>

