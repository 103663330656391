export const MEDIASECTIONS_PATH = 'media-sections';

export interface MediaSectionItem {
  name: string;
  mediaIds: Array<string>;
}

export interface MediaSection {
  id?: string;
  owner: string;
  sections: Array<MediaSectionItem>;
}

export interface GlobalMediaSection {
  id?: string;
  sections: Array<MediaSectionItem>;
  isGlobal: true;
}
