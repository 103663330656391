<div class="content h-full">
  <div
    class="flex h-full items-start justify-center px-4 py-12 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-4 rounded-xl bg-white p-6 shadow-md">
      <h2 class="text-center text-3xl font-extrabold text-gray-900">
        Unirse a un profesional
      </h2>
      <ng-container [ngSwitch]="pageLoaded">
        <ng-container *ngSwitchCase="'loading'">
          <div class="flex h-32 items-center justify-center">
            <app-loader-feedback size="large"></app-loader-feedback>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
          <div class="flex h-32 items-center justify-center">
            <app-error-feedback minimal="true"></app-error-feedback>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'idle'">
          <div *ngIf="!professional">
            <div class="mt-6 text-center text-gray-500">
              <p>No se ha podido encontrar el profesional</p>
              <p class="mt-6 text-center">
                <a
                  routerLink="/c"
                  class="font-medium text-intecc hover:text-intecc/80"
                >
                  Volver a la página de inicio
                </a>
              </p>
            </div>
          </div>
          <div *ngIf="linkStatus === 'unlinked' && professional">
            <div>
              <p class="mt-2 text-center text-gray-600">
                Has sido invitado a unirte
                <ng-container *ngIf="professional?.displayName">
                  al profesional
                  <span class="font-bold">{{ professional?.displayName }}</span>
                </ng-container>
                <ng-container *ngIf="!professional.displayName">
                  a un profesional
                </ng-container>
              </p>
            </div>

            <div class="mt-8 space-y-6">
              <p class="text-md mb-4 text-center text-gray-700">
                ¿Te gustaría aceptar esta invitación?
              </p>
              <div class="flex items-center justify-center space-x-4">
                <button
                  [disabled]="acceptInvitationState === 'loading'"
                  (click)="acceptInvitation()"
                  class="focus:ring-intecc-500 relative flex w-1/2 items-center justify-center rounded-md border border-transparent bg-intecc px-4 py-2 font-medium text-white hover:bg-intecc/80 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                >
                  Aceptar
                  <i
                    *ngIf="acceptInvitationState === 'loading'"
                    class="fas fa-spinner fa-spin ml-2"
                  ></i>
                </button>
                <button
                  (click)="rejectInvitation()"
                  class="relative flex w-1/2 justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Rechazar
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="linkStatus === 'linked'"
            class="mt-6 text-center text-gray-500"
          >
            <p>Ya estás vinculado a un profesional.</p>
            <p class="mt-6 text-center">
              <a
                routerLink="/c"
                class="font-medium text-intecc hover:text-intecc/80"
              >
                Volver a la página de inicio
              </a>
            </p>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
