import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CustomBrandingService {

    brand: 'default' | 'uem' = 'default';

    constructor(private router: Router) {
        const url = this.router.url;
        console.log(window.location.hostname);
        if (window.location.hostname.includes('uem')) {
            this.brand = 'uem';
        }

    }

}
